import { rootApi } from "../../api/rootApi";


export const messageTemplateForTenantApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({

        getSmsTemplateWithLimitOffsetForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "GET",
                url: `/tenant/smsTemplate/?limit=${params.limit}&offset=${params.offset}${params.account_id ? `&account_id=${params.account_id}` : ""}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                },
              };
            },
          }),
        addSmsTemplateForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "POST",
                url: `/tenant/smsTemplate/add`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",

                },
                body: params.body,
              };
            },
          }),
          updateSmsTemplateStatusForTenant: builder.mutation({
            query: (params) => {
              console.log("params",params);
              return {
                method: "PATCH",
                url: `/tenant/smsTemplate/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",


                },
                body: params.body,
              };
            },
          }),
        updateSmsTemplateForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "PUT",
                url: `/tenant/smsTemplate/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",

                },
                body: params.body,
              };
            },
          }),
        deleteSmsTemplateForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "DELETE",
                url: `/tenant/smsTemplate/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",

                }, };
            },
          }),
        
    })

});
export const { useAddSmsTemplateForTenantMutation,useDeleteSmsTemplateForTenantMutation,useGetSmsTemplateWithLimitOffsetForTenantMutation, useUpdateSmsTemplateForTenantMutation , useUpdateSmsTemplateStatusForTenantMutation} = messageTemplateForTenantApi;