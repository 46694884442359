import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi2";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ExportCSV from "../../../../components/ExportCsv/ExportCsv";
import { useDeleteSupplyBeamUsersMutation, useGetSupplyBeamUsersMutation } from "../../../../features/supplyBeam/manageUserSB/manageUserSBApi";

const SupplyBeamUsers = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const initialRequest = {
    name: "",
    employee_id:"",
    designation: "",
    email: "",
    mobile: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.email === "" &&
      request.name === "" &&
      request.designation === "" &&
      request.mobile === ""
    ) {
      getAllUsers();
      getAllUsersCsvFunction();
    }
  }, [request]);

  useEffect(() => {
    //   const hasViewPermission = hasPermission("webPanelUserList");

    //   if (!hasViewPermission) {
    //     navigate(`/genefied/${getVendorName()}/`);
    //   }

    getAllUsers();
    getAllUsersCsvFunction();
  }, []);

  const getAllUsersCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    getAllTenantUserCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const [
    getAllTenantUser,
    {
      data: getAllTenantUserData,
      isLoading: getAllTenantUserIsLoading,
      isError: getAllTenantUserIsError,
      error: getAllTenantUserError,
    },
  ] = useGetSupplyBeamUsersMutation();
  const [
    getAllTenantUserCsv,
    {
      data: getAllTenantUserCsvData,
      isLoading: getAllTenantUserCsvIsLoading,
      isError: getAllTenantUserCsvIsError,
      error: getAllTenantUserCsvError,
    },
  ] = useGetSupplyBeamUsersMutation();
  const getAllUsers = () => {
    getAllTenantUser({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const [
    deleteTenantUser,
    {
      data: deleteTenantUserData,
      isLoading: deleteTenantUserIsLoading,
      isError: deleteTenantUserIsError,
      error: deleteTenantUserError,
    },
  ] = useDeleteSupplyBeamUsersMutation();

  const deleteUser = (data) => {
    deleteTenantUser({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: data.id,
    });
  };
  useEffect(() => {
    if (deleteTenantUserData) {
      toast.success("Role Deleted Successfully !!");
      getAllUsers();
      getAllUsersCsvFunction();
    }
    if (deleteTenantUserError) {
      toast.error("Error in Deleting Role");
    }
  }, [deleteTenantUserData, deleteTenantUserError]);
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "160px",
    },
    {
      name: "Employee Id",
      selector: (row) => row.employee_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "location_id",
      selector: (row) => row.location_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "location_name",
      selector: (row) => row.location_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Email Id",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "Phone No",
      selector: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true,
      width: "120px",
    },
    {
      name: "Panel Role",
      selector: (row) => row.role_name,
      sortable: true,
      width: "150px",
    },
  
    {
      name: "Last Login",
      selector: (row) =>
        row.last_login && new Date(row.last_login).toLocaleString(),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        // row.role_name !== "Admin" && (
        <div className="flex justify-center space-x-2 m-auto">
          <>
            <button
              onClick={() => navigate(`add`, { state: row })}
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              Edit
            </button>
          </>
          <>
            <button
              onClick={() => deleteUser(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </>
        </div>
      ),
      // ),
      width: "20%",
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Web Panel Users
            <LinksComponent />
          </div>

          <div className=" p-3">
            {getAllTenantUserCsvData &&
              getAllTenantUserCsvData?.body?.users && (
                <ExportCSV
                  data={
                    getAllTenantUserCsvData &&
                    getAllTenantUserCsvData?.body?.users
                  }
                  columnsToExport={[
                    "name",
                    "employee_id",
                    "email",
                    "mobile",
                    "designation",
                    "role_name",
                  ]}
                />
              )}
            <div className=" bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Employee ID
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Employee ID..."
                        required
                        value={request.employee_id}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, employee_id: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Designation
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Designation..."
                        required
                        value={request.designation}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, designation: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Email
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Email ..."
                        required
                        value={request.email}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, email: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile No..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row gap-2 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>
                      <button
                        className="btn btn-outline-primary pdbtn_all clear-both float-right "
                        onClick={() => {
                          getAllUsers();
                          getAllUsersCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        &nbsp;
                      </label>
                      <button
                        className="btn btn-outline-primary pdbtn_all clear-both float-right whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <DataTableComponent
                columns={columns}
                data={getAllTenantUserData && getAllTenantUserData.body.users}
                loading={getAllTenantUserIsLoading}
                totalRows={
                  getAllTenantUserData && getAllTenantUserData.body.total_users
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                <div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplyBeamUsers;
