import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import {
  useAddBatchMutation,
  useUpdateBatchMutation,
} from "../../../features/tenant/batch/batchApi";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetchAllBrandsMutation } from "../../../features/tenant/brands/brandsApi";
import { useFetchProductsMutation } from "../../../features/tenant/products/productsApi";

const status = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Inactive",
  },
];

const brands = [
  {
    id: 1,
    name: "Brand1",
  },
  {
    id: 2,
    name: "Brand2",
  },
];

const AddBatch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isUpdate, setIsUpdate] = useState(location.state ? true : false);
  const [
    fetchProductsForCsv,
    {
      data: productsForCsvData,
      isLoading: productsForCsvIsLoading,
      isError: productsForCsvIsError,
      error: productsForCsvError,
    },
  ] = useFetchProductsMutation();

  const fetchProductsForCsvFunction = () => {
    const modifiedRequest = {
      limit: null,
      offset: null,
    };
    fetchProductsForCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [selectedBrandName, setselectedBrandName] = useState({
    id: -1,
    name: "Select Brand",
  });

  const [selectedProductName, setselectedProductName] = useState({
    id: -1,
    name: "Select Product",
  });

  const [date, setDate] = useState();
  const [batchName, setbatchName] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(status[0]);

  useEffect(() => {
    if (isUpdate) {
      const state = location.state;
      let month = Number(location.state.month);
      const year = Number(location.state.year);

      if (month < 10) {
        month = "0" + month;
      }

      setbatchName(state.name);
      setDate(year + "-" + month);
      setselectedBrandName({ id: 0, name: state.brand });
    }
  }, [location.state]);

  const [fetchBrandList, { data: brandsData, error: brandsError }] =
    useFetchAllBrandsMutation();

  useEffect(() => {
    fetchBrandList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("vendorData")).name == "Novamax") {
      fetchProductsForCsvFunction();
    }
  }, []);

  const [
    updateBatch,
    {
      data: updateBatchData,
      isLoading: updateBatchIsLoading,
      isError: updateBatchIsError,
      error: updateBatchError,
    },
  ] = useUpdateBatchMutation();

  const handleUpdate = (e) => {
    e.preventDefault();
    if (isUpdate) {
      const seletedDate = date.split("-");
      const data = {
        brand: selectedBrandName.name,
        name: batchName,
        status: selectedStatus.name === "Active" ? "1" : "0",
        month: seletedDate[1],
        year: seletedDate[0],
      };
      updateBatch({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: data,
        batchId: Number(location.state.batch_id),
      });
    }
  };

  useEffect(() => {
    if (updateBatchData) {
      toast.success("Batch Updated successfully", {
        id: "updateBatchSuccess",
      });
      setTimeout(() => {
        navigate(-1);
      }, 300);
    }

    if (updateBatchError) {
      toast.error(updateBatchError.data.message, {
        id: "updateBatchError",
      });
    }
  }, [updateBatchData, updateBatchError]);

  const [
    addBatch,
    {
      data: batchData,
      isLoading: batchIsLoading,
      isError: batchIsError,
      error: batchError,
    },
  ] = useAddBatchMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedBrandName.id === -1 && selectedProductName.id === -1) {
      return;
    }
    const seletedDate = date.split("-");
    if (selectedProductName.id === -1) {
      const data = {
        brand: selectedBrandName.name,
        name: batchName,
        status: selectedStatus.name === "Active" ? "1" : "0",
        month: seletedDate[1],
        year: seletedDate[0],
      };
      addBatch({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: data,
      });
    } else {
      const data = {
        brand: selectedProductName.name,
        name: batchName,
        status: selectedStatus.name === "Active" ? "1" : "0",
        month: seletedDate[1],
        year: seletedDate[0],
      };
      addBatch({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: data,
      });
    }
  };

  useEffect(() => {
    if (selectedBrandName.id !== -1 && date) {
      setbatchName(
        `${
          selectedBrandName.name.slice(0, 2) +
          String.fromCharCode(65 + Number(date.split("-")[1]) - 1) +
          date.split("-")[0].slice(-2)
        }`.toUpperCase()
      );
    }
  }, [selectedBrandName, date]);

  useEffect(() => {
    if (selectedProductName.id !== -1 && date) {
      const productNameParts = selectedProductName.name.split(" ");
      console.log(productNameParts, "HERE");
      const productCode =
        productNameParts.join(" ").match(/[A-Z]{3}/)?.[0] || "";
      const productCodeLtr = productNameParts.join(" ").match(/\d+/)?.[0] || "";

      // Extract the month and year from the date
      const month = date.split("-")[1];
      const year = date.split("-")[0].slice(-2);

      // Extract the last three digits of the product ID

      // Extract the last three digits of the product ID
      const productLineValue = productNameParts[productNameParts.length - 2];

      const productMotorValue = productNameParts[productNameParts.length - 1];

      // Generate the batch name
      const batchName =
        `${productCode}${productCodeLtr}${month}${year}${productLineValue}${productMotorValue}`.toUpperCase();
      setbatchName(batchName);
    }
  }, [selectedProductName, date]);

  useEffect(() => {
    if (batchData) {
      console.log("batch data", batchData);
      toast.success("Batch created successfully", {
        id: "batchSuccess",
      });
      setTimeout(() => {
        navigate(-1);
      }, 300);
    }

    if (batchError) {
      console.log("batch error", batchError);
      toast.error(batchError.data.message, {
        id: "batchError",
      });
    }
  }, [batchData, batchError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update" : "Add"} Batch
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <form className="">
                <div className="w-full flex justify-center space-x-4">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                  <div className="mb-6 bottom-2 w-1/2 dropds">
                    {productsForCsvData?.body?.products ? (
                      <SelectComponent
                        title={"Products"}
                        selected={selectedProductName}
                        setSelected={setselectedProductName}
                        dataArr={productsForCsvData?.body?.products || []}
                      />
                    ) : (
                      <SelectComponent
                        title={"Brand"}
                        selected={selectedBrandName}
                        setSelected={setselectedBrandName}
                        dataArr={brandsData?.body?.brands || []}
                      />
                    )}
                  </div>

                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Month-Year"}
                      type={"month"}
                      value={date}
                      setValue={setDate}
                      required={true}
                    />
                  </div>
                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Batch Code"}
                      type={"text"}
                      value={batchName}
                      setValue={setbatchName}
                      required={true}
                    />
                  </div>
                  <div className="mb-6 bottom-2 w-1/4 dropds">
                    <SelectComponent
                      title={"Status"}
                      selected={selectedStatus}
                      setSelected={setSelectedStatus}
                      dataArr={status}
                    />
                  </div>
                </div>

                <div className="flex justify-end mt-2">
                  {isUpdate ? (
                    <button
                      type="update"
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Add
                    </button>
                  )}
                </div>
              </form>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBatch;
