import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useCountExtraPointsScanHistoryMutation, useFetchExtraPointsScanHistoryMutation } from "../../../../features/tenant/rewardify/pointsScanHistory/extraPointsScanHistoryApi";
import ExportCSV from "../../../../components/ExportCsv/ExportCsv";
import { getLoginUserData } from "../../../../utils/getLoginUserData";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import CustomExport from "../../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../../components/Loader/CircleLoaderComponent";

const ExtraPointHistory = () => {
  const zoneId = getLoginUserData().zone_id;
  const [
    fetchPointsScanHistory,
    { data: pointsScanHistoryData, error: pointsScanHistoryError , isLoading : pointsScanHistoryIsLoading },
  ] = useFetchExtraPointsScanHistoryMutation();
  const [
    countPointsScanHistory,
    { data: countpointsScanHistoryData, error: countpointsScanHistoryError , isLoading : countpointsScanHistoryIsLoading },
  ] = useCountExtraPointsScanHistoryMutation();
  const [
    fetchPointsScanHistoryCsv,
    { data: pointsScanHistoryCsvData, error: pointsScanHistoryCsvError },
  ] = useFetchExtraPointsScanHistoryMutation();

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);


  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    name: "",
    limit: 10,
    offset: 0,
    product_code: "",
    product_name: "",
    mobile: "",
    start_date: "",
    end_date: currentDate,
    user_type_id: userTypeId,
    zoneId,
  };
  const [request, setRequest] = useState(initialRequest);
  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.name === "" &&
      request.product_code === "" &&
      request.product_name === "" &&
      request.mobile === "" &&
      request.start_date === "" &&
      request.end_date === currentDate
    ) {
      fetchPointsScanHistoryFunction();
      // fetchCsvPointsScanHistoryFunction();
    }
  }, [request]);

  useEffect(() => {
    if (
      request.name === "" &&
      request.product_code === "" &&
      request.product_name === "" &&
      request.mobile === "" &&
      request.start_date === "" &&
      request.end_date === currentDate
    ) {
      countPointsScanHistoryFunction();
      
      // fetchCsvPointsScanHistoryFunction();
    }
  }, [request]);

  useEffect(() => {
    if(limit!=10||offset!=0)
    fetchPointsScanHistoryFunction();
    // fetchCsvPointsScanHistoryFunction();
  }, [limit, offset]);

  const fetchPointsScanHistoryFunction = () => {
    fetchPointsScanHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const countPointsScanHistoryFunction = () => {
    countPointsScanHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchCsvPointsScanHistoryFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchPointsScanHistoryCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zone_id: zoneId,
    });
  };


  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (pointsScanHistoryCsvData && pointsScanHistoryCsvData?.body?.data) {
      setCsvData(pointsScanHistoryCsvData.body.data);
    }

    if (pointsScanHistoryCsvError) {
      setCsvData(null);
    }
  }, [pointsScanHistoryCsvData, pointsScanHistoryCsvError])

  useEffect(() => {
    if (pointsScanHistoryData && pointsScanHistoryData.body) {
      console.log("success scan history", pointsScanHistoryData);
      setMyList(pointsScanHistoryData.body && pointsScanHistoryData.body.data ? pointsScanHistoryData.body.data : []);
      setTotalRows(countpointsScanHistoryData.body && countpointsScanHistoryData.body.total ?countpointsScanHistoryData.body.total  : 0 );
    }
  }, [pointsScanHistoryData, pointsScanHistoryError,countpointsScanHistoryData]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };
  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },

    {
      name: "Phone",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Platform",
      cell: (row) => row.platform,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
      width: "150px",

    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "150px",

    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Scanned At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      width: "120px",
      sortable: true,
    },
    {
      name: "Method",
      cell: (row) => row.method_type,
      sortable: true,
    },
    {
      name: "Added By Mobile",
      cell: (row) => row?.cause_user_mobile,
      sortable: true,
      width: "200px",
    },
    {
      name: "Added By Name",
      cell: (row) => row?.cause_user_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Added By User Type",
      cell: (row) => row?.cause_user_type,
      sortable: true,
      width: "200px",
    },
    {
      name: "City",
      cell: (row) => row.city,
      sortable: true,
    },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => row.district,
      sortable: true,
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Extra Points History
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data && (
              <ExportCSV
                data={
                  pointsScanHistoryCsvData && pointsScanHistoryCsvData.body.data
                }
                columnsToExport={[
                  "name",
                  "mobile",
                  "user_type",
                  "points",
                  "product_name",
                  "product_code",
                  "qr_id",
                  "batch_running_code",
                  "method",
                ]}
              />
            )} */}
            <div className="mb-2">
              <CustomExport fetchData={fetchCsvPointsScanHistoryFunction} data={csvData} defaultColumnExport={[
                "name",
                "mobile",
                "user_type",
                "points",
                "product_name",
                "product_code",
                "qr_id",
                "batch_running_code",
                "method_type",
              ]}
                filename="extra_point_history"
              />
            </div>


            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-100 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile ..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="flex-col slc_dvd topslt">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">User Types</label>
                      <div className="relative searchin">
                        {appUsers && appUsers.length > 0 && (
                          <SelectComponent
                            className="capitalize"
                            //title={"User Types"}
                            selected={userTypesArray}
                            setSelected={setUserTypesArray}
                            dataArr={appUsers}
                          />
                        )}</div>
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.product_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Code</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Product Code ..."
                      required
                      value={request.product_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, product_code: newValue };
                        });
                      }}
                    />
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Start Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        value={request.start_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              start_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">End Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        value={request.end_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              end_date: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-52 gap-2 h-10">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  p-2"
                        onClick={() => {
                          fetchPointsScanHistoryFunction();
                          // fetchCsvPointsScanHistoryFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  whitespace-nowrap p-2"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {pointsScanHistoryIsLoading && <CircleLoaderComponent/> }
                <DataTableComponent
                  columns={columns}
                  // title={""}
                  data={
                    myList
                  }
                  // loading={loading}
                  totalRows={
                    totalRows
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                >
                  <div className="flex m-2 space-x-2 justify-end"></div>
                </DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtraPointHistory;
