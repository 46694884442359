import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { useFetchAllAppUserTypeQuery } from "../../../features/admin/AppUserType/appUserTypeApi";
import { GrFormClose } from "react-icons/gr";
import { useUpdateVendorMutation } from "../../../features/admin/vendor/vendorApi";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import PointSharing from "./PointSharing";
import RedemptionApprovalHierarchay from "./RedemptionApprovalHierarchay";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DreamGiftValue = {
  users: [],
  target: "point",
  percentage: null,
};

const productsAvailingObj = {
  Genuinity: { taken: false, users: [] },
  DWAN: { taken: false, users: [] },
  ScanAndWin: { taken: false, users: [] },
  Rewardify: { taken: false, users: [] },
  SupplyBeam: { taken: false, users: [] },
  HybridOcean: { taken: false, users: [] },
};

const KycOptionsObject = {
  Aadhar: { taken: false, users: [] },
  PAN: { taken: false, users: [] },
  // Address: { taken: false, users: [] },
  GSTIN: { taken: false, users: [] },
};

const loginOptionsObject = {
  Otp: { taken: false, users: [] },
  Password: { taken: false, users: [] },
};

const RegistrationOptionsObject = {
  Registration: { taken: false, users: [] },
};

const ApprovalOptionsObject = {
  Manual: { taken: false, users: [] },
  AutoApproval: { taken: false, users: [] },
};

const addOnFeatures = {
  registration_bonus: false,
  referral: false,
  bonus_system: false,
  campaign: false,
  membership: false,
  scheme: false,
  sales_booster: false,
  scratch_coupon: false,
  dream_gift: false,
  kyc_online_verification: false,
  instant_payment: false,
  multi_pop_on_qr : false,
  tds_deducted : false,
  tds_compliance : false,
};

const smsOptionsObject = {
  otp: false,
  registraion: false,
  block_account: false,
  activate_account: false,
  reset: false,
  profile_completion: false,
  set_mpin: false,
  redeem_request: false,
  bonus_points: false,
  birthday_wishes: false,
  redemption_reminders: false,
  scan_coupon_points: false,
  complaint_message: false,
  gift_delivery: false,
  extra_points: false,
};

const mailOptionObject = {
  welcome_mail: false,
  account_status_mail: false,
  redemption_mail: false,
  points_mail: false,
  gift_status_mail: false,
  points_mail: false,
  registration_mail: false,
  account_activation_mail: false,
  warranty_mail: false,
  gift_redemption_mail: false,
  claim_mail: false,
  support_mail: false,
  reset_password_mail: false,
  special_day_mail: false,
  celebrations_mail: false,
};

const pointsSharingObject = {
  flat_points: true,
  percentage_points: false,
  is_point_sharing: false,
  percentage_points_value: null,
  is_point_sharing_bw_user: false,
  is_point_reserved: false,
  point_reserved_percentage: null,
  point_sharing_bw_user: {
    user: {},
    percentage: {},
  },
};

const RedemptionHierarchy = {
  
  gift_mapped_users: {},
  is_mapped_users: false,
  gift_extra_approvals: {},
};

const BasicSetup = () => {
  const [dreamGiftPopUp, setDreamGiftPopUp] = useState(false);
  const [dreamGiftData, setDreamGiftData] = useState(DreamGiftValue);

  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(0);

  const [selectedVendorData, setSelectedVendorData] = useState(null);

  const [selectedRewardifyOption, setSelectedRewardifyOption] = useState([]);
  const [rewardifySubOptions, setRewardifySubOptions] = useState({});
  const [rewardifyOptions, setRewardifyOptions] = useState([]);

  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [selectedIndustryId, setSelectedIndustryId] = useState(0);
  const [selectedIndustryName, setselectedIndustryName] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [userTypes, setUsersTypes] = useState([]);
  const [userTypesObj, setUserTypesObj] = useState({});
  const [newUserValue, setNewUserValue] = useState("");
  const [productsAvailing, setproductsAvailing] = useState(productsAvailingObj);
  const [mailsOptions, setMailsOptions] = useState(mailOptionObject);
  const [smsOptions, setSmsOptions] = useState(smsOptionsObject);
  const [addOnOptions, setAddOnOptions] = useState(addOnFeatures);
  const [pointsSharing, setPointsSharing] = useState(pointsSharingObject);

  const [loginOptions, setLoginOptions] = useState(loginOptionsObject);

  const [registrationOptions, setRegistrationOptions] = useState(
    RegistrationOptionsObject
  );

  const [userAdditionOptions, setUserAdditionOptions] = useState(null);

  const [parentQrAccessUser, setParentQrAccessUser] = useState([]);
  const [childQrAccessUser, setChildQrAccessUser] = useState([]);

  const [approvalOptions, setApprovalOptions] = useState(ApprovalOptionsObject);

  const [kycOptions, setKycOptions] = useState(KycOptionsObject);

  const [userRoles, setUserRoles] = useState("");

  const [selectAllSmsOptions, setSelectAllSmsOptions] = useState(false);
  const [selectAllMailOptions, setSelectAllMailOptions] = useState(false);

  const [pointSharingPopUp, setPointSharingPopUp] = useState(false);
  const [redemptionHierarchyPopUp, setRedemptionHierarchyPopUp] =
    useState(false);
  const [redemptionHierarchy, setRedemptionHierarchy] =
    useState(RedemptionHierarchy);
    console.log("redemptionHierarchyPopUp : ", redemptionHierarchyPopUp);

  console.log("selectedVendorData : ", selectedVendorData);
  console.log("pointsSharing : ", pointsSharing);

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const handleLanguageSelection = (language) => {
    setSelectedLanguages((prevSelectedLanguages) => {
      if (prevSelectedLanguages.includes(language)) {
        // Remove the language if it's already selected
        return prevSelectedLanguages.filter((lang) => lang !== language);
      } else {
        // Add the language if it's not selected
        return [...prevSelectedLanguages, language];
      }
    });
  };

  const {
    data: allAppUserTypeData,
    isError: allAppUserTypeIsError,
    error: allAppUserTypeError,
    isLoading: allAppUserTypeIsLoading,
    refetch: allAppUserTypeRefetch,
  } = useFetchAllAppUserTypeQuery({
    token: sessionStorage.getItem("access_token"),
  });

  const [
    updateVendor,
    {
      data: updateVendorData,
      isLoading: updateVendorIsLoading,
      error: updateVendorError,
    },
  ] = useUpdateVendorMutation();

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/industry`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        setIndustryList(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedIndustryId != 0 && selectedVendor != 0) {
      setUpdateDisabled(false);
    } else setUpdateDisabled(true);
  }, [selectedIndustryId, selectedVendor]);

  const navigate = useNavigate();

  const createUserTypesObject = (users) => {
    const newUsersObject = {};
    users.forEach((user) => {
      newUsersObject[user] = true;
    });
    setUserTypesObj(newUsersObject);
  };

  const addNewUserTypeInObj = (newUserValues) => {
    console.log(newUserValues, userTypesObj);
    const newUsersObject = { ...userTypesObj };
    newUsersObject[newUserValues] = true;
    setUserTypesObj(newUsersObject);
  };

  const removeFromUserTypes = (e, key) => {
    console.log(key);
    let oldValues = [...userTypes];
    oldValues = oldValues.filter((user) => user != key);
    setUsersTypes(oldValues);
    removeFromUserTypesObject(e, key);
  };

  const removeFromUserTypesObject = (e, key) => {
    console.log(key);
    let oldValues = [];
    oldValues = Object.keys(userTypesObj).filter((user) => user != key);
    createUserTypesObject(oldValues);
    setUsersTypes(oldValues);
  };

  const handleProgramSelection = (e, type) => {
    // e.preventDefault();
    // alert(`clicked ${type}`);
    let copiedValue = { ...productsAvailing };
    copiedValue[type]["taken"] = copiedValue[type]["taken"] ? false : true;
    setproductsAvailing(() => copiedValue);
    console.log(productsAvailing);
  };

  function createUserTypeObject(arr) {
    const obj = {};

    for (let type of arr) {
      obj[type] = {
        taken: false,
        // users: arr.filter((item) => item !== type),
        users: [],
      };
    }

    return obj;
  }

  useEffect(() => {
    const userAdditionObj = createUserTypeObject(userTypes);
    setUserAdditionOptions(userAdditionObj);
  }, [userTypes]);

  const handleUserAdditionFunc = (e, type) => {
    // e.preventDefault();
    // alert(`clicked ${type}`);
    let copiedValue = { ...userAdditionOptions };
    copiedValue[type]["taken"] = copiedValue[type]["taken"] ? false : true;
    setUserAdditionOptions(() => copiedValue);
    console.log(userAdditionOptions);
  };

  const handleParentQrUserAdditionFunc = (e, user) => {
    let copiedValue = [...parentQrAccessUser, user];
    setParentQrAccessUser(copiedValue);
  };

  const handleChildQrUserAdditionFunc = (e, user) => {
    let copiedValue = [...childQrAccessUser, user];
    setChildQrAccessUser(copiedValue);
  };

  const handleUserTypeUserAddition = (e, type) => {
    e.stopPropagation();
    console.log("handle add user addition", e, type);
    let userAdditionOptionsUserType = { ...userAdditionOptions };
    // console.log({ userAdditionOptionsUserType });
    if (!userAdditionOptionsUserType[type].users.includes(e.target.value)) {
      console.log("pushed");
      userAdditionOptionsUserType[type]["users"].push(e.target.value);
    } else {
      const index = userAdditionOptionsUserType[type].users.indexOf(
        e.target.value
      );
      if (index > -1) {
        // only splice array when item is found
        userAdditionOptionsUserType[type].users.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log(index, e.target.value);
      console.log("popped");
      // userAdditionOptionsUserType[type].users.pop(e.target.value);
    }
    setUserAdditionOptions(userAdditionOptionsUserType);
  };

  console.log("user addition options", userAdditionOptions);

  const handleProgramSelectionLoginOptions = (e, type) => {
    // e.preventDefault();
    let copiedValue = { ...loginOptions };
    console.log(loginOptions);
    copiedValue[type]["taken"] = copiedValue[type]["taken"] ? false : true;
    setLoginOptions(() => copiedValue);
  };

  const handleProgramSelectionRegistrationOptions = (e, type) => {
    // e.preventDefault();
    let copiedValue = { ...registrationOptions };
    console.log(registrationOptions);
    copiedValue[type]["taken"] = copiedValue[type]["taken"] ? false : true;
    setRegistrationOptions(() => copiedValue);
  };

  const handleProgramSelectionApprovalOptions = (e, type) => {
    // e.preventDefault();
    let copiedValue = { ...approvalOptions };
    console.log(approvalOptions);
    copiedValue[type]["taken"] = copiedValue[type]["taken"] ? false : true;
    setApprovalOptions(() => copiedValue);
  };

  const handleProgramSelectionKYCOptions = (e, type) => {
    // e.preventDefault();
    let copiedValue = { ...kycOptions };
    console.log(kycOptions);
    copiedValue[type]["taken"] = copiedValue[type]["taken"] ? false : true;
    setKycOptions(() => copiedValue);
  };

  const handleSmsOptionsSelection = (type) => {
    setSelectAllSmsOptions(false);
    let copiedValue = { ...smsOptions };
    // console.log("HandleSmsOptions" ,type , smsOptions );
    copiedValue[type] = copiedValue[type] ? false : true;
    setSmsOptions(copiedValue);
  };

  const handleSelectAllSmsOptions = () => {
    let copiedValue = { ...smsOptions };
    console.log("HandleSmsOptions", smsOptions);

    Object.keys(copiedValue).forEach((each) => {
      copiedValue[each] = !selectAllSmsOptions;
    });

    setSelectAllSmsOptions(!selectAllSmsOptions);
    // copiedValue[type] = copiedValue[type] ? false : true;
    setSmsOptions(copiedValue);
  };

  const handleAddOnOptionsSelection = (type) => {
    console.log("object-", type);
    let copiedValue = { ...addOnOptions };
    copiedValue[type] = copiedValue[type] ? false : true;
    setAddOnOptions(copiedValue);
    if (type == "dream_gift") {
      setDreamGiftPopUp(true);
    }
    
  };

  const handleAddPointSharing = (obj) => {
    console.log("Point sharing ...................", obj);
    setPointsSharing({
      ...obj,
    });
  };

  const handleRedemptionHierarchy=(obj)=>{
    console.log("handleRedemptionHierarchy",obj);
    setRedemptionHierarchy({
      ...obj
    })
  }

  const handleMailOptionsSelection = (type) => {
    setSelectAllMailOptions(false);
    let copiedValue = { ...mailsOptions };
    copiedValue[type] = copiedValue[type] ? false : true;
    setMailsOptions(copiedValue);
  };

  const handleSelectAllMailOptions = () => {
    let copiedValue = { ...mailsOptions };
    console.log("HandleMailOptions", mailsOptions);

    Object.keys(copiedValue).forEach((each) => {
      copiedValue[each] = !selectAllMailOptions;
    });
    setSelectAllMailOptions(!selectAllMailOptions);
    setMailsOptions(copiedValue);
  };

  //handles user addtion and user removetion in productsAvailing
  const handleUserAddition = (e, type) => {
    e.stopPropagation();
    console.log("handle user addition", e, type);
    let productsAvailingUsers = { ...productsAvailing };
    // console.log({ productsAvailingUsers });
    if (!productsAvailingUsers[type].users.includes(e.target.value)) {
      console.log("pushed");
      productsAvailingUsers[type]["users"].push(e.target.value);
    } else {
      const index = productsAvailingUsers[type].users.indexOf(e.target.value);
      if (index > -1) {
        // only splice array when item is found
        productsAvailingUsers[type].users.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log(index, e.target.value);
      console.log("popped");
      // productsAvailingUsers[type].users.pop(e.target.value);
    }
    setproductsAvailing(productsAvailingUsers);
  };

  //handles user addtion and user removetion in loginType
  const handleUserAdditionLoginType = (e, type) => {
    e.stopPropagation();
    let loginOptionsUsers = { ...loginOptions };
    if (!loginOptionsUsers[type].users.includes(e.target.value)) {
      console.log("pushed");
      loginOptionsUsers[type]["users"].push(e.target.value);
    } else {
      const index = loginOptionsUsers[type].users.indexOf(e.target.value);
      if (index > -1) {
        // only splice array when item is found
        loginOptionsUsers[type].users.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log(index, e.target.value);
      console.log("popped");
      // loginOptionsUsers[type].users.pop(e.target.value);
    }
    setLoginOptions(loginOptionsUsers);
  };

  //checks if the user is already present in the productavail list
  const checkUserPresentInLoginType = (e, type) => {
    if (loginOptions[type]["users"].includes(e)) return true;

    return false;
  };

  const handleUserAdditionRegistrationType = (e, type) => {
    e.stopPropagation();

    if (e.target.value.toLowerCase() === "consumer") {
      // alert("consumer")
      return;
    }

    let registrationOptionsUsers = { ...registrationOptions };
    if (!registrationOptionsUsers[type].users.includes(e.target.value)) {
      console.log("pushed");
      registrationOptionsUsers[type]["users"].push(e.target.value);
    } else {
      const index = registrationOptionsUsers[type].users.indexOf(
        e.target.value
      );
      if (index > -1) {
        // only splice array when item is found
        registrationOptionsUsers[type].users.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log(index, e.target.value);
      console.log("popped");
      // registrationOptionsUsers[type].users.pop(e.target.value);
    }
    setRegistrationOptions(registrationOptionsUsers);
  };

  const checkUserPresentInRegistartionType = (e, type) => {
    if (registrationOptions[type]["users"].includes(e)) return true;
    return false;
  };

  const handleUserAdditionApprovalType = (e, type) => {
    e.stopPropagation();
    let approvalOptionsUsers = { ...approvalOptions };
    if (!approvalOptionsUsers[type].users.includes(e.target.value)) {
      console.log("pushed");
      approvalOptionsUsers[type]["users"].push(e.target.value);
    } else {
      const index = approvalOptionsUsers[type]["users"].indexOf(e.target.value);
      if (index > -1) {
        // only splice array when item is found
        approvalOptionsUsers[type]["users"].splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log(index, e.target.value);
      console.log("popped");
      // approvalOptionsUsers[type].users.pop(e.target.value);
    }
    setApprovalOptions(approvalOptionsUsers);
  };

  const checkUserPresentInApprovalType = (e, type) => {
    if (approvalOptions[type]["users"].includes(e)) return true;
    return false;
  };

  const handleUserAdditionKYCType = (e, type) => {
    e.stopPropagation();
    let KycOptionsUsers = { ...kycOptions };
    if (!KycOptionsUsers[type].users.includes(e.target.value)) {
      console.log("pushed");
      KycOptionsUsers[type]["users"].push(e.target.value);
    } else {
      console.log("popped");
      const index = KycOptionsUsers[type].users.indexOf(e.target.value);
      if (index > -1) {
        // only splice array when item is found
        KycOptionsUsers[type].users.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log(index, e.target.value);
      // KycOptionsUsers[type].users.pop(e.target.value);
    }
    setKycOptions(KycOptionsUsers);
  };

  const checkUserPresentInKYCType = (e, type) => {
    if (kycOptions[type]["users"].includes(e)) return true;
    return false;
  };

  //checks if the user is already present in the productavail list
  const checkUserPresent = (e, type) => {
    // console.log("check present", e);
    if (productsAvailing[type]["users"].includes(e)) return true;
    return false;
  };

  //checks if the user is already present in the user Addition list
  const checkUserPresentInUserAddition = (e, type) => {
    console.log("check present", e, userAdditionOptions[type]["users"]);
    if (userAdditionOptions[type]["users"].includes(e)) return true;
    return false;
  };

  const handleSelectOption = (e, key, item, idx) => {
    e.preventDefault();
    let oldOptions = { ...rewardifySubOptions };
    oldOptions[key][idx]["taken"] = !item["taken"];
    setRewardifySubOptions({ ...oldOptions });
    console.log(rewardifySubOptions);
  };

  useEffect(() => {
    toast.remove();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/company`,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        setVendorsList(response.data.body);
        //storing all vendors data
        sessionStorage.setItem("vendor_list", JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    // let industryID = 0;
    vendorsList.forEach(function (data) {
      if (data.company_id == event.target.value) {
        setSelectedVendorData(data);
        // industryID = data.industry_id;
        setSelectedIndustryId(data.industry_id);
        setselectedIndustryName(data.industry_name);
        console.log(data.industry_name, data.user_types);
        if (data.user_types) {
          setUserRoles(data.user_types);
          setUsersTypes(data.user_types);
          createUserTypesObject(data.user_types);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedVendor !== 0 && selectedVendorData !== null) {
      selectedVendorData.product_availing &&
        setproductsAvailing(selectedVendorData.product_availing);

      selectedVendorData.kyc_options &&
        setKycOptions(selectedVendorData.kyc_options);

      selectedVendorData.login_options &&
        setLoginOptions(selectedVendorData.login_options);

      selectedVendorData.mail_options &&
        setMailsOptions(selectedVendorData.mail_options);

      selectedVendorData.addon_features &&
        setAddOnOptions(selectedVendorData.addon_features);

      selectedVendorData.points_sharing &&
        setPointsSharing(selectedVendorData.points_sharing);

      selectedVendorData.redemption_hierarchy &&
      setRedemptionHierarchy(selectedVendorData.redemption_hierarchy);

      selectedVendorData.message_options &&
        setSmsOptions(selectedVendorData.message_options);

      selectedVendorData.user_types &&
        setUsersTypes(selectedVendorData.user_types);

      selectedVendorData.registration_options &&
        setRegistrationOptions(selectedVendorData.registration_options);

      selectedVendorData.approval_flow_options &&
        setApprovalOptions(selectedVendorData.approval_flow_options);

      selectedVendorData.user_management &&
        setUserAdditionOptions(selectedVendorData.user_management);

      selectedVendorData.dream_gift == null
        ? setDreamGiftData(DreamGiftValue)
        : setDreamGiftData(selectedVendorData.dream_gift);

      selectedVendorData.parent_qr_access_user == null
        ? setParentQrAccessUser([])
        : setParentQrAccessUser(selectedVendorData.parent_qr_access_user);

      selectedVendorData.child_qr_access_user == null
        ? setChildQrAccessUser([])
        : setChildQrAccessUser(selectedVendorData.child_qr_access_user);

        selectedVendorData?.language&& selectedVendorData?.language.length>0 &&
        setSelectedLanguages(selectedVendorData.language);
    }
  }, [selectedVendor, selectedVendorData]);

  const handleCompanyIndustryChange = (e) => {
    console.log(e.target.value);
    setSelectedIndustryId(e.target.value);
    if (e.target.value == 0) {
      setUserRoles([]);
      setUsersTypes([]);
      createUserTypesObject([]);
    }

    industryList.forEach(function (data) {
      if (data.industry_id == e.target.value) {
        setselectedIndustryName(data.name);
        if (data.users) {
          setUserRoles(data.users);
          setUsersTypes(data.users);
          createUserTypesObject(data.users);
        }
      }
    });
  };

  const handleUpdatingBasicSetup = () => {
    toast.loading("Updating Vendor..", {
      id: "basicSetupData",
    });

    console.log("Vendor ---------", selectedVendorData, selectedIndustryId);
    console.log(productsAvailing, selectedIndustryId, smsOptions, mailsOptions);

    var data = { ...selectedVendorData };

    // const formData = new FormData();
    // formData.append("logo", data.logo);
    // formData.append("name", data.name);
    // formData.append("email", data.email);
    // formData.append("gstin", data.gstin);
    // formData.append("mobile", data.mobile);
    // formData.append("qr_type", String(data.qr_type));
    // formData.append("industry_id", selectedIndustryId);
    // formData.append("industry_name", selectedIndustryId);
    // formData.append("user_types", JSON.stringify(userTypes));
    // formData.append("product_availing", JSON.stringify(productsAvailing));
    // formData.append("kyc_options", JSON.stringify(kycOptions));
    // formData.append("message_options", JSON.stringify(smsOptions));
    // formData.append("addon_features", JSON.stringify(addOnOptions));
    // formData.append("mail_options", JSON.stringify(mailsOptions));
    // formData.append("login_options", JSON.stringify(loginOptions));
    // formData.append(
    //   "registration_options",
    //   JSON.stringify(registrationOptions),
    // );
    // formData.append("approval_flow_options", JSON.stringify(approvalOptions));

    const obj = {
      industry_id: selectedIndustryId,
      industry_name: selectedIndustryName,
      user_types: JSON.stringify(userTypes),
      product_availing: JSON.stringify(productsAvailing),
      kyc_options: JSON.stringify(kycOptions),
      message_options: JSON.stringify(smsOptions),
      addon_features: JSON.stringify(addOnOptions),
      mail_options: JSON.stringify(mailsOptions),
      login_options: JSON.stringify(loginOptions),
      registration_options: JSON.stringify(registrationOptions),
      approval_flow_options: JSON.stringify(approvalOptions),
      user_management: JSON.stringify(userAdditionOptions),
      points_sharing: JSON.stringify(pointsSharing),
      parent_qr_access_user: JSON.stringify(parentQrAccessUser),
      child_qr_access_user: JSON.stringify(childQrAccessUser),
      redemption_hierarchy: JSON.stringify(redemptionHierarchy),
      language:JSON.stringify(selectedLanguages),
    };
    if (addOnOptions.dream_gift) {
      obj["dream_gift"] = dreamGiftData;
    }
    updateVendor({
      id: selectedVendor,
      token: sessionStorage.getItem("access_token"),
      body: obj,
    });
  };

  useEffect(() => {
    if (updateVendorData) {
      allAppUserTypeRefetch();
      if (updateVendorData) {
        toast.dismiss();
        toast.success(updateVendorData.message, {
          id: "vendorUpdated",
        });
        setTimeout(() => {
          navigate("/workflowSetup");
        }, 800);
      }
    }

    if (updateVendorError) {
      toast.dismiss();
      toast.error("Something went wrong", {
        id: "vendorUpdateError",
      });
    }
  }, [updateVendorData, updateVendorError, updateVendorIsLoading]);

  return (
    <>
      <Toaster />
      <div className="main-wrapper">
        <div className="page-content">
          <div className="card p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-0"> Welcome to Client Setup </h5>
            </div>
            <div className="card-body p-0">
              <div className="row frmds">
                <div className="col-12 col-xl-6 col-xs-12  my-2">
                  <label className="">Client</label>

                  <select
                    className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                    value={selectedVendor}
                    onChange={handleVendorChange}
                  >
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={0}
                    >
                      Select Client
                    </option>
                    {vendorsList.map((vendor) => (
                      <option key={vendor.id} value={vendor.company_id}>
                        {/* {vendor.name} */}
                        {vendor.name.charAt(0).toUpperCase() +
                          vendor.name.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12 col-xl-6 col-xs-6 my-2">
                  <label className="">Industry </label>
                  <select
                    className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                    value={selectedIndustryId}
                    onChange={handleCompanyIndustryChange}
                  >
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={0}
                    >
                      Select Industry
                    </option>
                    {industryList.map((industry, idx) => (
                      <option
                        key={industry.industry_id}
                        value={industry.industry_id}
                      >
                        {industry.name.charAt(0).toUpperCase() +
                          industry.name.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12 my-2">
                  <div>
                    <label className="">Users</label>
                  </div>

                  <select
                    type="text"
                    className="border rounded-lg shadow-sm shadow-black px-2"
                    placeholder="Add new UserType"
                    value={newUserValue}
                    // onChange={(e) => setNewUserValue(e.target.value)}
                    onChange={(e) => {
                      // if (e.keyCode == 13) {
                      console.log("----------->", e.target.value);
                      setNewUserValue(e.target.value);
                      // console.log("hello this i stushar");
                      let oldUserTypes = [...userTypes];
                      // console.log(oldUserTypes);
                      oldUserTypes.push(e.target.value);
                      addNewUserTypeInObj(e.target.value);
                      setUsersTypes(oldUserTypes);
                      setNewUserValue("");
                      // }
                    }}
                  >
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={newUserValue}
                    >
                      Select
                    </option>
                    {allAppUserTypeData &&
                      allAppUserTypeData?.body.map((x) => {
                        if (userTypes.toString().includes(x.user_type)) {
                          return <></>;
                        }
                        return (
                          <>
                            <option
                              className="rounded-lg shadow-sm shadow-black px-2"
                              value={x.user_type}
                            >
                              {x.user_type}
                            </option>
                          </>
                        );
                      })}
                  </select>
                  {/* <input
                  type="text"
                  className="border p-1"
                  placeholder="Add new UserType"
                  value={newUserValue}
                  onChange={(e) => setNewUserValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      let oldUserTypes = [...userTypes];
                      oldUserTypes.push(newUserValue);
                      addNewUserTypeInObj(newUserValue);
                      setUsersTypes(oldUserTypes);
                      setNewUserValue("");
                    }
                  }}
                /> */}

                  {userTypes.map((user) => (
                    <label className="mt-2 mr-2" key={user}>
                      <span className=" btn btn-outline-danger d-flex ">
                        {user.charAt(0).toUpperCase() + user.slice(1)}
                        <p className="ml-2">
                          <GrFormClose
                            className="w-4 h-4"
                            onClick={(e) => removeFromUserTypes(e, user)}
                          />
                        </p>
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              <div className=""></div>
            </div>
          </div>

          {/* Program Selection */}
          <div className="card my-4 p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-0"> Program Selection </h5>
            </div>
            <div className="card-body p-0">
              <div className="mt-3 ">
                <div className="d-flex w-full gap-3">
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex  justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => handleProgramSelection(e, "SupplyBeam")}
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"SupplyBeam"}
                          checked={
                            productsAvailing["SupplyBeam"].taken
                              ? "checked"
                              : ""
                          }
                          onChange={(e) =>
                            handleProgramSelection(e, "SupplyBeam")
                          }
                        />
                        {productsAvailing["SupplyBeam"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          // <BsCircle className="w-3.5 mr-2" />
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto  hover:bg-slate-200 font-semibold px-1 rounded-lg   ${
                            productsAvailing["SupplyBeam"].taken &&
                            "bg-slate-200"
                          } `}
                        >
                          SupplyBeam
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {productsAvailing["SupplyBeam"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <>
                            <div key={idx}>
                              <input
                                type="radio"
                                value={e}
                                checked={
                                  checkUserPresent(e, "SupplyBeam")
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) =>
                                  handleUserAddition(e, "SupplyBeam")
                                }
                              />
                              <span className="px-1 mx-1">
                                {e.charAt(0).toUpperCase() + e.slice(1)}
                              </span>
                            </div>
                          </>
                        ))}
                    </div>
                  </label>

                  <label className="border p-2 rounded-lg">
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => handleProgramSelection(e, "Rewardify")}
                      >
                        <input
                          type="radio"
                          style={{ display: "none" }}
                          value={"Rewardify"}
                          checked={
                            productsAvailing["Rewardify"].taken ? "checked" : ""
                          }
                          onChange={(e) =>
                            handleProgramSelection(e, "Rewardify")
                          }
                        />
                        {productsAvailing["Rewardify"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto  hover:bg-slate-200 font-semibold px-1 rounded-lg   ${
                            productsAvailing["Rewardify"].taken &&
                            "bg-slate-200"
                          } `}
                        >
                          Rewardify
                        </p>
                      </div>
                    </div>
                    <div className="ml-3 mt-1">
                      {productsAvailing["Rewardify"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresent(e, "Rewardify")
                                  ? "checked"
                                  : ""
                              }
                              onClick={(e) =>
                                handleUserAddition(e, "Rewardify")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>

                  <label className="border p-2 rounded-lg ">
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => handleProgramSelection(e, "Genuinity")}
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"Genuinity"}
                          checked={
                            productsAvailing["Genuinity"].taken ? "checked" : ""
                          }
                          onChange={(e) =>
                            handleProgramSelection(e, "Genuinity")
                          }
                        />
                        {productsAvailing["Genuinity"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                            productsAvailing["Genuinity"].taken &&
                            "bg-slate-200"
                          } `}
                        >
                          Genuinity
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {productsAvailing["Genuinity"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresent(e, "Genuinity")
                                  ? "checked"
                                  : ""
                              }
                              onClick={(e) =>
                                handleUserAddition(e, "Genuinity")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => handleProgramSelection(e, "DWAN")}
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"DWAN"}
                          checked={
                            productsAvailing["DWAN"].taken ? "checked" : ""
                          }
                          onChange={(e) => handleProgramSelection(e, "DWAN")}
                        />
                        {productsAvailing["DWAN"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto  hover:bg-slate-200 font-semibold px-1 rounded-lg   ${
                            productsAvailing["DWAN"].taken && "bg-slate-200"
                          } `}
                        >
                          DWAM
                        </p>
                      </div>
                    </div>
                    <div className="ml-3 mt-1">
                      {productsAvailing["DWAN"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresent(e, "DWAN") ? "checked" : ""
                              }
                              onChange={(e) => handleUserAddition(e, "DWAN")}
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelection(e, "HybridOcean")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"HybridOcean"}
                          checked={
                            productsAvailing["HybridOcean"].taken
                              ? "checked"
                              : ""
                          }
                          onChange={(e) =>
                            handleProgramSelection(e, "HybridOcean")
                          }
                        />
                        {productsAvailing["HybridOcean"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold  hover:bg-slate-200 px-1 rounded-lg   ${
                            productsAvailing["HybridOcean"].taken &&
                            "bg-slate-200"
                          } `}
                        >
                          HybridOcean
                        </p>
                      </div>
                    </div>
                    {/* <span className="px-1 mx-1 font-bold">HybridOcean</span> */}
                    <div className="ml-3 mt-1">
                      {productsAvailing["HybridOcean"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresent(e, "HybridOcean")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAddition(e, "HybridOcean")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* User Management */}
          <div className="card my-4 p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-0"> User Management </h5>
            </div>

            <div className="d-flex justify-start space-x-4">
              {userAdditionOptions &&
                Object.keys(userAdditionOptions).map((user) => (
                  <div className="">
                    <div className="card-body p-0">
                      <div className="mt-3 ">
                        <div className="d-flex w-full gap-3">
                          <label className="border p-2 rounded-lg">
                            <div className="d-flex  justify-content-start align-items-center ">
                              <div
                                className="d-flex justify-content-start align-items-center"
                                onClick={(e) => handleUserAdditionFunc(e, user)}
                              >
                                <input
                                  type="checkbox"
                                  style={{ display: "none" }}
                                  value={user}
                                  checked={
                                    userAdditionOptions[user]?.taken
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleUserAdditionFunc(e, user)
                                  }
                                />
                                {userAdditionOptions[user]?.taken ? (
                                  <BsCheckCircleFill className="w-3.5 mr-2" />
                                ) : (
                                  <BsCircle className="w-3.5 mr-2" />
                                )}

                                <p
                                  className={`ml-auto  hover:bg-slate-200 font-semibold px-1 rounded-lg   ${
                                    userAdditionOptions[user]?.taken &&
                                    "bg-slate-200"
                                  } `}
                                >
                                  {user.charAt(0).toUpperCase() + user.slice(1)}
                                </p>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {userAdditionOptions[user].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <>
                            {e !== user && (
                              <div key={idx}>
                                <input
                                  type="radio"
                                  value={e}
                                  checked={
                                    checkUserPresentInUserAddition(e, user)
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleUserTypeUserAddition(e, user)
                                  }
                                />
                                <span className="px-1 mx-1">
                                  {e.charAt(0).toUpperCase() + e.slice(1)}
                                </span>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* Parent Child Provision For User  */}

          {selectedVendorData && selectedVendorData.qr_type == "1" && (
            <div className="card my-4 p-4">
              <div className="borderbtm">
                <h5 class="card-title mb-0">
                  {" "}
                  Parent Child Provision For User{" "}
                </h5>
              </div>

              <div className="d-flex justify-start space-x-4">
                Parent
                {parentQrAccessUser.length > 0 &&
                  parentQrAccessUser.map((user) => (
                    <div className="">
                      <div className="card-body p-0">
                        <div className="mt-3 ">
                          <div className="d-flex w-full gap-3">
                            <label className="border p-2 rounded-lg">
                              <div className="d-flex  justify-content-start align-items-center ">
                                <div className="d-flex justify-content-start align-items-center">
                                  <>
                                    <button
                                      onClick={() => {
                                        console.log("hello----->");
                                        const newArray =
                                          parentQrAccessUser.filter(
                                            (item) => item !== user
                                          );
                                        setParentQrAccessUser(newArray);
                                      }}
                                      className="rounded-lg shadow-sm shadow-black px-2"
                                      value={user}
                                    >
                                      {user}
                                    </button>
                                  </>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="d-flex justify-start space-x-4">
                Child
                {childQrAccessUser.length > 0 &&
                  childQrAccessUser.map((user) => (
                    <div className="">
                      <div className="card-body p-0">
                        <div className="mt-3 ">
                          <div className="d-flex w-full gap-3">
                            <label className="border p-2 rounded-lg">
                              <div className="d-flex  justify-content-start align-items-center ">
                                <div className="d-flex justify-content-start align-items-center">
                                  <>
                                    <button
                                      className="rounded-lg shadow-sm shadow-black px-2"
                                      value={user}
                                      onClick={() => {
                                        const newArray =
                                          childQrAccessUser.filter(
                                            (item) => item != user
                                          );
                                        console.log("hello----->", newArray);
                                        setChildQrAccessUser(newArray);
                                      }}
                                    >
                                      {user}
                                    </button>
                                  </>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* KYC Setup */}
          <div className="card my-4 p-4 ">
            <div className="borderbtm d-flex justify-content-between">
              <h5 class="card-title mb-0"> KYC Setup</h5>
              <div class="form-check form-switch border p-2 rounded-lg d-flex items-center m-0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={
                    addOnFeatures["kyc_online_verification"] ? "checked" : ""
                  }
                  onClick={() =>
                    handleAddOnOptionsSelection("kyc_online_verification")
                  }
                  style={{ display: "none" }}
                />
                {addOnOptions["kyc_online_verification"] ? (
                  <BsCheckCircleFill className="w-3.5 mr-2" />
                ) : (
                  <BsCircle className="w-3.5 mr-2" />
                )}
                <label
                  class="form-check-label ml-0 ml-auto font-semibold hover:bg-slate-200  px-1 rounded-lg   false "
                  for="flexSwitchCheckChecked"
                >
                  Online Verification {addOnOptions["kyc_online_verification"]}
                </label>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="mt-3">
                <div className="d-flex w-full gap-3">
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex justify-content-start align-items-center ">
                      {/* <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Checked switch checkbox input
                        </label>
                      </div> */}

                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionKYCOptions(e, "Aadhar")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"Aadhar"}
                          checked={kycOptions["Aadhar"].taken ? "checked" : ""}
                          onChange={(e) =>
                            handleProgramSelectionKYCOptions(e, "Aadhar")
                          }
                        />
                        {kycOptions["Aadhar"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200  px-1 rounded-lg   ${
                            kycOptions["Aadhar"].taken && "bg-slate-200"
                          } `}
                        >
                          Aadhar
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {kycOptions["Aadhar"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInKYCType(e, "Aadhar")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionKYCType(e, "Aadhar")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                  <label className="border p-2 rounded-lg ">
                    <div className="d-flex  justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionKYCOptions(e, "PAN")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"PAN"}
                          checked={kycOptions["PAN"].taken ? "checked" : ""}
                          onChange={(e) =>
                            handleProgramSelectionKYCOptions(e, "PAN")
                          }
                        />
                        {kycOptions["PAN"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200  px-1 rounded-lg   ${
                            kycOptions["PAN"].taken && "bg-slate-200"
                          } `}
                        >
                          PAN
                        </p>
                      </div>
                    </div>
                    <div className="ml-3 mt-1">
                      {kycOptions["PAN"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInKYCType(e, "PAN")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionKYCType(e, "PAN")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>

                  {/* <label
                    className=" border-right mx-2 "
                    style={{ minWidth: "12%" }}
                  >
                    <div className="d-flex  justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionKYCOptions(e, "Address")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"Address"}
                          checked={kycOptions["Address"].taken ? "checked" : ""}
                          onChange={(e) =>
                            handleProgramSelectionKYCOptions(e, "Address")
                          }
                        />
                        {kycOptions["Address"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200  px-1 rounded-lg   ${
                            kycOptions["Address"].taken && "bg-slate-200"
                          } `}
                        >
                          Address
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {kycOptions["Address"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInKYCType(e, "Address")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionKYCType(e, "Address")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label> */}

                  <label className="border p-2 rounded-lg ">
                    <div className="d-flex  justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionKYCOptions(e, "GSTIN")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"GSTIN"}
                          checked={kycOptions["GSTIN"].taken ? "checked" : ""}
                          onChange={(e) =>
                            handleProgramSelectionKYCOptions(e, "GSTIN")
                          }
                        />
                        {kycOptions["GSTIN"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200  px-1 rounded-lg   ${
                            kycOptions["GSTIN"].taken && "bg-slate-200"
                          } `}
                        >
                          GSTIN
                        </p>
                      </div>
                    </div>
                    <div className="ml-3 mt-1">
                      {kycOptions["GSTIN"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInKYCType(e, "GSTIN")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionKYCType(e, "GSTIN")
                              }
                            />
                            <span className="px-1 mx-1">
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="card my-4 p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-0">Login & Registration </h5>
            </div>
            <div className="card-body p-0">
              {/* Login Setup */}

              <div className="mt-3">
                {/* <div>
                    <label className="text-capitalize font-weight-bold">
                      Login Setup
                    </label>
                  </div> */}
                <div className="d-flex w-full gap-3">
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionLoginOptions(e, "Otp")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"Otp"}
                          checked={loginOptions["Otp"].taken ? "checked" : ""}
                          onChange={(e) =>
                            handleProgramSelectionLoginOptions(e, "Otp")
                          }
                        />
                        {loginOptions["Otp"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                            loginOptions["Otp"].taken && "bg-slate-200"
                          } `}
                        >
                          Otp
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {loginOptions["Otp"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInLoginType(e, "Otp")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionLoginType(e, "Otp")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                  <label className="border p-2 rounded-lg ">
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionLoginOptions(e, "Otp")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"Password"}
                          checked={
                            loginOptions["Password"].taken ? "checked" : ""
                          }
                          onChange={(e) =>
                            handleProgramSelectionLoginOptions(e, "Password")
                          }
                        />
                        {loginOptions["Password"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                            loginOptions["Password"].taken && "bg-slate-200"
                          } `}
                        >
                          Password
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {loginOptions["Password"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInLoginType(e, "Password")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) => {
                                handleUserAdditionLoginType(e, "Password");
                                handleUserAdditionRegistrationType(
                                  e,
                                  "Registration"
                                );
                              }}
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                  <label className="border p-2 rounded-lg">
                    {/* <input
                        type="checkbox"
                        value={"Otp"}
                        checked={
                          registrationOptions["Registration"].taken
                            ? "checked"
                            : ""
                        }
                        onChange={(e) =>
                          handleProgramSelectionRegistrationOptions(
                            e,
                            "Registration",
                          )
                        }
                      /> */}
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionRegistrationOptions(
                            e,
                            "Registration"
                          )
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"Registration"}
                          checked={
                            registrationOptions["Registration"].taken
                              ? "checked"
                              : ""
                          }
                          onChange={(e) =>
                            handleProgramSelectionRegistrationOptions(
                              e,
                              "Registration"
                            )
                          }
                        />
                        {registrationOptions["Registration"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                            registrationOptions["Registration"].taken &&
                            "bg-slate-200"
                          } `}
                        >
                          Registration
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {registrationOptions["Registration"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInRegistartionType(
                                  e,
                                  "Registration"
                                )
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionRegistrationType(
                                  e,
                                  "Registration"
                                )
                              }
                            />
                            <span className="px-1 mx-1">
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                </div>
              </div>

              {/* Registration */}
            </div>
          </div>

          {/* Approval flow */}
          <div className="card my-4 p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-0">Approval Flow </h5>
            </div>
            <div className="card-body p-0">
              <div className="mt-3">
                {/* <div>
                    <label className="text-capitalize font-weight-bold">
                      Approval Flow
                    </label>
                  </div> */}
                <div className="d-flex w-full gap-3">
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionApprovalOptions(e, "Manual")
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"Manual"}
                          checked={
                            approvalOptions["Manual"].taken ? "checked" : ""
                          }
                          onChange={(e) =>
                            handleProgramSelectionApprovalOptions(e, "Manual")
                          }
                        />
                        {approvalOptions["Manual"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200  px-1 rounded-lg   ${
                            approvalOptions["Manual"].taken && "bg-slate-200"
                          } `}
                        >
                          Manual
                        </p>
                      </div>
                    </div>

                    <div className="ml-3 mt-1">
                      {approvalOptions["Manual"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInApprovalType(e, "Manual")
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionApprovalType(e, "Manual")
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex  justify-content-start align-items-center ">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) =>
                          handleProgramSelectionApprovalOptions(
                            e,
                            "AutoApproval"
                          )
                        }
                      >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={"AutoApproval"}
                          checked={
                            approvalOptions["AutoApproval"].taken
                              ? "checked"
                              : ""
                          }
                          onChange={(e) =>
                            handleProgramSelectionApprovalOptions(
                              e,
                              "AutoApproval"
                            )
                          }
                        />
                        {approvalOptions["AutoApproval"].taken ? (
                          <BsCheckCircleFill className="w-3.5 mr-2" />
                        ) : (
                          <BsCircle className="w-3.5 mr-2" />
                        )}

                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200  px-1 rounded-lg   ${
                            approvalOptions["AutoApproval"].taken &&
                            "bg-slate-200"
                          } `}
                        >
                          Auto
                        </p>
                      </div>
                    </div>
                    <div className="ml-3 mt-1">
                      {approvalOptions["AutoApproval"].taken &&
                        userTypes &&
                        userTypes.map((e, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              value={e}
                              checked={
                                checkUserPresentInApprovalType(
                                  e,
                                  "AutoApproval"
                                )
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                handleUserAdditionApprovalType(
                                  e,
                                  "AutoApproval"
                                )
                              }
                            />
                            <span className="px-1 mx-1">
                              {" "}
                              {e.charAt(0).toUpperCase() + e.slice(1)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

{/* Language Setup */}
<div className="card my-4 p-4">
  <div className="borderbtm">
    <h5 className="card-title mb-0">Language</h5>
  </div>
  <div className="card-body p-0">
    <div className="mt-3">
      <div className="d-flex w-full gap-3">
        {["Hindi", "English", "Tamil", "Telugu"].map((language, idx) => (
          <label key={idx} className="border p-2 rounded-lg">
            <div className="d-flex justify-content-start align-items-center">
              <input
                type="checkbox"
                style={{ display: "none" }}
                value={language}
                checked={selectedLanguages.includes(language)}
                onChange={() => handleLanguageSelection(language)}
              />
              {selectedLanguages.includes(language) ? (
                <BsCheckCircleFill className="w-3.5 mr-2" />
              ) : (
                <BsCircle className="w-3.5 mr-2" />
              )}

              <p
                className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg ${
                  selectedLanguages.includes(language) && "bg-slate-200"
                }`}
              >
                {language}
              </p>
            </div>
          </label>
        ))}
      </div>
    </div>
  </div>
</div>


          {/* Addon Features */}
          <div className="card my-4 p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-0">Add On Features </h5>
            </div>

            <MyModal
              dreamGiftData={dreamGiftData}
              setDreamGiftData={setDreamGiftData}
              open={dreamGiftPopUp}
              setOpen={setDreamGiftPopUp}
              userTypes={userTypes}
            />

            <PointSharing
              open={pointSharingPopUp}
              setOpen={setPointSharingPopUp}
              userTypes={userTypes}
              handleAddPointSharing={handleAddPointSharing}
              pointsSharing={pointsSharing}
            />

            <RedemptionApprovalHierarchay
              open={redemptionHierarchyPopUp}
              setOpen={setRedemptionHierarchyPopUp}
              userTypes={userTypes}
              handleRedemptionHierarchy={handleRedemptionHierarchy}
              redemptionHierarchy={redemptionHierarchy}
            />

            <div className="card-body p-0">
              <div className="mt-3">
                <div className="d-flex w-full gap-3 flex-wrap">
                  {Object.keys(addOnOptions).map((e) => (
                    <label className="border p-2 rounded-lg">
                      <div className="d-flex  w-full justify-content-start align-items-center ">
                        <div
                          className="d-flex justify-content-start align-items-center"
                          onClick={() => handleAddOnOptionsSelection(e)}
                        >
                          {/* <input
                          type="checkbox"
                          style={{ display: "none" }}
                          value={e}
                          checked={
                            addOnOptions[e]
                              ? "checked"
                              : ""
                          }
                          onChange={() =>
                            handleAddOnOptionsSelection(e)
                          }
                        /> */}
                          {addOnOptions[e] ? (
                            <BsCheckCircleFill className="w-3.5 mr-2" />
                          ) : (
                            <BsCircle className="w-3.5 mr-2" />
                          )}

                          <p
                            className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                              addOnOptions[e] && "bg-slate-200"
                            } `}
                          >
                            {e.replaceAll("_", " ").toLocaleUpperCase()}
                            {e == "dream_gift" && (
                              <div>
                                users : {dreamGiftData.users.join(",")}
                                <br />
                                target : {dreamGiftData.target}
                                <br />
                                {dreamGiftData.target == "value" &&
                                  dreamGiftData.percentage}
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    </label>
                  ))}
                  <label className="border p-2 rounded-lg">
                    <div className="d-flex  w-full justify-content-start align-items-center ">
                      <div className="d-flex justify-content-start align-items-center">
                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg  && "bg-slate-200"
                            } `}
                          onClick={() => setPointSharingPopUp(true)}
                        >
                          POINT SHARING
                        </p>
                      </div>
                    </div>
                  </label>

                  <label className="border p-2 rounded-lg">
                    <div className="d-flex  w-full justify-content-start align-items-center ">
                      <div className="d-flex justify-content-start align-items-center">
                        <p
                          className={`ml-auto font-semibold hover:bg-slate-200 px-1 rounded-lg  && "bg-slate-200"
                            } `}
                          onClick={() => setRedemptionHierarchyPopUp(true)}
                        >
                          REDEMPTION HIERARCHY
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Message Options    */}
          <div className="card my-4 p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-2"> Message Options </h5>
              <label
                className="border p-2 rounded-lg mb-0"
                style={{ minWidth: "12%", width: "300px" }}
              >
                <div className="d-flex mr-5 justify-content-start align-items-center ">
                  <div
                    className="d-flex w-full justify-content-start align-items-center"
                    onClick={() => handleSelectAllSmsOptions()}
                  >
                    {selectAllSmsOptions ? (
                      <BsCheckCircleFill className="w-3.5 mr-2" />
                    ) : (
                      <BsCircle className="w-3.5 mr-2" />
                    )}

                    <p
                      className={`mr-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                        selectAllSmsOptions && "bg-slate-200"
                      } `}
                    >
                      Select All
                    </p>
                  </div>
                </div>
              </label>
            </div>
            <div className="card-body p-0">
              <div className="mt-3">
                <div className="d-flex w-full flex-wrap justify-content-start gap-3">
                  {Object.keys(smsOptions).map((e) => (
                    <label
                      className="border p-2 rounded-lg mb-0"
                      style={{ minWidth: "12%", width: "300px" }}
                    >
                      <div className="d-flex mr-5 justify-content-start align-items-center ">
                        <div
                          className="d-flex w-full justify-content-start align-items-center"
                          onClick={() => handleSmsOptionsSelection(e)}
                        >
                          {smsOptions[e] ? (
                            <BsCheckCircleFill className="w-3.5 mr-2" />
                          ) : (
                            <BsCircle className="w-3.5 mr-2" />
                          )}

                          <p
                            className={`mr-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                              smsOptions[e] && "bg-slate-200"
                            } `}
                          >
                            {e.replaceAll("_", " ").toLocaleUpperCase()}
                          </p>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Mail Options    */}
          <div className="card my-4 p-4">
            <div className="borderbtm">
              <h5 class="card-title mb-0"> Mail Options</h5>
              <label
                className="border p-2 rounded-lg mb-0"
                style={{ minWidth: "12%", width: "300px" }}
              >
                <div className="d-flex mr-5 justify-content-start align-items-center ">
                  <div
                    className="d-flex w-full justify-content-start align-items-center"
                    onClick={() => handleSelectAllMailOptions()}
                  >
                    {selectAllMailOptions ? (
                      <BsCheckCircleFill className="w-3.5 mr-2" />
                    ) : (
                      <BsCircle className="w-3.5 mr-2" />
                    )}

                    <p
                      className={`mr-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                        selectAllMailOptions && "bg-slate-200"
                      } `}
                    >
                      Select All
                    </p>
                  </div>
                </div>
              </label>
            </div>
            <div className="card-body p-0">
              <div className="mt-3">
                <div className="d-flex w-full flex-wrap justify-content-start gap-3">
                  {Object.keys(mailsOptions).map((e) => (
                    <label
                      className="border p-2 rounded-lg mb-0"
                      style={{ minWidth: "12%", width: "300px" }}
                    >
                      <div className="d-flex mr-5 justify-content-start align-items-center ">
                        <div
                          className="d-flex w-full justify-content-start align-items-center"
                          onClick={() => handleMailOptionsSelection(e)}
                        >
                          {mailsOptions[e] ? (
                            <BsCheckCircleFill className="w-3.5 mr-2" />
                          ) : (
                            <BsCircle className="w-3.5 mr-2" />
                          )}

                          <p
                            className={`mr-auto font-semibold hover:bg-slate-200 px-1 rounded-lg   ${
                              mailsOptions[e] && "bg-slate-200"
                            } `}
                          >
                            {e.replaceAll("_", " ").toLocaleUpperCase()}
                          </p>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/*  Redemption Approval Hierarchay    */}
          {/* <div className="card my-4 p-4">
            <div className="col-12 my-2">
              <div>
                <label className=""> Redemption Approval Hierarchay </label>
              </div>

              <select
                type="text"
                className="border rounded-lg shadow-sm shadow-black px-2"
                placeholder="Add new UserType"
                value={newUserValue}
                // onChange={(e) => setNewUserValue(e.target.value)}
                onChange={(e) => {
                  // if (e.keyCode == 13) {
                  console.log("----------->", e.target.value);
                  setNewUserValue(e.target.value);
                  // console.log("hello this i stushar");
                  let oldUserTypes = [...userTypes];
                  // console.log(oldUserTypes);
                  oldUserTypes.push(e.target.value);
                  addNewUserTypeInObj(e.target.value);
                  setUsersTypes(oldUserTypes);
                  setNewUserValue("");
                  // }
                }}
              >
                <option
                  className="rounded-lg shadow-sm shadow-black px-2"
                  value={newUserValue}
                >
                  Select
                </option>
                {allAppUserTypeData &&
                  allAppUserTypeData?.body.map((x) => {
                    if (userTypes.toString().includes(x.user_type)) {
                      return <></>;
                    }
                    return (
                      <>
                        <option
                          className="rounded-lg shadow-sm shadow-black px-2"
                          value={x.user_type}
                        >
                          {x.user_type}
                        </option>
                      </>
                    );
                  })}
              </select>
              {/* <input
                  type="text"
                  className="border p-1"
                  placeholder="Add new UserType"
                  value={newUserValue}
                  onChange={(e) => setNewUserValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      let oldUserTypes = [...userTypes];
                      oldUserTypes.push(newUserValue);
                      addNewUserTypeInObj(newUserValue);
                      setUsersTypes(oldUserTypes);
                      setNewUserValue("");
                    }
                  }}
                /> */}

          {/* {userTypes.map((user) => (
                <label className="mt-2 mr-2" key={user}>
                  <span className=" btn btn-outline-danger d-flex ">
                    {user.charAt(0).toUpperCase() + user.slice(1)}
                    <p className="ml-2">
                      <GrFormClose
                        className="w-4 h-4"
                        onClick={(e) => removeFromUserTypes(e, user)}
                      />
                    </p>
                  </span>
                </label>
              ))}
            </div>
          </div> */}

          <div className="card p-4 ">
            <div className="d-flex justify-content-center w-100">
              <button
                type="button"
                className="btn btn-outline-primary btnall"
                onClick={handleUpdatingBasicSetup}
                disabled={updateDisabled}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MyModal = ({
  open,
  setOpen,
  dreamGiftData,
  setDreamGiftData,
  userTypes,
}) => {
  console.log("userTypes", userTypes);
  const [selectedUserTypes, setSelectedUserTypes] = useState(
    dreamGiftData.users
  );
  const [selectedTarget, setSelectedselectedTarget] = useState(
    dreamGiftData.target
  );
  const [percentage, setPercentage] = useState(dreamGiftData.percentage);

  // Sample array of ages
  const ages = [18, 25, 30, 35, 40, 45, 50];

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedUserTypes(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedUserTypes.length == 0) {
      return;
    }
    const newValue = {
      users: selectedUserTypes,
      target: selectedTarget,
      percentage: selectedTarget == "point" ? null : percentage,
    };

    console.log("object", newValue);

    setDreamGiftData({
      ...newValue,
    });
    // Close the modal
    handleClose();
  };

  return (
    <div className="w-full">
      <Dialog
        open={open}
        onClose={handleClose}
        autoDetectWindowHeight={false}
        autoScrollBodyContent={false}
      >
        <div style={{ width: 400, overflow: Hidden }}>
          <DialogTitle className="pb-0">Dream Gift Setup </DialogTitle>
          <DialogContent className="pt-3">
            {/* <DialogContentText>
             
          </DialogContentText> */}
            <FormControl fullWidth>
              <InputLabel className="bg-white">Select Usertype</InputLabel>
              <Select
                className="mb-4 "
                multiple
                value={selectedUserTypes}
                onChange={handleSelectChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {userTypes.map((x) => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel className="bg-white">Select Target Type</InputLabel>
              <Select
                className="mb-4 "
                value={selectedTarget}
                onChange={(e) => setSelectedselectedTarget(e.target.value)}
              >
                <MenuItem key={1} value={"point"}>
                  point
                </MenuItem>
                <MenuItem key={2} value={"value"}>
                  value
                </MenuItem>
              </Select>
            </FormControl>
            {selectedTarget == "value" && (
              <TextField
                label="percentage"
                fullWidth
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
              />
            )}
          </DialogContent>
          <DialogActions className="mb-3 px-3">
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default BasicSetup;
