import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { getVendorName } from "../../../utils/getVendorName";
import { RxCross2 } from "react-icons/rx";

const dummyData = [
  {
    slab_s: 0,
    slab_e: 100,
    assured_g: 0,
    lucky_draw: [
      { ld1_v: 1000, ld1_q: 1 },
      { ld2_v: 10, ld2_q: 1 },
      { ld3_v: 10, ld3_q: 1 },
      { ld4_v: 10, ld4_q: 1 },
    ],
  },
  {
    slab_s: 101,
    slab_e: 200,
    assured_g: 0,
    lucky_draw: [
      { ld1_v: 1000, ld1_q: 331 },
      { ld2_v: 10, ld2_q: 12121 },
      { ld3_v: 10, ld3_q: 1 },
      { ld4_v: 10, ld4_q: 245 },
    ],
  },
  {
    slab_s: 201,
    slab_e: 300,
    assured_g: 0,
    lucky_draw: [
      { ld1_v: 1000, ld1_q: 1 },
      { ld2_v: 10, ld2_q: 1 },
      { ld3_v: 10, ld3_q: 1 },
      { ld4_v: 10, ld4_q: 1 },
    ],
  },
];

function validateData(data) {
  for (let item of data) {
    const totalWeeks = item.week1 + item.week2 + item.week3 + item.week4;
    if (totalWeeks !== item.ld_q) {
      toast.error(
        `Mismatch found in data with ld_v: ${item.ld_v}. Expected ${item.ld_q} but got ${totalWeeks}.`,
        {
          id: "notValidData",
        },
      );
      return;
    }
  }
  return true;
}

const processData = (arr) => {
  const result = {};
  arr.forEach((item) => {
    item.lucky_draw.forEach((ld) => {
      const valueKey = Object.keys(ld).find((k) => k.includes("_v"));
      const quantityKey = valueKey.replace("_v", "_q");

      const value = ld[valueKey];

      if (!result[value]) {
        result[value] = 0;
      }

      result[value] += ld[quantityKey];
    });
  });

  const resultArray = Object.entries(result).map(([ld_v, ld_q]) => ({
    ld_v: parseInt(ld_v),
    ld_q,
    week1: 0,
    week1_used: 0,

    week2: 0,
    week2_used: 0,

    week3: 0,
    week3_used: 0,

    week4: 0,
    week4_used: 0,

    sum: 0,
  }));

  resultArray.sort((a, b) => b.ld_v - a.ld_v);

  return resultArray;
};

const LuckyDrawSetup = ({ dataArr, submitFunction }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    setData(processData(dataArr));
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(validateData(data));
    if (validateData(data)) {
      toast.success("Data is Valid");
      console.log(data);
      submitFunction(data);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
  };

  console.log("lucky_draw", data);

  const handleChangeValue = (idx, week, value) => {
    // console.log("chnage", idx, week, value);
    const tmp = [...data];
    tmp[idx][week] = Number(value);
    const sum =
      tmp[idx].week1 + tmp[idx].week2 + tmp[idx].week3 + tmp[idx].week4;
    tmp[idx].sum = sum;
    setData(tmp);
  };

  return (
    <>
      <form className=" w-full border-opacity-40 rounded-lg p-2">
              <div className=" flex w-full flex-col justify-center align-middle rounded-lg ">
                <div className="rounded-lg w-full flex flex-col justify-start items-start space-y-2">
                  <div class="w-full h-full rounded-lg ">
                    <div className="">
                      <div class="w-full h-full rounded-lg ">
                        <table className="min-w-full bg-white mb-3">
                          <thead>
                            <tr>
                              <th className="py-2 px-4 border-b bg-gray-100">Amount</th>
                              <th className="py-2 px-4 border-b whitespace-nowrap bg-gray-100">
                                Total Quantity
                              </th>
                              <th className="py-2 px-4 border-b bg-gray-100">Week1</th>
                              <th className="py-2 px-4 border-b bg-gray-100">Week2</th>
                              <th className="py-2 px-4 border-b bg-gray-100">Week3</th>
                              <th className="py-2 px-4 border-b bg-gray-100">Week4</th>
                              <th className="py-2 px-4 border-b bg-gray-100">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, index) => (
                              <tr key={index}>
                                <td className="py-2 px-4 border-b">
                                  {row.ld_v}
                                </td>
                                <td className="py-2 px-4 border-b">
                                  {row.ld_q}
                                </td>
                                <td className="py-2 px-4 border-b">
                                  <input
                                    type="number"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 px-3  "
                                    value={row.week1}
                                    onChange={(e) => {
                                      handleChangeValue(
                                        index,
                                        `week1`,
                                        e.target.value,
                                      );
                                    }}
                                  />
                                </td>{" "}
                                <td className="py-2 px-4 border-b">
                                  <input
                                    type="number"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 px-3  "
                                    value={row.week2}
                                    onChange={(e) => {
                                      handleChangeValue(
                                        index,
                                        `week2`,
                                        e.target.value,
                                      );
                                    }}
                                  />
                                </td>{" "}
                                <td className="py-2 px-4 border-b">
                                  <input
                                    type="number"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 px-3  "
                                    value={row.week3}
                                    onChange={(e) => {
                                      handleChangeValue(
                                        index,
                                        `week3`,
                                        e.target.value,
                                      );
                                    }}
                                  />
                                </td>
                                <td className="py-2 px-4 border-b">
                                  <input
                                    type="number"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 px-3  "
                                    value={row.week4}
                                    onChange={(e) => {
                                      handleChangeValue(
                                        index,
                                        `week4`,
                                        e.target.value,
                                      );
                                    }}
                                  />
                                </td>
                                <td className="py-2 px-4 border-b">
                                  <div className="flex justify-center items-center space-x-3">
                                    {row.ld_q === row.sum ? (
                                      <BsCheckCircleFill className="text-green-800" />
                                    ) : (
                                      <RxCross2 className="text-red-800" />
                                    )}{" "}
                                    <p>{row.sum}</p>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-2">
                {isUpdate ? (
                  <button
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Save
                  </button>
                )}
              </div>
            </form>
    </>
  );
};

export default LuckyDrawSetup;
