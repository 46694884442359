import { rootApi } from "../../api/rootApi";


export const vendorThemeApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        fetchVendorsTheme: builder.query({
            query: (token) => {
                return {
                method: 'GET',
                url: '/admin/vendorTheme',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
            }}
        }),
        addVendorTheme : builder.mutation({
            query: (params) => {
                return {
                method: 'POST',
                url: '/admin/vendorTheme/add',
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + params.token,
                },
                body : JSON.stringify(params.body)
            }}
        }),
        updateVendorTheme : builder.mutation({
            query: (params) => {
                return {
                method: 'PUT',
                url: `/admin/vendorTheme/${params.params}`,
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + params.token,
                },
                body : JSON.stringify(  params.body)
            }}
        }),

        fetchVendorsThemeByName : builder.mutation({
            query: (params) => {
                return {
                method: 'GET',
                url: `/admin/vendorTheme/${params.params}`,
                headers: {
                    "Content-Type": "application/json",
                },
            }}
        }),
        
    })

});
export const { useFetchVendorsThemeQuery , useAddVendorThemeMutation , useUpdateVendorThemeMutation , useFetchVendorsThemeByNameMutation } = vendorThemeApi;