import { rootApi } from "../../api/rootApi";

export const giftApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllCatalogues: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/giftCatalogue`,
            // url: `/tenant/giftCatalogue/?limit=${params.limit}&&offset=${params.offset}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
    fetchCatalogueById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/giftCatalogue/${params.id}`,
          // url: `/tenant/giftCatalogue/${params.id}?limit=5&offset=1`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchCatalogueByType: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/giftCatalogue/type?type=${params.type}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateAllCatalogueById: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/giftCatalogue/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateStatusOfCatalogueById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/giftCatalogue/status/${params.id}?status=${params.status}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addCatalogue: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/giftCatalogue/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateCatalogue: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/giftCatalogue/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteCatalogue: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/giftCatalogue/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
 useAddCatalogueMutation,
 useFetchAllCataloguesMutation,
 useFetchCatalogueByIdMutation,
 useFetchCatalogueByTypeMutation,
 useUpdateCatalogueMutation,
 useUpdateStatusOfCatalogueByIdMutation,
 useDeleteCatalogueMutation,
 useUpdateAllCatalogueByIdMutation
} = giftApi;
