import React, { useEffect } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../../utils/hasPermission";
import { getVendorName } from "../../../../utils/getVendorName";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";

const DynamicCoupon = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    // const hasViewPermission = hasPermission("redeemedCoupons");

    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    // fetchCouponRedemptions({
    //   token: sessionStorage.getItem("access_token"),
    //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    //   limit: 100,
    //   offset : 0
    // });
  }, []);

//   useEffect(() => {
//     if (couponRedemptionsList) {
//       console.log(couponRedemptionsList);
//     }
//   }, [couponRedemptionsList, couponRedemptionsError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "10%",
      textAlign: "left",
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
     
    },
    {
      name: "Coupon Code",
      selector: (row) => row.coupon_code,
      sortable: true,
    
    },
    {
      name: "Coupon Type ",
      selector: (row) => row.type,
      sortable: true,
      
    },
    {
      name: "User ",
      selector: (row) => row.app_user_name,
      sortable: true,
     
    },
    {
      name: "User Type ",
      selector: (row) => row.user_type,
      sortable: true,
     
    },
    {
      name: "Reddem Date",
      selector: (row) => row.redeem_date.slice(0,10),
      sortable: true,
     
    },
    {
      name: "Expire Date",
      selector: (row) => row.expire_date.slice(0,10),
      sortable: true,
     
    },
    // {
    //   name: "Category",
    //   selector: (row) =>
    //     row.category.map((cat) => (
    //       <span className="mr-1 text-gray-600 ">{getCategoryName(cat)}</span>
    //     )),
    //   sortable: true,
    // },
    
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
  .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Dynamic Coupon Provider
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // data={couponRedemptionsList && couponRedemptionsList.body.data}
                // loading={couponRedemptionsIsLoading}
                // totalRows={couponRedemptionsList && couponRedemptionsList.body.length}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              >
             
              </DataTableComponent>
              {true && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicCoupon;
