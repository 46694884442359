import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { getVendorName } from "../../../utils/getVendorName";
import { useCreateMembershipMutation } from "../../../features/tenant/membership/membershipApi";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import { AiFillDelete } from "react-icons/ai";
import { useUpdateMembershipByIdMutation } from "../../../features/tenant/membership/membershipApi";

const rangeTypes = [
  {
    id: 1,
    name: "Purchase",
  },
  {
    id: 2,
    name: "Scan",
  },
  {
    id: 3,
    name: "QrMrp",
  },
  {
    id: 4,
    name: "Points",
  },
];
const gradients = [
  "bg-gradient-to-r from-orange-600 to-orange-400",
  "bg-gradient-to-r from-rose-600 to-rose-400",
  "bg-gradient-to-r from-gray-500 to-gray-400",
  "bg-gradient-to-r from-indigo-500 to-indigo-400",
  "bg-gradient-to-r from-blue-500 to-blue-400",
  "bg-gradient-to-r from-blue-500 to-blue-400",
];

const AddMembership = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: -1,
    name: "Select User",
  });

  const [
    getAppUserTypes,
    {
      data: appUserTypesData,
      isLoading: appUserTypesIsLoading,
      error: appUserTypesIsError,
    },
  ] = useGetAppUserTypesMutation();

  useEffect(() => {
    if (location.state) {
      console.log("location.state", location.state);
      setIsUpdate(true);

      setStartDate(location.state.start_date);
      setEndDate(location.state.end_date);

      setSelectedRangeType(rangeTypes[Number(location.state.range_type) - 1]);

      setTiers(location.state.tiers);

      appUserTypesData?.body.map((userType) => {
        if (userType.user_type == location.state.user_type) {
          setSelectedUser(userType);
        }
      });
    }
  }, [location, appUserTypesData]);

  useEffect(() => {
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  

  const [
    addMembership,
    {
      data: addMembershipData,
      error: addMembershipError,
      isLoading: addMembershipisLoading,
    },
  ] = useCreateMembershipMutation();

  const [
    updateMembership,
    {
      data: updateMembershipData,
      error: updateMembershipError,
      isLoading: updateMembershipisLoading,
    },
  ] = useUpdateMembershipByIdMutation();

  const [selectedRangeType, setSelectedRangeType] = useState({
    id: -1,
    name: "Select Range Type",
  });
  const [tierName, setTierName] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [tiers, setTiers] = useState([]);

  const addTier = (e) => {
    e.preventDefault();

    if (!tierName) {
      toast.error("Name must be provided", { id: "noName" });
      return;
    }

    const updatedTiers = tiers.map((tier) => {
      return { ...tier, id: tier.id + 1 };
    });

    const newTier = { id: 1, name: tierName };
    updatedTiers.unshift(newTier);

    setTiers(updatedTiers);
    setTierName(null);
  };

  function removeTierByIndex(index) {
    const arr = [...tiers];
    if (index >= 0 && index < arr.length) {
      arr.splice(index, 1);
    }

    const updatedTiers = arr.map((tier, idx) => {
      return {
        ...tier,
        id: idx + 1,
      };
    });

    setTiers(updatedTiers);
  }

  useEffect(() => {
    if (addMembershipData) {
      toast.success("Membership created successfully", {
        id: "membershipCreated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/membership`);
      }, 400);
    }

    if (addMembershipError) {
      if (addMembershipError.status == 409) {
        toast.error("Membership already exists for this user type", {
          id: "addMembershipErrorAlreadyExists",
        });
      } else {
        toast.error("Something went wrong", { id: "addMembershipError" });
      }
      return;
    }
  }, [addMembershipData, addMembershipError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedUser);

    if (selectedUser.id == -1) {
      toast.error("Select user..", {
        id: "noUserSelected",
      });
      return;
    }

    if (selectedRangeType.id == -1) {
      toast.error("Select range type..", {
        id: "noRangeSelected",
      });
      return;
    }

    if (tiers.length == 0) {
      toast.error("Make some tiers..", {
        id: "noTierMade",
      });
      return;
    }

    if (!startDate) {
      toast.error("Please provide Start date..", {
        id: "startDate",
      });
      return;
    }

    if (!endDate) {
      toast.error("Please provide End date..", {
        id: "endDate",
      });
      return;
    }
    if (startDate > endDate) {
      toast.error("End Date must be greater than Start Date..", {
        id: "invalidEndDate",
      });
      return;
    }

    addMembership({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        user_type_id: selectedUser.user_type_id,
        user_type: selectedUser.user_type,
        range_type: selectedRangeType.id,
        tiers: tiers,
        start_date: startDate,
        end_date: endDate,
      },
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log(selectedUser);

    if (selectedUser.id == -1) {
      toast.error("Select user..", {
        id: "noUserSelected",
      });
      return;
    }

    if (selectedRangeType.id == -1) {
      toast.error("Select range type..", {
        id: "noRangeSelected",
      });
      return;
    }

    if (tiers.length == 0) {
      toast.error("Make some tiers..", {
        id: "noTierMade",
      });
      return;
    }

    if (!startDate) {
      toast.error("Please provide Start date..", {
        id: "startDate",
      });
      return;
    }

    if (!endDate) {
      toast.error("Please provide End date..", {
        id: "endDate",
      });
      return;
    }

    if (startDate > endDate) {
      toast.error("End Date must be greater than Start Date..", {
        id: "invalidEndDate",
      });
      return;
    }

    updateMembership({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        user_type_id: selectedUser.user_type_id,
        user_type: selectedUser.user_type,
        range_type: selectedRangeType.id,
        // tiers: tiers,
        start_date: startDate,
        end_date: endDate,
      },
      id: location.state.id,
    });
  };

  useEffect(() => {
    if (updateMembershipData) {
      toast.success("Membership Updated", {
        id: "membershipUpdated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/membership`);
      }, 400);
    }

    if (updateMembershipError) {
      toast.error("Something went wrong", { id: "updateMembershipError" });

      return;
    }
  }, [updateMembershipData, updateMembershipError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add New "} Membership
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 w-full bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="flex justify-start space-x-3">
                    <div className="mb-6 w-1/3 dropds">
                      {appUserTypesData && appUserTypesData.body && (
                        <SelectComponent
                          title={"User Type"}
                          placeholder={"Select user types..."}
                          selected={selectedUser}
                          setSelected={setSelectedUser}
                          dataArr={appUserTypesData.body}
                        />
                      )}
                    </div>
                    <div className="mb-6 w-1/3 dropds">
                      <SelectComponent
                        title={"Range on ...."}
                        selected={selectedRangeType}
                        setSelected={setSelectedRangeType}
                        dataArr={rangeTypes}
                      />
                    </div>
                    <div className="mb-6 w-1/5">
                      <InputComponent
                        name={"Start Date"}
                        type={"date"}
                        required={true}
                        value={startDate}
                        setValue={setStartDate}
                      />
                    </div>
                    <div className="mb-6 w-1/6">
                      <InputComponent
                        name={"End Date"}
                        type={"date"}
                        required={true}
                        value={endDate}
                        setValue={setEndDate}
                      />
                    </div>

                    {isUpdate ? (
                      <> </>
                    ) : (
                      <div className="mb-6 w-1/3 flex space-x-2 justify-center items-center">
                        <InputComponent
                          name={"Tier"}
                          type={"text"}
                          value={tierName ? tierName : ""}
                          setValue={setTierName}
                          required={true}
                        />

                        <div className="mt-auto">
                          <button
                            onClick={(e) => addTier(e)}
                            className="text-white text-xs bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-4 py-1.5 text-center "
                          >
                            Add Tier
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex justify-start space-x-3">
                    <div className="mb-6 min-w-fit flex-wrap flex space-x-2 justify-start items-start">
                      {tiers &&
                        tiers.length > 0 &&
                        tiers.map((each, idx) => (
                          <div
                            key={idx}
                            className={`relative border text-white m-2 px-4 py-2 rounded shadow-lg ${
                              gradients[each.id - 1]
                            }`}
                          >
                            <span
                              className="absolute bg-white text-red-600 -top-1 p-0.5 rounded-xl -right-1 cursor-pointer"
                              onClick={() => removeTierByIndex(idx)}
                            >
                              <AiFillDelete className="w-4 h-4" />
                            </span>
                            <div className="font-semibold">
                              Rank : {each.id}
                            </div>
                            <div className="font-semibold">
                              Tier : {each.name}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center">
                  {isUpdate ? (
                    <button
                      type=""
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type=""
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMembership;
