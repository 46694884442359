import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetchVendorsQuery } from "../../../features/admin/vendor/vendorApi";
import { useGetAllTenantDashboardsMutation } from "../../../features/admin/tenantDashboard/tenantDashboardApi";
import {
  useAddTenantWebDashboardMutation,
  useGetTenantWebDashboardByTenantMutation,
} from "../../../features/admin/tenantWebDashboard/tenantWebDashboardApi";
import { useNavigate } from "react-router-dom";
const AddTenantWebDashboard = () => {
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [dashboardSections, setDashboardSections] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const [isTanentAdded, setIsTanentAdded] = useState(false);
  const navigate=useNavigate()

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    vendorsList.forEach(function (data) {
      if (data.company_id == event.target.value) {
        setSelectedVendorData(data);
      }
    });
  };

  useEffect(() => {
    console.log(selectedItems, "Here is the array");
  }, [selectedItems]);
  const CheckBoxList = ({ dataArray }) => {
    const handleCheckboxChange = (itemName) => {
      if (selectedItems.includes(itemName)) {
        setSelectedItems(selectedItems.filter((name) => name !== itemName));
      } else {
        setSelectedItems([...selectedItems, itemName]);
      }
    };

    const checkboxes = dataArray.map((item) => (
      <div key={item.id} className="flex items-center gap-2 whitespace-nowrap border rounded-sm p-2">
        <input
          type="checkbox"
          id={`checkbox_${item.name}`}
          checked={selectedItems.includes(item.name)}
          onChange={() => handleCheckboxChange(item.name)}
        />
        <label
          htmlFor={`checkbox_${item.name}`}
          className={selectedItems.includes(item.name) ? "font-bold m-0" : "m-0"}
        >
          {item.name}
        </label>
      </div>
    ));

    return (
      <div className="flex flex-wrap align-middle gap-2">{checkboxes}</div>
    );
  };
  const {
    data: vendorsData,
    isError: vendorsIsError,
    isLoading: vendorsIsLoading,
    error: vendorsError,
  } = useFetchVendorsQuery(sessionStorage.getItem("access_token")) || {};

  const [
    getAllTenantDashboards,
    {
      data: getAllTenantDashboardsData,
      isError: getAllTenantDashboardsIsError,
      isLoading: getAllTenantDashboardsIsLoading,
      error: getAllTenantDashboardsError,
    },
  ] = useGetAllTenantDashboardsMutation();

  const [
    AddTenantWebDashboard,
    {
      data: AddTenantWebDashboardData,
      isError: AddTenantWebDashboardIsError,
      isLoading: AddTenantWebDashboardIsLoading,
      error: AddTenantWebDashboardError,
    },
  ] = useAddTenantWebDashboardMutation();

  const [
    GetTenantWebDashboardByTenant,
    {
      data: GetTenantWebDashboardByTenantData,
      isError: GetTenantWebDashboardByTenantIsError,
      isLoading: GetTenantWebDashboardByTenantIsLoading,
      error: GetTenantWebDashboardByTenantError,
    },
  ] = useGetTenantWebDashboardByTenantMutation();

  useEffect(() => {
    if (GetTenantWebDashboardByTenantData?.body) {
      const isEmptyObject = (obj) => Object.keys(obj).length === 0;

      if (isEmptyObject(GetTenantWebDashboardByTenantData?.body)) {
        setIsTanentAdded(false);
      } else {
        setIsTanentAdded(true);
      }
    }
  }, [GetTenantWebDashboardByTenantData]);

  const getTenantWebDashboard = () => {
    if (selectedVendorData?.tenant_id) {
      GetTenantWebDashboardByTenant({
        id: selectedVendorData.tenant_id,
        token: sessionStorage.getItem("access_token"),
      });
    }
  };

  useEffect(() => {
    getTenantWebDashboard();
  }, [selectedVendorData]);

  const addTenantWebDashboardFun = () => {
    if (isTanentAdded) {
      toast.error("Dashboard already exists for this Tenant");
      return;
    }
    if (!selectedVendorData) {
      toast.error("Please select a vendor");
      return;
    }
    if (!selectedItems.length) {
      toast.error("Please select an Item");
      return;
    }
    var body = {
      tenant_id: selectedVendorData.tenant_id,
      tenant_name: selectedVendorData.name,
      graphs: selectedItems,
    };
    AddTenantWebDashboard({
      token: sessionStorage.getItem("access_token"),
      body: body,
    });
  };

  useEffect(() => {
    getAllTenantDashboards({
      token: sessionStorage.getItem("access_token"),
    });
  }, []);

  useEffect(() => {
    if (getAllTenantDashboardsData?.body) {
      setDashboardSections(getAllTenantDashboardsData?.body);
    }
  }, [getAllTenantDashboardsData]);

  useMemo(() => {
    if (vendorsData) {
      (() => {
        setVendorsList(vendorsData.body);
      })();
    }
    if (vendorsError) {
      toast.error(vendorsError.error, {
        id: "vendorsError",
      });
    }
  }, [vendorsData, vendorsError]);

  useEffect(() => {
    if (AddTenantWebDashboardData) {
      toast.success("Web Dashboard Added Successfully", {
        id: "dashboardSuccess",
      });
      navigate(-1)
    }
    if (AddTenantWebDashboardIsError) {
      toast.error("error in Adding web Dashboard", {
        id: "dashboardError",
      });
    }
  }, [AddTenantWebDashboardData, AddTenantWebDashboardIsError]);

  return (
    <div className="page-content">
      <div className="card p-4">
      <div className="">
        <label className="text-capitalize font-weight-bold">
          Choose Client
        </label>
        <select
          className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black p-2.5 h-auto"
          value={selectedVendor}
          onChange={handleVendorChange}
        >
          <option value={0}>Select Client</option>
          {vendorsList &&
            vendorsList.map((vendor) => (
              <option key={vendor.id} value={vendor.company_id}>
                {vendor.name}
              </option>
            ))}
        </select>
      </div>

      <div className="justify-center my-2 mt-3">
       
        {dashboardSections.length > 0 && selectedVendor && !isTanentAdded ? (
          <div>
             <label className="text-capitalize font-weight-bold">
          {!isTanentAdded && "Choose Section"}
        </label>
            <CheckBoxList dataArray={dashboardSections} />
          </div>
        ) : dashboardSections.length > 0 && selectedVendor && isTanentAdded ? (
          "Dashboard Already Added"
        ) : null}
      </div>

      <button
        className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0 w-24 py-2.5"
        onClick={() => addTenantWebDashboardFun()}
      >
        Submit
      </button>
    </div></div>
  );
};

export default AddTenantWebDashboard;
