import { rootApi } from "../api/rootApi";

export const dreamGiftsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDreamGiftsSelectedByUser: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/dreamGifts/selected`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
  }),
});

export const { useGetAllDreamGiftsSelectedByUserMutation } = dreamGiftsApi;
