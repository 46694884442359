import { rootApi } from "../../api/rootApi";

export const uomValuesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUomValues: builder.mutation({
      query: (token) => {
        return {
          method: "GET",
          url: "/admin/uomValues",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
      },
    }),
    addUomValues: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/admin/uomValues/add",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),

    fetchUomValueById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/uomValues/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
        };
      },
    }),

    updateUomValueById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/uomValues/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),

    deleteUomValueById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/uomValues/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
        };
      },
    }),
  }),
});
export const {
  useAddUomValuesMutation,
  useFetchUomValueByIdMutation,
  useFetchUomValuesMutation,
  useUpdateUomValueByIdMutation,
  useDeleteUomValueByIdMutation,
} = uomValuesApi;
