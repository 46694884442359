import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllVerificationRecordsMutation,
  useGetAllVerificationRecordsStatsMutation,
} from "../../../features/tenant/VerificationRecords/VerificationRecordsApi";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { AiOutlineEdit } from "react-icons/ai";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";

const verificationTypeArray = [
  {
    name: "all",
    id: 0,
  },
  {
    name: "pan",
    id: 2,
  },

  {
    name: "gstin",
    id: 3,
  },
  {
    name: "cashfree_authorization",
    id: 4,
  },
  {
    name: "upi",
    id: 5,
  },
  {
    name: "bank",
    id: 6,
  },
  {
    name: "upi_from_mobile",
    id: 7,
  },
  {
    name: "aadhaar_send_otp",
    id: 8,
  },
  {
    name: "aadhaar_verify_otp",
    id: 9,
  },
  {
    name: "payment_transfer",
    id: 10,
  },
];
const prices = {
  pan: 1.5,
  gstin: 2.5,
  aadhaar_verify_otp: 2.5,
  upi: 2.5,
  bank: 1,
  upi_from_mobile: 2,
  payment_transfer: 5,
};
const ozonePrices = {
  pan: 1.25,
  gstin: 2,
  aadhaar_verify_otp: 2.5,
  upi: 2.5,
  bank: 1,
  upi_from_mobile: 2,
  payment_transfer: 5,
};


const VerificationRecords = () => {
  const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;
  const location = useLocation();
  const navigate = useNavigate();

  const [
    GetAllVerificationRecordsStats,
    {
      data: GetAllVerificationRecordsStatsData,
      error: GetAllVerificationRecordsStatsError,
      isLoading: GetAllVerificationRecordsStatsIsLoading,
      isError: GetAllVerificationRecordsStatsIsError,
    },
  ] = useGetAllVerificationRecordsStatsMutation();

  const [
    getAllVerificationRecords,
    {
      data: getAllVerificationRecordsData,
      error: getAllVerificationRecordsError,
      isLoading: getAllVerificationRecordsIsLoading,
      isError: getAllVerificationRecordsIsError,
    },
  ] = useGetAllVerificationRecordsMutation();
  const [
    getAllVerificationRecordsCSV,
    {
      data: getAllVerificationRecordsDataCSV,
      error: getAllVerificationRecordsErrorCSV,
      isLoading: getAllVerificationRecordsIsLoadingCSV,
      isError: getAllVerificationRecordsIsErrorCSV,
    },
  ] = useGetAllVerificationRecordsMutation();

  console.log("tennat",tenantId);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  const [prevPage, setPrevPage] = useState(0);
  const [totalPrices, setTotalPrices] = useState(0);

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const [verificationType, setVerificationType] = useState({
    name: "all",
    id: null,
  });
  const [showModal1, setShowModal] = useState(false);

  const [remarks, setRemarks] = useState();
  const initialRequest = {
    verification_type: "all",
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);

  useEffect(() => {
    GetAllVerificationRecordsStats({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body : {
        verification_type: verificationType,
        startDate : request.startDate,
        endDate : request.endDate,
      }
    });
  }, [request , verificationType]);

  useEffect(() => {
    if(getAllVerificationRecordsData){
      
      setMyList(getAllVerificationRecordsData.body && getAllVerificationRecordsData.body.data ? getAllVerificationRecordsData.body.data : []);
      setTotalRows(getAllVerificationRecordsData.body && getAllVerificationRecordsData.body.total ? getAllVerificationRecordsData.body.total : 0);
    }
  }, [getAllVerificationRecordsData ]);

  useEffect(() => {
    if (GetAllVerificationRecordsStatsData) {
      const amount = GetAllVerificationRecordsStatsData.body.reduce(
        (total, x) => {
          console.log(total, " -->");
          if ( tenantId != 7 && Object.keys(prices).includes(x.verification_type)) {
            total += Number(prices[x.verification_type]) * Number(x.stats);
          }else if (tenantId == 7 && Object.keys(ozonePrices).includes(x.verification_type)) {
            console.log("8t943257234757");
            total += Number(ozonePrices[x.verification_type]) * Number(x.stats);
          }
          return total;
        },
        0
      );
      console.log("amount", amount);
      setTotalPrices(amount);
    }
    if (GetAllVerificationRecordsStatsError) {
    }
  }, [GetAllVerificationRecordsStatsData, GetAllVerificationRecordsStatsError]);

  useEffect(() => {
    fetchAppUserByUserTypeFunction();
    // fetchAppUserByUserTypeCsvFunction();
  }, [location.pathname, limit, offset]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const fetchAppUserByUserTypeFunction = () => {
    request["limit"] = limit;
    request["offset"] = offset;
    request["verification_type"] = verificationType;
    getAllVerificationRecords({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchAppUserByUserTypeCsvFunction = () => {
    request["verification_type"] = verificationType;
    request["limit"] = null;
    request["offset"] = null;

    getAllVerificationRecordsCSV({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if(getAllVerificationRecordsDataCSV && getAllVerificationRecordsDataCSV?.body?.data){
      setCsvData(getAllVerificationRecordsDataCSV?.body?.data);
    }

    if (getAllVerificationRecordsErrorCSV) {
      setCsvData(null);
    }
  }, [getAllVerificationRecordsDataCSV , getAllVerificationRecordsErrorCSV])


  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {
    if (request.startDate === "" && request.endDate === currentDate) {
      fetchAppUserByUserTypeFunction();
      // fetchAppUserByUserTypeCsvFunction();
    }
  }, [request, verificationType]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Verification Type",
      cell: (row) => <div>{row.verification_type}</div>,
      sortable: true,
    },
    {
      name: "API End Point",
      cell: (row) => row.api_end_point,
      sortable: true,
    },
    {
      name: "Api Type",
      cell: (row) => row.api_type,
      sortable: true,
    },
    {
      name: "Request Body",
      cell: (row) => JSON.stringify(row.request_body),
      sortable: true,
    },
    {
      name: "Verification Vendor",
      cell: (row) => row.verification_vendor,
      sortable: true,
    },
    {
      name: "Value",
      cell: (row) => (row.value.value ? row.value.value : ""),
      sortable: true,
    },
    {
      name: "API Response ",
      cell: (row) =>
        JSON.stringify(row.api_response) &&
        JSON.stringify(row.api_response).length > 100
          ? JSON.stringify(row.api_response).slice(0, 100)
          : JSON.stringify(row.api_response),
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => row.status,
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Verification Records and Stats
            <LinksComponent />
          </div>

          <div className="p-3 ">
            <div className="flex justify-between">
              {/* {getAllVerificationRecordsData &&
                getAllVerificationRecordsData?.body?.data && (
                  <ExportCSV
                    data={
                      getAllVerificationRecordsDataCSV &&
                      getAllVerificationRecordsDataCSV?.body?.data
                    }
                    columnsToExport={[
                      "name",
                      "email",
                      "mobile",
                      "user_type",
                      "gender",
                      "city",
                      "pincode",
                      "district",
                      "state",
                    ]}
                  />
                )} */}


<div className="mb-2">
                  <CustomExport fetchData={fetchAppUserByUserTypeCsvFunction} data={csvData} defaultColumnExport={[
                       "name",
                       "email",
                       "mobile",
                       "user_type",
                       "gender",
                       "city",
                       "pincode",
                       "district",
                       "state",
                  ]} 
                    filename="verification_records"
                    />
                </div>


              <div className="font-bold">
                <button
                  onClick={() => setshowModal(!showModal)}
                  className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                  <AiOutlineEdit className="mr-1" />
                  View Stats
                </button>
              </div>
            </div>

            {showModal && (
              <ModalComponent
                size={"md"}
                show={showModal}
                setShow={setShowModal}
              >
                <div className="w-full p-4">
                  <h4 className="pophd">Stats</h4>
                  <div className="table-responsive">
                    <table className="table tablepop">
                      <tr>
                        <th>API</th>
                        <th>Hits</th>
                        <th>per hit cost</th>
                        <th>total amount</th>
                      </tr>
                      {GetAllVerificationRecordsStatsData &&
                        GetAllVerificationRecordsStatsData.body.length > 0 &&
                        GetAllVerificationRecordsStatsData.body.map((x) => {
                          return (
                            <>
                              {Object.keys(prices).includes(
                                x.verification_type
                              ) ? (
                                <tr>
                                  <td>{x.verification_type}</td>
                                  <td>{x.stats}</td>
                                  <td>{ tenantId == 7 ?ozonePrices[x.verification_type]  : prices[x.verification_type]}</td>
                                  <td>
                                    {Number( tenantId == 7 ?ozonePrices[x.verification_type]  : prices[x.verification_type]) *
                                      Number(x.stats)}
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{totalPrices}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </ModalComponent>
            )}

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 ">
                  <div className="w-52 "></div>
                  <div className="w-52 ">
                    <div className="flex-col slc_dvd topslt">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        User Types
                      </label>
                      <div className="relative searchin">
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={verificationType}
                          setSelected={setVerificationType}
                          dataArr={verificationTypeArray}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>

                    <div className="flex flex-row  gap-2 ">
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                          onClick={() => {
                            fetchAppUserByUserTypeFunction();
                            // fetchAppUserByUserTypeCsvFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3">

                {getAllVerificationRecordsIsLoading &&  <CircleLoaderComponent/>}
                <DataTableComponent
                  columns={columns}
                  data={
                    myList
                  }
                  totalRows={
                    totalRows
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationRecords;
