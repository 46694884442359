import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const EditProgramOption = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [optionType, setOptionType] = useState("");
  const [optionName, setOptionName] = useState("");
  const [optionId, setOptionId] = useState(0);

  useEffect(() => {
    const option = location.search && location.search.slice(1);
    const data = option.split("=");
    if (data[0] && data[1]) {
      setOptionType(data[0]);
      setOptionId(data[1]);
      setOptionName(location.state ? location.state : "" );
    }
  }, [location]);

  const addOption = () => {
    if (optionId !== 0 && optionName && optionName.length > 0) {
      const data = {
        option_name: optionName,
      };
      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/admin/${optionType}/${optionId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.success) {
            toast.success(response.data.message, {
              id: "UpdateOptionSuccess",
            });

            setTimeout(() => {
              navigate("/program");
            }, 500);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(
            error.response?.data?.message
              ? error.response.data.message
              : error.message,
            {
              id: "UpdateOptionError",
            },
          );
        });
    } else {
      toast.error("Please enter a valid option type", {
        id: "notValidOption",
      });
    }
  };

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit {optionType} Option</h4>

              <div className="cmxform" id="signupForm">
                <div className="form-group row">
                  <div className="col-md-6 mt-3">
                    <input
                      id="name"
                      className="form-control"
                      value={optionName}
                      onChange={(e) => {
                        setOptionName(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 mt-3">
                    <button
                      type="submit"
                      className="btn btn-outline-primary mr-2 w-100"
                      onClick={addOption}
                    >
                      Update
                    </button>
                  </div>
                  <div className="col-md-2 mt-3">
                    <button
                      type="cancel"
                      className="btn btn-outline-danger mr-2 w-100"
                      onClick={() => navigate("/program")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProgramOption;
