import React, { useEffect, useState } from "react";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import { useAddWheelCampaignMutation } from "../../../features/tenant/wheelCampaign/wheelCampaignApi";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { States } from "../../../utils/getStates";
import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";

const type = [
  {
    id: 1,
    name: "Not Applicable",
  },
  {
    id: 2,
    name: "Before Spin",
  },
  {
    id: 3,
    name: "After Spin",
  },
 
];

const WheelCampaign = () => {
  const [
    addWheelCampaign,
    {
      data: addWheelCampaignData,
      isLoading: addWheelCampaignIsLoading,
      isError: addWheelCampaignIsError,
      error: addWheelCampaignError,
    },
  ] = useAddWheelCampaignMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (addWheelCampaignData) {
      toast.success("Wheel Campaign Added Successfully !!");
      navigate(-1);
    }
    if (addWheelCampaignError) {
      toast.error("Error in Creating Wheel Campaign");
    }
  }, [addWheelCampaignData, addWheelCampaignError]);

  const handleAddWheelCampaign = (e) => {
    e.preventDefault();

    if (!title) {
      toast.error("Please enter the Title", {
        id: "imageError",
      });
      return;
    }
    if (!description) {
      toast.error("Please enter the Description", {
        id: "imageError",
      });
      return;
    }

    const finalStates = states.map( (st) => st.name);

    addWheelCampaign({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        title: title,
        description: description,
        start_date: startDate,
        end_date: endDate,
        budget: budget,
        states: finalStates,
        feedback_form : selectedType.id,
        form_template : JSON.stringify(elements),
      },
    });
  };

  const [selectedType, setSelectedType] = useState(type[0]);
  const [elements, setElements] = useState([]);
  const [lableName, setLableName] = useState("");
  const [name, setName] = useState("");
  const [inputLength, setInputLength] = useState("");
  const [inputType, setInputType] = useState("");
  const [required, setRequired] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [selectOption, setSelectOptions] = useState([]);
  const [newUserValue, setNewUserValue] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleNewElementAddition = () => {
      let newElementObj = {
        label: lableName,
        name: name,
        type: inputType,
        required: required,
        maxLength: inputLength,
        options: selectOption,
      };

      let oldOptions = elements;
      oldOptions.push(newElementObj);
      setElements(oldOptions);
      console.log("added");

      console.log(oldOptions);
    };
    if (lableName !== "" && inputType !== "" && inputLength !== "") {
      handleNewElementAddition();
      setName("");
      setLableName("");
      setInputLength("");
      setRequired(false);
      setInputType("");
    }
  }, [reRender]);

  const handleInputTypeChange = (e) => {
    setInputType(e.target.value);
  };

  const removeFromSelectOptions = (e, key) => {
    console.log(key);
    let oldValues = [...selectOption];
    oldValues = oldValues.filter((user) => user != key);
    setSelectOptions(oldValues);
  };

  const handleDeleteElement = (e, eIdx) => {
    let oldElements = elements;
    oldElements = oldElements.filter((ele, idx) => idx !== eIdx);
    setElements(oldElements);
    setReRender((old) => !old);
  };

  const allStates = States;
  const [states, setStates] = useState([allStates[0]]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState(0);

  return (
    <div className="flex w-full bg-slate-200 h-full justify-center">
      <div className="w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Add Wheel Camapign
          <LinksComponent />
        </div>
        <div className="p-3">
          <form className="p-3 w-full bg-white shadow rounded-lg">
            <div className="w-full  flex justify-center space-x-2">
              <div className="mb-2 w-1/2">
                <div className="w-full">
                  <InputComponent
                    name={"Title"}
                    type={"text"}
                    value={title}
                    setValue={setTitle}
                    required={true}
                  />
                </div>
              </div>

              <div className="mb-2 w-1/2">
                <div className="w-full mltselect">
                  <MultiSelectComponent
                    title={"Excluded States*"}
                    selected={states}
                    setSelected={setStates}
                    dataArr={allStates}
                  />
                </div>
              </div>
            </div>

            <div className="w-full  flex justify-center space-x-4">
              <div className="mb-2 w-1/3">
                <div className="w-full">
                  <InputComponent
                    name={"Start Date"}
                    type={"date"}
                    value={startDate}
                    setValue={setStartDate}
                    required={true}
                    maxLength={200}
                  />
                </div>
              </div>

              <div className="mb-2 w-1/3">
                <div className="w-full">
                  <InputComponent
                    name={"End Date"}
                    type={"date"}
                    value={endDate}
                    setValue={setEndDate}
                    required={true}
                    maxLength={200}
                  />
                </div>
              </div>

              <div className="mb-2 w-1/3">
                <div className="w-full">
                  <InputComponent
                    name={"Budget"}
                    type={"number"}
                    value={budget}
                    setValue={setBudget}
                    required={true}
                  />
                </div>
              </div>

              <div className="mb-2 w-1/3 ">
                <SelectComponent
                  title={"Feedback Form"}
                  selected={selectedType}
                  setSelected={setSelectedType}
                  dataArr={type}
                  required={true}
                />
              </div>
            </div>

            <div>
              <div className="w-full flex justify-center ">
                <div className="mb-2 w-full">
                  <InputComponent
                    name={"Description"}
                    type={"textarea"}
                    value={description}
                    setValue={setDescription}
                    required={true}
                    maxLength={200}
                  />
                </div>
              </div>

              { (selectedType.id == 2 || selectedType.id == 3) && (
                <div>
                  <div className="border-gray-300 shadow-sm">
                    <div className="row">
                      <div>
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={open}
                          onClose={handleClose}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                        >
                          <Fade in={open}>
                            <Box sx={style}>
                              <Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Preview
                              </Typography>
                              {elements.map((element, idx) => {
                                return element.type === "select" ? (
                                  <div className="col-md-12 my-2" key={idx}>
                                    <div className="d-flex my-1">
                                      <span className="p-2">
                                        {element.label}{" "}
                                      </span>
                                    </div>
                                    <select className="my-2 py-1">
                                      <option value={"Select Options"}>
                                        Select Options
                                      </option>

                                      {element.options.map((opt) => (
                                        <option value={opt}>{opt}</option>
                                      ))}
                                    </select>
                                  </div>
                                ) : (
                                  <div className="col-md-12 my-2" key={idx}>
                                    <div className="d-flex my-1">
                                      <span className="p-2">
                                        {element.label}{" "}
                                      </span>
                                    </div>
                                    <input
                                      className="form-control"
                                      type={element.type}
                                      maxLength={element.maxLength}
                                      placeholder={element.label}
                                      required={element.required}
                                    />
                                  </div>
                                );
                              })}
                            </Box>
                          </Fade>
                        </Modal>
                      </div>

                      <div className="mt-2 col-lg-12 grid-margin stretch-card chng_crd">
                        <div className="">
                          <h2 className="text-capitalize font-bold text-lg p-2">
                            Add Feedback Form
                          </h2>
                          <div className="card-body">
                            <div className="cmxform " id="feedbackForm">
                              <div className="form-group ">
                                <div>
                                  <label className="text-capitalize font-semibold">
                                    New Input
                                  </label>
                                </div>
                                <div className="d-flex form_feed ">
                                  <label>
                                    <input
                                      type="text"
                                      className="border p-2 wdt"
                                      placeholder="Add Name"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </label>
                                  <label>
                                    <input
                                      type="text"
                                      className="border p-2 wdt"
                                      placeholder="Add Label Name"
                                      value={lableName}
                                      onChange={(e) =>
                                        setLableName(e.target.value)
                                      }
                                    />
                                  </label>
                                  <label>
                                    <input
                                      type="number"
                                      className="border p-2 wdt1"
                                      placeholder="Max Length"
                                      value={inputLength}
                                      onChange={(e) =>
                                        setInputLength(e.target.value)
                                      }
                                    />
                                  </label>
                                  <label>
                                    <select
                                      className="border p-2 wdt"
                                      value={inputType}
                                      onChange={(e) => handleInputTypeChange(e)}
                                    >
                                      <option value={""}>Select Type</option>
                                      <option value={"text"}>Text</option>
                                      <option value={"textarea"}>
                                        Textarea
                                      </option>
                                      <option value={"number"}>Number</option>
                                      <option value={"date"}>Date</option>
                                      <option value={"file"}>File</option>
                                      <option value={"checkbox"}>
                                        Checkbox
                                      </option>
                                      <option value={"select"}>Options</option>
                                    </select>
                                  </label>

                                  {inputType === "select" ? (
                                    <label>
                                      {selectOption.map((key) => (
                                        <label key={key}>
                                          <span
                                            className="px-2 py-2 mx-2 btn btn-outline-danger"
                                            onClick={(e) =>
                                              removeFromSelectOptions(e, key)
                                            }
                                          >
                                            {key}
                                          </span>
                                        </label>
                                      ))}

                                      <input
                                        type="text"
                                        className="border p-2 wdt1"
                                        placeholder="Add new option"
                                        value={newUserValue}
                                        onChange={(e) =>
                                          setNewUserValue(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                          if (e.keyCode == 13) {
                                            let oldUserTypes = [
                                              ...selectOption,
                                            ];
                                            oldUserTypes.push(newUserValue);
                                            setSelectOptions(oldUserTypes);
                                            setNewUserValue("");
                                          }
                                        }}
                                      />
                                    </label>
                                  ) : (
                                    <label></label>
                                  )}
                                  <label className="d-flex">
                                    <input
                                      type="checkbox"
                                      className="px-1 py-1"
                                      checked={required ? "checked" : ""}
                                      onChange={() => setRequired(!required)}
                                    />{" "}
                                    <span className="px-1 py-1 ">Required</span>
                                  </label>

                                  <button
                                    type="button"
                                    className="btn btn-outline-primary mtms"
                                    onClick={() => {
                                      setReRender(!reRender);
                                    }}
                                  >
                                    Add
                                  </button>
                                </div>

                                {elements.map((element, idx) => {
                                  return element.type === "select" ? (
                                    <div className="my-2 show_wt" key={idx}>
                                      <div className="d-flex my-1 justify-between">
                                        <span className="text_show">
                                          {element.label}{" "}
                                        </span>
                                        <span
                                          className="close_show text-danger"
                                          onClick={(e) =>
                                            handleDeleteElement(e, idx)
                                          }
                                        >
                                          x
                                        </span>{" "}
                                      </div>
                                      <select className="selctws ">
                                        <option value={"Select Options"}>
                                          Select Options
                                        </option>

                                        {element.options.map((opt) => (
                                          <option value={opt}>{opt}</option>
                                        ))}
                                      </select>
                                    </div>
                                  ) : (
                                    <div className="show_wt my-2" key={idx}>
                                      <div className="d-flex my-1 justify-between">
                                        <span className="text_show">
                                          {element.label}{" "}
                                        </span>
                                        <span
                                          className="close_show text-danger"
                                          onClick={(e) =>
                                            handleDeleteElement(e, idx)
                                          }
                                        >
                                          x
                                        </span>
                                      </div>

                                      <input
                                        className="form-control ckbox_show"
                                        type={element.type}
                                        maxLength={element.maxLength}
                                        placeholder={element.label}
                                        required={element.required}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                onClick={(e) => handleAddWheelCampaign(e)}
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WheelCampaign;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
