import { rootApi } from "../../api/rootApi";


export const formTemplateApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        fetchIndustries: builder.mutation({
            query: (token) => {
                return {
                method: 'GET',
                url: '/admin/industry',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
            }}
        }),
        addIndustry: builder.mutation({
            query: (params) => {
        
                return {
                method: 'POST',
                url: '/admin/industry/add',
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + params.token,
                },
                body : JSON.stringify(params.body)
            }}
        }),
        
    })

});
export const { useFetchIndustriesMutation , useAddIndustryMutation } = formTemplateApi;