import { rootApi } from "../../api/rootApi";


export const messageTemplateApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({

        getSmsTemplateWithLimitOffset: builder.mutation({
            query: (params) => {
              return {
                method: "GET",
                url: `/admin/smsTemplate/?limit=${params.limit}&offset=${params.offset}${params.account_id ? `&account_id=${params.account_id}` : ""}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                },
              };
            },
          }),
        addSmsTemplate: builder.mutation({
            query: (params) => {
              return {
                method: "POST",
                url: `/admin/smsTemplate/add`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
          updateSmsTemplateStatus: builder.mutation({
            query: (params) => {
              console.log("params",params);
              return {
                method: "PATCH",
                url: `/admin/smsTemplate/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                },
                body: params.body,
              };
            },
          }),
        updateSmsTemplate: builder.mutation({
            query: (params) => {
              return {
                method: "PUT",
                url: `/admin/smsTemplate/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
        deleteSmsTemplate: builder.mutation({
            query: (params) => {
              return {
                method: "DELETE",
                url: `/admin/smsTemplate/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                },              };
            },
          }),
        
    })

});
export const { useAddSmsTemplateMutation ,useGetSmsTemplateWithLimitOffsetMutation ,useUpdateSmsTemplateMutation ,useDeleteSmsTemplateMutation,useUpdateSmsTemplateStatusMutation } = messageTemplateApi;