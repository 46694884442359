import React, { useEffect, useState } from "react";
import { useFetchAppUserMutation, useUpdateKycStatusMutation } from "../../../features/tenant/appUsers/appUsersApi";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import member from "../../../assests/tenant/images/member.svg";

import copy from "../../../assests/tenant/images/copy.svg";

import view from "../../../assests/tenant/images/view.svg";
import hide from "../../../assests/tenant/images/hide.svg";
import imageSvg from "../../../assests/tenant/images/addhar.svg";
import greenCheck from "../../../assests/tenant/images/green-check.svg";

import MyVouchers from "../../../assests/tenant/images/MyVouchers.png";
import Cashback from "../../../assests/tenant/images/Cashback.png";
import EarnedPoints from "../../../assests/tenant/images/EarnedPoints.png";
import Spin from "../../../assests/tenant/images/Spin.png";

import { useGetBankDetailByAppUserIdMutation } from "../../../features/tenant/appUserBankDetails/appUserBankApi";
import { useGetAllRedeemedCouponsMutation } from "../../../features/tenant/couponRedemption/couponRedemptionApi";
import {

  useFetchUserPointsHistoryMutation,
  useFetchUserPointsMutation,
} from "../../../features/tenant/rewardify/pointsScanHistory/pointsScanHistoryApi";
import {
  useFetchCashbackRedemptionsOfUserMutation,
  useFetchUserCashbackByAppUserIdMutation,
} from "../../../features/tenant/cashBackRedemption/cashBackRedemptionApi";
import {
  useGetAllWheelHistoryMutation,
  useGetWheelsDetailsMutation,
  useGetWheelsHistoryByUserIdMutation,
} from "../../../features/tenant/wheel/wheelApi";

import { useFetchGiftsRedemptionsOfUserMutation } from "../../../features/tenant/gift/giftApi";
import { useGetAllDreamGiftsSelectedByUserMutation } from "../../../features/DreamGift/DreamGiftApi";
import { useCheckActiveMembershipForUserMutation } from "../../../features/tenant/membership/membershipApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useGetFormTemplateMutation } from "../../../features/tenant/formTemplate/formTemplateApi";

import { toast } from "react-hot-toast";
import { useGetLocationByPincodeMutation } from "../../../features/tenant/pincode/pincodeApi";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";
import { useUpdateProfileMutation } from "../../../features/tenant/appUserProfile/appProfileApi";
import { useGetMappingDetailsByAppUserIdMutation } from "../../../features/tenant/userMapping/userMappingApi";
import { getVendorName } from "../../../utils/getVendorName";
import { useFetchAllCashTransactionsByAppUserIDMutation } from "../../../features/tenant/cashTransactions/cashTransactionsApi";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import { useGetExtraPointsDataMutation } from "../../../features/tenant/rewardify/pointsScanHistory/extraPointsScanHistoryApi";


const UserProfile = () => {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [value, setValue] = React.useState("one");
  const [redemption, setRedemption] = useState([]);
  const [entryPoints, setEntryPoints] = useState([]);
  const [copied, setCopied] = useState(false);
  const [limitOne, setLimitOne] = useState(10);
  const [offsetOne, setOffsetOne] = useState(0);
  const [prevPageOne, setPrevPageOne] = useState(0);
  const [limitTwo, setLimitTwo] = useState(10);
  const [offsetTwo, setOffsetTwo] = useState(0);
  const [prevPageTwo, setPrevPageTwo] = useState(0);
  const [limitThree, setLimitThree] = useState(10);
  const [offsetThree, setOffsetThree] = useState(0);
  const [prevPageThree, setPrevPageThree] = useState(0);
  const [limitFour, setLimitFour] = useState(10);
  const [offsetFour, setOffsetFour] = useState(0);
  const [prevPageFour, setPrevPageFour] = useState(0);
  const [limitFive, setLimitFive] = useState(10);
  const [offsetFive, setOffsetFive] = useState(0);
  const [prevPageFive, setPrevPageFive] = useState(0);
  const [limitSix, setLimitSix] = useState(10);
  const [offsetSix, setOffsetSix] = useState(0);
  const [prevPageSix, setPrevPageSix] = useState(0);

  const [limitSeven, setLimitSeven] = useState(10);
  const [offsetSeven, setOffsetSeven] = useState(0);
  const [prevPageSeven, setPrevPageSeven] = useState(0);
  const [extraPointsData, setExtraPointsData] = useState([]);

  const [limitEight, setLimitEight] = useState(10);
  const [offsetEight, setOffsetEight] = useState(0);
  const [prevPageEight, setPrevPageEight] = useState(0);

  const [limitNine, setLimitNine] = useState(10);
  const [offsetNine, setOffsetNine] = useState(0);
  const [prevPageNine, setPrevPageNine] = useState(0);

  const [showAadhar, setShowAadhar] = useState(false);
  const [showPan, setShowPan] = useState(false);
  const [showGSTIN, setShowGSTIN] = useState(false);
  const [activeTab, setActiveTab] = useState("bank");

  const [showModal, setShowModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const [showAadharImage, setShowAadharImage] = useState(false);
  const [showPanImage, setShowPanImage] = useState(false);
  const [showGstinImage, setShowGstinImage] = useState(false);

  const navigate = useNavigate();

  const handleToggleAadhar = () => {
    setShowAadhar(!showAadhar);
  };

  const handleTogglePan = () => {
    setShowPan(!showPan);
  };

  const handleToggleGSTIN = () => {
    setShowGSTIN(!showGSTIN);
  };

  const handlePageChangeOne = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitOne;
    setOffsetOne(newOffset);
    setPrevPageOne(pageNumber - 1);
  };

  const handlePerRowsChangeOne = (newPerPage, page) => {
    setLimitOne(newPerPage);
  };

  const handlePageChangeTwo = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitTwo;
    setOffsetTwo(newOffset);
    setPrevPageTwo(pageNumber - 1);
  };

  const handlePerRowsChangeTwo = (newPerPage, page) => {
    setLimitTwo(newPerPage);
  };

  const handlePageChangeThree = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitThree;
    setOffsetThree(newOffset);
    setPrevPageThree(pageNumber - 1);
  };

  const handlePerRowsChangeThree = (newPerPage, page) => {
    setLimitThree(newPerPage);
  };

  const handlePageChangeFour = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitFour;
    setOffsetFour(newOffset);
    setPrevPageFour(pageNumber - 1);
  };

  const handlePerRowsChangeFour = (newPerPage, page) => {
    setLimitFour(newPerPage);
  };

  const handlePageChangeFive = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitFive;
    setOffsetFive(newOffset);
    setPrevPageFive(pageNumber - 1);
  };

  const handlePerRowsChangeFive = (newPerPage, page) => {
    setLimitFive(newPerPage);
  };

  const handlePageChangeSix = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitSix;
    setOffsetSix(newOffset);
    setPrevPageSix(pageNumber - 1);
  };

  const handlePerRowsChangeSix = (newPerPage, page) => {
    setLimitSix(newPerPage);
  };

  const handlePageChangeSeven = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitSeven;
    setOffsetSeven(newOffset);
    setPrevPageSeven(pageNumber - 1);
  };

  const handlePerRowsChangeSeven = (newPerPage, page) => {
    setLimitSeven(newPerPage);
  };

  const handlePageChangeEight = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitEight;
    setOffsetEight(newOffset);
    setPrevPageEight(pageNumber - 1);
  };

  const handlePerRowsChangeEight = (newPerPage, page) => {
    setLimitEight(newPerPage);
  };

  const handlePageChangeNine = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limitNine;
    setOffsetNine(newOffset);
    setPrevPageNine(pageNumber - 1);
  };

  const handlePerRowsChangeNine = (newPerPage, page) => {
    setLimitNine(newPerPage);
  };

  const handleCopyToClipboard = () => {
    const textToCopy = profileData?.referral_code;

    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);

        // Reset copied status after 1.5 seconds
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      });
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [fetchAppUserProfile, { data: appUserData, error: appUserError }] =
    useFetchAppUserMutation();

  const [
    updateKycStatus,
    {
      data: updateKycStatusData,
      error: updateKycStatusError,
      isLoading: updateKycStatusIsLoading,
      isError: updateKycStatusIsError,
    },
  ] = useUpdateKycStatusMutation();


  const [
    fetchBankDetailByAppUserIdProfile,
    {
      data: fetchBankDetailByAppUserIdData,
      error: fetchBankDetailByAppUserIdError,
    },
  ] = useGetBankDetailByAppUserIdMutation();

  const [
    fetchAllRedeemedCoupons,
    { data: fetchAllRedeemedCouponsData, error: fetchAllRedeemedCouponsError },
  ] = useGetAllRedeemedCouponsMutation();

  const [
    fetchUserPointsHistory,
    { data: fetchUserPointsHistoryData, error: fetchUserPointsHistoryError },
  ] = useFetchUserPointsHistoryMutation();

  const [
    fetchUserPoints,
    { data: fetchUserPointsData, error: fetchUserPointsError },
  ] = useFetchUserPointsMutation();
  const [
    fetchUserCashbackByAppUserId,
    {
      data: fetchUserCashbackByAppUserIdData,
      isLoading: fetchUserCashbackByAppUserIdIsLoading,
      isError: fetchUserCashbackByAppUserIdIsError,
      error: fetchUserCashbackByAppUserIdError,
    },
  ] = useFetchUserCashbackByAppUserIdMutation();

  const [
    fetchAllDreamGiftsSelectedByUser,
    {
      data: fetchAllDreamGiftsSelectedByUserData,
      isLoading: fetchAllDreamGiftsSelectedByUserIsLoading,
      isError: fetchAllDreamGiftsSelectedByUserIsError,
      error: fetchAllDreamGiftsSelectedByUserError,
    },
  ] = useGetAllDreamGiftsSelectedByUserMutation();

  const [
    GetAllWheelHistory,
    { data: getAllWheelHistoryData, error: getAllWheelHistoryError },
  ] = useGetAllWheelHistoryMutation();

  const [
    getAllWheelHistoryByUserId,
    {
      data: getAllWheelHistoryByUserIdData,
      error: getAllWheelHistoryByUserIdError,
    },
  ] = useGetWheelsHistoryByUserIdMutation();

  const [
    fetchAllCashTransactions,
    { data: transactionsData, error: transactionsError },
  ] = useFetchAllCashTransactionsByAppUserIDMutation();

  const [
    GetWheelsDetails,
    { data: getWheelsDetailsData, error: getWheelsDetailsError },
  ] = useGetWheelsDetailsMutation();

  const [
    getExtraPointsFunc,
    {
      data: getExtraPointsData,
      error: getExtraPointsError,
      isLoading: getExtraPointsIsLoading,
      isError: getExtraPointsIsError,
    },
  ] = useGetExtraPointsDataMutation();

  const [
    fetchCashbackRedemptionsOfUser,
    {
      data: fetchCashbackRedemptionsOfUserData,
      isLoading: fetchCashbackRedemptionsOfUserIsLoading,
      isError: fetchCashbackRedemptionsOfUserIsError,
      error: fetchCashbackRedemptionsOfUserError,
    },
  ] = useFetchCashbackRedemptionsOfUserMutation();

  const [
    FetchGiftsRedemptionsOfUser,
    {
      data: fetchGiftsRedemptionsOfUserData,
      isLoading: fetchGiftsRedemptionsOfUserIsLoading,
      isError: fetchGiftsRedemptionsOfUserIsError,
      error: fetchGiftsRedemptionsOfUserError,
    },
  ] = useFetchGiftsRedemptionsOfUserMutation();

  const [
    checkMembershipStatus,
    {
      data: membershipStatusData,
      isLoading: membershipStatusIsLoading,
      error: membershipStatusError,
    },
  ] = useCheckActiveMembershipForUserMutation();

  //Mapping Details
  const [
    getAppUserMappingDetails,
    { data: appUserMappingDetailsData, error: appUserMappingDetailsError },
  ] = useGetMappingDetailsByAppUserIdMutation();

  // console.log("userProfile ---------->", profileData);

  useEffect(() => {
    if (appUserMappingDetailsData) {
      // console.log("appUserMappingDetailsData", appUserMappingDetailsData);
    }

    if (appUserMappingDetailsError) {
      console.log("appUserMappingDetailsError", appUserMappingDetailsError);
    }
  }, [appUserMappingDetailsData, appUserMappingDetailsError]);
  // getAllWheelHistoryByUserId

  const updateKycStatusFunc = (type) => {
    if (!window.confirm(`Are you sure you want to update ${type.toUpperCase()} status to verified ?`)) {
      return;
    }

    toast.loading("Updating Kyc Status", { id: "loading-update-kyc-status" });

    const bodyData = { ...profileData };

    bodyData[`is_valid_${type}`] = true;
    bodyData[`${type}`] = profileData[type];
    bodyData["type"] = "tenant-manual-kyc";
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;

    // console.log("updateKycStatus bodyData", bodyData);

    updateKycStatus({
      body: bodyData,
      tenant_id: tenantId,
      id: profileData.id,
    });
  }

  useEffect(() => {
    if (updateKycStatusData && updateKycStatusData.body) {
      toast.dismiss();
      toast.success("Kyc Status Updated", { id: "kycStatusSuccess" });
      // console.log("updateKycStatusData", updateKycStatusData.body);
      location.state = updateKycStatusData.body;
      setProfileData(updateKycStatusData.body);
    }

    if (updateKycStatusError) {
      console.log("updateKycStatusError", updateKycStatusError)
    }
  }, [updateKycStatusData, updateKycStatusError])


  const getAllWheelHistoryByUserIdFun = () => {
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;
    const token = sessionStorage.getItem("access_token");
    const userId = profileData.id;

    getAllWheelHistoryByUserId({
      tenant_id: tenantId,
      token,
      user_type_id: userId,
      limit: limitSix,
      offset: offsetSix,
    });
  };

  const getAllExtraPointsByUserIdFun = () => {
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;
    const token = sessionStorage.getItem("access_token");
    const userId = profileData.id;

    getExtraPointsFunc({
      tenant_id: tenantId,
      token,
      id: userId,
      limit: limitSix,
      offset: offsetSix,
    });
  };

  const fetchUserPointsHistoryFun = () => {
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;
    const token = sessionStorage.getItem("access_token");
    const userId = profileData.id;

    console.log("object", limitOne, offsetOne);
    fetchUserPointsHistory({
      tenant_id: tenantId,
      token,
      userId,
      limit: limitOne ? limitOne : 20,
      offset: offsetOne ? offsetOne : 0,
    });
  };

  const FetchGiftsRedemptionsOfUserFun = () => {
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;
    const token = sessionStorage.getItem("access_token");
    const userId = profileData.id;

    FetchGiftsRedemptionsOfUser({
      tenant_id: tenantId,
      token,
      userId,
      type: "1",
      limit: limitTwo,
      offset: offsetTwo,
    });
  };

  const FetchCouponRedemptionsOfUserFun = () => {
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;
    const token = sessionStorage.getItem("access_token");
    const userId = profileData.id;

    FetchGiftsRedemptionsOfUser({
      tenant_id: tenantId,
      token,
      userId,
      type: "3",
      limit: limitNine,
      offset: offsetNine,
    });
  };

  const fetchCashbackRedemptionsOfUserFun = () => {
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;
    const token = sessionStorage.getItem("access_token");
    const userId = profileData.id;

    fetchCashbackRedemptionsOfUser({
      tenant_id: tenantId,
      token,
      userId,
      type: "2",
      limit: limitThree,
      offset: offsetThree,
    });
  };

  useEffect(() => {
    if (ids.length) {
      GetWheelsDetails({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,

        data: { id: ids },
      });
    }
  }, [ids]);

  useEffect(() => {
    if (getWheelsDetailsData?.body) {
      setCardData(getWheelsDetailsData.body);
    }
  }, [getWheelsDetailsData]);

  useEffect(() => {
    if (getAllWheelHistoryData?.body?.length) {
      const idArray = [];
      const historyArray = [];
      for (let i = 0; i < getAllWheelHistoryData.body.length; i++) {
        idArray.push(getAllWheelHistoryData.body[i].w_id);
        historyArray.push(getAllWheelHistoryData.body[i]);
      }
      setIds(idArray);
    }
  }, [getAllWheelHistoryData]);

  const fetchAllReedemedCouponsFun = () => {
    fetchAllRedeemedCoupons({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        id: profileData.id,
        limit: limitFive,
        offset: offsetFive,
      },
    });
  };

  const fetchUserPointsFun = () => {
    fetchUserPoints({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      userId: profileData.id,
    });
  };

  const fetchfetchUserPointsHistoryFun = () => {
    fetchUserPointsHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      userId: profileData.id,
      limit: limitOne ? limitOne : 20,
      offset: offsetOne ? offsetOne : 0,
    });
  };

  const [profileData, setProfileData] = useState(null);
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [editProfileData, setEditProfileData] = useState(profileData);

  useEffect(() => {
    setEditProfileData(profileData);
  }, [profileData]);

  useEffect(() => {
    const data = location.state;
    // console.log("location user datata", data);
    // setProfileData(data);
    if (profileData == null && data && data?.id) {
      toast.loading("Fetching User Profile", { id: "loading-user-profile" })
      fetchAppUserProfile({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        type: "tenant",
        app_user_id: data?.id
      });
    }
  }, []);

  const fetchUserBankDetailfun = () => {
    fetchBankDetailByAppUserIdProfile({
      id: profileData.id,
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };
  useEffect(() => {
    if (fetchUserPointsHistoryData?.body?.data) {
      const sortedEntryPoints = [...fetchUserPointsHistoryData.body.data].sort(
        (a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        }
      );
      setEntryPoints(sortedEntryPoints);
    }
  }, [fetchUserPointsHistoryData]);

  const formatUTCDateToDDMMYY = (date) => {
    const utcDate = new Date(date);
    const day = utcDate.getUTCDate().toString().padStart(2, "0");
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = utcDate.getUTCFullYear().toString().slice(-2);

    return day + "-" + month + "-" + year;
  };

  useEffect(() => {
    if (fetchGiftsRedemptionsOfUserData?.body?.userPointsRedemptionList) {
      const sortedRedemption = [
        ...fetchGiftsRedemptionsOfUserData.body.userPointsRedemptionList,
      ].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });
      setRedemption(sortedRedemption);
    }
  }, [fetchGiftsRedemptionsOfUserData]);

  useEffect(() => {
    if (membershipStatusData && membershipStatusData.body) {
      console.log("membership data", membershipStatusData);
    }
  }, [membershipStatusData]);

  useEffect(() => {
    if (profileData?.id) {
      fetchUserBankDetailfun();
      fetchUserPointsFun();
      fetchfetchUserPointsHistoryFun();
      fetchAllReedemedCouponsFun();
      fetchUserCashbackByAppUserId({
        app_user_id: profileData.id,
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });

      GetAllWheelHistory({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        data: {
          id: profileData.id,
        },
      });

      getAppUserMappingDetails({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: profileData.id,
      });

      fetchAllCashTransactions({
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        token: sessionStorage.getItem("access_token"),
        limit: limitSeven,
        offset: offsetSeven,
        app_user_id: profileData.id,
      });

      // fetchAllCouponRedemtions({
      //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      //   token: sessionStorage.getItem("access_token"),
      //   limit: limitNine,
      //   offset: offsetNine,
      //   app_user_id: profileData.id,
      // });
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData?.id && value === "one") {
      fetchUserPointsHistoryFun();
    }
  }, [limitOne, offsetOne, profileData, value]);

  useEffect(() => {
    if (profileData?.id && value === "two") {
      FetchGiftsRedemptionsOfUserFun();
    }
  }, [limitTwo, offsetTwo, profileData, value]);

  useEffect(() => {
    if (profileData?.id && value === "nine") {
      FetchCouponRedemptionsOfUserFun();
    }
  }, [limitNine, offsetNine, profileData, value]);

  useEffect(() => {
    if (profileData?.id && value === "three") {
      fetchCashbackRedemptionsOfUserFun();
    }
  }, [limitThree, offsetThree, profileData, value]);

  useEffect(() => {
    if (profileData?.id && value === "four") {
      fetchAllDreamGiftsSelectedByUser({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        data: {
          id: profileData.id,
          limit: limitFour,
          offset: offsetFour,
        },
      });
    }
  }, [limitFour, offsetFour, profileData, value]);

  useEffect(() => {
    if (profileData?.id && value === "five") {
      fetchAllReedemedCouponsFun();
    }
  }, [limitFive, offsetFive, profileData, value]);

  useEffect(() => {
    if (profileData?.id && value === "six") {
      getAllWheelHistoryByUserIdFun();
    }
  }, [limitSix, offsetSix, profileData, value]);

  useEffect(() => {
    if (profileData?.id && value === "eight") {
      getAllExtraPointsByUserIdFun();
    }
  }, [limitEight, offsetEight, profileData, value]);

  useEffect(() => {
    if (appUserData && appUserData.body) {
      toast.dismiss();
      // console.log("APP USER DATA", appUserData.body);
      toast.success("Profile Fetched", { id: "success-profile-fetch" })
      setProfileData(appUserData.body);

    }

    if (appUserError) {
      toast.dismiss();
      toast.error("Unable to fetch profile.", { id: "error-profile-unable-to-fetch" })
      console.log("APP USER ERROR", appUserError);
    }
  }, [appUserData, appUserError]);

  const columnsOne = [
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
    },
    {
      name: "Batch Running Code ",
      cell: (row) => row.batch_running_code,
      sortable: true,
    },
    {
      name: "Claim Date",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Method",
      cell: (row) => row.method,
      sortable: true,
    },
    {
      name: "Points",
      cell: (row) => `+${row.points}`,
      sortable: true,
    },
  ];

  const columnsTwo = [
    {
      name: "Product",
      cell: (row) =>
        row.gift.gift && row.gift.gift.map((each) => each.name).join(", "),
      sortable: true,
    },
    {
      name: "Redeem Date",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Redeemed Points",
      cell: (row) => `-${row.points}`,
      sortable: true,
    },
  ];

  const columnsThree = [
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
    },
    {
      name: "CashBack",
      cell: (row) => row.cashbacks,
      sortable: true,
    },
    {
      name: "Claim Date",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const columnsFour = [
    {
      name: "Brand",
      cell: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Category",
      cell: (row) => row.category,
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Current",
      cell: (row) => row.current,
      sortable: true,
    },
    {
      name: "Target",
      cell: (row) => row.target,
      sortable: true,
    },
    {
      name: "Start Date",
      cell: (row) => formatUTCDateToDDMMYY(row.start_at),
      sortable: true,
    },
    {
      name: "End Date",
      cell: (row) => formatUTCDateToDDMMYY(row.end_at),
      sortable: true,
    },
  ];

  const columnsFive = [
    {
      name: "Brand Name",
      cell: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Coupon Code",
      cell: (row) => row.coupon_code,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "Redeem Date",
      cell: (row) => new Date(row.redeem_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "Expire Date",
      cell: (row) => new Date(row.expire_date).toLocaleString(),
      sortable: true,
    },
  ];

  const columnsSix = [
    {
      name: "Name",
      cell: (row) => row.app_user_name,
      sortable: true,
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
    },

    {
      name: "Wheel Id",
      cell: (row) => row.w_id,
      sortable: true,
    },
    {
      name: "Wheel Campaign",
      cell: (row) => row.wc_id,
      sortable: true,
    },
    {
      name: "Won At",
      cell: (row) => new Date(row.updated_at).toLocaleString(),
      sortable: true,
    },
  ];

  const columnsSeven = [
    {
      name: "Cash",
      cell: (row) => row.cash,
      sortable: true,
    },
    {
      name: "Remarks  ",
      selector: (row) => row.remarks,
    },
    {
      name: "Transfer ID  ",
      selector: (row) => row.transfer_id,
    },
    {
      name: "Transfer Mode  ",
      selector: (row) => row.transfer_mode,
    },
    {
      name: "Panel Action",
      selector: (row) =>
        row.approval_status == "3"
          ? "Pending"
          : row.approval_status == "1"
            ? "Accepted"
            : "Rejected",
    },
    {
      name: "Bank Status",
      selector: (row) =>
        row.approval_status == "1"
          ? row.status == "2"
            ? "Pending"
            : row.approval_status == "1"
              ? "success"
              : "Rejected"
          : row.approval_status == "3"
            ? "Pending At Panel"
            : "Rejected",
    },
    {
      name: "utr",
      selector: (row) => row.utr,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },

  ];

  const columnsEight = [
    {
      name: "Type",
      cell: (row) => row.cause.type,
      sortable: true,
    },
    {
      name: "Points  ",
      selector: (row) => row.points,
    },
    {
      name: "state",
      selector: (row) => row.state,
    },
    {
      name: "pincode ",
      selector: (row) => row.pincode,
    },
    {
      name: "area ",
      selector: (row) => row.area,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const columnsNine = [
    {
      name: "Ref Number",
      cell: (row) => row.ref_no,
      sortable: true,
    },
    {
      name: "Brand Product Code",
      cell: (row) => row.brand_product_code,
      sortable: true,
    },
    {
      name: "Denomination",
      cell: (row) => row.denomination,
      sortable: true,
    },
    {
      name: "Approval Status",
      cell: (row) => (
        <span>
          {row.approval_status == "1" && "Pending"}
          {row.approval_status == "2" && "Accepted"}
          {row.approval_status == "3" && "Rejected"}
          {row.approval_status == "4" && "Check Status"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Remarks",
      cell: (row) => `${row.remarks}`,
      sortable: true,
    },
    {
      name: "Redeemed Points",
      cell: (row) => `${row.points}`,
      sortable: true,
    },
    {
      name: "Redeem Date",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const [imagesData, setImagesData] = useState([]);
  const [
    uploadImages,
    {
      data: uploadImagesData,
      error: uploadImagesError,
      isError: uploadImagesIsError,
      isLoading: uploadImagesIsLoading,
    },
  ] = useUploadSingleFileMutation();

  useEffect(() => {
    if (uploadImagesData) {
      // console.log( "Upload Image data" ,uploadImagesData);
      const arr = [];
      // for (let i = 0; i < uploadImagesData.body.length; i++) {
      //   const element = uploadImagesData.body[i];
      //   arr.push(element.filename);
      // }
      console.log("success image upload", uploadImagesData.body);
      arr.push(uploadImagesData.body.fileLink);

      setImagesData(arr);
      const updatedData = { ...editProfileData };

      updatedData["profile_pic"] = arr[0];

      setEditProfileData(updatedData);
    }

    if (uploadImagesError) {
      console.log("Something went wrong while uploading image");
      toast.error("Something went wrong while uploading image", {
        id: "uploadImagesError",
      });
    }
  }, [uploadImagesData, uploadImagesError]);

  const [
    getFormTemplate,
    { data: formTemplateData, error: formTemplateError },
  ] = useGetFormTemplateMutation();

  const fetchFormTemplate = (userType) => {
    getFormTemplate({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      form_type: "6",
      user_type: userType,
    });
  };

  const [templateData, setTemplateData] = useState(null);

  const [formTemplateColumns, setFormTemplateColumns] = useState([]);

  useEffect(() => {
    if (
      formTemplateData &&
      formTemplateData.body &&
      formTemplateData.body.template && profileData
    ) {
      // console.log("formTemplateData", formTemplateData);
      const arr = Object.values(formTemplateData.body.template);
      const data = [];

      const columnsArr = [];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].type.trim().toLowerCase() == "file") {
          const element = {
            ...arr[i],
            type: "file",
            value: "",
          };
          data.push(element);
        } else {
          const type = arr[i].name;
          const element = { ...arr[i], value: profileData[type] };
          console.log(element);
          data.push(element);
        }

        columnsArr.push(arr[i].name);
      }
      setFormTemplateColumns(columnsArr);
      setTemplateData(data);
    } else {
      console.log("Form Template Not Found");
      // toast.error("Form Template Not Found", { id: "formTemplateNotFound" });
      setTemplateData(null);
    }

    if (formTemplateError) {
      console.log("formTemplateError", formTemplateError);
    }
  }, [formTemplateData, formTemplateError]);

  // console.log("formTemplateColumns ", formTemplateColumns);

  const [
    getLocationByPincode,
    { data: GetLocationByPincodeData, error: GetLocationByPincodeError },
  ] = useGetLocationByPincodeMutation();

  useEffect(() => {
    if (GetLocationByPincodeData) {
      console.log("getLocationByPinc", GetLocationByPincodeData);
      toast.dismiss();
      toast.success(GetLocationByPincodeData.message, {
        id: "GetLocationByPincodeData",
      });

      if (GetLocationByPincodeData && GetLocationByPincodeData.body) {
        const updatedData = { ...editProfileData };

        updatedData["state"] = GetLocationByPincodeData.body[0].state;
        updatedData["district"] = GetLocationByPincodeData.body[0].district;

        setEditProfileData(updatedData);
      }
    }
    if (GetLocationByPincodeError) {
      toast.dismiss();
      toast.error(GetLocationByPincodeError.data.message, {
        id: "GetLocationByPincodeError",
      });
    }
  }, [GetLocationByPincodeData, GetLocationByPincodeError]);

  const handleFormTemplateValueChange = (type, value) => {
    // console.log("handleFormTemplateValueChange", profileData);

    const updatedData = { ...editProfileData };

    updatedData[type] = value;

    // console.log(updatedData);
    setEditProfileData(updatedData);
  };

  const [
    updateProfile,
    { data: updatedProfileData, error: updatedProfileError },
  ] = useUpdateProfileMutation();

  const updateUserProfile = (e) => {
    e.preventDefault();

    // const formData = new FormData();

    // for (const key in editProfileData) {
    //   if (editProfileData[key] !== null && editProfileData[key] !== "null") {
    //     formData.append(key, editProfileData[key]);
    //   }
    // }

    updateProfile({
      data: editProfileData,
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      type: "tenant",
    });
  };

  useEffect(() => {
    if (updatedProfileData && updatedProfileData.body) {
      toast.dismiss();
      toast.success("Profile Updated Sucessfully", {
        id: "updatedProfileData",
      });

      // console.log("updated profile", updatedProfileData);
      setShowModal(false);
      setProfileData(updatedProfileData.body);
    }

    if (updatedProfileError) {
      toast.error("Some error occured", { id: "updatedProfileError" });
      console.log("updatedProfileError", updatedProfileError);
    }
  }, [updatedProfileData, updatedProfileError]);

  useEffect(() => {
    if (getExtraPointsData && getExtraPointsData.body) {
      setExtraPointsData(getExtraPointsData.body.data);
    }

    if (getExtraPointsError) {
      toast.error("Some error occured", { id: "getExtraPointsError" });
      console.log("getExtraPointsError", getExtraPointsError);
    }
  }, [getExtraPointsData, getExtraPointsError]);

  const [mappingDetailsModal, setmappingDetailsModal] = useState(false);

  return (
    <>
      <div className="flex w-full bg-slate-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Profile
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className=" bg-white shadow rounded-lg">
              <div className="flex">
                <div className="cl_left w-40  border-r-2 border-r-gray-200">
                  <div className="imagbx p-3 text-center">
                    {profileData?.profile_pic ? (
                      <span className="inline-block bg-gray-100 border border-gray-300 rounded-full p-1">
                        <img
                          src={
                            profileData?.profile_pic
                              ? profileData?.profile_pic
                              : ""
                          }
                          onClick={() =>
                            setShowImageModal(
                              profileData?.profile_pic
                                ? profileData?.profile_pic
                                : ""
                            )
                          }
                          alt=""
                          className="w-32 h-32 rounded-full"
                        />
                      </span>
                    ) : (
                      <span className="inline-block bg-gray-100 border border-gray-300 rounded-full p-1">
                        <img
                          src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                          alt=""
                          className="w-32 h-32 rounded-full"
                          onClick={() =>
                            setShowImageModal(
                              "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                            )
                          }
                        />
                      </span>
                    )}

                    <h1 className="font-bold text-xl capitalize">
                      {" "}
                      {profileData?.name}
                    </h1>
                    <span className="flex text-sm text-gray-500 justify-center gap-1 mt-1 align-baseline">
                      {" "}
                      <img src={member} alt="" />
                      <h2 className="text-lg font-bold">
                        {membershipStatusData &&
                          membershipStatusData?.body &&
                          membershipStatusData.body?.tier?.name}
                      </h2>
                    </span>
                    {/* {profileData?.mobile && (
                      <span className="flex text-sm text-gray-500 justify-center gap-1 mt-1 align-baseline">
                        {" "}
                        <img src={verified} alt="" />
                        Verified Account
                      </span>
                    )} */}

                    <span className="flex text-sm text-gray-500 justify-center gap-1 mt-1 align-baseline">
                      Joined :{" "}
                      {profileData?.created_at &&
                        profileData.created_at.length > 0 &&
                        profileData?.created_at.slice(0, 10)}
                    </span>
                    <span className="flex text-sm text-blue-700 justify-center gap-1 mt-1 align-baseline">
                      Referral Code&nbsp;&nbsp;:&nbsp;&nbsp;
                      {profileData?.referral_code}{" "}
                      <img
                        src={copy}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={handleCopyToClipboard}
                      />
                      {copied && (
                        <span style={{ color: "green" }}>Copied!</span>
                      )}
                    </span>
                    <span className="flex text-sm text-black-700 justify-center gap-1 mt-1 align-baseline">
                      {profileData?.salesteam_name && (
                        <>
                          Dealer Name&nbsp;&nbsp;:&nbsp;&nbsp;
                          {profileData?.salesteam_name}
                        </>
                      )}
                    </span>
                    <span className="flex text-sm text-black-700 justify-center gap-1 mt-1 align-baseline">
                      {profileData?.profession && (
                        <>
                          Profession Name&nbsp;&nbsp;:&nbsp;&nbsp;
                          {profileData?.profession}
                        </>
                      )}
                    </span>

                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-2"
                      onClick={() => setmappingDetailsModal(true)}
                    >
                      {" "}
                      Mapping Details{" "}
                    </button>
                  </div>

                  <div className="kyc_bx border-t-2 border-gray-200 mt-2">
                    <div className="p-3">
                      <h2 className="font-bold text-base mb-2">KYC Info</h2>

                      <div className="flex justify-between items-start mb-2 space-x-2">
                        <div className="">
                          <span className="text-gray-400 text-sm  block">
                            Aadhar Number
                          </span>
                          <h5 className="flex text-black text-sm gap-1">
                            {showAadhar ? profileData?.aadhar : "*************"}
                          </h5>
                        </div>
                        <div className="flex justify-center items-center space-x-2">
                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showAadhar ? (
                              <img
                                src={view}
                                alt=""
                                onClick={handleToggleAadhar}
                              />
                            ) : (
                              <img
                                src={hide}
                                alt=""
                                onClick={handleToggleAadhar}
                              />
                            )}
                          </button>
                          <button className="bg-black p-1 rounded-sm" type="button"> <img
                            src={imageSvg}
                            alt=""
                            onClick={() => setShowAadharImage(true)}
                          /></button>

                          {
                            profileData?.is_valid_aadhar ?
                              <button className="cursor-none p-1 rounded-sm" type="button">
                                <img
                                  src={greenCheck}
                                  alt=""
                                /></button>

                              :
                              <button disabled={profileData?.aadhar && profileData.aadhar?.length > 0 ? false : true} className={`py-1.5 px-2 text-sm text-white rounded-sm bg-blue-500 disabled:bg-gray-400 ${profileData?.aadhar && profileData.aadhar?.length > 0 ? " " : " cursor-not-allowed"}`} type="button" onClick={() => updateKycStatusFunc("aadhar")}>
                                Update
                              </button>
                          }

                        </div>
                      </div>

                      {showAadharImage && (
                        <ImageModal
                          show={showAadharImage ? true : false}
                          setShow={setShowAadharImage}
                          size="md"
                          data={profileData?.aadhar_image}
                        >
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                            <img
                              src={profileData?.aadhar_image}
                              alt="kyc-aadhar-img"
                              className="h-full w-full object-cover object-center group-hover:opacity-75"
                            />
                          </div>
                        </ImageModal>
                      )}

                      <div className="flex justify-between items-center mb-2">
                        <div className="">
                          <span className="text-gray-400 text-sm  block">
                            Pan Number
                          </span>
                          <h5 className="flex text-black text-sm  gap-1">
                            {showPan ? profileData?.pan : "*************"}
                          </h5>
                        </div>
                        <div className="flex justify-center items-center space-x-2">
                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showPan ? (
                              <img
                                src={view}
                                alt=""
                                onClick={handleTogglePan}
                              />
                            ) : (
                              <img
                                src={hide}
                                alt=""
                                onClick={handleTogglePan}
                              />
                            )}
                          </button>

                          <button className="bg-black p-1 rounded-sm" type="button"> <img
                            src={imageSvg}
                            alt=""
                            onClick={() => setShowPanImage(true)}
                          /></button>

                          {
                            profileData?.is_valid_pan ?
                              <button className=" cursor-none p-1 rounded-sm" type="button"> <img
                                src={greenCheck}
                                alt=""
                              /></button>

                              :
                              <button disabled={profileData?.pan && profileData.pan?.length > 0 ? false : true} className={`py-1.5 px-2 text-sm text-white rounded-sm bg-blue-500 disabled:bg-gray-400 ${profileData?.pan && profileData.pan?.length > 0 ? " " : " cursor-not-allowed"}`} type="button" onClick={() => updateKycStatusFunc("pan")}>
                                Update
                              </button>
                          }
                        </div>
                      </div>

                      {showPanImage && (
                        <ImageModal
                          show={showPanImage ? true : false}
                          setShow={setShowPanImage}
                          size="md"
                          data={profileData?.pan_image}
                        >
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                            <img
                              src={profileData?.pan_image}
                              alt="kyc-pan-img"
                              className="h-full w-full object-cover object-center group-hover:opacity-75"
                            />
                          </div>
                        </ImageModal>
                      )}

                      <div className="flex justify-between items-center">
                        <div className="">
                          <span className="text-gray-400 text-sm  block">
                            GSTIN
                          </span>
                          <h5 className="flex text-black text-sm  gap-1">
                            {showGSTIN ? profileData?.gstin : "*************"}
                          </h5>
                        </div>
                        <div className="flex justify-center items-center space-x-2">
                          <button 
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showGSTIN ? (
                              <img
                                src={view}
                                alt=""
                                onClick={handleToggleGSTIN}
                              />
                            ) : (
                              <img
                                src={hide}
                                alt=""
                                onClick={handleToggleGSTIN}
                              />
                            )}
                          </button>
                          <button className="bg-black p-1 rounded-sm" type="button"> <img
                            src={imageSvg}
                            alt=""
                            onClick={() => setShowGstinImage(true)}
                          /></button>


                          {
                            profileData?.is_valid_gstin ?
                              <button className=" cursor-none p-1 rounded-sm" type="button"> <img
                                src={greenCheck}
                                alt=""
                              /></button>

                              :
                              <button disabled={profileData?.gstin && profileData.gstin?.length > 0 ? false : true} className={`py-1.5 px-2 text-sm text-white rounded-sm bg-blue-500 disabled:bg-gray-400 ${profileData?.gstin && profileData.gstin?.length > 0 ? " " : " cursor-not-allowed"}`} type="button" onClick={() => updateKycStatusFunc("gstin")}>
                                Update
                              </button>
                          }
                        </div>
                      </div>

                      {showGstinImage && (
                        <ImageModal
                          show={showGstinImage ? true : false}
                          setShow={setShowGstinImage}
                          size="md"
                          data={profileData?.gstin_image}
                        >
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                            <img
                              src={profileData?.gstin_image}
                              alt="kyc-gstin-img"
                              className="h-full w-full object-cover object-center group-hover:opacity-75"
                            />
                          </div>
                        </ImageModal>
                      )}


                    </div>
                  </div>
                </div>

                <div className="cl_right w-full ">
                  <div className="pl-3 pr-3  pt-3 flex justify-between items-center ">
                    <h2 className="font-bold text-base ">Basic Info</h2>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="text-white flex gap-1 items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center "
                        onClick={() => setShowModal(true)}
                      >
                        <svg
                          className="text-white"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          {" "}
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />{" "}
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                        </svg>{" "}
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Email
                        </span>

                        <a
                          href={`mailto:${profileData?.email}`}
                          className="flex text-black text-sm  gap-1"
                        >
                          {profileData?.email}
                        </a>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Mobile No
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          +91-{profileData?.mobile}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Gender / DOB
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.gender} /{" "}
                          {profileData?.dob
                            ? new Date(profileData?.dob).toLocaleDateString()
                            : ""}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          State
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.state}
                        </h5>
                      </div>


                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          District
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.district}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Pincode
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.pincode}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Address 1
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.address1}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Address 2
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.address2}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          City
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.city}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          FirmId / Firm Name
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.firm_id} / {profileData?.firm_name}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          FSR
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.fsr}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          ASM
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.asm}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="grid divide-y divide-neutral-200 ">
                    <details className="group ">
                      <summary className="p-3 flex justify-between items-center font-medium cursor-pointer list-none">
                        <h2 className="font-bold text-base ">
                          Account Details{" "}
                        </h2>
                        <span className="transition group-open:rotate-180">
                          <svg
                            fill="none"
                            height="24"
                            shape-rendering="geometricPrecision"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M6 9l6 6 6-6"></path>
                          </svg>
                        </span>
                      </summary>

                      <div className="px-3">
                        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                          <li className="mr-2">
                            <span
                              className={`inline-block px-4 py-2 rounded-t-lg ${activeTab === "bank"
                                ? "bg-blue-600 text-white"
                                : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                                }`}
                              onClick={() => setActiveTab("bank")}
                            >
                              BANK
                            </span>
                          </li>
                          <li className="mr-2">
                            <span
                              className={`inline-block px-4 py-2 rounded-t-lg ${activeTab === "upi"
                                ? "bg-blue-600 text-white"
                                : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                                }`}
                              onClick={() => setActiveTab("upi")}
                            >
                              UPI
                            </span>
                          </li>
                        </ul>
                        <div className="bankbox border border-gray-100 p-3 overflow-y-auto h-32">
                          {fetchBankDetailByAppUserIdData?.body?.length > 0 ? (
                            fetchBankDetailByAppUserIdData?.body.map((e) => {
                              if (
                                e.transfer_mode == "banktransfer" &&
                                activeTab === "bank"
                              ) {
                                return (
                                  <div className="flex items-center mb-2 border-b border-gray-200 pb-3">
                                    <input
                                      id="default-checkbox"
                                      type="checkbox"
                                      value=""
                                      checked={e.status == "1"}
                                      className="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <ul className="flex justify-between">
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          Bank Name&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_bank}
                                        </label>
                                      </li>
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          A/C&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_details?.bank_account}
                                        </label>
                                      </li>
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          Branch&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_branch}
                                        </label>
                                      </li>
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          IFSC CODE&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_details?.ifsc}
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                );
                              } else if (
                                e.transfer_mode == "upi" &&
                                activeTab === "upi"
                              ) {
                                return (
                                  <div className="flex items-center mb-2 border-b border-gray-200 pb-3">
                                    <input
                                      id="default-checkbox"
                                      type="checkbox"
                                      value=""
                                      checked={e.status == "1"}
                                      className="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <ul className="flex justify-between">
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          Upi Id&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_details?.upi_id}
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div>No data Found</div>
                          )}
                        </div>
                      </div>

                      {/* {fetchBankDetailByAppUserIdData?.body?.length > 0 &&
                      fetchBankDetailByAppUserIdData?.body[0]?.transfer_mode ==
                        "banktransfer" ? (
                        <>




                          <div className="border-b border-gray-200">
                         


                            <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  Bank Name
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_bank}
                                </h5>
                              </div>

                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  A/C Name
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_name}
                                </h5>
                              </div>

                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  A/C Number
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_details?.bank_account}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className="border-b border-gray-200">
                            <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  Branch
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_branch}
                                </h5>
                              </div>

                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  IFSC Code
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_details?.ifsc}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                UPI ID
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                {fetchBankDetailByAppUserIdData?.body?.length >
                                  0 &&
                                  fetchBankDetailByAppUserIdData?.body[0]
                                    ?.bene_details?.upi_id}
                              </h5>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </details>
                    {profileData?.user_type == "retailer" ||
                      profileData?.user_type == "distributor" ? (
                      <>
                        {/* <details className="group ">
                        <summary className="p-3 flex justify-between items-center font-medium cursor-pointer list-none">
                          <h2 className="font-bold text-base ">
                            Business Info{" "}
                          </h2>
                          <span className="transition group-open:rotate-180">
                            <svg
                              fill="none"
                              height="24"
                              shape-rendering="geometricPrecision"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </summary>
                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Your Shop/Firm Name
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Genefied
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Business Type
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Proprietorship
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Distributor
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Nikhil
                              </h5>
                            </div>
                          </div>
                        </div>
                      </details> */}
                      </>

                    ) : null}

                    {profileData?.user_type == "retailer" ||
                      profileData?.user_type == "distributor" ? (
                      <>
                        {/* <details className="group ">
                        <summary className="p-3 flex justify-between items-center font-medium cursor-pointer list-none">
                          <h2 className="font-bold text-base ">
                            Other Details{" "}
                          </h2>
                          <span className="transition group-open:rotate-180">
                            <svg
                              fill="none"
                              height="24"
                              shape-rendering="geometricPrecision"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </summary>
                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Highest Education
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Graduate
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Material Status
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Married
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                No of kids
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                4
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Hobbies
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Watch Cricket
                              </h5>
                            </div>
                          </div>
                        </div>
                      </details> */}
                      </>

                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-2 pt-3">
              <div className="bg-white shadow rounded-xl p-3 flex items-center gap-2">
                <span className="wdht  rounded-full flex justify-center items-center border border-gray-300">
                  <img src={MyVouchers} alt="" />
                </span>
                <h5 className="font-bold text-gray-700 text-xl ">
                  <span className="font-medium text-sm block">My Vouchers</span>
                  {(fetchAllRedeemedCouponsData &&
                    fetchAllRedeemedCouponsData?.body?.data &&
                    fetchAllRedeemedCouponsData?.body?.data?.length) ||
                    0}
                </h5>
              </div>

              <div className="bg-white shadow rounded-xl p-3 flex items-center gap-2">
                <span className="wdht  rounded-full flex justify-center items-center border border-gray-300">
                  <img src={Cashback} alt="" />
                </span>
                <h5 className="font-bold text-gray-700 text-xl ">
                  <span className="font-medium text-sm block">
                    Redeemable Cash
                  </span>
                  {(fetchUserCashbackByAppUserIdData &&
                    fetchUserCashbackByAppUserIdData?.body.cashback_balance) ||
                    0}{" "}
                </h5>
              </div>

              <div className="bg-white shadow rounded-xl p-3 flex items-center gap-2">
                <span className="wdht  rounded-full flex justify-center items-center border border-gray-300">
                  <img src={EarnedPoints} alt="" />
                </span>
                <h5 className="font-bold text-gray-700 text-xl ">
                  <span className="font-medium text-sm block">
                    Earned Points
                  </span>
                  {fetchUserPointsData?.body?.point_earned || 0}
                </h5>
              </div>

              <div className="bg-white shadow rounded-xl p-3 flex items-center gap-2">
                <span className="wdht  rounded-full flex justify-center items-center border border-gray-300">
                  <img src={EarnedPoints} alt="" />
                </span>
                <h5 className="font-bold text-gray-700 text-xl ">
                  <span className="font-medium text-sm block">
                    Balanced Points
                  </span>
                  {fetchUserPointsData?.body?.point_balance || 0}
                </h5>
              </div>

              <div className="bg-white shadow rounded-xl p-3 flex items-center gap-2">
                <span className="wdht  rounded-full flex justify-center items-center border border-gray-300">
                  <img src={EarnedPoints} alt="" />
                </span>
                <h5 className="font-bold text-gray-700 text-xl ">
                  <span className="font-medium text-sm block">
                    Redeemed Points
                  </span>
                  {fetchUserPointsData?.body?.point_redeemed || 0}
                </h5>
              </div>

              <div className="bg-white shadow rounded-xl p-3 flex items-center gap-2">
                <span className="wdht  rounded-full flex justify-center items-center border border-gray-300">
                  <img src={EarnedPoints} alt="" />
                </span>
                <h5 className="font-bold text-gray-700 text-xl ">
                  <span className="font-medium text-sm block">
                    Points Reserved
                  </span>
                  {fetchUserPointsData?.body?.point_reserved || 0}
                </h5>
              </div>

              <div className="bg-white shadow rounded-xl p-3 flex items-center gap-2">
                <span className="wdht  rounded-full flex justify-center items-center border border-gray-300">
                  <img src={Spin} alt="" />
                </span>
                <h5 className="font-bold text-gray-700 text-xl ">
                  <span className="font-medium text-sm block">Total Spin</span>{" "}
                  {cardData.length}
                </h5>
              </div>
            </div>

            <Box
              className="w-max tbchnge"
              style={{ width: "100%", textAlign: "left" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                centered
              >
                <Tab value="one" label="Scanned History" />
                <Tab value="two" label="Redeemed History" />
                <Tab value="three" label="Cashback Redeem History" />
                <Tab value="four" label="Target Progress" />
                {/* <Tab value="five" label="Vouchers" /> */}
                <Tab value="six" label="Wheel History" />
                <Tab value="seven" label="Cash Transfers" />
                <Tab value="eight" label="Extra Point History" />
                <Tab value="nine" label="Coupon Redemptions" />
              </Tabs>

              {value === "one" && (
                <DataTableComponent
                  columns={columnsOne}
                  data={entryPoints}
                  totalRows={fetchUserPointsHistoryData?.body?.total}
                  handlePerRowsChange={handlePerRowsChangeOne}
                  handlePageChange={handlePageChangeOne}
                />
              )}

              {value === "two" && (
                <DataTableComponent
                  columns={columnsTwo}
                  data={redemption}
                  totalRows={
                    fetchGiftsRedemptionsOfUserData?.body?.total_user_points
                  }
                  handlePerRowsChange={handlePerRowsChangeTwo}
                  handlePageChange={handlePageChangeTwo}
                />
              )}

              {value === "three" && (
                <DataTableComponent
                  columns={columnsThree}
                  data={
                    fetchCashbackRedemptionsOfUserData?.body
                      ?.userPointsRedemptionList
                  }
                  totalRows={
                    fetchCashbackRedemptionsOfUserData?.body?.total_user_points
                  }
                  handlePerRowsChange={handlePerRowsChangeThree}
                  handlePageChange={handlePageChangeThree}
                />
              )}

              {value === "four" && (
                <DataTableComponent
                  columns={columnsFour}
                  data={fetchAllDreamGiftsSelectedByUserData?.body}
                  totalRows={fetchAllDreamGiftsSelectedByUserData?.body.length}
                  handlePerRowsChange={handlePerRowsChangeFour}
                  handlePageChange={handlePageChangeFour}
                />
              )}

              {value === "five" && (
                <DataTableComponent
                  columns={columnsFive}
                  data={fetchAllRedeemedCouponsData?.body?.data}
                  totalRows={fetchAllRedeemedCouponsData?.body?.total}
                  handlePerRowsChange={handlePerRowsChangeFive}
                  handlePageChange={handlePageChangeFive}
                />
              )}

              {value === "six" && (
                <DataTableComponent
                  columns={columnsSix}
                  data={getAllWheelHistoryByUserIdData?.body?.data}
                  totalRows={getAllWheelHistoryByUserIdData?.body?.total}
                  handlePerRowsChange={handlePerRowsChangeSix}
                  handlePageChange={handlePageChangeSix}
                />
              )}

              {value === "seven" && (
                <DataTableComponent
                  columns={columnsSeven}
                  data={transactionsData?.body?.data}
                  totalRows={transactionsData?.body?.count}
                  handlePerRowsChange={handlePerRowsChangeSeven}
                  handlePageChange={handlePageChangeSeven}
                />
              )}

              {value === "eight" && (
                <DataTableComponent
                  columns={columnsEight}
                  data={extraPointsData}
                  totalRows={getExtraPointsData?.body?.total}
                  handlePerRowsChange={handlePerRowsChangeSeven}
                  handlePageChange={handlePageChangeSeven}
                />
              )}

              {value === "nine" && (
                <DataTableComponent
                  columns={columnsNine}
                  data={redemption}
                  totalRows={
                    fetchGiftsRedemptionsOfUserData?.body?.total_user_points
                  }
                  handlePerRowsChange={handlePerRowsChangeNine}
                  handlePageChange={handlePageChangeNine}
                />
              )}

              {/* getAllWheelHistoryByUserIdData */}
            </Box>
          </div>
        </div>

        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
        {showModal && (
          <ModalComponent
            size={"2xl"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
              <h1 className="flex items-center gap-1 font-semibold text-base border-b pb-2 border-gray-200">
                <svg
                  className="h-6 w-6 text-black"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  {" "}
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                  <circle cx="12" cy="7" r="4" />
                </svg>
                Edit Profile
              </h1>

              <form className="w-full  mt-3">
                <div className="grid grid-cols-2 gap-4">
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Name
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Name"
                      value={editProfileData?.name}
                      onChange={(e) =>
                        handleFormTemplateValueChange("name", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Mobile No
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Mobile No"
                      value={editProfileData?.mobile}
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Email"
                      value={editProfileData?.email}
                      onChange={(e) =>
                        handleFormTemplateValueChange("email", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Gender
                    </label>
                    <select
                      className="shadow-sm bg-gray-50 border h-auto border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      onChange={(e) =>
                        handleFormTemplateValueChange("gender", e.target.value)
                      }
                    >
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      <option value={"other"}>Other</option>
                    </select>
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      DOB
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="date"
                      placeholder="Enter DOB"
                      value={editProfileData?.dob}
                      onChange={(e) =>
                        handleFormTemplateValueChange("dob", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Pincode
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Pincode"
                      value={editProfileData?.pincode}
                      onChange={(e) => {
                        if (e.target.value.length == 6) {
                          console.log(e);
                          getLocationByPincode({
                            pincode: e.target.value,
                            token: sessionStorage.getItem("access_token"),
                            tenant_id: JSON.parse(
                              sessionStorage.getItem("vendorData")
                            ).tenant_id,
                          });
                        }
                        handleFormTemplateValueChange(
                          "pincode",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 mt-3">
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      State
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter State"
                      value={editProfileData?.state}
                      onChange={(e) =>
                        handleFormTemplateValueChange("state", e.target.value)
                      }
                    />
                  </div>
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      City
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter City"
                      value={editProfileData?.city}
                      onChange={(e) =>
                        handleFormTemplateValueChange("city", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      District
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter District"
                      value={editProfileData?.district}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "district",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-3">
                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Address"
                      value={editProfileData?.address1}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "address1",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address 2
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Address"
                      value={editProfileData?.address2}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "address2",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      User Id
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter User ID"
                      value={editProfileData?.user_id}
                      onChange={(e) =>
                        handleFormTemplateValueChange("user_id", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Password
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter password"
                      value={editProfileData?.password}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "password",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  {/* <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Assoiate Dealer
                    </label>
                    <select className="shadow-sm bg-gray-50 border h-auto border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ">
                      <option>Ajay Pumps (New Delhi)</option>
                      <option>Sanjay Pumps (Agra)</option>
                    </select>
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Linkd Employee
                    </label>
                    <select className="shadow-sm bg-gray-50 border h-auto border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ">
                      <option>Vivek </option>
                      <option>Saabir</option>
                    </select>
                  </div> */}
                </div>

                <div className="grid grid-cols-3 gap-4 mt-3">
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Firm Id
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Firm ID"
                      value={editProfileData?.firm_id}
                      onChange={(e) =>
                        handleFormTemplateValueChange("firm_id", e.target.value)
                      }
                    />
                  </div>
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Firm Name
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Firm Name"
                      value={editProfileData?.firm_name}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "firm_name",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      ASM
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter ASM"
                      value={editProfileData?.asm}
                      onChange={(e) =>
                        handleFormTemplateValueChange("asm", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      FSR
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter FSR"
                      value={editProfileData?.fsr}
                      onChange={(e) =>
                        handleFormTemplateValueChange("fsr", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="w-full  mt-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    User Profile
                  </label>
                  <input
                    className="shadow-sm bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2  "
                    type="file"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const uploadFile = new FormData();

                        uploadFile.append("image", e.target.files[0]);
                        uploadImages({
                          body: uploadFile,
                        });
                        // handleFormTemplateValueChange(
                        //   idx,
                        //   element.label,
                        //   e,
                        // );
                      }
                    }}
                  />

                  {imagesData.length > 0 &&
                    imagesData.map((img, index) => (
                      <div
                        key={index}
                        className="overflow-hidden rounded-lg mt-3 bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 w-10 h-10"
                      >
                        <img
                          src={img}
                          alt=""
                          srcset=""
                          className="max-w-full max-h-full object-cover object-center"
                        />
                      </div>
                    ))}
                </div>
                <div className="grid grid-cols-3 gap-4 mt-3">
                  {formTemplateColumns.includes("pan") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        Pan
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter Pan"
                        value={editProfileData?.pan}
                        onChange={(e) =>
                          handleFormTemplateValueChange("pan", e.target.value)
                        }
                      />
                    </div>
                  )}
                  {formTemplateColumns.includes("aadhar") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        Aadhar
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter Aadhar"
                        value={editProfileData?.aadhar}
                        onChange={(e) =>
                          handleFormTemplateValueChange(
                            "aadhar",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                  {formTemplateColumns.includes("gstin") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        GSTIN
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter GSTIN"
                        value={editProfileData?.gstin}
                        onChange={(e) =>
                          handleFormTemplateValueChange("gstin", e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="grid grid-cols-3 gap-4 mt-3">
                  {formTemplateColumns.includes("fsr") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        FSR
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter FSM"
                        value={editProfileData?.fsr}
                        onChange={(e) =>
                          handleFormTemplateValueChange("fsr", e.target.value)
                        }
                      />
                    </div>
                  )}
                  {formTemplateColumns.includes("asm") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        ASM
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter ASM"
                        value={editProfileData?.asm}
                        onChange={(e) =>
                          handleFormTemplateValueChange("asm", e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>
                {/* {templateData &&
                  templateData.map((element, idx) => {
                    return element.type === "select" ? (
                      <div className="col-md-12 my-3" key={idx}>
                        <SelectComponent
                          title={element.label}
                          selected={{ id: 0, name: `Select ${element.label}` }}
                          // setSelected={setselectedBrandName}
                          dataArr={element.options ? element.options : []}
                        />
                      </div>
                    ) : element.type === "file" ? (
                      <div className="w-full col-md-12 my-2 flex flex-col justify-center ">
                        <div className="flex flex-col items-start justify-center w-full">
                          {element.label} {element.required ? "*" : ""}
                          <label
                            for="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                          >
                            <div className="flex flex-col items-center justify-center">
                              <svg
                                aria-hidden="true"
                                className="w-20 h-20 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  strokeLinejoin="round"
                                  stroke-width="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">
                                  Click to upload
                                </span>
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500">
                                PNG or JPG (800x400px)
                              </p>
                            </div>
                            <input
                              id="dropzone-file"
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  const uploadFile = new FormData();

                                  uploadFile.append(
                                    "images",
                                    e.target.files[0],
                                  );
                                  uploadImages({
                                    body: uploadFile,
                                  });
                                  // handleFormTemplateValueChange(
                                  //   idx,
                                  //   element.label,
                                  //   e,
                                  // );
                                }
                              }}
                            />
                          </label>
                        </div>

                        <div
                          className="d-flex justify-content-center align-items-center h-100"
                          style={{ width: "150px" }}
                        >
                          {imagesData.length > 0 &&
                            imagesData.map((img, index) => (
                              <div
                                key={index}
                                className="w-100 overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 "
                              >
                                <img
                                  src={
                                    
                                    img
                                  }
                                  alt=""
                                  srcset=""
                                  className="h-full w-full object-cover object-center"
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : element.name == "pincode" ? (
                      <div className="col-md-12 my-3" key={idx}>
                        <InputComponent
                          name={element.label}
                          type={element.type}
                          maxLength={element.maxLength}
                          placeholder={element.label}
                          required={element.required}
                          value={element.value}
                          setValue={(e) => {
                            handleFormTemplateValueChange(
                              idx,
                              element.label,
                              e,
                            );

                            if (e.length == 6) {
                              // console.log(e);
                              getLocationByPincode({
                                pincode: e,
                                token: sessionStorage.getItem("access_token"),
                                tenant_id: JSON.parse(
                                  sessionStorage.getItem("vendorData"),
                                ).tenant_id,
                              });
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="col-md-12 my-3" key={idx}>
                        <InputComponent
                          name={element.label}
                          type={element.type}
                          maxLength={element.maxLength}
                          placeholder={element.label}
                          required={element.required}
                          value={element.value}
                          setValue={(e) =>
                            handleFormTemplateValueChange(idx, element.label, e)
                          }
                        />
                      </div>
                    );
                  })} */}
                <div className="flex justify-center mt-4">
                  <button
                    type="submit"
                    className="text-white flex gap-1 items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center "
                    fdprocessedid="648ct2"
                    onClick={updateUserProfile}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </ModalComponent>
        )}

        {mappingDetailsModal &&
          appUserMappingDetailsData &&
          appUserMappingDetailsData.body && (
            <ModalComponent
              size={"2xl"}
              data={"nadnandand"}
              show={mappingDetailsModal}
              setShow={setmappingDetailsModal}
            >
              <div className="p-4 flex gap-4">
                <div className="w-1/2">
                  <h2 className="text-xl font-semibold mb-2">Parent Data</h2>
                  <div className="overflow-y-auto max-h-96 p-2">
                    {appUserMappingDetailsData.body.parent.map((parentItem) => (
                      <div
                        key={parentItem.id}
                        className="bg-white rounded-lg  shadow-md border border-gray-300 mb-3 transition transform hover:scale-105 duration-300 ease-in-out"
                      >
                        <div className="bg-blue-700 rounded-t-lg px-3 py-2.5">
                          <p className="text-white font-semibold">
                            ID&nbsp;:&nbsp;{parentItem.app_user_id}
                          </p>
                          <p
                            className="underline text-white font-semibold"
                            onClick={() =>
                              navigate(
                                `/genefied/${getVendorName()}/user/${parentItem.user_type.toLowerCase()}`
                              )
                            }
                          >
                            User type&nbsp;:&nbsp;
                            {parentItem.user_type.toUpperCase()}
                          </p>
                        </div>
                        <div className="px-3 py-2.5">
                          <p> Name&nbsp;:&nbsp;{parentItem.app_user_name}</p>
                          <p>Mobile&nbsp;:&nbsp;{parentItem.app_user_mobile}</p>
                          <p>
                            Mapped On&nbsp;:&nbsp;{" "}
                            {new Date(parentItem.created_at).toLocaleString()}
                          </p>
                          <p>
                            Mapping Status&nbsp;:&nbsp;{" "}
                            {parentItem.status == 1 ? "Active" : "Inactive"}
                          </p>
                          <p>
                            User Status&nbsp;:&nbsp;{" "}
                            {parentItem.user_status == 1
                              ? "Active"
                              : "Inactive"}
                          </p>
                          {/* Add more fields here as needed */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-1/2">
                  <h2 className="text-xl font-semibold mb-2">Child Data</h2>
                  <div className="overflow-y-auto max-h-96 p-2">
                    {appUserMappingDetailsData.body.child.map((childItem) => (
                      <div
                        key={childItem.id}
                        className="bg-white rounded-lg shadow-md border border-gray-800 mb-3 transition transform hover:scale-105 duration-300 ease-in-out"
                      >
                        <div className="bg-blue-700 rounded-t-lg px-3 py-2.5">
                          <p className="text-white font-semibold">
                            ID&nbsp;:&nbsp;{childItem.mapped_app_user_id}{" "}
                          </p>
                          <p
                            className="underline text-white font-semibold"
                            // `/genefied/${getVendorName()}/user/${childItem.mapped_user_type.toLowerCase()}/${childItem.mapped_app_user_id}`
                            onClick={() => {
                              navigate(
                                `../user/${childItem.mapped_user_type.toLowerCase()}`,
                                { replace: true }
                              );
                            }}
                          >
                            User Type&nbsp;:&nbsp;
                            {childItem.mapped_user_type.toUpperCase()}{" "}
                          </p>
                        </div>
                        {/* <Link> */}
                        <div className="px-3 py-2.5">
                          <p>
                            Name&nbsp;:&nbsp;{childItem.mapped_app_user_name}{" "}
                          </p>
                          {/* </Link> */}
                          <p>
                            Mobile&nbsp;:&nbsp;
                            {childItem.mapped_app_user_mobile}
                          </p>
                          <p>
                            Mapped On&nbsp;:&nbsp;{" "}
                            {new Date(childItem.created_at).toLocaleString()}
                          </p>
                          <p>
                            Mapping Status&nbsp;:&nbsp;{" "}
                            {childItem.status == 1 ? "Active" : "Inactive"}
                          </p>
                          <p>
                            User Status&nbsp;:&nbsp;{" "}
                            {childItem.user_status == 1 ? "Active" : "Inactive"}
                          </p>
                          {/* Add more fields here as needed */}
                        </div>{" "}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}
      </div>
    </>
  );
};

export default UserProfile;
