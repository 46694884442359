import { rootApi } from "../../api/rootApi";

export const ultimatrueApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getRequestQuotationFormData: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/ultimatrue/request-project-quotation/all`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
 useGetRequestQuotationFormDataMutation
} = ultimatrueApi;
