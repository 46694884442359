import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useFetchVendorsThemeQuery } from "../../../features/admin/vendorTheme/vendorThemeApi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import { useDeleteAppUserDashboardMutation, useFetchAllAppUserDashboardMutation,  } from "../../../features/admin/appUserDashboard/appUserDashboardApi";
import AppDashboardWizard from "./AppDashboardWizard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
  maxHeight: "600px",
};

const wizardStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AppDashboard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [selectedData, setSelectedData] = useState(null);
  
  const [refetch, setRefetch] = useState(0);

  const [wizardToggle, setWizardToggle] = useState(false)
  const handleWizardOpen = () => setWizardToggle(true);
  const handleWizardClose = () => setWizardToggle(false);


  const [
    fetchAllAppUserDashboard,
    {
      data: appUserDashboardData,
      error: appUserDashboardError,
    },
  ] = useFetchAllAppUserDashboardMutation();

  const [
    deleteAppUserDashboard,
    {
      data: deleteAppUserDashboardData,
      isLoading: deleteappUserMenuIsLoading,
      error: deleteappUserDashboardError,
    },
  ] = useDeleteAppUserDashboardMutation();

  useMemo(() => {
    if (deleteAppUserDashboardData) {
      (() => {
    toast.dismiss();
        console.log("deleted app user dashboard", deleteAppUserDashboardData);
        fetchAllAppUserDashboard({ token: sessionStorage.getItem("access_token") });
      })();
    }
    if (deleteappUserDashboardError) {
      toast.error("Some error occured", {
        id: "deleteAppMenuError",
      });
    }
  }, [deleteAppUserDashboardData, deleteappUserDashboardError]);

  useEffect(() => {
    if (appUserDashboardData) {
      console.log("app user dashboard", appUserDashboardData);
    }

    if (appUserDashboardError) {
      console.log("app user dashboard error", appUserDashboardError);
    }
  }, [appUserDashboardData, appUserDashboardError]);

  useEffect(() => {
    fetchAllAppUserDashboard({ token: sessionStorage.getItem("access_token") });
  }, [refetch]);

  const handleDeleteWrapper = (id) => {
    toast(
      (t) => (
        <span>
          <h6>
            Are you sure you want to delete this ?
          </h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleDelete(id)
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      },
    );
    return;
  }

  const handleDelete = (id) => {
    deleteAppUserDashboard({
      token: sessionStorage.getItem("access_token"),
      id: id,
    });
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row.tenant_name,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type.toUpperCase(),
      sortable: false,
      allowOverflow: false,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            setSelectedData(r);
            setOpen(true);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r.id)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div className="main-wrapper">
      <div>
        {wizardToggle && (
          <Modal keepMounted open={wizardToggle} onClose={handleWizardClose}>
            <Box style={{ overflowY: "scroll" }} sx={wizardStyle}>
              <div>
                <AppDashboardWizard
                  handleClose={handleWizardClose}
                  refetch={setRefetch}
                />
              </div>
            </Box>
          </Modal>
        )}
      </div>
      <div className="page-content">
        <div className="card p-4">
        <div className="borderbtm  d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="mb-3 mb-md-0 card-title">Welcome to App Dashboard Master</h4>
          </div>
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              // onClick={() => navigate("add")}
              onClick={() => handleWizardOpen()}
            >
              Add
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-xl-12">
            <DataTable
              title=""
              data={appUserDashboardData && appUserDashboardData.body}
              columns={columns}
              //selectableRows
              //onSelectedRowsChange={handleChange}
            />
          </div>
        </div>
        <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style} style={{ overflow: "scroll" }}>
                  <div className="card-body text-center">
                    <h2
                      className="card-title mb-2"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {selectedData &&
                        selectedData.tenant_name.charAt(0).toUpperCase() +
                          selectedData.tenant_name.slice(1) +
                          " " +
                          selectedData.user_type.charAt(0).toUpperCase() +
                          selectedData.user_type.slice(1) +
                          " Menu"}
                    </h2>
                    <table class="table table-bordered">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col-3">S No.</th>
                          <th scope="col-3">Dashboard Item</th>
                        </tr>
                      </thead>
                      {selectedData && selectedData.app_dashboard.length > 0 && (
                        <tbody>
                          {selectedData.app_dashboard.map((item, idx) => (
                            <tr>
                              <th scope="row">{idx + 1}</th>
                              <td>{item.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default AppDashboard;
