import { rootApi } from "../../api/rootApi";

export const appOtpsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllOtps: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/userOtp?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    countAllOtps: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/userOtp/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const { useFetchAllOtpsMutation,useCountAllOtpsMutation } = appOtpsApi;
