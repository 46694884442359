import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useGetAppVideoMutation } from "../../../../features/tenant/appVideo/appVideoApi";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import {
  useDeletePushNotificationTypeByIdMutation,
  useFetchAllPushNotificationTypeMutation,
  useSendFirebaseNotificationsMutation,
  useSendNotificationsMutation,
  useUpdatePushNotificationTypeByIdMutation,
} from "../../../../features/tenant/PushNotificationType/pushNotificationTypeAPi";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { downloadCSV } from "../../../../utils/downloadCSV";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import { useFetchAllTopicsMutation, useSendNotificationToTopicsMutation } from "../../../../features/tenant/FirebaseTopics/firebaseTopicsApi";

function parseCSV(string) {
  const csvHeader = string
    .slice(0, string.indexOf("\n"))
    .replace(/\s/g, "")
    .split(",");

  const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);

  console.log("csvRows: ", csvRows.length, csvRows);

  const array = csvRows
    .map((row) => {
      const values = [];
      let insideQuotes = false;
      let currentField = "";

      for (let char of row) {
        if (char === "," && !insideQuotes) {
          values.push(currentField.trim());
          currentField = "";
        } else if (char === '"') {
          insideQuotes = !insideQuotes;
        } else {
          currentField += char;
        }
      }

      values.push(currentField.trim());
      return values;
    })
    .filter((values) => values.length === csvHeader.length)
    .map((values) => {
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index].trim();
        return object;
      }, {});
      return obj;
    });

  return array;
}

function filterValidPhoneNumbers(phoneNumbers) {
  // Regular expression to match 10-digit phone numbers
  let phoneRegExp = /^\d{10}$/;

  if(JSON.parse(sessionStorage.getItem("vendorData")).tenant_name === "ultimatrue"){
    phoneRegExp = /^\d{11}$/;
  }

  // Filter the array to include only valid phone numbers
  const validPhoneNumbers = phoneNumbers.filter((number) =>
    phoneRegExp.test(number)
  );

  return validPhoneNumbers;
}

const PushNotificationsTypeList = () => {
  const logo = JSON.parse(sessionStorage.getItem("companyProfile")).logo;
  const tenantName = JSON.parse(sessionStorage.getItem("vendorData")).tenant_name;
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState();
  const [selectTopic, setSelectTopic] = useState({
    id : 0,
    name : 'No Topic'
  });
  const [showModal, setShowModal] = useState(false);
  const [allTopics, setAllTopics] = useState([{
    id : 0,
    name : 'No Topic'
  }]);
  const [showModalForTopicMessage, setShowModalForTopicMessage] =
    useState(false);

  const [modalData, setmodalData] = useState();
  const [modalDataForTopicMessage, setModalDataForTopicMessage] = useState();

  const handleOpenModal = (data) => {
    setShowModal(true);
    setmodalData(data);
  };

  const handleOpenModalForTopicMessage = (data) => {
    setShowModalForTopicMessage(true);
    setModalDataForTopicMessage(data);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setmodalData(null);
  };

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [mobileNumbers, setMobileNumbers] = useState("");

  const [
    getAllPushNotificationType,
    {
      data: getAllPushNotificationTypeData,
      isLoading: getAllPushNotificationTypeLoading,
      error: getAllPushNotificationTypeError,
    },
  ] = useFetchAllPushNotificationTypeMutation();
  const [
    sendNotificationToTopics,
    {
      data: sendNotificationToTopicsData,
      isLoading: sendNotificationToTopicsLoading,
      error: sendNotificationToTopicsError,
    },
  ] = useSendNotificationToTopicsMutation();

  const [
    fetchAllTopics,
    {
      data: fetchAllTopicsData,
      error: fetchAllTopicsError,
      isLoading: fetchAllTopicsIsLoading,
      isError: fetchAllTopicsIsError,
    },
  ] = useFetchAllTopicsMutation();

  const [
    sendPushNotificationsToMobiles,
    {
      data: sendPushNotificationsToMobilesData,
      isLoading: sendPushNotificationsToMobilesDataLoading,
      error: sendPushNotificationsToMobilesDataError,
    },
  ] = useSendFirebaseNotificationsMutation();

  const [
    deletePushNotificationType,
    {
      data: deleteNotificationData,
      isLoading: deleteNotificationIsLoading,
      error: deleteNotificationDataError,
    },
  ] = useDeletePushNotificationTypeByIdMutation();


  const getAll = () => {
    getAllPushNotificationType({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  const handleFetchAllTopics = (limit, offset) => {
    fetchAllTopics({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  const sendPushNotofications = (body) => {
    sendPushNotificationsToMobiles({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: body,
    });
  };
  
  useEffect(() => {
    getAll();
    handleFetchAllTopics(100, 0);
  }, []);
  
    useEffect(() => {
      if (fetchAllTopicsData) {
        const temp = fetchAllTopicsData.body.data.map(x=>{
          return {
            id : Number(x.id),
            name : `${x.name.split("_").slice(1).join(" ")}`
          }
        })

        setAllTopics([...temp]);
      }
    }, [fetchAllTopicsData]);

  useEffect(() => {
    if (getAllPushNotificationTypeData) {
      console.log(
        "getAllPushNotificationTypeData",
        getAllPushNotificationTypeData
      );
    }

    if (getAllPushNotificationTypeError) {
      console.log(
        "getAllPushNotificationTypeError",
        getAllPushNotificationTypeError
      );
    }
  }, [getAllPushNotificationTypeData, getAllPushNotificationTypeError]);

  useEffect(() => {
    if (sendNotificationToTopicsData) {
      toast.dismiss();
      toast.success(sendNotificationToTopicsData.message);
    }
    if (sendNotificationToTopicsError) {
       toast.dismiss();
       toast.error("Internal Error Server");
    }
  }, [sendNotificationToTopicsData, sendNotificationToTopicsError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    {
      name: "Type",
      cell: (row) => row.notification_type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Title",
      cell: (row) => row.title,
      sortable: true,
    },
    {
      name: "Subtitle",
      cell: (row) => row.subtitle,
      sortable: true,
    },
    {
      name: "Body",
      cell: (row) => row.body,
      sortable: true,
      width: "250px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleOpenModalForTopicMessage(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            Send To Topic
          </button>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleOpenModal(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            Send
          </button>
        </div>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => navigate("add",{
              state : {
                isUpdate : true,
                row : row
              }
            })
          }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            Update
          </button>
        </div>
      ),
      sortable: true,
      width: "100px",
    },
    // {
    //   name: "",
    //   cell: (row) =>
    //     true && (
    //       <div>
    //         <button
    //           onClick={() =>
    //             navigate("add", {
    //               state: row,
    //             })
    //           }
    //           className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    //         >
    //           <AiOutlineEdit className="mr-1" />
    //           Edit
    //         </button>
    //       </div>
    //     ),
    //   width: "100px",
    // },
    {
      name: "",
      cell: (row) =>
        true && (
          <div>
            <button
              onClick={() => handleDeleteNotification(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      minWidth: "120px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleSendNotifications = (row) => {
    // console.log("row", row);
    const valid = filterValidPhoneNumbers(mobileNumbers.split(/[ ,]+/));
    // console.log("valid mobiles ", valid);
    handleCloseModal()

    if (valid.length == 0) {
      toast(
        (t) => (
          <span className="">
            <h6>
              No mobile numbers , Are you sure you want to send notification to
              all devices ?
            </h6>
            <div className="mt-3 d-flex">
              <button
                className="btn btn-outline-danger btn-sm ml-auto mr-1"
                onClick={() => {
                  sendPushNotofications({
                    notification_type: row.notification_type,
                    mobile: valid,
                    title: row.title,
                    body: row.body,
                    subtitle: row.subtitle,
                    image : logo[0],
                    platform: "4",
                    platform_id: "PANEL",
                    sendToAll: true,
                  });
                }}
              >
                Send to all
              </button>
              <button
                className="btn btn-outline-primary btn-sm mr-auto ml-1"
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                Cancel
              </button>
            </div>
          </span>
        ),
        {
          id: "sendToAllMobiles",
        }
      );
    } else {
      toast(
        (t) => (
          <span>
            <h6 className="capitalize">{valid.length} valid mobile number</h6>
            <div className="mt-3 d-flex">
              <button
                className="btn btn-outline-danger btn-sm ml-auto mr-1"
                onClick={() => {
                  sendPushNotofications({
                    notification_type: row.notification_type,
                    mobile: valid,
                    title: row.title,
                    body: row.body,
                    subtitle: row.subtitle,
                    platform: "4",
                    platform_id: "PANEL",
                    image : logo[0],
                    sendToAll: false,
                  });
                }}
              >
                Send
              </button>
              <button
                className="btn btn-outline-primary btn-sm mr-auto ml-1"
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                Cancel
              </button>
            </div>
          </span>
        ),
        {
          id: "sendToMobiles",
        }
      );
    }
  };

  useEffect(() => {
    if (sendPushNotificationsToMobilesData) {
      toast.dismiss();
      console.log(
        "Successfully sent push notifications",
        sendPushNotificationsToMobilesData
      );
      if (sendPushNotificationsToMobilesData.body) {
        toast.success(sendPushNotificationsToMobilesData.message, {
          id: "sendNotificationsSuccess",
        });
      } else {
        toast(sendPushNotificationsToMobilesData.message, {
          id: "sendNotificationsSuccess",
        });
      }
    }

    if (sendPushNotificationsToMobilesDataError) {
      toast.dismiss();

      console.log("Something went wrong.");
      toast.error("Something went wrong", {
        id: "sendNotificationError",
      });
    }

    if (sendPushNotificationsToMobilesDataLoading) {
      toast.dismiss();
      toast.loading("Please wait...");
    }
  }, [
    sendPushNotificationsToMobilesData,
    sendPushNotificationsToMobilesDataError,
    sendPushNotificationsToMobilesDataLoading,
  ]);

  const handleDeleteNotification = (row) => {
    toast.dismiss();
    toast(
      (t) => (
        <span className="">
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deletePushNotificationType({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: row.id,
                });
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteNotification",
      }
    );
  };

  useEffect(() => {
    if (deleteNotificationData) {
      toast.dismiss();
      getAll();
      console.log("deleteNotificationData", deleteNotificationData);
      toast.success("Deleted Successfully", {
        id: " deleteNotificationData",
      });
    }

    if (deleteNotificationDataError) {
      toast.dismiss();
      console.log("deleteNotificationDataError", deleteNotificationDataError);
      toast.error("Something went wrong..", {
        id: " deleteNotificationDataError",
      });
    }
  }, [deleteNotificationData, deleteNotificationDataError]);

  const [mobileCsv, setMobileCsv] = useState(null);

  const handleUploadUserMobileCsv = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!e.target.files[0]) {
      toast.error("Please select a file");
      return;
    }
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      setMobileCsv(e.target.files[0]);
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);

        const mobiles = datas.map((each) => each.mobile);
        setMobileNumbers(mobiles.join(","));
      };
      fileReader.readAsText(e.target.files[0]);
    }
  };

  const handleSendNotificationsToTopic =(data)=>{
    if( selectTopic.id == '0'){
      toast.dismiss();
      toast.error("Please Select a Topic");

      return ;
    }

    sendNotificationToTopics({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id:`${selectTopic.id}`,
        body : data,
    })

  }

  console.log("set==>",allTopics);

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Push Notifications Type List
          <LinksComponent />
        </div>
        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            <DataTableComponent
              columns={columns}
              data={
                getAllPushNotificationTypeData &&
                getAllPushNotificationTypeData.body?.data
              }
              // loading={loading}
              totalRows={
                getAllPushNotificationTypeData &&
                getAllPushNotificationTypeData.body?.count
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            ></DataTableComponent>
            <div
              className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
              id="box_btn"
            >
              <div className="text-right">
                <Menu className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={() => navigate("add",{
                        state : {
                          isUpdate : false,
                        }
                      })}
                      className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                      style={{ backgroundColor: `${primaryColor["700"]}` }}
                    >
                      <HiOutlinePlus className="w-full h-full p-3" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ModalComponent
          size={"md"}
          data={"nadnandand"}
          show={showModal}
          setShow={setShowModal}
        >
          <div className="w-full">
            <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
              <div className="w-full">
                <div className="flex justify-center mb-1">
                  <button
                    type="submit"
                    className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm px-3 py-1.5 text-center "
                    onClick={() => downloadCSV(sampleData, "mobileSample.csv")}
                  >
                    Sample Csv Download
                  </button>
                </div>
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        {mobileCsv ? (
                          mobileCsv.name
                        ) : (
                          <>
                            <span className="font-semibold">
                              Choose CSV file
                            </span>
                            <div>Click to upload or drag and drop</div>
                          </>
                        )}
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleUploadUserMobileCsv}
                    />
                  </label>
                </div>

                <div className="p-2 mt-3 ">
                  <div className="w-full min-h-max">
                    <InputComponent
                      name={"Enter Mobile Numbers"}
                      type={"textarea"}
                      value={mobileNumbers}
                      setValue={setMobileNumbers}
                      required={true}
                      placeholder={"Enter Mobile Numbers"}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-2">
                <button
                  type="submit"
                  onClick={(e) => handleSendNotifications(modalData)}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
      {showModalForTopicMessage && (
        <ModalComponent
          size={"md"}
          data={"nadnandand"}
          show={showModalForTopicMessage}
          setShow={setShowModalForTopicMessage}
        >
          <div className="w-full">
            <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
              <div className="w-full">
              <button
            onClick={() => navigate("/genefied/"+tenantName+"/topics" )}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            Add Topic
          </button>
                <div className="p-2 mt-3 ">
                  <div className="w-full min-h-max">
                    <SelectComponent
                      name={"Select Topic "}

                          selected={selectTopic}
                          setSelected={setSelectTopic}
                          dataArr={allTopics}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-2">
                <button
                  type="submit"
                  onClick={(e) => handleSendNotificationsToTopic(modalDataForTopicMessage)}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default PushNotificationsTypeList;

const sampleData = [
  { mobile: "9878987890" },
  { mobile: "8789987890" },
  { mobile: "9999997890" },
];
