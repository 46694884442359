import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDeleteSmsCredentialMutation,
  useGetSmsCredentialWithLimitOffsetMutation,
  useUpdateSmsCredentialStatusMutation,
} from "../../../features/admin/smsCredential/smsCredentialApi";
import { toast } from "react-hot-toast";

const providerArray = [
  {
    id: 1,
    name: "orbital",
    form: {
      name: ["user_id", "password", "entity_id"],
      view_name: ["User Id", "Password", "Entity Id"],
    },
  },
  {
    id: 2,
    name: "smslane",
    form: {
      name: ["api_key", "entity_id"],
      view_name: ["Api Key", "Entity Id"],
    },
  },
  {
    id: 3,
    name: "juro",
    form: {
      name: ["token","entity_id"],
      view_name: ["Token", "Entity Id"],
    },
  },
];



const SmsCredential = () => {
  const navigate = useNavigate();
  // const [showCred , setShowCred] = useState();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    getSmsCredentialWithLimitOffset,
    {
      data: getSmsCredentialWithLimitOffsetData,
      isError: getSmsCredentialWithLimitOffsetIsError,
      error: getSmsCredentialWithLimitOffsetError,
      isLoading: getSmsCredentialWithLimitOffsetIsLoading,
    },
  ] = useGetSmsCredentialWithLimitOffsetMutation();

  const [
    deleteSmsCredential,
    {
      data: deleteSmsCredentialData,
      isError: deleteSmsCredentialIsError,
      error: deleteSmsCredentialError,
      isLoading: deleteSmsCredentialIsLoading,
    },
  ] = useDeleteSmsCredentialMutation();

  const [
    updateSmsCredentialStatus,
    {
      data: updateSmsCredentialStatusData,
      isError: updateSmsCredentialStatusIsError,
      error: updateSmsCredentialStatusError,
      isLoading: updateSmsCredentialStatusIsLoading,
    },
  ] = useUpdateSmsCredentialStatusMutation();

  useEffect(() => {
    console.log("1");
    getSmsCredentialWithLimitOffset({
      token: sessionStorage.getItem("access_token"),
      limit: limit,
      offset: offset,
    });
  }, [limit, offset]);
  console.log(
    "getSmsCredentialWithLimitOffsetData",
    getSmsCredentialWithLimitOffsetData
  );

  if (getSmsCredentialWithLimitOffsetIsError) {
    toast.dismiss();
    toast.error("Some Error Occured", {
      id: "getSmsCredentialWithLimitOffsetError",
    });
  }

  if (getSmsCredentialWithLimitOffsetIsLoading) {
    toast.dismiss();
    toast.loading("Loading...", {
      id: "getSmsCredentialWithLimitOffsetLoading",
    });
  }

  useEffect(() => {
    if (getSmsCredentialWithLimitOffsetData) {
      toast.dismiss();
      toast.success(`${getSmsCredentialWithLimitOffsetData.message}`, {
        id: `getSmsCredentialWithLimitOffsetData`,
      });
    }
  }, [getSmsCredentialWithLimitOffsetData]);

  const handleDelete = (row) => {
    deleteSmsCredential({
      token: sessionStorage.getItem("access_token"),
      id: Number(row.id),
    });
    
  };



  useEffect(()=>{
    if(deleteSmsCredentialData ){
      toast.dismiss();
      setTimeout(()=>{
        toast.success(`${deleteSmsCredentialData.message}`,{
          id : "deleteSmsCredentialData"
        })

      },1000)
      getSmsCredentialWithLimitOffset({
        token: sessionStorage.getItem("access_token"),
        limit: limit,
        offset: offset,
      });
  
    }

  },[deleteSmsCredentialData])

  const handleButtonEditClick = (row) => {
    navigate("/updateSMSCredential", {
      state: row,
    });
  };

  const changeStatusBtn = (row)=>{
    updateSmsCredentialStatus(
      {
        token: sessionStorage.getItem("access_token"),
        id: Number(row.id),
      }
    )
    
  }
  useEffect(()=>{
    if(updateSmsCredentialStatusData ){
      toast.dismiss();
      setTimeout(()=>{
        toast.success(`${updateSmsCredentialStatusData.message}`,{
          id : "updateSmsCredentialStatusData"
        })

      },1000)
      getSmsCredentialWithLimitOffset({
        token: sessionStorage.getItem("access_token"),
        limit: limit,
        offset: offset,
      });
  
    }

  },[updateSmsCredentialStatusData])

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Credential",
      selector: (row) =>{
        return providerArray.map(p=>{
          if(p.name === row.type){
            return p.form?.name.map((x, index) => {

              if(index < Object.keys(row.api_credential).length){
                return (<>
                  <div key={index} 
                  // className="form-group row"
                  >
                    <div >
                      <label for="name"> {  p?.form.view_name[index] } </label>
                      <input
                        name={x}
                        className="form-control"
                        value={Object.values(row.api_credential)[index]}
                        type="text"
                      />
                    </div>
                  </div>
                </>)
              }else{
                return (<></>);
              }
            })
          }else{
            return <></>;
          }
        })
      }
    },
    // {
    //   name: "Credential",
    //   selector: (row) =>
    //     row.type === "orbital" ? (
    //       <div className="d-flex align-items-center flex-wrap">
    //         user_id :
    //         <input type="password" value={row.api_credential.user_id} />
    //         password :
    //         <input type="password" value={row.api_credential.user_id} />
    //       </div>
    //     ) : (
    //       <div className="d-flex align-items-center flex-wrap">
    //         api_key :
    //         <input type="password" value={row.api_credential.api_key} />
    //       </div>
    //     ),
    // },
    {
      name: "Entity Id",
      selector: (row) => row.entity_id,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Created At",
      selector: (row) =>new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Status",
      cell: (r) =>
        r.status === "2" ? (
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => changeStatusBtn(r)}
          >
            In-Active
          </button>
        ) : (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => changeStatusBtn(r)}
          >
            Active
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDelete(r)}
        >
          Delete
        </button>
      ),

      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Action ",
      cell: (r) => {
        return (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() =>
              navigate("/messageTemplate", {
                state: r,
              })
            }
          >
            Templates
          </button>
        );
      },
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };
  
  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  console.log(
    "getSmsCredentialWithLimitOffsetData?.body?.total",
    getSmsCredentialWithLimitOffsetData?.body?.total
  );

  return (
    <>
      <div className="page-content">
        <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>{/* <h4 className="mb-3 mb-md-0">SMS Credentials</h4> */}</div>
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              onClick={() => navigate("/addSMSCredential")}
            >
              Add SMS Credentials
            </button>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <DataTable
                title="SMS Credentials"
                data={getSmsCredentialWithLimitOffsetData?.body?.data}
                columns={columns}
                // onSelectedRowsChange={handleChange}
                // customStyles={customStyles}
                totalRows={getSmsCredentialWithLimitOffsetData?.body?.total}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                striped
                highlightOnHover
                pointerOnHover
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsCredential;
