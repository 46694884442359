import React, { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import {
  useUploadSingleFileMutation,
} from "../../../features/admin/imageUpload/imageUploadApi";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useUpdateVendorMutation } from "../../../features/admin/vendor/vendorApi";

const VendorUpdate = () => {
  const navigate = useNavigate();

  const companyAddressObj = {
    homeAddress: "",
    state: "",
    country: "",
    state: "",
    district: "",
    landMark: "",
    city: "",
    pincode: "",
  };
  const companySocialMediaObj = {
    facebook: "",
    youtube: "",
    instagram: "",
    twitter: "",
  };

  const [comapnyName, setComapnyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [companyRePassword, setCompanyRePassword] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactPersonNumber, setContactPersonNumber] = useState("");
  const [companyAddress, setcompanyAddress] = useState(companyAddressObj);
  const [companySocialMedia, setCompanySocialMedia] = useState(
    companySocialMediaObj,
  );
  const [thumbnailLogo, setThumbnailLogo] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);

  const [navbarLogo, setNavbarLogo] = useState(null);
  const [navbarFile, setNavbarFile] = useState(null);

  const [companyIndustry, setCompanyIndustry] = useState(0);
  const [companyGstin, setCompanyGstin] = useState("");
  const [panelStatus, setPanelStatus] = useState(0);
  const [companyStatus, setCompanyStatus] = useState(1);
  const [companyUrl, setCompanyUrl] = useState("");
  const [qrType, setQrType] = useState(0);
  const [industryData, setIndustryData] = useState([]);
  const [companyId, setCompanyId] = useState("");

  // errors
  const [companyEmailError, setCompanyEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordReError, setPasswordReError] = useState(null);
  const [masterError, setMasterError] = useState(null);
  const [companyMobileError, setCompanyMobileError] = useState(null);
  const [logoErr, setLogoErr] = useState(null);

  const [successMsg, setSuccessMsg] = useState(null);

  const [
    updateVendorFunc,
    {
      data: updateVendorData,
      isLoading: updateVendorIsLoading,
      error: updateVendorError,
    },
  ] = useUpdateVendorMutation();

  const updateFields = (companyData) => {
    console.log("updating fields");
    console.log(companyData.logo);

    setThumbnailLogo(companyData.logo[0]);
    setNavbarLogo(companyData.logo[1]);

    companyData.name && setComapnyName(companyData.name);
    companyData.email && setCompanyEmail(companyData.email);
    companyData.gstin && setCompanyGstin(companyData.gstin);
    companyData.mobile && setCompanyMobile(companyData.mobile);
    companyData.address && setcompanyAddress(companyData.address);
    companyData.qr_type && setQrType(companyData.qr_type);
    companyData.socials && setCompanySocialMedia(companyData.socials);
    companyData.panel_status && setPanelStatus(companyData.panel_status);
    companyData.status && setCompanyStatus(companyData.status);
    companyData.website && setCompanyUrl(companyData.website);
    companyData.contact_person_name &&
      setContactPerson(companyData.contact_person_name);
    companyData.contact_person_number &&
      setContactPersonNumber(companyData.contact_person_number);
    companyData.industry_id && setCompanyIndustry(companyData.industry_id);
  };

  useEffect(() => {
    console.log("ID", window.location.search.split("=")[1]);
    setCompanyId(() =>
      window.location.search ? window.location.search.split("=")[1] : null,
    );

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/company/${
        window.location.search.split("=")[1]
      }`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Company Fetched", response.data.body);
        updateFields(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/industry`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.body);
        setIndustryData(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (
      !companyEmailError &&
      !passwordError &&
      !passwordReError &&
      !companyMobileError
    ) {
      setMasterError(null);
    }
  }, [companyEmailError, passwordError, passwordReError, companyMobileError]);

  const handleComapnyAddressChange = (e, target) => {
    let copiedValue = { ...companyAddress };
    copiedValue[target] = e.target.value;
    setcompanyAddress(copiedValue);
  };

  const handlePhoneChange = (event) => {
    if (
      !validator.isMobilePhone(event.target.value, ["en-IN"]) &&
      event.target.value > 0
    ) {
      setCompanyMobileError("Phone no Invalid");
    } else {
      setCompanyMobileError(null);
    }

    setCompanyMobile(event.target.value);
  };

  const handleCompanySocialChange = (e, target) => {
    let copiedValue = { ...companySocialMedia };
    copiedValue[target] = e.target.value;
    setCompanySocialMedia(copiedValue);
  };

  const handleEmailChange = (event) => {
    console.log(event.target.value);
    console.log(validator.isEmail(event.target.value));
    if (
      !validator.isEmail(event.target.value) &&
      event.target.value.length > 0
    ) {
      setCompanyEmailError("Email is invalid");
    } else {
      setCompanyEmailError(null);
    }
    setCompanyEmail(event.target.value);
  };

  const handlePasswordChange = (event, type) => {
    if (
      event.target.value.length > 0 &&
      !validator.isStrongPassword(event.target.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      if (event.target.value !== companyPassword && type == 1) {
        setPasswordReError("Password Not Same");
      } else if (type == 1)
        setPasswordReError(
          "Password is not strong , must include a number , symbol , uppercase and lowercase ",
        );
      else
        setPasswordError(
          "Password is not strong , must include a number , symbol , uppercase and lowercase ",
        );
    } else {
      if (type == 1) setPasswordReError(null);
      else setPasswordError(null);
    }

    if (type == 1) setCompanyRePassword(event.target.value);
    else setCompanyPassword(event.target.value);
  };

  const handleCompanyIndustryChange = (e) => {
    setCompanyIndustry(e.target.value);
  };

  const updateVendor = (e) => {
    e.preventDefault();
    setLogoErr(null);
    setMasterError(null);
    console.log(thumbnailLogo, thumbnailFile, navbarFile, navbarLogo);

    let industryName = "";
    let user_types = [];
    industryData.forEach(function (data) {
      if (data.industry_id == companyIndustry) {
        industryName = data.name;
        user_types = data.users;
      }
    });

    if (
      companyEmailError == null &&
      companyMobileError == null &&
      passwordError == null &&
      passwordReError == null &&
      companyEmail.length > 0 &&
      comapnyName.length > 0 &&
      companyGstin.length > 0
    ) {
      const data = {
        name: comapnyName,
        email: companyEmail,
        gstin: companyGstin,
        mobile: companyMobile,
        address: JSON.stringify(companyAddress),
        qr_type: String(qrType),
        password: companyPassword,
        socials: JSON.stringify(companySocialMedia),
        panel_status: panelStatus,
        status: companyStatus,
        website: companyUrl,
        contact_person_name: contactPerson,
        contact_person_number: contactPersonNumber,
        logo: [thumbnailLogo, navbarLogo],
        user_types: JSON.stringify(user_types),
        industry_id: companyIndustry,
        industry_name: industryName,
      };

      // console.log(data);
      updateVendorFunc({
        id: companyId,
        token: sessionStorage.getItem("access_token"),
        body: data,
      });

      // var config = {
      //   method: "put",
      //   url: `${process.env.REACT_APP_BASE_URL}/admin/company/${companyId}`,
      //   headers: {
      //     // "Content-Type": "application/json",
      //     "Content-Type": "multipart/form-data",
      //     Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      //   },
      //   data: formData,
      // };
      // axios(config)
      //   .then(function (response) {
      //     console.log(response.data);
      //     if (response.data.success) {
      //       setSuccessMsg(response.data.message);
      //       toast.success(response.data.message, {
      //         id: "updateCompanySuccess",
      //       });
      //       navigate("/");
      //     }
      //     //   const { id } = response.data.data;
      //     //   setVendorId(id);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   })
      //   .finally(() => {});
    } else {
      setLogoErr("Upload Image First");
      setMasterError(
        "Please fill all the required fields and check the errors",
      );
      toast.error("Please fill all the required fields and check the errors", {
        id: "masterErrorCompanyUpdate",
      });
    }
  };

  useEffect(() => {
    if (updateVendorData) {
      console.log(updateVendorData);
      if (updateVendorData) {
        toast.success(updateVendorData.message, {
          id: "vendorUpdated",
        });
        navigate("/");
      }
    }

    if (updateVendorError) {
      toast.error("Something went wrong", {
        id: "vendorUpdateError",
      });
    }
  }, [updateVendorData, updateVendorError, updateVendorIsLoading]);

  const resetValues = () => {
    setComapnyName("");
    setCompanyEmail("");
    setCompanyPassword("");
    setCompanyRePassword("");
    setCompanyGstin("");
    setcompanyAddress(companyAddressObj);
    setCompanySocialMedia(companySocialMediaObj);
    setContactPerson("");
    setContactPersonNumber("");
    setCompanyIndustry(0);
    setCompanyMobile("");
    setCompanyUrl("");
    setPanelStatus(0);
    setThumbnailFile(null);
    setNavbarFile(null);
    setCompanyStatus(0);
  };

  const handleCompanyStatus = (event) => {
    setCompanyStatus(event.target.value);
  };

  const handleCompanyPanelStatus = (event) => {
    // console.log(panelStatus);
    console.log(event.target.value);
    setPanelStatus(event.target.value);
  };

  const handleQrChange = (event) => {
    setQrType(parseInt(event.target.value));
    console.log({ qrType });
  };

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const [logoType, setLogoType] = useState(null);

  const uploadImageFunc = (file, type) => {
    const formData = new FormData();
    if (type == 0) {
      setLogoType(type);
      formData.append("image", file[0]);
      uploadImage({
        body: formData,
        token: sessionStorage.getItem("access_token"),
      });
    } else if (type == 1) {
      setLogoType(type);
      formData.append("image", file[0]);
      uploadImage({
        body: formData,
        token: sessionStorage.getItem("access_token"),
      });
    }
  };

  useEffect(() => {
    if (uploadImageData) {
      // console.log(uploadImageData.body[0], logoType);
      if (logoType == 0) {
        setThumbnailLogo(uploadImageData.body.fileLink);
        setLogoErr(null);
      } else if (logoType == 1) {
        setNavbarLogo(uploadImageData.body.fileLink);
        setLogoErr(null);
      }
    }
    if (uploadImageError) {
      toast.error("image upload failed");
      setLogoErr("Image upload failed");
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);
  return (
    <>
      <Toaster />
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="borderbtm">
                  <h4 className="card-title mb-0">Update Client</h4>
                </div>
                <div className="cmxform">
                  <div className="row frmds">
                    <div className="col-md-4 my-2">
                      <label>Name </label>
                      <input
                        id="m_name"
                        className="form-control"
                        name="m_name"
                        type="text"
                        value={comapnyName}
                        onChange={(e) => setComapnyName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <label>Email </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="m_no"
                        type="email"
                        value={companyEmail}
                        onChange={(e) => handleEmailChange(e)}
                      />
                      {companyEmailError && (
                        <p className="text-danger mx-2 my-2">
                          {companyEmailError}
                        </p>
                      )}
                    </div>
                    <div className="col-md-4 my-2">
                      <label>Password </label>
                      <input
                        id="m_password"
                        className="form-control"
                        name="password"
                        type="text"
                        value={companyPassword}
                        onChange={(e) => handlePasswordChange(e, 0)}
                      />
                      {passwordError && (
                        <p className="text-danger mx-2 my-2">{passwordError}</p>
                      )}
                    </div>
                    <div className="col-md-4 my-2">
                      <label>Re-Password </label>
                      <input
                        id="m_re_password"
                        className="form-control"
                        name="re-password"
                        type="password"
                        value={companyRePassword}
                        onChange={(e) => handlePasswordChange(e, 1)}
                      />
                      {passwordReError && (
                        <p className="text-danger mx-2 my-2">
                          {passwordReError}
                        </p>
                      )}
                    </div>

                    <div className="col-md-4 my-2">
                      <label>Mobile No </label>
                      <input
                        id="m_mobile"
                        className="form-control"
                        name="m_name"
                        type="text"
                        value={companyMobile}
                        onChange={(e) => handlePhoneChange(e)}
                      />
                      {companyMobileError && (
                        <p className="text-danger mx-2 my-2">
                          {companyMobileError}
                        </p>
                      )}
                    </div>

                    <div className="col-md-4 my-2">
                      <label className="my-1">Industry </label>
                      <select
                        className="js-example-basic-single w-100"
                        value={companyIndustry}
                        onChange={handleCompanyIndustryChange}
                      >
                        <option value={0}>Select Industry</option>
                        {industryData &&
                          industryData.map((industry) => (
                            <option
                              key={industry.industry_id}
                              value={industry.industry_id}
                            >
                              {industry.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-4 my-2">
                      <label>Contact Person Name </label>
                      <input
                        id="m_contact_person_name"
                        className="form-control"
                        name="m_no"
                        type="text"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <label>Contact Person Number</label>
                      <input
                        id="m_contact_person_number"
                        className="form-control"
                        name="m_no"
                        type="text"
                        value={contactPersonNumber}
                        onChange={(e) => setContactPersonNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <label>Landmark </label>
                      <input
                        id="m_landmark"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.landMark}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "landMark")
                        }
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <label>Head Office Address </label>
                      <textarea
                        id="maxlength-textarea"
                        className="form-control"
                        maxLength="100"
                        rows="4"
                        value={companyAddress.homeAddress}
                        placeholder="This textarea has a limit of 100 chars."
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "homeAddress")
                        }
                      />
                    </div>

                    <div className="col-md-4 my-2 ">
                      <label>State </label>
                      <input
                        id="m_state"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.state}
                        onChange={(e) => handleComapnyAddressChange(e, "state")}
                      />
                    </div>
                    <div className="col-md-4 my-2 ">
                      <label>City </label>
                      <input
                        id="m_city"
                        className="form-control"
                        name="city"
                        type="text"
                        value={companyAddress.city}
                        onChange={(e) => handleComapnyAddressChange(e, "city")}
                      />
                    </div>
                    <div className="col-md-4 my-2 ">
                      <label>Country </label>
                      <input
                        id="m_country"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.country}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "country")
                        }
                      />
                    </div>
                    <div className="col-md-4 my-2 ">
                      <label>District </label>
                      <input
                        id="m_district"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.district}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "district")
                        }
                      />
                    </div>
                    <div className="col-md-4 my-2 ">
                      <label>Pincode </label>
                      <input
                        id="m_pincode"
                        className="form-control"
                        name="pincode"
                        type="text"
                        value={companyAddress.pincode}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "pincode")
                        }
                      />
                    </div>
                  </div>

                  <label className=" mt-4 hdngbtm">Social Media </label>

                  <div className="frmds row">
                    <div className="col-md-3 my-2">
                      <label>Facebook </label>

                      <input
                        id="m_facebook"
                        className="form-control"
                        name="fb"
                        type="text"
                        value={companySocialMedia.facebook}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "facebook")
                        }
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Instagram </label>

                      <input
                        id="m_instagram"
                        className="form-control"
                        name="ig"
                        type="text"
                        value={companySocialMedia.instagram}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "instagram")
                        }
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Twitter </label>

                      <input
                        id="m_twitter"
                        className="form-control"
                        name="tw"
                        type="text"
                        value={companySocialMedia.twitter}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "twitter")
                        }
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Youtube </label>

                      <input
                        id="m_youtube"
                        className="form-control"
                        name="yt"
                        type="text"
                        value={companySocialMedia.youtube}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "youtube")
                        }
                      />
                    </div>
                  </div>

                  <div className="frmds row my-2">
                    <div className="col-md-6 my-1">
                      <label>Company URL</label>
                      <input
                        id="m-url"
                        className="form-control"
                        name="p_box"
                        value={companyUrl}
                        onChange={(e) => {
                          setCompanyUrl(e.target.value);
                        }}
                        type="text"
                      />
                    </div>

                    <div className="col-md-6 my-1">
                      <label>Company GSTIN</label>
                      <input
                        id="gstin"
                        className="form-control"
                        name="p_box"
                        value={companyGstin}
                        maxLength={16}
                        onChange={(e) => {
                          setCompanyGstin(e.target.value);
                        }}
                        type="text"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      <label>QR Type</label>
                      <select
                        className="js-example-basic-single w-100"
                        value={qrType}
                        onChange={handleQrChange}
                      >
                        <option value={"0"}>Standard Qr Codes</option>
                        <option value={"1"}>Enterprise QR Codes</option>
                      </select>
                    </div>
                    <div className="col-md-4 my-2">
                      <label>Status</label>
                      <select
                        className="js-example-basic-single w-100"
                        value={companyStatus}
                        onChange={handleCompanyStatus}
                      >
                        <option value={1}>Active</option>
                        <option value={2}>Inactive</option>
                      </select>
                    </div>

                    <div className="col-md-4 my-2">
                      <label className="my-1">Pannel Status</label>
                      <select
                        className="js-example-basic-single w-100"
                        value={panelStatus}
                        onChange={handleCompanyPanelStatus}
                      >
                        <option value={0}>Demo</option>
                        <option value={1}>Testing / Staging</option>
                        <option value={2}>Pre-Production</option>
                        <option value={3}>Production (Live)</option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div className="borderbox_upl shadow-sm">
                        <h2 className="mb-2">Upload Logo (Login Page)</h2>
                        <div class="input-group mb-3 px-2 py-2 w-full border-2 rounded-pill bg-white shadow-sm d-flex justify-content-center align-items-center">
                          <input
                            id="upload-login"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              // setThumbnailLogo(URL.createObjectURL(e.target.files[0]));
                              setThumbnailFile(e.target.files);
                            }}
                            class="form-control border-0"
                            style={{ display: "none" }}
                          />
                          {/* <label for="upload-login" class="font-weight-light">Choose file</label> */}
                          <div class="input-group-append w-full text-center">
                            <label
                              for="upload-login"
                              class="btn btn-light m-0 rounded-pill px-4 d-flex align-items-center w-full  justify-center "
                            >
                              <FaCloudUploadAlt class="mr-2" />
                              <small class="text-uppercase text-truncate font-weight-bold text-muted">
                                Choose file :{" "}
                                <span className="font-weight-light text-truncate text-black text-center">
                                  {thumbnailFile &&
                                    thumbnailFile.length > 0 &&
                                    thumbnailFile[0].name.slice(0, 10)}
                                </span>
                              </small>
                            </label>
                          </div>
                        </div>
                        {thumbnailFile && thumbnailFile.length > 0 && (
                          <>
                            {uploadImageIsLoading && logoType == 0 ? (
                              <div
                                class="spinner-border text-dark"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary mb-2 "
                                onClick={() =>
                                  uploadImageFunc(thumbnailFile, 0)
                                }
                              >
                                Upload
                              </button>
                            )}
                            <p class="font-italic text-black text-center">
                              The image uploaded will be rendered below.
                            </p>
                          </>
                        )}

                        <div class="image-area my-2 w-20 imgcn1 ">
                          <img
                            id="imageResult"
                            src={thumbnailLogo ? thumbnailLogo : ""}
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div className="borderbox_upl shadow-sm">
                        <h2 className="mb-2">Upload Logo (Navbar)</h2>
                        <div class="input-group mb-3 px-2 py-2 w-full border-2 rounded-pill bg-white shadow-sm d-flex justify-content-center align-items-center">
                          <input
                            id="upload-navbar"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              // setNavbarLogo(URL.createObjectURL(e.target.files[0]));
                              setNavbarFile(e.target.files);
                            }}
                            class="form-control border-0"
                            style={{ display: "none" }}
                          />
                          {/* <label for="upload-navbar" class="font-weight-light">Choose file</label> */}
                          <div class="input-group-append w-full text-center">
                            <label
                              for="upload-navbar"
                              class="btn btn-light m-0 rounded-pill px-4 d-flex align-items-center w-full  justify-center  "
                            >
                              <FaCloudUploadAlt class="mr-2" />
                              <small class="text-uppercase font-weight-bold text-muted">
                                Choose file :{" "}
                                <span className="font-weight-light text-black text-center">
                                  {navbarFile &&
                                    navbarFile.length > 0 &&
                                    navbarFile[0].name}
                                </span>
                              </small>
                            </label>
                          </div>
                        </div>

                        {navbarFile && navbarFile.length > 0 && (
                          <>
                            {uploadImageIsLoading && logoType == 1 ? (
                              <div
                                class="spinner-border text-dark"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary mb-2 "
                                onClick={() => uploadImageFunc(navbarFile, 1)}
                              >
                                Upload
                              </button>
                            )}
                            <p class="font-italic text-black text-center">
                              The image uploaded will be rendered below.
                            </p>
                          </>
                        )}

                        <div class="image-area my-2 w-20 imgcn1 ">
                          <img
                            id="imageResult"
                            src={navbarLogo ? navbarLogo : ""}
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3 text-center">
                      <button
                        className="btn btn-outline-primary mt-3 btnall"
                        onClick={(e) => updateVendor(e)}
                      >
                        Update
                      </button>
                    </div>
                    {/* <div className="col-md-6 mt-3">
                      <button
                        className="btn btn-outline-secondary mr-2 w-100"
                        onClick={(e) => resetValues(e)}
                      >
                        Reset Values
                      </button>
                    </div> */}
                  </div>
                </div>
                {/* {masterError && (
                  <div className="alert alert-danger mt-4" role="alert">
                    {masterError}
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorUpdate;
