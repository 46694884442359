import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { HiOutlinePlus } from "react-icons/hi";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
  useAddBulkLocationsToZoneMutation,
  useAddLocationToZoneMutation,
  useDeleteZoneMutation,
  useGetAllNotAssignedDistrictsMutation,
  useGetAllZoneMutation,
  useUpdateStatusMutation,
} from "../../../features/tenant/zones/zonesApi";
import toast from "react-hot-toast";

import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import {
  useGetAllDistrictMutation,
  useGetAllStateMutation,
} from "../../../features/tenant/locations/locations.api";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { hasPermission } from "../../../utils/hasPermission";

const Zones = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [
    getAllZone,
    {
      data: getAllZoneData,
      isLoading: getAllZoneIsLoading,
      isError: getAllZoneIsError,
      error: getAllZoneError,
    },
  ] = useGetAllZoneMutation();

  const [
    updateStatus,
    {
      data: updateStatusData,
      isLoading: updateStatusIsLoading,
      isError: updateStatusIsError,
      error: updateStatusError,
    },
  ] = useUpdateStatusMutation();

  const [
    deleteZone,
    {
      data: deleteZoneData,
      isLoading: deleteZoneIsLoading,
      isError: deleteZoneIsError,
      error: deleteZoneError,
    },
  ] = useDeleteZoneMutation();

  const [
    getAllState,
    {
      data: getAllStateData,
      isLoading: getAllStateIsLoading,
      isError: getAllStateIsError,
      error: getAllStateError,
    },
  ] = useGetAllStateMutation();

  const [
    getAllNotAssignedDistricts,
    {
      data: getAllNotAssignedDistrictsData,
      isLoading: getAllNotAssignedDistrictsIsLoading,
      isError: getAllNotAssignedDistrictsIsError,
      error: getAllNotAssignedDistrictsError,
    },
  ] = useGetAllNotAssignedDistrictsMutation();

  const [
    addBulkLocationsToZone,
    {
      data: addBulkLocationsToZoneData,
      isLoading: addBulkLocationsToZoneIsLoading,
      isError: addBulkLocationsToZoneIsError,
      error: addBulkLocationsToZoneError,
    },
  ] = useAddBulkLocationsToZoneMutation();

  const [addLocationsPopUp, setAddLocationsPopUp] = useState(false);
  const [curZone, setCurZone] = useState();
  const [states, setStates] = useState([]);
  const [curState, setCurState] = useState({
    id : 1,
    name : "Select States"
  });
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistricts] = useState([]);

  // permissions
  const getAddZonePermissions = hasPermission("addZone");
  const getViewZonePermissions = hasPermission("viewZone");
  const getUpdateZoneStatusPermission = hasPermission("updateZoneStatus");
  const getDeleteZonePermission = hasPermission("deleteZone");
  const getViewZoneLocationPermissions = hasPermission("viewZoneLocation");

  const handleGetAllDistrictByStateId = (stateId) => {
    getAllNotAssignedDistricts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      state_id: stateId,
    });
  };

  useEffect(() => {
    if (getViewZonePermissions) {
      getAllZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
    getAllState({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    handleGetAllDistrictByStateId(curState.id);
  }, [curState]);

  useEffect(() => {
    if (getAllStateData) {
      let allStates = getAllStateData.body.map((x) => {
        return {
          id: x.id,
          name: x.state,
        };
      });
      setStates(allStates);
    }
  }, [getAllStateData, getAllStateError]);
  console.log("states",states);
  useEffect(() => {
    if (getAllNotAssignedDistrictsData) {
      console.log("------>", getAllNotAssignedDistrictsData);
      if (getAllNotAssignedDistrictsData.body.length > 0) {
        let allDistricts = [
          {
            id: 0,
            name: "All",
          },
        ];
        getAllNotAssignedDistrictsData.body.map((x) => {
          allDistricts.push({
            id: x.id,
            name: x.district,
          });
        });
        setDistricts(allDistricts);
        setSelectedDistricts([]);
      } else {
        setDistricts([
          {
            id: -1,
            name: "No Districts",
          },
        ]);
        setSelectedDistricts([
          {
            id: -1,
            name: "No Districts",
          },
        ]);
      }
    }
  }, [getAllNotAssignedDistrictsData]);

  useEffect(() => {
    if (getAllZoneData) {
      toast.dismiss();
      toast.success(getAllZoneData.message, {
        id: "getallzonedata",
      });
    }
    if (getAllZoneError) {
      toast.dismiss();
      toast.error(getAllZoneError.data.message, {
        id: "errorAtGetallzonedata",
      });
    }
  }, [getAllZoneData, getAllZoneError]);

  const handleAddLocationToZone = (e) => {
    console.log("state", curState);
    console.log("district", selectedDistrict);

    if(selectedDistrict.length == 0){
      toast.error("Please select a district");
      return ;
    }
    if(selectedDistrict[0].id == -1){
      toast.error("state has no district left");
      return;
    }
    let bodyArray=[];

    if(selectedDistrict[0].id == 0){
      console.log("districts",districts);

       districts.map(x=>{
        if(x.id != 0){
          
          bodyArray.push({
            zone_id : curZone.toString(),
            state_id : curState.id.toString(),
            district_id : x.id.toString()
          });
        }
      })
    }else{

      selectedDistrict.map(x=>{
        bodyArray.push({
          zone_id : curZone.toString(),
          state_id : curState.id.toString(),
          district_id : x.id.toString()
        });
      })
    }


    console.log("bodyArray",bodyArray);

    addBulkLocationsToZone({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        locations:bodyArray
      },
    })
  };

  useEffect(() => {
    if (addBulkLocationsToZoneData) {
      toast.dismiss();
      toast.success(addBulkLocationsToZoneData.message, {
        id: "addBulkLocationsToZoneData",
      });
      setAddLocationsPopUp(false);
    }
    if (addBulkLocationsToZoneError) {
      toast.dismiss();
      toast.error(addBulkLocationsToZoneError.data.message, {
        id: "addBulkLocationsToZoneError",
      });
    }
  }, [addBulkLocationsToZoneData, addBulkLocationsToZoneError]);

  useEffect(() => {
    if (getAllZoneIsLoading) {
      toast.dismiss();
      toast.loading("loading ...", {
        id: "getAllZoneIsLoading ",
      });
    }
  }, [getAllZoneIsLoading]);

  const handleStatusChange = (row) => {
    updateStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        zone: row.zone,
      },
    });
  };

  useEffect(() => {
    if (updateStatusData) {
      toast.dismiss();
      toast.success(updateStatusData.message, {
        id: "updateStatusData",
      });
      getAllZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
    if (updateStatusError) {
      toast.dismiss();
      toast.error(updateStatusError.data.message, {
        id: "updateStatusError",
      });
    }
  }, [updateStatusData, updateStatusError]);

  useEffect(() => {
    if (updateStatusIsLoading) {
      toast.dismiss();
      toast.loading("loading ...", {
        id: "updateStatusIsLoading ",
      });
    }
  }, [updateStatusIsLoading]);

  const handleDeleteZone = (row) => {
    deleteZone({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        zone: row.zone,
      },
    });
  };

  useEffect(() => {
    if (deleteZoneData) {
      toast.dismiss();
      toast.success(deleteZoneData.message, {
        id: "deleteZoneData",
      });
      getAllZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
    if (deleteZoneError) {
      toast.dismiss();
      toast.error(deleteZoneError.data.message, {
        id: "deleteZoneError",
      });
    }
  }, [deleteZoneData, deleteZoneError]);

  useEffect(() => {
    if (deleteZoneIsLoading) {
      toast.dismiss();
      toast.loading("loading ...", {
        id: "delete loading ",
      });
    }
  }, [deleteZoneIsLoading]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => Number(idx + 1),
      sortable: true,
      width: "70px",
      textAlign: "left",
    },
    {
      name: "Zone Name",
      cell: (row) => row.zone_name,
      sortable: true,
    },
    {
      name: "Short Name",
      cell: (row) => row.zone,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) =>
        getUpdateZoneStatusPermission &&
        (row.status == "1" ? (
          <>
            {" "}
            <button
              onClick={() => {
                handleStatusChange(row);
              }}
              className="flex justify-center items-center rounded bg-green-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              {/* <AiOutlineEdit className="mr-1" /> */}
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleStatusChange(row);
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            InActive
          </button>
        )),
    },
    {
      name: "Created At ",
      cell: (row) => row.created_at,
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        // editProductPermission && (
        <div className="">
          <button
            onClick={() => {
              setCurZone(row.id);
              setAddLocationsPopUp(true);
            }}
            className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            add Locations
          </button>
        </div>
      ),
      // ),
     
    },

    {
      name: "",
      cell: (row) => (
        getViewZoneLocationPermissions && (
        <div className="">
          <button
            onClick={() => {
              navigate("view-locations", {
                state: {
                  states: states,
                  zone_id: row.id,
                  zone_name: row.zone_name,
                },
              });
            }}
            className="flex justify-center items-center rounded bg-primary px-1.5 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            view Locations
          </button>
        </div>
      )),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          {getDeleteZonePermission && (
            <button
              onClick={() => handleDeleteZone(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          )}
        </div>
      ),
      minWidth: "120px",
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            ZONE LIST
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Zones"}
                data={getAllZoneData && getAllZoneData.body}
              ></DataTableComponent>
              {getAddZonePermissions && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
              {addLocationsPopUp && (
                <ModalComponent
                  size={"md"}
                  show={addLocationsPopUp}
                  setShow={setAddLocationsPopUp}
                >
                  <div className="w-full h-full p-3">
                    <div className="flex justify-center w-full h-full ">
                      <ol class="relative text-gray-500 w-full  h-full ">
                        

                          <div className="mb-6  w-full slctbox1 h-full dropds">
                            <SelectComponent
                              title={"States"}
                              selected={curState}
                              setSelected={setCurState}
                              dataArr={states  }
                            />
                          </div>
                        
                          <div className="mb-6  w-full slctbox1 h-full ">
                          <MultiSelectComponent
                             placeholder={"Select Districts"}
                             selected={selectedDistrict}
                             setSelected={setSelectedDistricts}
                             dataArr={districts  }
                           />
                           </div>


                        <div className="bg-red">
                          <button className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]" onClick={(e) => handleAddLocationToZone(e)}>
                            Add
                          </button>
                        </div>
                      </ol>
                    </div>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Zones;
