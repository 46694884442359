export const CircleLoaderComponent = () => {
  return (
    <>
     <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-filter backdrop-blur-lg">
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div className="relative">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-white">
          <svg className="w-8 h-8 text-gray-800 animate-spin" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2.942 7.943a8.001 8.001 0 01-7.887-4.011H0c0 4.418 3.582 8 8 8v-4a7.995 7.995 0 01-1.058-3.943z"></path>
          </svg>
        </div>
        <div className="mt-4 text-gray-100 text-sm">Loading...</div>
      </div>
    </div>
    </>
  );
};
