import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineDelete } from "react-icons/ai";
import { GrDocumentPdf } from "react-icons/gr";
import {
  useAddProductInstallationVideosMutation,
  useDeleteProductInstallationVideosMutation,
  useFetchProductInstallationVideosMutation,
  useUpdateProductInstallationVideosMutation,
} from "../../../features/tenant/productInstallationVideo/productInstallationVideo";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import toast from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { downloadSampleCSV } from "../../../utils/downloadSampleCsv";

const ProductInstallationVideo = () => {
  const navigate = useNavigate();
  console.log(`fslfsjfljl-->${process.env.REACT_APP_BASE_URL}`);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  let hasViewPermission = hasPermission('allProductInstallationVideosList');
  let hasAddPermission = hasPermission('addProductInstallationVideos');
  let hasDeletePermission = hasPermission('deleteProductInstallationVideos');
  let statusChangePermission = hasPermission('editProductInstallationVideos');

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [currentVideoLink, setCurrentVideoLink] = useState(null);
  const [currentVideoPopUp, setCurrentVideoPopUp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState({});

  const [
    fetchProductInstallationVideos,
    {
      data: fetchProductInstallationVideosData,
      isLoading: fetchProductInstallationVideosIsLoading,
      isError: fetchProductInstallationVideosIsError,
      error: fetchProductInstallationVideosError,
    },
  ] = useFetchProductInstallationVideosMutation();
  const [
    deleteProductInstallationVideos,
    {
      data: deleteProductInstallationVideosData,
      isLoading: deleteProductInstallationVideosIsLoading,
      isError: deleteProductInstallationVideosIsError,
      error: deleteProductInstallationVideosError,
    },
  ] = useDeleteProductInstallationVideosMutation();
  const [
    updateProductInstallationVideos,
    {
      data: updateProductInstallationVideosData,
      isLoading: updateProductInstallationVideosIsLoading,
      isError: updateProductInstallationVideosIsError,
      error: updateProductInstallationVideosError,
    },
  ] = useUpdateProductInstallationVideosMutation();
  const [
    addProductInstallationVideos,
    {
      data: addProductInstallationVideosData,
      isLoading: addProductInstallationVideosIsLoading,
      isError: addProductInstallationVideosIsError,
      error: addProductInstallationVideosError,
    },
  ] = useAddProductInstallationVideosMutation();

  const handleFetchProductInstallationVideo = (limit, offset) => {
    fetchProductInstallationVideos({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };
  useEffect(() => {
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    handleFetchProductInstallationVideo(limit, offset);
  }, [limit, offset]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleUpdateStatusProductInstallationVideo = (videoId, status) => {
    console.log("status", status);
    console.log("videoId", videoId);
    if (window.confirm("are you sure you want to update this status")) {
      updateProductInstallationVideos({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: videoId,
        body: {
          status: status,
        },
      });
    }
    return;
  };

  useEffect(() => {
    if (updateProductInstallationVideosData) {
      toast.dismiss();
      toast.success(updateProductInstallationVideosData.message, {
        id: "updateProductInstallationVideosData",
      });
      handleFetchProductInstallationVideo(limit, offset);
    }
    if (updateProductInstallationVideosError) {
      toast.dismiss();
      toast.error(updateProductInstallationVideosError.data.message, {
        id: "updateProductInstallationVideosError",
      });
    }
  }, [
    updateProductInstallationVideosData,
    updateProductInstallationVideosError,
  ]);

  const handleDeleteStatusProductInstallationVideo = (videoId) => {
    console.log("lsfsdljlasjfasjfljddfs", videoId);
    if (window.confirm("are you sure you want to delete ")) {
      deleteProductInstallationVideos({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: videoId,
      });
    }
    return;
  };

  useEffect(() => {
    if (deleteProductInstallationVideosData) {
      toast.dismiss();
      toast.success(deleteProductInstallationVideosData.message, {
        id: "deleteProductInstallationVideosData",
      });
      handleFetchProductInstallationVideo(limit, offset);
    }
    if (deleteProductInstallationVideosError) {
      toast.dismiss();
      toast.error(deleteProductInstallationVideosError.data.message, {
        id: "deleteProductInstallationVideosError",
      });
    }
  }, [
    deleteProductInstallationVideosData,
    deleteProductInstallationVideosError,
  ]);

  useEffect(() => {
    console.log("ima flsdafadsj-->", file);
  }, [file]);

  function parseCSV(string) {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .replace(/\s/g, "")
      .split(",");
  
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
  
    const array = csvRows
      .map((row) => row.split(","))
      .filter((values) => values.length === csvHeader.length)
      .map((values) => {
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index].trim();
          return object;
        }, {});
        return obj;
      });
  
    return array;
  }

  const handleSubmit = (e) => {
    console.log("files --->", file);
    if (!file) {
      console.error("No file selected");
      return;
    }

    let productInstallationVideoData = [];

    const fileReader = new FileReader();
    fileReader.onload = function (event) {
      const text = event.target.result;
      console.log("Text is Consoled Here:", text);

      let datas = parseCSV(text);
      datas = datas.map((dataItem) => {
        return dataItem;
      });

      console.log("data", datas);
      productInstallationVideoData = [...datas];
      console.log("productInstallationVideoData", productInstallationVideoData);
      addProductInstallationVideos({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          data: productInstallationVideoData,
        },
      });
    };

    fileReader.readAsText(file);
  };

  useEffect(() => {
    if (addProductInstallationVideosData) {
      toast.dismiss();
      toast.success(addProductInstallationVideosData.message, {
        id: "addProductInstallationVideosData",
      });
      handleFetchProductInstallationVideo(limit, offset);
    }
    if (addProductInstallationVideosError) {
      toast.dismiss();
      toast.error(addProductInstallationVideosError.data.message, {
        id: "addProductInstallationVideosError",
      });
    }
  }, [addProductInstallationVideosData, addProductInstallationVideosError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Product Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Link",
      cell: (row) => (
        <>
          <button
            onClick={(row) => {
              setCurrentVideoLink(row.link);
              setCurrentVideoPopUp(true);
            }}
          >
            <svg
              class="h-8 w-8 text-red-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              {" "}
              <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />{" "}
              <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
            </svg>
          </button>
        </>
      ),
      sortable: true,
      width: "120px",
    },

    {
      name: "Status",
      cell: (row) =>
        row.status == "1" ? (
          <>
            {statusChangePermission && (
              <button
                onClick={() => {
                  handleUpdateStatusProductInstallationVideo(row.id, "2");
                }}
                className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                {/* <AiOutlineEdit className="mr-1" /> */}
                Active
              </button>
            )}
          </>
        ) : (
          <>
            {statusChangePermission && (
              <button
                onClick={() => {
                  handleUpdateStatusProductInstallationVideo(row.id, "1");
                }}
                className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                {/* <AiOutlineEdit className="mr-1" /> */}
                InActive
              </button>
            )}
          </>
        ),
      sortable: true,
    },

    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {hasDeletePermission && (
            <button
              onClick={() => handleDeleteStatusProductInstallationVideo(row.id)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          )}
        </div>
      ),
      minWidth: "130px",
    },
  ];

  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Product Installation Videos
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                data={
                  fetchProductInstallationVideosData &&
                  fetchProductInstallationVideosData?.body?.data
                }
                // loading={loading}
                totalRows={
                  fetchProductInstallationVideosData &&
                  fetchProductInstallationVideosData?.body?.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {hasAddPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => setShowModal(true)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
              {currentVideoPopUp && (
                <ModalComponent
                  size={"3xl"}
                  data={"nadnandand"}
                  show={currentVideoPopUp}
                  setShow={setCurrentVideoPopUp}
                >
                  <div className="w-full">
                    <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
                      <h2 className="sr-only"> Video</h2>

                      <div className="w-full">
                        <iframe
                          className="m-auto mt-5 w-full"
                          src={"//www.youtube.com/embed/" + currentVideoLink}
                          frameborder="0"
                          height={400}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </ModalComponent>
              )}

              {showModal && (
                <ModalComponent
                  size={"lg"}
                  data={"nadnandand"}
                  show={showModal}
                  setShow={setShowModal}
                >
                  <div className="w-full p-4">
                  {
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() =>
                      downloadSampleCSV([
                        {
                          name: "name",
                        },
                        {
                          name: "link",
                        },
                        
                      ])
                    }
                  >
                    Sample Csv Download
                  </button>
                </div>
              }
                    <div className="px-3 flex flex-col justify-center align-middle m-auto ">
                      <div className="flex items-center justify-center w-full">
                        <label
                          for="dropzone-file"
                          className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                        >
                          <div className="flex flex-col items-center justify-center ">
                            <svg
                              aria-hidden="true"
                              className="w-20 h-20 mb-3 text-gray-400 mt-2"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                strokeLinejoin="round"
                                stroke-width="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 text-center">
                              <span className="font-semibold">
                                Choose CSV file
                              </span>
                              <div>Click to upload or drag and drop</div>
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-center mt-2">
                      {Object.keys(file).length > 0 && file.name}
                    </div>

                    <div className="flex justify-center mt-3">
                      <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={(e) => handleSubmit(e)}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInstallationVideo;
