import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Column from "./Column";

function WorkflowBoard({ boardObject }) {
  const boards = useSelector((state) => state.boards);
  const [colData, setcolData] = useState([]);

  useEffect(() => {
    // console.log("workflow------>", boards);
    if (boards) {
      const board = boards.find((board) => board.isActive === true);
      setcolData(board?.columns);
    }
  }, [boards]);

  return (
    <div className="d-flex justify-content-between w-100">
      {/* Columns Section */}
      {colData && colData.length > 0 ? (
        <>
          {colData.map((col, index) => (
            <Column key={index} colIndex={index} />
          ))}
        </>
      ) : (
        <>
          <div
            className="alert alert-danger w-100 text-center "
            style={{ height: "50px" }}
            role="alert"
          >
            Users or Selected Programs does not exists
          </div>
        </>
      )}
    </div>
  );
}

export default WorkflowBoard;
