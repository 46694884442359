import { rootApi } from "../../api/rootApi";

export const productWarrantyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({

    addBatchOfProductsWithWarranty: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/productWarranty/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: { batch : params.body},
        };
      },
    }),
 
  }),
});

export const { useAddBatchOfProductsWithWarrantyMutation} = productWarrantyApi;
