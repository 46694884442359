import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      beginAtZero: true,
      min: 0,
    },
  },
  layout: {
    padding: {
      top: 0,
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const darkColors = [
  "#004EDE",
  "#F66B4E",
  "#1CA2D5",
  "#00A54D",
  "#3B2947",
  "#19abde",
  "#1de4bd",
  "#6df0d1",
  "#29066c",
  "#7d39c0",
  "#da4cb2",
  "#ec548d",
  "#e97369",
  "#f0a58e",
  "#810400",
  "#bf2324",
  "#de542d",
  "#ef7e32",
  "#eabe3b",
];

export function StackedBarChart({ allData }) {
  const datasets = allData.map((dataItem, index) => ({
    label: dataItem.user_type,
    data: dataItem.warranty,
    backgroundColor: darkColors[index],
  }));

  const data = {
    labels,
    datasets,
  };

  console.log(allData, "All Data is saved");
  return data && <Bar options={options} data={data} />;
}
