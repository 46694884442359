export function downloadSampleCSV(templateData) {
  if (!templateData || templateData.length === 0) {
    alert("No Registration Template Available !");
    return;
  }

  const csvContent = templateData.map((item) => item.name).join(",");

  const sampleData = templateData.map((item) => "Sample" + item.name).join(",");

  const csv = `${csvContent}\n${sampleData}`;

  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

  const a = document.createElement("a");
  a.href = url;

  a.download = `sample_${timestamp}.csv`;

  a.click();
  window.URL.revokeObjectURL(url);
}
