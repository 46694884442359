import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useAddAppGalleryMutation } from "../../../../features/tenant/appGallery/appGallery.Api";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../../utils/getVendorName";
import { hasPermission } from "../../../../utils/hasPermission";

const Gallery = () => {
  const [title, setTitle] = useState("");
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  // const [type, setType] = useState("");

  const navigate = useNavigate();
  const [
    addGallery,
    {
      data: addAppGalleryData,
      isLoading: addAppGalleryIsLoading,
      isError: addAppGalleryIsError,
      error: addAppGalleryError,
    },
  ] = useAddAppGalleryMutation();

  useEffect(() => {

    const hasViewPermission = hasPermission("gallery");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    if (addAppGalleryData) {
      toast.success("Images Added");
      navigate(`/genefied/${getVendorName()}/gallery`)
    }
  }, [addAppGalleryData, addAppGalleryError]);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (images.length === 0) {
      toast.error("Please provide at least one image", {
        id: "imageError",
      });
      return;
    }
    if (!title) {
      toast.error("Please provide a Title", {
        id: "imageError",
      });
      return;
    }

    // if (!type) {
    //   toast.error("Please provide a type", {
    //     id: "imageError",
    //   });
    //   return
    // }

    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }

    // formData.append('images', images);

    formData.append("title", title);
    formData.append("type", "event");

    addGallery({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: formData,
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const imageFilesLength = files.length;
    let img = [];
    if (imageFilesLength && files.length) {
      for (let i = 0; i < imageFilesLength; i++) {
        const imageSrc = URL.createObjectURL(files[i]);
        img.push(imageSrc);
      }
      setPreviewImage(img);
    }

    setImages(files);
  };

  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Gallery
            <LinksComponent />
          </div>
          <div className=" mx-9 mt-10">
            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-fit p-4 px-5 justify-center">
              <div className="w-96 flex flex-col justify-center align-middle  ">
                <div className="mb-6 ">
                  <InputComponent
                    name={"Title"}
                    type={"text"}
                    value={title}
                    setValue={setTitle}
                    required={true}
                    placeholder={"Enter Title"}
                  />
                </div>

                {/* <div className="mb-6 ">
                  <InputComponent
                    name={"Type"}
                    type={"text"}
                    value={type}
                    setValue={setType}
                    required={true}
                  />
                </div> */}
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-red-600">
                        PNG or JPG (800x400px).
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      multiple
                      onChange={(e) => handleImageChange(e)}
                    />
                  </label>
                </div>
                {/* <div className="flex  m-auto">
        <div className="flex justify-center  flex-wrap  w-full">
          {previewImage.length > 0 &&
            previewImage.map((img, index) => (
              <img
                src={img}
                alt=""
                srcSet=""
                key={index}
                className=" max-w-xs pb-3 pt-3 m-auto"
              />
            ))}
        </div>
      </div> */}
                <div className="flex flex-row mt-2 mb-2 justify-center">
                  <div className=" flex justify-center ">
                    {previewImage.length > 0 &&
                      previewImage.map((img, index) => (
                        <div className="h-16 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">

                        <img
                          src={img}
                          alt=""
                          srcSet=""
                          key={index}
                          className="h-full w-full object-cover object-center group-hover:opacity-75"
                          />
                          </div>
                      ))}
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
