import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utils/hasPermission";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDeleteWhatsappNotificationTemplateForTenantMutation, useGetWhatsappNotificationTemplateWithLimitOffsetForTenantMutation, useUpdateWhatsappNotificationTemplateStatusForTenantMutation } from "../../../features/tenant/whatsappNotificationTemplates/whatsappNotificationTemplatesApi";
import { getVendorName } from "../../../utils/getVendorName";
import toast from "react-hot-toast";

const WhatsappNotificationTemplates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location at SMS template", location);
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const addWhatsappNotificationTemplatePermission = hasPermission(
    "addWhatsappNotificationTemplateSetup"
  );
  const editWhatsappNotificationTemplatePermission = hasPermission(
    "editWhatsappNotificationTemplateSetup"
  );
  const deleteWhatsappNotificationTemplatePermission = hasPermission(
    "deleteWhatsappNotificationTemplateSetup"
  );
  const updateWhatsappNotificationTemplateStatusPermission = hasPermission(
    "updateWhatsappNotificationTemplateSetup"
  );

  const viewWhatsappNotificationTemplateStatusPermission = hasPermission(
    "updateWhatsappNotificationTemplateStatusSetup"
  );

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    getWhatsappNotificationTemplateWithLimitOffsetForTenant,
    {
      data: getWhatsappNotificationTemplateWithLimitOffsetForTenantData,
      isLoading: getWhatsappNotificationTemplateWithLimitOffsetForTenantIsLoading,
      isError: getWhatsappNotificationTemplateWithLimitOffsetForTenantIsError,
      error: getWhatsappNotificationTemplateWithLimitOffsetForTenantError,
    },
  ] = useGetWhatsappNotificationTemplateWithLimitOffsetForTenantMutation();

  const [
    updateWhatsappNotificationTemplateStatusForTenant,
    {
      data: updateWhatsappNotificationTemplateStatusForTenantData,
      isLoading: updateWhatsappNotificationTemplateStatusForTenantIsLoading,
      isError: updateWhatsappNotificationTemplateStatusForTenantIsError,
      error: updateWhatsappNotificationTemplateStatusForTenantError,
    },
  ] = useUpdateWhatsappNotificationTemplateStatusForTenantMutation();

  const [
    deleteWhatsappNotificationTemplateForTenant,
    {
      data: deleteWhatsappNotificationTemplateForTenantData,
      isLoading: deleteWhatsappNotificationTemplateForTenantIsLoading,
      isError: deleteWhatsappNotificationTemplateForTenantIsError,
      error: deleteWhatsappNotificationTemplateForTenantError,
    },
  ] = useDeleteWhatsappNotificationTemplateForTenantMutation();

  const fetchWhatsappNotificationTemplates = (limit, offset) => {
    getWhatsappNotificationTemplateWithLimitOffsetForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
      account_id : location.state.id
    });
  };

 
  useEffect(() => {
    

    if (!viewWhatsappNotificationTemplateStatusPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchWhatsappNotificationTemplates(limit, offset);
  }, [limit, offset]);

  useEffect(() => {
    if (getWhatsappNotificationTemplateWithLimitOffsetForTenantData) {
      toast.dismiss();
      toast.success(getWhatsappNotificationTemplateWithLimitOffsetForTenantData.message, {
        id: "getWhatsappNotificationTemplateWithLimitOffsetForTenantData",
      });
    }
    if (getWhatsappNotificationTemplateWithLimitOffsetForTenantError) {
      toast.dismiss();
      toast.error(getWhatsappNotificationTemplateWithLimitOffsetForTenantError.data.message, {
        id: "getWhatsappNotificationTemplateWithLimitOffsetForTenantError",
      });
    }
  }, [
    getWhatsappNotificationTemplateWithLimitOffsetForTenantData,
    getWhatsappNotificationTemplateWithLimitOffsetForTenantError,
  ]);


  const handleStatusChange = (row) => {
    if (updateWhatsappNotificationTemplateStatusPermission) {
        updateWhatsappNotificationTemplateStatusForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: Number(row.id),
      });
    } else {
      toast.error("You don't have permission to change status", {
        id: "You can not update the status",
      });
    }
  };

  useEffect(() => {
    if (updateWhatsappNotificationTemplateStatusForTenantData) {
      toast.dismiss();
      toast.success(updateWhatsappNotificationTemplateStatusForTenantData.message, {
        id: "getWhatsappNotificationTemplateWithLimitOffsetForTenantData",
      });
      fetchWhatsappNotificationTemplates(limit, offset);
    }
    if (updateWhatsappNotificationTemplateStatusForTenantError) {
      toast.dismiss();
      toast.error(updateWhatsappNotificationTemplateStatusForTenantError.data.message, {
        id: "updateWhatsappNotificationTemplateStatusForTenantError",
      });
    }
  }, [
    updateWhatsappNotificationTemplateStatusForTenantData,
    updateWhatsappNotificationTemplateStatusForTenantError,
  ]);


  const handleDeleteTemplate = (row) => {
    if (deleteWhatsappNotificationTemplatePermission) {
      deleteWhatsappNotificationTemplateForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: Number(row.id),
      });
    } else {
      toast.error("You don't have permission to delete whatsapp template", {
        id: "You can not detele SMS template",
      });
    }
  };

  
  useEffect(() => {
    if (deleteWhatsappNotificationTemplateForTenantData) {
      toast.dismiss();
      toast.success(deleteWhatsappNotificationTemplateForTenantData.message, {
        id: "deleteWhatsappNotificationTemplateForTenantData",
      });
      fetchWhatsappNotificationTemplates(limit, offset);
    }
    if (deleteWhatsappNotificationTemplateForTenantError) {
      toast.dismiss();
      toast.error(deleteWhatsappNotificationTemplateForTenantError.data.message, {
        id: "deleteWhatsappNotificationTemplateForTenantError",
      });
    }
  }, [
    deleteWhatsappNotificationTemplateForTenantData,
    deleteWhatsappNotificationTemplateForTenantError,
  ]);

  const handleEditTemplate = (row) => {
    if (editWhatsappNotificationTemplatePermission) {
      navigate("add", {
        state: {
          row: location && location.state,
          edit: true,
          data: row,
        },
      });
    } else {
      toast.error("You don't have permission to edit SMS template", {
        id: "You can not edit SMS template",
      });
    }
  };


  



  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Template Id ",
      cell: (row) => {
        return row.template_id;
      },
      sortable: true,
    },
    {
      name: "Message Template",
      cell: (row) => row.template_text,
      sortable: true,
    },
    {
      name: "type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "sender Id",
      cell: (row) => row.sender_id,
      sortable: true,
    },
    {
      name: "",
      cell: (row) =>
        row.status == "1" ? (
          <>
            {" "}
            <button
              onClick={() => {
                handleStatusChange(row, "2");
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              {/* <AiOutlineEdit className="mr-1" /> */}
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleStatusChange(row, "1");
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            InActive
          </button>
        ),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        // editProductPermission && (
        <div className="">
          <button
            onClick={() => {
                handleEditTemplate(row);
            }}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            edit
          </button>
        </div>
      ),
      // ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) => (
        // deleteProductPermission &&
        <button
          onClick={() => handleDeleteTemplate(row)}
          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        >
          <AiOutlineDelete className="mr-1" />
          Delete
        </button>
      ),
      minWidth: "130px",
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Whatsapp Notification Templates
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                title={"Whatsapp Notification Template List"}
                data={
                    getWhatsappNotificationTemplateWithLimitOffsetForTenantData &&
                    getWhatsappNotificationTemplateWithLimitOffsetForTenantData.body.data
                }
                // loading={productsIsLoading}
                totalRows={
                    getWhatsappNotificationTemplateWithLimitOffsetForTenantData &&
                    getWhatsappNotificationTemplateWithLimitOffsetForTenantData.body.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              {addWhatsappNotificationTemplatePermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`,{
                            state : {
                                data : location.state
                            }
                          })}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsappNotificationTemplates;
