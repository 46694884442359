import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchVendorsThemeByNameMutation } from '../../../features/admin/vendorTheme/vendorThemeApi';

const DataDeletion = () => {

    const navigate = useNavigate();
    const [userType, setUserType] = useState(-1);

    const [logo, setLogo] = useState(null);

    const [tenantData, setTenantData] = useState(null);
    const location = useLocation();

    const [
        getVendorTheme,
        {
            data: vendorThemeData,
            isLoading: vendorThemeIsLoading,
            isError: vendorThemeIsError,
            error: vendorThemeError,
        },
    ] = useFetchVendorsThemeByNameMutation();

    useEffect(() => {
        const path = location.pathname.split("/");
        console.log("data-deletion-path", path)
        if (path.length >= 2 && path[1] === "data-deletion" && path[2]) {
            // setTenantName(path[2]);
            getVendorTheme({
                params: path[2],
            });
        }
    }, []);


    useEffect(() => {

        if (vendorThemeData && vendorThemeData?.body) {
            setTenantData(vendorThemeData.body)
            const path = location.pathname.split("/");

            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
            }


            if (path.length >= 2 && path[1] === "data-deletion" && path[2]) {
                link.href =

                    vendorThemeData?.body?.logo[0];
                setLogo(vendorThemeData?.body?.logo[0])
                document.title = `${vendorThemeData?.body?.tenant_name?.toUpperCase()} | Genefied SAAS`;
            } else {
                console.log("admin");
                link.href = "https://www.genefiedtech.com/assets/images/favicon.ico.png";
                document.title = "Genefied - SAAS ";

            }
        }
    }, [vendorThemeData]);

    console.log("logo", logo)


    return (
        <div className="bglog">
            <section className="data-deletion ">
                <div className="data-deletion-page-logo w-full flex justify-center">
                    <img src={logo} alt="Demo Logo" />
                </div>


                <div className="data-deletion-box pb-3">
                    <h1 className="text-center">Data Deletion Policy</h1>

                    {tenantData &&
                        <div className="deletion-request-info mt-4">
                            <h4 className='text-lg text-center mb-3'>{tenantData.name.toUpperCase()} Account Deletion Request</h4>
                            <p className='text-base font-semibold'>Hello {tenantData.name.toUpperCase()} Users,</p>
                            <p className='py-2'>
                                At {tenantData.name.toUpperCase()}, we value your privacy and believe in giving you control over
                                your data. If you wish to delete your account and associated data,
                                please follow the simple steps outlined below:
                            </p>

                            {/* Steps to Request Account Deletion */}
                            <h6 className='font-semibold'>Steps to Request Account Deletion:</h6>
                            <p className='py-2 list-disc ml-4'>
                                <li>Open {tenantData.name.toUpperCase()} App.</li>
                                <li>Navigate to the Drawer.</li>
                                <li>Open Profile.</li>
                                <li>Find and select “Edit Button”.</li>
                                <li>Look for the "Delete Account" icon.</li>
                                <li>Follow the on-screen instructions to confirm your decision.</li>
                            </p>

                            {/* Account Deletion Process */}
                            <h6 className='font-semibold'>Account Deletion Process:</h6>
                            <p className='py-2 list-disc ml-4'>
                                <li>

                                    Upon receiving your account deletion request, we will promptly begin
                                    the process to delete your account and associated data permanently.
                                </li>
                            </p>

                            {/* Types of Data Deleted */}
                            <h6 className='font-semibold'>Types of Data Deleted:</h6>
                            <p className='py-2 list-disc ml-4'>
                                <li>Personal information (e.g., name, email, profile details).</li>
                                <li>Usage data (e.g., app activity, preferences).</li>
                            </p>

                            {/* Types of Data Retained */}
                            <h6 className='font-semibold'>Types of Data Retained:</h6>
                            <p className='py-2 list-disc ml-4'>
                                <li>

                                    Anonymized, aggregated data for analytical purposes, stripped of
                                    personally identifiable information.
                                </li>
                            </p>

                            {/* Privacy and Support Information */}
                            <p className='py-2 list-disc ml-4'>
                                <li>

                                    Your privacy matters to us, and we are committed to ensuring a
                                    transparent and secure process for account deletion. If you have any
                                    questions or concerns, please contact our support team at{" "}
                                    <a className='text-blue-700 font-semibold' href="mailto:support@genuinemark.org">support@genuinemark.org</a> {" "} and {" "}
                                    <a className='text-blue-700 font-semibold' href={`mailto:${tenantData.email}`}>{tenantData.email}</a>.
                                </li>
                            </p>

                            <p className='py-2'>Thank you for being a part of the {tenantData.name.toUpperCase()} community.</p>
                        </div>
                    }


                </div>
            </section>

        </div>
    );
};

export default DataDeletion