import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useFetchAllAppUserTypeQuery } from "../../../features/admin/AppUserType/appUserTypeApi";

const AddIndustry = () => {
  const [indusrtyName, setIndusrtyName] = useState("");
  const [newUserValue, setNewUserValue] = useState("");
  const [userTypes, setUsersTypes] = useState([]);

  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const {
    data: allAppUserTypeData,
    isError: allAppUserTypeIsError,
    error: allAppUserTypeError,
    isLoading: allAppUserTypeIsLoading,
    refetch: allAppUserTypeRefetch,
  } = useFetchAllAppUserTypeQuery({
    token: sessionStorage.getItem("access_token"),
  });

  useEffect(() => {
    toast.remove();
  }, []);

  const removeFromUserTypes = (e, key) => {
    console.log(key);
    let oldValues = [...userTypes];
    oldValues = oldValues.filter((user) => user != key);
    setUsersTypes(oldValues);
  };

  const cancelAddIndustry = () => {
    navigate("/industryMaster");
  };
  const handleUserTypeSelection = (e, type) => {
    console.log(type);
    let copiedValue = [...userTypes];
    copiedValue[type] = copiedValue[type] ? false : true;
    setUsersTypes(copiedValue);
    //console.log(productsAvailing);
  };

  const addIndustry = () => {
    setError(null);
    if (indusrtyName.length > 0) {
      // Hit the Api for adding product category

      var data = {
        name: indusrtyName,
        users: userTypes,
      };

      console.log(data);

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/admin/industry/add`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          allAppUserTypeRefetch();
          console.log(response.data);
          if (response.data.success) {
            toast.success(response.data.message, {
              id: "addIndustrySuccess",
            });

            setTimeout(() => {
              navigate("/industryMaster");
            }, 500);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error(
            error.response?.data?.message
              ? error.response.data.message
              : error.message,
            {
              id: "addOptionError",
            }
          );
        });
    } else {
      // add logic to display the error
      toast.error("Please enter industry name", {
        id: "notValidIndustry",
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="borderbtm">
                <h4 className="card-title mb-0">Add New Industry</h4>
                </div>

                <div className="cmxform" id="signupForm">
                  <div className="frmds row">
                    <div className="col-md-6 mt-3">
                      <label for="name">Industry Name </label>
                      <input
                        id="name"
                        className="form-control"
                        value={indusrtyName}
                        onChange={(e) => {
                          setIndusrtyName(e.target.value);
                        }}
                        type="text"
                      />
                    </div>

                   
                  </div>
                  <div className="frmds row">
                  <div className="col-md-6 my-3">
                      <div>
                        <label className="text-capitalize">
                          {" "}
                          Users
                        </label>
                      </div>
                      <select
                          type="text"
                          className=""
                          placeholder="Add new UserType"
                          value={newUserValue}
                          // onChange={(e) => setNewUserValue(e.target.value)}
                          onChange={(e) => {
                            // if (e.keyCode == 13) {
                            console.log("----------->", e.target.value);
                            setNewUserValue(e.target.value);
                            console.log("hello this i stushar");
                            let oldUserTypes = [...userTypes];
                            console.log(oldUserTypes);
                            oldUserTypes.push(e.target.value);
                            // addNewUserTypeInObj(e.target.value);
                            setUsersTypes(oldUserTypes);
                            setNewUserValue("");
                            // }
                          }}
                        >
                          <option value={newUserValue}>select</option>
                          {allAppUserTypeData &&
                            allAppUserTypeData?.body.map((x) => {
                              if (userTypes.toString().includes(x.user_type)) {
                                return <></>;
                              }
                              return (
                                <>
                                  <option value={x.user_type}>
                                    {x.user_type}
                                  </option>
                                </>
                              );
                            })}
                        </select>

                      {userTypes.map((key) => (
                        <label className="mt-2" key={key}>
                          <span
                            className="mr-2 text-capitalize btn btn-outline-danger"
                            onClick={(e) => removeFromUserTypes(e, key)}
                          >
                            {key}
                          </span>
                        </label>
                      ))}

                    
                    </div>
                    </div>
                
                    <div className="d-flex  mt-3 ">
                      <button
                        type="submit"
                        className="btn btn-outline-primary mr-2 btnall"
                        onClick={addIndustry}
                      >
                        Add Industry
                      </button>

                      <button
                        type="cancel"
                        className="btn btn-outline-danger mr-2 btnall"
                        onClick={cancelAddIndustry}
                      >
                        Cancel
                      </button>
                    </div>
                    
                  
                  {error && (
                    <div className="alert alert-danger mt-4" role="alert">
                      {error}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddIndustry;
