import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
} from "chart.js";
import LoaderComponent from "../../Loader/LoaderComponent";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);

export const options = {
  responsive: true,
  scales: {
    y: {
      type: 'logarithmic',
      min: 1,
      ticks: {
        callback: function (value, index, values) {
          return Number(value.toString());
        },
      },
    },
  },
};

const GenerationVsScanning = ({ data , isLoading = false }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Month is zero-based, so we add 1 to get the current month

  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const [selectedWeek, setSelectedWeek] = useState();
  const [weeksData, setWeeksData] = useState([]);

  const years = [...new Set(data.map((item) => item.year))];
  const months = selectedYear
    ? Array.from({ length: 12 }, (_, i) => i + 1)
    : [];
  const weeksInMonth =
    selectedYear && selectedMonth
      ? [
          ...new Set(
            data
              .filter(
                (item) =>
                  parseInt(item.year) === parseInt(selectedYear) &&
                  parseInt(item.month) === parseInt(selectedMonth)
              )
              .map((item) => parseInt(item.week_in_month))
          ),
        ]
      : [];

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const weeksData = weeksInMonth.map((week) => {
        const generationTotal = data
          .filter(
            (item) =>
              parseInt(item.year) === parseInt(selectedYear) &&
              parseInt(item.month) === parseInt(selectedMonth) &&
              parseInt(item.week_in_month) === parseInt(week)
          )
          .reduce((acc, cur) => acc + parseInt(cur.total_qrs), 0);

        const scanningTotal = data
          .filter(
            (item) =>
              parseInt(item.year) === parseInt(selectedYear) &&
              parseInt(item.month) === parseInt(selectedMonth) &&
              parseInt(item.week_in_month) === parseInt(week)
          )
          .reduce((acc, cur) => acc + parseInt(cur.scanned_qrs), 0);

        return {
          week: week,
          generationTotal: parseInt(generationTotal),
          scanningTotal: parseInt(scanningTotal),
        };
      });
      setWeeksData(weeksData);
    }
  }, [data, selectedYear, selectedMonth]);

  const monthNames = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  const chartData = {
    labels: weeksData.map((weekData) => `Week ${weekData.week}`),
    datasets: [
      {
        label: "Generation Total",
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        data: weeksData.map((weekData) => weekData.generationTotal),
      },
      {
        label: "Scanning Total",
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        data: weeksData.map((weekData) => weekData.scanningTotal),
      },
    ],
  };

  const handleWeekSelect = (week) => {
    setSelectedWeek(week);
  };

  if (isLoading) {
    return <div className="flex justify-center items-center mt-4 mb-4">
      <LoaderComponent/>
    </div>;
  } else {

  return (
    <div>
      <div className="flex justify-center items-center px-5 mt-2 space-x-5 ">
        <div className="w-1/3 rounded-md">
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="w-1/3 rounded-md">
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">Select Month</option>
            {years.length > 0 && months.map((month, index) => (
              <option key={month} value={month}>
                {monthNames[index]}
              </option>
            ))}
          </select>
        </div>
      </div>
      {weeksData.length > 0 ? (
        <div className="max-h-80 mx-auto flex justify-center items-center">
          <Bar data={chartData} options={options} />
        </div>
      ) : (
        <div className="flex justify-center items-center text-lg font-bold pt-5">
          No data available for the selected filters.
        </div>
      )}
    </div>
  );
      }
};

export default GenerationVsScanning;
