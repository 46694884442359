import React, { useEffect } from 'react'
// import { useFetchADwanQuery, useFetchDwanQuery } from '../features/dwan/dwanApi';
// import { useFetchGmQuery } from '../features/gm/gmApi';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchFormTemplateQuery } from '../features/admin/formTemplate/formTemplateApi';
import { useFetchMessageTemplateQuery } from '../features/admin/messageTemplate/messageTemplateApi';
import { convertLength } from '@mui/material/styles/cssUtils';
import { useAdminLoginMutation } from '../features/admin/adminAuth/adminAuthApi';
import { setAdminCredential } from '../features/admin/adminAuth/adminAuthSlice';
// import { useAddIndustryMutation, useFetchIndustriesQuery } from '../features/admin/industryMaster/industryMasterApi';
// import { setDwan } from '../features/dwan/dwanSlice';
// import { setGm } from '../features/gm/gmSlice';
// import { increment } from '../features/counter/counterSlice';

const Dwan = () => {
    const dispatch = useDispatch();

    const token = sessionStorage.getItem("access_token")
    // const { data: dwan,  } = useFetchDwanQuery(token) || {};
    // const { data: gm, isLoading, isError, error } = useFetchGmQuery(token) || {};
    // const { data: dwambyid  } = useFetchADwanQuery(token,1) || {};
    // console.log("dwan data ", dwan);
    // console.log("gm data ", gm);
    // console.log("gm data by id  ", dwambyid);
    // const addDwan = ()=>{
    //     console.log("Enter button -=-->",dwan);
    //     dispatch(setDwan(dwan))
    // }
    // const addGm = ()=>{
    //     console.log("Enter button gmmmmmmmmm -=-->",gm);
    //     dispatch(setGm(gm))
    // }
// const incrementBtn = ()=>{
//     dispatch(increment())
// }
// const { data: formTemplateData } = useFetchFormTemplateQuery(token) || {};

// const { data: emailTemplateData } = useFetchEmailTemplateQuery(token) || {};
// const { data: messageTemplateData } = useFetchMessageTemplateQuery(token) || {};
const [ loginAdmin, { data ,isLoading, isError, error } ] = useAdminLoginMutation();
const cred = useSelector((state) => state?.adminCredentials);

// useEffect(()=>{
//     console.log("data inside use Effect",data);
// },[data])
// const [ addIndustry ] = useAdminLoginMutation();
// const [ addIndustry ,{indust , isError  ,error  }] = useAddIndustryMutation() || {};
// console.log("formTemplateData", formTemplateData);
// console.log("emailTemplateData", emailTemplateData);
// console.log("messageTemplateData", messageTemplateData);
// console.log("Industries", industries);

console.log("didMount");
const loginButton  = ()=>{
    loginAdmin({
        "email" : "tushar2@gmail.com",
        "password" : "Tushar2@1234"
    })
    console.log("before selector ",cred);
    console.log("data inside func",data);
    // dispatch(setAdminCredential(data.body));
    // console.log("after selector ",cred);

}
console.log("data outside func",data);
console.log("selector cred",cred);


if(data){
    console.log("data inside if",data);
}

if(isError){
    console.log("error ",error);
}
// console.log("---------------------------------------<>",isLoading);
// if(data){
//     console.log("data",data);

//     console.log("Admin credential setup ");
//     dispatch(setAdminCredential(data));
// }

const setLoginData =()=>{
    console.log("login ",data);
    dispatch(setAdminCredential(data.body));
}

// const addIndustryFunction = ()=>{
//     console.log("token",token);
//     addIndustry({
//         body:{
//                 name : "Automobiles",
//                 users : ["Retailer"]
//              },
//         token : token
//     })
// }
// console.log("credential ",cred);
  return (
    <div>Dwan
        {/* <button onClick={addDwan} >ENter</button>
        <button onClick={addGm} >ENter2</button>
        <button onClick={incrementBtn} >inc</button> */}
        <button onClick={loginButton} >login</button>
        <button onClick={setLoginData} >set data</button>
        {/* <button onClick={addIndustryFunction} >add industry</button> */}
    </div>
  )
}

export default Dwan