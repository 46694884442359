import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetchVendorsQuery } from "../../../features/admin/vendor/vendorApi";
import {
  useGetAllTenantPermissionsMutation,
  useUpdateAllTenantPermissionsMutation,
} from "../../../features/admin/permissions/allPermissionsApi";
import { useLocation, useNavigate } from "react-router-dom";
const AddTenantSidebar = () => {
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const [tenantId, setTenantId] = useState(-1);

  const handleCheckboxChange = (id, isParent) => {
    if (isParent) {
      const updatedSelectedItems = [...selectedItems];

      const parentIndex = updatedSelectedItems.findIndex(
        (item) => item.id === id,
      );

      if (parentIndex !== -1) {
        updatedSelectedItems[parentIndex] = {
          ...updatedSelectedItems[parentIndex],
          status: updatedSelectedItems[parentIndex].status === 1 ? 0 : 1,
        };
      } else {
        updatedSelectedItems.push({ id, status: 1 });
      }

      const parentItem = GetAllTenantPermissionsData.body.find(
        (item) => item.id === id,
      );

      if (parentItem && parentItem.children && parentItem.children.length > 0) {
        parentItem.children.forEach((child) => {
          const childIndex = updatedSelectedItems.findIndex(
            (item) => item.id === child.id,
          );

          if (childIndex !== -1) {
            updatedSelectedItems[childIndex] = {
              ...updatedSelectedItems[childIndex],
              status: updatedSelectedItems[childIndex].status === 1 ? 0 : 1,
            };
          } else {
            updatedSelectedItems.push({ id: child.id, status: 1 });
          }
        });
      }

      setSelectedItems(updatedSelectedItems);
    } else {
      // Handle child checkbox change
      const updatedSelectedItems = [...selectedItems];

      const index = updatedSelectedItems.findIndex((item) => item.id === id);

      if (index !== -1) {
        updatedSelectedItems[index] = {
          ...updatedSelectedItems[index],
          status: updatedSelectedItems[index].status === 1 ? 0 : 1,
        };
      } else {
        updatedSelectedItems.push({ id, status: 1 });
      }

      setSelectedItems(updatedSelectedItems);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [
    GetAllTenantPermissions,
    {
      data: GetAllTenantPermissionsData,
      isError: GetAllTenantPermissionsIsError,
      error: GetAllTenantPermissionsError,
    },
  ] = useGetAllTenantPermissionsMutation();

  const [
    UpdateAllTenantPermissions,
    {
      data: UpdateAllTenantPermissionsData,
      isError: UpdateAllTenantPermissionsIsError,
      error: UpdateAllTenantPermissionsError,
    },
  ] = useUpdateAllTenantPermissionsMutation();

  const GetAllTenantPermissionsFun = (tenant_id) => {
    if (tenant_id != -1) {
      GetAllTenantPermissions({
        tenant_id: tenant_id || tenantId,
        token: sessionStorage.getItem("access_token"),
      });
    }
  };

  useEffect(() => {
    toast.dismiss();
    console.log("location state", location.state);
    if (location?.state) {
      setTenantId(location.state);
      GetAllTenantPermissionsFun(location.state);
    }
  }, [location.state]);

  const handleSubmit = () => {
    console.log("tenaantID ", tenantId);
    UpdateAllTenantPermissions({
      tenant_id: tenantId,
      token: sessionStorage.getItem("access_token"),
      data: {
        batch: selectedItems,
      },
    });
  };

  useEffect(() => {
    if (GetAllTenantPermissionsData?.body) {
      setSelectedItems((prevState) => {
        const updatedSelectedItems = [];

        const processItems = (items) => {
          for (const item of items) {
            if (
              item.status == 1 ||
              (item.children &&
                item.children.some((child) => child.status == 1))
            ) {
              updatedSelectedItems.push({
                id: item.id,
                status: 1,
              });
            }

            if (item.children && item.children.length > 0) {
              processItems(item.children);
            }
          }
        };

        processItems(GetAllTenantPermissionsData.body);

        return updatedSelectedItems;
      });
    }
  }, [GetAllTenantPermissionsData, tenantId]);

  useEffect(() => {
    if (UpdateAllTenantPermissionsData) {
      toast.success("Sidebar Permissions Updated Successfully", {
        id: "dashboardSuccess",
      });
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
    if (UpdateAllTenantPermissionsError) {
      console.log(
        "updateAllTenantPermissionsError",
        UpdateAllTenantPermissionsError,
      );
    }
    if (UpdateAllTenantPermissionsIsError) {
      toast.error("Something went wrong", {
        id: "dashboardError",
      });
    }
  }, [UpdateAllTenantPermissionsData, UpdateAllTenantPermissionsIsError]);

  return (
    <div className="page-content">
      <h1 className="font-weight-bold text-lg mb-3">Tenant Sidebar Setup </h1>
      {/* <div className=" card p-4">
        <label className="text-capitalize font-weight-bold">
          Choose Client
        </label>
        <select
          className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black p-2.5 h-auto"
          value={selectedVendor}
          onChange={handleVendorChange}
        >
          <option value={0}>Select Client</option>
          {vendorsList &&
            vendorsList.map((vendor) => (
              <option key={vendor.id} value={vendor.company_id}>
                {vendor.name}
              </option>
            ))}
        </select>
      </div> */}

      <div className="mt-3">
        <label className="text-capitalize font-weight-bold">
          Choose Section
        </label>
        <div>
          {GetAllTenantPermissionsData?.body?.length > 0 && (
            <div className="w-full m-auto flex overflow-auto gap-2 ">
              {GetAllTenantPermissionsData?.body.map((parent) => {
                return (
                  <div
                    className="bg-white shadow rounded-lg p-3 mb-3"
                    key={parent.id}
                  >
                    <label className="flex bg-gray-100 p-2 rounded-md whitespace-nowrap">
                      <input
                        className="mr-2"
                        type="checkbox"
                        checked={selectedItems.some(
                          (item) => item.id === parent.id && item.status === 1,
                        )}
                        onChange={() => handleCheckboxChange(parent.id, true)}
                      />{" "}
                      {parent.name}
                    </label>
                    {parent.children && parent.children.length > 0 && (
                      <div className=" flex ml-3 flex-wrap">
                        {parent.children.map((child) => (
                          <div key={child.id}>
                            <label className="flex whitespace-nowrap">
                              <input
                                type="checkbox"
                                className="mr-1"
                                checked={selectedItems.some(
                                  (item) =>
                                    item.id === child.id && item.status === 1,
                                )}
                                onChange={() =>
                                  handleCheckboxChange(child.id, false)
                                }
                              />{" "}
                              {child.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {tenantId && GetAllTenantPermissionsData?.body?.length > 0 && (
        <div className="flex justify-center mt-3">
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            onClick={() => handleSubmit()}
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export default AddTenantSidebar;
