import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { getVendorName } from "../../../utils/getVendorName";

import {
  useUpdateBillOfPurchaseOrderMutation
} from "../../../features/tenant/purchaseOrder/purchaseOrderApi";

const UpdatePurchaseOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isUpdate, setIsUpdate] = useState(false);

  const [mobile, setMobile] = useState(null);
  const [orderValue, setOrderValue] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [orderDate, setOrderDate] = useState(0);
  const [billValue, setbillValue] = useState(0);
  const [billDate, setbillDate] = useState(0);

  useEffect(() => {
    console.log(location.state);

    if (!location.state) {
      toast.error("Invalid Request ", {
        id: "noOrderData",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/punch-order`);
      }, 400);
    }
    
    if(location.state){
        setIsUpdate(true);
        setMobile(location.state.mobile);
        setOrderValue(location.state.order_value);
        setOrderId(location.state.order_id);
        setOrderDate(location.state.order_date.toLocaleString().split("T")[0]);
        setbillDate(location.state.bill_date.toLocaleString().split("T")[0]);
        setbillValue(location.state.bill_value);
    }

  }, [location]);

  const [
    updateBillPurchaseOrder,
    { data: updateBillPurchaseOrderData, error: updateBillPurchaseOrderError },
  ] = useUpdateBillOfPurchaseOrderMutation();

  useEffect(() => {
    if (updateBillPurchaseOrderData) {
      toast.success("Purchase Order Updated", {
        id: "rewardUpdated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/punch-order`);
      }, 400);
    }

    if (updateBillPurchaseOrderError) {
      if (updateBillPurchaseOrderError.status == 409) {
        toast.error("Reward already exists ", {
          id: "updateBillPurchaseOrderError",
        });
      } else {
        toast.error("Something went wrong", {
          id: "updateBillPurchaseOrderError",
        });
      }
      return;
    }
  }, [updateBillPurchaseOrderData, updateBillPurchaseOrderError]);

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!location.state || !location.state.id || !location.state.order_id) {
      return;
    }

    if (!billValue) {
      toast.error("Please Provide Bill Value", { id: "noBillValue" });
      return;
    }

    if (!billDate) {
      toast.error("Please Provide Bill Date", { id: "noBillDate" });
      return;
    }

   
    updateBillPurchaseOrder({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      order_id: location.state.order_id,
      body: {
        order_value : orderValue,
        order_date : orderDate,
        order_id : orderId,
        bill_value: billValue,
        bill_date: billDate,
      },
    });
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate && "Update "}Purchase Order Bill
            <LinksComponent />
          </div>

          <div className="p-3">
            <form className="p-3 w-full bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="flex justify-start space-x-3">
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Mobile"}
                        type={"text"}
                        value={mobile ? mobile : ""}
                        setValue={setMobile}
                        required={true}
                        disabled={isUpdate}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Order Value"}
                        type={"number"}
                        value={orderValue ? orderValue : ""}
                        setValue={setOrderValue}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Order Id"}
                        type={"number"}
                        value={orderId ? orderId : ""}
                        setValue={setOrderId}
                        required={true}
                      />
                    </div>
                   

                    {/* {schemeType === "CONTINUOUS" && (
                      <div className="mb-6 w-1/3">
                        <InputComponent
                          name={"Value"}
                          type={"number"}
                          value={value ? value : ""}
                          setValue={setValue}
                          required={true}
                        />
                      </div>
                    )} */}
                  </div>
                  <div className="flex justify-start space-x-3">
                  <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Order Date"}
                        type={"date"}
                        value={orderDate ? orderDate : ""}
                        setValue={setOrderDate}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Bill Value"}
                        type={"number"}
                        value={billValue ? billValue : ""}
                        setValue={setbillValue}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Bill Date"}
                        type={"date"}
                        value={billDate ? billDate : ""}
                        setValue={setbillDate}
                        required={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  <button
                    type=""
                    onClick={(e) => handleUpdate(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePurchaseOrder;
