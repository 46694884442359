import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { getVendorName } from "../../../utils/getVendorName";

import {
  useAddPurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
} from "../../../features/tenant/purchaseOrder/purchaseOrderApi";

const ManagePurchaseOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isUpdate, setIsUpdate] = useState(false);

  const [mobile, setMobile] = useState(null);
  const [orderValue, setOrderValue] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [orderDate, setOrderDate] = useState(0);
  const [billValue, setbillValue] = useState(0);
  const [billDate, setbillDate] = useState(0);

  useEffect(() => {
    console.log(location.state);

    if (location.state) {
      setIsUpdate(true);
      setMobile(location.state.mobile);
      setOrderValue(location.state.order_value);
      setOrderId(location.state.order_id);
      //   setOrderDate(location.state.order_date);
      setOrderDate(location.state.order_date.toLocaleString().split("T")[0]);
    }
  }, [location]);

  const [
    addPurchaseOrder,
    { data: addPurchaseOrderData, error: addPurchaseOrderError },
  ] = useAddPurchaseOrderMutation();

  const [
    updatePurchaseOrder,
    { data: updatePurchaseOrderData, error: updatePurchaseOrderError },
  ] = useUpdatePurchaseOrderMutation();

  useEffect(() => {
    if (addPurchaseOrderData) {
      toast.success("Purchase Order ", {
        id: "purchaseOrderCreated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/punch-order`);
      }, 400);
    }

    if (addPurchaseOrderError) {
      console.log("addPurchaseOrderError", addPurchaseOrderError);
      if (addPurchaseOrderError.status == 404) {
        toast.error("No Active Scheme Exists ", {
          id: "addPurchaseOrderNoActiveScheme",
        });
      } else if (addPurchaseOrderError.status == 409) {
        toast.error("Purchase Order with Order Id Already Exists ", {
          id: "addPurchaseOrderError",
        });
      } else {
        toast.error("Something went wrong", {
          id: "addPurchaseOrderError",
        });
      }
      return;
    }
  }, [addPurchaseOrderData, addPurchaseOrderError]);

  useEffect(() => {
    if (updatePurchaseOrderData) {
      toast.success("Purchase Order Updated", {
        id: "rewardUpdated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/punch-order`);
      }, 400);
    }

    if (updatePurchaseOrderError) {
      if (updatePurchaseOrderError.status == 409) {
        toast.error("Purchase Order with Order Id Already Exists ", {
          id: "updatePurchaseOrderError",
        });
      } else {
        toast.error("Something went wrong", {
          id: "updatePurchaseOrderError",
        });
      }
      return;
    }
  }, [updatePurchaseOrderData, updatePurchaseOrderError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!mobile) {
      toast.error("Please enetr mobile", { id: "noMobile" });
      return;
    }

    if (!orderValue) {
      toast.error("Please enter order value", { id: "noOrderValue" });
      return;
    }

    if (!orderId) {
      toast.error("Please Enter Order ID", { id: "noOrderId" });
      return;
    }

    if (!orderDate) {
      toast.error("Please enter order date", { id: "noOrderDate" });
      return;
    }

    if (orderValue && billValue) {
      if (billValue > orderValue) {
        toast.error("Bill Value can't be more than Order Value", {
          id: "noOrderDate",
        });
        return;
      }
    }

    addPurchaseOrder({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        mobile: mobile,
        order_id: orderId,
        order_value: orderValue,
        order_date: orderDate,
        bill_value: billValue,
        bill_date: billDate,
      },
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    updatePurchaseOrder({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      order_id: location.state.order_id,
      body: {
        mobile: mobile,
        order_id: orderId,
        order_value: orderValue,
        order_date: orderDate,
        // bill_value: billValue,
        // bill_date: billDate,
      },
    });
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}Purchase Order
            <LinksComponent />
          </div>

          <div className="p-3">
            <form className="p-3 w-full bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="flex justify-start space-x-3">
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Mobile"}
                        type={"text"}
                        value={mobile ? mobile : ""}
                        setValue={setMobile}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Order Value"}
                        type={"number"}
                        value={orderValue ? orderValue : ""}
                        setValue={setOrderValue}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Order Id"}
                        type={"text"}
                        value={orderId ? orderId : ""}
                        setValue={setOrderId}
                        required={true}
                      />
                    </div>
                 

                    {/* {schemeType === "CONTINUOUS" && (
                      <div className="mb-6 w-1/3">
                        <InputComponent
                          name={"Value"}
                          type={"number"}
                          value={value ? value : ""}
                          setValue={setValue}
                          required={true}
                        />
                      </div>
                    )} */}
                  </div>
                  {!isUpdate && (
                    <div className="flex justify-start space-x-3">
                         <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Order Date"}
                        type={"date"}
                        value={orderDate ? orderDate : ""}
                        setValue={setOrderDate}
                        required={true}
                      />
                    </div>
                      <div className="mb-6 w-1/3">
                        <InputComponent
                          name={"Bill Value"}
                          type={"number"}
                          value={billValue ? billValue : ""}
                          setValue={setbillValue}
                          required={true}
                        />
                      </div>
                      <div className="mb-6 w-1/3">
                        <InputComponent
                          name={"Bill Date"}
                          type={"date"}
                          value={billDate ? billDate : ""}
                          setValue={setbillDate}
                          required={true}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex justify-center mt-2">
                  {isUpdate ? (
                    <button
                      type=""
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type=""
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePurchaseOrder;
