import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    user : JSON.parse( sessionStorage.getItem("userTenant"))
}

export const tenantAuthSlice = createSlice({
    name: 'tenantAuth',
    initialState,
    reducers: {
        setTenantCredential: (state, action) => {
            state.user = action.payload;
        },
        tenantLogOut : (state, action) => {
            state.user = null;
        },
    },
})

export const { setTenantCredential , tenantLogOut} = tenantAuthSlice.actions
export default tenantAuthSlice.reducer