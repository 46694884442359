import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import QRCode from "react-qr-code";

export default function ProductModal({ show, setShow, data }) {
  const [isOpen, setIsOpen] = useState(show);

  console.log("in product modal ", show, data);

  function closeModal() {
    setIsOpen(false);
    setShow(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl mt-5 transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="poptext_p  text-center  leading-6 text-gray-900"
                  >
                    {data.name}
                  </Dialog.Title>
                  <div className=" mx-auto">
                    <div className="md:flex items-center justify-center py-3 2xl:px-20 md:px-6 px-4">
                      <div className="xl:w-2/6 lg:w-2/5 w-40 md:block hidden border p-3">
                        {data?.images?.map((each) => (
                          <img
                            className="w-full"
                            alt={each}
                            src={ each}
                          />
                        ))}
                      </div>

                      <div className="xl:w-2/5 md:w-1/2 lg:ml-8 w-60 md:ml-6 md:mt-0 ">
                        <div className="border-b border-gray-200 ">
                          <p className="text-sm leading-none text-gray-600 text-capitalize">
                            {data.brand_name}
                          </p>
                          <h1 className="text-xl text-xl font-semibold lg:leading-6 text-capitalize leading-7 text-gray-800 mt-2 pb-3">
                            {data.name}
                          </h1>
                        </div>
                        {/* <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                          <p className="text-base leading-4 text-gray-800 ">
                            Colours
                          </p>
                          <div className="flex items-center justify-center">
                            <p className="text-sm leading-none text-gray-600 ">
                              Smoke Blue with red accents
                            </p>
                            <div className="w-6 h-6 bg-gradient-to-b from-gray-900 to-indigo-500 ml-3 mr-4 cursor-pointer"></div>
                            <svg
                              className="cursor-pointer text-gray-300 dark:text-white"
                              width="6"
                              height="10"
                              viewBox="0 0 6 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1L5 5L1 9"
                                stroke="currentColor"
                                stroke-width="1.25"
                                strokeWidth="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div> */}
                        {/* <div className="py-3 border-b border-gray-200 flex items-center justify-between">
                          <p className="text-base leading-4 text-gray-800 ">Qty</p>
                          <div className="flex items-center justify-center">
                            <p className="text-sm leading-none text-gray-600  mr-3">
                              {data.qty}
                            </p>
                           
                          </div>
                        </div> */}
                        {/* <button className="dark:bg-white dark:text-gray-900 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 text-base flex items-center justify-center leading-none text-white bg-gray-800 w-full py-4 hover:bg-gray-700 focus:outline-none">
                          <svg
                            className="mr-3 text-white dark:text-gray-900"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.02301 7.18999C7.48929 6.72386 7.80685 6.12992 7.93555 5.48329C8.06425 4.83666 7.9983 4.16638 7.74604 3.55724C7.49377 2.94809 7.06653 2.42744 6.51835 2.06112C5.97016 1.6948 5.32566 1.49928 4.66634 1.49928C4.00703 1.49928 3.36252 1.6948 2.81434 2.06112C2.26615 2.42744 1.83891 2.94809 1.58665 3.55724C1.33439 4.16638 1.26843 4.83666 1.39713 5.48329C1.52583 6.12992 1.8434 6.72386 2.30968 7.18999L4.66634 9.54749L7.02301 7.18999Z"
                              stroke="currentColor"
                              stroke-width="1.25"
                              strokeWidth="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.66699 4.83333V4.84166"
                              stroke="currentColor"
                              stroke-width="1.25"
                              strokeWidth="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.69 13.8567C14.1563 13.3905 14.4738 12.7966 14.6025 12.15C14.7312 11.5033 14.6653 10.8331 14.413 10.2239C14.1608 9.61476 13.7335 9.09411 13.1853 8.72779C12.6372 8.36148 11.9926 8.16595 11.3333 8.16595C10.674 8.16595 10.0295 8.36148 9.48133 8.72779C8.93314 9.09411 8.5059 9.61476 8.25364 10.2239C8.00138 10.8331 7.93543 11.5033 8.06412 12.15C8.19282 12.7966 8.51039 13.3905 8.97667 13.8567L11.3333 16.2142L13.69 13.8567Z"
                              stroke="currentColor"
                              stroke-width="1.25"
                              strokeWidth="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.333 11.5V11.5083"
                              stroke="currentColor"
                              stroke-width="1.25"
                              strokeWidth="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Check availability in store
                        </button> */}
                        <div>
                          <p className=" text-sm lg:leading-tight leading-normal text-gray-600  mt-2">
                            {data.description}
                          </p>
                          <p className="text-sm leading-4 mt-2 text-gray-600 ">
                            Category : {data.category_name}
                          </p>
                          <p className="text-sm leading-4 mt-2 text-gray-600 ">
                            Product Code: {data.product_code}
                          </p>
                          <p className="text-sm leading-4 mt-2 text-gray-600 ">
                            Unit : {data.unit_name}
                          </p>

                          <p className="text-sm leading-4 mt-2 text-gray-600 ">
                            Size : {data?.uom_value} {data?.uom_key}
                          </p>
                          {data.distributor_points && (
                            <p className="text-sm leading-4 mt-2 text-gray-600 ">
                              Distributor Points: {data.distributor_points}
                            </p>
                          )}
                          {data.retailer_points && (
                            <p className="text-sm leading-4 mt-2 text-gray-600 ">
                              Retailer Points: {data.retailer_points}
                            </p>
                          )}{data.consumer_points && (
                            <p className="text-sm leading-4 mt-2 text-gray-600 ">
                              Consumer Points: {data.consumer_points}
                            </p>
                          )}
                         
                          <p className="md:w-96 text-sm leading-normal text-gray-600  mt-2">
                            Created At : {data.created_at.slice(0, 10)}
                          </p>
                        </div>
                        {/* <div>
                          <div className="border-t border-b py-4 mt-7 border-gray-200">
                            <div
                              data-menu
                              className="flex justify-between items-center cursor-pointer"
                            >
                              <p className="text-base leading-4 text-gray-800 ">
                                Shipping and returns
                              </p>
                              <button
                                className="cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 rounded"
                                role="button"
                                aria-label="show or hide"
                              >
                                <svg
                                  className="transform text-gray-300 dark:text-white"
                                  width="10"
                                  height="6"
                                  viewBox="0 0 10 6"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 1L5 5L1 1"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    strokeWidth="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div
                              className="hidden pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 "
                              id="sect"
                            >
                              You will be responsible for paying for your own
                              shipping costs for returning your item. Shipping
                              costs are nonrefundable
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="border-b py-4 border-gray-200">
                            <div
                              data-menu
                              className="flex justify-between items-center cursor-pointer"
                            >
                              <p className="text-base leading-4 text-gray-800 ">
                                Contact us
                              </p>
                              <button
                                className="cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 rounded"
                                role="button"
                                aria-label="show or hide"
                              >
                                <svg
                                  className="transform text-gray-300 dark:text-white"
                                  width="10"
                                  height="6"
                                  viewBox="0 0 10 6"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 1L5 5L1 1"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    strokeWidth="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div
                              className="hidden pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 "
                              id="sect"
                            >
                              If you have any questions on how to return your
                              item to us, contact us.
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="text-center closebx">
                    <button
                      type="button"
                      className="flex justify-center items-center w-5 h-5"
                      onClick={closeModal}
                    >
                      x
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
