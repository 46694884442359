import React, { useEffect, useMemo, useState } from "react";
import { useFetchVendorsQuery } from "../../../features/admin/vendor/vendorApi";
import { toast } from "react-hot-toast";

// import {
//   useAddAppUserMenuMutation,
//   useFetchAllMenuItemsMutation,
// } from "../../../features/admin/appUserMenu/appUserMenuApi";

import ReorderList from "../../../components/ReorderList/ReorderList";
import { Box, Fade, Backdrop, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// AiOutlineCheck
// IoSquareOutline
import { AiOutlineCheckSquare } from "react-icons/ai";
import { IoSquareOutline } from "react-icons/io5";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { useAddAppUserDashboardMutation, useFetchAllDashboardItemsMutation } from "../../../features/admin/appUserDashboard/appUserDashboardApi";
// BsCheckCircleFill
// AiOutlineCheckSquare

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
  maxHeight: "600px",
};

const ManageAppDashboard = () => {
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(0);
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [orderList, setOrderList] = useState([]);
  const [finalList, setFinalList] = useState(null);

  const navigate = useNavigate();

  const {
    data: vendorsData,
    isError: vendorsIsError,
    isLoading: vendorsIsLoading,
    error: vendorsError,
  } = useFetchVendorsQuery(sessionStorage.getItem("access_token")) || {};

  const [
    fetchDashboardItems,
    { data: appDashboardData, isLoading: appDashboardIsLoading, error: appDashboardError },
  ] = useFetchAllDashboardItemsMutation();

  const [
    addAppUserDashboard,
    {
      data: appUserDashboardData,
      isLoading: appUserMenuIsLoading,
      error: appUserDashboardError,
    },
  ] = useAddAppUserDashboardMutation();

  useEffect(() => {
    if (selectedUser !== null && !appDashboardData) {
      fetchDashboardItems({ token: sessionStorage.getItem("access_token") });
    }
  }, [selectedUser]);

  function addCheckedKey(array) {
    return array.map((item) => {
      return { ...item, checked: false };
    });
  }

  const [allMenuItems, setAllMenuItems] = useState([]);

  useMemo(() => {
    if (appDashboardData) {
      (() => {
        console.log("app dashboard items ", appDashboardData.body);
        const newArray = addCheckedKey(appDashboardData.body);
        setAllMenuItems(newArray);
      })();
    }
    if (appDashboardError) {
      console.log("app dashboard error", appDashboardError);
    }
  }, [appDashboardData, appDashboardError]);

  //   console.log("items", allMenuItems);

  useMemo(() => {
    if (vendorsData) {
      (() => {
        setVendorsList(vendorsData.body);
      })();
    }
    if (vendorsError) {
      toast.error(vendorsError.error, {
        id: "vendorsError",
      });
    }
  }, [vendorsData, vendorsError]);

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    vendorsList.forEach(function (data) {
      if (data.company_id == event.target.value) {
        setSelectedVendorData(data);
        console.log(data);
        setUsersList(data.user_types);
      }
    });
  };

  const handleOnChange = (idx) => {
    const newData = [...allMenuItems];
    newData[idx].checked = !allMenuItems[idx].checked;
    setAllMenuItems(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(finalList);
    
    for (let index = 0; index < finalList.length; index++) {
      const element = finalList[index];
      element.order_at = element.order;
      delete element.order;
      delete element.created_at;
      delete element.created_by_id;
      delete element.created_by_name;
      delete element.checked;
    }
    console.log("dataaa ", finalList, selectedVendorData, selectedUser);

    if (finalList.length <= 0) {
      toast.error("Please select some options");
      return;
    }

    addAppUserDashboard({
      token: sessionStorage.getItem("access_token"),
      data: {
        tenant_id: selectedVendorData.tenant_id,
        tenant_name: selectedVendorData.name,
        user_type: selectedUser,
        app_dashboard: finalList,
      },
    });
  };

  const previewButton = () => {
    const arr = [];
    for (let index = 0; index < allMenuItems.length; index++) {
      const element = allMenuItems[index];
      if (element.checked) {
        // const obj = {...element, "order": element.order_at};
        // console.log(obj);
        element.order = element.order_at;
        // arr.push(obj);
        arr.push(element);
      }
    }
    console.log("previewww  ", arr);
    setOrderList(arr);
    setFinalList(arr);
    setOpen(true);
  };

  useMemo(() => {
    if (appUserDashboardData) {
      (() => {
        console.log("user dashboard  ", appUserDashboardData.body);
        setOpen(false);
        toast.success("User dashboard created");
        setTimeout(() => {
          navigate("/appDashboard");
        }, 300);
      })();
    }
    if (appUserDashboardError) {
      console.log("user dashboard ", appUserDashboardError);
      setOpen(false);
      if (appUserDashboardError.status === 409) {
        toast.error(appUserDashboardError.data.message, { id: "appUserDashboardError" });
      } else toast.error("Something went wrong", { id: "appUserDashboardError" });
    }
  }, [appUserDashboardData, appUserDashboardError]);

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">

              <h4 className="card-title mb-0">Manage App Dashboard</h4>
              <div className="d-flex align-items-center flex-wrap text-nowrap">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                  // onClick={() => navigate("add")}
                  >
                  Add New Dashboard Option
                </button>
              </div>
                  </div>
              <div className="row">
                <div className="col-12 col-xl-6 col-xs-12">
                  <label className="text-capitalize font-weight-bold">
                    Choose Client
                  </label>
                  <select
                    className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                    value={selectedVendor}
                    onChange={handleVendorChange}
                  >
                    <option className="rounded-lg shadow-sm shadow-black px-2" value={0}>Select Client</option>
                    {vendorsList &&
                      vendorsList.map((vendor) => (
                        <option className="rounded-lg shadow-sm shadow-black px-2" key={vendor.id} value={vendor.company_id}>
                          {vendor.name.charAt(0).toUpperCase() +
                            vendor.name.slice(1)}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-12 col-xl-6 col-xs-12">
                  <label className="text-capitalize font-weight-bold">
                    Choose User
                  </label>
                  <select
                    className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                  >
                    <option className="rounded-lg shadow-sm shadow-black px-2" value={0}>Select User</option>
                    {usersList &&
                      usersList.map((user, idx) => (
                        <option className="rounded-lg shadow-sm shadow-black px-2" key={idx} value={user}>
                          {user.charAt(0).toUpperCase() + user.slice(1)}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div
                className="row m-3 justify-content-center align-items-center"
                style={{ minHeight: "300px" }}
              >
                <div
                  className="d-flex w-full justify-content-start align-items-center flex-wrap"
                  style={{ height: "" }}
                >
                  {allMenuItems
                    ? allMenuItems.map((item, idx) => (
                        <div
                          key={idx}
                          className="d-flex mr-5 mb-4 w-20 justify-content-start align-items-center "
                        >
                          <div
                            className="d-flex justify-content-start align-items-center"
                            onClick={() => handleOnChange(idx)}
                          >
                            {item.checked ? (
                              <BsCheckCircleFill className="w-3.5 mr-2" />
                            ) : (
                              <BsCircle className="w-3.5 mr-2" />
                            )}

                            <p
                              className={`ml-auto font-bold hover:bg-slate-200 p-1 rounded-lg   ${
                                item.checked && "bg-slate-200"
                              } `}
                            >
                              {/* {item.icon} */}
                              {item.name}
                            </p>
                          </div>
                        </div>
                      ))
                    : appDashboardIsLoading}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <button
                    className="btn btn-outline-primary mr-2 w-100"
                    onClick={previewButton}
                    disabled={!selectedVendorData || !selectedUser}
                  >
                    Preview
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-outline-secondary mr-2 w-100"
                    //   onClick={resetWorkflowBoard}
                    disabled={false}
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <Box sx={style} style={{ overflow: "scroll" }}>
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        className="text-center"
                      >
                        {selectedUser &&
                          selectedUser.charAt(0).toUpperCase() +
                            selectedUser.slice(1)}{" "}
                        Menu
                      </Typography>
                      {orderList.length > 0 && (
                        <ReorderList
                          list={orderList}
                          setList={setFinalList}
                          type={"menu"}
                        />
                      )}
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn btn-primary mt-3"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save
                        </button>
                      </div>
                    </Box>
                  </Fade>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAppDashboard;
