import React, { useEffect } from "react";
import { Counter } from "./features/counter/Counter";
// import "./App.css";
import "./App.css";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import LoginAdmin from "./screens/Admin/LoginAdmin/LoginAdmin";
import AdminHome from "./screens/Admin/AdminHome/AdminHome";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Login from "./screens/Tenant/Login/Login";
import TenantHome from "./screens/Tenant/TenantHome/TenantHome";
import NotFound from "./components/NotFound";
import { useFetchVendorsThemeByNameMutation } from "./features/admin/vendorTheme/vendorThemeApi";
import Loader from "./components/Loader/Loader";
import ErrorBoundry from "./components/ErrorBoundry/ErrorBoundry";
import DataDeletion from "./screens/Tenant/DataDeletion/DataDeletion";
import PrivacyPolicy from "./screens/Tenant/PrivacyPolicy/PrivacyPolicy";
import Home from "./screens/Tenant/Home/Home";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [
    getVendorTheme,
    {
      data: vendorThemeData,
      isLoading: vendorThemeIsLoading,
      isError: vendorThemeIsError,
      error: vendorThemeError,
    },
  ] = useFetchVendorsThemeByNameMutation();

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path.length >= 2 && path[1] === "genefied" && path[2]) {
      // setTenantName(path[2]);
      getVendorTheme({
        params: path[2],
      });
    }
  }, []);

  // console.log(vendorThemeData);

  const [authorized, setAuthorized] = useState(() => {
    return sessionStorage.getItem("authorized")
      ? sessionStorage.getItem("authorized")
      : false;
  });

  const [tenantAuthorized, setTenantAuthorized] = useState(() =>
    sessionStorage.getItem("authorizedTenant") ? true : false,
  );

  useEffect(() => {
    const path = location.pathname.split("/");
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    // link.href = "https://www.genefiedtech.com/assets/images/favicon.ico.png";


    if (path.length >= 2 && path[1] === "genefied" && path[2]) {
      // console.log("path" ,path);
      link.href =
        
      vendorThemeData?.body?.logo?.length> 0 && vendorThemeData?.body?.logo[0];
        document.title = `${vendorThemeData?.body?.tenant_name?.toUpperCase()} | Genefied SAAS`;
    } else {
      console.log("admin");
      link.href = "https://www.genefiedtech.com/assets/images/favicon.ico.png";
      document.title = "Genefied - SAAS ";

    }
    const text1 = "SAAS";
    const text2 = "GENEFIED";
    const styledText1 = `%c${text1}`;
    const styledText2 = `%c${text2}`;
    const gradient = `font-size: 24px; background: linear-gradient(45deg, #ff00ff, #00ffff, #ffff00, #ff00ff); color: transparent; -webkit-background-clip: text; background-clip: text;`;
    
    console.log(styledText1, gradient);
    console.log(styledText2, gradient);
  }, [vendorThemeData]);



  return (
    <div>
      <Toaster />
      <Routes>
        {/* <Route path="/counter" element={<Counter />} /> */}
        <Route
          path="/*"
          element={
            !authorized ? (
              <div>
                <LoginAdmin setAuthorized={setAuthorized} />
              </div>
            ) : (
              <div>
                <AdminHome />
              </div>
            )
          }
        />
        <Route
          path="/genefied/:tenant/*"
          element={
            !tenantAuthorized ? (
              <div>
                <Login
                  showLoading={vendorThemeIsLoading}
                  vendorThemeData={vendorThemeData}
                  setTenantAuthorized={setTenantAuthorized}
                />
              </div>
            ) : (
              <div>
                <TenantHome />
              </div>
            )
          }
        />


            {/*Data deletion policy page */}
            <Route
            path="/data-deletion/:tenant"
            element={
              <ErrorBoundry>
                <DataDeletion />
              </ErrorBoundry>
            }
          />


          <Route
            path="/privacy-policy/:tenant"
            element={
              <ErrorBoundry>
                <PrivacyPolicy />
              </ErrorBoundry>
            }
          />

        <Route path="/*" element={<NotFound />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/loader" element={<Loader />} />
      </Routes>
    </div>
  );
}

export default App;
