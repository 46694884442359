// api/tenant/qrScanHistory?batchCode=BT1223&userType=1

import { rootApi } from "../../api/rootApi";

export const appUserQrScan = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchQrScanHistory: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qrScanHistory`,
          body: params.body,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    countQrScanHistory: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qrScanHistory/count`,
          body: params.body,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchQrScanHistoryMonthly: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qrScanHistory/month`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchQrScanHistoryStateWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qrScanHistory/state`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchQrScanHistoryMutation,
  useCountQrScanHistoryMutation,
  useFetchQrScanHistoryMonthlyMutation,
  useFetchQrScanHistoryStateWiseMutation,
} = appUserQrScan;
