import React, { useEffect, useMemo, useState } from "react";
import qr from "../../../assests/tenant/images/qr.jpg";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import {
  useCountAllsupportQueriesMutation,
  useFetchAllsupportQueriesMutation,
  useUpdateSupportQueriesByIdMutation,
} from "../../../features/tenant/supportQueries/supportQueriesApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import toast from "react-hot-toast";
import CustomExport from "../../../components/CustomExport/CustomExport";

const queriesType = [
  {
    name: "All",
    id: null,
  },
  { id: 1, name: "KYC Related" },
  { id: 2, name: "Points Related" },
  { id: 3, name: "Redeemption Related" },
  { id: 4, name: "Application Related" },
  { id: 5, name: "Product Related" },
  { id: 6, name: "Qr Code Related" },
  { id: 7, name: "Others" },
];

const SupportQueries = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [prevPage, setPrevPage] = useState(0);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [remarks, setRemarks] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);

  const initialRequest = {
    type: "",
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);

  const [
    fetchAllsupportQueries,
    {
      data: allsupportQueriesData,
      isLoading: allsupportQueriesIsLoading,
      isError: allsupportQueriesIsError,
      error: allsupportQueriesError,
    },
  ] = useFetchAllsupportQueriesMutation();

  const [
    countAllsupportQueries,
    {
      data: countallsupportQueriesData,
      isLoading: countallsupportQueriesIsLoading,
      isError: countallsupportQueriesIsError,
      error: countallsupportQueriesError,
    },
  ] = useCountAllsupportQueriesMutation();

  const [
    fetchAllsupportQueriesCsv,
    {
      data: allsupportQueriesCsvData,
      isLoading: allsupportQueriesCsvIsLoading,
      isError: allsupportQueriesCsvIsError,
      error: allsupportQueriesCsvError,
    },
  ] = useFetchAllsupportQueriesMutation();

  const [
    updateSupportQueriesById,
    {
      data: allupdateSupportQueriesByIdvData,
      isLoading: allupdateSupportQueriesByIdvIsLoading,
      isError: allupdateSupportQueriesByIdvIsError,
      error: allupdateSupportQueriesByIdvError,
    },
  ] = useUpdateSupportQueriesByIdMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!remarks || remarks.length == 0) {
      toast.error("Remark cannot be empty");
      return;
    }
    // console.log("selected row", selectedRow);
    handleUpdateStatus(selectedRow, remarks);
    setShowModal(false);
  }

  const handleUpdateStatus = (row, remarks) => {
    console.log(row);
    if (row.status == 1) {
      updateSupportQueriesById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: { status: "2", remarks: remarks },
        id: row.id,
      });
    }
    else if (row.status == 2) {
      updateSupportQueriesById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: { status: "1", remarks: remarks },
        id: row.id,
      });
    }
  };

  const handleShowModal = (row) => {
    setSelectedRow(row);
    setRemarks("");
    setShowModal(true);
  }

  useEffect(() => {
    if (allupdateSupportQueriesByIdvData) {
      fetchAllSupportQueriesFun();

      // fetchAllSupportQueriesCsvFun();
    }
  }, [allupdateSupportQueriesByIdvData]);
  const fetchAllSupportQueriesFun = () => {
    fetchAllsupportQueries({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const countAllSupportQueriesFun = () => {
    countAllsupportQueries({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchAllSupportQueriesCsvFun = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAllsupportQueriesCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (allsupportQueriesCsvData && allsupportQueriesCsvData.body.supportQueriesList) {
      setCsvData(allsupportQueriesCsvData.body.supportQueriesList);
    }

    if (allupdateSupportQueriesByIdvError) {
      setCsvData(null);
    }
  }, [allsupportQueriesCsvData, allupdateSupportQueriesByIdvError])

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
    },

    {
      name: "Short Desc",
      cell: (row) => row?.short_description,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Long Desc",
      cell: (row) => row?.long_description,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Remarks",
      cell: (row) => row?.remarks,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Image",
      cell: (row) => (
        <div className="w-12 h-12 my-2 rounded-full">
          {row.images && row.images[0] ? ( // Check if row.images exists and has at least one element
            <img
              src={row.images[0]}
              alt={row.images[0]}
              className="rounded-full"
              onClick={() =>
                setShowImageModal(
                  row.images[0]
                )
              }
            />
          ) : (
            <span>No Image</span> // Display a message or placeholder for no image
          )}
        </div>
      ),
    },
    {
      name: "Status",
      cell: (r) =>
        r.status === "2" ? (
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => handleShowModal(r)}
          >
            Resolved
          </button>
        ) : (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleShowModal(r)}
          >
            Active
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },

    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      minWidth: "200px",
    },
  ];

  useEffect(() => {
    fetchAllSupportQueriesFun();
    countAllSupportQueriesFun();
    // fetchAllSupportQueriesCsvFun();
  }, [limit, offset, request]);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const clearFilters = () => {
    setRequest(initialRequest);
  };

  // console.log(
  //   "allsupportQueriesCsvData?.body?.supportQueriesList",
  //   allsupportQueriesCsvData?.body
  // );

  useEffect(() => {
    if (allsupportQueriesData && allsupportQueriesData.body && allsupportQueriesData.body.supportQueriesList) {
      setMyList(allsupportQueriesData.body.supportQueriesList)
    }
  }, [allsupportQueriesData])

  useEffect(() => {
    if (countallsupportQueriesData &&
      countallsupportQueriesData.body.total_support_queries) {
      setTotalRows(countallsupportQueriesData.body.total_support_queries)
    }
  }, [countallsupportQueriesData])


  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Support Queries
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="flex justify-between items-start">
            {/* {allsupportQueriesCsvData &&
              allsupportQueriesCsvData?.body?.supportQueriesList && (
                <ExportCSV
                  data={
                    allsupportQueriesCsvData &&
                    allsupportQueriesCsvData?.body?.supportQueriesList
                  }
                  columnsToExport={[
                    "user_type",
                    "name",
                    "type",
                    "mobile",
                    "short_description",
                    "long_description",
                    "remarks",
                  ]}
                />
              )} */}

            <div className="mb-2">
              <CustomExport fetchData={fetchAllSupportQueriesCsvFun} data={csvData} defaultColumnExport={[
                "user_type",
                "name",
                "type",
                "mobile",
                "short_description",
                "long_description",
                "remarks",
              ]}
                filename="support_queries"
              />
            </div>
          </div>
          {/* <div className="">
            <button
              type="submit"
              className="btn btn-outline-primary clear-both px-3 py-2.5  mb-2"
            >
              Export
            </button>
          </div> */}
          <div className=" bg-white shadow rounded-lg ">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 ">
                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                    placeholder=""
                    value={request.startDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          startDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                    placeholder=""
                    value={request.endDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          endDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                  <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">User Types</label>
                    <div className="relative searchin">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}</div>
                  </div>
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    Query Type
                  </label>
                  <div className="mb-2 flex-col slc_dvd">
                    <select
                      className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 "
                      name="type"
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, type: newValue };
                        });
                      }}
                      id="type"
                      value={request.type}
                    >
                      {queriesType &&
                        queriesType.length > 0 &&
                        queriesType.map((each) => (
                          <option key={each.id} value={each.name}>
                            {each.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="w-52 ">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    Mobile No
                  </label>
                  <input
                    type="text"
                    className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                    placeholder="Enter Mobile No"
                    value={request.mobile}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          mobile: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    &nbsp;
                  </label>

                  <div className="flex flex-row  gap-2 ">
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                        onClick={() => {
                          fetchAllSupportQueriesFun();
                          // fetchAllSupportQueriesCsvFun();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" p-3">
              <DataTableComponent
                columns={columns}
                data={
                  myList
                }
                totalRows={
                  totalRows
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showModal && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-3">
              <div className="w-full flex justify-center ">
                <div className="mb-6 w-full">
                  <InputComponent
                    name={"Remarks"}
                    type={"textarea"}
                    value={remarks}
                    placeholder={"Enter Remarks"}
                    setValue={setRemarks}
                    required={true}
                  />
                </div>
              </div>
              <div className="flex justify-center">

                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </button>

              </div>
            </div>
          </ModalComponent>
        )}
        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
      </div>
    </div>
  );
};

export default SupportQueries;
