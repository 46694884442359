import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";

import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import validator from "validator";
import { getVendorName } from "../../../utils/getVendorName";
import {
  useCreateTenantEmailTemplateMutation,
  useUpdateTenantEmailTemplateMutation,
} from "../../../features/tenant/emailTemplate/emailTemplateApiTenant";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";

const emailTypes = [
  { id: 1, name: "welcome_mail" },
  { id: 2, name: "redemption_mail" },
  { id: 3, name: "registration_mail" },
  { id: 4, name: "warranty_mail" },
  { id: 5, name: "gift_redemption_mail" },
  { id: 6, name: "support_mail" },
  { id: 7, name: "reset_password_mail" },
  { id: 8, name: "order_status_change" },
];

const ManageEmailTemplate = () => {
  const [
    createTenantEmailTemplate,
    { data: emailTemplateData, error: emailTemplateError },
  ] = useCreateTenantEmailTemplateMutation();

  const [
    updateEmailTemplate,
    { data: updateEmailTemplateData, error: updateEmailTemplateError },
  ] = useUpdateTenantEmailTemplateMutation();

  const [isUpdate, setIsUpdate] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const [templateType, setTemplateType] = useState(emailTypes[0]);
  const [subject, setSubject] = useState(null);
  const [plaintext, setPlaintext] = useState(null);
  const [htmlTemplate, setHtmlTemplate] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleUpdate = (e) => {
    e.preventDefault();

    toast.dismiss();

    if (!templateName) {
      toast.error("Please provide a template name", {
        id: "noTemplateName",
      });
      return;
    }

    if (!templateType || !templateType.name) {
      toast.error("Please select a template type", {
        id: "notemplateType",
      });
      return;
    }

    if (!subject) {
      toast.error("Please provide a subject", {
        id: "nosubject",
      });
      return;
    }

    if (!htmlTemplate) {
      toast.error("Please provide a html template", {
        id: "noHtmlTemplate",
      });
      return;
    }

    updateEmailTemplate({
      token: sessionStorage.getItem("access_token"),
      id: location.state.id,
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        template_name: templateName,
        template_type: templateType.name,
        subject: subject,
        plain_text: plaintext,
        html_template: htmlTemplate,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.dismiss();

    if (!templateName) {
      toast.error("Please provide a template name", {
        id: "noTemplateName",
      });
      return;
    }

    if (!templateType) {
      toast.error("Please select a template type", {
        id: "notemplateType",
      });
      return;
    }

    if (!subject) {
      toast.error("Please provide a subject", {
        id: "nosubject",
      });
      return;
    }

    if (!htmlTemplate) {
      toast.error("Please provide a html template", {
        id: "noHtmlTemplate",
      });
      return;
    }

    createTenantEmailTemplate({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        template_name: templateName,
        template_type: templateType.name,
        subject: subject,
        plain_text: plaintext,
        html_template: htmlTemplate,
      },
    });
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (emailTemplateData && emailTemplateData.body) {
      //   console.log("email Template data", emailTemplateData);
      toast.success("Template Added", {
        id: "emailTemplateAdded",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/email-templates`);
      }, 700);
    }

    if (emailTemplateError) {
      console.log("email Template error", emailTemplateError);
      toast.error("Something went wrong", { id: "emailTemplateError" });
    }
  }, [emailTemplateData, emailTemplateError]);

  useEffect(() => {
    if (updateEmailTemplateData && updateEmailTemplateData.body) {
      console.log("update email template", updateEmailTemplateData);
      toast.success("Email Template updated", {
        id: "updateEmailTemplate",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/email-templates`)
      }, 1000);
    }

    if (updateEmailTemplateError) {
      console.log("updateEmailTemplateError", updateEmailTemplateError);
      toast.error("Something went wrong", {
        id: "updateEmailTemplateError",
      });
    }
  }, [updateEmailTemplateData, updateEmailTemplateError]);

  useEffect(() => {
    if (location.state) {
      // console.log(location.state);
      setIsUpdate(true);
      
      emailTypes.forEach( (emailType) => {
        if(emailType.name == location.state.template_type){
          setTemplateType(emailType)
        }
      })
      setTemplateName(location.state.template_name);
      setSubject(location.state.subject);
      setHtmlTemplate(location.state.html_template);
      setPlaintext(location.state.plain_text);
    }
  }, [location]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update" : "Add"} Email Template
            <LinksComponent />
          </div>

          <div className="p-3">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}

                  <div className="flex justify-center space-x-3">
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Template Name*"}
                        type={"text"}
                        value={templateName}
                        setValue={setTemplateName}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-full dropds">
                      <SelectComponent
                        title={"Template Type"}
                        selected={templateType}
                        setSelected={setTemplateType}
                        dataArr={emailTypes}
                      />
                    </div>
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Subject*"}
                        type={"text"}
                        value={subject}
                        setValue={setSubject}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center space-x-3">
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Plain Text"}
                        type={"textarea"}
                        value={plaintext}
                        setValue={setPlaintext}
                        required={true}
                        rows={4}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center space-x-3">
                    <div className="mb-6 w-full">
                  <div className="my-1">Instructions goes here......</div>
                      <InputComponent
                        name={"Html Template*"}
                        type={"textarea"}
                        value={htmlTemplate}
                        setValue={setHtmlTemplate}
                        required={true}
                        rows={20}
                      />
                    </div>
                    {/* <div className="mb-6 w-full">
                      <InputComponent
                        name={"Password"}
                        type={"text"}
                        value={password}
                        setValue={setPassword}
                        required={true}
                      />
                    </div> */}
                  </div>

                  <div className="flex justify-center mt-2">
                    {isUpdate ? (
                      <div className="flex space-x-3">
                        <div
                          type=""
                          onClick={(e) => {
                            setModalData(htmlTemplate);
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        >
                          Preview
                        </div>
                        <button
                          type="update"
                          onClick={(e) => handleUpdate(e)}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        >
                          Update
                        </button>
                      </div>
                    ) : (
                      <div className="flex space-x-3">
                        <div
                          type=""
                          onClick={(e) => {
                            setModalData(htmlTemplate);
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        >
                          Preview
                        </div>

                        <button
                          type="submit"
                          onClick={(e) => handleSubmit(e)}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        >
                          Add
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>

            {showModal && modalData && (
              <ModalComponent
                size={"4xl"}
                data={"Html Template"}
                show={showModal}
                setShow={setShowModal}
              >
                <div className="w-full">
                  <div className="w-full flex justify-center ">
                    <div className="mb-6 w-full">
                      <div
                        className=""
                        dangerouslySetInnerHTML={{ __html: modalData }}
                      />
                    </div>
                  </div>
                </div>
              </ModalComponent>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageEmailTemplate;
