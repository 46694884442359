import { rootApi } from "../../api/rootApi";

export const serverCaintanceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    sendMessageForTds: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/serverCaintance/tax-deduction`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    sendMessageForServerMaintainance: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/serverCaintance/`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    sendMessageForOpenRedemptionWindow: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/serverCaintance/open-redemption`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    sendMessageForStaticSms: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/serverCaintance/static-sms`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useSendMessageForTdsMutation , useSendMessageForServerMaintainanceMutation,useSendMessageForOpenRedemptionWindowMutation,useSendMessageForStaticSmsMutation
} = serverCaintanceApi;
