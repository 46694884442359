import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { useNavigate } from "react-router-dom";
import { useFetchProductsByCategoryIdMutation } from "../../../features/tenant/products/productsApi";
import { useFetchSubcategoriesMutation } from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { toast } from "react-hot-toast";
import { useAddBatchOfProductsWithWarrantyMutation } from "../../../features/tenant/productWarranty/productWarrantyApi";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";

const productBrandName = [
  {
    id: "1",
    name: "Apple",
  },
  {
    id: "2",
    name: "Samsung",
  },
];

const durationPeriod = {
  body: [
    {
      id: 0,
      name: "period",
      value: null,
    },
    {
      id: 1,
      name: "year",
      value: "y",
    },
    {
      id: 2,
      name: "month",
      value: "m",
    },
    // {
    //   id: 3,
    //   name: "week",
    //   value: "w",
    // },
    {
      id: 4,
      name: "day",
      value: "d",
    },
  ],
};

const AddWarrantyOnProducts = () => {
  const navigate = useNavigate();
  const [selectedProductCategory, setSelectedProductCategory] = useState({
    id: "-1",
    name: "select category",
  });
  const [selectedProductSubCategory, setSelectedProductSubCategory] = useState({
    id: "-1",
    name: "select sub-category",
  });

  const [selectedPrimaryDuration, setSelectedPrimaryDuration] = useState({
    id: "-1",
    name: "select period",
  });
  const [selectedSecondaryDuration, setSelectedSecondaryDuration] = useState({
    id: "-1",
    name: "select period",
  });
  const [selectedBrandName, setSelectedBrandName] = useState(
    productBrandName[0]
  );
  const [
    fetchProductsByCategoryId,
    {
      data: productsByCategoryData,
      isLoading: productsByCategoryIsLoading,
      isError: productsByCategoryIsError,
      error: productsByCategoryError,
    },
  ] = useFetchProductsByCategoryIdMutation() || {};

  const [
    addBatchOfProductsWithWarranty,
    {
      data: addBatchOfProductsWithWarrantyData,
      isLoading: addBatchOfProductsWithWarrantyIsLoading,
      isError: addBatchOfProductsWithWarrantyIsError,
      error: addBatchOfProductsWithWarrantyError,
    },
  ] = useAddBatchOfProductsWithWarrantyMutation() || {};

  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  // state for check mark change for all the products
  const [checkAllProducts, setCheckAllProducts] = useState(false);
  // state to track the changes for individal products
  const [checkProductsList, setCheckProductsList] = useState([]);
  // state for point change for all the products according to user
  const [productWarrantyChange, setProductWarrantyChange] = useState([]);

  useEffect(() => {
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useMemo(() => {
    console.log("memo");

    if (Number(selectedProductSubCategory.id) > 0) {
      // console.log("memo if",selectedProductSubCategory);
      fetchProductsByCategoryId({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        category_id: Number(selectedProductSubCategory.id),
      });
    }
  }, [selectedProductSubCategory]);

  useEffect(() => {
    if (productsByCategoryData && !productsByCategoryIsError) {
      console.log("objecproductsByCategoryDatat", productsByCategoryData);

      if(productsByCategoryData.message == "No Product Found !!!"){
        console.log("object", productsByCategoryData);
        return;
      }

      let isCheckedRequired = productsByCategoryData.body.products.map(
        (prod, index) => {
          let bool =
            prod.is_secondary_warranty || prod.is_primary_warranty
              ? true
              : false;
          return bool;
        }
      );

      console.log("isCheck required", isCheckedRequired);

      setCheckProductsList(
        productsByCategoryData.body.products.map((prod, index) => {
          return isCheckedRequired[index]
            ? { ...prod, checked: true }
            : { ...prod, checked: false };
        })
      );
      setProductWarrantyChange(
        productsByCategoryData.body.products.map((x) => {
          return {
            is_primary_warranty: x.is_primary_warranty,
            primary_warranty_on: x.primary_warranty_on,
            primary_warranty_period_date: x.primary_warranty_period
              ? x.primary_warranty_period.split("").splice(0 , x.primary_warranty_period.length-1 ).join("")
              : "",
            primary_warranty_period: x.primary_warranty_period
              ? x.primary_warranty_period.split("")[ x.primary_warranty_period.length-1  ]
              : "",
            is_secondary_warranty: x.is_secondary_warranty,
            secondary_warranty_on: x.secondary_warranty_on,
            secondary_warranty_period_date: x.secondary_warranty_period
              ? x.secondary_warranty_period.split("").splice(0 , x.secondary_warranty_period.length-1 ).join("")
              : "",
            secondary_warranty_period: x.secondary_warranty_period
              ? x.secondary_warranty_period.split("")[x.secondary_warranty_period.length-1 ]
              : "",
            product_id: x.product_id,
            name: x.name,
            product_code: x.product_code,
            warranty_activation_period : x.warranty_activation_period,
          };
        })
      );
    }
  }, [productsByCategoryData]);

  console.log(
    "productWarrantyChange.....................",
    productWarrantyChange
  );

  const handleAddWarrantyToProducts = (e) => {
    e.preventDefault();
    let reqData = [];

    productWarrantyChange.map((product, index) => {
      if (checkProductsList[index].checked) {
        let newProduct = {
          is_primary_warranty: product.primary_warranty_on && product.primary_warranty_on.length>0 && product.primary_warranty_period && product.primary_warranty_period.length > 0 && product.primary_warranty_period_date && product.primary_warranty_period_date >0 ? true : false,

          is_secondary_warranty: product.secondary_warranty_on && product.secondary_warranty_on.length >0 && product.secondary_warranty_period && product.secondary_warranty_period.length > 0 && product.secondary_warranty_period_date && product.secondary_warranty_period_date >0 ? true : false,

          primary_warranty_on: product.primary_warranty_on,

          primary_warranty_period: product.primary_warranty_period && product.primary_warranty_period.length > 0 && product.primary_warranty_period_date && product.primary_warranty_period_date >0 ? product.primary_warranty_period_date + product.primary_warranty_period : null,

          product_id: product.product_id,

          secondary_warranty_on: product.secondary_warranty_on ,

          secondary_warranty_period: product.secondary_warranty_period && product.secondary_warranty_period.length > 0 && product.secondary_warranty_period_date && product.secondary_warranty_period_date >0 ? product.secondary_warranty_period_date + product.secondary_warranty_period : null,

          warranty_activation_period: product.warranty_activation_period,
        };
        reqData.push(newProduct);
        // reqData.push(productWarrantyChange[index]);
      }
      return;
    });

    if (reqData.length === 0) {
      toast.error("please select the product", {
        id: "check-mark-product-error",
      });
      return;
    }

    console.log("reqdata", reqData);
    addBatchOfProductsWithWarranty({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: reqData ,
    })
    // navigate(-1);
  };

  useEffect(()=>{
    if(addBatchOfProductsWithWarrantyData && !addBatchOfProductsWithWarrantyIsError){
      toast.dismiss();
      toast.success("Successfully Added Warranty to Products",{
        id : "addBatchOfProductsWithWarrantyData"
      })
    }
  },[addBatchOfProductsWithWarrantyData])

  if(addBatchOfProductsWithWarrantyIsError){
    toast.dismiss();
    toast.error("Ops something went wrong",{
      id : "addBatchOfProductsWithWarrantyIsError"
    })
  }
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
  .color_shades;
  return (
    <div className="flex w-full bg-slate-200 h-full ">
      <div className="w-full justify-center ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Manage Products Warranty
          <LinksComponent />
        </div>
        <div className="p-3">
          <div className=" bg-white shadow rounded-lg">
            <div className="bg-gray-100 rounded-t-lg p-3">
              {/* <div className="w-full max-w-full px-3 flex-0 sm:w-4/12 mb-3">
                <SelectComponent
                  title={"Brand Name"}
                  selected={selectedBrandName}
                  setSelected={setSelectedBrandName}
                  dataArr={productBrandName}
                />
              </div>
              <div className="w-full max-w-full px-3 flex-0 sm:w-4/12 mb-3">
                <SelectComponent
                  title={"Category"}
                  selected={selectedProductCategory}
                  setSelected={setSelectedProductCategory}
                  dataArr={productCategories}
                />
              </div> */}
              <div className="w-full capitalize">
                <SelectComponent className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-auto "
                  title={"Sub Category"}
                  selected={selectedProductSubCategory}
                  setSelected={setSelectedProductSubCategory}
                  dataArr={subCategoriesData ? subCategoriesData?.body : []}
                />
              </div>
            </div>

            <div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
            {Number(selectedProductSubCategory.id) <= 0 ? (
              <></>
            ) : productsByCategoryData &&
              productsByCategoryData.body.length < 1 ? (
              <></>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  {/* <button
                    className="fixed m-6 p-3 bottom-2 right-2  bg-blue-700 text-blue-100 rounded-full "
                    onClick={handleAddWarrantyToProducts}
                  >
                    <AiOutlinePlus className="w-6 h-6 " />
                  </button> */}
                  <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={handleAddWarrantyToProducts}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
                  <tr>
                    <th scope="col" className="p-4">
                      <div className="flex items-center whitespace-nowrap">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 font-normal text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                          checked={checkAllProducts}
                          onChange={() => {
                            setCheckAllProducts(!checkAllProducts);
                            setCheckProductsList(
                              checkProductsList.map((x) => {
                                x.checked = !checkAllProducts;
                                return x;
                              })
                            );
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3  whitespace-nowrap">
                      Product name
                    </th>
                    <th scope="col" className=" text-center px-6 py-3  whitespace-nowrap">
                      <span>Warranty Duration</span>
                      <div className="w-full">
                        <input
                          type="text"
                          className="shadow-sm bg-gray-50 border font-normal border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder={"Enter no of days"}
                         
                          onChange={(e) => {
                            productWarrantyChange.map((x) => {
                              x.warranty_activation_period = e.target.value;
                            });
                            setProductWarrantyChange([
                              ...productWarrantyChange,
                            ]);
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col" className=" text-center px-6 py-3  whitespace-nowrap">
                      <span>primary warranty on </span>
                      <div className="w-full">
                        <input
                          type="text"
                          className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder={"Enter Part Name"}
                         
                          onChange={(e) => {
                            productWarrantyChange.map((x) => {
                              x.primary_warranty_on = e.target.value;
                            });
                            setProductWarrantyChange([
                              ...productWarrantyChange,
                            ]);
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col" className=" text-center px-6 py-3  whitespace-nowrap">
                      <span>primary warranty period</span>
                      <div className="flex w-full">
                        <input
                          type="number"
                          // key={user.id}
                          className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder={"Enter Number"}
                          onChange={(e) => {
                            productWarrantyChange.map((x) => {
                              x.primary_warranty_period_date = e.target.value;
                            });
                            setProductWarrantyChange([
                              ...productWarrantyChange,
                            ]);
                          }}
                        />
                        {/* <SelectComponent
                          //   title={"Sub Category"}
                          selected={selectedPrimaryDuration}
                          setSelected={setSelectedPrimaryDuration}
                          dataArr={durationPeriod ? durationPeriod?.body : []}
                        /> */}
                        <select
                          className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  h-auto capitalize p-2.5  w-48 ml-1 "
                          onChange={(e) => {
                            productWarrantyChange.map((x) => {
                              x.primary_warranty_period = e.target.value;
                            });
                            setProductWarrantyChange([
                              ...productWarrantyChange,
                            ]);
                          }}
                        >
                          {durationPeriod?.body.map((x) => {
                            return <option value={x.value}>{x.name}</option>;
                          })}
                        </select>
                      </div>
                    </th>
                    <th scope="col" className=" text-center px-6 py-3  whitespace-nowrap">
                      <span>secondary warranty on</span>
                      <div className="w-full">
                        <input
                          type="text"
                          // key={user.id}
                          className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder={"Enter Part Name"}
                          onChange={(e) => {
                            productWarrantyChange.map((x) => {
                              x.secondary_warranty_on = e.target.value;
                            });
                            setProductWarrantyChange([
                              ...productWarrantyChange,
                            ]);
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col" className=" text-center px-6 py-3  whitespace-nowrap">
                      <span>secondary warranty period</span>
                      <div className="flex w-full">
                        <input
                          type="number"
                          // key={user.id}
                          className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                          placeholder={"Enter Number"}
                          onChange={(e) => {
                            productWarrantyChange.map((x) => {
                              x.secondary_warranty_period_date = e.target.value;
                            });
                            setProductWarrantyChange([
                              ...productWarrantyChange,
                            ]);
                          }}
                        />
                        <select
                          className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  h-auto capitalize p-2.5  w-48 ml-1  "
                          onChange={(e) => {
                            productWarrantyChange.map((x) => {
                              x.secondary_warranty_period = e.target.value;
                            });
                            setProductWarrantyChange([
                              ...productWarrantyChange,
                            ]);
                          }}
                        >
                          {durationPeriod?.body.map((x) => {
                            return <option value={x.value}>{x.name}</option>;
                          })}
                        </select>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productsByCategoryData &&
                    productsByCategoryData.body.products &&
                    productsByCategoryData.body.products.map(
                      (product, index) => {
                        return (
                          <>
                            <tr
                              className="bg-white border-b  hover:bg-gray-50 
                            "
                              key={product.product_id}
                            >
                              <td className="w-4 p-4" key={index}>
                                <div className="flex items-center" key={index}>
                                  <input
                                    id="checkbox-table-search-1"
                                    type="checkbox"
                                    key={index}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
                                    checked={
                                      checkProductsList &&
                                      checkProductsList.length > 0 &&
                                      checkProductsList[index]?.checked
                                    }
                                    onChange={() => {
                                      checkProductsList[index].checked =
                                        checkProductsList.length > 0 &&
                                        !checkProductsList[index].checked;
                                      setCheckProductsList([
                                        ...checkProductsList,
                                      ]);
                                    }}
                                  />
                                </div>
                              </td>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                              >
                                {product.name}
                              </th>
                              <td className="px-6 py-4" key={index}>
                                <div className="w-full">
                                  <input
                                    type="number"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                    value={
                                      productWarrantyChange?.length > 0
                                        ? productWarrantyChange[index]
                                            .warranty_activation_period
                                        : ""
                                    }
                                    onChange={(e) => {
                                      productWarrantyChange[
                                        index
                                      ].warranty_activation_period = e.target.value;
                                      setProductWarrantyChange([
                                        ...productWarrantyChange,
                                      ]);
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="px-6 py-4" key={index}>
                                <div className="w-full">
                                  <input
                                    type="text"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                    value={
                                      productWarrantyChange?.length > 0
                                        ? productWarrantyChange[index]
                                            .primary_warranty_on
                                        : ""
                                    }
                                    onChange={(e) => {
                                      productWarrantyChange[
                                        index
                                      ].primary_warranty_on = e.target.value;
                                      setProductWarrantyChange([
                                        ...productWarrantyChange,
                                      ]);
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="px-6 py-4" key={index}>
                                <div className="flex w-full">
                                  <input
                                    type="number"
                                    // key={user.id}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                    placeholder={"enter number"}
                                    value={
                                      productWarrantyChange?.length > 0
                                        ? productWarrantyChange[index]
                                            .primary_warranty_period_date
                                        : ""
                                    }
                                    onChange={(e) => {
                                      productWarrantyChange[
                                        index
                                      ].primary_warranty_period_date =
                                        e.target.value;
                                      setProductWarrantyChange([
                                        ...productWarrantyChange,
                                      ]);
                                    }}
                                  />
                                  <select
                                    className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  h-auto capitalize p-2.5  w-48 ml-1 "
                                    value={
                                      productWarrantyChange?.length > 0
                                        ? productWarrantyChange[index]
                                            .primary_warranty_period
                                        : ""
                                    }
                                    onChange={(e) => {
                                      productWarrantyChange[
                                        index
                                      ].primary_warranty_period =
                                        e.target.value;
                                      setProductWarrantyChange([
                                        ...productWarrantyChange,
                                      ]);
                                    }}
                                  >
                                    {durationPeriod?.body.map((x) => {
                                      return (
                                        <option value={x.value}>
                                          {x.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </td>
                              <td className="px-6 py-4" key={index}>
                                <div className="w-full">
                                  <input
                                    type="text"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                    value={
                                      productWarrantyChange?.length > 0
                                        ? productWarrantyChange[index]
                                            .secondary_warranty_on
                                        : ""
                                    }
                                    onChange={(e) => {
                                      productWarrantyChange[
                                        index
                                      ].secondary_warranty_on = e.target.value;
                                      setProductWarrantyChange([
                                        ...productWarrantyChange,
                                      ]);
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="px-6 py-4" key={index}>
                                <div className="flex w-full">
                                  <input
                                    type="number"
                                    // key={user.id}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                    placeholder={"enter number"}
                                    value={
                                      productWarrantyChange?.length > 0
                                        ? productWarrantyChange[index]
                                            .secondary_warranty_period_date
                                        : ""
                                    }
                                    onChange={(e) => {
                                      productWarrantyChange[
                                        index
                                      ].secondary_warranty_period_date =
                                        e.target.value;
                                      setProductWarrantyChange([
                                        ...productWarrantyChange,
                                      ]);
                                    }}
                                  />
                                  <select
                                    className="shadow-sm font-normal bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  h-auto capitalize p-2.5  w-48 ml-1  "
                                    value={
                                      productWarrantyChange?.length > 0
                                        ? productWarrantyChange[index]
                                            .secondary_warranty_period
                                        : ""
                                    }
                                    onChange={(e) => {
                                      productWarrantyChange[
                                        index
                                      ].secondary_warranty_period =
                                        e.target.value;
                                      setProductWarrantyChange([
                                        ...productWarrantyChange,
                                      ]);
                                    }}
                                  >
                                    {durationPeriod?.body.map((x) => {
                                      return (
                                        <option value={x.value}>
                                          {x.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )}
                </tbody>
              </table>
            )}
          </div>
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default AddWarrantyOnProducts;
