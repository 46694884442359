import { rootApi } from "../../api/rootApi";

export const printerApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    printQrCodes: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr-print/print`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    
    addPrinter: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr-print/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchAllPrinters: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr-print/all`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchPrinterByID: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qr-print/${params.id}?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updatePrinterByID: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/qr-print/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    deletePrinterById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/qr-print/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const { useAddPrinterMutation , usePrintQrCodesMutation , useFetchAllPrintersMutation , useFetchPrinterByIDMutation , useUpdatePrinterByIDMutation , useDeletePrinterByIdMutation } = printerApi;
