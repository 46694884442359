import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const LinksComponent = () => {
  const location = useLocation();
  const [links, setlinks] = useState([]);
  useEffect(() => {
    const arr = location.pathname.split("/");
    const paths = [];
    paths.push({ to: arr[2], name: arr[2] });
    let i = 0;
    for (let index = 3; index < arr.length; index++) {
      const element = arr[index];
      // console.log(element);
      if (element === "") {
        continue;
      }
      paths.push({
        to: `${paths[i].to}/${element}`,
        name: element.charAt(0).toUpperCase() + element.slice(1),
      });
      i++;
    }
    paths[0].name = "Home";
    setlinks(paths);
    // console.log(paths);
  }, [location.pathname]);


  return (
    <>
      <span className="block text-xs font-normal text-gray-500 mb-1">
        {/* <Link to={`/genefied/${getVendorName()}/`}>Home</Link> » */}
        {links &&
          links.map((link, idx) => (
            <div className="inline" key={idx}>
              {" "}
              {links.length == idx + 1 ? (
                < >{link.name}</>
              ) : (
                <>
                  <Link to={`/genefied/${link.to} `}>{link.name}</Link>»{" "}
                </>
              )}
            </div>
          ))}
      </span>
    </>
  );
};

export default LinksComponent;
