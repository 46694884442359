import React, { useEffect, useState } from 'react'
import InputComponent from '../../../../components/Tenant/InputComponent/InputComponent'
import LinksComponent from '../../../../components/Tenant/LinksComponent/LinksComponent'
import DataTableComponent from '../../../../components/Tenant/DataTableComponent/DataTableComponent'
import { Menu } from '@headlessui/react'
import { HiOutlinePlus } from 'react-icons/hi2'
import ModalComponent from '../../../../components/Tenant/ModalComponent/ModalComponent'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useAddUserRolesSupplyBeamMutation, useDeleteUserRolesSupplyBeamMutation, useGetAllUserRolesSupplyBeamMutation, useUpdateUserRolesSupplyBeamMutation } from '../../../../features/supplyBeam/userRoleSB/userRoleSB'

const UserRolesSupplyBean = () => {

    const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState("null");
  const [
    getAllRoles,
    {
      data: getAllRolesData,
      isLoading: getAllRolesIsLoading,
      isError: getAllRolesIsError,
      error: getAllRolesError,
    },
  ] = useGetAllUserRolesSupplyBeamMutation();
  const [
    addUserRole,
    {
      data: AddUserRoleData,
      isLoading: AddUserRoleIsLoading,
      isError: AddUserRoleIsError,
      error: AddUserRoleError,
    },
  ] = useAddUserRolesSupplyBeamMutation();
  const [
    updateUserRoleById,
    {
      data: updateUserRoleByIdData,
      isLoading: updateUserRoleByIdIsLoading,
      isError: updateUserRoleByIdIsError,
      error: updateUserRoleByIdError,
    },
  ] = useUpdateUserRolesSupplyBeamMutation();

  const [
    deleteUserRole,
    {
      data: deleteUserRoleData,
      isLoading: deleteUserRoleIsLoading,
      isError: deleteUserRoleIsError,
      error: deleteUserRoleError,
    },
  ] = useDeleteUserRolesSupplyBeamMutation();

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };
  
  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleShowModal = (type, data) => {
    setAction(type);
    if (type === "add") {
      setRole("");
      setShowModal(true);
    }

    if (type === "edit") {
      setRole(data.name);
      setId(data.id);
      setShowModal(true);
    }
  };

  const addRole = () => {
    addUserRole({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        name: role,
      },
    });
  };

  const editRole = () => {
    updateUserRoleById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      role_id: id,
      data: {
        name: role,
      },
    });
  };

  const deleteRole = (data) => {
    deleteUserRole({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      role_id: data.id,
    });
  };

  const getAllRolesFun = () => {
    getAllRoles({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!role) {
      toast.error("Please enter the Role Name", {
        id: "imageError",
      });
      return;
    }
    if (action === "add") {
      addRole();
      return;
    }
    editRole();
  };

  useEffect(() => {
    if (deleteUserRoleData) {
      toast.success("Role Deleted Successfully !!");
      getAllRolesFun();
    }
    if (deleteUserRoleError) {
      toast.error("Error in Deleting Role");
    }
  }, [deleteUserRoleData, deleteUserRoleError]);
  useEffect(() => {
    if (updateUserRoleByIdData) {
      toast.success("Role Updated Successfully !!");
      getAllRolesFun();
      setShowModal(!showModal);
    }
    if (updateUserRoleByIdError) {
      toast.error("Error in Updating Role");
    }
  }, [updateUserRoleByIdData, updateUserRoleByIdError]);

  useEffect(() => {
    if (AddUserRoleData) {
      toast.success("Role Added Successfully !!");
      getAllRolesFun();
      setShowModal(!showModal);
    }
    if (AddUserRoleError) {
      toast.error("Error in Creating Role");
    }
  }, [AddUserRoleData, AddUserRoleError]);

  useEffect(() => {
    getAllRolesFun();
  }, []);
  useEffect(() => {
    if (getAllRolesData?.body) {
      console.log(getAllRolesData);
      setRoles(sortResponseByCreatedAt(getAllRolesData?.body));
    }
  }, [getAllRolesData]);

  const sortResponseByCreatedAt = (response) => {
    const dataArray = Object.values(response); // Convert the response object into an array of values

    // Filter out non-data elements (e.g., "message", "success")
    const data = dataArray.filter((item) => typeof item === "object");

    // Sort the data array based on the "created_at" property
    const sortedData = data.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return new Date(a.created_at) - new Date(b.created_at);
      }
      return 0;
    });

    return sortedData;
  };

    const columns = [
        {
          name: "Role",
          cell: (row) => row.name,
          width: "50%",
        },
        {
          name: "",
          cell: (row) =>
            row.name !== "Admin" && (
              <div className="flex justify-center ">
                <>
                  <button
                    onClick={() => handleShowModal("edit", row)}
                    className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    <AiOutlineEdit className="mr-1" />
                    Edit Role
                  </button>
                </>
              </div>
            ),
          width: "15%",
        },
        {
          name: "Action",
          cell: (row) => (
            row.name !== "Admin" &&
            <div className="flex justify-center">
              <>
                <button
                  onClick={() => navigate(`add`, { state: row })}
                  className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                  <AiOutlineEdit className="mr-1" />
                  Edit Permissions
                </button>
              </>
            </div>
          ),
          width: "20%",
        },
        {
          name: "",
          cell: (row) => (
            row.name !== "Admin" &&
            <div className="flex justify-center ">
              <>
                <button
                  onClick={() => deleteRole(row)}
                  className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                >
                  <AiOutlineDelete className="mr-1" />
                  Delete
                </button>
              </>
            </div>
          ),
          width: "15%",
        },
      ];
  return (
    <>
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Web Panel Roles
          <LinksComponent />
        </div>
        <div className="p-3">
        <div className="p-3 bg-white shadow rounded-lg">
          <div className="">
            <DataTableComponent columns={columns} data={roles}>
              {/* <div className="flex m-2 space-x-2 justify-end"></div> */}
            </DataTableComponent>
            <div
              className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
              id="box_btn"
            >
              <div className="text-right">
                <Menu className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={() => handleShowModal("add")}
                      className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                      style={{ backgroundColor: `${primaryColor["700"]}` }}
                    >
                      <HiOutlinePlus className="w-full h-full p-3" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div></div>

      {showModal && (
        <ModalComponent
          size={"sm"}
          data={"nadnandand"}
          show={showModal}
          setShow={setShowModal}
        >
          <div className="w-full p-4">
            <div className="w-full flex justify-center ">
              <div className="flex items-center justify-center w-full mb-6">
                <InputComponent
                  name={"Enter Role Name*"}
                  placeholder={"Enter Role Name"}
                  type={"text"}
                  value={role}
                  setValue={setRole}
                  required={true}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                onClick={(e) => handleSubmit(e)}
              >
                Add
              </button>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  </>
  )
}

export default UserRolesSupplyBean