import React, { useMemo } from 'react'
import DataTable from 'react-data-table-component';
import { useFetchVendorsThemeQuery } from '../../../features/admin/vendorTheme/vendorThemeApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import tailwindColor from "../../../../node_modules/tailwindcss/colors";

const VendorTheme = () => {
    const navigate = useNavigate();

    const {
        data: vendorsThemeData,
        isError: vendorsThemeIsError,
        isLoading: vendorsThemeIsLoading,
      } = useFetchVendorsThemeQuery(sessionStorage.getItem("access_token")) || {};

      const handleButtonEditClick = (vendorThemeData)=>{
        navigate("/addVendorTheme",{
            state : vendorThemeData});
      }

      // if(vendorsThemeIsLoading){
      //   return toast.loading("loading ...",{
      //       id : "loadingId",
      //       autoClose: 1000,
      //   });
      // }

      if(vendorsThemeIsError){
        return toast.error("Ops , some error Occur ",{
            id : "errorId",
            autoClose: 1000,
        });
      }

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1 ,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.tenant_name,
      sortable: true,
    },
    {
      name: "Font",
      selector: (row) => row.theme.font,
      sortable: false,
      allowOverflow: false,
    },
    {
      name: "Color",
      selector: (row) => row.theme.primary_color,
      sortable: false,
      allowOverflow: false,
    },

    {
      name: "Created On",
      selector: (row) =>new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div className="main-wrapper">
      <div className="page-content">
        <div className='card p-4'>
        <div className="borderbtm">
          <div>
            <h4 className="mb-3 mb-md-0 card-title">Welcome to Client Theme Master</h4>
          </div>
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            {/* <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              onClick={() => navigate("/addVendorTheme")}
            >
              Add Vendor Theme
            </button> */}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-xl-12">
            <DataTable
              title="Themes"
              data={vendorsThemeData && vendorsThemeData.body}
              columns={columns}
              //selectableRows
            //   onSelectedRowsChange={handleChange}
            />
          </div>
        </div>
      </div></div>
    </div>
  )
}

export default VendorTheme