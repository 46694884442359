import { rootApi } from "../../api/rootApi";

export const userActionSBApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
   
    getAllSupplyBeamUserActions: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/supply-beam/user-action`,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
 
  }),
});

export const { useGetAllSupplyBeamUserActionsMutation } = userActionSBApi;
