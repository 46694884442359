import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  useGetAllTenantWebDashboardsMutation,
  useRemoveTenantWebDashboardMutation,
} from "../../../features/admin/tenantWebDashboard/tenantWebDashboardApi";
import { Box, Fade, Modal, Backdrop } from "@mui/material";
import { toast } from "react-hot-toast";

const AllTenantWebDashboards = () => {
  const navigate = useNavigate();
  const [popupData, setPopupData] = useState(null);
  const [viewProgramsPopup, setViewProgramsPopup] = useState(false);
  const handleProgramPopupOpen = () => setViewProgramsPopup(true);
  const handleProgramPopuClose = () => setViewProgramsPopup(false);
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "20%",
    minHeight: "20%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };
  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                RemoveTenantWebDashboardFun(r);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      }
    );
    return;
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Company Name",
      selector: (row) => row.tenant_name,
      sortable: true,
    },

    {
      name: "Created at",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setPopupData(r);
            handleProgramPopupOpen();
            //
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [
    GetAllTenantWebDashboards,
    {
      data: GetAllTenantWebDashboardsData,
      isError: GetAllTenantWebDashboardsIsError,
      isLoading: GetAllTenantWebDashboardsIsLoading,
      error: GetAllTenantWebDashboardsError,
    },
  ] = useGetAllTenantWebDashboardsMutation();

  const [
    RemoveTenantWebDashboard,
    {
      data: RemoveTenantWebDashboardData,
      isError: RemoveTenantWebDashboardIsError,
      isLoading: RemoveTenantWebDashboardIsLoading,
      error: RemoveTenantWebDashboardError,
    },
  ] = useRemoveTenantWebDashboardMutation();

  useEffect(() => {
    GetAllTenantWebDashboardsFun();
  }, []);

  const GetAllTenantWebDashboardsFun = () => {
    GetAllTenantWebDashboards({
      token: sessionStorage.getItem("access_token"),
    });
  };

  const RemoveTenantWebDashboardFun = (r) => {
    RemoveTenantWebDashboard({
      token: sessionStorage.getItem("access_token"),
      id: r.id,
    });
  };

  useEffect(() => {
    if (RemoveTenantWebDashboardData) {
      toast.dismiss();
      toast.success("Web Dashboard Deleted Successfully", {
        id: "dashboardSuccess",
      });
      GetAllTenantWebDashboardsFun();
    }
    if (RemoveTenantWebDashboardIsError) {
      toast.error("error in Deleting web Dashboard", {
        id: "dashboardError",
      });
    }
  }, [RemoveTenantWebDashboardData, RemoveTenantWebDashboardIsError]);

  return (
    <div className="page-content">
      <div>
        {viewProgramsPopup && (
          <Modal
            keepMounted
            open={viewProgramsPopup}
            onClose={handleProgramPopuClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={viewProgramsPopup}>
              <Box sx={style2}>
                <div className="card-body">
                  <h2
                    className="card-title"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {" "}
                    {popupData &&
                      popupData.tenant_name + " " + " Web Dashboard"}{" "}
                  </h2>
                  <table class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col-3">S No.</th>
                        <th scope="col-3">Options</th>
                      </tr>
                    </thead>
                    {popupData && (
                      <tbody>
                        {popupData.graphs.map((program, idx) => (
                          <tr>
                            <th scope="row">{idx + 1}</th>
                            <td>{program}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </Box>
            </Fade>
          </Modal>
        )}
      </div>
      <div className="card p-4">
        <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="mb-3 mb-md-0 card-title">
              Welcome to Tenant Dashboard setups
            </h4>
          </div>
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              onClick={() => navigate("/tenantWebDashboard/add")}
            >
              Add New Tenant Dashboard
            </button>
          </div>
        </div>

        <div className="row">
          <div className="table-responsive">
            <DataTable
              // title="All Workflows"
              data={GetAllTenantWebDashboardsData?.body}
              columns={columns}
              // onSelectedRowsChange={handleChange}
              // customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              pagination
              // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              // subHeaderComponent={subHeaderComponentMemo}
              // selectableRows
              persistTableHead
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTenantWebDashboards;
