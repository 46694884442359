import { rootApi } from "../../api/rootApi";

export const tenantFirebaseSetupApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFirebaseSetup: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/firebase`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addFirebaseSetup: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/firebase/add`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),
    updateFirebaseSetup: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/firebase/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getFirebaseSetupById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/firebase/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getFirebaseSetupByTenant: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/firebase/tenant/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    removeFirebaseSetupById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/firebase/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
  }),
});
export const {
useGetAllFirebaseSetupMutation , useAddFirebaseSetupMutation , useUpdateFirebaseSetupMutation , useGetFirebaseSetupByIdMutation , useGetFirebaseSetupByTenantMutation , useRemoveFirebaseSetupByIdMutation
} = tenantFirebaseSetupApi;
