import React from "react";

const ListItem = ({ boxColor, boxNumber, handleDrag, handleDrop , name}) => {
  return (
    <div
      draggable={true}
      id={boxNumber}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      className="text-center"
      style={{
        border: "1px solid #bac3ff2e",
        borderRadius: "5px",
        paddingTop: "10px",
        marginBottom: "5px",
        backgroundColor: "#bac3ff2e",
      }}
    >
       <p className=" pb-2 ">{name}</p>
    </div>
  );
};

export default ListItem;
