import { rootApi } from "../../api/rootApi";

export const batchApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllBatch: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/batch?limit=${params.limit}&offset=${params.offset}${
            params.batchListName ? `&batchListName=${params.batchListName}` : ""
          }`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchBatchByBatchID: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/batch/${params.batchId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addBatch: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/batch/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateBatch: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/batch/${params.batchId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteBatch: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/batch/${params.batchId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchAllBatchMutation,
  useFetchBatchByBatchIDQuery,
  useAddBatchMutation,
  useUpdateBatchMutation,
  useDeleteBatchMutation,
} = batchApi;
