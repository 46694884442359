import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useFetchIndustriesMutation } from "../../../features/admin/industryMaster/industryMasterApi";

const IndustryMaster = () => {
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const [userTypes, setUsersTypes] = useState([]);

  const [tableDataItems, setTableDataItems] = useState([]);

  // useEffect(() => {
  //   console.log("state", selectedRows);
  // }, [selectedRows]);

  const [fetchIndustries , {
    data: industriesData,
    isError: industruesIsError,
    isLoading: industruesIsLoading,
    error: industruesError,
  } ]= useFetchIndustriesMutation();
 
  useMemo(() => {
    if (industriesData) {
      (() => {
        setTableDataItems(industriesData.body);
      })();
    }

    if (industruesError) {
      toast.error(industruesError.error, {
        id: "industriesFetchError",
      });
    }
  }, [industriesData, industruesError]);

  useEffect(() => {
    fetchIndustries(sessionStorage.getItem("access_token"))
  }, [])
  

  // useEffect(() => {
  //   var config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_BASE_URL}/admin/industry`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + sessionStorage.getItem("access_token"),
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data);
  //       setTableDataItems(response.data.body);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  const handleButtonEditClick = (r) => {
    //handle the edit functionality
    navigate(`/updateIndustry?industry=${r.industry_id}`);
  };

  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>
            Are you sure you want to delete this ?
          </h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleButtonDeleteClick(r)
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteIndustry",
      },
    );
    return;
  }

  const handleButtonDeleteClick = (r) => {
    //handle the delete functionality

    let tbc = [...tableDataItems];
    const objWithIdIndex = tbc.findIndex(
      (obj) => obj.industry_id === r.industry_id,
    );
    if (objWithIdIndex > -1) {
      tbc.splice(objWithIdIndex, 1);
    }
    setTableDataItems(tbc);

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/admin/industry/${r.industry_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        fetchIndustries(sessionStorage.getItem("access_token"));
        if (response.data.success) {
          toast.dismiss();
          toast.success(response.data.message, {
            id: "deleteIndustrySuccess",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "deleteIndustryError",
          },
        );
      });
  };

  const handleChange = useCallback((state) => {
    // setSelectedRows(state.selectedRows);
  }, []);

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1 ,
      sortable: true,
      width : "80px"
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Users",
      selector: (row) => {
        return row.users.map((user) => <p key={user}> {user} </p>);
      },
    },

    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  if (industruesIsError) {
    return (
      <div>
        <div className="alert alert-danger" role="alert">
          {industruesError?.error}
        </div>
      </div>
    );
  }

  if (industruesIsLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="page-content">
        <div className="card p-4">
        <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="mb-3 mb-md-0 card-title">Welcome to Industry Master</h4>
          </div>
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              onClick={() => navigate("/addIndustry")}
            >
              Add New Industry
            </button>
          </div>
        </div>

        <div className="row">
          <div className="table-responsive">
            <DataTable
              title="Industries"
              data={tableDataItems}
              columns={columns}
              //selectableRows
              onSelectedRowsChange={handleChange}
              // customStyles={customStyles}
              striped
              highlightOnHover
              pointerOnHover
            />
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default IndustryMaster;
