import { rootApi } from "../../api/rootApi";


export const dwamApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        fetchDwam: builder.query({
            query: (token) => {
                return {
                method: 'GET',
                url: '/admin/dwan',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
            }}
        }),
        addDwam : builder.mutation({
            query: (params) => {
                return {
                method: 'POST',
                url: '/admin/Dwam/add',
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + params.token,
                },
                body : JSON.stringify(params.body)
            }}
        }),
        
    })

});
export const { useFetchDwamQuery , useAddDwamMutation } = dwamApi;