import { rootApi } from "../../api/rootApi";

export const uomKeysApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUomKeys: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/admin/uomKeys",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug : params?.tenant_id,
          },
        };
      },
    }),
    addUomKeys: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/admin/uomKeys/add",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
            slug: params?.tenant_id,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),

    fetchUomKeyById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/uomKeys/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
        };
      },
    }),

    updateUomKeyById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/uomKeys/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),

    deleteUomKeyById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/uomKeys/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
        };
      },
    }),
  }),
});
export const {
  useAddUomKeysMutation,
  useFetchUomKeysMutation,
  useFetchUomKeyByIdMutation,
  useUpdateUomKeyByIdMutation,
  useDeleteUomKeyByIdMutation,
} = uomKeysApi;
