import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { tenantLogOut } from "../../features/tenant/tenantAuth/tenantAuthSlice";
import { useGetAppUserTypesMutation } from "../../features/tenant/appUsers/appUsersApi";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../utils/getVendorName";

const Navbar = ( {togglesidebar} ) => {
  const [toggleUserMenuBtn, setToggleUserMenuBtn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const { name, role_name } = JSON.parse(sessionStorage.getItem("userTenant"));

  const logout = () => {
    const vendorData = JSON.parse(sessionStorage.getItem("vendorData"));

    toast(
      (t) => (
        <span>
          <h6>
            Are you sure you want to <b>Logout ?</b>
          </h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                sessionStorage.clear();
                dispatch(tenantLogOut());
                window.location.href = `/genefied/${vendorData.tenant_name}/`;
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "logout",
      }
    );
    return;
  };

  return (
    <nav
      style={{ backgroundColor: `${primaryColor["700"]}` }}
      className="bg-blue-700 shadow-xl sticky w-full top-0 text-black z-50"
    >
      <Toaster />
      <div className="mx-auto ">
        <div className="flex items-stretch justify-between h-14">
          {/* <div className="flex items-center md:hidden">
            <div className="-mr-2 flex hidden">
              
              <button
                type="button"
              
                className="clk1 bg-white ml-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-black hover:bg-white focus:outline-none   "
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div> */}
          <div className="flex items-center pl-6">
            <div className="flex-shrink-0 hidden">
              <a
                href="#"
                className="text-white flex items-center space-x-2 group"
              >
                <div>
                  <img
                    src="images/logo-admin.svg"
                    alt="error"
                    className="object-cover w-48"
                  />
                </div>
              </a>
            </div>
            {/* toggel sidebar */}
            <div
              className="text-white cursor-pointer hidden sm-block md:block"
              onClick={ () => togglesidebar()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </div>
          </div>
          <div className="hidden md:flex sm-flex items-stretch">
            {/* Profile Menu DT */}
            <div className="ml-4 flex md:ml-6 ">
              <div className="relative flex justify-center items-center mr-4">
                <div
                  style={{ backgroundColor: `${primaryColor["800"]}` }}
                  className="bg-blue-800 block hover:text-white p-1 rounded-full text-gray-400"
                >
                  <span className="sr-only">View notifications</span>
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </div>
              </div>

              {/* Profile dropdown */}
              <div
                style={{ backgroundColor: `${primaryColor["800"]}` }}
                className="mdpd8 relative bg-blue-800 pl-2 pr-3 text-white hover:text-white text-sm cursor-pointer"
                onClick={() => setToggleUserMenuBtn(!toggleUserMenuBtn)}
              >
                <div
                  className="flex items-center min-h-full gap-1"
                  //    @click="open = !open"
                >
                  <div
                    className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open user menu</span>
                    {/* <img
                      className="h-8 w-8 rounded-full"
                      src="https://assets.codepen.io/3321250/internal/avatars/users/default.png?fit=crop&format=auto&height=512&version=1646800353&width=512"
                      alt="error"
                    /> */}
                    <div className="h-8 w-8 rounded-full flex justify-center items-center bg-yellow-400 text-black">
                      {role_name.slice(0, 1)}
                    </div>
                  </div>
                  <div className="flex flex-col w-28 justify-center items-center capitalize">
                    <span className="text-sm">{name && name.split(" ")[0]}</span>
                    <span className="text-yellow-400 font-bold capitalize">
                      {role_name}
                    </span>
                  </div>
                </div>
                <div
                  x-show="open"
                  //    @click.away="open = false"
                  className={`origin-top-right absolute right-0 mt-0 min-w-full rounded-b-md shadow py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
                    toggleUserMenuBtn ? "" : "hidden "
                  }`}
                  // x-transition:enter="transition ease-out duration-100" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  <div
                    href=" "
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-0"
                    onClick={() => navigate(`/genefied/${getVendorName()}/profile`)}
                  >
                    My Profile
                  </div>

                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-1"
                    onClick={logout}
                  >
                    Sign out
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              //    @click="mobilemenue = !mobilemenue"
              className="mr-2"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://assets.codepen.io/3321250/internal/avatars/users/default.png?fit=crop&format=auto&height=512&version=1646800353&width=512"
                  alt="error"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu, show/hide based on menu state. */}
      <div
        className="md:hidden absolute bg-white w-full"
        id="mobile-menu"
        //  x-show="mobilemenue" x-transition:enter="transition ease-out duration-100" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95"
        //  @click.away="mobilemenue = false"
      >
        <div className="pt-4 pb-3 border-t border-gray-700 hidden">
          {/* profile menue for mobile */}
          <div className="flex items-center px-5">
            <div className="flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                src="https://assets.codepen.io/3321250/internal/avatars/users/default.png?fit=crop&format=auto&height=512&version=1646800353&width=512"
                alt="error"
              />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium leading-none text-gray-400">
                Tom Cook
              </div>
              <div className="text-sm font-medium leading-none text-gray-400">
                tom@example.com
              </div>
            </div>
            <button
              type="button"
              style={{ backgroundColor: `${primaryColor["700"]}` }}
              className="ml-auto bg-blue-700 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button>
          </div>
          <div className="mt-3 px-2 space-y-1">
            <a
              href="#"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              Your Profile
            </a>
            <span
              onClick={logout}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              Sign out
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
