import { rootApi } from "../../api/rootApi";

export const salesBoosterTriggerApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createSalesBoosterTrigger: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/sbTrigger/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllSalesBoosterTriggers: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/sbTrigger?id=${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    // fetchSalesBoosterById: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: "GET",
    //       url: `/tenant/sbTrigger/${params.id}&limit=${params.limit}&offset=${params.offset}`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + params.token,
    //         slug: params.tenant_id,
    //       },
    //     };
    //   },
    // }),

    // updateSalesBoosterById: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: "PUT",
    //       url: `/tenant/sbTrigger/${params.id}`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + params.token,
    //         slug: params.tenant_id,
    //       },
    //       body: params.body,
    //     };
    //   },
    // }),
    
    deleteSalesBoosterTriggerById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/sbTrigger/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const { useCreateSalesBoosterTriggerMutation , useDeleteSalesBoosterTriggerByIdMutation , useFetchAllSalesBoosterTriggersMutation  } = salesBoosterTriggerApi;
