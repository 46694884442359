import { rootApi } from "../../api/rootApi";

export const locationsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllState: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/location/states/1`,
          headers: {
            "Content-Type": "application/json",
            slug: params.tenant_id,
          },
        };
      },
    }),
    getAllDistrict: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/location/districts/${params.state_id}`,
          headers: {
            "Content-Type": "application/json",
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const { useGetAllDistrictMutation ,useGetAllStateMutation } = locationsApi;
