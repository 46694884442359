import { rootApi } from "../../api/rootApi";

export const appCampaignsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addAppCampaign: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/appCampaigns/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getAllAppCampaign: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/appCampaigns`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getAppCampaignById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/appCampaigns/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateAppCampaignById: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/appCampaigns/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    deleteAppCampaign: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/appCampaigns/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getAllAppCampaignByUserID: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/appCampaigns/userType/id`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getAllAppCampaignDatas: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/appCampaignData/feedback/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useAddAppCampaignMutation,
  useGetAllAppCampaignMutation,
  useGetAppCampaignByIdMutation,
  useUpdateAppCampaignByIdMutation,
  useDeleteAppCampaignMutation,
  useGetAllAppCampaignByUserIDMutation,
  useGetAllAppCampaignDatasMutation,
} = appCampaignsApi;
