import { rootApi } from "../../api/rootApi";


export const adminAuthApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        adminLogin: builder.mutation({
            query: (body) => {
                console.log("auth login");
                return {
                method: 'POST',
                url: '/admin/login',
                headers: {
                    "Content-Type": "application/json",
                },
                body : JSON.stringify(body)
            }}
        }),
    })

});
export const { useAdminLoginMutation } = adminAuthApi;