import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import {
  useGetAllDreamGiftsCheckoutMutation,
  useUpdateRedeemedDreamGiftsStatusMutation,
} from "../../../features/tenant/redeemedDreamGiftsStatus/redeemedGiftsStatusApi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { toast } from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useGetRedeemedGiftsStatusQuery } from "../../../features/tenant/redeemedGiftsStatus/redeemedGiftsStatusApi";

const TypeArr = [
  {
    id: "1",
    name: "Dream Gift",
  },
  {
    id: "2",
    name: "Other",
  },
];

const DreamGiftsRedeemRequest = () => {
  const [popUpBtnToggle, setPopUpBtnToggle] = useState(false);
  const [selectStatus, setSelectStatus] = useState(1);
  const [currentDreamGiftId, setCurrentDreamGiftId] = useState(null);
  const [progressBtnToggle, setProgressBtnToggle] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const navigate = useNavigate();

  const {
    data: getRedeemedGiftsStatusData,
    error: getRedeemedGiftsStatusError,
  } = useGetRedeemedGiftsStatusQuery({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  const [
    getAllDreamGiftsCheckout,
    {
      data: getAllDreamGiftsCheckoutData,
      error: getAllDreamGiftsCheckoutError,
    },
  ] = useGetAllDreamGiftsCheckoutMutation();

  const [
    updateRedeemedDreamGiftsStatus,
    {
      data: updateRedeemedDreamGiftsStatusData,
      error: updateRedeemedDreamGiftsStatusError,
    },
  ] = useUpdateRedeemedDreamGiftsStatusMutation();

  const handleGetAllDreamGiftsCheckout = (limit, offset) => {
    getAllDreamGiftsCheckout({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    // const hasViewPermission = hasPermission("dreamGiftsRedeemRequestList");
    // console.log(hasViewPermission);

    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }

    handleGetAllDreamGiftsCheckout(limit, offset);
  }, [limit, offset]);

  useEffect(() => {
    if (getAllDreamGiftsCheckoutData) {
      toast.success(getAllDreamGiftsCheckoutData.message, {
        id: "getAllDreamGiftsCheckoutData",
      });
    }

    if (getAllDreamGiftsCheckoutError) {
      toast.error(getAllDreamGiftsCheckoutData.data.message, {
        id: "getAllDreamGiftsCheckoutError",
      });
    }
  }, [getAllDreamGiftsCheckoutData, getAllDreamGiftsCheckoutError]);

  const handleUpdateRedeemedDreamGiftsStatus = (e) => {
    updateRedeemedDreamGiftsStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        status: `${selectStatus}`,
        dream_gift_id: `${currentDreamGiftId}`,
      },
    });
  };
  useEffect(() => {
    if (updateRedeemedDreamGiftsStatusData) {
      toast.dismiss();
      toast.success(`${updateRedeemedDreamGiftsStatusData.message}`, {
        id: `updateRedeemedGiftsStatusData`,
      });
      handleGetAllDreamGiftsCheckout(limit, offset);
      setPopUpBtnToggle(false);
    }

    if (updateRedeemedDreamGiftsStatusError) {
      toast.dismiss();
      toast.error(`${updateRedeemedDreamGiftsStatusError.data.message}`, {
        id: `updateRedeemedGiftsStatusError`,
      });
      setPopUpBtnToggle(false);
    }
  }, [updateRedeemedDreamGiftsStatusData, updateRedeemedDreamGiftsStatusError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "catalogue name",
      cell: (row) => row.catalogue_name,
      sortable: true,
    },
    {
      name: "Target Type",
      cell: (row) =>
        TypeArr.map((x) => {
          if (x.id == row.target_type) {
            return x.name;
          } else {
            return "";
          }
        }),
      sortable: true,
      // width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          onClick={() => {
            // handleStatusChange(row, "1");
            setCurrentDreamGiftId(row.dream_gift_id);
            setSelectStatus(Number(row.status));
            setPopUpBtnToggle(true);
          }}
          className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        >
          {row.status_name}
        </button>
      ),

      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          {" "}
          <button
            onClick={() => {
              // handleStatusChange(row, "2");
              setCurrentStatus(Number(row.status));
              setProgressBtnToggle(true);
            }}
            className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            view progress
          </button>
        </>
      ),
      sortable: true,
    },

    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  console.log("object", getAllDreamGiftsCheckoutData);
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Dream Gifts Redeem Request
            <LinksComponent />
          </div>

          <div className="w-full ">
            <div className="">
              <DataTableComponent
                columns={columns}
                title={""}
                data={
                  getAllDreamGiftsCheckoutData &&
                  getAllDreamGiftsCheckoutData.body.data
                }
                totalRows={
                  getAllDreamGiftsCheckoutData &&
                  getAllDreamGiftsCheckoutData.body.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {popUpBtnToggle && (
                <ModalComponent
                  size={"md"}
                  show={popUpBtnToggle}
                  setShow={setPopUpBtnToggle}
                >
                  <div className="w-full ">
                    <div className="w-full flex justify-center ">
                      <select
                        type="text"
                        className="border p-1"
                        placeholder="Add new UserType"
                        value={selectStatus}
                        onChange={(e) =>
                          setSelectStatus(Number(e.target.value))
                        }
                      >
                        {getRedeemedGiftsStatusData &&
                          getRedeemedGiftsStatusData.body.map((x, index) => {
                            return (
                              <option key={index} value={Number(index)}>
                                {x}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="w-full flex justify-center mt-5 ">
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={handleUpdateRedeemedDreamGiftsStatus}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </ModalComponent>
              )}
              {progressBtnToggle && (
                <ModalComponent
                  size={"md"}
                  show={progressBtnToggle}
                  setShow={setProgressBtnToggle}
                >
                  <div className="w-full ">
                    <div className="flex justify-center ">
                      <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400">
                        {getRedeemedGiftsStatusData.body.map((x, index) => {
                          if (index == 0) {
                            return <></>;
                          }
                          if (index <= currentStatus) {
                            return (
                              <li class="mb-10 ml-6">
                                <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                  <svg
                                    class="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 16 12"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M1 5.917 5.724 10.5 15 1.5"
                                    />
                                  </svg>
                                </span>
                                <h3 class="font-medium leading-tight capitalize ">
                                  {x}
                                </h3>
                              </li>
                            );
                          } else {
                            return (
                              <li class="mb-10 ml-6">
                                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                                  <svg
                                    class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 16"
                                  >
                                    <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                                  </svg>
                                </span>
                                <h3 class="font-medium leading-tight capitalize">
                                  {x}
                                </h3>
                                {/* <p class="text-sm">Step details here</p> */}
                              </li>
                            );
                          }
                        })}
                      </ol>
                    </div>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DreamGiftsRedeemRequest;
