
import { rootApi } from "../../api/rootApi";

export const appVideoApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addAppVideo: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/appVideo/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    getAppVideo: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/appVideo/`,
            headers: {
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
      getAppVideoTypes: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/appVideo/type`,
            headers: {
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }), 
     deleteAppVideo: builder.mutation({
        query: (params) => {
          return {
            method: "DELETE",
            url: `/tenant/appVideo//${params.id}`,
            headers: {
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
  }),
});

export const { useAddAppVideoMutation ,useGetAppVideoMutation,useGetAppVideoTypesMutation,useDeleteAppVideoMutation} = appVideoApi;
