import React, { useEffect } from "react";
import adminLogo from "../../assests/tenant/images/logo-admin.svg";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { selectUserTypes } from "../../features/tenant/companyProfile/companyProfileSlice";
import { useSelector } from "react-redux";
import { BiHomeAlt2, BiQrScan, BiError } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { VscActivateBreakpoints, VscListTree } from "react-icons/vsc";
import {
  AiFillBoxPlot,
  AiFillGift,
  AiFillPrinter,
  AiOutlineDeploymentUnit,
  AiOutlineEnvironment,
  AiOutlineQrcode,
  AiOutlineTransaction,
} from "react-icons/ai";
import { FaListAlt, FaObjectGroup, FaQuestion, FaRegNewspaper } from "react-icons/fa";
import { GrProductHunt, GrTransaction } from "react-icons/gr";
import {
  MdHistory,
  MdOutlineOndemandVideo,
  MdRedeem,
  MdOutlineCampaign,
  MdPayment,
  MdOutlineSms,
  MdOutlinePayment,
  MdNotificationsActive,
  MdOutlineAttachEmail,
  MdAlternateEmail,
} from "react-icons/md";
import { RiCheckboxMultipleBlankFill, RiQrScan2Line } from "react-icons/ri";
import { TfiGallery, TfiImport } from "react-icons/tfi";
import { TbAlphabetLatin } from "react-icons/tb";
import { FaUserAlt } from "react-icons/fa";
import { HiUserGroup, HiPrinter } from "react-icons/hi";
import { VscFilePdf } from "react-icons/vsc";
import { GiCartwheel } from "react-icons/gi";
import { IoPodiumOutline } from "react-icons/io5";
import { PiHandCoins, PiUserListThin } from "react-icons/pi";
import { GoOrganization } from "react-icons/go";
import { RiCoupon2Line, RiCoupon3Line } from "react-icons/ri";
import { BsCashCoin, BsListCheck, BsShieldCheck, BsBank, BsCash } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { useGetAllUserPermissionsMutation } from "../../features/tenant/userPermissions/userPermissions";
import { useGetAllRolesMutation } from "../../features/tenant/appRoles/appRoles.Api";
import { MdContactPhone } from "react-icons/md";

const Sidebar = ({ isOpen }) => {
  const [vendorData, setVendorData] = useState(null);
  useEffect(() => {
    const vendor = JSON.parse(sessionStorage.getItem("vendorData"));
    if (!vendorData) {
      setLogo(
        vendor.logo
          ? vendor.logo[1]
          : null
      );

      setVendorData(vendor);
    }
  }, []);
  const iconComponents = {
    VscActivateBreakpoints: <VscActivateBreakpoints />,
    ImProfile: <ImProfile />,
    BiQrScan: <BiQrScan />,
    BiError: <BiError />,
    FaListAlt: <FaListAlt />,
    TfiGallery: <TfiGallery />,
    MdOutlineOndemandVideo: <MdOutlineOndemandVideo />,
    AiFillBoxPlot: <AiFillBoxPlot />,
    RiQrScan2Line: <RiQrScan2Line />,
    MdRedeem: <MdRedeem />,
    MdHistory: <MdHistory />,
    RiCheckboxMultipleBlankFill: <RiCheckboxMultipleBlankFill />,
    AiFillGift: <AiFillGift />,
    AiFillPrinter: <AiFillPrinter />,
    AiOutlineQrcode: <AiOutlineQrcode />,
    FaObjectGroup: <FaObjectGroup />,
    GrProductHunt: <GrProductHunt />,
    VscListTree: <VscListTree />,
    TbAlphabetLatin: <TbAlphabetLatin />,
    FaUserAlt: <FaUserAlt />,
    HiUserGroup: <HiUserGroup />,
    BiHomeAlt2: <BiHomeAlt2 />,
    VscFilePdf: <VscFilePdf />,
    GiCartwheel: <GiCartwheel />,
    IoPodiumOutline: <IoPodiumOutline />,
    MdOutlineCampaign: <MdOutlineCampaign />,
    PiHandCoins: <PiHandCoins />,
    GoOrganization: <GoOrganization />,
    RiCoupon2Line: <RiCoupon2Line />,
    RiCoupon3Line: <RiCoupon3Line />,
    MdPayment: <MdPayment />,
    BsCashCoin: <BsCashCoin />,
    PiUserListThin: <PiUserListThin />,
    FiUsers: <FiUsers />,
    MdOutlineSms: <MdOutlineSms />,
    MdOutlinePayment: <MdOutlinePayment />,
    BsListCheck: <BsListCheck />,
    BsShieldCheck: <BsShieldCheck />,
    AiOutlineDeploymentUnit: <AiOutlineDeploymentUnit />,
    FaRegNewspaper: <FaRegNewspaper />,
    BsBank: <BsBank />,
    GrTransaction: <GrTransaction />,
    TfiImport: <TfiImport />,
    AiOutlineTransaction: <AiOutlineTransaction />,
    FaQuestion: <FaQuestion />,
    MdNotificationsActive: <MdNotificationsActive />,
    MdContactPhone: <MdContactPhone />,
    BsCash: <BsCash />,
    AiOutlineEnvironment: <AiOutlineEnvironment />,
    MdOutlineAttachEmail: <MdOutlineAttachEmail />,
    MdAlternateEmail: <MdAlternateEmail />,
    HiPrinter: <HiPrinter />
  };

  const iconMaker = (icon) => {
    if (icon in iconComponents) {
      return iconComponents[icon];
    } else {
      return VscActivateBreakpoints;
    }
  };

  const [sidebar, setSidebar] = useState([]);
  const [hoverPrimaryColor, setHoverPrimaryColor] = useState(false);
  const [hoverPathName, setHoverPathName] = useState("");

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const getUserSidebar = () => {
    const userRole = JSON.parse(sessionStorage.getItem("userTenant")).role_name;
    // console.log(userRole);
    let userRolePermissions = [];
    const allPermissions = sessionStorage.getItem("permissions")
      ? JSON.parse(sessionStorage.getItem("permissions"))
      : [];

    const roles = sessionStorage.getItem("roles")
      ? JSON.parse(sessionStorage.getItem("roles"))
      : [];

    for (let index = 0; index < roles.length; index++) {
      if (roles[index].name === userRole) {
        userRolePermissions = roles[index].permission;
      }
    }
    const finalArr = [];

    for (let index = 0; index < allPermissions.length; index++) {
      if (userRole.toLowerCase() === "admin") {
        finalArr.push(allPermissions[index]);
      } else if (userRolePermissions.includes(allPermissions[index].id)) {
        finalArr.push(allPermissions[index]);
      }
    }

    return finalArr;
  };

  const [
    getAllUserPermissions,
    {
      data: getAllUserPermissionsData,
      isLoading: getAllUserPermissionsIsLoading,
      isError: getAllUserPermissionsIsError,
      error: getAllUserPermissionsError,
    },
  ] = useGetAllUserPermissionsMutation();

  const [
    getAllRoles,
    {
      data: getAllRolesData,
      isLoading: getAllRolesIsLoading,
      isError: getAllRolesIsError,
      error: getAllRolesError,
    },
  ] = useGetAllRolesMutation();

  useEffect(() => {
    getAllRoles({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    getAllUserPermissions({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (getAllRolesData) {
      sessionStorage.setItem("roles", JSON.stringify(getAllRolesData.body));
    }
    if (getAllRolesError) {
      // console.log("All roles error", getAllRolesError);
    }
    if (getAllUserPermissionsData) {
      sessionStorage.setItem(
        "permissions",
        JSON.stringify(getAllUserPermissionsData.body)
      );
    }
    if (getAllUserPermissionsError) {
      // console.log("All user permission error", getAllUserPermissionsError);
    }

    if (getAllRolesData && getAllUserPermissionsData) {
      let userSidebar = getUserSidebar();
      sessionStorage.setItem("userPermission", JSON.stringify(userSidebar));

      function sortPermissions(userSidebar) {
        const sortedPermissions = [];
        const parentPermissions = userSidebar
          .filter((e) => e.type === "1")
          .sort(compare);
        const childPermissions = userSidebar
          .filter((e) => e.type === "2")
          .sort(compare);

        for (const parentPermission of parentPermissions) {
          sortedPermissions.push(parentPermission);

          const matchingChildPermissions = childPermissions.filter(
            (childPermission) =>
              childPermission.parent_module_id === parentPermission.action
          );

          sortedPermissions.push(...matchingChildPermissions);
        }

        return sortedPermissions;
      }

      function compare(a, b) {
        const orderA = parseInt(a.order_at);
        const orderB = parseInt(b.order_at);

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      }
      const sortedSidebar = sortPermissions(userSidebar);

      setSidebar(sortedSidebar);
    }
  }, [
    getAllRolesData,
    getAllRolesError,
    getAllUserPermissionsData,
    getAllUserPermissionsError,
  ]);

  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname.split("/")[1]);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const pathname = location.pathname;

    let parts = pathname.split("/");

    if (parts.length > 3) {
      let result = parts.slice(3).join("/");
      // console.log("to scroll ", result);

      setTimeout(() => {
        // console.log("my_element", my_element);
        var my_element = document.getElementById(`/${result}`);
        my_element &&
          my_element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
      }, 500);
    }
    setPathname(pathname);
  }, [location.pathname]);

  return (
    vendorData &&
    isOpen && (
      <div
        style={{ backgroundColor: `${primaryColor["700"]}` }}
        className="sidebar mediarem max-h-screen top-0 h-screen bg-blue-700 text-blue-100 w-56 sticky inset-y-0 left-0 transform transition duration-200 ease-in-out z-50 "
      >
        <header
          style={{ backgroundColor: `${primaryColor["700"]}` }}
          className=" h-[56px] py-2 shadow-lg px-4 md:sticky top-0 bg-white z-40"
        >
          <a
            href=" "
            className="text-white flex items-center space-x-2 group hover:text-white"
          >
            <div className="logobx">
              <img src={logo ? logo : adminLogo} alt="company Logo" />
            </div>
          </a>
        </header>
        <nav className="px-2 whitespace-nowrap scrl overflow-auto  pb-4 max-h-[calc(100vh-56px)]">
          <ul className="flex flex-col space-y-2">
            {
              sidebar.length > 0 ?
              sidebar.map((item, key) => {
                if (item.type === "1") {
                  return (
                    <div
                      style={{ borderColor: `${primaryColor["100"]}` }}
                      className="section border-b pt-4 text-sm text-white border-blue-100 pb-1 pl-3 "
                      key={key}
                    >
                      {item.name}
                    </div>
                  );
                } else if (item.type === "2") {
                  return (
                    <li className="text-xs text-white " id={item.link} key={key}>
                      <Link
                        to={`/genefied/${vendorData.tenant_name}${item.link}`}
                        href=" "
                        style={
                          hoverPrimaryColor &&
                            `/genefied/${vendorData.tenant_name}${item.link}` ===
                            hoverPathName
                            ? { backgroundColor: `${primaryColor["600"]}` }
                            : `/genefied/${vendorData.tenant_name}${item.link}` ===
                              pathname
                              ? { backgroundColor: `${primaryColor["600"]}` }
                              : {}
                        }
                        onMouseEnter={(e) => {
                          setHoverPathName(
                            "/" + e?.target?.href?.split("/").slice(3).join("/")
                          );
                          setHoverPrimaryColor(true);
                        }}
                        onMouseLeave={() => {
                          setHoverPrimaryColor(false);
                        }}
                        className={
                          `/genefied/${vendorData.tenant_name}${item.link}` ===
                            pathname
                            ? "flex items-center w-full py-2 px-2 rounded relative hover:text-white  text-white"
                            : "flex items-center w-full py-2 px-2 rounded relative hover:text-white "
                        }
                      >
                        <div className="pr-2 text-lg">{iconMaker(item.icon)}</div>
                        <div className="capitalize">{item.name}</div>
                      </Link>
                    </li>
                  );
                }
              })
                : 
              <div class=" " style={{ backgroundColor: `${primaryColor["700"]}` }}>
                {/* <li className="text-xs text-white" >
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}` , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }} ></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                  <div class=" my-1.5  flex items-center w-full py-2 px-1 rounded-sm relative animate-pulse" style={{ backgroundColor: `${primaryColor["500"]}`  , height : "38px" }}></div>
                </li> */}
              </div>

            }
          </ul>
        </nav>
      </div>
    )
  );
};

export default Sidebar;
