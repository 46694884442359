import { Backdrop, Box, Fade, Modal } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import WorkflowWizard from "./WorkflowWizard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "20%",
  minHeight: "20%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AllWorkflow = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [viewProgramsPopup, setViewProgramsPopup] = useState(false);
  const handleProgramPopupOpen = () => setViewProgramsPopup(true);
  const handleProgramPopuClose = () => setViewProgramsPopup(false);
  const [popupData, setPopupData] = useState(null);

  const [refetch, setRefetch] = useState(0);

  const [tableDataItems, setTableDataItems] = useState([]);
  useEffect(() => {
    toast.remove();

    const toastid = toast.loading("Fetching all workflows...", {
      id: "workflowData",
    });

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/workflow`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          toast.success("Data Fetched Successfully", {
            id: toastid,
          });
          setTableDataItems(response.data.body);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: toastid,
          },
        );
      });
  }, [refetch]);

  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleButtonDeleteClick(r);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      },
    );
    return;
  };

  const handleButtonDeleteClick = (r) => {
    console.log(r);
    // if (window.confirm("Are you sure you want to delete ", r.id)) {
    let tbc = [...tableDataItems];

    tbc = tbc.filter((data) => data.workflow_id != r.workflow_id);

    setTableDataItems(tbc);
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/admin/workflow/${r.workflow_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        toast.dismiss();
      })
      .catch(function (error) {
        console.log(error);
      });
    // } else {
    //   return;
    // }
  };

  const handleChange = useCallback((state) => {
    // setSelectedRows(state.selectedRows);
  }, []);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Company Name",
      selector: (row) => row.tenant_name,
      sortable: true,
    },
    {
      name: "Industry Name",
      selector: (row) => row.industry_name,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type.toUpperCase(),
      sortable: true,
    },
    {
      name: "Created at",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setPopupData(r);
            handleProgramPopupOpen();
            //
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [filterText, setFilterText] = useState();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //   const filteredItems = tableDataItems.filter(
  //     (item) =>
  //       item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  //   );

  //   console.log( "saflkjasklfjasflkjaslkfjalks" ,filteredItems);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <Toaster />
      <div>
        {open && (
          <Modal keepMounted open={open} onClose={handleClose}>
            <Box style={{ overflowY: "scroll" }} sx={style}>
              <div>
                <WorkflowWizard
                  handleClose={handleClose}
                  refetch={setRefetch}
                />
              </div>
            </Box>
          </Modal>
        )}
      </div>

      <div>
        {viewProgramsPopup && (
          <Modal
            keepMounted
            open={viewProgramsPopup}
            onClose={handleProgramPopuClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={viewProgramsPopup}>
              <Box sx={style2}>
                <div className="card-body">
                  <h2
                    className="card-title"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    {" "}
                    {popupData &&
                      popupData.tenant_name +
                        " " +
                        popupData.user_type.charAt(0).toUpperCase() +
                        popupData.user_type.slice(1) +
                        " Workflow"}{" "}
                  </h2>
                  <table class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col-3">S No.</th>
                        <th scope="col-3">Program</th>
                      </tr>
                    </thead>
                    {popupData && (
                      <tbody>
                        {popupData.program.map((program, idx) => (
                          <tr>
                            <th scope="row">{idx + 1}</th>
                            <td>{program}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </Box>
            </Fade>
          </Modal>
        )}
      </div>
      <div className="page-content">
        <div className="card p-4">
          <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 className="mb-3 mb-md-0 card-title">Welcome to Workflow</h4>
            </div>
            <div className="d-flex align-items-center flex-wrap text-nowrap">
              <button
                type="button"
                className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                onClick={() => handleOpen()}
              >
                Add New Workflow
              </button>
            </div>
          </div>

          <div className="row">
            <div className="table-responsive">
              <DataTable
                // title="All Workflows"
                data={tableDataItems}
                columns={columns}
                onSelectedRowsChange={handleChange}
                // customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                persistTableHead
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllWorkflow;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      className="w-"
      id="search"
      type="text"
      placeholder="Filter By Company"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button type="button" onClick={onClear}>
      X
    </button>
  </>
);
