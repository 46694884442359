import { rootApi } from "../../api/rootApi";


export const rewardifyApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        fetchRewardify: builder.query({
            query: (token) => {
                return {
                method: 'GET',
                url: '/admin/rewardify',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
            }}
        }),
        addRewardify : builder.mutation({
            query: (params) => {
                return {
                method: 'POST',
                url: '/admin/rewardify/add',
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + params.token,
                },
                body : JSON.stringify(params.body)
            }}
        }),
        
    })

});
export const { useFetchRewardifyQuery , useAddRewardifyMutation } = rewardifyApi;