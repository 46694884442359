import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import nodata from "../../../assests/tenant/images/nodata.jpg";
import {
  useGetUserProfileMutation,
  useManualRewardingMutation,
} from "../../../features/tenant/ManualRewarding/ManualRewardingApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import toast from "react-hot-toast";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const rewardingThrough = [
  {
    id: 1,
    name: "unique_code",
  },
  {
    id: 2,
    name: "batch_running_code",
  },
];

const ManualRewarding = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
  .color_shades;
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(null);
  const [mobileDetails, setMobileDetails] = useState(null);
  const [value, setValue] = useState(null);
  const [selectRewardingThrough, setSelectRewardingThrough] = useState({
    id: 0,
    name: "Select Rewarding Type",
  });

  console.log("mobileDetails", mobileDetails);

  const [
    getUserProfile,
    {
      data: getUserProfileData,
      error: getUserProfileError,
      isLoading: getUserProfileisLoading,
    },
  ] = useGetUserProfileMutation();

  const [
    ManualRewarding,
    {
      data: ManualRewardingData,
      error: ManualRewardingError,
      isLoading: ManualRewardingisLoading,
    },
  ] = useManualRewardingMutation();

  const handleGetMobileDetails = (e) => {
    getUserProfile({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      mobile: mobile,
    });
  };
  const handleManualRewarding = (e) => {
    if (!value) {
      toast.dismiss();
      toast.error("value is not entered ");
      return;
    }
    const body = {
      mobile: mobile,
      type: selectRewardingThrough.name,
      value: value,
    };

    ManualRewarding({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: body,
    });
  };

  useEffect(() => {
    if (getUserProfileData) {
      console.log("getUserProfileData", getUserProfileData);
      setMobileDetails(getUserProfileData.body);
    }
  }, [getUserProfileData]);

  useEffect(() => {
    if (ManualRewardingData) {
      toast.dismiss();
      toast.success(ManualRewardingData.message);
    }
    if (ManualRewardingError) {
      toast.dismiss();
      console.log("error", ManualRewardingError);
      toast.error(ManualRewardingError.data.message);
    }
  }, [ManualRewardingData, ManualRewardingError]);

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Manual Rewarding
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className=" bg-white shadow rounded-lg p-3">
            <div className="w-full">
              <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                Mobile
              </label>
              <div className="flex justify-between gap-3 mb-3">
                <div className="w-full ">
                  <div className="relative searchin">
                    <input
                      type="number"
                      id="default-search"
                      className="w-full px-2 py-2 h-auto text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile"
                      required
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="w-full ">
                <div className="relative searchin">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full px-2 py-2 h-auto text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder="Batch Running Code..."
                    required
                    value={request.batch_running_code}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          batch_running_code: newValue,
                        };
                      });
                    }}
                  />
                </div>
              </div> */}
                <button
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleGetMobileDetails}
                >
                  Submit
                </button>
              </div>
            </div>
            {getUserProfileData &&
            getUserProfileData.body &&
            Object.keys(getUserProfileData.body).length > 0 ? (
              <div className="mt-3 row">
                <div className="col-sm-6">
                  <div className="flex gap-3  mb-3">
                    <div className="w-100">
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tr className="text-sm border border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            App User ID
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.id}
                          </td>
                        </tr>

                        <tr className="text-sm border border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Name
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.name}
                          </td>
                        </tr>

                        <tr className="text-sm border border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            User Type
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.user_type}
                          </td>
                        </tr>

                        <tr className="text-smborder border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Mobile
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.mobile}
                          </td>
                        </tr>

                        <tr className="text-smborder border-gray-200 dark:border-gray-700">
                          <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Gender
                          </th>
                          <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                            {mobileDetails && mobileDetails.gender}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="bg-gray-50 p-2 rounded border border-gray-200">
                    {selectRewardingThrough != 0 ? (
                      <div className="w-full ">
                        <div className="d-flex gap-2">
                          <div className="manualr_50">
                            <SelectComponent
                              className="capitalize "
                              //title={"User Types"}
                              selected={selectRewardingThrough}
                              setSelected={setSelectRewardingThrough}
                              dataArr={rewardingThrough}
                            />
                          </div>

                          <div className="relative searchin manualr_50">
                            <input
                              type="text"
                              id="default-search"
                              className="w-full px-2 py-2 h-auto text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                              placeholder="Value"
                              required
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="btn_ma">
                          <button
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={handleManualRewarding}
                          >
                            Manual Rewarding
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {ManualRewardingData && ManualRewardingData.body && (
                      <div
                        className="rewardbox_s"
                        onClick={handleManualRewarding}
                      >
                        <h3>
                          Total Points&nbsp;:&nbsp;{" "}
                          {ManualRewardingData &&
                            ManualRewardingData.body.total_points}
                        </h3>

                        <div className="listreward">
                          <span>
                            Product Name&nbsp;:&nbsp;{" "}
                            {ManualRewardingData &&
                              ManualRewardingData?.body?.body[0]
                                ?.points_on_product?.product_name}
                          </span>
                          <span>
                            Product Code&nbsp;:&nbsp;{" "}
                            {ManualRewardingData &&
                              ManualRewardingData?.body?.body[0]
                                ?.points_on_product?.product_code}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <span className="block text-center  text-lg font-bold text-gray-300">
                <img
                  src={nodata}
                  alt=""
                  className="m-auto max-w-full max-h-full"
                />
                No Data Available
              </span>
            )}
          </div>
        </div>
        {true && (
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                <div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

export default ManualRewarding;
