import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Tenant/Navbar";
import Sidebar from "../../../components/Tenant/Sidebar";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import NotFound from "../../../components/NotFound";
import "../tenant.css";
import Footer from "../../../components/Tenant/Footer";
import Temp from "../Temp/Temp";
import Profile from "../Profile/Profile";
import ProductList from "../Product/ProductList";
import AddProduct from "../Product/AddProduct";
import QrGeneration from "../QrCode/QrGeneration";
import EditProduct from "../Product/EditProduct";
import QrPrint from "../QrCode/QrPrint";
import ProductHeirarchy from "../Product/ProductHeirarchy";
import BatchList from "../Batch/BatchList";
import AddBatch from "../Batch/AddBatch";
import QrByBatch from "../QrCode/QrByBatch";
import GiftList from "../Gift/GiftList";
import AddGift from "../Gift/AddGift";
import GiftCatalogueList from "../GiftCatalogue/GiftCatalogueList";
import GiftCatalogue from "../GiftCatalogue/GiftCatalogue";
import AddGiftCatalogue from "../GiftCatalogue/AddGiftCatalogue";
import UserList from "../UserList/UserList";
import UserScanHistory from "../UserScanHistory/UserScanHistory";
import ProductPoints from "../Rewardify/ProductPoints/ProductPoints";
import AddProductPoints from "../Rewardify/ProductPoints/AddProductPoints";
import PointsHistory from "../Rewardify/PointsHistory/PointsHistory";
import RedeemRequest from "../RedeemRequest/RedeemRequest";
import IssueReport from "../IssueReport/IssueReport";
import Video from "../MediaCommunication/Video/Video";
import Gallery from "../MediaCommunication/Gallery/Gallery";
import BannerList from "../MediaCommunication/Banner/BannerList";
import UserProfile from "../UserProfile/UserProfile";
import BrandList from "../Brands/BrandList";
import ECatalogue from "../MediaCommunication/E_Catalogue/ECatalogue";
import AddECatalogue from "../MediaCommunication/E_Catalogue/AddECatalogue";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import WebPanelUsers from "../WebPanelUsers/WebPanelUsers";
import AddWebPanelUser from "../WebPanelUsers/AddWebPanelUser";
import WebPanelRoles from "../WebPanelRoles/WebPanelRoles";
import EditWebPanelRolePermission from "../WebPanelRoles/EditWebPanelRolePermission";
import Wheel from "../Wheel/Wheel";
import WheelCampaign from "../Wheel/WheelCampaign";
import ManageCouponProvider from "../Rewardify/CouponMaster/ManageCouponProvider";
import StaticCouponsList from "../Rewardify/CouponMaster/StaticCouponsList";
import AddStaticCoupon from "../Rewardify/CouponMaster/AddStaticCoupon";
import CouponByBrand from "../Rewardify/CouponMaster/CouponByBrand";
import RedeemedCouponList from "../Rewardify/CouponMaster/RedeemedCouponList";
import DynamicCoupon from "../Rewardify/CouponMaster/DynamicCoupon";
import AddDynamicCoupon from "../Rewardify/CouponMaster/AddDynamicCoupon";

import PayementGateway from "../PaymentGateway/PayementGateway";
import AddPaymentGateway from "../PaymentGateway/AddPaymentGateway";
import WheelCampaignList from "../Wheel/WheelCampaignList";
import AppUserType from "../AppUserType/AppUserType";
import WheelSummary from "../Wheel/WheelSummary";
import WarrantyList from "../Warranty/WarrantyList";
import WarrantyOnProducts from "../WarrantyOnProducts/WarrantyOnProducts";
import AddWarrantyOnProducts from "../WarrantyOnProducts/AddWarrantyOnProducts";
import GalleryList from "../MediaCommunication/Gallery/GalleryList";
import VideoList from "../MediaCommunication/Video/VideoList";
import CreateSalesBooster from "../SalesBooster/CreateSalesBooster";
import SalesBoosterList from "../SalesBooster/SalesBoosterList";
import Campaign from "../Campaign/Campaign";
import AddCampaign from "../Campaign/AddCampaign";
import ViewCampaign from "../Campaign/ViewCampaign";
import ReferAndEarnRewards from "../ReferAndEarnRewards/ReferAndEarnRewards";
import AddReferralAndEarnRewards from "../ReferAndEarnRewards/AddReferralAndEarnRewards";
import LeaderBoard from "../LeaderBoard/LeaderBoard";
import ManageSalesBooster from "../SalesBooster/ManageSalesBooster";
import SMSCredential from "../SMSMaster/SMSCredential";
import AddSMSCredential from "../SMSMaster/AddSMSCredential";
import SMSTemplates from "../SMSMaster/SMSTemplates";
import AddSMSTemplates from "../SMSMaster/AddSMSTemplates";
import EditProfile from "../Profile/EditProfile";
import WarrantyClaimList from "../Warranty/WarrantyClaimList";
import EmailCredentials from "../EmailMaster/EmailCredentials";
import ManageEmailCredentials from "../EmailMaster/ManageEmailCredential";
import EmailTemplates from "../EmailMaster/EmailTemplates";
import ManageEmailTemplate from "../EmailMaster/ManageEmailTemplate";
import ErrorLogs from "../ErrorLogs/ErrorLogs";
import SchemeList from "../Scheme/SchemeList";
import ManageScheme from "../Scheme/ManageScheme";
import AddMembership from "../Membership/AddMembership";
import ManageMembership from "../Membership/ManageMembership";
import MembershipList from "../Membership/MembershipList";
import DreamGiftsRedeemRequest from "../dreamGifts/DreamGiftsRedeemRequest";
import AddScaratchableScheme from "../Scratchable Coupon Scheme/AddScaratchableScheme";
import ScratchableRewards from "../Scratchable Coupon Scheme/ScratchableRewards";
import ScratchableRewardList from "../Scratchable Coupon Scheme/ScractableRewardList";
import ScratchableSchemeList from "../Scratchable Coupon Scheme/ScratchableSchemeList";
import PurchaseOrderList from "../PurchaseOrder/PurchaseOrderList";
import ManagePurchaseOrder from "../PurchaseOrder/ManagePurchaseOrder";
import UpdatePurchaseOrder from "../PurchaseOrder/UpdatePurchaseOrder";
import SchemePurchaseOrders from "../Scratchable Coupon Scheme/SchemePurchaseOrders";
import AddRewardScheme from "../RewardScheme/AddRewardScheme";
import LuckyDrawSetup from "../RewardScheme/LuckyDrawSetup";
import AllRewardSchemeList from "../RewardScheme/AllRewardSchemeList";
import SchemeOrderUpload from "../RewardScheme/SchemeOrderUpload";
import Zones from "../Zones/Zones";
import AddZones from "../Zones/AddZones";
import ViewLocations from "../Zones/ViewLocations";
import AppOtps from "../AppOtps/AppOtps";
import ErrorBoundry from "../../../components/ErrorBoundry/ErrorBoundry";
import LegalList from "../Legal/LegalList";
import AddLegal from "../Legal/AddLegal";
import Mediaist from "../MediaCommunication/Media/MediaList";
import Tracking from "../Tracking/Tracking";
import AppBan from "../AppBan/AppBan";
import SupportQueries from "../SupportQueries/SupportQueries";
import BankList from "../BankList/BankList";
import TDS from "../TDS/TDS";
import Notification from "../Notification/Notification";
import QrBatchImport from "../Ozone/QrBatchImport";
import CashTransactionList from "../CashTransactions/CashTransactionList";
import PaymentTransferList from "../PaymentTransfer/PaymentTransferList";
import PaymentTransferListByBatchId from "../PaymentTransfer/PaymentTransferListByBatchId";
import UploadPaymentTransfer from "../PaymentTransfer/UploadPaymentTransfer";
import Faq from "../Faqs/Faq";
import AddFaq from "../Faqs/AddFaq";
import ProductInstallationVideo from "../ProductInstallationVideo/ProductInstallationVideo";
import WhatsappNotificationCredentials from "../WhatsappNotification/WhatsappNotificationCredentials";
import AddWhatsappNotificationCredential from "../WhatsappNotification/AddWhatsappNotificationCredential";
import WhatsappNotificationTemplates from "../WhatsappNotification/WhatsappNotificationTemplates";
import AddWhatappNotificationTemplates from "../WhatsappNotification/AddWhatappNotificationTemplates";
import PushNotificationsTypeList from "../MediaCommunication/PushNotifications/PushNotifcationsTypeList";
import AddPushNotificationsType from "../MediaCommunication/PushNotifications/AddPushNotificationsType";
import LoggingWhatsappAndSMS from "../LoggingWhatsappAndSMS/LoggingWhatsappAndSMS";
import QrBulkUpload from "../QrCode/QrBulkUpload";
import PushNotificationsDumpList from "../MediaCommunication/PushNotifications/PushNotificationDumpList";
import BookAppointment from "../BookAppointment/BookAppointment";
import QrMapping from "../QrCode/QrMapping";
import CashTransferRequests from "../CashTransferRequests/CashTransferRequests";
import AllQrCodeList from "../QrCode/AllQrCodeList";
import ExtraPointHistory from "../Rewardify/PointsHistory/ExtraPointHistory";
import MembershipHistory from "../Membership/MembershipHIstory";
import PrintersList from "../Printer/AllPrinters";
import AddPrinter from "../Printer/AddPrinter";
import CouponRequests from "../Rewardify/CouponMaster/couponRequest";
import OzoneDynamicCouponList from "../Ozone/OzoneDynamicCouponList";
import DynamicCouponRequestList from "../Ozone/DynamicCouponRequestList";
import UploadOrders from "../Tropolite/UploadOrders";
import AllOrderList from "../Tropolite/AllOrderList";
import AppFeedback from "../AppFeedBack/AppFeedBack";
import NotInitiatedPayment from "../NotInitiatePayment/NotInitiatedPayment";
import ActiveUsers from "../ActiveUsers/ActiveUsers";
import PointsHistoryRetailers from "../Rewardify/PointsHistory/PointsHistoryRetailers";
import UploadPaymentTarget from "../PaymentTarget/UploadPaymentTarget";
import PaymentTargetListByBatchId from "../PaymentTarget/PaymentTargetListByBatchId";
import PaymentTargetList from "../PaymentTarget/PaymentTargetList";
import UserRolesSupplyBean from "../SupplyBeam/UserRolesSupplyBeam/UserRolesSupplyBean";
import EditUserRolesPermissionSupplyBeam from "../SupplyBeam/UserRolesSupplyBeam/EditUserRolesPermissionSupplyBeam";
import SupplyBeamUsers from "../SupplyBeam/ManageSupplyBeamUsers/SupplyBeamUsers";
import AddSupplyUsers from "../SupplyBeam/ManageSupplyBeamUsers/AddSupplyUsers";
import AddSiteLocations from "../SupplyBeam/SiteLocationsSupplyBeam/AddSiteLocations";
import SiteLocation from "../SupplyBeam/SiteLocationsSupplyBeam/SiteLocation";
import SearchByBatchRunningCode from "../QrCode/SearchByBatchRunningCode";
import VerificationRecords from "../VerificationRecords/VerificationRecords";
import AppVersion from "../AppVersion/AppVersion";
import AllUsersList from "../AllUsersList/AllUsersList";
import UnEnteredProducts from "../Ozone/UnAddedProductList";
import ManualRewarding from "../ManualRewarding/ManualRewarding";
import SendBulkMessages from "../SendBulkMessages/SendBulkMessages";
import RequestProjectQuotation from "../Ultimatrue/RequestProjectQuotation";
import Home from "../Home/Home";
import BulkManualRewarding from "../ManualRewarding/BulkManualRewarding";
import UserMapping from "../Mapping/UserMapping";
import FirebaseTopicsList from "../FirebaseTopics/FirebaseTopicsList";
import CustomNotifications from "../CustomNotifications/CustomNotifications";
import AddCustomNotifications from "../CustomNotifications/AddCustomNotifications";
import AccountTransfer from "../AccountTransfer/AccountTransfer";
import AccountTransferList from "../AccountTransfer/AccountTransferList";
import QrDistributorMapping from "../JqrSports/QrDistributorMapping";
import SchemeDetailsList from "../Scheme/SchemeDetails";
import CgGenerateQr from "../ClientSpecific/CgGenerateQr";
import PointHistoryJqr from "../JqrSports/PointHistoryJqr";
import UltimatrueUserList from "../Ultimatrue/UltimatrueUserList";

const TenantHome = () => {
  const [
    getAppUserTypes,
    { data: getAppUserTypesData, error: getAppUserTypesError },
  ] = useGetAppUserTypesMutation();

  useEffect(() => {
    const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
    if (!appUserTypes) {
      getAppUserTypes({
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  }, []);

  useEffect(() => {
    if (getAppUserTypesData) {
      sessionStorage.setItem(
        "appUserType",
        JSON.stringify(getAppUserTypesData.body)
      );
    }
  }, [getAppUserTypesData]);

  const [isOpen, setIsopen] = useState(true);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  return (
    <div className="flex">
      <Sidebar isOpen={isOpen} />
      <div className="flex flex-col flex-1 withrgt ">
        <Navbar togglesidebar={ToggleSidebar} />
        <Routes>
          <Route
            exact
            path=""
            element={
              <ErrorBoundry>
                <Home />
              </ErrorBoundry>
            }
          />
          <Route

            path="/dashboard"
            element={
              <ErrorBoundry>
                <Dashboard />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <ErrorBoundry>
                <Profile />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/profile/edit"
            element={
              <ErrorBoundry>
                <EditProfile />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/product/heirarchy"
            element={
              <ErrorBoundry>
                <ProductHeirarchy />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/active-users"
            element={
              <ErrorBoundry>
                <ActiveUsers />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/product"
            element={
              <ErrorBoundry>
                <ProductList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/product/add"
            element={
              <ErrorBoundry>
                <AddProduct />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/product/edit/:product_id"
            element={
              <ErrorBoundry>
                <EditProduct />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/product-points"
            element={
              <ErrorBoundry>
                <ProductPoints />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/product-points/add"
            element={
              <ErrorBoundry>
                <AddProductPoints />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/batch"
            element={
              <ErrorBoundry>
                <BatchList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/batch/add"
            element={
              <ErrorBoundry>
                <AddBatch />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/generateQr"
            element={
              <ErrorBoundry>
                <QrGeneration />
              </ErrorBoundry>
            }
          />

<Route
            exact
            path="/cgqr"
            element={
              <ErrorBoundry>
                <CgGenerateQr />
              </ErrorBoundry>
            }
          />
          

          <Route
            exact
            path="/upload-qr"
            element={
              <ErrorBoundry>
                <QrBulkUpload />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/printQr"
            element={
              <ErrorBoundry>
                <QrPrint />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/printQrList"
            element={
              <ErrorBoundry>
                <AllQrCodeList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/not-initiated-payouts"
            element={
              <ErrorBoundry>
                <NotInitiatedPayment />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/printQr/batch"
            element={
              <ErrorBoundry>
                <QrByBatch />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/printQr/mapping"
            element={
              <ErrorBoundry>
                <QrMapping />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/gift"
            element={
              <ErrorBoundry>
                <GiftList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/gift/add"
            element={
              <ErrorBoundry>
                <AddGift />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/coupon-provider"
            element={
              <ErrorBoundry>
                <ManageCouponProvider />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/static-coupon"
            element={
              <ErrorBoundry>
                <StaticCouponsList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/static-coupon/add"
            element={
              <ErrorBoundry>
                <AddStaticCoupon />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/static-coupon/:brandName"
            element={
              <ErrorBoundry>
                <CouponByBrand />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/redeemed-coupon"
            element={
              <ErrorBoundry>
                <RedeemedCouponList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/dynamic-coupon"
            element={
              <ErrorBoundry>
                <DynamicCoupon />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/dynamic-coupon/add"
            element={
              <ErrorBoundry>
                <AddDynamicCoupon />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/gift-catalogue"
            element={
              <ErrorBoundry>
                <GiftCatalogueList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/gift-catalogue/catalogue"
            element={
              <ErrorBoundry>
                <GiftCatalogue />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/gift-catalogue/add"
            element={
              <ErrorBoundry>
                <AddGiftCatalogue />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/points-history"
            element={
              <ErrorBoundry>
                <PointsHistory />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/extra-points-history"
            element={
              <ErrorBoundry>
                <ExtraPointHistory />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/redemption-history"
            element={
              // <ErrorBoundry>
              <RedeemRequest />
              // </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/dream-gifts-redemption-history"
            element={
              <ErrorBoundry>
                <DreamGiftsRedeemRequest />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/reports"
            element={
              <ErrorBoundry>
                <IssueReport />
              </ErrorBoundry>
            }
          />
          {/* <Route  exact path="/banner" element={<Banner />} /> */}
          <Route
            exact
            path="/bannerlist"
            element={
              <ErrorBoundry>
                <BannerList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/video"
            element={
              <ErrorBoundry>
                <VideoList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/video/add"
            element={
              <ErrorBoundry>
                <Video />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/gallery"
            element={
              <ErrorBoundry>
                <GalleryList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/media"
            element={
              <ErrorBoundry>
                <Mediaist />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/gallery/add"
            element={
              <ErrorBoundry>
                <Gallery />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/campaign"
            element={
              <ErrorBoundry>
                <Campaign />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/campaign/view"
            element={
              <ErrorBoundry>
                <ViewCampaign />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/campaign/add"
            element={
              <ErrorBoundry>
                <AddCampaign />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/user/:userType"
            element={
              <ErrorBoundry>
                <UserList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/user/:userType/:userName"
            element={
              <ErrorBoundry>
                <UserProfile />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/scanHistory/:userType"
            element={
              <ErrorBoundry>
                <UserScanHistory />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/brand"
            element={
              <ErrorBoundry>
                <BrandList />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/web-panelUser"
            element={
              <ErrorBoundry>
                <WebPanelUsers />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/web-panelUser/add"
            element={
              <ErrorBoundry>
                <AddWebPanelUser />
              </ErrorBoundry>
            }
          />
          {/* WebPanelUsers */}
          <Route
            exact
            path="/web-panelRole"
            element={
              <ErrorBoundry>
                <WebPanelRoles />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/web-panelRole/add"
            element={
              <ErrorBoundry>
                <EditWebPanelRolePermission />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/e-catalogue"
            element={
              <ErrorBoundry>
                <ECatalogue />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/wheel"
            element={
              <ErrorBoundry>
                <Wheel />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/wheelCampaign"
            element={
              <ErrorBoundry>
                <WheelCampaignList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/wheelCampaign/add"
            element={
              <ErrorBoundry>
                <WheelCampaign />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/wheel/summary"
            element={
              <ErrorBoundry>
                <WheelSummary />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/e-catalogue/add"
            element={
              <ErrorBoundry>
                <AddECatalogue />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/paymentGateway"
            element={
              <ErrorBoundry>
                <PayementGateway />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/paymentGateway/add"
            element={
              <ErrorBoundry>
                <AddPaymentGateway />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/cashPerPoint"
            element={
              <ErrorBoundry>
                <AppUserType />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/warranty"
            element={
              <ErrorBoundry>
                <WarrantyList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/warranty-claim"
            element={
              <ErrorBoundry>
                <WarrantyClaimList />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/warranty-on-products"
            element={
              <ErrorBoundry>
                <WarrantyOnProducts />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/warranty-on-products/add"
            element={
              <ErrorBoundry>
                <AddWarrantyOnProducts />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/sales-booster"
            element={
              <ErrorBoundry>
                <SalesBoosterList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/sales-booster/add"
            element={
              <ErrorBoundry>
                <CreateSalesBooster />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/sales-booster/manage"
            element={
              <ErrorBoundry>
                <ManageSalesBooster />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/refer-and-earn-rewards"
            element={
              <ErrorBoundry>
                <ReferAndEarnRewards />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/refer-and-earn-rewards/add"
            element={
              <ErrorBoundry>
                <AddReferralAndEarnRewards />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/leaderboard"
            element={
              <ErrorBoundry>
                <LeaderBoard />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/sms-credential"
            element={
              <ErrorBoundry>
                <SMSCredential />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/sms-credential/add"
            element={
              <ErrorBoundry>
                <AddSMSCredential />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/sms-credential/sms-templates"
            element={
              <ErrorBoundry>
                <SMSTemplates />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/sms-credential/sms-templates/add"
            element={
              <ErrorBoundry>
                <AddSMSTemplates />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/email-credential"
            element={
              <ErrorBoundry>
                <EmailCredentials />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/email-credential/manage"
            element={
              <ErrorBoundry>
                <ManageEmailCredentials />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/email-templates"
            element={
              <ErrorBoundry>
                <EmailTemplates />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/email-templates/manage"
            element={
              <ErrorBoundry>
                <ManageEmailTemplate />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/error-logs"
            element={
              <ErrorBoundry>
                <ErrorLogs />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/whatsapp-notifications"
            element={
              <ErrorBoundry>
                <WhatsappNotificationCredentials />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/whatsapp-notifications/add"
            element={
              <ErrorBoundry>
                <AddWhatsappNotificationCredential />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/whatsapp-notifications/templates"
            element={
              <ErrorBoundry>
                <WhatsappNotificationTemplates />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/whatsapp-notifications/templates/add"
            element={
              <ErrorBoundry>
                <AddWhatappNotificationTemplates />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/scheme"
            element={
              <ErrorBoundry>
                <SchemeList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/scheme/manage"
            element={
              <ErrorBoundry>
                <ManageScheme />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/scheme/details/:schemeId"
            element={
              <ErrorBoundry>
                <SchemeDetailsList />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/membership"
            element={
              <ErrorBoundry>
                <MembershipList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/membership/add"
            element={
              <ErrorBoundry>
                <AddMembership />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/membership/history"
            element={
              <ErrorBoundry>
                <MembershipHistory />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/membership/manage"
            element={
              <ErrorBoundry>
                <ManageMembership />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/zones"
            element={
              <ErrorBoundry>
                <Zones />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/zones/add"
            element={
              <ErrorBoundry>
                <AddZones />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/zones/view-locations"
            element={
              <ErrorBoundry>
                <ViewLocations />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/otp"
            element={
              <ErrorBoundry>
                <AppOtps />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/scratchable-coupon"
            element={
              <ErrorBoundry>
                <ScratchableSchemeList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/scratchable-coupon/add"
            element={
              <ErrorBoundry>
                <AddScaratchableScheme />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/scratchable-coupon/rewards"
            element={
              <ErrorBoundry>
                <ScratchableRewardList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/scratchable-coupon/rewards/add"
            element={
              <ErrorBoundry>
                <ScratchableRewards />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/scratchable-coupon/orders"
            element={
              <ErrorBoundry>
                <SchemePurchaseOrders />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/punch-order"
            element={
              <ErrorBoundry>
                <PurchaseOrderList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/punch-order/add"
            element={
              <ErrorBoundry>
                <ManagePurchaseOrder />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/punch-order/bill"
            element={
              <ErrorBoundry>
                <UpdatePurchaseOrder />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/reward-scheme"
            element={
              <ErrorBoundry>
                <AllRewardSchemeList />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/reward-scheme/add"
            element={
              <ErrorBoundry>
                <AddRewardScheme />
              </ErrorBoundry>
            }
          />
          {/* <Route exact path="/reward-scheme/luckydraw" element={ <ErrorBoundry> <LuckyDrawSetup /> </ErrorBoundry>} /> */}

          <Route
            exact
            path="/scheme-order/upload"
            element={
              <ErrorBoundry>
                <SchemeOrderUpload />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/temp"
            element={
              <ErrorBoundry>
                <Temp />
              </ErrorBoundry>
            }
          />

          <Route
            exact
            path="/Logging"
            element={
              <ErrorBoundry>
                <LoggingWhatsappAndSMS />
              </ErrorBoundry>
            }
          />
          <Route
            path="*"
            element={
              <ErrorBoundry>
                <NotFound />
              </ErrorBoundry>
            }
          />
          <Route
            exact
            path="/scheme-order/upload"
            element={<SchemeOrderUpload />}
          />
          <Route exact path="/legal" element={<LegalList />} />
          <Route exact path="/legal/add" element={<AddLegal />} />

          <Route
            path="/qr-import"
            element={
              <ErrorBoundry>
                <QrBatchImport />
              </ErrorBoundry>
            }
          />

          <Route
            path="/tracking"
            element={
              <ErrorBoundry>
                <Tracking />
              </ErrorBoundry>
            }
          />

          <Route
            path="/appBan"
            element={
              <ErrorBoundry>
                <AppBan />
              </ErrorBoundry>
            }
          />

          <Route
            path="/orders"
            element={
              <ErrorBoundry>
                <AllOrderList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/orders/upload"
            element={
              <ErrorBoundry>
                <UploadOrders />
              </ErrorBoundry>
            }
          />

          {/* <Route
            path="/orders/batch"
            element={
              <ErrorBoundry>
                <OrderBatchList />
              </ErrorBoundry>
            }
          /> */}

          <Route
            path="/printer"
            element={
              <ErrorBoundry>
                <PrintersList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/printer/add"
            element={
              <ErrorBoundry>
                <AddPrinter />
              </ErrorBoundry>
            }
          />

          <Route
            path="/supportQueries"
            element={
              <ErrorBoundry>
                <SupportQueries />
              </ErrorBoundry>
            }
          />

          <Route
            path="/enquiry"
            element={
              <ErrorBoundry>
                <BookAppointment />
              </ErrorBoundry>
            }
          />

          <Route
            path="/bankList"
            element={
              <ErrorBoundry>
                <BankList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/tds"
            element={
              <ErrorBoundry>
                <TDS />
              </ErrorBoundry>
            }
          />

          <Route
            path="/notification"
            element={
              <ErrorBoundry>
                <Notification />
              </ErrorBoundry>
            }
          />

          <Route
            path="/transaction-list"
            element={
              <ErrorBoundry>
                <CashTransactionList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/cashtransfer-request"
            element={
              <ErrorBoundry>
                <CashTransferRequests />
              </ErrorBoundry>
            }
          />

          <Route
            path="/coupon-request"
            element={
              <ErrorBoundry>
                <CouponRequests />
              </ErrorBoundry>
            }
          />

          {/* CouponRequests */}
          {/* CouponRequests */}
          <Route
            path="/payment-transfer"
            element={
              <ErrorBoundry>
                <PaymentTransferList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/payment-transfer/batch"
            element={
              <ErrorBoundry>
                <PaymentTransferListByBatchId />
              </ErrorBoundry>
            }
          />

          <Route
            path="/payment-transfer/add"
            element={
              <ErrorBoundry>
                <UploadPaymentTransfer />
              </ErrorBoundry>
            }
          />
          <Route
            path="/payment-target"
            element={
              <ErrorBoundry>
                <PaymentTargetList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/payment-target/batch"
            element={
              <ErrorBoundry>
                <PaymentTargetListByBatchId />
              </ErrorBoundry>
            }
          />

          <Route
            path="/payment-target/add"
            element={
              <ErrorBoundry>
                <UploadPaymentTarget />
              </ErrorBoundry>
            }
          />

          <Route
            path="/faqs"
            element={
              <ErrorBoundry>
                <Faq />
              </ErrorBoundry>
            }
          />

          <Route
            path="/faqs/add"
            element={
              <ErrorBoundry>
                <AddFaq />
              </ErrorBoundry>
            }
          />
          <Route
            path="/product-installation-videos"
            element={
              <ErrorBoundry>
                <ProductInstallationVideo />
              </ErrorBoundry>
            }
          />
          <Route
            path="/product-installation-videos/add"
            element={
              <ErrorBoundry>
                <ProductInstallationVideo />
              </ErrorBoundry>
            }
          />

          <Route
            path="/push-notification/"
            element={
              <ErrorBoundry>
                <PushNotificationsTypeList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/push-notification/list"
            element={
              <ErrorBoundry>
                <PushNotificationsDumpList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/push-notification/add"
            element={
              <ErrorBoundry>
                <AddPushNotificationsType />
              </ErrorBoundry>
            }
          />

          <Route
            path="/appFeedback"
            element={
              <ErrorBoundry>
                <AppFeedback />
              </ErrorBoundry>
            }
          />

          <Route
            path="/ozone-dynamic-coupons"
            element={
              <ErrorBoundry>
                <OzoneDynamicCouponList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/ozone-coupon-request"
            element={
              <ErrorBoundry>
                <DynamicCouponRequestList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/points-retailers"
            element={
              <ErrorBoundry>
                <PointsHistoryRetailers />
              </ErrorBoundry>
            }
          />

          <Route
            path="/supply-beam/user-roles"
            element={
              <ErrorBoundry>
                <UserRolesSupplyBean />
              </ErrorBoundry>
            }
          />
          <Route
            path="/supply-beam/user-roles/add"
            element={
              <ErrorBoundry>
                <EditUserRolesPermissionSupplyBeam />
              </ErrorBoundry>
            }
          />
          <Route
            path="/supply-beam/users"
            element={
              <ErrorBoundry>
                <SupplyBeamUsers />
              </ErrorBoundry>
            }
          />
          <Route
            path="/supply-beam/users/add"
            element={
              <ErrorBoundry>
                <AddSupplyUsers />
              </ErrorBoundry>
            }
          />

          <Route
            path="/supply-beam/site-locations/add"
            element={
              <ErrorBoundry>
                <AddSiteLocations />
              </ErrorBoundry>
            }
          />
          <Route
            path="/supply-beam/site-locations"
            element={
              <ErrorBoundry>
                <SiteLocation />
              </ErrorBoundry>
            }
          />

          <Route
            path="/batch-running-code"
            element={
              <ErrorBoundry>
                <SearchByBatchRunningCode />
              </ErrorBoundry>
            }
          />
          <Route
            path="/verification-records"
            element={
              <ErrorBoundry>
                <VerificationRecords />
              </ErrorBoundry>
            }
          />
          <Route
            path="/app-versions"
            element={
              <ErrorBoundry>
                <AppVersion />
              </ErrorBoundry>
            }
          />

          {/* SearchByBatchRunningCode */}
          <Route
            path="/allUsers"
            element={
              <ErrorBoundry>
                <AllUsersList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/product-not-added"
            element={
              <ErrorBoundry>
                <UnEnteredProducts />
              </ErrorBoundry>
            }
          />

          <Route
            path="/manual-rewarding"
            element={
              <ErrorBoundry>
                <ManualRewarding />
              </ErrorBoundry>
            }
          />
          <Route
            path="/manual-rewarding/add"
            element={
              <ErrorBoundry>
                <BulkManualRewarding />
              </ErrorBoundry>
            }
          />

          <Route
            path="/bulk-sms"
            element={
              <ErrorBoundry>
                <SendBulkMessages />
              </ErrorBoundry>
            }
          />

          <Route
            path="/request-project-quotation"
            element={
              <ErrorBoundry>
                <RequestProjectQuotation />
              </ErrorBoundry>
            }
          />

<Route
            path="/ut/:userType"
            element={
              <ErrorBoundry>
                <UltimatrueUserList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/mapping"
            element={
              <ErrorBoundry>
                <UserMapping />
              </ErrorBoundry>
            }
          />

          <Route
            path="/topics"
            element={
              <ErrorBoundry>
                < FirebaseTopicsList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/custom-notifications"
            element={
              <ErrorBoundry>
                <CustomNotifications />
              </ErrorBoundry>
            }
          />

          <Route
            path="/custom-notifications/add"
            element={
              <ErrorBoundry>
                <AddCustomNotifications />
              </ErrorBoundry>
            }
          />

          <Route
            path="/custom-notifications/add"
            element={
              <ErrorBoundry>
                <AddCustomNotifications />
              </ErrorBoundry>
            }
          />

          <Route
            path="/account-transfer/add"
            element={
              <ErrorBoundry>
                <AccountTransfer />
              </ErrorBoundry>
            }
          />
          <Route
            path="/account-transfer"
            element={
              <ErrorBoundry>
                <AccountTransferList />
              </ErrorBoundry>
            }
          />

          <Route
            path="/qr-mapping"
            element={
              <ErrorBoundry>
                <QrDistributorMapping />
              </ErrorBoundry>
            }
          />

          <Route
            path="/jqr-point-history"
            element={
              <ErrorBoundry>
                <PointHistoryJqr />
              </ErrorBoundry>
            }
          />

          {/* UnEnteredProducts */}

          {/* UserMapping */}

          <Route exact path="/temp" element={<Temp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </div>
    </div>
  );
};

export default TenantHome;
