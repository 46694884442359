import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useFetchAllGiftsMutation } from "../../../features/tenant/gift/giftApi";
import { toast } from "react-hot-toast";
import { useAddCatalogueMutation } from "../../../features/tenant/giftCatalogue/giftCatalogueApi";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";

const catalogueTypeArr = [{
  id: "1",
  name: "point",
  },
{
  id: "2",
  name: "value",
  },]

const TypeArr = [{
  id: "1",
  name: "Dream Gift",
  },
{
  id: "2",
  name: "Other",
  },{
    id: "3",
    name: "Scheme",
    },]

const AddGiftCatalogue = () => {
  const [
    fetchGifts,
    {
      data: giftsData,
      isLoading: giftsIsLoading,
      isError: giftsIsError,
      error: giftsError,
    },
  ] = useFetchAllGiftsMutation();
  const [
    getAppUserTypes,
    {
      data: appUserTypesData,
      isLoading: appUserTypesIsLoading,
      error: appUserTypesError,
    },
  ] = useGetAppUserTypesMutation();
  useEffect(() => {
    fetchGifts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: 1000,
      offset: 0,
      
    });
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const [selectedGifts, setselectedGifts] = useState([]);
  const [globalChecked, setGlobalChecked] = useState(false);

  const [selectedCatalogueType, setSelectedCatalogueType] = useState(catalogueTypeArr[0]);
  const [catalogueType, setCatalogueType] = useState(catalogueTypeArr)
  const [selectedType, setSelectedType] = useState(TypeArr[0]);
  const [types, setTypes] = useState(TypeArr)

  const [catalogueName, setCatalogueName] = useState(null);
  const [userTypes, setUserTypes] = useState();
  useEffect(() => {
    const arr = giftsData ? [...giftsData?.body?.gifts] : [];

    const newArr = arr.map((each) => ({
      ...each,
      checked: false,
    }));

    setselectedGifts(newArr);
  }, [giftsData]);

  const handleOnChange = (e, idx) => {
    const newData = [...selectedGifts];
    newData[idx].checked = !selectedGifts[idx].checked;
    setselectedGifts(newData);
    setGlobalChecked(false);
  };

  const handleGlobalSelection = (e) => {
    if (globalChecked) {
      const newData = [...selectedGifts];
      for (let i = 0; i < newData.length; i++) {
        newData[i].checked = false;
      }
      setselectedGifts(newData);
      setGlobalChecked(false);
    } else {
      const newData = [...selectedGifts];
      for (let i = 0; i < newData.length; i++) {
        newData[i].checked = true;
      }
      setselectedGifts(newData);
      setGlobalChecked(true);
    }
  };

  const [
    addCatalogue,
    {
      data: addCatalogueData,
      error: addCatalogueError,
    },
  ] = useAddCatalogueMutation();

  const handleAddCatalogue = (e) => {
    toast.dismiss();
    e.preventDefault();
    if (!catalogueName) {
      toast.error("Please enter catalogue name", {
        id: "catalogueNameError",
      });
      return;
    }
    const giftIds = [];
    for (let i = 0; i < selectedGifts.length; i++) {
      if (selectedGifts[i].checked === true) {
        giftIds.push(selectedGifts[i].id);
      }
    }
    if (giftIds.length <= 0) {
      toast.error("Please select some gifts", {
        id: "catalogueGiftError",
      });
      return;
    }

    if (!userTypes || userTypes.length <= 0) {
      toast.error("Select Users", {
        id: "userTypesError",
      });
      return;
    }

    if(!selectedCatalogueType){
      toast.error("Catalogue type not selected", {
        id: "catalogueTypeError",
      });
      return;
    }

    const userTypeIds = userTypes.map((user) => user.user_type_id);


    addCatalogue({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        catalogue_name: catalogueName,
        gift_id: giftIds,
        catalogue_type : selectedCatalogueType.name,
        type : selectedType.id,
        user_types: userTypeIds,
      },
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (addCatalogueData) {
      toast.success("Catalogue added ", {
        id: "catalogueSuccess",
      });
    setTimeout(() => {
        navigate(-1);
    }, 400);
    }

    if (addCatalogueError) {
      toast.error("Some error occured", {
        id: "catalogueError",
      });
    }
  }, [addCatalogueData, addCatalogueError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Add Gift Catalogue
            <LinksComponent />
          </div>

          <div className="w-full">
            <div className="p-3">
              <form className="p-3 bg-white shadow rounded-lg">
                <div className=" flex flex-col justify-center align-middle">
                  <div className=" ">
                    <div className="mx-auto flex space-x-5 ">
                      <div className="mb-6 w-1/4">
                        <InputComponent
                          name={"Catalogue Name"}
                          type={"text"}
                          value={catalogueName}
                          setValue={setCatalogueName}
                          required={true}
                        />
                      </div>
                      <div className="mb-6 w-1/4 dropds">
                      <SelectComponent
                      title={"Status"}
                      selected={selectedCatalogueType}
                      setSelected={setSelectedCatalogueType}
                      dataArr={catalogueType}
                    />
                      </div>
                      <div className="mb-6 w-1/4 dropds">
                      <SelectComponent
                      title={"Type"}
                      selected={selectedType}
                      setSelected={setSelectedType}
                      dataArr={types}
                    />
                      </div>
                      <div className="mb-6 w-1/3 dropds">
                        <MultiSelectComponent
                          title={"User Type"}
                          placeholder={"Select user types..."}
                          selected={userTypes}
                          setSelected={setUserTypes}
                          dataArr={
                            appUserTypesData ? appUserTypesData.body : []
                          }
                        />
                      </div>
                    </div>
                    <div className="w-full flex justify-center ">
                      <div className="flex flex-col items-center w-full rounded-lg bg-gray-50">
                        <>
                          <tr className=" flex justify-evenly w-full bg-gray-200 ">
                            <td className="py-3 w-1/12 min-w-max">
                              <div className="flex items-center">
                                <input
                                  id="checkbox-table-search-1"
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                                  checked={globalChecked}
                                  value={globalChecked}
                                  onChange={(e) => handleGlobalSelection(e)}
                                />
                                <label
                                  htmlFor="checkbox-table-search-1"
                                  className="sr-only"
                                >
                                  checkbox
                                </label>
                              </div>
                            </td>
                            <th
                              scope="row"
                              className="px-2 py-3 w-1/5 font-medium text-gray-900 whitespace-nowrap"
                            >
                              Name
                            </th>
                            <td className="px-2 py-3 w-1/5">Brand</td>
                            <td className="px-2 py-3 w-1/5">Points</td>
                            {/* <td className="px-2 py-3 w-1/5"></td> */}
                          </tr>
                        </>
                        {selectedGifts &&
                          selectedGifts.map((each, idx) => (
                            <>
                              <tr className="bg-white flex justify-evenly w-full hover:bg-gray-50 ">
                                <td className="py-3 w-1/12 min-w-max">
                                  <div className="flex items-center">
                                    <input
                                      id="checkbox-table-search-1"
                                      type="checkbox"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                                      onChange={(e) => handleOnChange(e, idx)}
                                      value={each.checked}
                                      checked={each.checked}
                                    />
                                    <label
                                      htmlFor="checkbox-table-search-1"
                                      className="sr-only"
                                    >
                                      checkbox
                                    </label>
                                  </div>
                                </td>
                                <th
                                  scope="row"
                                  className="px-2 py-2 w-1/5 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {each.name}
                                </th>
                                <td className="px-2 py-2 w-1/5">
                                  {each.brand}
                                </td>
                                <td className="px-2 py-2 w-1/5">
                                  {each.points}
                                </td>
                                {/* <td className="px-2 py-2 w-1/5">
                                  {each.checked ? "True" : "False"}{" "}
                                </td> */}
                                {/* <td className="px-2 py-2 w-1/5">{idx}</td> */}
                              </tr>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="  mt-3  flex justify-center ">
                    <button
                      type="submit"
                      onClick={(e) => handleAddCatalogue(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGiftCatalogue;
