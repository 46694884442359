import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Rewardify = () => {
  const [newRewardityOption, setNewRewardifyOption] = useState("");
  const [rewardifyOptions, setRewardifyOptions] = useState([]);

  const [newGenuinityOption, setNewGenuinityOption] = useState("");
  const [genuinityOptions, setGenuinityOptions] = useState([]);

  const [newWarrantyOption, setNewWarrantyOption] = useState("");
  const [warrantyOptions, setWarrantyOptions] = useState([]);

  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const removeFromRewardifyOptions = (e, key) => {
    console.log(key);
    if (window.confirm("Remove option ", key)) {
      let oldValues = [...rewardifyOptions];
      oldValues = oldValues.filter((user) => user != key);
      setRewardifyOptions(oldValues);
    }
  };

  const removeFromGenuinityOptions = (e, key) => {
    console.log(key);
    if (window.confirm("Remove option ", key)) {
      let oldValues = [...genuinityOptions];
      oldValues = oldValues.filter((user) => user != key);
      setGenuinityOptions(oldValues);
    }
  };

  const removeFromWarrantyOptions = (e, key) => {
    console.log(key);
    if (window.confirm("Remove option ", key)) {
      let oldValues = [...warrantyOptions];
      oldValues = oldValues.filter((user) => user != key);
      setWarrantyOptions(oldValues);
    }
  };

  useEffect(() => {

    toast.remove();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/rewardify`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setRewardifyOptions(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "toastid",
          },
        );
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/genuinemark`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setGenuinityOptions(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "toastid",
          },
        );
      });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/dwan`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setWarrantyOptions(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "toastid",
          },
        );
      });
  }, []);

  const deleteRewardifyOption = (r) => {
    let tbc = [...rewardifyOptions];
    const objWithIdIndex = tbc.findIndex((obj) => obj.rewardify_id === r.rewardify_id);
    if (objWithIdIndex > -1) {
      tbc.splice(objWithIdIndex, 1);
    }
    setRewardifyOptions(tbc);

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/admin/rewardify/${r.rewardify_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.message, {
            id: "deleteSuccess",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "deleteError",
          },
        );
      });
  };

  const deleteGenuinemarkOption = (r) => {
    let tbc = [...genuinityOptions];
    const objWithIdIndex = tbc.findIndex((obj) => obj.gm_id === r.gm_id);
    if (objWithIdIndex > -1) {
      tbc.splice(objWithIdIndex, 1);
    }
    setGenuinityOptions(tbc);

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/admin/genuinemark/${r.gm_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.message, {
            id: "deleteSuccess",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "deleteError",
          },
        );
      });
  };

  const deleteDwamOption = (r) => {
    let tbc = [...warrantyOptions];
    const objWithIdIndex = tbc.findIndex((obj) => obj.dwan_id === r.dwan_id);
    if (objWithIdIndex > -1) {
      tbc.splice(objWithIdIndex, 1);
    }
    setWarrantyOptions(tbc);

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/admin/dwan/${r.dwan_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.message, {
            id: "deleteSuccess",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "deleteError",
          },
        );
      });
  };

  const handleButtonDeleteClick = (r) => {
    console.log(r);
    if ("rewardify_id" in r) {
      deleteRewardifyOption(r);
    } else if ("gm_id" in r) {
      deleteGenuinemarkOption(r);
    } else if ("dwan_id" in r) {
      deleteDwamOption(r);
    }
    return;
  };

  const handleButtonEditClick = (r) => {
    console.log(r);
    if ("rewardify_id" in r) {
      navigate(`/editOption?rewardify=${r.rewardify_id}` , { state : r.option_name });
    } else if ("gm_id" in r) {
      navigate(`/editOption?genuinemark=${r.gm_id}` , { state : r.option_name });
    } else if ("dwan_id" in r) {
      navigate(`/editOption?dwan=${r.dwan_id}` , { state : r.option_name });
    }
    return;
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1 ,
      sortable: true,
      width : "80px"
    },
    {
      name: "Name",
      selector: (row) => row.option_name,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleButtonDeleteClick(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="borderbtm">
                <h4 className="card-title mb-0">Rewardify</h4>
                </div>
                <div className="cmxform mt-3" id="signupForm">
                  <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                      onClick={() => navigate("/addNewOption?rewardify")}
                    >
                      Add New Rewardify Option
                    </button>
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      <DataTable
                        data={rewardifyOptions}
                        columns={columns}
                        //selectableRows
                        // onSelectedRowsChange={handleChange}
                        // customStyles={customStyles}
                        striped
                        highlightOnHover
                        pointerOnHover
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="borderbtm">
                <h4 className="card-title mb-0">Genuinemark</h4>
                </div>
                <div className="cmxform mt-3" id="signupForm">
                  <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                      onClick={() => navigate("/addNewOption?genuinemark")}
                    >
                      Add New Genuinemark Option
                    </button>
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      <DataTable
                        // title="Rewardify"
                        data={genuinityOptions}
                        columns={columns}
                        //selectableRows
                        // onSelectedRowsChange={handleChange}
                        // customStyles={customStyles}
                        striped
                        highlightOnHover
                        pointerOnHover
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="borderbtm">
                <h4 className="card-title mb-0">DWAM</h4>
                </div>
                <div className="cmxform mt-3" id="signupForm">
                  <div className="d-flex align-items-center flex-wrap text-nowrap">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                      onClick={() => navigate("/addNewOption?dwan")}
                    >
                      Add New DWAM Option
                    </button>
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      <DataTable
                        data={warrantyOptions}
                        columns={columns}
                        //selectableRows
                        // onSelectedRowsChange={handleChange}
                        // customStyles={customStyles}
                        striped
                        highlightOnHover
                        pointerOnHover
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rewardify;
