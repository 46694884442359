import { rootApi } from "../../api/rootApi";

export const profileApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    
    updateProfile: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/app/profile/tenant/?type=${params.type}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug : params.tenant_id,
          },
          body : params.data
        };
      },
    }),
    
  }),
  
});

export const { useUpdateProfileMutation} = profileApi;
