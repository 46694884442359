import { rootApi } from "../../api/rootApi";

export const eCatalogueApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteCatelogue: builder.mutation({
        query: (params) => {
          return {
              method: "DELETE",
              url: `/tenant/eCatalogue/delete/${params.catalogueId}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + params.token,
                slug: params.tenant_id,
              },
            };
        },
      }),
    fetchCatelogues: builder.mutation({
      query: (params) => {
        return {
            method: "GET",
            url: `/tenant/eCatalogue/?limit=${params.limit}&&offset=${params.offset}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
      },
    }),
    fetchCatelogueByCatalogueId: builder.mutation({
      query: (params) => {
        return {
            method: "GET",
            url: `/tenant/eCatalogue/?id=${params.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
      },
    }),
    uploadECatalogue: builder.mutation({
      query: (params) => {
        return {
          method: "post",
          url: `/tenant/eCatalogue/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug : params.tenant_id,
          },
          body : params.data
        };
      },
    }),
  }),
});

export const { useFetchCatelogueByCatalogueIdMutation , useFetchCateloguesMutation , useUploadECatalogueMutation ,useDeleteCatelogueMutation } = eCatalogueApi;
