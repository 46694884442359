import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { getVendorName } from "../../../utils/getVendorName";
import { useCreateMembershipMutation } from "../../../features/tenant/membership/membershipApi";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";
import {
  useCreateScratchableRewardMutation,
  useUpdateScratchableRewardByIdMutation,
} from "../../../features/tenant/scratchableReward/scratchableRewardAPI";

const ScratchableRewards = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isUpdate, setIsUpdate] = useState(false);

  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [value, setValue] = useState(null);
  const [couponCount, setCouponCount] = useState(null);
  const [probability, setProbability] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);

  const [schemeType, setSchemeType] = useState(null);

  const [couponCountLimit, setCouponCountLimit] = useState(0);
  const [probabilityLimit, setProbabilityLimit] = useState(0);


  useEffect(() => {
    console.log(location.state);

    if (location.state) {
      if (location.state.isUpdate === true) {
        setIsUpdate(true);
        setName(location.state.data.name);
        setPrice(location.state.data.original_price);
        setValue(location.state.data.value);
        setCouponCount(location.state.data.coupon_count);
        setDisplayImage(location.state.data.image);
        setProbability(location.state.data.probability);
        console.log(location.state.scheme.total_coupons - (location.state.scheme.used_coupons - location.state.data.coupon_count ));
        setCouponCountLimit(location.state.scheme.total_coupons - (location.state.scheme.used_coupons - location.state.data.coupon_count ));
        setProbabilityLimit( 100 - (location.state.scheme.total_probability - location.state.data.probability) );
      }else {
        setCouponCountLimit(location.state.scheme.total_coupons - location.state.scheme.used_coupons);
        setProbabilityLimit(100 - location.state.scheme.total_probability);
      }

      if (location.state.scheme) {
        setSchemeType(location.state.scheme.scheme_type);
      }
    }
  }, []);

  const [
    addScratchableReward,
    { data: addScratchableRewardData, error: addScratchableRewardError },
  ] = useCreateScratchableRewardMutation();

  const [
    updateScratchableReward,
    { data: updateScratchableRewardData, error: updateScratchableRewardError },
  ] = useUpdateScratchableRewardByIdMutation();

  useEffect(() => {
    if (addScratchableRewardData) {
      toast.success("Reward Added successfully", {
        id: "rewardCreated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/scratchable-coupon/rewards`, {
          state: location.state.scheme,
        });
      }, 400);
    }

    if (addScratchableRewardError) {
      if (addScratchableRewardError.status == 409) {
        toast.error("Reward already exists ", {
          id: "addMembershipErrorAlreadyExists",
        });
      } else {
        toast.error("Something went wrong", {
          id: "addScratchableRewardError",
        });
      }
      return;
    }
  }, [addScratchableRewardData, addScratchableRewardError]);

  useEffect(() => {
    if (updateScratchableRewardData) {
      toast.success("Reward updated successfully", {
        id: "rewardUpdated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/scratchable-coupon/rewards`, {
          state: location.state.scheme,
        });
      }, 400);
    }

    if (updateScratchableRewardError) {
      if (updateScratchableRewardError.status == 409) {
        toast.error("Reward already exists ", {
          id: "updateMembershipErrorAlreadyExists",
        });
      } else {
        toast.error("Something went wrong", {
          id: "updateScratchableRewardError",
        });
      }
      return;
    }
  }, [updateScratchableRewardData, updateScratchableRewardError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!location.state || !location.state.scheme) {
      return;
    }

    if (!name) {
      toast.error("Please enetr name", { id: "noName" });
      return;
    }

    if (!price) {
      toast.error("Please enter price", { id: "noPrice" });
      return;
    }

    // if(location.state.scheme.scheme_type === "CONTINUOUS"){
    //   if (!value) {
    //     toast.error("Please enter value", { id: "noValue" });
    //     return;
    //   }
    // }

    if (!couponCount) {
      toast.error("Please enter Coupon Count", { id: "noCouponCount" });
      return;
    }

    if(couponCount > couponCountLimit ){
      toast.error(`Coupon count Limit Exceeded ${couponCountLimit}`, { id: "noCouponLimit" });
      return;
      
    }

    if (!probability) {
      toast.error("Please enter Probability", { id: "noProbability" });
      return;
    }

    if (probability > probabilityLimit) {
      toast.error(`Probability Limit Exceeded ${probabilityLimit}`, { id: "invalidProbability" });
      return;
    }

    if (probability < 0 || probability > 100) {
      toast.error("Please enter valid probability", {
        id: "notValidProbability",
      });
      return;
    }

    if (price > 2147483640 || price < 0) {
      toast.error("Please enter valid price", {
        id: "noValidPrice",
      });
      return;
    }

    addScratchableReward({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        ref_id: location.state.scheme.id,
        name: name,
        original_price: price,
        // value: value,
        coupon_count: couponCount,
        image: displayImage,
        probability: probability,
      },
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!location.state || !location.state.data || !location.state.scheme) {
      return;
    }

    if (probability < 0 || probability > 100) {
      toast.error("Please enter valid probability", {
        id: "notValidProbability",
      });
      return;
    }
    if (probability > probabilityLimit) {
      toast.error(`Probability Limit Exceeded ${probabilityLimit}`, { id: "invalidProbability" });
      return;
    }

    if (price > 2147483640 || price < 0) {
      toast.error("Please enter valid price", {
        id: "noValidPrice",
      });
      return;
    }

    if(couponCount > couponCountLimit ){
      toast.error(`Coupon count Limit Exceeded ${couponCountLimit}`, { id: "noCouponLimit" });
      return; 
    }

    updateScratchableReward({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: location.state.data.id,
      body: {
        ref_id: location.state.scheme.id,
        name: name,
        original_price: price,
        // value: value,
        coupon_count: couponCount,
        image: displayImage,
        probability: probability,
      },
    });
  };

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const uploadImageFunc = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    uploadImage({
      body: formData,
    });
  };

  useEffect(() => {
    toast.dismiss();
    if (uploadImageData) {
      console.log("success image upload", uploadImageData.body);
      setDisplayImage(uploadImageData.body.fileLink);
    }
    if (uploadImageError) {
      toast.error("Image upload failed");
    }

    if (uploadImageIsLoading) {
      toast.loading("Uploading your image...", { id: "uploadImageLoading" });
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);


  console.log("couponLimit : probabilityLimit" , couponCountLimit , probabilityLimit);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}Scratchable Reward
            <LinksComponent />
          </div>

          {/* <h1>Coupon Limit {couponCountLimit}</h1>
          <h1>Probabilty Limit {probabilityLimit}</h1> */}
          <div className="p-3">
            <form className="p-3 w-full bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="flex justify-start space-x-3">
                    <div className="mb-6 w-1/4">
                      <InputComponent
                        name={"Name"}
                        type={"text"}
                        value={name ? name : ""}
                        setValue={setName}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/4">
                      <InputComponent
                        name={"Price"}
                        type={"number"}
                        value={price ? price : ""}
                        setValue={setPrice}
                        required={true}
                      />
                    </div>
                    {/* {schemeType === "CONTINUOUS" && (
                      <div className="mb-6 w-1/3">
                        <InputComponent
                          name={"Value"}
                          type={"number"}
                          value={value ? value : ""}
                          setValue={setValue}
                          required={true}
                        />
                      </div>
                    )} */}

                    <div className="mb-6 w-1/4">
                      <InputComponent
                        name={"Coupon Count"}
                        type={"number"}
                        value={couponCount ? couponCount : ""}
                        setValue={setCouponCount}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/4">
                    <InputComponent
                      name={"Probability"}
                      type={"number"}
                      value={probability ? probability : ""}
                      setValue={setProbability}
                      required={true}
                    />
                  </div>
                  </div>

                

                  <div className="w-full flex justify-center ">
                    <div className="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                      >
                        <div className="flex flex-col items-center justify-center ">
                          <svg
                            aria-hidden="true"
                            className="w-40 h-40 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              strokeLinejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500">PNG or JPG .</p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => uploadImageFunc(e.target.files[0])}
                        />
                      </label>
                    </div>
                  </div>

                  {displayImage && (
                    <img
                      src={
                        
                        displayImage
                      }
                      alt=""
                      srcset=""
                      className="flex items-center justify-center w-20 m-auto rounded-md"
                    />
                  )}
                </div>

                <div className="flex justify-center mt-2">
                  {isUpdate ? (
                    <button
                      type=""
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type=""
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScratchableRewards;
