import React from "react";
import { useSelector } from "react-redux";

function Task({ colIndex, taskIndex }) {
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const columns = board.columns;
  const col = columns.find((col, i) => i === colIndex);
  const task = col.tasks.find((task, i) => i === taskIndex);

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex }),
    );
  };

  return (
    <div
      draggable
      onDragStart={handleOnDrag}
      className=" text-center"
      style={{
        border: "1px solid #bac3ff2e",
        borderRadius: "5px",
        paddingTop: "10px",
        marginBottom: "5px",
        backgroundColor: "#bac3ff2e",
      }}
    >
      <p className=" pb-2 ">{task.title}</p>
    </div>
  );
}

export default Task;
