export const hasPermission = (action) => {
    const permission = sessionStorage.getItem("userPermission")
      ? JSON.parse(sessionStorage.getItem("userPermission"))
      : null;

    //   console.log(permission , action);

    for (let index = 0; index < permission.length; index++) {
        // const element = permission[index];
        if(permission[index].action === action) {
            return true;
        }
    }  

    return false;
}