import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import CSVReader from "../../../components/ImportCsv/CSVReader";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import toast from "react-hot-toast";
import { getVendorName } from "../../../utils/getVendorName";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadSampleCSV } from "../../../utils/downloadSampleCsv";
import { useBulkManualRewardingMutation } from "../../../features/tenant/ManualRewarding/ManualRewardingApi";
import CustomExport from "../../../components/CustomExport/CustomExport";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";

function validateAndCleanObjects(objects, requiredKeysSet) {
  function isEmptyObject(obj) {
    return Array.from(requiredKeysSet).every((key) => !obj[key.trim()]);
  }

  const cleanedObjects = [];

  console.log("object", objects);

  for (const object of objects) {
    const newObject = {};

    if (isEmptyObject(object)) {
      continue;
    }

    let valid = true;
    for (let key in object) {
      key = key.trim();
      console.log(
        "object key",
        key,
        key.length,
        requiredKeysSet.has(key),
        Object.keys(object)
      );
      if (requiredKeysSet.has(key)) {
        if (!object[key]) {
          valid = false;
          break;
        } else {
          newObject[key] = object[key];
        }
      }
    }

    newObject.status = valid ? 1 : 2;
    cleanedObjects.push(newObject);
  }

  if (cleanedObjects.some((obj) => obj.status === 2)) {
    return {
      isValid: false,
      data: cleanedObjects,
    };
  }

  return {
    isValid: true,
    data: cleanedObjects,
  };
}
const BulkManualRewarding = () => {
  let requiredKeysSet;
  if (JSON.parse(sessionStorage.getItem("vendorData")).tenant_id == 13) {
    requiredKeysSet = new Set(["mobile", "type", "value"]);
  } else {
    requiredKeysSet = new Set(["mobile", "type", "value"]);
  }

  const navigate = useNavigate();
  const location = useLocation();

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const [isDownload, setIsDownload] = useState(false);

  const [canSubmit, setCanSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [manualRewardingResponseData, setManualRewardingResponseData] =
    useState([]);
  const [modalData, setModalData] = useState(null);

  const [sortedData, setsortedData] = useState([]);

  const [fileName, setFileName] = useState(null);

  const fileReader = new FileReader();

  const [
    bulkManualRewarding,
    {
      data: bulkManualRewardingData,
      error: bulkManualRewardingError,
      isLoading: bulkManualRewardingIsLoading,
    },
  ] = useBulkManualRewardingMutation();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  console.log("csvData2", csvData, csvData?.file?.name);

  const handleFileUpload = (e) => {
    e.preventDefault();
    console.log("new ", file);

    console.log("csvData3", csvData);

    if (csvData.parsed.length > 0) {
      const validationResult = validateAndCleanObjects(
        csvData.parsed,
        requiredKeysSet
      );
      console.log("validationResult", validationResult);

      setCanSubmit(validationResult.isValid);

      setModalData(validationResult.data);

      setArray(validationResult.data);
      setsortedData(validationResult.data);
      toast.success("Data successfully imported...");
    }
  };

  console.log(array);
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const handleOnSubmit = (e) => {
    setsortedData(null);
    toast.loading("Processing your data...");

    e.preventDefault();

    bulkManualRewarding({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        data: array,
        token: sessionStorage.getItem("access_token"),
      },
    });
  };

  useEffect(() => {
    if (bulkManualRewardingData && bulkManualRewardingData.body) {
      toast.dismiss();
      toast.success(bulkManualRewardingData.message, {
        id: "bulkInsertPaymentTransferData",
      });
      console.log("bulkManualRewardingData", bulkManualRewardingData);
      setManualRewardingResponseData(bulkManualRewardingData.body);
      setShowModal2(true);

      // setTimeout(() => {
      //   navigate(`/genefied/${getVendorName()}/payment-target`);
      // }, 400);
    }

    if (bulkManualRewardingError) {
      toast.dismiss();

      if (bulkManualRewardingError.status === 409) {
        toast.error(bulkManualRewardingError.data.message, {
          id: "bulkInsertPaymentTransferError",
        });
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [bulkManualRewardingData, bulkManualRewardingError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Upload Bulk Manual Rewarding Data
            <LinksComponent />
          </div>

          <div className="mx-9 mt-10">
            <div className="bg-white m-auto border-opacity-40 rounded-lg flex w-full p-4 px-5 justify-center">
              <div className=" flex flex-col justify-center align-middle w-full">
                <div className="flex justify-center mt-3">
                  <button
                    // type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() =>
                      downloadSampleCSV(
                        JSON.parse(sessionStorage.getItem("vendorData"))
                          .tenant_id == 13
                          ? [
                              { name: "mobile" },
                              { name: "type" },
                              {
                                name: "value",
                              },
                            ]
                          : [
                              { name: "mobile" },
                              { name: "type" },
                              {
                                name: "value",
                              },
                            ]
                      )
                    }
                  >
                    Sample Csv Download
                  </button>
                </div>
                <div className="">
                  <div className="text-center ">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Upload Excel/CSV File:
                    </label>
                    <div className="flex gap-3 border-b pb-4 border-gray-200">
                      {/* Month and Year Input */}
                      <div className="flex gap-2"></div>

                      {/* CSV File Input */}

                      {/*<input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type={"file"}
                      id={"csvFileInput"}
                      accept={".csv"}
                      onChange={handleOnChange}
                    /> */}

                      <CSVReader
                        setCSVData={setCSVData}
                        setFileName={setFileName}
                      />

                      {/* Import CSV Button */}
                      <button
                        className="whitespace-nowrap bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
                        onClick={(e) => handleFileUpload(e)}
                      >
                        IMPORT CSV
                      </button>
                    </div>

                    <div className="mt-4 flex justify-center space-x-2 ">
                      {/* View CSV File Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center"
                        >
                          View CSV File
                        </button>
                      )}

                      {/* Save Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => handleOnSubmit(e)}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Save
                        </button>
                      )}
                    </div>
                    <div className="mt-2 bg-gray-100 p-3 d-flex justify-center">
                    {showModal2 && (
                      <ExportCSV
                        data={
                          manualRewardingResponseData.length > 0 &&
                          manualRewardingResponseData
                        }
                        columnsToExport={[
                          "mobile",
                          "type",
                          "value",
                          "fail",
                          "api_response",
                        ]}
                      />
                    )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>

          {showModal && modalData && (
            <ModalComponent
              size={"5xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg">
                <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr>
                        {headerKeys.map((key, idx) => (
                          <th
                            className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            key={idx}
                          >
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {array.slice(0, 30).map((item) => (
                        <tr
                          key={item.id}
                          className="border-t-2 border-b-2 border-gray-300"
                        >
                          {Object.values(item).map((val, index) => (
                            <td
                              key={index}
                              className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                    <h2 className="mt-2 ml-auto">
                      {" "}
                      {array.length - 30} rows more
                    </h2>
                  </table>
                </div>
              </div>
            </ModalComponent>
          )}
          {showModal2 &&
            manualRewardingResponseData &&
            manualRewardingResponseData.length > 0 && (
              <ModalComponent
                size={"5xl"}
                data={""}
                show={showModal}
                setShow={setShowModal}
              >
                <div className="w-full rounded-lg">
                  <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                    <button className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] ">
                      Download Response
                    </button>
                  </div>
                </div>
              </ModalComponent>
            )}
        </div>
      </div>
    </>
  );
};

export default BulkManualRewarding;
