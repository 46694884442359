import { Dialog } from "@headlessui/react";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { GrFormClose } from "react-icons/gr";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RedemptionApprovalHierarchay = ({
  open,
  setOpen,
  userTypes,
  handleRedemptionHierarchy,
  redemptionHierarchy,
}) => {
  console.log("UserTYpe", userTypes);

  const [selectedUserTypes, setSelectedUserTypes] = useState({});

  const [selectedMappedUsers, setSelectedMappedUsers] = useState({});
  const [selectGiftExtraApproval, setSelectGiftExtraApproval] = useState({});
  // const [giftApprovalHierarchy, setGiftApprovalHierarchy] = useState([]);

  console.log("redemptionHierarchy", redemptionHierarchy);
  console.log("selectedUserTypes", selectedUserTypes);
  console.log("selectedMappedUsers", selectedMappedUsers);
  console.log("selectGiftExtraApproval", selectGiftExtraApproval);

  useEffect(() => {
    setSelectedMappedUsers(redemptionHierarchy && redemptionHierarchy.gift_mapped_users ?  redemptionHierarchy.gift_mapped_users : {} );
    setSelectedUserTypes(redemptionHierarchy.gift_mapped_users  ? redemptionHierarchy.gift_mapped_users : {});
    setSelectGiftExtraApproval(redemptionHierarchy && redemptionHierarchy.gift_extra_approvals
 ? redemptionHierarchy.gift_extra_approvals
 : {});
  }, [redemptionHierarchy]);

  const handleCheckUserTypeChange = (event) => {
    setSelectedUserTypes({
      ...selectedUserTypes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleGiftExtraApprovalCheckChange = (event)=>{
    setSelectGiftExtraApproval({
      ...selectGiftExtraApproval,
      [event.target.name]: event.target.checked,
    })
  }

  const handleSelectChange = (event, name) => {
    setSelectedMappedUsers({
      ...selectedMappedUsers,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(object);

    const isMappedUsers =
      Object.keys(selectedMappedUsers).length > 0 ? true : false;
    // const isGiftApprovalHeirarchy = giftApprovalHierarchy.length >0 ? true : false;

    if (isMappedUsers) {
      const remdemptionApprovalObject = {
        is_mapped_users: isMappedUsers,
        gift_mapped_users: selectedMappedUsers,
        gift_extra_approvals: selectGiftExtraApproval,
      };

      handleRedemptionHierarchy(remdemptionApprovalObject);
      setOpen(false);
    } else {
      toast.error("Gifts Mapping Users Selection is Required");
    }

    return;
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log("userTypes", userTypes);

  return (
    <div className="w-full">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography variant="h4" style={{ color: "black" }}>
            Gift Aproval Hierarchy
          </Typography>
          <Typography variant="h6" style={{ color: "black" }}>
            Gifts Mapping Users
          </Typography>
          <div className="d-flex">
            {userTypes && userTypes.map((type) => (
              <div className="mx-2" key={type}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (Object.keys(selectedUserTypes).length > 0 &&
                          selectedUserTypes[type]) ||
                        false
                      }
                      onChange={(e) => handleCheckUserTypeChange(e, type)}
                      name={type}
                    />
                  }
                  label={type}
                />
                {Object.keys(selectedUserTypes).length > 0 &&
                  selectedUserTypes[type] && (
                    <FormControl fullWidth>
                      <InputLabel className="bg-white">
                        Select User Type
                      </InputLabel>
                      <Select
                        multiple
                        value={selectedMappedUsers[type] || []}
                        onChange={(e) => handleSelectChange(e, type)}
                        className="mb-3"
                      >
                         { userTypes && userTypes.map((t, i) => {
                          if (t === type) {
                            return null;
                          } else {
                            return (
                              <MenuItem key={i} value={t}>
                                {t}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  )}
              </div>
            ))}
          </div>
          {
             Object.keys(selectedMappedUsers).length > 0 && Object.keys(selectedMappedUsers).map((type)=>{
              return (
                <div>
                  <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (Object.keys(selectGiftExtraApproval).length > 0 &&
                        selectGiftExtraApproval[type]) ||
                        false
                      }
                      onChange={(e) => handleGiftExtraApprovalCheckChange(e, type)}
                      name={type}
                    />
                  }
                  label={type}
                />
                </div>
              )
            })
          }
          {/* <div className="col-12 my-2">
            <div>
              <label>Gift Approval </label>
            </div>
            <select
              onClick={(e) => {
                if (e.target.value != "select") {
                  setGiftApprovalHierarchy([
                    ...giftApprovalHierarchy,
                    e.target.value,
                  ]);
                }
              }}
              className="border rounded-lg shadow-sm shadow-black px-2"
            >
              <option
                value={"select"}
                className="rounded-lg shadow-sm shadow-black px-2"
              >
                Select
              </option>
              {/* Uncomment and modify the below code as needed */}
          {/* {userTypes &&
                userTypes.map((x) => {
                  if (giftApprovalHierarchy.toString().includes(x)) {
                    return null;
                  }
                  return (
                    <option
                      key={x}
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={x}
                    >
                      {x}
                    </option>
                  );
                })}
            </select>
            {giftApprovalHierarchy.length > 0 &&
              giftApprovalHierarchy.map((user) => (
                <label className="mt-2 mr-2" key={user}>
                  <span className="btn btn-outline-danger d-flex align-items-center">
                    {user.charAt(0).toUpperCase() + user.slice(1)}
                    <GrFormClose
                      className="w-4 h-4 ml-2"
                      onClick={() => {
                        let newArr = giftApprovalHierarchy.filter(
                          (item) => item !== user
                        );
                        setGiftApprovalHierarchy(newArr);
                      }}
                    />
                  </span>
                </label>
              ))}
          </div> */}
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default RedemptionApprovalHierarchay;
