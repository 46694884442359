import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { toast } from "react-hot-toast";
import {
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} from "../../../features/admin/emailMaster/emailTemplateApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const emailTypes = [
  "welcome_mail",
  "redeemption_mail",
  "registration_mail",
  "warranty_mail",
  "gift_redeemption_mail",
  "support_mail",
  "reset_password_mail",
  "order_status_change",
];

export default function AddEmailTemplate() {
  const [templateName, setTemplateName] = useState(null);
  const [templateType, setTemplateType] = useState(emailTypes[0]);
  const [subject, setSubject] = useState(null);
  const [plaintext, setPlaintext] = useState(null);
  const [htmlTemplate, setHtmlTemplate] = useState(null);

  const [isUpdate, setIsUpdate] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [
    createEmailTemplate,
    {
      data: createEmailTemplateData,
      error: createEmailTemplateError,
      isLoading: createEmailTemplateisLoading,
    },
  ] = useCreateEmailTemplateMutation();

  const [
    updateEmailTemplateFunc,
    {
      data: updateEmailTemplateData,
      error: updateEmailTemplateError,
      isLoading: updateEmailTemplateisLoading,
    },
  ] = useUpdateEmailTemplateMutation();

  useEffect(() => {
    if (createEmailTemplateData) {
      console.log("succcessss", createEmailTemplateData);
      toast.success("Template Added Successfully", {
        id: "templateAddSuccess",
      });
    }

    if (createEmailTemplateError) {
      console.log("errrrrror", createEmailTemplateError);
      toast.error("Something went wrong", {
        id: "templateAddError",
      });
    }
  }, [createEmailTemplateData, createEmailTemplateError]);

  useEffect(() => {
    if (location.state) {
      // console.log("object ", location.state);
      setIsUpdate(true);
      setTemplateType(location.state.template_type);
      setTemplateName(location.state.template_name);
      setSubject(location.state.subject);
      setHtmlTemplate(location.state.html_template);
      setPlaintext(location.state.plain_text);
    }
  }, [location]);

  const addEmailTemplate = () => {
    toast.dismiss();

    if (!templateName) {
      toast.error("Please provide a template name", {
        id: "noTemplateName",
      });
      return;
    }

    if (!templateType) {
      toast.error("Please select a template type", {
        id: "notemplateType",
      });
      return;
    }

    if (!subject) {
      toast.error("Please provide a subject", {
        id: "nosubject",
      });
      return;
    }

    if (!htmlTemplate) {
      toast.error("Please provide a html template", {
        id: "noHtmlTemplate",
      });
      return;
    }

    console.log(templateName, templateType);

    createEmailTemplate({
      token: sessionStorage.getItem("access_token"),
      data: {
        template_name: templateName,
        template_type: templateType,
        subject: subject,
        plain_text: plaintext,
        html_template: htmlTemplate,
        template_owned_by_id: "0",
      },
    });
  };

  const updateEmailTemplate = () => {
    if (!templateName) {
      toast.error("Please provide a template name", {
        id: "noTemplateName",
      });
      return;
    }

    if (!templateType) {
      toast.error("Please select a template type", {
        id: "notemplateType",
      });
      return;
    }

    if (!subject) {
      toast.error("Please provide a subject", {
        id: "nosubject",
      });
      return;
    }

    if (!htmlTemplate) {
      toast.error("Please provide a html template", {
        id: "noHtmlTemplate",
      });
      return;
    }
    updateEmailTemplateFunc({

    token: sessionStorage.getItem("access_token"),
    id : location.state.id,
     data :{ template_name: templateName,
      template_type: templateType,
      subject: subject,
      plain_text: plaintext,
      html_template: htmlTemplate,}
    });
  };

  useEffect(() => {
    if (updateEmailTemplateData) {
      console.log("updateEmailTemplateData", updateEmailTemplateData);
      toast.success("Updated Email Template", {
        id: "updatedEmailTemplateSuccess",
      });
      setTimeout(() => {
        navigate(-1);
      }, 700);
    }
    if (updateEmailTemplateError) {
      toast.error("Something went wrong", { id: "updateEmailTemplateError" });
    }
  }, [updateEmailTemplateData, updateEmailTemplateError]);

  return (
    <>
      <div className="page-content">
        <div className="card p-4">
          <div className="text-center">
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {templateName}
                  </Typography>
                  <div
                    className="modal-body"
                    dangerouslySetInnerHTML={{ __html: htmlTemplate }}
                  />
                </Box>
              </Fade>
            </Modal>
          </div>

          <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 className="card-title">
                {" "}
                {isUpdate ? "Update" : "Add New"} Email Template{" "}
              </h4>
            </div>
          </div>

          <div className="cmxform " id="signupForm">
            <div className="row frmds">
              <div className="col-md-4 my-2">
                <label className="my-1">Template Name </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Template Name"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>

              <div className="col-md-4 my-2 ">
                <label className="my-1">Template Type </label>

                <select
                  className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black px-2"
                  value={templateType}
                  onChange={(e) => setTemplateType(e.target.value)}
                >
                  {emailTypes.map((each) => (
                    <option
                      className="rounded-lg shadow-sm shadow-black px-2"
                      value={each}
                    >
                      {each.replaceAll("_", " ").toLocaleUpperCase()}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-4 my-2">
                <label className="my-1">Subject </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Template Name"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
            </div>

            <div className="row frmds">
              <div className="col-md-6 my-2">
                <label className="my-1">Plain text </label>
                <textarea
                  type="textarea"
                  className="form-control"
                  placeholder="Plain text"
                  value={plaintext}
                  rows={"4"}
                  onChange={(e) => setPlaintext(e.target.value)}
                />
              </div>
            </div>

            <div className="row frmds">
              <div className="col-md w-100 my-2">
                <div className="my-1">Instructions goes here......</div>

                <label className="my-1">Html Template </label>

                <textarea
                  type="textarea"
                  className="form-control"
                  placeholder="Paste html template"
                  value={htmlTemplate}
                  rows={"20"}
                  onChange={(e) => setHtmlTemplate(e.target.value)}
                />
              </div>
            </div>

            <div className="d-flex  mt-3 gap-3">
              {isUpdate ? (
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-sm"
                  onClick={updateEmailTemplate}
                >
                  Update
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-sm"
                  onClick={addEmailTemplate}
                >
                  Save
                </button>
              )}

              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={handleOpen}
                disabled={
                  htmlTemplate === null || htmlTemplate.length == 0
                    ? true
                    : false
                }
              >
                Preview
              </button>

              <button
                type="cancel"
                className="btn btn-outline-danger btn-sm"
                // onClick={cancelAddProductSubCategory}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
