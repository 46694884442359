import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";

import { toast } from "react-hot-toast";
import {
  useDeleteRewardSchemeByIdMutation,
  useFetchAllRewardSchemeMutation,
  useFetchRewardSchemeBySchemeIdMutation,
} from "../../../features/tenant/rewardScheme/rewardSchemeApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useFetchSchemeOrderBySchemeIdMutation } from "../../../features/tenant/schemeOrder/schemeOrderApi";
import { getVendorName } from "../../../utils/getVendorName";
import { BiDownload, BiUpload } from "react-icons/bi";

function downloadCSV(arrayOfObjects) {
  // Convert array of objects to CSV string
  const csvRows = [];
  const headers = Object.keys(arrayOfObjects[0]);
  csvRows.push(headers.join(","));

  for (let row of arrayOfObjects) {
    const values = headers.map((header) => {
      const escaped = ("" + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  }

  const csvString = csvRows.join("\r\n");

  // Create a blob
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  // Create a download link and click it
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", "data.csv");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const AllRewardSchemeList = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Scheme ID",
      selector: (row) => row.scheme_id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.scheme_name,
      sortable: true,
    },
    {
      name: "For Month",
      selector: (row) =>
        row.created_at &&
        new Date(row.created_at).toLocaleString("default", { month: "long" }),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <button
          className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          onClick={() => handleShowRewardScheme(row)}
        >
          View
        </button>
      ),
      sortable: true,
      minWidth: "50px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => handleFetchAllSchemeOrder(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <BiDownload className="mr-1" />
            Download Orders
          </button>
        </div>
      ),
      minWidth: "210px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => {
              navigate(`/genefied/${getVendorName()}/scheme-order/upload`);
            }}
            className="flex justify-center items-center rounded bg-success  px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]"
          >
            <BiUpload className="mr-1" />
            Upload Orders
          </button>
        </div>
      ),
      minWidth: "180px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => handleDelete(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        </div>
      ),
      minWidth: "120px",
    },
  ];

  const navigate = useNavigate();

  const [
    fetchRewardSchemeBySchemeId,
    { data: rewardSchemeIdData, error: rewardSchemeIdError },
  ] = useFetchRewardSchemeBySchemeIdMutation();

  const [
    fetchSchemeOrderBySchemeId,
    { data: schemeOrderIdData, error: schemeOrderIdError },
  ] = useFetchSchemeOrderBySchemeIdMutation();

  const [
    fetchAllRewardSchemes,
    { data: rewardSchemeData, error: rewardSchemeError },
  ] = useFetchAllRewardSchemeMutation();

  useEffect(() => {
    fetchAllRewardSchemes({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: 10,
      offset: 0,
    });
  }, []);

  console.log(rewardSchemeData);

  const [
    deleteRewardScheme,
    { data: deleteRewardSchemeData, error: deleteRewardSchemeError },
  ] = useDeleteRewardSchemeByIdMutation();

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteRewardScheme({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  scheme_id: data.scheme_id,
                });
                fetchAllRewardSchemes({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  limit: 10,
                  offset: 0,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteRewardScheme",
      }
    );
    return;
  };

  const handleFetchAllSchemeOrder = (row) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to download this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                fetchSchemeOrderBySchemeId({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  scheme_id: row.scheme_id,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "downloadSchemeOrder",
      }
    );
    return;
  };

  useEffect(() => {
    if (schemeOrderIdData) {
      if (schemeOrderIdData && schemeOrderIdData.body.length > 0) {
        toast.success("Orders Fetched", {
          id: "SchemeOrderSuccess",
        });
        console.log("schemeOrderIdData", schemeOrderIdData?.body);
        downloadCSV(schemeOrderIdData.body);
      } else {
        toast("No Orders Found", {
          id: "SchemeOrderSuccess",
        });
      }
    }
    if (schemeOrderIdError) {
      toast.error("Something went wrong", {
        id: "deleteRewardSchemeError",
      });
    }
  }, [schemeOrderIdData, schemeOrderIdError]);

  useEffect(() => {
    if (deleteRewardSchemeError) {
      toast.error("Something went wrong", {
        id: "deleteRewardSchemeError",
      });
    }
  }, [deleteRewardSchemeError]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handleShowRewardScheme = (row) => {
    fetchRewardSchemeBySchemeId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      scheme_id: row.scheme_id,
    });
  };

  useEffect(() => {
    if (rewardSchemeIdData) {
      console.log("rewardSchemeIdData ", rewardSchemeIdData);
      setModalData(rewardSchemeIdData.body);
      setShowModal(true);
    }
    if (rewardSchemeIdError) {
      console.log("rewardSchemeIdError ", rewardSchemeIdError);
    }
  }, [rewardSchemeIdData, rewardSchemeIdError]);

  console.log(rewardSchemeIdData);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            All Reward Schemes
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={rewardSchemeData ? rewardSchemeData.body.data : []}
                // loading={loading}
                // totalRows={totalRows}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div>
          {showModal && modalData && (
            <ModalComponent
              size={"6xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg p-4">
<div className="grid grid-cols-2 gap-5">
<div className="w-full border-r border-gray-100 pr-4">
<h2 className="text-xl font-bold mb-2">Schemes</h2>
                    <div className="grid grid-cols-1 gap-3 overflow-y-scroll max_h">
                      {modalData?.data.map((scheme) => (
                        <div
                          key={scheme.id}
                          className="border  rounded-md"
                        >
                          <h3 className="text-lg font-semibold  rounded-t-md p-2.5 text-center bg-blue-700 text-white">
                            {scheme.scheme_name} ({scheme.product_category})
                          </h3>
                          <div className="p-3">
                          <ul className="grid grid-cols-3 gap-2">
                            <li className="w-full bg-lime-200 border-lime-300 rounded-md px-1 py-2 text-center">
                              <span className="block font-weight-medium text-gray-800 text-xs">Slab</span>
                          <span className="block font-weight-medium text-gray-800">{scheme.slab_start} - {scheme.slab_end}</span>
                         </li>
                         <li className="w-full bg-lime-200 border-lime-300 rounded-md px-1 py-2 text-center">
                         <span className="block font-weight-medium text-gray-800 text-xs">Assured Gift</span>
                         <span className="block font-weight-medium text-gray-800">{scheme.assured_gift}</span>
                                                  
                         </li>
                         <li className="w-full bg-lime-200 border-lime-300 rounded-md px-1 py-2 text-center"> 
                         <span className="block font-weight-medium text-gray-800 text-xs">Status{" "}</span>
                         <span className="block font-weight-medium text-gray-800">{scheme.status === "1" ? "Active" : "Inactive"}</span>
                          </li>
                          </ul>
                          <div className="mt-3 font-weight-medium">
                            <h4 className="text-blue-700 mb-2">Lucky Draws:</h4>
                            <ul className="list-disc ">
                              {scheme.lucky_draws.map((ld, index) => (
                                <li className="flex gap-3 bg-gray-50 mb-1" key={index}>
                                  <h4 className="bg-blue-700 text-white px-4 py-2.5">Draw {index + 1}</h4>
                                  <ul className="flex items-center">
                                    <li className="border-r border-gray-200 pr-3">Value&nbsp;:&nbsp;{" "}{ld[`ld${index + 1}_v`]}</li>
                                    <li className="border-r border-gray-200 pr-3 pl-3">Quantity&nbsp;:&nbsp;{" "}{ld[`ld${index + 1}_q`]}</li>
                                 <li className=" pl-3">
                                 Used&nbsp;:&nbsp;{" "}{ld[`ld${index + 1}_u`]} </li>
                                
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div></div>
                      ))}
                    </div>
</div>

<div className="w-full">
                      <h2 className="text-xl font-bold mb-2">
                        Lucky Draw Breakdown
                      </h2>
                      <div className="grid grid-cols-2 gap-3 overflow-y-scroll max_h">
                        {modalData?.lucky_draw.lucky_draw.map((ld, index) => (
                          <div key={index} className="border px-3 py-3 rounded-md">
                            <div className="flex justify-between items-center mb-2 border-b pb-2 border-gray-100">
                              <span className="font-medium">Draw Value</span>
                              <span className="font-medium bg-lime-200 border-lime-300 px-3 py-1.5 rounded-sm">{ld.ld_v}</span>
                            </div>
                           
                            <div className="flex justify-between items-center mb-2 border-b pb-2 border-gray-100">
<span className="font-medium">Draw Quantity</span>
<span className="font-medium bg-lime-200 border-lime-300 px-3 py-1.5 rounded-sm">{ld.ld_q}</span>
                              </div>

                              <div className="flex justify-between items-center ">
<span className="font-medium">Total</span>
<span className="font-medium bg-lime-200 border-lime-300 px-3 py-1.5 rounded-sm">{ld.sum}</span>
                                </div>

                           
                            {/* <p>Draw Quantity: {ld.ld_u}</p> */}
                          
                          </div>
                        ))}
                      </div>
                    </div>
</div>

<div className="bg-gray-50  rounded-md shadow-md overflow-x-auto mt-4 border-t ">
                      <table className="min-w-full w-full border-collapse table-auto">
                        <thead>
                          <tr className="bg-gray-200 text-left">
                            <th className="px-4 py-2">Draw Value</th>
                            <th className="px-4 py-2">Quantity</th>
                            <th className="px-4 py-2">Week 1</th>
                            <th className="px-4 py-2">Week 1 Used</th>
                            <th className="px-4 py-2">Week 2</th>
                            <th className="px-4 py-2">Week 2 Used</th>
                            <th className="px-4 py-2">Week 3</th>
                            <th className="px-4 py-2">Week 3 Used</th>
                            <th className="px-4 py-2">Week 4</th>
                            <th className="px-4 py-2">Week 4 Used</th>
                            <th className="px-4 py-2">Total Used</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalData?.lucky_draw.lucky_draw.map(
                            (draw, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "bg-gray-100" : ""}
                              >
                                <td className="border text-xs  px-4 py-2">
                                  {draw.ld_v}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.ld_q}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week1}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week1_used}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week2}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week2_used}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week3}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week3_used}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week4}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {draw.week4_used}
                                </td>
                                <td className="border text-xs px-4 py-2">
                                  {Number(draw.week1_used) +
                                    Number(draw.week2_used) +
                                    Number(draw.week3_used) +
                                    Number(draw.week4_used)}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>


              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default AllRewardSchemeList;
