import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useLocation, useNavigate } from "react-router-dom";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import { AiTwotoneDelete } from "react-icons/ai";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import { LoaderIcon, toast } from "react-hot-toast";
import { getCurrentDate } from "../../../utils/getCurrentDate";
import { useCreateSalesBoosterMutation } from "../../../features/tenant/salesBooster/salesBoosterApi";
import { getVendorName } from "../../../utils/getVendorName";
import TriggerOn from "../../../components/Tenant/SalesBooster/TriggerOn";
import {
  useCreateSalesBoosterTriggerMutation,
  useDeleteSalesBoosterTriggerByIdMutation,
  useFetchAllSalesBoosterTriggersMutation,
} from "../../../features/tenant/salesBoosterTrigger/salesBoosterTriggerApi";

const ManageSalesBooster = () => {
  const [
    AddSalesBooster,
    {
      data: addSalesBoosterData,
      error: addSalesBoosterError,
      isLoading: addSalesBoosterisLoading,
    },
  ] = useCreateSalesBoosterMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const [isUpdate, setIsUpdate] = useState(false);

  const [refetchTriggers, setRefetchTriggers] = useState(0);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [name, setName] = useState(location?.state?.name);
  const [startDate, setStartDate] = useState(location?.state?.start_date);
  const [endDate, setEndDate] = useState(location?.state?.end_date);
  const [applicableOn, setApplicableOn] = useState([]);
  const [products, setProducts] = useState(location?.state?.products);

  const [totaltriggerOns, setTotalTriggerOns] = useState([]);

  const [takenTriggers, setTakenTriggers] = useState([]);

  const [
    getAppUserTypes,
    {
      data: appUserTypesData,
      isLoading: appUserTypesIsLoading,
      error: appUserTypesIsError,
    },
  ] = useGetAppUserTypesMutation();

  const [
    GetAllSbTriggers,
    {
      data: sbTriggersData,
      isLoading: sbTriggersisLoading,
      error: sbTriggersError,
    },
  ] = useFetchAllSalesBoosterTriggersMutation();

  const [
    DeleteSbTrigger,
    {
      data: deleteSbTriggerData,
      isLoading: deleteSbTriggerisLoading,
      error: deleteSbTriggerError,
    },
  ] = useDeleteSalesBoosterTriggerByIdMutation();

  useEffect(() => {
    //fetching all sb triggers of sales booster with id
    GetAllSbTriggers({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      id: location.state.id,
    });

    //fetching all user types
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    console.log(location.state);
  }, [refetchTriggers]);

  useEffect(() => {
    if (sbTriggersData) {
      console.log("sb triggers data", sbTriggersData.body);
      const arr = [];
      for (let i = 0; i < sbTriggersData.body.length; i++) {
        const element = sbTriggersData.body[i];
        arr.push(element.trigger_on);
      }
      setTakenTriggers(arr);
    }

    if (sbTriggersError) {
      console.log("sb triggers error ", sbTriggersError);
    }
  }, [sbTriggersData, sbTriggersError]);

  useEffect(() => {
    if (appUserTypesData && location.state) {
      // console.log(appUserTypesData);
      const arr = [];
      for (let i = 0; i < appUserTypesData.body.length; i++) {
        const element = appUserTypesData.body[i];
        if (location.state.user_type.includes(Number(element.user_type_id))) {
          arr.push(element);
        }
      }
      setApplicableOn(arr);
    }
  }, [appUserTypesData]);

  const addNewTrigger = () => {
    const arr = [...totaltriggerOns];
    arr.push({ id: new Date().getMilliseconds() });

    if (arr.length > 1) {
      toast.error("Please save existing trigger to add new.", {
        id: "triggerExceedError",
      });
    } else {
      setTotalTriggerOns(arr);
    }
  };

  const removeTrigger = (idx) => {
    const arr = [...totaltriggerOns];
    arr.splice(idx, 1);
    setTotalTriggerOns(arr);
  };

  const deleteSBTriggerFunc = (id) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                DeleteSbTrigger({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: id,
                });
                GetAllSbTriggers({
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  token: sessionStorage.getItem("access_token"),
                  id: location.state.id,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteSalesBooster",
      },
    );
    return;
  };

  //on submit
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   toast.dismiss();
  //   console.log("------submit-----");
  //   console.log(triggerOptions);

  //   if (!name) {
  //     toast.error("Name is required", {
  //       id: "nameError",
  //     });
  //     return;
  //   }

  //   if (!applicableOn || applicableOn.length <= 0) {
  //     toast.error("Applicable on is required", {
  //       id: "applicableOnError",
  //     });
  //     return;
  //   }

  //   if (!startDate || !endDate) {
  //     toast.error("Start Date and End Date are required", {
  //       id: "dateError",
  //     });
  //     return;
  //   }
  //   //date error check
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);
  //   if (start > end) {
  //     toast.error("End date should be greater than Start date", {
  //       id: "dateError",
  //     });
  //     return;
  //   }

  //   let selectedTriggerOption = null;
  //   let trigger_value = triggerOnValue;
  //   triggerOptions.forEach((each) => {
  //     if (each.checked) {
  //       selectedTriggerOption = each;
  //       //trigger value equal 1 when trigger on is Each Scan
  //       if (each.id === 1) {
  //         trigger_value = 1;
  //       } else {
  //         //trigger value error checks
  //         if (!trigger_value) {
  //           toast.error("Trigger on value is required ", {
  //             id: "triggerOnValueError",
  //           });
  //         } else if (trigger_value <= 0) {
  //           toast.error("Trigger on value should be greater than 0 ", {
  //             id: "triggerOnValueError",
  //           });
  //         }
  //       }
  //     }
  //   });

  //   console.log(selectedTriggerOption, triggerOnValue);
  //   console.log(offerType);

  //   const userTypeIds =  applicableOn.map( (user) => user.user_type_id);

  //   const data = {
  //     name: name,
  //     user_type: userTypeIds,
  //     start_date: startDate,
  //     end_date: endDate,
  //     trigger_on: selectedTriggerOption.name.toLowerCase(),
  //     trigger_value: trigger_value,
  //     offer_type: null,
  //     point: null,
  //     gift_id: null,
  //     coupon: null,
  //     wheel_campaign_id: null,
  //     cashback: null,
  //     point_probability: null,
  //     gift_probability: null,
  //     coupon_probability: null,
  //     wheel_campaign_probability: null,
  //     cashback_probability: null,
  //   };

  //   //if single offer
  //   if (offerType[0].checked) {
  //     data.offer_type = "S";
  //     let selectedOfferOption = null;
  //     offerOptions.map((each) => {
  //       if (each.checked) {
  //         selectedOfferOption = each.name;
  //       }
  //     });
  //     if (selectedOfferOption.toLowerCase() === "point") {
  //       if (!pointValue || pointValue <= 0) {
  //         toast.error("Point value must be greater than 0", {
  //           id: "pointValueError",
  //         });
  //         return;
  //       }
  //       data.point = `${pointType.name.slice(0, 1) + "-" + pointValue}`;
  //     } else if (selectedOfferOption.toLowerCase() === "gift") {
  //       if (selectedGift.id == 0) {
  //         toast.error("Select a gift", {
  //           id: "giftError",
  //         });
  //         return;
  //       }
  //       data.gift_id = selectedGift.id;
  //     } else if (selectedOfferOption.toLowerCase() === "coupon") {
  //       if (selectedCoupon.id == 0) {
  //         toast.error("Select a coupon", {
  //           id: "couponError",
  //         });
  //         return;
  //       }
  //       data.coupon = selectedCoupon.name;
  //     } else if (selectedOfferOption.toLowerCase() === "wheel") {
  //       if (selectedWheel.id == 0) {
  //         toast.error("Select a wheel", {
  //           id: "wheelError",
  //         });
  //         return;
  //       }
  //       data.wheel_campaign_id = selectedWheel.id;
  //     } else if (selectedOfferOption.toLowerCase() === "cashback") {
  //       if (!cashbackValue || cashbackValue <= 0) {
  //         toast.error("Cashback value must be greater than 0", {
  //           id: "cashbackValueError",
  //         });
  //         return;
  //       }
  //       data.cashback = cashbackValue;
  //     }
  //   }
  //   //multiple offer
  //   else {
  //     data.offer_type = "M";
  //     let isError = false;
  //     let totalProbability = 0;
  //     offerOptions.forEach((each) => {
  //       if (each.checked) {
  //         if (each.name.toLowerCase() === "point") {
  //           if (!pointValue || pointValue <= 0) {
  //             toast.error("Point value must be greater than 0", {
  //               id: "pointValueError",
  //             });
  //             isError = true;
  //           }

  //           if (!pointProbability || pointProbability <= 0) {
  //             toast.error("Point probability must be greater than 0", {
  //               id: "pointProbabilityError",
  //             });
  //             isError = true;
  //           }
  //           data.point = `${pointType.name.slice(0, 1) + "-" + pointValue}`;
  //           data.point_probability = pointProbability;
  //           totalProbability += Number(pointProbability);
  //         } else if (each.name.toLowerCase() === "gift") {
  //           if (selectedGift.id == 0) {
  //             toast.error("Select a gift", {
  //               id: "giftError",
  //             });
  //             isError = true;
  //           }
  //           if (!giftProbability || giftProbability <= 0) {
  //             toast.error("Gift probability must be greater than 0", {
  //               id: "giftProbabilityError",
  //             });
  //             isError = true;
  //           }
  //           data.gift_id = selectedGift.id;
  //           data.gift_probability = giftProbability;
  //           totalProbability += Number(giftProbability);
  //         } else if (each.name.toLowerCase() === "coupon") {
  //           if (selectedCoupon.id == 0) {
  //             toast.error("Select a coupon", {
  //               id: "couponError",
  //             });
  //             isError = true;
  //           }
  //           if (!couponProbability || couponProbability <= 0) {
  //             toast.error("Coupon probability must be greater than 0", {
  //               id: "couponProbabilityError",
  //             });
  //             isError = true;
  //           }
  //           data.coupon = selectedCoupon.name;
  //           data.coupon_probability = couponProbability;
  //           totalProbability += Number(couponProbability);
  //         } else if (each.name.toLowerCase() === "wheel") {
  //           if (selectedWheel.id == 0) {
  //             toast.error("Select a wheel", {
  //               id: "wheelError",
  //             });
  //             isError = true;
  //           }
  //           if (!wheelProbability || wheelProbability <= 0) {
  //             toast.error("Wheel probability must be greater than 0", {
  //               id: "wheelProbabilityError",
  //             });
  //             isError = true;
  //           }
  //           data.wheel_campaign_id = selectedWheel.id;
  //           data.wheel_campaign_probability = wheelProbability;
  //           totalProbability += Number(wheelProbability);
  //         } else if (each.name.toLowerCase() === "cashback") {
  //           if (!cashbackValue || cashbackValue <= 0) {
  //             toast.error("Cashback value must be greater than 0", {
  //               id: "cashbackValueError",
  //             });
  //             isError = true;
  //           }
  //           if (!cashbackProbability || cashbackProbability <= 0) {
  //             toast.error("Cashback probability must be greater than 0", {
  //               id: "cashbackProbabilityError",
  //             });
  //             isError = true;
  //           }
  //           data.cashback = cashbackValue;
  //           data.cashback_probability = cashbackProbability;
  //           totalProbability += Number(cashbackProbability);
  //         }
  //       }
  //     });
  //     console.log(totalProbability);
  //     if (totalProbability > 100 || totalProbability <= 0) {
  //       toast.error("Total Probabilty cannot exceed 100 ", {
  //         id: "totalProbabilityError",
  //       });
  //       isError = true;
  //     }
  //     if (isError) {
  //       console.log(isError);
  //       return;
  //     }
  //   }

  //   console.log("Final Data", data);

  //   AddSalesBooster({
  //       token: sessionStorage.getItem("access_token"),
  //       tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //       body : data
  //   })
  // };

  //on reset

  const handleReset = () => {
    setApplicableOn();
    setStartDate(getCurrentDate());
    setEndDate();
    // setOfferOptions(offerOpsObj);
    // setOfferType(offerTypeObj);
    // setTriggerOptions(triggerOptionObj);
    // setTriggerOnValue();
    // setPointType(pointsTypeObj[0]);
  };

  useEffect(() => {
    if (addSalesBoosterData) {
      console.log("success addd salesBooster ", addSalesBoosterData);
      navigate(`/genefied/${getVendorName()}/sales-booster`);
    }

    if (addSalesBoosterError) {
      console.log("error add salesBooster", addSalesBoosterError);
    }
  }, [addSalesBoosterData, addSalesBoosterError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Manage Sales Booster
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className=" w-full bg-white shadow rounded-lg">

<div className="bg-blue-700 rounded-t-lg px-1 py-3">
<ul className="flex justify-center brdergp">
  <li className="text-center">
    <span className="text-white text-md  block">Name</span>
<span className="text-white text-md block font-semibold capitalize">{name}</span></li>

  <li className="text-center">
    <span className="text-white text-md  block">Applicable User Types</span>
  <span className="text-white text-md block font-semibold capitalize">{" "}
                        {appUserTypesData?.body.length>0 ? applicableOn.map((user) => (
                          <span> {user.name} </span>
                        )) : 
                        <div className="w-full flex justify-center items-center">
                          <LoaderIcon/>
                        </div>
                        }</span></li>

{/* <li className="text-center">
    <span className="text-white text-md  block">Selected Products</span>
  <span className="text-white text-md block font-semibold capitalize">{" "}
                        {products.map((p) => (
                          <span> {p} </span>
                        ))}</span></li> */}
  <li className="text-center">
  <span className="text-white text-md  block">Start Date</span>
  <span className="text-white text-md block font-semibold capitalize">{startDate}</span>
  </li>
  <li className="text-center">
  <span className="text-white text-md  block">End Date</span>
<span className="text-white text-md block font-semibold capitalize">{endDate}</span>
  </li>
</ul>
</div>

<div className="w-full p-3">
<div className="">
                      {sbTriggersData &&
                        sbTriggersData.body.map((each, idx) => (
                          <div
                            className=" text-base rounded-md border border-gray-200  mb-2 flex items-center justify-between"
                            key={idx}
                          >
                            
                            {/* {JSON.stringify(each)} */}
                            <div className="w-full flex">
                            <div className="w-1/4 px-3 py-2 border-r border-gray-200">
                             <div className="flex justify-between items-center">
<div className="border-r border-gray-200 pr-3">
<div className=" capitalize mb-1.5">
                                <span className="text-black text-sm font-semibold block">Trigger On&nbsp;&nbsp;:&nbsp;&nbsp;<span className="text-orange-400">{each.trigger_on}</span></span>
                         </div>

                              <div className="capitalize mb-1.5">
                                <span className="text-black text-sm font-semibold block">Trigger Value&nbsp;&nbsp;:&nbsp;&nbsp;<span className="text-orange-400">{each.trigger_value}</span></span>
                               </div>

                              <div className="capitalize mb-1.5">
                                <span className="text-black text-sm font-semibold block">Offer Type&nbsp;&nbsp;:&nbsp;&nbsp;<span className="text-orange-400">{each.offer_type.toLowerCase() === "s" ? "Single" : "Multiple"}</span></span>
                                </div>
  </div>

  <div className="relative flex justify-end">
                              <span
                                className="bg-red-600 text-white inline-flex items-center rounded-sm ml-2 px-1 py-1 text-xs font-medium  ring-1 ring-inset ring-pink-700/10 "
                                onClick={() => deleteSBTriggerFunc(each.id)}
                              >
                                <AiTwotoneDelete className="w-4 h-4" />
                              </span>
                            </div>
                              </div>
                            </div>

                            <div className="w-3/4 bg-gray-50 flex items-center p-3 gap-3 whitespace-nowrap overflow-auto">
                              {each.point && (
                                <div className="border-dashed border-2 w-fit h-fit py-1 px-3 bg-gray-100  rounded-md">
                                  <div className=" ">
                                    Point&nbsp;:&nbsp;{each.point}
                                  </div>
                                  {each.point_probability > 0 && (
                                    <div className=" ">
                                      Point Probability&nbsp;:&nbsp;
                                      {each.point_probability}
                                    </div>
                                  )}
                                </div>
                              )}
                              {each.gift_id && (
                                <div className="border-dashed border-2 w-fit h-fit py-1 px-3 bg-gray-100  rounded-md">
                                  <div className=" ">
                                    Gift&nbsp;:&nbsp;{each.gift_id}
                                  </div>
                                  {each.gift_probability > 0 && (
                                    <div className=" ">
                                      Gift Probability&nbsp;:&nbsp;
                                      {each.gift_probability}
                                    </div>
                                  )}
                                </div>
                              )}
                              {each.coupon && (
                                <div className="border-dashed border-2 w-fit h-fit py-1 px-3 bg-gray-100  rounded-md">
                                  <div className="">Coupon&nbsp;:&nbsp;{each.coupon}</div>
                                  {each.coupon_probability > 0 && (
                                    <div className=" ">
                                      Coupon Probability&nbsp;:&nbsp;
                                      {each.coupon_probability}
                                    </div>
                                  )}
                                </div>
                              )}

                              {each.wheel_campaign_id && (
                                <div className="border-dashed border-2 w-fit h-fit py-1 px-3 bg-gray-100  rounded-md">
                                  <div className=" ">
                                    Wheel&nbsp;:&nbsp;{each.wheel_campaign_id}
                                  </div>
                                  {each.wheel_campaign_probability > 0 && (
                                    <div className=" ">
                                      Wheel Probability&nbsp;:&nbsp; 
                                      {each.wheel_campaign_probability}
                                    </div>
                                  )}
                                </div>
                              )}
                              {each.cashback && (
                                <div className="border-dashed border-2 w-fit h-fit py-1 px-3 bg-gray-100  rounded-md">
                                  <div className=" ">
                                    Cashback&nbsp;:&nbsp;{each.cashback}
                                  </div>
                                  {each.cashback_probability > 0 && (
                                    <div className=" ">
                                      Cashback Probability&nbsp;:&nbsp;
                                      {each.cashback_probability}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div></div>

                          
                          </div>
                        ))}
                    </div>
</div>



           
            </div>

            <div className=" w-full bg-white shadow rounded-lg mt-3">
              <div className="">
                      {totaltriggerOns.map((each, idx) => (
                        <div key={idx} className="px-4 ">
                          <div className="w-full mb-4 flex justify-end pt-3 pb-3 items-center  border-b border-x-gray-100">
                          <h1 className="w-full  text-2xl font-semibold  border-black">
                            Trigger{" "}
                            {sbTriggersData &&
                              sbTriggersData.body.length + idx + 1}
                          </h1>
                            <span
                              className="bg-red-600 text-white inline-flex items-center rounded-sm ml-2 px-1.5 py-1.5 text-xs font-medium  ring-1 ring-inset ring-pink-700/10 "
                              onClick={() => removeTrigger(idx)}
                            >
                              <AiTwotoneDelete className="w-4 h-4" />
                            </span>
                          </div>
                         
                          <TriggerOn
                            name={name}
                            applicableOn={applicableOn}
                            startDate={startDate}
                            endDate={endDate}
                            sb_id={location?.state?.id}
                            removeTrigger={removeTrigger}
                            takenTriggers={takenTriggers}
                            setRefetchTriggers={setRefetchTriggers}
                          />
                        </div>
                      ))}
                    </div>

                  <div className="fixed bottom-0 right-10  mb-6 cursor-pointer">
                    {sbTriggersData &&
                      // sbTriggersData.body.length + totaltriggerOns.length <
                      //   4 &&
                         (
                        <div className="w-full flex justify-end">
                          <button
                            onClick={(e) => addNewTrigger()}
                            className="flex gap-2 text-white focus:outline-none font-medium rounded-lg text-sm px-3 py-2.5 text-center "
                            style={{
                              backgroundColor: `${primaryColor["500"]}`,
                            }}
                          >
                            <svg class="h-5 w-5 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="12" y1="8" x2="12" y2="16" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>Add Trigger
                          </button>
                        </div>
                      )}
                  </div>
                
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSalesBooster;
