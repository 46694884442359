import { rootApi } from "../../api/rootApi";

export const scratchSchemeCouponApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createScratchCoupon: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scratchCouponScheme/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllScratchCoupons: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/scratchCouponScheme?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchScratchCouponById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/scratchCouponScheme/${params.id}&limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateScratchCouponById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/scratchCouponScheme/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    deleteScratchCouponById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/scratchCouponScheme/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateScratchCouponMutation,
  useFetchAllScratchCouponsMutation,
  useFetchScratchCouponByIdMutation,
  useUpdateScratchCouponByIdMutation,
  useDeleteScratchCouponByIdMutation,
} = scratchSchemeCouponApi;
