import React, { useEffect, useState } from "react";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { toast } from "react-hot-toast";
import {
  useAddPGCredentialsMutation,
  useFetchPGCredentialsQuery,
} from "../../../features/tenant/payoutGatewayCredentials/payoutGatewayCredentialsApi";
import { useNavigate } from "react-router-dom";

const paymentGatewayType = [
  {
    id: 1,
    name: "razorpay",
  },
  {
    id: 2,
    name: "cashfree",
  },
  {
    id: 3,
    name: "hatio",
  },
];

const AddPaymentGateway = () => {
  const navigate = useNavigate();
  const [
    addPGCredentials,
    {
      data: addPGCredentialsData,
      isLoading: addPGCredentialsIsLoading,
      isError: addPGCredentialsIsError,
      error: addPGCredentialsError,
    },
  ] = useAddPGCredentialsMutation();

  const {
    data: fetchPGCredentialsData,
    isLoading: fetchPGCredentialsIsLoading,
    isError: fetchPGCredentialsIsError,
    error: fetchPGCredentialsError,
    refetch: fetchPGCredentialsRefetch,
  } = useFetchPGCredentialsQuery({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  const [selectedPGType, setselectedPGType] = useState({
    id: -1,
    name: "select Payment Gateway",
  });

  const [inputClientId, setInputClientId] = useState(null);
  const [inputClientSecret, setInputClientSecret] = useState(null);
  const [inputHatioWalletId, setInputHatioWalletId] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      selectedPGType.id == "" ||
      selectedPGType == "select Payment Gateway" ||
      inputClientSecret == null ||
      inputClientSecret == "" ||
      inputClientId == null ||
      inputClientId == ""
    ) {
      toast.dismiss();
      toast.error("Invalid or missing field", {
        id: "invalid-missing-field",
      });
      return;
    }

    if( selectedPGType.name == "hatio" && !inputHatioWalletId){
      toast.dismiss();
      toast.error("Please enter Wallet ID", {
        id: "invalid-missing-field",
      });
      return;
    }

    addPGCredentials({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        type: selectedPGType.name,
        client_id: inputClientId,
        client_secret: inputClientSecret,
        wallet_id : inputHatioWalletId 
      },
    });
  };

  useEffect(() => {
    if (addPGCredentialsData && addPGCredentialsIsError == false) {
      toast.dismiss();
      toast.success(addPGCredentialsData.message, {
        id: "addPGCredentialsData",
      });
      fetchPGCredentialsRefetch();
      navigate(-1);
    }
  }, [addPGCredentialsData]);

  if (addPGCredentialsIsError) {
    toast.dismiss();
    console.log("addPGCredentialsError", addPGCredentialsError);
    toast.error(addPGCredentialsError.data.message, {
      id: "addPGCredentialsIsError",
    });
  }

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {/* { isUpdate ? "Update" : "Add" } Batch */}
            Add Payment Gateway
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="bg-white shadow rounded-lg p-3">
              <div className="w-full  flex justify-center space-x-3">
                {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                <div className="mb-6 w-1/3 dropds">
                  <SelectComponent
                    title={"Payment Gateway"}
                    selected={selectedPGType}
                    setSelected={setselectedPGType}
                    dataArr={paymentGatewayType ? paymentGatewayType : []}
                  />
                </div>
                <div className="mb-6 w-1/3">
                  <InputComponent
                    name={"Client ID"}
                    type={"text"}
                    value={inputClientId}
                    setValue={setInputClientId}
                    required={true}
                  />
                </div>
                <div className="mb-6 w-1/3">
                  <InputComponent
                    name={"Client Secret"}
                    type={"text"}
                    value={inputClientSecret}
                    setValue={setInputClientSecret}
                    required={true}
                  />
                </div>

                {selectedPGType && selectedPGType.name == "hatio" && (
                  <div className="mb-6 w-1/3">
                    <InputComponent
                      name={"Hatio Wallet ID"}
                      type={"text"}
                      value={inputHatioWalletId}
                      setValue={setInputHatioWalletId}
                      required={true}
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-center mt-2">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaymentGateway;
