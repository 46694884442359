import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import {
  useDeleteSmsTemplateForTenantMutation,
  useGetSmsTemplateWithLimitOffsetForTenantMutation,
  useUpdateSmsTemplateStatusForTenantMutation,
} from "../../../features/tenant/smsTemplate/smsTemplateApi";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

const SMSTemplates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location at SMS template", location);
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const addSMSTemplatePermission = hasPermission("addSmsTemplateSetup");
  const editSMSTemplatePermission = hasPermission("editSmsTemplateSetup");
  const deleteSMSTemplatePermission = hasPermission("deleteSmsTemplateSetup");
  const updateSMSTemplateStatusPermission = hasPermission(
    "updateSmsTemplateSetup"
  );

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    getSmsTemplateWithLimitOffsetForTenant,
    {
      data: getSmsTemplateWithLimitOffsetForTenantData,
      isLoading: getSmsTemplateWithLimitOffsetForTenantIsLoading,
      isError: getSmsTemplateWithLimitOffsetForTenantIsError,
      error: getSmsTemplateWithLimitOffsetForTenantError,
    },
  ] = useGetSmsTemplateWithLimitOffsetForTenantMutation();

  const [
    updateSmsTemplateStatusForTenant,
    {
      data: updateSmsTemplateStatusForTenantData,
      isLoading: updateSmsTemplateStatusForTenantIsLoading,
      isError: updateSmsTemplateStatusForTenantIsError,
      error: updateSmsTemplateStatusForTenantError,
    },
  ] = useUpdateSmsTemplateStatusForTenantMutation();

  const [
    deleteSmsTemplateForTenant,
    {
      data: deleteSmsTemplateForTenantData,
      isLoading: deleteSmsTemplateForTenantIsLoading,
      isError: deleteSmsTemplateForTenantIsError,
      error: deleteSmsTemplateForTenantError,
    },
  ] = useDeleteSmsTemplateForTenantMutation();

  const fetchSMSTemplates = (limit, offset) => {
    getSmsTemplateWithLimitOffsetForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    const viewSMSTemplatesPermission = hasPermission("viewSmsSetup");

    if (!viewSMSTemplatesPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchSMSTemplates(limit, offset);
  }, [limit, offset]);

  useEffect(() => {
    if (getSmsTemplateWithLimitOffsetForTenantData) {
      toast.dismiss();
      toast.success(getSmsTemplateWithLimitOffsetForTenantData.message, {
        id: "getSmsTemplateWithLimitOffsetForTenantData",
      });
    }
    if (getSmsTemplateWithLimitOffsetForTenantError) {
      toast.dismiss();
      toast.error("Oops some error occurred", {
        id: "getSmsTemplateWithLimitOffsetForTenantError",
      });
    }
  }, [
    getSmsTemplateWithLimitOffsetForTenantData,
    getSmsTemplateWithLimitOffsetForTenantError,
  ]);

  if (getSmsTemplateWithLimitOffsetForTenantIsLoading) {
    toast.loading("Loading ...", {
      id: "getSmsTemplateWithLimitOffsetForTenantIsLoading",
    });
  }

  const handleStatusChange = (row) => {
    if (updateSMSTemplateStatusPermission) {
      updateSmsTemplateStatusForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: Number(row.id),
      });
    } else {
      toast.error("You don't have permission to change status", {
        id: "You can not update the status",
      });
    }
  };

  useEffect(() => {
    if (updateSmsTemplateStatusForTenantData) {
      toast.dismiss();
      toast.success(updateSmsTemplateStatusForTenantData.message, {
        id: "updateSmsTemplateStatusForTenantData",
      });
      fetchSMSTemplates(limit, offset);
    }
    if (updateSmsTemplateStatusForTenantError) {
      toast.dismiss();
      toast.error("Oops some error occurred", {
        id: "updateSmsTemplateStatusForTenantError",
      });
    }
  }, [
    updateSmsTemplateStatusForTenantData,
    updateSmsTemplateStatusForTenantError,
  ]);

  if (updateSmsTemplateStatusForTenantIsLoading) {
    toast.loading("Loading ...", {
      id: "updateSmsTemplateStatusForTenantIsLoading",
    });
  }

  const handleEditSMSTemplate = (row) => {
    if (editSMSTemplatePermission) {
      navigate("add", {
        state: {
          account_id: location && location.state.id,
          edit: true,
          data: row,
        },
      });
    } else {
      toast.error("You don't have permission to edit SMS template", {
        id: "You can not edit SMS template",
      });
    }
  };
  const handleDeleteSMSTemplate = (row) => {
    if (deleteSMSTemplatePermission) {
      deleteSmsTemplateForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: Number(row.id),
      });
    } else {
      toast.error("You don't have permission to delete SMS template", {
        id: "You can not detele SMS template",
      });
    }
  };

  useEffect(() => {
    if (deleteSmsTemplateForTenantData) {
      toast.dismiss();
      toast.success(deleteSmsTemplateForTenantData.message, {
        id: "deleteSmsTemplateForTenantData",
      });
      fetchSMSTemplates(limit, offset);
    }
    if (deleteSmsTemplateForTenantError) {
      toast.dismiss();
      toast.error("Oops some error occurred", {
        id: "deleteSmsTemplateForTenantError",
      });
    }
  }, [deleteSmsTemplateForTenantData, deleteSmsTemplateForTenantError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Template Id ",
      cell: (row) => {
        return row.template_id;
      },
      sortable: true,
    },
    {
      name: "Message Template",
      cell: (row) => row.template_text,
      sortable: true,
    },
    {
      name: "type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "sender Id",
      cell: (row) => row.sender_id,
      sortable: true,
    },
    {
      name: "",
      cell: (row) =>
        row.status == "1" ? (
          <>
            {" "}
            <button
              onClick={() => {
                handleStatusChange(row, "2");
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              {/* <AiOutlineEdit className="mr-1" /> */}
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleStatusChange(row, "1");
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            InActive
          </button>
        ),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        // editProductPermission && (
        <div className="">
          <button
            onClick={() => {
              handleEditSMSTemplate(row);
            }}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            edit
          </button>
        </div>
      ),
      // ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) => (
        // deleteProductPermission &&
        <button
          onClick={() => handleDeleteSMSTemplate(row)}
          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        >
          <AiOutlineDelete className="mr-1" />
          Delete
        </button>
      ),
      minWidth: "130px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            SMS Templates
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"SMS Templates List"}
                data={
                  getSmsTemplateWithLimitOffsetForTenantData &&
                  getSmsTemplateWithLimitOffsetForTenantData.body.data
                }
                // loading={productsIsLoading}
                totalRows={
                  getSmsTemplateWithLimitOffsetForTenantData &&
                  getSmsTemplateWithLimitOffsetForTenantData.body.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                
              </DataTableComponent>
              {addSMSTemplatePermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() =>
                            navigate(`add`, {
                              state: {
                                account_id: location && location.state.id,
                              },
                            })
                          }
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMSTemplates;
