import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { getVendorName } from "../../../utils/getVendorName";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useFetchAllCashTransactionsMutation,
  useFetchPayoutStatsMutation,
} from "../../../features/tenant/cashTransactions/cashTransactionsApi";
import { hasPermission } from "../../../utils/hasPermission";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import toast from "react-hot-toast";

const slab = {
  2.8: "0-1000",
  5.5: "1001-10000",
  10: "10001-above",
  1.8: "0-1000",
  3.6: "1001-10000",
  8: "10001-above",
};

const CashTransactionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    start_date: "",
    end_date: currentDate,
    status: "-1",
    mobile: "",
    limit: 10,
    offset: 0,
  };

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [tableDataItems, setTableDataItems] = useState([]);
  const upiSlabValue = {
    "0-1000": {
      pending: 0,
      success: 0,
      extra_charges: 2.8,
      pending_total: 0,
      success_total: 0,
      no_of_transactions_pending: 0,
      no_of_transactions_success: 0,
    },
    "1001-10000": {
      pending: 0,
      success: 0,
      extra_charges: 5.5,
      pending_total: 0,
      success_total: 0,

      no_of_transactions_pending: 0,
      no_of_transactions_success: 0,
    },
    "10000-above": {
      pending: 0,
      success: 0,
      extra_charges: 10,
      pending_total: 0,
      success_total: 0,

      no_of_transactions_pending: 0,
      no_of_transactions_success: 0,
    },
  };
  const bankSlabValue = {
    "0-1000": {
      pending: 0,
      success: 0,
      extra_charges: 1.8,
      pending_total: 0,
      success_total: 0,

      no_of_transactions_pending: 0,
      no_of_transactions_success: 0,
    },
    "1001-10000": {
      pending: 0,
      success: 0,
      extra_charges: 3.6,
      pending_total: 0,
      success_total: 0,

      no_of_transactions_pending: 0,
      no_of_transactions_success: 0,
    },
    "10000-above": {
      pending: 0,
      success: 0,
      extra_charges: 8,
      pending_total: 0,
      success_total: 0,

      no_of_transactions_pending: 0,
      no_of_transactions_success: 0,
    },
  };
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [upiSlab, setUpiSlab] = useState(upiSlabValue);
  const [bankSlab, setBankSlab] = useState(bankSlabValue);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  console.log("startDate", startDate);

  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const [request, setRequest] = useState(initialRequest);
  console.log(request, "dfksaflaldsj");

  const [
    fetchAllCashTransactions,
    {
      data: transactionsData,
      error: transactionsError,
      isLoading: transactionsisLoading,
    },
  ] = useFetchAllCashTransactionsMutation();

  const [
    fetchPayoutStats,
    {
      data: fetchPayoutStatsData,
      error: fetchPayoutStatsError,
      isLoading: fetchPayoutStatsisLoading,
    },
  ] = useFetchPayoutStatsMutation();
  // useFetchPayoutStatsMutation
  const [
    fetchAllCashTransactionsCsv,
    { data: transactionsCSVData, error: transactionsCSVError },
  ] = useFetchAllCashTransactionsMutation();

  const handleFetchAllCashTransactions = () => {
    console.log("request ", request);
    fetchAllCashTransactions({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      query: request,
    });
  };

  const fetchProductsForCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };

    console.log("request csv", request);
    console.log("modifiedRequest", modifiedRequest);

    fetchAllCashTransactionsCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      query: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);

  console.log("fetchPayoutStats :", fetchPayoutStatsData);

  const fetchPayoutStatsFunction = (startDate, endDate) => {
    if (endDate > new Date().toISOString().split("T")[0]) {
      toast.dismiss();
      toast.error("end date should not exceed today date");
      return;
    }

    if (
      startDate &&
      endDate &&
      startDate <= endDate &&
      startDate.length > 0 &&
      endDate.length > 0
    ) {
      fetchPayoutStats({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  useEffect(() => {
    if (transactionsCSVData && transactionsCSVData?.body?.data) {
      setCsvData(transactionsCSVData?.body?.data);
    }

    if (transactionsCSVError) {
      setCsvData(null);
    }
  }, [transactionsCSVData, transactionsCSVError]);

  useEffect(() => {
    if (fetchPayoutStatsData && fetchPayoutStatsData?.body.length > 0) {
      // setShowModal2(true);

      for (let x of fetchPayoutStatsData.body) {
        if (x.transfer_mode == "upi" && upiSlabValue[slab[x.extra_charges]]) {
          if (x.status == "1") {
            upiSlabValue[slab[x.extra_charges]].success = x.sum;
            upiSlabValue[slab[x.extra_charges]].success_total =
              Number(x.sum) +
              upiSlabValue[slab[x.extra_charges]].extra_charges *
                Number(x.upi_1);

            upiSlabValue[slab[x.extra_charges]].no_of_transactions_success =
              Number(x.upi_1);
          } else if (x.status == "2") {
            upiSlabValue[slab[x.extra_charges]].pending = x.sum;
            upiSlabValue[slab[x.extra_charges]].pending_total =
              Number(x.sum) +
              upiSlabValue[slab[x.extra_charges]].extra_charges *
                Number(x.upi_2);

            upiSlabValue[slab[x.extra_charges]].no_of_transactions_pending =
              Number(x.upi_2);
          }
        }

        if (
          x.transfer_mode == "banktransfer" &&
          bankSlabValue[slab[x.extra_charges]]
        ) {
          if (x.status == "1") {
            bankSlabValue[slab[x.extra_charges]].success = x.sum;
            bankSlabValue[slab[x.extra_charges]].success_total =
              Number(x.sum) +
              bankSlabValue[slab[x.extra_charges]].extra_charges *
                Number(x.banktransfer_1);

            bankSlabValue[slab[x.extra_charges]].no_of_transactions_success =
              Number(x.banktransfer_1);
          } else if (x.status == "2") {
            bankSlabValue[slab[x.extra_charges]].pending = x.sum;
            bankSlabValue[slab[x.extra_charges]].pending_total =
              Number(x.sum) +
              bankSlabValue[slab[x.extra_charges]].extra_charges *
                Number(x.banktransfer_2);

            bankSlabValue[slab[x.extra_charges]].no_of_transactions_pending =
              Number(x.banktransfer_2);
          }
        }
      }

      setUpiSlab(upiSlabValue);
      setBankSlab(bankSlabValue);
    }
  }, [fetchPayoutStatsData]);

  console.log("upi slab", upiSlab);
  console.log("bank slab", bankSlab);

  useEffect(() => {
    const hasViewPermission = hasPermission("transactionList");

    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    if (limit != 10 || offset != 0) handleFetchAllCashTransactions();
    // fetchProductsForCsvFunction();
  }, [request]);

  useEffect(() => {
    if (
      transactionsData &&
      transactionsData.body &&
      transactionsData.body.data
    ) {
      console.log("transactionsData  data", transactionsData);
      const arr = [...transactionsData.body.data];
      // arr.sort((a, b) => a.id - b.id);
      setTableDataItems(arr);
      setTotalRows(
        transactionsData && transactionsData.body.count
          ? transactionsData?.body?.count
          : 0
      );
    }

    if (transactionsError) {
      console.log("transactionsData error", transactionsError);
    }
  }, [transactionsData, transactionsError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Mobile  ",
      selector: (row) => row.mobile,
      width: "150px",
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
    },

    {
      name: "Amount - TDS ",
      selector: (row) => row.cash,
      width: "150px",
    },
    {
      name: "Total Amount  ",
      selector: (row) => row.amount_without_tds,
      width: "150px",
    },
    {
      name: "TDS Deducted ",
      selector: (row) => row.tds_deducted,
      width: "150px",
    },
    {
      name: "Remarks  ",
      selector: (row) => row.remarks,
      width: "150px",
    },
    {
      name: "Transfer ID  ",
      selector: (row) => row.transfer_id,
      width: "150px",
    },
    {
      name: "Reference ID ",
      selector: (row) => row.reference_id,
      width: "150px",
    },
    {
      name: "Utr No ",
      selector: (row) => row.utr,
      width: "150px",
    },
    {
      name: "Transfer Mode  ",
      selector: (row) => row.transfer_mode,
      width: "150px",
    },
    {
      name: " bank details ",
      selector: (row) => {
        return (
          <>
            {row.transfer_mode == "upi" && (
              <>
                {" "}
                Beneficiary Name : {row.bene_details.name} <br /> UPI :{" "}
                {row.bene_details.vpa}{" "}
              </>
            )}
            {row.transfer_mode == "banktransfer" && (
              <>
                {" "}
                Beneficiary Name : {row.bene_name} <br /> Account NO :{" "}
                {row.bene_details.bankAccount} <br /> IFSC :{" "}
                {row.bene_details.ifsc}{" "}
              </>
            )}
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Payout Status",
      selector: (row) => {
        if (row.status == "1")
          return <div className=" text-green-600 capitalize ">Success</div>;

        if (row.status == "2")
          return <div className=" text-gray-600  capitalize ">Pending</div>;

        if (row.status == "0")
          return <div className=" text-red-600  capitalize ">Error</div>;
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Payout Response Message",
      selector: (row) => {
        return row.response_message;
      },
      sortable: true,
      width: "250px",
    },
    {
      name: "Action On",
      selector: (row) => new Date(row.action_date).toLocaleString(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Confirmation On",
      selector: (row) => new Date(row.transaction_on).toLocaleString(),
      sortable: true,
      width: "200px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  // console.log("request", request);

  const showPayoutsStatsFunction = () => {
    setShowModal2(true);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Transaction List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="flex gap-2 mb-2 justify-between">
              {/* {transactionsCSVData && transactionsCSVData?.body?.data && (
                <ExportCSV
                  data={transactionsCSVData && transactionsCSVData?.body.data}
                  columnsToExport={[
                    "name",
                    "mobile",
                    "user_type",
                    "cash",
                    "remarks",
                    "transfer_id",
                    "reference_id",
                    "transfer_mode",
                    "bene_details",
                    "status",
                    "response_message",
                    "transaction_on",
                  ]}
                />
              )} */}

              <div>
                <CustomExport
                  fetchData={fetchProductsForCsvFunction}
                  data={csvData}
                  defaultColumnExport={[
                    "name",
                    "mobile",
                    "user_type",
                    "cash",
                    "remarks",
                    "transfer_id",
                    "reference_id",
                    "transfer_mode",
                    "bene_details",
                    "status",
                    "response_message",
                    "transaction_on",
                  ]}
                  filename="cash_transactions"
                />
              </div>

              <button
                onClick={showPayoutsStatsFunction}
                className="btn btn-primary"
              >
                View Stats
              </button>
            </div>

            <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-end">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <input
                        type="number"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile Search"
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          console.log("request ", request);

                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        required
                        value={request.start_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          console.log("request ", request);

                          setRequest((prevState) => {
                            return { ...prevState, start_date: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        required
                        value={request.end_date}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, end_date: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <select
                      id="default-search"
                      className="w-full px-2 text-sm placeholder:text-gray-400 text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Name..."
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // if (newValue != "-1") {
                        // console.log("status alll" , newValue)
                        setRequest((prevState) => {
                          return { ...prevState, status: newValue };
                        });
                        // }
                      }}
                    >
                      <option defa value="-1">
                        all
                      </option>
                      <option value="1">success</option>
                      <option value="2">pending</option>
                      <option value="0">error</option>
                    </select>
                  </div>

                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  p-2"
                        onClick={() => {
                          handleFetchAllCashTransactions();
                          // fetchProductsForCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {transactionsisLoading && <CircleLoaderComponent />}
              <DataTableComponent
                columns={columns}
                data={tableDataItems}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="m-4  bg-white rounded-md  shadow-lg">
                <div className="p-2.5 mt-3 shadow-md">
                  <h2 className="text-2xl font-semibold mb-4">Bank Details</h2>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <p className="text-gray-600 mb-2">
                        Beneficiary ID:{" "}
                        <span className="font-semibold">
                          {modalData.bene_id}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Bank Name:{" "}
                        <span className="font-semibold">
                          {modalData.bene_bank}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Name:{" "}
                        <span className="font-semibold">
                          {modalData.bene_name}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Mobile:{" "}
                        <span className="font-semibold">
                          {modalData.bene_mobile}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Branch:{" "}
                        <span className="font-semibold">
                          {modalData.bene_branch}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Transfer Mode:{" "}
                        <span className="font-semibold">
                          {modalData.transfer_mode}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Details:{" "}
                        <span className="font-semibold">
                          {/* {modalData.bene_details} */}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
        {showModal2 && (
          <ModalComponent
            size={"6xl"}
            show={showModal2}
            setShow={setShowModal2}
          >
            <div className="w-full p-4">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between items-center">
                  <h4 className="text-lg font-semibold">
                    Transaction History Stats
                  </h4>
                  <div className="d-flex justify-end gap-2">
                    <div className="w-52 h-10">
                      <div className="relative searchin">
                        <input
                          type="date"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="Start Date"
                          required
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="w-52 h-10">
                      <div className="relative searchin">
                        <input
                          type="date"
                          id="default-search"
                          className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                          placeholder="End Date"
                          required
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row  gap-2 h-10">
                      <div className="relative searchin">
                        <button
                          className="py-2 btn btn-outline-primary clear-both float-right  p-2"
                          onClick={() => {
                            fetchPayoutStatsFunction(startDate, endDate);
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                          onClick={() => {
                            setStartDate("");
                            setEndDate("");
                            setUpiSlab(upiSlabValue);
                            setBankSlab(bankSlabValue);
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-4">
                {fetchPayoutStatsData &&
                  fetchPayoutStatsData.body.length > 0 && (
                    <table className="table table-b">
                      <tr>
                        <th colSpan={2} className="bg-blue-700 text-white"></th>
                        <th className="bg-blue-700 text-white">
                          Service Charges <br />( per transaction )
                        </th>
                        <th className="bg-blue-700 text-white">
                          No of Success
                          <br /> transactions
                        </th>
                        <th className="bg-blue-700 text-white">
                          Success Amount
                        </th>
                        <th className="bg-blue-700 text-white">
                          Total Success Amount
                        </th>
                        <th className="bg-blue-700 text-white">
                          No of Pending
                          <br />
                          transactions
                        </th>

                        <th className="bg-blue-700 text-white">
                          Pending Amount
                        </th>

                        <th className="bg-blue-700 text-white">
                          Total Pending Amount
                        </th>
                      </tr>

                      <tr>
                        <td
                          rowSpan={3}
                          className="bg-gray-100 text-center border-b-2 border-gray-200 font-semibold"
                        >
                          {" "}
                          UPI
                        </td>
                        <td>0-1000</td>
                        <td>{upiSlab["0-1000"].extra_charges}</td>
                        <td>{upiSlab["0-1000"].no_of_transactions_success}</td>
                        <td>{upiSlab["0-1000"].success}</td>
                        <td>{upiSlab["0-1000"].success_total}</td>
                        <td>{upiSlab["0-1000"].no_of_transactions_pending}</td>
                        <td>{upiSlab["0-1000"].pending}</td>
                        <td>{upiSlab["0-1000"].pending_total}</td>
                      </tr>
                      <tr>
                        <td>1001-10000</td>
                        <td>{upiSlab["1001-10000"].extra_charges}</td>
                        <td>
                          {upiSlab["1001-10000"].no_of_transactions_success}
                        </td>
                        <td>{upiSlab["1001-10000"].success}</td>
                        <td>{upiSlab["1001-10000"].success_total}</td>
                        <td>
                          {upiSlab["1001-10000"].no_of_transactions_pending}
                        </td>
                        <td>{upiSlab["1001-10000"].pending}</td>
                        <td>{upiSlab["1001-10000"].pending_total}</td>
                      </tr>

                      <tr>
                        <td>10001-above</td>
                        <td>{upiSlab["10000-above"].extra_charges}</td>
                        <td>
                          {upiSlab["10000-above"].no_of_transactions_success}
                        </td>
                        <td>{upiSlab["10000-above"].success}</td>
                        <td>{upiSlab["10000-above"].success_total}</td>
                        <td>
                          {upiSlab["10000-above"].no_of_transactions_pending}
                        </td>
                        <td>{upiSlab["10000-above"].pending}</td>
                        <td>{upiSlab["10000-above"].pending_total}</td>
                      </tr>

                      <tr>
                        <td
                          rowSpan={3}
                          className="bg-gray-100 text-center font-semibold"
                        >
                          {" "}
                          BANK
                        </td>
                        <td>0-1000</td>
                        <td>{bankSlab["0-1000"].extra_charges}</td>
                        <td>{bankSlab["0-1000"].no_of_transactions_success}</td>
                        <td>{bankSlab["0-1000"].success}</td>
                        <td>{bankSlab["0-1000"].success_total}</td>
                        <td>{bankSlab["0-1000"].no_of_transactions_pending}</td>
                        <td>{bankSlab["0-1000"].pending}</td>
                        <td>{bankSlab["0-1000"].pending_total}</td>
                      </tr>
                      <tr>
                        <td>1001-10000</td>
                        <td>{bankSlab["1001-10000"].extra_charges}</td>
                        <td>
                          {bankSlab["1001-10000"].no_of_transactions_success}
                        </td>
                        <td>{bankSlab["1001-10000"].success}</td>
                        <td>{bankSlab["1001-10000"].success_total}</td>
                        {bankSlab["1001-10000"].no_of_transactions_pending}
                        <td>{bankSlab["1001-10000"].pending}</td>
                        <td>{bankSlab["1001-10000"].pending_total}</td>
                      </tr>

                      <tr>
                        <td>10001-above</td>
                        <td>{bankSlab["10000-above"].extra_charges}</td>
                        <td>
                          {bankSlab["10000-above"].no_of_transactions_success}
                        </td>
                        <td>{bankSlab["10000-above"].success}</td>
                        <td>{bankSlab["10000-above"].success_total}</td>
                        <td>
                          {bankSlab["10000-above"].no_of_transactions_pending}
                        </td>
                        <td>{bankSlab["10000-above"].pending}</td>
                        <td>{bankSlab["10000-above"].pending_total}</td>
                      </tr>

                      <tr>
                        <td>TOTAL</td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          {Object.values(upiSlab).reduce(
                            (acc, curr) =>
                              acc + Number(curr.no_of_transactions_success),
                            0
                          ) +
                            Object.values(bankSlab).reduce(
                              (acc, curr) =>
                                acc + Number(curr.no_of_transactions_success),
                              0
                            )}
                        </td>
                        <td>
                          {" "}
                          {Object.values(upiSlab).reduce(
                            (acc, curr) => acc + Number(curr.success),
                            0
                          ) +
                            Object.values(bankSlab).reduce(
                              (acc, curr) => acc + Number(curr.success),
                              0
                            )}
                        </td>

                        <td className="font-bold text-xl">
                          {Object.values(upiSlab).reduce(
                            (acc, curr) => acc + Number(curr.success_total),
                            0
                          ) +
                            Object.values(bankSlab).reduce(
                              (acc, curr) => acc + Number(curr.success_total),
                              0
                            )}
                        </td>
                        <td>
                          {" "}
                          {Object.values(upiSlab).reduce(
                            (acc, curr) =>
                              acc + Number(curr.no_of_transactions_pending),
                            0
                          ) +
                            Object.values(bankSlab).reduce(
                              (acc, curr) =>
                                acc + Number(curr.no_of_transactions_pending),
                              0
                            )}
                        </td>
                        <td>
                          {" "}
                          {Object.values(upiSlab).reduce(
                            (acc, curr) => acc + Number(curr.pending),
                            0
                          ) +
                            Object.values(bankSlab).reduce(
                              (acc, curr) => acc + Number(curr.pending),
                              0
                            )}
                        </td>

                        <td className="font-bold text-xl">
                          {Object.values(upiSlab).reduce(
                            (acc, curr) => acc + Number(curr.pending_total),
                            0
                          ) +
                            Object.values(bankSlab).reduce(
                              (acc, curr) => acc + Number(curr.pending_total),
                              0
                            )}
                        </td>

                        <td></td>
                      </tr>

                      {/* {fetchPayoutStatsData &&
                          fetchPayoutStatsData.body.length > 0 &&
                          fetchPayoutStatsData.body.map((x) => {
                            return (
                              <>
                                
                                  <tr>
                                    <td>{x.transfer_mode}</td>
                                    <td>{x.extra_charges}</td>
                                    <td>
                                      {x.status}
                                    </td>
                                    <td>
                                      {x.sum}
                                    </td>
                                  </tr>
  
                              </>
                            );
                          })} */}
                    </table>
                  )}
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default CashTransactionList;
