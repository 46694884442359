import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";

import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import {
  useDeleteSalesBoosterByIdMutation,
  useFetchAllSalesBoosterMutation,
} from "../../../features/tenant/salesBooster/salesBoosterApi";
import { toast } from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { IoIosStats } from "react-icons/io";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import { GrDocumentPdf } from "react-icons/gr";

const SalesBoosterList = () => {
  const addSalesBoosterPermission = hasPermission("addSalesBooster");
  const manageSalesBoosterPermission = hasPermission("manageSalesBooster");
  const deleteSalesBoosterPermission = hasPermission("deleteSalesBooster");

  const [showModal, setShowModal] = useState(false);

  const [selectedSalesBooster, setselectedSalesBooster] = useState();

  const [applicableUserTypes, setApplicableUserTypes] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowModal = (data) => {
    console.log("data", data);
    setselectedSalesBooster(data);
    const allUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
    const arr = [];
    for (let i = 0; i < allUserTypes.length; i++) {
      const element = allUserTypes[i];
      if (data.user_type.includes(Number(element.user_type_id))) {
        arr.push(element);
      }
    }
    setApplicableUserTypes(arr);
    setShowModal(true);
  };

  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "220px",
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Products",
    //   selector: (row) => row.products.map((each) => each + " ,"),
    //   sortable: true,
    //   // width: "120px",
    // },
    {
      name: "Type",
      selector: (row) =>
        row.type == 1
          ? "All Products"
          : row.type == 2
            ? "Classification"
            : "Catgeory-Subcategory",
      sortable: true,
      width: "180px",
    },
    {
      name: "End Date",
      selector: (row) => row.end_date,
      sortable: true,
      width: "120px",
    },
    {
      name: "Image",
      cell: (row) =>
        row?.image && row?.image?.length > 0 && (
          <div className="w-12 h-12 my-2 flex justify-center items-center rounded-full">
            <img
              // style={{ width: "40px", padding: "10px" }}
              src={row.image}
              alt="scheme-images"
              className="rounded-full"
              onClick={() => setShowImageModal(row.image)}
            />
          </div>
        ),
      maxWidth: "150px",
    },
    {
      name: "Pdf",
      cell: (row) =>
        <div>
          <a
            href={row?.pdf}
            target="__blank"
            // onClick={() => {setShowPdf(!showPdf)
            // setCurrentPdf(row.files[0])
            // }}
            className="flex text-center justify-center items-center rounded bg-primary px-1.5
         text-xs  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <GrDocumentPdf className="w-40 h-20" /> &nbsp; &nbsp;{" "}
            <p className="w-full text-xs">View</p>
          </a>
        </div>,
      maxWidth: "150px",
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row.created_at.slice(0, 10),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleShowModal(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          >
            <AiFillEye className="mr-1" />
            View
          </button>
        </div>
      ),
      minWidth: "100px",
    },

    // {
    //   name: "",
    //   cell: (row) => (
    //     <div>
    //       <button
    //          onClick={() =>
    //           navigate("add", {
    //             state: row,
    //           })
    //         }
    //         className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    //       >
    //         <AiFillEye className="mr-1" />
    //         Edit
    //       </button>
    //     </div>
    //   ),
    //   minWidth: "100px",
    // },

    //  {
    //       name: "",
    //       cell: (row) => (
    //         <div>
    //           <button
    //             //  onClick={() =>
    //             //   navigate("add", {
    //             //     state: row,
    //             //   })
    //             // }
    //             className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    //           >
    //             <IoIosStats className="mr-1" />
    //             Stats
    //           </button>
    //         </div>
    //       ),
    //       minWidth: "100px",
    //     },

    {
      name: "",
      selector: (row) =>
        manageSalesBoosterPermission && (
          <div>
            <button
              onClick={() =>
                navigate("manage", {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              Manage
            </button>
          </div>
        ),
      minWidth: "140px",
    },
    {
      name: "",
      selector: (row) =>
        deleteSalesBoosterPermission && (
          <div>
            <button
              onClick={() => handleDelete(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      minWidth: "140px",
    },
  ];

  const navigate = useNavigate();

  const [
    fetchAllSalesBooster,
    {
      data: salesBoosterData,
      isLoading: salesBoosterIsLoading,
      error: salesBoosterError,
    },
  ] = useFetchAllSalesBoosterMutation();

  useEffect(() => {
    toast.dismiss();
    if (salesBoosterError) {
      toast.error("Something went wrong ", {
        id: "salesBoosterError",
      });
    }
  }, [salesBoosterData, salesBoosterError]);

  useEffect(() => {
    toast.dismiss();
    const hasSalesBoosterView = hasPermission("salesBooster");

    if (!hasSalesBoosterView) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchAllSalesBooster({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: 30,
      offset: 0,
    });
  }, []);

  // console.log(salesBoosterData);

  const [
    deleteSalesBooster,
    {
      data: deleteSalesBoosterData,
      isLoading: deleteSalesBoosterIsLoading,
      error: deleteSalesBoosterError,
    },
  ] = useDeleteSalesBoosterByIdMutation();

  useEffect(() => {
    if (deleteSalesBoosterData) {
      toast.success("Deleted Successfully", { id: "deleteSalesBooster" });
      fetchAllSalesBooster({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        limit: 30,
        offset: 0,
      });
    }

    if (deleteSalesBoosterError) {
      toast.error("Something went wrong", {
        id: "deleteSalesBoosterError",
      });
    }
  }, [deleteSalesBoosterData, deleteSalesBoosterError]);

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteSalesBooster({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: data.id,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteSalesBooster",
      },
    );
    return;
  };

  useEffect(() => {
    if (deleteSalesBoosterError) {
      toast.error("Something went wrong", {
        id: "deleteSalesBoosterError",
      });
    }
  }, [deleteSalesBoosterError]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Sales Booster
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={salesBoosterData ? salesBoosterData.body.data : []}
                loading={salesBoosterIsLoading}
              // totalRows={totalRows}
              // handlePerRowsChange={handlePerRowsChange}
              // handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {addSalesBoosterPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>

            {showModal && (
              <ModalComponent
                size={"xl"}
                data={"nadnandand"}
                show={showModal}
                setShow={setShowModal}
              >
                <div className="w-full p-3">
                  <div className="w-full flex justify-center">
                    <div className="w-full mx-4">
                      <h2 className="text-2xl font-bold mb-4">
                        Sales Booster Name : {selectedSalesBooster.name}
                      </h2>
                      <div className="mb-6">
                        <div className="flex flex-col space-y-2">
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">User Type:</p>
                            <p>
                              {applicableUserTypes.map((user) => (
                                <span className="capitalize"> {user.name} </span>
                              ))}
                            </p>
                          </div>
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">Start Date:</p>
                            <p>{selectedSalesBooster.start_date}</p>
                          </div>
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">End Date:</p>
                            <p>{selectedSalesBooster.end_date}</p>
                          </div>
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">Type</p>
                            <p>
                              {selectedSalesBooster.type == 1
                                ? "All Products"
                                : selectedSalesBooster.type == 2
                                  ? "Classification"
                                  : selectedSalesBooster.type == 3 ? "Catgeory-Subcategory" : ""}
                            </p>
                          </div>
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">States:</p>
                            <p>{selectedSalesBooster.states.join(", ")}</p>
                          </div>
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">
                              Products Codes : (Total{" "}
                              {selectedSalesBooster.products.length})
                            </p>
                            <div className="max-h-48 overflow-y-scroll">
                              <ul className="list-disc pl-5">
                                {selectedSalesBooster.products.map(
                                  (product, index) => (
                                    <li key={index}>{product}</li>
                                  ),
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">Status:</p>
                            <p>
                              {selectedSalesBooster.status === "1"
                                ? "Active"
                                : "Inactive"}
                            </p>
                          </div>
                          <div className="bg-gray-100 rounded-lg px-2 py-1">
                            <p className="text-sm font-semibold">Created At:</p>
                            <p>
                              {new Date(
                                selectedSalesBooster.created_at,
                              ).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalComponent>
            )}

            {showImageModal && (
              <ImageModal
                show={showImageModal ? true : false}
                setShow={setShowImageModal}
                size="xl"
                data={showImageModal}
              >
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img
                    src={showImageModal}
                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                  />
                </div>
              </ImageModal>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesBoosterList;
