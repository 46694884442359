import { rootApi } from "../../api/rootApi";

export const bookAppointmentApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllbookAppointment: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/bookAp`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    countAllbookAppointment: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/bookAp/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchbookAppointmentById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/bookAp/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchAllbookAppointmentMutation,
  useCountAllbookAppointmentMutation,
  useFetchbookAppointmentByIdMutation,
} = bookAppointmentApi;
