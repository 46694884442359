import { Listbox, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import { HiArrowsUpDown, HiOutlineCheck } from "react-icons/hi2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SelectComponent = ({
  title,
  selected,
  setSelected,
  dataArr,
  withAvatar,
  required=false,
}) => {
  // console.log("dataArr",dataArr , selected);
  return (
    <>
      {" "}
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
         <Listbox.Label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-0">
              {title} {required ? "*" : ""}{" "}
            </Listbox.Label> 
            <div className="relative searchin">
              <Listbox.Button className="w-full datepd text-sm text-gray-900 shadow-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 h-auto inputp p-2.5">
                <span className="flex items-center">
                  <span className="block capitalize">{selected?.name} </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <HiArrowsUpDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {dataArr.map((data, idx) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-teal-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                      value={data}
                    >
                      {({ selected, active }) => (
                        <>
                        {/* {data.name} */}
                          <div className="flex items-center">
                            {withAvatar && (
                              <img
                                src={data?.avatar}
                                alt=""
                                className="h-5 w-5 mr-3 flex-shrink-0 rounded-full"
                              />
                            )}
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                " block truncate",
                              )}
                            >
                              
                              {data?.name}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-teal-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                              )}
                            >
                              <HiOutlineCheck
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};

export default SelectComponent;
