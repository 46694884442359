import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../../utils/hasPermission";
import { getVendorName } from "../../../../utils/getVendorName";
import { useFetchAllCouponRedemptionsMutation } from "../../../../features/tenant/couponRedemption/couponRedemptionApi";
import ExportCSV from "../../../../components/ExportCsv/ExportCsv";

const RedeemedCouponList = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const initialRequest = {
    brand: "",
    coupon_code: "",
    app_user_name: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };
  const [
    fetchCouponRedemptions,
    {
      data: couponRedemptionsList,
      isLoading: couponRedemptionsIsLoading,
      error: couponRedemptionsError,
    },
  ] = useFetchAllCouponRedemptionsMutation();
  const [
    fetchCouponRedemptionsCsv,
    {
      data: couponRedemptionsCsvList,
      isLoading: couponRedemptionsCsvIsLoading,
      error: couponRedemptionsCsvError,
    },
  ] = useFetchAllCouponRedemptionsMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("redeemedCoupons");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchCouponRedemptionsFunction();
    fetchCouponRedemptionsCsvFunction();
  }, []);
  
  useEffect(() => {
    if (
      request.brand === "" &&
      request.coupon_code === "" &&
      request.app_user_name === ""
    ) {
      fetchCouponRedemptionsFunction();
      fetchCouponRedemptionsCsvFunction();
    }
  }, [request]);

  const fetchCouponRedemptionsFunction = () => {
    fetchCouponRedemptions({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchCouponRedemptionsCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchCouponRedemptionsCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  useEffect(() => {
    if (couponRedemptionsList) {
      console.log(couponRedemptionsList);
    }
  }, [couponRedemptionsList, couponRedemptionsError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "10%",
      textAlign: "left",
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
    },
    {
      name: "Coupon Code",
      selector: (row) => row.coupon_code,
      sortable: true,
    },
    {
      name: "Coupon Type ",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "User ",
      selector: (row) => row.app_user_name,
      sortable: true,
    },
    {
      name: "User Type ",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Reddem Date",
      selector: (row) => row.redeem_date.slice(0, 10),
      sortable: true,
    },
    {
      name: "Expire Date",
      selector: (row) => row.expire_date.slice(0, 10),
      sortable: true,
    },
    // {
    //   name: "Category",
    //   selector: (row) =>
    //     row.category.map((cat) => (
    //       <span className="mr-1 text-gray-600 ">{getCategoryName(cat)}</span>
    //     )),
    //   sortable: true,
    // },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Redeemed Coupons
            <LinksComponent />
          </div>

          <div className="p-3">
            {couponRedemptionsCsvList && couponRedemptionsCsvList.body.data && (
              <ExportCSV
                data={
                  couponRedemptionsCsvList && couponRedemptionsCsvList.body.data
                }
                columnsToExport={[
                  "brand",
                  "coupon_code",
                  "type",
                  "user_type",
                  "redeem_date",
                  "expire_date",
                ]}
              />
            )}
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <div className="flex  space-x-2 justify-end">
                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Brand Name..."
                      required
                      value={request.brand}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, brand: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Coupon Code..."
                      required
                      value={request.coupon_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, coupon_code: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52 h-10">
                  <div className="relative searchin">
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="User Name..."
                      required
                      value={request.app_user_name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, app_user_name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative searchin">
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  p-2"
                      onClick={() => {
                        fetchCouponRedemptionsFunction();
                        fetchCouponRedemptionsCsvFunction();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="relative searchin">
                    <button
                      className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className="">
                <DataTableComponent
                  columns={columns}
                  data={
                    couponRedemptionsList && couponRedemptionsList.body.data
                  }
                  loading={couponRedemptionsIsLoading}
                  totalRows={
                    couponRedemptionsList && couponRedemptionsList?.body?.total
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedeemedCouponList;
