import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import {
  useDeleteScratchCouponByIdMutation,
  useFetchAllScratchCouponsMutation,
} from "../../../features/tenant/scratchableCouponScheme/scratchableCouponSchemeApi";

const ScratchableSchemeList = () => {
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => new Date(row.start_date).toLocaleDateString(),
      sortable: true,
      // width: "120px",
    },
    {
      name: "End Date",
      selector: (row) => new Date(row.end_date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Total Coupon",
      selector: (row) => row.total_coupons,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <button
          onClick={() => handleShowModal(row)}
          className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
        >
          View
        </button>
      ),
      
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("add", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            Manage
          </button>
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => handleDelete(row)}
            className="flex justify-center items-center rounded bg-danger px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [
    fetchAllScratchCouponSchemes,
    { data: scratchCouponSchemeData, error: scratchCouponSchemeError },
  ] = useFetchAllScratchCouponsMutation();

  useEffect(() => {
    // const hasScratchCouponSchemeView = hasPermission("Membership");

    // if (!hasScratchCouponSchemeView) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    fetchAllScratchCouponSchemes({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: 20,
      offset: 0,
    });
  }, []);

  console.log(scratchCouponSchemeData);

  const [
    deleteScratchCouponScheme,
    {
      data: deleteScratchCouponSchemeData,
      error: deleteScratchCouponSchemeError,
    },
  ] = useDeleteScratchCouponByIdMutation();

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteScratchCouponScheme({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: data.id,
                });
                fetchAllScratchCouponSchemes({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  limit: 20,
                  offset: 0,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteScratchCouponScheme",
      },
    );
    return;
  };

  const handleShowModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;



  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Scratchable Coupon Schemes
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={
                  scratchCouponSchemeData
                    ? scratchCouponSchemeData.body.schemes
                    : []
                }
                // loading={loading}
                totalRows={
                  scratchCouponSchemeData
                    ? scratchCouponSchemeData.body.total_schemes
                    : 0
                }
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
              {
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"2xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full rounded-lg">
            <h1 class="bg-blue-700 text-white rounded-t-md p-2.5 text-center text-lg font-semibold">
               {modalData?.name}</h1>

<ul className="grid grid-cols-4 gap-2 p-3">
<li className="w-full bg-lime-200 border-lime-300 rounded-md px-2 py-2 text-center">
  <span className="block font-weight-medium text-gray-800 text-xs">Start Date</span>
  <span className="block font-weight-bold text-gray-800">{new Date(modalData?.start_date).toLocaleDateString()}</span>
</li>

<li className="w-full bg-lime-200 border-lime-300 rounded-md px-2 py-2 text-center">
  <span className="block font-weight-medium text-gray-800 text-xs">End Date</span>
  <span className="block font-weight-bold text-gray-800">{new Date(modalData?.end_date).toLocaleDateString()}</span>
</li>

<li className="w-full bg-lime-200 border-lime-300 rounded-md px-2 py-2 text-center">
  <span className="block font-weight-medium text-gray-800 text-xs">Total Coupons</span>
  <span className="block font-weight-bold text-gray-800">{modalData?.total_coupons}</span>
</li>

<li className="w-full bg-lime-200 border-lime-300 rounded-md px-2 py-2 text-center">
  <span className="block font-weight-medium text-gray-800 text-xs">Slab Value</span>
  <span className="block font-weight-bold text-gray-800">{modalData?.slab_value}</span>
</li>
</ul>


              <div class="my-2">
                <div class=" w-full  mb-3 px-3 ">
                 
                 
                
               

                
                  {/* <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                    Scheme Type : <span class="text-gray-500" >
                       {modalData?.scheme_type}
                      </span>
                  </p> */}
{/* 
                  {modalData?.scheme_type == "FIXED" && (
                    <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                      Slab Value : <span class="text-gray-500" >
                         {modalData?.slab_value}
                        </span>
                    </p>
                  )} */}

                  <div class="text-xs text-black font-semibold lg:leading-5 leading-5 mt-2">
                    Information : <span class="text-gray-500" >
                      {modalData?.information}
                      </span> 
                  </div>

                  <div class="text-xs text-black   font-semibold lg:leading-5 leading-5 mt-2">
                    Terms & Conditions : <span class="text-gray-500" >
                       {modalData?.terms_conditions}
                      </span>
                  </div>
                </div>
              </div>

              <div className="flex justify-center gap-3 mt-2 mb-3 border-t pt-3 border-gray-200">
                <button
                  type=""
                  onClick={() =>
                    navigate("orders", {
                      state: modalData,
                    })
                  }
                  className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center "
                >
                  View Purchase Orders
                </button>

                <button
                  type=""
                  onClick={() =>
                    navigate("rewards", {
                      state: modalData,
                    })
                  }
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center "
                >
                  View Reward List
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default ScratchableSchemeList;
