import { rootApi } from "../../api/rootApi";

export const giftApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllBrands: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/brand?limit=${params.limit}&offset=${params.offset}${
            params.brandName ? `&brandName=${params.brandName}` : ""
          }`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchBrandById: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/brand/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addBrand: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/brand/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateBrand: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/brand/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteBrand: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/brand/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchAllBrandsMutation,
  useFetchBrandByIdQuery,
  useAddBrandMutation,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
} = giftApi;
