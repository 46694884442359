import { rootApi } from "../../api/rootApi";

export const leaderBoardApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchLeaderBoard: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/leaderBoard/?limit=${params.limit}&offset=${params.offset}&type=${params.type}&startDate=${params.startDate}&endDate=${params.endDate}&userType=${params.userType}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchScanLeaderBoardByUserTypeId: builder.mutation({
      query: (params) => {
        console.log("params fetchScanLeaderBoardByUserTypeId", params)
        return {
          method: "GET",
          url: `/tenant/leaderBoard?limit=${params.limit}&offset=${
            params.offset
          }${params.userType ? `&userType=${params.userType}` : ""}${
            params.type ? `&type=${params.type}` : ""
          }${params.startDate ? `&startDate=${params.startDate}` : ""}${
            params.endDate ? `&endDate=${params.endDate}` : ""
          }${params.zone_id ? `&zone_id=${params.zone_id}` : ""}${params.state ? `&state=${params.state}` : ""}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchLeaderBoardMutation,
  useFetchScanLeaderBoardByUserTypeIdMutation,
} = leaderBoardApi;
