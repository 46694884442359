import { rootApi } from "../../api/rootApi";

export const ordersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    bulkInsertOrders: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/order/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllOrderBatches: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/order`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),


    fetchOrderByBatchId: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/order/batch/${params.batch_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

   
  }),
});

export const {
 useBulkInsertOrdersMutation,
 useFetchAllOrderBatchesMutation,
 useFetchOrderByBatchIdMutation
} = ordersApi;
