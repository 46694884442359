import { rootApi } from "../../api/rootApi";

export const jqrSportsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    bulkImportQrs: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/tenant/jqr-sports/qr-mapping",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),


    userPointHistoryForJqr: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/tenant/jqr-sports/point-history",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),

    
  }),
});

export const {
 useBulkImportQrsMutation,
 useUserPointHistoryForJqrMutation
} = jqrSportsApi;
