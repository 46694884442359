import { rootApi } from "../../api/rootApi";

export const membershipDetailsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createMembershipDetails: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/membershipDetails/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchMembershipByMembershipId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/membershipDetails/${params.id}&limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateMembershipDetails: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/membershipDetails/batchUpdate`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useCreateMembershipDetailsMutation,
  useFetchMembershipByMembershipIdMutation,
  useUpdateMembershipDetailsMutation,
} = membershipDetailsApi;
