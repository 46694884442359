import { rootApi } from "../../api/rootApi";

export const redeemedDreamGiftsStatusApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRedeemedDreamGiftsStatus: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/redeemedDreamGiftsStatus/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body,
        };
      },
    }),
    getAllDreamGiftsCheckout: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/redeemedDreamGiftsStatus/?limit=${params.limit}&offset=${params.offset}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
  }),
});

export const {
 useUpdateRedeemedDreamGiftsStatusMutation,
 useGetAllDreamGiftsCheckoutMutation
} = redeemedDreamGiftsStatusApi;
