export function findMatchingStates(statesData, stateIds) {
    const matchingStates = [];
    for (const idObj of stateIds) {
        const stateId = idObj.state_id;
        const state = statesData.find(state => state.id === stateId);
        if (state) {
            matchingStates.push(state);
        }
    }
    return matchingStates;
}