import React, { useEffect, useMemo, useState } from "react";
import { Menu } from "@headlessui/react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useBulkInsertProductMutation,
  useBulkUpdateProductMutation,
  useChangeProductPointStatusByIdMutation,
  useCountProductsMutation,
  useDeletedProductByIdMutation,
  useDisableProductByIdMutation,
  useFetchProductsMutation,
  useUpdateProductImagesMutation,
} from "../../../features/tenant/products/productsApi";
import { toast } from "react-hot-toast";
import ProductModal from "../../../components/Tenant/ProductModal/ProductModal";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useFetchAllBrandsMutation } from "../../../features/tenant/brands/brandsApi";
import { useFetchSubcategoriesMutation } from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { mergeAndRemove } from "../../../utils/mergeAndRemove";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import { downloadSampleCSV } from "../../../utils/downloadSampleCsv";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";
import CustomExport from "../../../components/CustomExport/CustomExport";
const productStatus = ["deleted", "active", "inactive"];

const ProductList = () => {
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState();
  const [showModal3, setShowModal3] = useState(false);

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [
    fetchProducts,
    {
      data: productsData,
      isLoading: productsIsLoading,
      isError: productsIsError,
      error: productsError,
    },
  ] = useFetchProductsMutation();

  const [
    fetchProductsForCsv,
    {
      data: productsForCsvData,
      isLoading: productsForCsvIsLoading,
      isError: productsForCsvIsError,
      error: productsForCsvError,
    },
  ] = useFetchProductsMutation();

  const [
    fetchAllProducts,
    {
      data: allProductsData,
      isLoading: allProductsIsLoading,
      isError: allProductsIsError,
      error: allProductsError,
    },
  ] = useFetchProductsMutation();

  const [
    countAllProducts,
    {
      data: countallProductsData,
      isLoading: countallProductsIsLoading,
      isError: countallProductsIsError,
      error: countallProductsError,
    },
  ] = useCountProductsMutation();

  const [
    deletedProductById,
    {
      data: deleteProduct,
      isLoading: deleteProductIsLoading,
      isError: deleteProductIsError,
      error: deleteProductError,
    },
  ] = useDeletedProductByIdMutation();

  const [
    disableProductById,
    {
      data: disableProduct,
      isLoading: disableProductIsLoading,
      isError: disableProductIsError,
      error: disableProductError,
    },
  ] = useDisableProductByIdMutation();

  const [updatefile, setUpdateFile] = useState();


  const [file, setFile] = useState();
  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  const handleOpenModal = (url) => {
    setCurrentUrl(getId(url));
    setShowModal3(true);
  };

  const [selectedBrandName, setSelectedBrandName] = useState({
    id: -1,
    name: "",
  });

  const [selectedProductCategory, setSelectedProductCategory] = useState({
    id: -1,
    name: "",
  });
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };


  const handleBulkUpdateOnChange = (e) => {
    setUpdateFile(e.target.files[0]);
  };

  const [
    bulkInsertProduct,
    {
      data: addProductsData,
      isLoading: addProductIsLoading,
      error: addProductError,
    },
  ] = useBulkInsertProductMutation();


  const [
    bulkUpdateProduct,
    {
      data: updateProductsData,
      isLoading: updateProductsIsLoading,
      error: updateProductsError,
    },
  ] = useBulkUpdateProductMutation();

  // function parseCSV(string) {
  //   const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
  //   const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

  //   console.log("--------->", csvHeader[csvHeader.length - 1]);

  //   console.log("csv header: ", csvHeader);

  //   const array = csvRows.map((i) => {
  //     const values = i.split(",");
  //     const obj = csvHeader.reduce((object, header, index) => {
  //       object[header] = values[index];
  //       return object;
  //     }, {});
  //     return obj;
  //   });

  //   return array;
  // }

  function parseCSV(string) {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .replace(/\s/g, "")
      .split(",");

    const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);

    // console.log("csvRows: ", csvRows.length, csvRows);

    const array = csvRows
      .map((row) => {
        const values = [];
        let insideQuotes = false;
        let currentField = "";

        for (let char of row) {
          if (char === "," && !insideQuotes) {
            values.push(currentField.trim());
            currentField = "";
          } else if (char === '"') {
            insideQuotes = !insideQuotes;
          } else {
            currentField += char;
          }
        }

        values.push(currentField.trim());
        return values;
      })
      .filter((values) => values.length === csvHeader.length)
      .map((values) => {
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index].trim();
          return object;
        }, {});
        return obj;
      });

    // console.log("array", array);
    return array;
  }

  useEffect(() => {
    if (addProductsData) {
      // console.log("addProductsData", addProductsData);
      toast(addProductsData?.message ? addProductsData.message : "Added Successfully");
      return;
    }
    if (addProductError) {
      toast.error("Error in Adding Product");
    }
  }, [addProductsData, addProductError]);

  const [fetchBrandList, { data: brandsData, error: brandsError }] =
    useFetchAllBrandsMutation();

  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  useEffect(() => {
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    fetchBrandList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);


  const handleOnUpdate = (e) => {
    if (e) {
      e.preventDefault();
    }


    if (!updatefile) {
      toast.error("Please select update file.");
      return;
    }

   
      const fileReader = new FileReader();
      if (updatefile) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          let datas = parseCSV(text);

          const skip = [];
          const updateData = [];
          datas.map((dataItem) => {
            console.log("data item ", dataItem)
            if(Object.keys(dataItem).includes("product_id")){
              if(isNaN(dataItem.product_id) || dataItem.product_id == undefined || dataItem.product_id == ""){
                skip.push(dataItem)
              }else { 
                const updatedDataItem = {
                  ...dataItem,
                };
                updateData.push(updatedDataItem);
              }
            }
          });

          // console.log("Bulk Update", updateData);
          handleBulkUdateProductFunc(updateData, skip);
        };
        fileReader.readAsText(updatefile);
      }
  };


  const handleBulkUdateProductFunc = (data, skip) => {
    if (
      window.confirm(`Confirm skipping ${skip.length} entries and Continue updating ${data.length} entries?`)
    ) {
      bulkUpdateProduct({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          qty: data.length,
          products: data,
        },
      });
      setShowBulkUpdateModal(false);
    }
    return;
  };

  useEffect(() => {
    if (updateProductsData && updateProductsData.body) {
      console.log('updateProductsData', updateProductsData.body);

      toast.success(`${updateProductsData.body.success.length} products updated successfully `);
      return;
    }
    if (updateProductsError) {
      console.log("updateProductsError", updateProductsError);
      toast.error("Error in Updating Products");
    }
  }, [updateProductsData, updateProductsError]);


  useEffect(() => {
    if (allProductsData?.body?.products) {
      handleOnSubmit();

    }
  }, [allProductsData]);

  const handleOnSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    let pointPerc = JSON.parse(
      sessionStorage.getItem("vendorData"),
    ).points_sharing;

    let percentage = 0;

    if (!pointPerc?.flat_points) {

      percentage = parseFloat(pointPerc?.percentage_points_value??0);
    }

    console.log(pointPerc, "percents data", percentage);

    if (selectedBrandName.id == -1) {
      toast.error("Please select a brand");
      return;
    }

    if (!file) {
      toast.error("Please select a file");
      return;
    }

    if (allProductsData?.body?.products) {
      const fileReader = new FileReader();
      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          let datas = parseCSV(text);
          datas = datas.map((dataItem) => {
            // const categoryId = getSubcategoryId(dataItem.category_name, subCategoriesData);

            const updatedDataItem = {
              ...dataItem,
              brand_id: selectedBrandName.id,
              brand_name: selectedBrandName.name,
              // category_id: categoryId,

            };

            if (dataItem.mrp) {
              updatedDataItem.mrp = +dataItem.mrp;
              for (let index = 0; index < appUserTypes.length; index++) {
                const element = appUserTypes[index];
                if(percentage){
                  updatedDataItem[`${element.user_type}_points`] =
                  (+dataItem.mrp * percentage) / 100;
                }else {
                  updatedDataItem[`${element.user_type}_points`] = dataItem[`${element.user_type}_points`]
                }
              }
            }

            return updatedDataItem;
          });
          let { count, data } = mergeAndRemove(
            allProductsData?.body?.products,
            datas,
            "product_code",
          );
          console.log(count, data, "Count Here");
          handleBulkUploadProducts(count, data);
        };
        fileReader.readAsText(file);
      }
    } else {
      fetchAllProducts({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  };


  function getSubcategoryId(categoryName, subcategories) {
    const matchingSubcategory = subcategories.body.find(subcat => subcat.name === categoryName);
    return matchingSubcategory ? matchingSubcategory.id : null;
}




  const handleBulkUploadProducts = (count, data) => {
    if (
      window.confirm(`Confirm Skipping ${count} Existing Entries and Continue?`)
    ) {
      bulkInsertProduct({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          qty: data.length,
          products: data,
        },
      });
      setShowModal2(false);
    }
    return;
  };

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const initialRequest = {
    product_code: "",
    name: "",
    brand_name: "",
    category_name: "",
    points_active: "",
    classification: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    console.log("request", request);
    if (
      request.product_code === "" &&
      request.name === "" &&
      request.brand_name === "" &&
      request.category_name === "" &&
      request.classification === ""
    ) {
      fetchProductsFunction();
      countProductsFunction();
      // fetchProductsForCsvFunction();
    }
  }, [request]);
  const countProductsFunction = () => {
    countAllProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  useEffect(() => {
    const hasViewPermission = hasPermission("product");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    fetchProductsFunction();
    // fetchProductsForCsvFunction();
  }, [limit, offset]);

  const fetchProductsFunction = () => {
    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchProductsForCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchProductsForCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const handleEditProduct = (product) => {
    navigate(`edit/${product.product_id}`, {
      state: {
        product: product,
      },
    });
  };

  const handleDeleteProduct = (product) => {
    if (window.confirm("are you sure you want to delete this product")) {
      deletedProductById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        product_id: product.product_id,
      });

      fetchProductsFunction();
    }
    return;
  };

  const handleDisableProduct = (product) => {
    disableProductById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      product_id: product.product_id,
    });
    return;
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (productsForCsvData && productsForCsvData?.body?.products) {
      setCsvData(productsForCsvData?.body?.products);
    }

    if (productsForCsvError) {
      setCsvData(null);
    }
  }, [productsForCsvData, productsForCsvError])


  const addProductPermission = hasPermission("addProduct");
  const editProductPermission = hasPermission("editProduct");
  const deleteProductPermission = hasPermission("deleteProduct");

  useMemo(() => {
    if (deleteProduct) {
      toast.dismiss();
      toast.success("Product Deleted Successfully", {
        id: "Product Deleted Successfully",
      });
    }
    if (deleteProductError) {
      toast.dismiss();
      toast.error("Something went wrong", {
        id: "delete-product-error",
      });
    }
  }, [deleteProductIsError, , deleteProductError, deleteProduct]);

  useMemo(() => {
    if (disableProduct) {
      toast.success("Status Updated Successfully", {
        id: "Product Deleted Successfully",
      });
      fetchProductsFunction();
    }
    if (disableProductError) {
      toast.error("Something went wrong", {
        id: "delete-product-error",
      });
    }
  }, [disableProductIsError, , disableProductError, disableProduct]);

  // disableProduct

  if (deleteProductIsError) {
    toast.dismiss();
    toast.error(`${productsError.data.message}`, {
      id: "fetch-product-error",
    });
  }

  console.log("object", productsIsLoading);

  if (productsIsLoading) {
    toast.dismiss();
  }

  if (deleteProductIsLoading) {
    toast.dismiss();
    toast.loading(`loading ....`, {
      id: "loading-delete-product",
    });
  }

  if (deleteProduct) {
    toast.dismiss();
  }

  // if (productsData) {
  //   toast.dismiss();
  // }

  useEffect(() => {
    if (productsData && productsData.body) {
      toast.dismiss();
      console.log("productsData", productsData);
      setMyList(productsData.body.products ? productsData.body.products : []);


    }
  }, [productsData])

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);


  const [showImageModal, setShowImageModal] = useState(false);

  const [productData, setProductData] = useState(null);

  const handleShowQr = (data) => {
    setProductData(data);
    setShowModal(true);
  };
  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Brand name",
      cell: (row) => row.brand_name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Product name",
      cell: (row) => row.name,
      sortable: true,
      width: "150px",

    },
    {
      name: "Product code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "150px",

    },
    {
      name: "Sub Category ",
      cell: (row) => row.category_name,
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Parent Category ",
    //   cell: (row) => row.parent_category_name,
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Parent Category ",
      cell: (row) => row.parent_category_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "MRP",
      cell: (row) => row?.mrp,
      sortable: true,
      width: "120px",
    },
    {
      name: "Classification",
      cell: (row) => row?.classification,
      sortable: true,
      width: "220",
    },
    {
      name: "Color",
      cell: (row) => row?.color,
      sortable: true,
      width: "120px",
    },
    {
      name: "Size",
      cell: (row) => row?.size,
      sortable: true,
      width: "120px",
    },
    {
      name: "Video",
      cell: (row) => (
        <>
          {row?.video && row?.video?.length ? (
            <button
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
              onClick={() => handleOpenModal(row?.video ? row?.video : "")}
            >
              Video
            </button>
          ) : (
            "N/A"
          )}
        </>
      ),
      sortable: true,
      width: "80px",
    },
    {
      name: "Unit",
      cell: (row) => row.unit_name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Uom Key",
      cell: (row) => row?.uom_key,
      sortable: true,
      width: "120px",
    },
    {
      name: "Uom Value",
      cell: (row) => row?.uom_value,
      sortable: true,
      width: "120px",
    },
    {
      name: "Qty",
      cell: (row) => row.qty,
      sortable: true,
      width: "80px",
    },
    {
      name: "Points Active",
      cell: (r) =>
        r.points_active === "2" ? (
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => handleUpdateProductPointsStatus(r)}
          >
            In-Active
          </button>
        ) : (
          <button
            className="btn btn-outline-success btn-sm"
            onClick={() => handleUpdateProductPointsStatus(r)}
          >
            Active
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },

    {
      name: "Image",
      cell: (row) => (
        <div className="w-12 h-12 my-2 rounded-full flex justify-center items-center">
          {row.images && row.images[0]?.length > 0 ? (
            <img
              src={row.images[0]}
              alt={row.images[0]}
              className="rounded-full"
              onClick={() =>
                setShowImageModal(
                  row.images[0],
                )
              }
            />
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      name: "Status",
      cell: (r) =>
        r.status === "2" ? (
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => handleDisableProduct(r)}
          >
            In-Active
          </button>
        ) : (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleDisableProduct(r)}
          >
            Active
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
          onClick={() => handleShowQr(row)}
        >
          View
        </button>
      ),
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) =>
        editProductPermission && (
          <div className="">
            <button
              onClick={() => handleEditProduct(row)}
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              edit
            </button>
          </div>
        ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) =>
        deleteProductPermission && (
          <button
            onClick={() => handleDeleteProduct(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        ),
      minWidth: "100px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [
    chnageProductPointStatusById,
    {
      data: changeProductPointStatusData,
      isLoading: changeProductPointStatusIsLoading,
      error: changeProductPointStatusError,
    },
  ] = useChangeProductPointStatusByIdMutation();

  const handleUpdateProductPointsStatus = (product) => {
    chnageProductPointStatusById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      product_id: product.product_id,
    });
    fetchProductsFunction();

  };

  useEffect(() => {
    if (changeProductPointStatusData) {
      console.log("changeProductPointStatusData", changeProductPointStatusData);
      if (changeProductPointStatusData.body) {
        toast.success("Point Status updated successfully", { id: "changeProductPointStatusData" })
      }
    }
    if (changeProductPointStatusError) {
      console.log("changeProductPointStatusError", changeProductPointStatusError);
      toast.error("Something went wrong", { id: "changeProductPointStatusError" })
    }
  }, [changeProductPointStatusData, changeProductPointStatusError])


  const [
    updateProductImagesFunc,
    {
      data: updateProductImagesData,
      error: updateProductImagesError,
      isLoading: updateProductImagesIslOading,
    },
  ] = useUpdateProductImagesMutation();

  const handleUpdateProductImages = (e) => {
    e.preventDefault();

    updateProductImagesFunc({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    let t;
    if (updateProductImagesData) {
      t = toast.success("Updated Product Images.", {
        id: "updateProductImagesData",
      });
      console.log("updateProductImagesData", updateProductImagesData);
    }

    if (updateProductImagesError) {
      console.log("updateProductImagesError", updateProductImagesError);
      if (updateProductImagesError.status == 404) {
        t = toast.error("No Products Found", {
          id: "updateProductImagesError",
        });
      } else {
        t = toast.error("Something went wrong", {
          id: "updateProductImagesError",
        });
      }
    }

    if (updateProductImagesIslOading) {
      console.log("updateProductImagesIslOading", updateProductImagesIslOading);
      t = toast.loading("Updating Product Images.....", {
        id: "updateProductImagesIslOading",
      });
    }
  }, [
    updateProductImagesData,
    updateProductImagesError,
    updateProductImagesIslOading,
  ]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Product List
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="flex gap-2">
              {/* {productsForCsvData && productsForCsvData?.body?.products && (
                <ExportCSV
                  data={
                    productsForCsvData && productsForCsvData?.body?.products
                  }
                  columnsToExport={[
                    "brand_name",
                    "name",
                    "product_code",
                    "unit_name",
                    "qty",
                  ]}
                />
              )} */}

              <div>
                <CustomExport fetchData={fetchProductsForCsvFunction} data={csvData} defaultColumnExport={[
                  "brand_name",
                  "name",
                  "product_code",
                  "unit_name",
                  "qty",
                ]}
                  filename="product_list"
                />
              </div>

              <button
                className="btn btn-outline-primary mb-2"
                onClick={handleUpdateProductImages}
              >
                Update Product Images
              </button>

              <button
                className="btn btn-outline-primary clear-both   mb-2"
                onClick={() => {
                  setShowModal2(true);
                }}
              >
                Add Product Csv
              </button>


              <button
                className="btn btn-outline-primary clear-both   mb-2"
                onClick={() => {
                  setShowBulkUpdateModal(true);
                }}
              >
                Bulk Update Product
              </button>
            </div>
            <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">B-Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Brand Name..."
                        required
                        value={request.brand_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, brand_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">C-Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Category Name..."
                        required
                        value={request.category_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, category_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code ..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">P-Classification</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Classification..."
                        required
                        value={request.classification}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, classification: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">points</label>
                      <select
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        name="points active"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, points_active: newValue };
                          });
                        }}
                        id=""
                      >
                        <option selected value="">All</option>
                        <option value="1">
                          Points Active
                        </option>
                        <option value="2">Points Inactive</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                        onClick={() => {
                          fetchProductsFunction();
                          // fetchProductsForCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {productsIsLoading && <CircleLoaderComponent />}
              <DataTableComponent
                columns={columns}
                data={myList}
                loading={productsIsLoading}
                totalRows={countallProductsData?.body?.total_products}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              {addProductPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <ProductModal
            data={productData}
            show={showModal}
            setShow={setShowModal}
          />
        )}

        {showModal2 && (
          <ModalComponent show={showModal2} setShow={setShowModal2} size="md">
            <div className="w-full p-4">
              {
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() =>
                      downloadSampleCSV([
                        {
                          name: "category_id",
                        },
                        {
                          name: "category_name",
                        },
                        {
                          name: "name"
                        },
                        {
                          name: "description",
                        },
                        {
                          name: "product_code",
                        },
                        {
                          name: "mrp",
                        },
                        {
                          name: "size",
                        },
                        {
                          name: "color",
                        },
                      ])
                    }
                  >
                    Sample Csv Download
                  </button>
                </div>
              }
              <div className="w-full m-auto  justify-center ">
                <div className="flex gap-1 space-x-3">
                  <div className="pb-1  bottom-2 w-full mb-2">
                    <SelectComponentWithSearch
                      title={"Brand Name"}
                      selected={selectedBrandName}
                      setSelected={setSelectedBrandName}
                      dataArr={brandsData?.body?.brands || []}
                      placeholder="Select Brand"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnChange}
                    />
                  </label>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleOnSubmit()}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}


        {showBulkUpdateModal && (
          <ModalComponent show={showBulkUpdateModal} setShow={setShowBulkUpdateModal} size="md">
            <div className="w-full p-4">
              {
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() =>
                      downloadSampleCSV([
                        {
                          name: "product_id",
                        },
                        {
                          name: "name"
                        },
                        {
                          name: "description",
                        },
                        {
                          name: "product_code",
                        },
                        {
                          name: "mrp",
                        },
                        {
                          name: "size",
                        },
                        {
                          name: "color",
                        },
                      ])
                    }
                  >
                    Sample Csv Download
                  </button>
                </div>
              }
              <div className="w-full m-auto  justify-center ">
                <div className="flex items-center mt-4 justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleBulkUpdateOnChange}
                    />
                  </label>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleOnUpdate()}
                >
                  Update
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}

        {showModal3 && (
          <ModalComponent
            size={"3xl"}
            data={"nadnandand"}
            show={showModal3}
            setShow={setShowModal3}
          >
            <div className="w-full">
              <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only"> Video</h2>

                <div className="w-full">
                  <iframe
                    className="m-auto mt-5 w-full"
                    src={"//www.youtube.com/embed/" + currentUrl}
                    frameborder="0"
                    height={400}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
                  ></iframe>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default ProductList;
