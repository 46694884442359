import React, { useEffect, useRef } from "react";
import {
  useFetchVendorsThemeQuery,
  useUpdateVendorThemeMutation,
} from "../../../features/admin/vendorTheme/vendorThemeApi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import tailwindColor from "../../../../node_modules/tailwindcss/colors";

const fontFamily = ["Helvetica", "Arial", "sans-serif"];
const colorList = [
  "red",
  "green",
  "yellow",
  "blue",
  "cyan",
  "indigo",
  "orange",
  "violet",
  "amber",
  "fuchsia",
  "purple",
  "lime",
  "emerald",
  "slate",
  "rose",
  "teal",
  "stone"
];

const AddVendorTheme = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("tailwindColor",tailwindColor);
  // const {
  //   data: vendorsData,
  //   isError: vendorsIsError,
  //   isLoading: vendorsIsLoading,
  //   // error: vendorsError,
  // } = useFetchVendorsQuery(sessionStorage.getItem("access_token")) || {};

  const { refetch: vendorsThemeRefetch } =
    useFetchVendorsThemeQuery(sessionStorage.getItem("access_token")) || {};

  // const [
  //   addVendorTheme,
  //   {
  //     data: vendorThemeData,
  //     isLoading: vendorThemeIsLoading,
  //     isError: vendorThemeIsError,
  //     error: vendorThemeError,
  //   },
  // ] = useAddVendorThemeMutation();

  const [
    updateVendorTheme,
    {
      data: updateVendorThemeData,
      isLoading: updateVendorThemeIsLoading,
      isError: updateVendorThemeIsError,
      error: updateVendorThemeError,
    },
  ] = useUpdateVendorThemeMutation();

  const selectVendor = useRef(
    location.state !== null ? location.state.tenant_name : -1,
  );
  const selectColor = useRef(
    location.state !== null ? location.state.theme.primary_color : "#000000",
  );
  const selectFontFamily = useRef(
    location.state !== null ? location.state.theme.font : "sans-serif",
  );

  useEffect(() => {
    console.log("location", location.state);
    if (location.state) {
      selectVendor.current.value = location.state.tenant_name;
      selectColor.current.value = location.state.theme.primary_color;
      selectFontFamily.current.value = location.state.theme.font;
    } else {
      selectFontFamily.current.value = "sans-serif";
      selectColor.current.value = "red";
      selectVendor.current.value = -1;
    }
  }, []);

  // const AddVendorTheme = (e) => {
  //   e.preventDefault();
  //   const color = selectColor.current.value;
  //   const fontFamily = selectFontFamily.current.value;
  //   if (Number(selectVendor.current.value) !== -1) {
  //     const vendorUser = vendorsData.body[selectVendor.current.value];

  //     addVendorTheme({
  //       token: sessionStorage.getItem("access_token"),
  //       body: {
  //         tenant_id: Number(vendorUser.tenant_id),
  //         tenant_name: vendorUser.name,
  //         theme: {
  //           primary_color: color,
  //           font: fontFamily,
  //         },
  //       },
  //     });
  //   }
  //   if (Number(selectVendor.current.value) === -1) {
  //     toast.error("select vendor", {
  //       id: "selectVendorError",
  //     });
  //   }
  // };

  const handleUpdateVendorTheme = (e) => {
    e.preventDefault();
    // console.log("update");
    const color = selectColor.current.value;
    const fontFamily = selectFontFamily.current.value;

    const tenantTheme = {
      primary_color: color,
      color_shades: tailwindColor[`${color.trim()}`],
      font: fontFamily,
    };

    // console.log("tenant", tenantTheme);

    updateVendorTheme({
      token: sessionStorage.getItem("access_token"),
      params: Number(location.state.tenant_id),
      body: {
        tenant_name: location.state.tenant_name,
        theme: tenantTheme,
      },
    });
  };

  // if (vendorThemeIsLoading === true) {
  //   return <h1>Loading...</h1>;
  // }

  // if (vendorThemeData) {
  //   vendorsThemeRefetch();
  //   console.log("vendorThemeData",vendorThemeData);
  //   toast.success(`${vendorThemeData.message}`, {
  //     id: "vendorThemeAddedSuccessfully",
  //   });

  //   navigate("/vendorTheme", {
  //     state: {
  //       refetch: true,
  //     },
  //   });
  // }

  if (updateVendorThemeData) {
    vendorsThemeRefetch();
    toast.success(`${updateVendorThemeData.message}`, {
      id: "vendorThemeUpdateSuccessfully",
    });
    navigate("/vendorTheme", {
      state: {
        refetch: true,
      },
    });
  }

  // if (vendorThemeIsError) {
  //   toast.error(`${vendorThemeError.data.message}`, {
  //     id: "vendorThemeAddError",
  //   });
  // }

  if (updateVendorThemeIsLoading === true) {
    return <h1>Loading...</h1>;
  }

  if (updateVendorThemeIsError) {
    toast.error(`${updateVendorThemeError.data.message}`, {
      id: "vendorThemeAddError",
    });
  }

  const resetValue = (e) => {
    e.preventDefault();

    selectFontFamily.current.value = "sans-serif";
    selectColor.current.value = "#000000";
    selectVendor.current.value = -1;
  };

  return (
    <>
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="borderbtm">
                  <h4 className="card-title mb-0">
                    {/* {location.state !== null */}
                    {/* ?  */}
                    Update Client Theme
                    {/* : "Add Client Theme"} */}
                  </h4>
                </div>
                <div className="cmxform">
                  <div className="frmds row">
                    <div className="col-md-4 my-3">
                      <label>Client </label>
                      <select
                        className="js-example-basic-single w-100"
                        ref={selectVendor}
                      >
                        {/* {location.state !== null ? ( */}
                        <option value={location.state.tenant_name}>
                          {location.state.tenant_name}
                        </option>
                        {/* ) : ( */}
                        {/* <>
                            <option value={-1}>Select Vendor</option>
                            {vendorsIsLoading ? (
                              <option value={1}>Loading....</option>
                            ) : (
                              vendorsData &&
                              vendorsData.body.map((vendor, index) => (
                                <option key={vendor.tenant_id} value={index}>
                                  {vendor.name}
                                </option>
                              ))
                            )}
                          </> */}
                        {/* )} */}
                      </select>
                    </div>

                    <div className="col-md-4 my-3">
                      <label>Primary Color </label>
                      <select
                        className="js-example-basic-single w-100"
                        ref={selectColor}
                      >
                        <option
                          value={
                            location.state !== null
                              ? location.state.theme.primary_color
                              : "red"
                          }
                        >
                          {location.state !== null
                            ? location.state.theme.primary_color
                            : "red"}
                        </option>
                        {colorList.map((color, index) => {
                          if (location.state !== null) {
                            if (color === location.state.theme.primary_color) {
                              return <></>;
                            } else {
                              return (
                                <option key={index} value={color}>
                                  {color}
                                </option>
                              );
                            }
                          } else {
                            if (color === "red") {
                              return <></>;
                            } else {
                              return (
                                <option key={index} value={color}>
                                  {color}
                                </option>
                              );
                            }
                          }
                        })}
                      </select>
                    </div>

                    <div className="col-md-4 my-3">
                      <label className="my-1">Font Family </label>
                      <select
                        className="js-example-basic-single w-100"
                        ref={selectFontFamily}
                      >
                        <option
                          value={
                            location.state !== null
                              ? location.state.theme.font
                              : "san-serif"
                          }
                        >
                          {location.state !== null
                            ? location.state.theme.font
                            : "san-serif"}
                        </option>
                        {fontFamily.map((font, index) => {
                          if (location.state !== null) {
                            if (font === location.state.theme.font) {
                              return <></>;
                            } else {
                              return (
                                <option key={index} value={font}>
                                  {font}
                                </option>
                              );
                            }
                          } else {
                            if (font === "san-serif") {
                              return <></>;
                            } else {
                              return (
                                <option key={index} value={font}>
                                  {font}
                                </option>
                              );
                            }
                          }
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      {/* {location.state === null ? (
                        <button
                          className="btn btn-outline-primary mr-2 w-100"
                          onClick={AddVendorTheme}
                        >
                          Save
                        </button>
                      ) : ( */}
                      <button
                        className="btn btn-outline-primary btnall"
                        onClick={handleUpdateVendorTheme}
                      >
                        Update
                      </button>
                      {/* )} */}
                    </div>
                    {location.state === null && (
                      <div className="col-md-6 mt-3">
                        <button
                          className="btn btn-outline-secondary mr-2 w-100"
                          onClick={resetValue}
                        >
                          Reset
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVendorTheme;
