import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    user :  JSON.parse(  sessionStorage.getItem("user"))
}

export const adminAuthSlice = createSlice({
    name: 'adminAuth',
    initialState,
    reducers: {
        setAdminCredential: (state, action) => {
            state.user = action.payload;
        },
        logOut : (state, action) => {
            state.user = null;
        },
    },
})

export const { setAdminCredential , logOut} = adminAuthSlice.actions
export default adminAuthSlice.reducer