import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import {
  useAddGiftMutation,
  useUpdateGiftMutation,
} from "../../../features/tenant/gift/giftApi";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";

const categoriesArr = [
  { id: 1, name: "Smartphone" },
  { id: 2, name: "Electronics" },
  { id: 4, name: "Toys" },
  { id: 5, name: "Furniture" },
  { id: 7, name: "Kitchen Appliances" },
  { id: 8, name: "Utensils" },
  { id: 9, name: "Automobiles" },
  { id: 10, name: "Holiday Packages" },
  { id: 12, name: "Other" },
];

const categoriesUltimatrue = [ { id: 13, name: "Free Product Samples" },
  { id: 14, name: "Order discounts" },
  { id: 15, name: "Vouchers" },
  { id: 16, name: "Tools" },
  { id: 17, name: "Free Installation" },
  { id: 18, name: "Free Maintenance" },
  { id: 19, name: "Coupons" },
  { id: 20, name: "Invitations for Events" },
  { id: 21, name: "Free Trainings" },
  { id: 22, name: "Others" }];

const AddGift = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [brand, setBrand] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState({
    id: -1,
    name: "",
  });

  const [finalCategories, setFinalCategories] = useState(JSON.parse(sessionStorage.getItem("vendorData")).tenant_id == "17" ?categoriesUltimatrue : categoriesArr);
  
  const [name, setName] = useState(null);
  const [points, setPoints] = useState(null);
  const [value, setValue] = useState(null);
  const [images, setImages] = useState(null);
  // const [formImg, setFormImg] = useState("");

  const [isUpdate, setIsUpdate] = useState(location.state ? true : false);

  useEffect(() => {
    if (isUpdate) {
      const state = location.state;
      console.log(state);
      setBrand(state.brand);
      setName(state.name);
      setPoints(state.points);
      setValue(state.value);
      setImages(state.images);
      setSelectedCategory(
        finalCategories.find((each) => each.name === state.category),
      );
    }
  }, [location.state]);

  const [
    addGift,
    {
      data: addGiftData,
      isLoading: addGiftIsLoading,
      isError: addGiftIsError,
      error: addGiftError,
    },
  ] = useAddGiftMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!brand) {
      toast.error("Brand is empty", {
        id: "brandError",
      });
      return;
    }
    if (!name) {
      toast.error("Name is empty", {
        id: "nameError",
      });
      return;
    }
    if (points <= 0) {
      toast.error("Points must be greater than 0", {
        id: "pointsError",
      });

      return;
    }

    if (value <= 0) {
      toast.error("Value must be greater than 0", {
        id: "valueError",
      });

      return;
    }

    // if (images.length === 0) {
    //   toast.error("Please provide at least one image", {
    //     id: "imageError",
    //   });
    // }

    const formData = {
      image: images,
      brand: brand,
      category: selectedCategory.name,
      name: name,
      points: points,
      value: value,
    };

    addGift({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: formData,
    });
  };

  useEffect(() => {
    if (addGiftData) {
      console.log("gift added", addGiftData);
      toast.success("Gift Added.", {
        id: "giftAddSucess",
      });
      navigate(-1);
    }
    if (addGiftError) {
      console.log("gift error", addGiftError);
      toast.error("Some Error Occured", {
        id: "giftAddError",
      });
    }
  }, [addGiftData, addGiftError]);

  const [updateGift, { data: updateGiftData, error: updateGiftError }] =
    useUpdateGiftMutation();

  const handleUpdate = (e) => {
    e.preventDefault();
    if (isUpdate) {
      // console.log("images", images)
      const formData = {
        image : images,
        brand: brand,
        category: selectedCategory.name,
        name: name,
        points: points,
        value: value,
      };

      if(images && Array.isArray(images)){
        formData["image"] = images[0];
      }

      // console.log("formData", formData);
      // return;
      updateGift({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: formData,
        id: Number(location.state.id),
      });
    }
  };

  useEffect(() => {
    if (updateGiftData) {
      console.log("update gift data", updateGiftData);
      toast.success("Gift Updated.", {
        id: "giftUpdateSucess",
      });
      setTimeout(() => {
        navigate(-1);
      }, 400);
    }

    if (updateGiftError) {
      console.log("update gift error", updateGiftError);
      toast.error("Some Error Occured", {
        id: "giftUpdateError",
      });
    }
  }, [updateGiftData, updateGiftError]);

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const previewImage = (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    uploadImage({
      body: formData,
    });

    // setFormImg(event.target.files[0]);
    // const imageFiles = event.target.files;
    // const imageFilesLength = imageFiles.length;
    // if (imageFilesLength > 0) {
    //   const imageSrc = URL.createObjectURL(imageFiles[0]);
    //   setImages(imageSrc);
    // }
  };

  useEffect(() => {
    toast.dismiss();
    if (uploadImageData) {
      console.log("success image upload", uploadImageData.body);
      setImages(uploadImageData.body.fileLink);
    }
    if (uploadImageError) {
      toast.error("Image upload failed");
    }

    if (uploadImageIsLoading) {
      toast.loading("Uploading your image...", { id: "uploadImageLoading" });
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update" : "Add New "} Gift
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}

                  <div className="flex justify-center space-x-3">
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Brand"}
                        type={"text"}
                        value={brand}
                        setValue={setBrand}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-full">
                      <SelectComponentWithSearch
                        title={"Category"}
                        selected={selectedCategory}
                        setSelected={setSelectedCategory}
                        dataArr={finalCategories}
                        placeholder="Select Category"
                      />
                    </div>
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Name"}
                        type={"text"}
                        value={name}
                        setValue={setName}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Points"}
                        type={"number"}
                        value={points}
                        setValue={setPoints}
                        required={true}
                      />
                    </div>

                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Value"}
                        type={"number"}
                        value={value}
                        setValue={setValue}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-center ">
                    <div className="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                      >
                        <div className="flex flex-col items-center justify-center ">
                          <svg
                            aria-hidden="true"
                            className="w-40 h-40 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              strokeLinejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold block">
                              Click to upload or drag and drop
                            </span>{" "}
                          </p>
                          <p className="text-xs text-gray-500">PNG or JPG .</p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => previewImage(e)}
                        />
                      </label>
                    </div>
                  </div>

                  {images && (
                    <img
                      src={images}
                      alt=""
                      srcset=""
                      className="flex items-center justify-center h-16 w-16 mt-2 mb-2 border border-gray-300 p-2 m-auto rounded-md"
                    />
                  )}
                </div>

                <div className="flex justify-center mt-2">
                  {isUpdate ? (
                    <button
                      type="update"
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGift;
