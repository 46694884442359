import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFetchCompanyProfileMutation } from "../../../features/tenant/companyProfile/companyProfileApi";
import { toast } from "react-hot-toast";
import { setCompanyProfile } from "../../../features/tenant/companyProfile/companyProfileSlice";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";

const Profile = () => {
  const companyProfile = JSON.parse(sessionStorage.getItem("companyProfile"));
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [
    fetchCompanyProfile,
    {
      data: companyData,
      isLoading: companyIsLoading,
      isError: companyIsError,
      error: companyError,
    },
  ] = useFetchCompanyProfileMutation();

  useEffect(() => {
    fetchCompanyProfile({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    if (companyData) {
      console.log("companyyy", companyData);
      sessionStorage.setItem(
        "companyProfile",
        JSON.stringify(companyData.body),
      );
      dispatch(setCompanyProfile(companyData.body));
      setCompanyProfile(companyData.body);
    }

    if (companyError) {
      toast.dismiss();
      toast.error("Error Occured while fetching profile data", {
        id: "fetchCompanyError",
      });
    }
  }, [companyData]);
  return (
    <div className="flex w-full bg-slate-200 h-full justify-center">
      <div className=" w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Company Profile
          <LinksComponent />
        </div>
        <div className="p-3">
          {companyProfile && (
            <div className="">
              <div className=" ">
                <div className="bg-white shadow rounded-lg">
                  <div className="flex">
                    <div className="cl_left w-40  border-r-2 border-r-gray-200">
                      <div className=" p-3 ">
                        <div className="image overflow-hidden">
                          <img
                            className="h-auto w-full mx-auto"
                            src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                            alt=""
                          />
                        </div>

                        <ul className="">
                          <li>
                            <div className="mb-3">
                              <img
                                className="w-50 h-50 p-1 rounded  mx-auto"
                                src={

                                  companyProfile.logo[0]
                                }
                                alt="Company Logo"
                                onClick={() =>
                                  setShowImageModal(

                                    companyProfile.logo[0],
                                  )
                                }
                              />
                            </div>
                          </li>
                          <li className="border-b pb-2 border-gray-300">
                            <div className="text-gray-500 text-sm">
                              Contact Person
                            </div>
                            <div className="font-semibold text-sm">
                              {companyProfile.contact_person_name}
                            </div>
                          </li>
                          <li className="border-b pt-2.5 pb-2.5 border-gray-300 ">
                            <div className="text-gray-500 text-sm">
                              Contact Person Number
                            </div>
                            <div className="font-semibold text-sm">
                              {companyProfile.contact_person_number}
                            </div>
                          </li>
                        </ul>

                        <ul className="flex gap-2 justify-center mt-2 pt-2 pb-2">
                          {companyProfile?.socials?.linkedin &&
                            <li
                              className=" border-gray-700 rounded whbox_social bg-blue-400  hover:bg-blue-600"
                              onClick={() =>
                                window.open(
                                  `https://${companyProfile?.socials?.linkedin}`,
                                  "_blank",
                                )
                              }
                            >
                              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" fill="#0F0F0F"></path> <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" fill="#0F0F0F"></path> <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z" fill="#0F0F0F"></path> </g></svg>
                            </li>
                          }
                          {companyProfile?.socials?.whatsapp && <li
                            className=" border-gray-700 rounded whbox_social bg-green-300 hover:bg-green-500"
                            onClick={() =>
                              window.open(
                                `https://${companyProfile?.socials?.whatsapp}`,
                                "_blank",
                              )
                            }
                          >
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z" fill="#0F0F0F"></path> </g></svg>
                          </li>
                          }
                          {companyProfile?.socials?.facebook &&
                            <li
                              className="border-2 border-gray-700 rounded whbox_social bg-blue-200 hover:bg-blue-400"
                              onClick={() =>
                                window.open(
                                  `https://${companyProfile?.socials?.facebook}`,
                                  "_blank",
                                )
                              }
                            >
                              <svg
                                class="h-5 w-5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                {" "}
                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                              </svg>
                            </li>
                          }


                          {companyProfile?.socials?.twitter &&
                            <li
                              className="border-2 border-gray-700 rounded whbox_social bg-slate-400 hover:bg-slate-600"
                              onClick={() =>
                                window.open(
                                  `https://${companyProfile?.socials?.twitter}`,
                                  "_blank",
                                )
                              }
                            >
                              <svg
                                class="h-5 w-5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                {" "}
                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                              </svg>
                            </li>

                          }
                          {companyProfile?.socials?.instagram &&
                            <li
                              className="border-2 border-gray-700 rounded whbox_social bg-purple-400  hover:bg-purple-600"
                              onClick={() =>
                                window.open(
                                  `https://${companyProfile?.socials?.instagram}`,
                                  "_blank",
                                )
                              }
                            >
                              <svg
                                class="h-5 w-5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                {" "}
                                <rect
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="5"
                                  ry="5"
                                />{" "}
                                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                              </svg>
                            </li>
                          }


                          {companyProfile?.socials?.portal &&
                            <li
                              className="border-2 border-gray-700 rounded whbox_social bg-blue-400 hover:bg-blue-600"
                              onClick={() =>
                                window.open(
                                  `https://${companyProfile?.socials?.portal}`,
                                  "_blank",
                                )
                              }
                            >
                              <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7.05025 1.53553C8.03344 0.552348 9.36692 0 10.7574 0C13.6528 0 16 2.34721 16 5.24264C16 6.63308 15.4477 7.96656 14.4645 8.94975L12.4142 11L11 9.58579L13.0503 7.53553C13.6584 6.92742 14 6.10264 14 5.24264C14 3.45178 12.5482 2 10.7574 2C9.89736 2 9.07258 2.34163 8.46447 2.94975L6.41421 5L5 3.58579L7.05025 1.53553Z" fill="#000000"></path> <path d="M7.53553 13.0503L9.58579 11L11 12.4142L8.94975 14.4645C7.96656 15.4477 6.63308 16 5.24264 16C2.34721 16 0 13.6528 0 10.7574C0 9.36693 0.552347 8.03344 1.53553 7.05025L3.58579 5L5 6.41421L2.94975 8.46447C2.34163 9.07258 2 9.89736 2 10.7574C2 12.5482 3.45178 14 5.24264 14C6.10264 14 6.92742 13.6584 7.53553 13.0503Z" fill="#000000"></path> <path d="M5.70711 11.7071L11.7071 5.70711L10.2929 4.29289L4.29289 10.2929L5.70711 11.7071Z" fill="#000000"></path> </g></svg>
                            </li>
                          }

                          {companyProfile?.socials?.youtube &&
                            <li
                              className="border-2 border-gray-700 rounded whbox_social bg-red-400 hover:bg-red-600"
                              onClick={() =>
                                window.open(
                                  `https://${companyProfile?.socials?.youtube}`,
                                  "_blank",
                                )
                              }
                            >
                              <svg
                                class="h-5 w-5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                {" "}
                                <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />{" "}
                                <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
                              </svg>
                            </li>
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="cl_right w-full ">
                      {/* INFO */}
                      <div className="">
                        <div className="pl-3 pr-3  pt-3  border-b pb-2 border-gray-300 flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                          <span clas="text-green-500">
                            <svg
                              className="h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                strokeLinejoin="round"
                                stroke-width="2"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                              />
                            </svg>
                          </span>
                          <span className="tracking-wide text-lg">About</span>
                        </div>

                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-2 gap-2 pl-3 pr-3 pb-3 pt-3">
                            <div>
                              <div className="text-gray-400 text-sm  block">
                                Name
                              </div>

                              <div className="flex text-black text-sm  gap-1">
                                {companyProfile.name}
                              </div>
                            </div>

                            <div>
                              <div className="text-gray-400 text-sm  block">
                                Website
                              </div>

                              <div
                                className=" flex text-black text-sm  gap-1"
                                onClick={() =>
                                  window.open(
                                    `${companyProfile.website}`,
                                    "_blank",
                                  )
                                }
                              >
                                {companyProfile.website}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-2 gap-2 pl-3 pr-3 pb-3 pt-3">
                            <div>
                              <div className="text-gray-400 text-sm  block">
                                Customer Support Mobile
                              </div>

                              <div className="flex text-black text-sm  gap-1">
                                {companyProfile.customer_support_mobile}
                              </div>
                            </div>

                            <div>
                              <div className="text-gray-400 text-sm  block">
                                Customer Support Email
                              </div>

                              <a
                                className="text-blue-800 flex text-md  gap-1"
                                href={`mailto:${companyProfile.customer_support_email}`}
                              >
                                {companyProfile.customer_support_email}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-2 gap-2 pl-3 pr-3 pb-3 pt-3">
                            <div>
                              <div className="text-gray-400 text-sm  block">
                                Documentation
                              </div>

                              <a
                                className="text-blue-800 flex text-md  gap-1"
                                target="_blank"
                                href={`${process.env.REACT_APP_BASE_URL}-docs/`}
                                rel="noreferrer"
                              >
                                Documentation
                              </a>
                            </div>

                            <div>
                              <div className="text-gray-400 text-sm  block">
                                Api Key
                              </div>

                              <div className="flex text-black text-sm  gap-1">
                                {companyProfile.api_key}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="bg-gray-100 mt-2 rounded-sm py-2 px-3">
                        <div className="border-b pb-2 border-gray-300 flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                              <span clas="text-green-500">
                                <svg
                                  className="h-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    strokeLinejoin="round"
                                    stroke-width="2"
                                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                  />
                                </svg>
                              </span>
                              <span className="tracking-wide text-lg">Socials</span>
                            </div>

<div className="d-flex gap-3 pb-2">
<div className="text-gray-400 w-10">Facebook</div>
<span>:</span>
<div className="text-blue-700 ">
                                  {companyProfile.socials.facebook}
                                </div>
                                
  </div>

  <div className="d-flex gap-3 pb-2">
<div className="text-gray-400 w-10">Twitter</div>
<span>:</span>
<div className="text-blue-700 ">
{companyProfile.socials.twitter}
                                </div>
                                
  </div>


  <div className="d-flex gap-3 pb-2">
<div className="text-gray-400 w-10">Instagram</div>
<span>:</span>
<div className="text-blue-700 ">
{companyProfile.socials.instagram}
                                </div>
                                
  </div>


  <div className="d-flex gap-3 pb-2">
<div className="text-gray-400 w-10">Youtube</div>
<span>:</span>
<div className="text-blue-700 ">
{companyProfile.socials.youtube}
                                </div>
                                
  </div>




                          </div> */}

                        <div className="flex justify-end p-3">
                          <button
                            type="submit"
                            onClick={(e) => navigate("edit")}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showImageModal && (
            <ImageModal
              show={showImageModal ? true : false}
              setShow={setShowImageModal}
              size="md"
              data={showImageModal}
            >
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={showImageModal}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
            </ImageModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
