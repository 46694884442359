import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MyWizard from "../Vendor/MyWizard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const MasterHome = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [refetch, setRefetch] = useState(false);

  const [tableDataItems, setTableDataItems] = useState([]);

  useEffect(() => {
    toast.remove();
    const toastid = toast.loading("Fetching all vendors data...", {
      id: "vendorsData",
    });

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/company`,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          toast.success("Data Fetched Successfully", {
            id: toastid,
          });
          setTableDataItems(response.data.body);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: toastid,
          },
        );
      });
  }, [refetch]);

  const handleButtonEditClick = (r) => {
    //handle the edit functionality
    navigate(`/editVendor?vendor=${r.company_id}`);
  };

  const changeStatusBtn = (r) => {
    // console.log(r);
    // console.log(tableDataItems);
    let tbc = [...tableDataItems];
    let data;
    tbc.forEach((element) => {
      if (element.company_id == r.company_id) {
        if (element.status === "0") {
          element.status = "1";
          console.log("status changed to 1 ");
        } else {
          element.status = "0";
          console.log("status changed to 0 ");
        }
        data = {
          ...r,
          status: element.status,
        };
      }
    });

    setTableDataItems(tbc);
    console.log(data);
    // return;

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_BASE_URL}/admin/company/${r.company_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          toast.success("Status Changed", {
            id: "statusUpdateSuccess",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "statusUpdateError",
          },
        );
      });
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "Industry",
      selector: (row) => row.industry_name,
      sortable: true,
      width: "100px",
    },
    {
      name: "Tenant DB",
      selector: (row) => row.tenant_id,
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Panel Status",
      selector: (row) => {
        if (row.panel_status == 0) {
          return "Demo";
        } else if (row.panel_status == 1) {
          return "Testing/Staging";
        } else if (row.panel_status == 2) {
          return "Pre-Production";
        } else if (row.panel_status == 3) {
          return "Production (Live)";
        } else return "Null";
      },
      sortable: true,
    },
    {
      name: "Status",
      cell: (r) =>
        r.status === "2" ? (
          <button
            className="btn btn-outline-secondary btn-sm"
            // onClick={() => changeStatusBtn(r)}
          >
            In-Active
          </button>
        ) : (
          <button
            className="btn btn-outline-primary btn-sm"
            // onClick={() => changeStatusBtn(r)}
          >
            Active
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div>
        {open && (
          <Modal keepMounted open={open} onClose={handleClose}>
            <Box style={{ overflowY: "scroll" }} sx={style}>
              <div>
                <MyWizard handleClose={handleClose} refetch={setRefetch} />
              </div>
            </Box>
          </Modal>
        )}
      </div>
      <div className="page-content">
        <div className="card p-4">
        <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="mb-3 mb-md-0 card-title">Welcome to Dashboard</h4>
          </div>
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              // onClick={() => navigate("/addVendor")}
              onClick={handleOpen}
            >
              Register Client
            </button>
          </div>
        </div>
        <div className="">
          <div className="card-body p-0">
            <div className="table-responsive">
              <DataTable
                title="Clients"
                data={tableDataItems}
                columns={columns}
                // onSelectedRowsChange={handleChange}
                // customStyles={customStyles}
                striped
                highlightOnHover
                pointerOnHover
                pagination
              />
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default MasterHome;
