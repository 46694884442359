import React, { useEffect, useState } from "react";
import ListItem from "./ListItem";

const ReorderList = ({ list, setList, type }) => {
  const [dragId, setDragId] = useState();
  const [boxes, setBoxes] = useState(list);

  useEffect(() => {
    console.log("ReorderList Component Useffect");
    setBoxes(list);
  }, [list]);

  const handleDrag = (ev) => {
    // console.log(ev.currentTarget.id);
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    // console.log(ev);
    const dragBox = boxes.find((box) => box.id == dragId);
    const dropBox = boxes.find((box) => box.id == ev.currentTarget.id);

    console.log(dropBox, dragBox);

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = boxes.map((box) => {
      if (box.id == dragId) {
        box.order = dropBoxOrder;
      }
      if (box.id == ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    setBoxes(newBoxState);
    setList(newBoxState);
  };

  return (
    <div className="scrollbar-hide " style={{ width: "100%", margin: "10px" }}>
      {boxes
        .sort((a, b) => a.order - b.order)
        .map((box) => (
          <ListItem
            key={box.id}
            boxColor={box.color}
            boxNumber={box.id}
            name={type == "menu" ? box.name : box.id}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
          />
        ))}
    </div>
  );
};

export default ReorderList;
