import { rootApi } from "../../api/rootApi";

export const zonesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addZone: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/zones/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getAllZone: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/zones`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    deleteZone: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/zones/delete`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,

        };
      },
    }),
    updateStatus: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/zones/status`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,

        };
      },
    }),

    getAllZonesObject: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/zones/zone-obj`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },

        };
      },
    }),

    addLocationToZone: builder.mutation({
      query: (params) => {
        return {
          method: "post",
          url: `/tenant/zonesLocations/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.data

        };
      },
    }),
    getAllLocationsByZone: builder.mutation({
      query: (params) => {
        return {
          method: "get",
          url: `/tenant/zonesLocations/${params.zone_id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },

        };
      },
    }),
    getAllDistrictLocationsByZone: builder.mutation({
      query: (params) => {
        return {
          method: "get",
          url: `/tenant/zonesLocations/${params.zone_id}/${params.state_id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },

        };
      },
    }),
    deleteStatesAndDistrictsOfZone: builder.mutation({
      query: (params) => {
        return {
          method: "delete",
          url: `/tenant/zonesLocations/${params.zone_id}/${params.state_id}${params.district_id ? `/${params.district_id}` : ''}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },

        };
      },
  }),

    getAllNotAssignedDistricts: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/zonesLocations/districts/${params.state_id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },

        }
      }}),
    addBulkLocationsToZone: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/zonesLocations/addBatch`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.data

        }
      }})
})
});

export const {
    useAddZoneMutation,
    useGetAllZoneMutation,
    useDeleteZoneMutation,
    useUpdateStatusMutation,
    useGetAllZonesObjectQuery,
    useAddLocationToZoneMutation,
    useGetAllLocationsByZoneMutation,
    useGetAllDistrictLocationsByZoneMutation,
    useDeleteStatesAndDistrictsOfZoneMutation,useAddBulkLocationsToZoneMutation,useGetAllNotAssignedDistrictsMutation
} = zonesApi;