import { rootApi } from "../../api/rootApi";

export const firebaseTopicsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllTopics: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/firebase-topics/?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addTopics: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/firebase-topics/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteTopics: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/firebase-topics/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    deleteUsersTopics: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/firebase-topics/delete-user/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),

    sendNotificationToTopics: builder.mutation({
      query: (params) => {
        console.log("sendNotificationToTopics api",params);
        return {
          method: "PATCH",
          url: `/tenant/firebase-topics/send-notification/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body : params.body
        };
      },
    }),
    addUsersToTopics: builder.mutation({
        query: (params) => {
          console.log("addUsersToTopics",params);
          return {
            method: "PUT",
            url: `/tenant/firebase-topics/add-users/${params.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body : params.body
          };
        },
      }),
  }),
});
export const {
 useAddTopicsMutation,
 useDeleteTopicsMutation,
 useFetchAllTopicsMutation,
 useSendNotificationToTopicsMutation,
 useAddUsersToTopicsMutation,
 useDeleteUsersTopicsMutation
} = firebaseTopicsApi;
