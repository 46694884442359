import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import Wheels from "../../../components/Tenant/Wheel/Wheel";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { States } from "../../../utils/getStates";
import { toast } from "react-hot-toast";
import {
  useAddWheelMutation,
  useGetWheelByCampaignIdMutation,
  useUpdateWheelByIdMutation,
} from "../../../features/tenant/wheel/wheelApi";
import {
  useAddWheelRewardsMutation,
  useGetWheelRewardsByWheelIdMutation,
  useUpdateWheelRewardsByIdMutation,
} from "../../../features/tenant/wheelRewards/wheelRewardsApi";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
} from "@mui/material";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import { useFetchCouponsBrandMutation } from "../../../features/tenant/staticCoupons/staticCouponsApi";
import "./Wheel.css";
import {
  useGetWheelCampaignByIdMutation,
  useUpdateWheelCampaignByIdMutation,
} from "../../../features/tenant/wheelCampaign/wheelCampaignApi";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";

const type = [
  {
    id: 1,
    name: "Not Applicable",
  },
  {
    id: 2,
    name: "Before Spin",
  },
  {
    id: 3,
    name: "After Spin",
  },
];

const Wheel = () => {
  const location = useLocation();
  const allStates = States;
  const [states, setStates] = useState([allStates[0]]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState(0);
  const [selectedType, setSelectedType] = useState(type[0]);

  const [elements, setElements] = useState([]);
  const [lableName, setLableName] = useState("");
  const [name, setName] = useState("");
  const [inputLength, setInputLength] = useState("");
  const [inputType, setInputType] = useState("");
  const [required, setRequired] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [selectOption, setSelectOptions] = useState([]);
  const [newUserValue, setNewUserValue] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleNewElementAddition = () => {
      let newElementObj = {
        label: lableName,
        name: name,
        type: inputType,
        required: required,
        maxLength: inputLength,
        options: selectOption,
      };

      let oldOptions = elements;
      oldOptions.push(newElementObj);
      setElements(oldOptions);
      console.log("added");

      console.log(oldOptions);
    };
    if (lableName !== "" && inputType !== "" && inputLength !== "") {
      handleNewElementAddition();
      setName("");
      setLableName("");
      setInputLength("");
      setRequired(false);
      setInputType("");
    }
  }, [reRender]);

  const handleInputTypeChange = (e) => {
    setInputType(e.target.value);
  };

  const removeFromSelectOptions = (e, key) => {
    console.log(key);
    let oldValues = [...selectOption];
    oldValues = oldValues.filter((user) => user != key);
    setSelectOptions(oldValues);
  };

  const handleDeleteElement = (e, eIdx) => {
    let oldElements = elements;
    oldElements = oldElements.filter((ele, idx) => idx !== eIdx);
    setElements(oldElements);
    setReRender((old) => !old);
  };

  const [wc_id, setWc_id] = useState();
  const [wheelId, setWheelId] = useState();
  const color = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .primary_color;
  const [rewardsSbmt, setRewardsSbmt] = useState(true);
  const [userTypes, setUserTypes] = useState(null);
  const [edit, setEdit] = useState(false);
  const [openTab, setOpenTab] = React.useState(1);
  const [names, setUserNames] = useState([]);
  const [status, setStatus] = useState([
    { id: 0, name: "Deleted" },
    { id: 1, name: "Active" },
    { id: 2, name: "InActive" },
  ]);
  const [data, setData] = useState([
    { option: "" },
    { option: "" },
    { option: "" },
    { option: "" },
    { option: "" },
    { option: "" },
    { option: "" },
    { option: "" },
  ]);

  // const [primaryBackgroundColor, setPrimaryBackgroundColor] =
  //   useState("#3e3e3e");
  // const [secondaryBackgroundColor, setSecondaryBackgroundColor] =
  //   useState("#df3428");
  // const [primaryTextColor, setPrimaryTextColor] = useState("#ffffff");
  // const [secondaryTextColor, setSecondaryTextColor] = useState("#3e3e3e");
  // const [outerBorderColor, setOuterBorderColor] = useState("#000000");
  const [renderTimes, setrenderTimes] = useState([]);
  const [images, setImages] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [picture, setPicture] = useState(null);
  const [pictureFile, setPictureFile] = useState(null);

  const navigate = useNavigate();
  const [rewardLengths, setRewardLength] = useState([
    {
      id: 1,
      name: 4,
    },
    // {
    //   id: 2,
    //   name: 5,
    // },
    {
      id: 3,
      name: 6,
    },
    // {
    //   id: 4,
    //   name: 7,
    // },
    {
      id: 5,
      name: 8,
    },
  ]);
  const [types, setTypes] = useState([
    {
      id: 1,
      name: "Filler",
    },
    {
      id: 2,
      name: "Gift",
    },
    // {
    //   id: 3,
    //   name: "Coupon",
    // },
    // {
    //   id: 4,
    //   name: "Point",
    // },
  ]);

  const [reward, setReward] = useState({
    id: 5,
    name: 8,
  });

  const wheelTypes = [
    { id: "1", name: "Probability" },
    { id: "2", name: "Fixed Prize" },
  ];

  const [wheelType, setWheelType] = useState({
    id: 0,
    name: "Set Wheel Type",
  });

  const [fixedReward, setFixedReward] = useState({
    id: 0,
    name: "Select Reward",
  });

  const obj = {
    id: "",
    wc_id: wc_id,
    w_id: wheelId,
    type: "",
    name: "",
    status: "",
    windex: "",
    startDate: startDate,
    endDate: endDate,
    offsetx: 0,
    offsety: 0,
    probability: 0,
    quantity: 10,
    image: "",
    points: 0,
  };

  const validateForm = () => {
    const errors = [];
    let sum = 0;

    renderTimes.forEach((obj, index) => {
      if (obj.type === "") {
        errors.push("Some field Missed");
        toast.error(`Type is required `, { id: "TypeError" });
      }
      if (obj.status === "") {
        errors.push("Some field Missed");
        toast.error(`Status is required `, { id: "NameError" });
      }
      if (obj.windex === "") {
        errors.push("Some field Missed");
        toast.error(`Index is required `, { id: "indexError" });
      }
      if (obj.startDate === "") {
        errors.push("Some field Missed");
        toast.error(`Start Date is required `, { id: "indexError" });
      }
      if (obj.endDate === "") {
        errors.push("Some field Missed");
        toast.error(`End Date is required `, { id: "indexError" });
      }

      sum += Number(obj.probability);
    });

    if (sum > 100) {
      errors.push("Sum of probabilities cannot exceed 100");
      toast.error(`Sum of probabilities cannot exceed 100`, { id: "SumError" });
    }

    return errors.length === 0;
  };

  const stateChange = (index, key, value) => {
    if (wc_id && wheelId) {
      const updatedArray = renderTimes.map((obj, i) => {
        if (i === index) {
          return { ...obj, [key]: value };
        }
        return obj;
      });
      console.log(key, updatedArray);
      setrenderTimes(updatedArray);
    }
  };

  const [
    fetchcouponBrands,
    {
      data: couponBrandList,
      isLoading: couponBrandIsLoading,
      error: couponBrandError,
    },
  ] = useFetchCouponsBrandMutation();

  const [
    addWheel,
    {
      data: addWheelData,
      isLoading: addWheelIsLoading,
      isError: addWheelIsError,
      error: addWheelError,
    },
  ] = useAddWheelMutation();

  const [
    addWheelRewards,
    {
      data: addWheelRewardsData,
      isLoading: addWheelRewardsIsLoading,
      isError: addWheelRewardsIsError,
      error: addWheelRewardsError,
    },
  ] = useAddWheelRewardsMutation();

  // useUpdateWheelByIdMutation
  const [
    UpdateWheelById,
    {
      data: updateWheelByIdData,
      isLoading: updateWheelByIdIsLoading,
      isError: updateWheelByIdIsError,
      error: updateWheelByIdError,
    },
  ] = useUpdateWheelByIdMutation();

  const [
    getWheelByCampaignId,
    {
      data: getWheelByCampaignIdData,
      isLoading: getWheelByCampaignIdIsLoading,
      isError: getWheelByCampaignIdIsError,
      error: getWheelByCampaignIdError,
    },
  ] = useGetWheelByCampaignIdMutation();

  const [
    GetWheelCampaignById,
    {
      data: GetWheelCampaignByIdData,
      isLoading: GetWheelCampaignByIdIsLoading,
      isError: GetWheelCampaignByIdIsError,
      error: GetWheelCampaignByIdError,
    },
  ] = useGetWheelCampaignByIdMutation();

  const [
    UpdateWheelCampaignById,
    {
      data: UpdateWheelCampaignByIdData,
      isLoading: UpdateWheelCampaignByIdIsLoading,
      isError: UpdateWheelCampaignByIdIsError,
      error: UpdateWheelCampaignByIdError,
    },
  ] = useUpdateWheelCampaignByIdMutation();

  const [
    getWheelRewardsByWheelId,
    {
      data: getWheelRewardsByWheelIdData,
      isLoading: getWheelRewardsByWheelIdIsLoading,
      isError: getWheelRewardsByWheelIdIsError,
      error: getWheelRewardsByWheelIdError,
    },
  ] = useGetWheelRewardsByWheelIdMutation();

  const [
    getAppUserTypes,
    {
      data: getAppUserTypesData,
      isLoading: getAppUserTypesIsLoading,
      error: getAppUserTypesIsError,
      isError: getAppUserTypesError,
    },
  ] = useGetAppUserTypesMutation();

  const [
    UpdateWheelRewardsById,
    {
      data: updateWheelRewardsByIdData,
      isLoading: updateWheelRewardsByIdIsLoading,
      error: updateWheelRewardsByIdIsError,
      isError: updateWheelRewardsByIdError,
    },
  ] = useUpdateWheelRewardsByIdMutation();

  useEffect(() => {
    if (updateWheelRewardsByIdData) {
      toast.success("Rewards Updated Succesfully");
      return;
    }
    if (updateWheelRewardsByIdError) {
      toast.error("Error in Updaing Rewards");
    }
  }, [updateWheelRewardsByIdData, updateWheelRewardsByIdError]);

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const uploadImageFunc = (file, type) => {
    const formData = new FormData();
    formData.append("image", file[0]);
    uploadImage({
      body: formData,
    });
  };

  useEffect(() => {
    if (uploadImageData) {
      // console.log(uploadImageData.body[0], logoType);
      setPicture(uploadImageData.body.fileLink);
    }
    if (uploadImageError) {
      toast.error("image upload failed");
      console.log("Image upload failed");
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);

  const getAllWheelRewardsFun = () => {
    getWheelRewardsByWheelId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: wheelId,
      limit: 10,
      offset: 0,
    });

    // fetchcouponBrands({
    //   token: sessionStorage.getItem("access_token"),
    //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    // });
  };

  const getWheelCampaignById = () => {
    GetWheelCampaignById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: wc_id,
    });

    // fetchcouponBrands({
    //   token: sessionStorage.getItem("access_token"),
    //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    // });
  };

  // GetWheelCampaignById
  useEffect(() => {
    if (addWheelRewardsData) {
      toast.success("Reward Added Sucessfully");
      navigate(-1);
      return;
    }
    if (addWheelRewardsIsError) {
      toast.error("Error in adding Reward!");
      return;
    }
  }, [addWheelRewardsData, addWheelRewardsIsError]);

  useEffect(() => {
    if (couponBrandList) {
      console.log(couponBrandList);
      const arr = [];
      for (let i = 0; i < couponBrandList?.body?.length; i++) {
        const element = couponBrandList.body[i];
        const obj = { id: i, name: element.brand, qty: element.count };
        arr.push(obj);
      }
      setCouponList(arr);
    }
  }, [couponBrandList, couponBrandError]);

  useEffect(() => {
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (wheelId) getAllWheelRewardsFun();
  }, [wheelId]);

  useEffect(() => {
    if (getWheelRewardsByWheelIdData?.body?.gifts?.length) {
      const sortedGifts = [...getWheelRewardsByWheelIdData.body.gifts].sort(
        (a, b) => a.windex - b.windex,
      );

      const updatedRenderTimes = renderTimes.map((obj, index) => {
        const responseObj = sortedGifts[index];
        if (responseObj && obj.windex === responseObj.windex) {
          return {
            ...obj,
            id: responseObj.id,
            probability: responseObj.chance,
            type: responseObj.type,
            status: responseObj.status,
            startDate: responseObj.start_date.slice(0, 10),
            endDate: responseObj.end_date.slice(0, 10),
            offsetx: responseObj.offsetx,
            offsety: responseObj.offsety,
            quantity: responseObj.quantity,
            points: responseObj.points,
            price: responseObj.price,
            name: responseObj.name,
            image: responseObj.image,
            // is_reward_fixed: responseObj.is_reward_fixed,
          };
        }
      });
      console.log("updatedRenderTimes", updatedRenderTimes);
      setrenderTimes(updatedRenderTimes);

      setRewardsSbmt(false);
      // Now you can use the updatedRenderTimes array as needed
    }
  }, [getWheelRewardsByWheelIdData]);

  const updateWheelRewards = (e) => {
    e.preventDefault();



    if (validateForm()) {
      renderTimes.forEach((obj, index) => {
        const formData = new FormData();
        Object.entries(obj).forEach(([key, value]) => {
          formData.append(`${key}`, value);
        });

        UpdateWheelRewardsById({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          id: obj.id,
          data: formData,
        });

        formData.delete("*");
      });
    }
  };

  useEffect(() => {
    if (getAppUserTypesData?.body) {
      setUserTypes(getAppUserTypesData.body);
    }
  }, [getAppUserTypesData]);

  const getWheelByCampaignIdFun = () => {
    getWheelByCampaignId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: wc_id,
      limit: 10,
      offset: 0,
    });
  };

  useEffect(() => {
    if (wc_id) {
      getWheelByCampaignIdFun();
    }
  }, [wc_id]);

  const doesValueExist = (array, value) => {
    for (let i = 0; i < array?.length; i++) {
      if (array[i].user_type == value || array[i].name == value) {
        return i;
      }
    }
    return -1;
    // return array.some((obj) => Object.values(obj).includes(value));
  };

  // console.log("reward", reward)

  useEffect(() => {
    console.log("getWheelByCampaignIdData", getWheelByCampaignIdData?.body);

    if (getWheelByCampaignIdData?.body?.length) {
      setWheelId(getWheelByCampaignIdData.body[0].id);
      setReward({
        ...reward,
        name: getWheelByCampaignIdData.body[0].reward_count,
      });
      // setPrimaryBackgroundColor(getWheelByCampaignIdData.body[0].bg_color);
      // setSecondaryBackgroundColor(
      //   getWheelByCampaignIdData.body[0].bg_alt_color,
      // );
      // setPrimaryTextColor(getWheelByCampaignIdData.body[0].font_color);
      // setSecondaryTextColor(getWheelByCampaignIdData.body[0].font_alt_color);
      // setOuterBorderColor(getWheelByCampaignIdData.body[0].line_color);
      // setFontSize(getWheelByCampaignIdData.body[0].font_size);
      setPicture(getWheelByCampaignIdData.body[0].wheel_image);
      if (getWheelByCampaignIdData.body[0].wheel_type == "1") {
        setWheelType(wheelTypes[0]);
      } else if (getWheelByCampaignIdData.body[0].wheel_type == "2") {
        setWheelType(wheelTypes[1]);
      }

      // console.log("user types", userTypes);

      if (
        getWheelByCampaignIdData?.body[0]?.user_type_id?.length &&
        userTypes?.length > 0
      ) {
        const arr = [];
        for (
          let i = 0;
          i < getWheelByCampaignIdData?.body[0]?.user_type_id?.length;
          i++
        ) {
          const f = userTypes.filter(
            (user) =>
              user.user_type_id ==
              getWheelByCampaignIdData?.body[0]?.user_type_id[i],
          );
          // console.log("f",f)
          if (f.length > 0) {
            arr.push(f[0]);
          }
        }
        // console.log("arr", arr);
        setUserNames(arr);
      }

      setEdit(true);
    }
  }, [getWheelByCampaignIdData, userTypes]);

  useEffect(() => {
    if(getWheelByCampaignIdData?.body?.length && renderTimes.length > 0){
        console.log("rendertimes ", renderTimes , getWheelByCampaignIdData.body[0]);

        const f = renderTimes.filter(
          (gift) =>
            gift.id ==
            getWheelByCampaignIdData?.body[0]?.wheel_fixed_reward
        );
        if(f.length > 0)
          setFixedReward(f[0]);
    }
  }, [renderTimes ,getWheelByCampaignIdData ])
  
  
  useEffect(() => {
    if (addWheelData) {
      toast.success("Wheel  Added Successfully !!");
    }
    if (addWheelError) {
      toast.error("Error in Creating Wheel ");
    }
  }, [addWheelData, addWheelError]);

  useEffect(() => {
    const data = location.state;

    console.log("data", data);

    if (!data) {
      navigate(-1);
    }

    if (data?.id) {
      setWc_id(data.id);
    }

    if (data?.title) {
      setTitle(data.title);
    }
    if (data?.states?.length) {
      console.log("all states ", allStates);

      console.log("states here", data?.states);
      const finalStates = [];

      data.states.forEach((state) => {
        const a = allStates.filter((each) => each.name === state);
        finalStates.push(a[0]);
      });

      console.log("finalStates here", finalStates);

      // const parsedStates = data.states
      //   .map((stateJson, index) => {
      //     try {
      //       console.log("here", stateJson)
      //       const stateObject = JSON.parse(stateJson);
      //       return { id: stateObject.id, name: stateObject.name };
      //     } catch (error) {
      //       console.error(`Error parsing state at index ${index}:`, error);
      //       return null;
      //     }
      //   })
      //   .filter((state) => state !== null);
      setStates(finalStates);
    }
    if (data?.description) {
      setDescription(data.description);
    }
    if (data?.start_date) {
      data.start_date = data.start_date.slice(0, 10);
      setStartDate(data.start_date);
    }
    if (data?.end_date) {
      data.end_date = data.end_date.slice(0, 10);
      setEndDate(data.end_date);
    }
    if (data?.budget) {
      setBudget(data.budget);
    }

    if (data?.feedback_form) {
      setSelectedType(type[Number(data.feedback_form - 1)]);
    }

    if (data?.form_template) {
      setElements(data.form_template);
    }
  }, [location.state]);

  const handleAddWheel = (e) => {
    e.preventDefault();
    if (getWheelByCampaignIdData?.body?.length) {
      toast.error("Wheel Already Exists !");
      return;
    }
    if (wheelType.id == 0) {
      toast.error("Select Wheel Type", {
        id: "wheel_type",
      });
      return;
    }
    const userTypeIds = names.map((obj) => obj.user_type_id);
    const userTypeNames = names.map((obj) => obj.user_type);
    addWheel({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        wc_id,
        reward_count: reward.name,
        // bg_color: primaryBackgroundColor,
        // bg_alt_color: secondaryBackgroundColor,
        // font_color: primaryTextColor,
        // font_alt_color: secondaryTextColor,
        // line_color: outerBorderColor,
        // font_size: fontSize,
        wheel_type: wheelType,
        user_type: userTypeNames,
        user_type_id: userTypeIds,
        wheel_image: picture,
      },
    });
    getWheelByCampaignIdFun();
  };

  // UpdateWheelCampaignById

  const updateWheelCampaign = (e) => {
    e.preventDefault();
    console.log("states", states);
    const finalStates = states.map((st) => st.name);
    // return;
    UpdateWheelCampaignById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: wc_id,
      data: {
        title: title,
        description: description,
        start_date: startDate,
        end_date: endDate,
        budget: budget,
        states: finalStates,
        feedback_form: selectedType.id,
        form_template: JSON.stringify(elements),
      },
    });
  };

  // getWheelCampaignById
  useEffect(() => {
    if (UpdateWheelCampaignByIdData) {
      getWheelCampaignById();
    }
    if (UpdateWheelCampaignByIdData) {
      toast.success("Wheel Campaign Updated Successfully");
      navigate(-1);
      return;
    }
    if (UpdateWheelCampaignByIdError) {
      toast.error("Wheel Campaign Updated Successfully");
      return;
    }
  }, [UpdateWheelCampaignByIdData, UpdateWheelCampaignByIdError]);

  const updateWheel = (e) => {
    e.preventDefault();
    if (wheelType.id == 0) {
      toast.error("Select Wheel Type", {
        id: "wheel_type",
      });
      return;
    }
    console.log("wheel_fixed_reward", fixedReward);
    // return;

    const userTypeIds = names.map((obj) => obj.user_type_id);
    const userTypeNames = names.map((obj) => obj.user_type);

    UpdateWheelById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: wheelId,
      data: {
        wc_id,
        reward_count: reward.name,
        // bg_color: primaryBackgroundColor,
        // bg_alt_color: secondaryBackgroundColor,
        // font_color: primaryTextColor,
        // font_alt_color: secondaryTextColor,
        // line_color: outerBorderColor,
        // font_size: fontSize,
        wheel_type: wheelType.id,
        user_type: userTypeNames,
        user_type_id: userTypeIds,
        wheel_image: picture,
        wheel_fixed_reward : fixedReward?.id ? fixedReward.id : -1,
      },
    });
    getAllWheelRewardsFun();
  };

  useEffect(() => {
    if (updateWheelByIdData) {
      toast.dismiss();
      toast.success("Wheel Updated Successfully", {id : "updateWheelByIdData"});
      console.log("Wheel Updated Successfully");
      return;
    }
    if (updateWheelByIdError) {
      toast.dismiss();
      toast.error("Something went wrong", {id : "updateWheelByIdError"});
      console.log("updateWheelByIdError", updateWheelByIdError);
    }
  }, [updateWheelByIdData, updateWheelByIdError]);

  const handleAddRewards = (e) => {
    e.preventDefault();
    if (validateForm()) {
      renderTimes.forEach((obj, index) => {
        const formData = new FormData();
        Object.entries(obj).forEach(([key, value]) => {
          formData.append(`${key}`, value);
        });

        addWheelRewards({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          data: formData,
        });

        formData.delete("*");
      });
    }

    getAllWheelRewardsFun();
  };

  useEffect(() => {
    // const arr = Array.from({ length: +reward.name }, () => obj);
    const arr = [];
    for (let i = 0; i < Number(reward.name); i++) {
      obj.windex = i;
      const newObj = { ...obj };
      arr.push(newObj);
    }
    setrenderTimes(arr);
  }, [reward]);

  // const [backgroundColor, setBackgroundColour] = useState([
  //   primaryBackgroundColor,
  //   secondaryBackgroundColor,
  // ]);
  // const [textColors, setTextColours] = useState([
  //   primaryTextColor,
  //   secondaryTextColor,
  // ]);

  // const handlePrimaryBackgroundColorChange = (event) => {
  //   const selectedColor = event.target.value;
  //   setPrimaryBackgroundColor(selectedColor);
  // };

  // const handleFontChange = (event) => {
  //   setFontSize(event.target.value);
  // };

  // const handleSecondaryBackgroundColorChange = (event) => {
  //   const selectedColor = event.target.value;
  //   setSecondaryBackgroundColor(selectedColor);
  // };

  // const handlePrimaryTextColorChange = (event) => {
  //   const selectedColor = event.target.value;
  //   setPrimaryTextColor(selectedColor);
  // };

  // const handleSecondaryTextColorChange = (event) => {
  //   const selectedColor = event.target.value;
  //   setSecondaryTextColor(selectedColor);
  // };

  // useEffect(() => {
  //   setTextColours([primaryTextColor, secondaryTextColor]);
  // }, [primaryTextColor, secondaryTextColor]);

  // useEffect(() => {
  //   setBackgroundColour([primaryBackgroundColor, secondaryBackgroundColor]);
  // }, [primaryBackgroundColor, secondaryBackgroundColor]);

  useEffect(() => {
    if (reward && reward.name) {
      const newData = Array.from({ length: +reward.name }, () => ({
        option: "",
      }));
      setData(newData);
    }
  }, [reward]);

  // console.log("names", names);

  return (
    <div className="w-full  flex flex-col items-center justify-center">
      <div className="w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          {!edit ? "Add " : "Update "} Wheel Component
          <LinksComponent />
        </div>
        <div className="p-3">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 flex-row justify-between gap-3"
            role="tablist"
          >
            <li className="-mb-px  last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-sm font-bold uppercase px-5 py-3 rounded-t-lg block leading-normal " +
                  (openTab === 1 ? "text-blue " : "text-black bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Details
              </a>
            </li>
            <li className="-mb-px  last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-sm font-bold uppercase px-5 py-3 rounded-t-lg block leading-normal " +
                  (openTab === 2 ? "text-blue " : "text-black bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Wheel
              </a>
            </li>
            <li className="-mb-px  last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-sm font-bold uppercase px-5 py-3 rounded-t-lg block leading-normal " +
                  (openTab === 3 ? "text-blue " : "text-black bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Rewards
              </a>
            </li>
          </ul>
          <div className="p-3 w-full bg-white shadow rounded-lg">
            <div className="">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <form className="">
                    <div className="w-full  flex justify-center space-x-3">
                      <div className="mb-2 w-1/2">
                        <div className="w-full">
                          <InputComponent
                            name={"Title"}
                            type={"text"}
                            value={title}
                            setValue={setTitle}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="mb-2 w-1/2">
                        <div className="w-full">
                          <MultiSelectComponent
                            title={"Included States*"}
                            selected={states}
                            setSelected={setStates}
                            dataArr={allStates}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full  flex justify-center space-x-3">
                      <div className="mb-2 w-1/3">
                        <div className="w-full">
                          <InputComponent
                            name={"Start Date"}
                            type={"date"}
                            value={startDate}
                            setValue={setStartDate}
                            required={true}
                            maxLength={200}
                          />
                        </div>
                      </div>

                      <div className="mb-2 w-1/3">
                        <div className="w-full">
                          <InputComponent
                            name={"End Date"}
                            type={"date"}
                            value={endDate}
                            setValue={setEndDate}
                            required={true}
                            maxLength={200}
                          />
                        </div>
                      </div>

                      <div className="mb-2 w-1/3">
                        <div className="w-full">
                          <InputComponent
                            name={"Budget"}
                            type={"number"}
                            value={budget}
                            setValue={setBudget}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="mb-2 w-1/3 ">
                        <SelectComponent
                          title={"Feedback Form"}
                          selected={selectedType}
                          setSelected={setSelectedType}
                          dataArr={type}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="mb-2 w-full">
                      <InputComponent
                        name={"Description"}
                        type={"textarea"}
                        value={description}
                        setValue={setDescription}
                        required={true}
                        maxLength={200}
                      />
                    </div>

                    {(selectedType.id == 2 || selectedType.id == 3) && (
                      <div>
                        <div className="border-gray-300 shadow-sm">
                          <div className="row">
                            <div>
                              <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={open}>
                                  <Box sx={style}>
                                    <Typography
                                      id="transition-modal-title"
                                      variant="h6"
                                      component="h2"
                                    >
                                      Preview
                                    </Typography>
                                    {elements.map((element, idx) => {
                                      return element.type === "select" ? (
                                        <div
                                          className="col-md-12 my-2"
                                          key={idx}
                                        >
                                          <div className="d-flex my-1">
                                            <span className="p-2">
                                              {element.label}{" "}
                                            </span>
                                          </div>
                                          <select className="my-2 py-1">
                                            <option value={"Select Options"}>
                                              Select Options
                                            </option>

                                            {element.options.map((opt) => (
                                              <option value={opt}>{opt}</option>
                                            ))}
                                          </select>
                                        </div>
                                      ) : (
                                        <div
                                          className="col-md-12 my-2"
                                          key={idx}
                                        >
                                          <div className="d-flex my-1">
                                            <span className="p-2">
                                              {element.label}{" "}
                                            </span>
                                          </div>
                                          <input
                                            className="form-control"
                                            type={element.type}
                                            maxLength={element.maxLength}
                                            placeholder={element.label}
                                            required={element.required}
                                          />
                                        </div>
                                      );
                                    })}
                                  </Box>
                                </Fade>
                              </Modal>
                            </div>

                            <div className="mt-2 col-lg-12 grid-margin stretch-card chng_crd">
                              <div className="">
                                <h2 className="text-capitalize font-bold text-lg p-2">
                                  Update Feedback Form
                                </h2>
                                <div className="card-body">
                                  <div className="cmxform " id="feedbackForm">
                                    <div className="form-group ">
                                      <div>
                                        <label className="text-capitalize font-semibold">
                                          New Input
                                        </label>
                                      </div>
                                      <div className="d-flex form_feed ">
                                        <label>
                                          <input
                                            type="text"
                                            className="border p-2 wdt"
                                            placeholder="Add Name"
                                            value={name}
                                            onChange={(e) =>
                                              setName(e.target.value)
                                            }
                                          />
                                        </label>
                                        <label>
                                          <input
                                            type="text"
                                            className="border p-2 wdt"
                                            placeholder="Add Label Name"
                                            value={lableName}
                                            onChange={(e) =>
                                              setLableName(e.target.value)
                                            }
                                          />
                                        </label>
                                        <label>
                                          <input
                                            type="number"
                                            className="border p-2 wdt1"
                                            placeholder="Max Length"
                                            value={inputLength}
                                            onChange={(e) =>
                                              setInputLength(e.target.value)
                                            }
                                          />
                                        </label>
                                        <label>
                                          <select
                                            className="border p-2 wdt"
                                            value={inputType}
                                            onChange={(e) =>
                                              handleInputTypeChange(e)
                                            }
                                          >
                                            <option value={""}>
                                              Select Type
                                            </option>
                                            <option value={"text"}>Text</option>
                                            <option value={"textarea"}>
                                              Textarea
                                            </option>
                                            <option value={"number"}>
                                              Number
                                            </option>
                                            <option value={"date"}>Date</option>
                                            <option value={"file"}>File</option>
                                            <option value={"checkbox"}>
                                              Checkbox
                                            </option>
                                            <option value={"select"}>
                                              Options
                                            </option>
                                          </select>
                                        </label>

                                        {inputType === "select" ? (
                                          <label>
                                            {selectOption.map((key) => (
                                              <label key={key}>
                                                <span
                                                  className="px-2 py-2 mx-2 btn btn-outline-danger"
                                                  onClick={(e) =>
                                                    removeFromSelectOptions(
                                                      e,
                                                      key,
                                                    )
                                                  }
                                                >
                                                  {key}
                                                </span>
                                              </label>
                                            ))}

                                            <input
                                              type="text"
                                              className="border p-2 wdt1"
                                              placeholder="Add new option"
                                              value={newUserValue}
                                              onChange={(e) =>
                                                setNewUserValue(e.target.value)
                                              }
                                              onKeyDown={(e) => {
                                                if (e.keyCode == 13) {
                                                  let oldUserTypes = [
                                                    ...selectOption,
                                                  ];
                                                  oldUserTypes.push(
                                                    newUserValue,
                                                  );
                                                  setSelectOptions(
                                                    oldUserTypes,
                                                  );
                                                  setNewUserValue("");
                                                }
                                              }}
                                            />
                                          </label>
                                        ) : (
                                          <label></label>
                                        )}
                                        <label className="d-flex">
                                          <input
                                            type="checkbox"
                                            className="px-1 py-1"
                                            checked={required ? "checked" : ""}
                                            onChange={() =>
                                              setRequired(!required)
                                            }
                                          />{" "}
                                          <span className="px-1 py-1 ">
                                            Required
                                          </span>
                                        </label>

                                        <button
                                          type="button"
                                          className="btn btn-outline-primary mtms"
                                          onClick={() => {
                                            setReRender(!reRender);
                                          }}
                                        >
                                          Add
                                        </button>
                                      </div>

                                      {elements.map((element, idx) => {
                                        return element.type === "select" ? (
                                          <div
                                            className="my-2 show_wt"
                                            key={idx}
                                          >
                                            <div className="d-flex my-1 justify-between">
                                              <span className="text_show">
                                                {element.label}{" "}
                                              </span>
                                              <span
                                                className="close_show text-danger"
                                                onClick={(e) =>
                                                  handleDeleteElement(e, idx)
                                                }
                                              >
                                                x
                                              </span>{" "}
                                            </div>
                                            <select className="selctws ">
                                              <option value={"Select Options"}>
                                                Select Options
                                              </option>

                                              {element.options.map((opt) => (
                                                <option value={opt}>
                                                  {opt}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        ) : (
                                          <div
                                            className="show_wt my-2"
                                            key={idx}
                                          >
                                            <div className="d-flex my-1 justify-between">
                                              <span className="text_show">
                                                {element.label}{" "}
                                              </span>
                                              <span
                                                className="close_show text-danger"
                                                onClick={(e) =>
                                                  handleDeleteElement(e, idx)
                                                }
                                              >
                                                x
                                              </span>
                                            </div>

                                            <input
                                              className="form-control ckbox_show"
                                              type={element.type}
                                              maxLength={element.maxLength}
                                              placeholder={element.label}
                                              required={element.required}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex justify-start mt-4">
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={updateWheelCampaign}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <form className="">
                    <div className="flex  w-full">
                      <div className="w-1/2">
                        <div className="mb-6">
                          <SelectComponent
                            title={"No. of Rewards*"}
                            selected={reward}
                            setSelected={setReward}
                            dataArr={rewardLengths}
                          />
                        </div>

                        <div className="mb-6">
                          <SelectComponent
                            title={"Wheel Type"}
                            selected={wheelType}
                            setSelected={setWheelType}
                            dataArr={wheelTypes}
                          />
                        </div>

                        <div className="mb-6">
                      { rewardsSbmt == false && wheelType.id == "2" && (
                        <>
                          <SelectComponent
                            title={"Fixed Wheel Reward"}
                            selected={fixedReward}
                            setSelected={setFixedReward}
                            dataArr={renderTimes.length > 0 ? renderTimes : []}
                          />
                        </>
                      )}
                    </div>

                        {/* 
                        <div className="w-full  flex justify-center space-x-3">
                          <div className="mb-3 w-1/2">
                            <div className="w-full">
                              <label
                                htmlFor="primaryBackgroundColour"
                                className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                              >
                                Background Colour
                              </label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="color"
                                value={primaryBackgroundColor}
                                onChange={handlePrimaryBackgroundColorChange}
                              />
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="text"
                                value={primaryBackgroundColor}
                                onChange={handlePrimaryBackgroundColorChange}
                              />
                            </div>
                          </div>

                          <div className="mb-3 w-1/2">
                            <div className="w-full">
                              <label
                                htmlFor="secondaryBackgroundColour"
                                className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                              >
                                Secondary Background Colour
                              </label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="color"
                                value={secondaryBackgroundColor}
                                onChange={handleSecondaryBackgroundColorChange}
                              />
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="text"
                                value={secondaryBackgroundColor}
                                onChange={handleSecondaryBackgroundColorChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full  flex justify-center space-x-3">
                          <div className="mb-3 w-1/2">
                            <div className="w-full">
                              <label
                                htmlFor="fontSize"
                                className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                              >
                                Font Size
                              </label>
                              <input
                                type="number"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                value={fontSize}
                                onChange={handleFontChange}
                              />
                            </div>
                          </div>

                          <div className="mb-3 w-1/2">
                            <div className="w-full">
                              <label
                                htmlFor="secondaryBackgroundColour"
                                className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                              >
                                Font Colour
                              </label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="color"
                                value={primaryTextColor}
                                onChange={handlePrimaryTextColorChange}
                              />
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="text"
                                value={primaryTextColor}
                                onChange={handlePrimaryTextColorChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full  flex justify-center space-x-3">
                          <div className="mb-3 w-1/2">
                            <div className="w-full">
                              <label
                                htmlFor="secondaryBackgroundColour"
                                className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                              >
                                Secondary Font Colour
                              </label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="color"
                                value={secondaryTextColor}
                                onChange={handleSecondaryTextColorChange}
                              />
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="text"
                                value={secondaryTextColor}
                                onChange={handleSecondaryTextColorChange}
                              />
                            </div>
                          </div>

                          <div className="mb-3 w-1/2">
                            <div className="w-full">
                              <label
                                htmlFor="lineColour"
                                className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                              >
                                Line Colour
                              </label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="color"
                                value={outerBorderColor}
                                onChange={(e) => {
                                  setOuterBorderColor(e.target.value);
                                }}
                              />
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="text"
                                value={outerBorderColor}
                                onChange={(e) => {
                                  setOuterBorderColor(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div> */}

                        {/* {userTypes && (
                          <div className="mb-6 select_mlt">
                            <InputLabel>User Types*</InputLabel>
                            <Select
                              className=" shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full min-h-0  "
                              multiple
                              value={names}
                              onChange={(e) => {
                                setUserNames(e.target.value);
                              }}
                              input={<OutlinedInput label="Multiple Select" />}
                            >
                              {userTypes.map((e) => (
                                <MenuItem key={e.name} value={e}>
                                  {e.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        )} */}

                        {
                          <div className="w-full mb-6">
                            <MultiSelectComponent
                              title={"Select User Types States"}
                              required={true}
                              selected={names}
                              setSelected={setUserNames}
                              dataArr={
                                userTypes && userTypes.length > 0
                                  ? userTypes
                                  : []
                              }
                            />
                          </div>
                        }

                        <div class="w-full">
                          <div className="borderbox_upl shadow-sm">
                            <h2 className="mb-2">Upload Wheel Image</h2>
                            <div class="input-group mb-3 px-2 py-2 w-full border-2 rounded-pill bg-white shadow-sm d-flex justify-content-center align-items-center">
                              <input
                                id="upload-login"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  // setThumbnailLogo(URL.createObjectURL(e.target.files[0]));
                                  setPictureFile(e.target.files);
                                }}
                                class="form-control border-0"
                                style={{ display: "none" }}
                              />
                              {/* <label for="upload-login" class="font-weight-light">Choose file</label> */}
                              <div class="input-group-append w-full text-center">
                                <label
                                  for="upload-login"
                                  class="btn btn-light m-0 rounded-pill px-4 d-flex align-items-center w-full  justify-center "
                                >
                                  <FaCloudUploadAlt class="mr-2" />
                                  <small class="text-uppercase text-truncate font-weight-bold text-muted">
                                    Choose file :{" "}
                                    <span className="font-weight-light text-truncate text-black text-center">
                                      {pictureFile &&
                                        pictureFile.length > 0 &&
                                        pictureFile[0].name.slice(0, 10)}
                                    </span>
                                  </small>
                                </label>
                              </div>
                            </div>
                            {pictureFile && pictureFile.length > 0 && (
                              <>
                                {uploadImageIsLoading ? (
                                  <div
                                    class="spinner-border text-dark"
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  <button
                                    className="btn btn-primary mb-2 "
                                    onClick={() =>
                                      uploadImageFunc(pictureFile, 0)
                                    }
                                  >
                                    Upload
                                  </button>
                                )}
                                <p class="font-italic text-black text-center">
                                  The image uploaded will be rendered below.
                                </p>
                              </>
                            )}

                            <div class="image-area my-2 w-20 imgcn1 ">
                              <img
                                id="imageResult"
                                src={picture ? picture : ""}
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="w-1/2 bg-gray-50 rounded-lg flex justify-center items-center m-3">
                        <Wheels
                          data={data}
                          // backgroundColor={backgroundColor}
                          // textColors={textColors}
                          // outerBorderColor={outerBorderColor}
                          // fontSize={fontSize}
                        />
                      </div> */}
                    </div>
                    {!edit ? (
                      <div className="flex justify-center mt-4">
                        <button
                          type="submit"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  "
                          onClick={handleAddWheel}
                        >
                          Add
                        </button>
                      </div>
                    ) : (
                      <div className="flex justify-center mt-4">
                        <button
                          type="submit"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  "
                          onClick={updateWheel}
                        >
                          Update
                        </button>
                      </div>
                    )}
                  </form>
                </div>

                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <form className="my-2">
                    {/* <div className="bg-gray-50 rounded-lg mb-3 p-3">
                      <Wheels
                        data={data}
                        // backgroundColor={backgroundColor}
                        // textColors={textColors}
                        // outerBorderColor={outerBorderColor}
                        // fontSize={fontSize}
                      />
                    </div> */}
                    {renderTimes.map((obj, index) => (
                      <div
                        id={"childDiv" + index}
                        className="border-2 bg-gray-200 p-2 mb-1.5 rounded-md"
                      >
                        <div className="w-full flex justify-start space-x-3">
                          <div className="mb-6 w-1/6">
                            <label htmlFor="">Type*</label>
                            <select
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  h-auto"
                              value={obj.type}
                              onChange={(e) => {
                                stateChange(index, "type", e.target.value);
                              }}
                              required
                            >
                              <option value="">Select type...</option>
                              {types.map((option) => (
                                <option
                                  key={option.name}
                                  value={option.name}
                                  selected={option.name === obj.type}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {obj.type === "Coupon" ? (
                            <div className="mb-6 w-1/6">
                              <label htmlFor="">Coupon Provider *</label>
                              <select
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  h-auto"
                                value={obj.name}
                                onChange={(e) => {
                                  console.log(e.target.value);

                                  let qty = 0;

                                  for (let i = 0; i < couponList?.length; i++) {
                                    if (
                                      couponList[i].name.toLowerCase() ===
                                      e.target.value.toLowerCase()
                                    ) {
                                      qty = couponList[i].qty;
                                      break;
                                    }
                                  }
                                  console.log("here", qty);
                                  stateChange(index, "name", e.target.value);
                                  // stateChange(index, "quantity", qty);
                                }}
                              >
                                <option value="">Select Provider...</option>
                                {couponList.map((option) => (
                                  <option
                                    key={option.id}
                                    value={option.name}
                                    selected={option.name === obj.name}
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : obj.type === "Filler" ? (
                            <div className="mb-6 w-1/6">
                              <label htmlFor="">Name*</label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="text"
                                value={
                                  obj.name ? obj.name : "Better Luck Next Time"
                                }
                                required // Add required attribute
                                onChange={(e) => {
                                  stateChange(index, "name", e.target.value);
                                  const updatedData = [...data];
                                  updatedData[index].name = e.target.value;
                                  setData(updatedData);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="mb-6 w-1/6">
                              <label htmlFor="">Name*</label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="text"
                                value={obj.name}
                                required // Add required attribute
                                onChange={(e) => {
                                  stateChange(index, "name", e.target.value);
                                  const updatedData = [...data];
                                  updatedData[index].name = e.target.value;
                                  setData(updatedData);
                                }}
                              />
                            </div>
                          )}

                          <div className="mb-6 w-1/6">
                            <label htmlFor="Index*">Index</label>
                            <input
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                              type="text"
                              value={obj.windex}
                              required
                              disabled
                              // onChange={(e) =>
                              //   stateChange(index, "windex", e.target.value)
                              // }
                            />
                          </div>
                          <div className="mb-6 w-1/6">
                            <label htmlFor="">Status*</label>
                            <select
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  h-auto"
                              value={obj.status}
                              onChange={(e) => {
                                stateChange(index, "status", e.target.value);
                              }}
                              required
                            >
                              <option value="">Select Status...</option>
                              {status.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.id}
                                  selected={option.id === obj.status}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="mb-6 w-1/6">
                            <label htmlFor="">Price</label>
                            <input
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                              type="number"
                              value={obj.price}
                              required
                              onChange={(e) =>
                                stateChange(index, "price", e.target.value)
                              }
                            />
                          </div>

                          <div className="mb-6 w-1/6">
                            <label htmlFor="image">
                              Image(
                              <span className="text-xs">Size : 86*86</span>)
                            </label>

                            <br />
                            <input
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  h-auto"
                              type="file"
                              onChange={(e) => {
                                const imageFiles = e.target.files;
                                const imageFilesLength = imageFiles.length;
                                let img = [];
                                if (imageFilesLength && imageFiles.length) {
                                  for (let i = 0; i < imageFilesLength; i++) {
                                    const imageSrc = URL.createObjectURL(
                                      imageFiles[i],
                                    );
                                    img.push(imageSrc);
                                  }
                                  setImages(img);
                                }
                                const updatedData = data.map((item, idx) => {
                                  if (idx === index) {
                                    return {
                                      ...item,
                                      image: { uri: img[0] },
                                    };
                                  }
                                  return item;
                                });
                                setData(updatedData);
                                stateChange(index, "image", e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>

                        <div className="w-full flex justify-start space-x-3">
                          <div className="mb-6 w-1/6">
                            <label htmlFor="">Start Date</label>
                            <input
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                              type="date"
                              value={obj.startDate}
                              maxLength={200}
                              onChange={(e) =>
                                stateChange(index, "startDate", e.target.value)
                              }
                            />
                          </div>

                          <div className="mb-6 w-1/6">
                            <label htmlFor="">End Date</label>
                            <input
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                              type={"date"}
                              value={obj.endDate}
                              maxLength={200}
                              onChange={(e) =>
                                stateChange(index, "endDate", e.target.value)
                              }
                            />
                          </div>

                          {/* <div className="mb-6 w-1/6">
                            <label htmlFor="offSet">Offset</label> <br />
                            <input
                              type="range"
                              min="-80"
                              max="80"
                              step={1}
                              value={obj.offsetx}
                              onChange={(e) => {
                                stateChange(index, "offsetx", e.target.value);
                                if (data[index].image) {
                                  const updatedData = [...data];
                                  updatedData[index].image.offsetX = Number(
                                    e.target.value,
                                  );
                                  setData(updatedData);
                                }
                              }}
                              disabled={!data[index].image}
                            />
                            <input
                              type="range"
                              min="-80"
                              max="80"
                              value={obj.offsety}
                              onChange={(e) => {
                                stateChange(index, "offsety", e.target.value);
                                if (data[index].image) {
                                  const updatedData = [...data];
                                  updatedData[index].image.offsetY = Number(
                                    e.target.value,
                                  );
                                  setData(updatedData);
                                }
                              }}
                              disabled={!data[index].image}
                            />
                          </div> */}

                          <div className="mb-6 w-1/6">
                            <label htmlFor="">Probability</label>
                            <input
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                              type="number"
                              value={obj.probability}
                              onChange={(e) => {
                                if (e.target.value <= 100) {
                                  stateChange(
                                    index,
                                    "probability",
                                    e.target.value,
                                  );
                                }
                              }}
                            />
                          </div>

                          <div className="mb-6 w-1/6">
                            <label htmlFor="">Quantity</label>
                            <input
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                              type="number"
                              value={obj.quantity}
                              onChange={(e) =>
                                stateChange(index, "quantity", e.target.value)
                              }
                            />
                          </div>
                          <div className=" w-1/6">
                          {obj.image && <img src={obj.image} alt={obj.windex}  className="img-fluid flex items-center justify-center w-20 m-auto " />}
</div>
                          {obj.type === "Point" && (
                            <div className="mb-6 w-1/6">
                              <label htmlFor="">Points</label>
                              <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                type="number"
                                value={obj.points}
                                onChange={(e) =>
                                  stateChange(index, "points", e.target.value)
                                }
                              />
                            </div>
                          )}
                        </div>
                        {/* <hr className="horizontal-line pb-1" /> */}
                      </div>
                    ))}

                    {openTab == 3 && rewardsSbmt ? (
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                          onClick={handleAddRewards}
                        >
                          Submit
                        </button>
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                          onClick={updateWheelRewards}
                        >
                          Update
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wheel;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
