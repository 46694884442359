import React from "react";

const InputComponent = ({
  name,
  type,
  placeholder,
  value,
  setValue,
  required,
  maxLength,
  disabled,
  rows,
}) => {
  if (type === "textarea") {
    return (
      <>
        <div className="w-full relative">
          <label
            htmlFor={name}
            className="po block text-sm font-medium capitalize leading-6 text-gray-900 mb-2"
          >
            {name} {required ? "*" : ""}{" "}
          </label>
          <textarea
            type="textarea"
            id={name}
            className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  "
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            maxLength={maxLength}
            disabled={disabled}
            rows={rows}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="w-full">
          <label
            htmlFor={name}
            className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-0"
          >
            {name} {required ? "*" : ""}{" "}
          </label>
          <input
            type={type}
            id={name}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            maxLength={maxLength}
            disabled={disabled}
          />
        </div>
      </>
    );
  }
};

export default InputComponent;
