import React, { useEffect, useState } from "react";
import qr from "../../../assests/tenant/images/qr.jpg";
import nodata from "../../../assests/tenant/images/nodata.jpg";
import { useFetchQrScanHistoryMutation } from "../../../features/tenant/appUserQrScan/appUserQrScanApi";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";

const Tracking = () => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const initialRequest = {
    unique_code: "",
    batch_running_code: "",
    limit: 10,
    offset: 0,
  };
  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.scanned_by_name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Batch Code",
      cell: (row) => row.batch_code,
      sortable: true,
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
      sortable: true,
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
    },
    {
      name: "Qr Code",
      cell: (row) => row.unique_code,
      sortable: true,
      width: "150px",
    },
    {
      name: "Scanned At",
      cell: (row) => new Date(row.scanned_at).toLocaleString(),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "City",
      cell: (row) => row.scan_city,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Pincode",
      cell: (row) => row.scan_pincode,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "District",
      cell: (row) => row.scan_district,
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "State",
      cell: (row) => row.scan_state,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Address",
      cell: (row) => row.scan_address,
      sortable: true,
      width: "400px",
    },
  ];

  const [request, setRequest] = useState(initialRequest);

  const location = useLocation();

  useEffect(() => {
    console.log("location", location.state);

    if(location.state){
      request["unique_code"] = location.state.unique_code;
      request["batch_running_code"] = location.state.batch_running_code;
    }

    if (request.unique_code !== "" || request.batch_running_code !== "") {
      fetchUserQrScanFunction();
    }
  }, [limit, offset , location.state]);

  const [fetchUserQrScan, { data: qrScanData, error: qrScanError }] =
    useFetchQrScanHistoryMutation();

  const fetchUserQrScanFunction = () => {
    fetchUserQrScan({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Tracking
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className=" bg-white shadow rounded-lg p-3">
            <div className="w-full">
              <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                Unique Code
              </label>
              <div className="flex justify-between gap-3 mb-3">
                <div className="w-full ">
                  <div className="relative searchin">
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 py-2 h-auto text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Qr Code..."
                      required
                      value={request.unique_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, unique_code: newValue };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-full ">
                  <div className="relative searchin">
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 py-2 h-auto text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Batch Running Code..."
                      required
                      value={request.batch_running_code}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            batch_running_code: newValue,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <button
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchUserQrScanFunction();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
            {qrScanData && qrScanData.body.qrs&&qrScanData.body.qrs.length>0 ? (
              <div className="mt-3 ">
                <div className="flex gap-3  mb-3">
                  <div className="border border-gray-200 p-2 flex justify-center items-center w-1/5">
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={`${
                        process.env.REACT_APP_QR_URL
                      }genefied/verifyQr?qrCode=${
                        qrScanData?.body?.qrs?.length > 0 &&
                        qrScanData?.body?.qrs[0]?.unique_code
                      }`}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                  <div className="w-4/5">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <tr className="text-sm border border-gray-200 dark:border-gray-700">
                        <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                          QR ID
                        </th>
                        <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                          {qrScanData?.body?.qrs?.length > 0 &&
                            qrScanData?.body?.qrs[0]?.qr_id}
                        </td>
                        <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                          QR
                        </th>
                        <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                          {process.env.REACT_APP_QR_URL}
                          genefied/verifyQr?qrCode=
                          {qrScanData?.body?.qrs?.length > 0 &&
                            qrScanData?.body?.qrs[0]?.unique_code}
                        </td>
                      </tr>

                      <tr className="text-sm border border-gray-200 dark:border-gray-700">
                        <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                          GENERATED ON
                        </th>
                        <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                          {qrScanData?.body?.qrs?.length > 0 &&
                            qrScanData?.body?.qrs[0]?.created_at}
                        </td>
                      </tr>

                      <tr className="text-sm border border-gray-200 dark:border-gray-700">
                        <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                          PRODUCT NAME
                        </th>
                        <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                          {qrScanData?.body?.qrs?.length > 0 &&
                            qrScanData?.body?.qrs[0]?.product_name}
                        </td>
                      </tr>

                      <tr className="text-smborder border-gray-200 dark:border-gray-700">
                        <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                          CATEGORY
                        </th>
                        <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                          {qrScanData?.body?.qrs?.length > 0 &&
                            qrScanData?.body?.qrs[0]?.category_name}
                        </td>
                      </tr>

                      <tr className="text-smborder border-gray-200 dark:border-gray-700">
                        <th className="text-sm text-left border border-gray-200 uppercase px-2.5 py-2.5 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                          BATCH CODE
                        </th>
                        <td className="text-sm border border-gray-200 uppercase px-2.5 py-2.5 text-gray-900">
                          {qrScanData?.body?.qrs?.length > 0 &&
                            qrScanData?.body?.qrs[0]?.batch_code}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="p-3 w-full bg-white shadow rounded-lg">
                  <DataTableComponent
                    columns={columns}
                    // title={""}
                    data={qrScanData && qrScanData.body.qrs}
                    // loading={loading}
                    totalRows={qrScanData?.body?.total_scans}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                  ></DataTableComponent>
                </div>
              </div>
            ) : (
              <span className="block text-center  text-lg font-bold text-gray-300">
                <img
                  src={nodata}
                  alt=""
                  className="m-auto max-w-full max-h-full"
                />
                No Data Available
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
