import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const PointSharing = ({
  open,
  setOpen,
  userTypes,
  handleAddPointSharing,
  pointsSharing,
}) => {
  console.log("UserTYpe", userTypes);
  console.log("pointsSharing", pointsSharing);
  const [selectedUserTypes, setSelectedUserTypes] = useState({});

  const [directPoint, setDirectPoint] = useState();
  const [percentagePoint, setPercentagePoint] = useState();
  const [percentagePointValue, setPercentagePointValue] = useState();
  const [selectedTarget, setSelectedTarget] = useState();
  const [percentages, setPercentages] = useState();
  const [pointSharingCheck, setPointSharingCheck] = useState(false);
  const [pointReservedCheck, setPointReservedCheck] = useState(false);
  const [pointReservedPercentage, setPointReservedPercentage] = useState();

  // const [directPoint, setDirectPoint] = useState(()=>pointsSharing.flat_points);
  // const [percentagePoint, setPercentagePoint] = useState(()=>pointsSharing.percentage_points);
  // const [percentagePointValue, setPercentagePointValue] = useState(()=>pointsSharing.percentage_points_value);
  // const [selectedTarget, setSelectedTarget] = useState(()=>pointsSharing.point_sharing_bw_user.user);
  // const [percentages, setPercentages] = useState(()=>pointsSharing.point_sharing_bw_user.percentage);

  console.log("directPoint", directPoint);
  console.log("percentagePoint", percentagePoint);
  console.log("selectedTarget", selectedTarget);
  console.log("percentages", percentages);

  useEffect(() => {
    setDirectPoint(pointsSharing.flat_points);
    setPercentagePoint(pointsSharing.percentage_points);
    setPercentagePointValue(pointsSharing.percentage_points_value);
    setSelectedTarget(pointsSharing.point_sharing_bw_user.user);
    setPercentages(pointsSharing.point_sharing_bw_user.percentage);
    setPointSharingCheck(Boolean(pointsSharing.is_point_sharing_bw_user));
    setPointReservedCheck(Boolean(pointsSharing.is_point_reserved));
    setPointReservedPercentage(pointsSharing.point_reserved_percentage);
  }, [pointsSharing]);

  const handleCheckUserTypeChange = (event) => {
    setSelectedUserTypes({
      ...selectedUserTypes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSelectChange = (event, name) => {
    setSelectedTarget({
      ...selectedTarget,
      [name]: event.target.value,
    });
  };

  const handleDirectPointChange = () => {
    setDirectPoint(!directPoint);
    setPercentagePoint(false);
  };

  const handlePercentagePointChange = () => {
    setPercentagePoint(!percentagePoint);
    setDirectPoint(false);
  };

  const handlePercentageChange = (event, name) => {
    setPercentages({
      ...percentages,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const pointsSharingObject = {
      flat_points: directPoint,
      percentage_points: percentagePoint,
      is_point_sharing: true,
      percentage_points_value: percentagePointValue,
      is_point_sharing_bw_user: pointSharingCheck,
      is_point_reserved: pointReservedCheck,
      point_reserved_percentage: pointReservedPercentage,
      point_sharing_bw_user: {
        user: selectedTarget,
        percentage: percentages,
      },
    };
    handleAddPointSharing(pointsSharingObject);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="w-full">
      <Dialog
        open={open}
        onClose={handleClose}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
      >
        <div
          style={{
            minWidth: "550px",
            height: "700px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DialogTitle variant="h5" className="pb-0 uppercase ">
            Point Sharing{" "}
          </DialogTitle>
          <DialogContent className="pt-3">
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={directPoint}
                    onChange={handleDirectPointChange}
                  />
                }
                label="Direct Point On Product"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={percentagePoint}
                    onChange={handlePercentagePointChange}
                  />
                }
                label="Points Through Product Value Percentage "
              />
              {percentagePoint && (
                <TextField
                  label="Percentage Point Value"
                  fullWidth
                  value={percentagePointValue}
                  onChange={(e) => setPercentagePointValue(e.target.value)}
                />
              )}
            </FormControl>
            <hr style={{ margin: "20px 20px" }} />

            <Typography variant="h6" style={{ color: "black" }}>
              Point Sharing Between Users
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={pointSharingCheck}
                  onChange={(e) => {
                    setPointSharingCheck(!pointSharingCheck);
                  }}
                />
              }
            />
            <div className="d-flex">
              {userTypes.map((type) => (
                <div className="mx-2" key={type}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUserTypes[type] || false}
                        onChange={handleCheckUserTypeChange}
                        name={type}
                      />
                    }
                    label={type}
                  />
                  {selectedUserTypes[type] && (
                    <FormControl fullWidth>
                      <InputLabel className="bg-white">
                        Select User Type
                      </InputLabel>
                      <Select
                        multiple
                        value={selectedTarget[type] || []}
                        onChange={(e) => handleSelectChange(e, type)}
                        className="mb-3"
                      >
                        {userTypes.map((t, i) => {
                          if (t == type) {
                            return <></>;
                          } else {
                            return (
                              <MenuItem key={i} value={t}>
                                {t}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                      {
                        <>
                          {type.toUpperCase()} % :
                          <TextField
                            label="points %"
                            fullWidth
                            value={percentages[type] || ""}
                            onChange={(e) => handlePercentageChange(e, type)}
                          />
                        </>
                      }
                    </FormControl>
                  )}
                </div>
              ))}
            </div>
            <FormControl>
              <Typography variant="h6" style={{ color: "black" }}>
                Point Reversed
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pointReservedCheck}
                    onChange={(e) => {
                      setPointReservedCheck(!pointReservedCheck);
                    }}
                  />
                }
              />

              {pointReservedCheck && (
                <TextField
                  label="Reserved Point %"
                  fullWidth
                  value={pointReservedPercentage}
                  onChange={(e) => setPointReservedPercentage(e.target.value)}
                />
              )}
            </FormControl>
          </DialogContent>
          <DialogActions className="mb-3 px-3">
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default PointSharing;
