import { shuffle } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Task from "./Task";
import boardsSlice from "../../features/admin/workflow/boardSlice";

function Column({ colIndex }) {
  const colors = ["bg-primary", "bg-succcess", "bg-secondary", "bg-danger"];

  const dispatch = useDispatch();
  const [color, setColor] = useState(null);
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const col = board?.columns.find((col, i) => i === colIndex);
  useEffect(() => {
    setColor(shuffle(colors).pop());
  }, [dispatch]);

  const handleOnDrop = (e) => {
    const { prevColIndex, taskIndex } = JSON.parse(
      e.dataTransfer.getData("text"),
    );

    if (colIndex !== prevColIndex) {
      dispatch(
        boardsSlice.actions.dragTask({ colIndex, prevColIndex, taskIndex }),
      );
    }
    // dispatch(
    //   boardsSlice.actions.dragTask({ colIndex, prevColIndex, taskIndex }),
    // );
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  return (
    col && (
      <div
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
        className="scrollbar-hide "
        style={{ width: "100%", margin: "10px" }}
      >
        <div className="mb-2 font-semibold d-flex items-center align-items-center ">
          <div
            className={`${color} mr-1 `}
            style={{
              backgroundColor: "red",
              borderRadius: "50px",
              width: "10px",
              height: "10px",
            }}
          ></div>
          {col.name} ({col.tasks.length})
        </div>

        {col.tasks.map((task, index) => (
          <Task key={index} taskIndex={index} colIndex={colIndex} />
        ))}
      </div>
    )
  );
}

export default Column;
