import { rootApi } from "../../api/rootApi";

export const staticCouponApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    bulkInsertCoupons: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/staticCoupon/bulk-insert`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    // fetchAllcoupons: builder.mutation({
    //     query: (params) => {
    //       return {
    //         method: "GET",
    //         url: `/tenant/staticCoupon?limit=${params.limit}&offset=${params.offset}`,
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + params.token,
    //           slug: params.tenant_id,
    //         },
    //       };
    //     },
    //   }),

    fetchCouponsBrand: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/staticCoupon/`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchCouponsByBrandName: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/staticCoupon/brand/?brand_name=${params.brandName}&limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateCouponByBrand: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/staticCoupon/brand`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useBulkInsertCouponsMutation,
  useFetchAllCouponsMutation,
  useFetchCouponsBrandMutation,
  useFetchCouponsByBrandNameMutation,
  useUpdateCouponByBrandMutation,
} = staticCouponApi;
