import React, { useEffect, useState } from "react";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useAddZoneMutation,
  useGetAllZonesObjectQuery,
} from "../../../features/tenant/zones/zonesApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const AddZones = () => {
  const navigate = useNavigate();
  const {
    data: getAllZonesObjectData,
    isError: getAllZonesObjectIsError,
    isLoading: getAllZonesObjectIsLoading,
    error: getAllZonesObjectError,
  } = useGetAllZonesObjectQuery({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  const [
    AddZone,
    {
      data: addZoneData,
      isError: addZoneIsError,
      isLoading: addZoneIsLoading,
      error: addZoneError,
    },
  ] = useAddZoneMutation();

  const [selectZone, setSelectZone] = useState("N");
  const [allZones, setAllZones] = useState([]);

  useEffect(() => {
    if (getAllZonesObjectData) {
      setAllZones(
        Object.keys(getAllZonesObjectData.body).map((x, index) => {
          return x;
        })
      );
    }
    if (getAllZonesObjectError) {
      toast.dismiss();
      toast.error(getAllZonesObjectError.data.message, {
        id: "getAllZonesObjectError",
      });
    }
  }, [getAllZonesObjectIsError, getAllZonesObjectData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    AddZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        data:{
            zone : selectZone
        }
    })
  };

  useEffect(()=>{
    if(addZoneData){
        toast.dismiss();
        toast.success(addZoneData.message,{
            id : "addZoneData"
        })
        navigate(-1);
    }
    if(addZoneError){
        toast.dismiss();
        toast.error(addZoneError.data.message,{
            id : "addZoneError"
        })
    }
  },[addZoneData , addZoneError])

  useEffect(()=>{
    if(addZoneIsLoading){
        toast.dismiss();
        toast.loading("Loading ...")
    }
  },[addZoneIsLoading])
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Add Zones
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className=" p-3 w-full bg-white shadow rounded-lg flex-col ">
              <form className="">
                <div className="w-full  flex justify-start space-x-3">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                  <div className="mb-6 w-1/2">
                    <div className="w-full">
                    {/* <SelectComponent
                      title={"Select Zones"}
                      selected={selectZone}
                      setSelected={setSelectZone}
                      dataArr={allZones.length > 0 ? allZones : []}
                    /> */}

<label className="block text-sm font-medium leading-6 text-gray-900 mb-0">Select Zones</label>
                    <select
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  h-auto  "
                      value={selectZone}
                      onChange={(e) => setSelectZone(e.target.value)}
                    >
                      {allZones.map((x, index) => {
                        return <option value={x}>{x}</option>;
                      })}
                    </select></div>
                  </div>

                  <div className="mb-6 w-1/2">
                    <div className="w-full">
                      <label className="block text-sm font-medium leading-6 text-gray-900 mb-0
">&nbsp;</label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={
                        getAllZonesObjectData && getAllZonesObjectData.body
                          ? getAllZonesObjectData.body[selectZone]
                          : ""
                      }
                    />
                  </div></div>
                </div>

                <div className="flex justify-center ">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddZones;
