import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import {
  useCreateTenantEmailCredentialMutation,
  useDeleteTenantEmailCredentialMutation,
  useGetAllTenantEmailCredentialMutation,
  useUpdateTenantEmailCredentialMutation,
} from "../../../features/tenant/emailCredential/emailCredentialApiTenant";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import validator from "validator";
import { getVendorName } from "../../../utils/getVendorName";

const ManageEmailCredentials = () => {
  const [
    createTenantEmailCredentials,
    {
      data: emailCredentialData,
      error: emailCredentialError,
    },
  ] = useCreateTenantEmailCredentialMutation();

  const [
    updateEmailCredential,
    {
      data: updateEmailCredentialData,
      error: updateEmailCredentialError,
    },
  ] = useUpdateTenantEmailCredentialMutation();

  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleUpdate = (e) => {
    e.preventDefault();

    toast.dismiss();
    if (
      location.state.tenant_id !==
      JSON.parse(sessionStorage.getItem("vendorData")).tenant_id
    ) {
      toast.error("Cannot Updated");
      return;
    }

    updateEmailCredential({
      token: sessionStorage.getItem("access_token"),
      id: location.state.id,
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        user_id: userId,
        password: password,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!userId) {
      toast.error("please enter user id", { id: "noUserId" });
      return;
    }

    if (!validator.isEmail(userId) && userId.length > 0) {
      toast.error("Please enter a valid email address", {
        id: "emailNotValid",
      });
      return;
    }

    if (!password) {
      toast.error("please enter password", { id: "noPassword" });
      return;
    }

    createTenantEmailCredentials({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        user_id: userId,
        password: password,
      },
    });
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (emailCredentialData && emailCredentialData.body) {
    //   console.log("email credential data", emailCredentialData);
      toast.success("Credential Added", {
        id: "emailCredentialAdded",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/email-credential`)
      }, 700);
    }

    if (emailCredentialError) {
      console.log("email credential error", emailCredentialError);
      toast.error("Something went wrong", { id: "emailCredentialError" });
    }
  }, [emailCredentialData, emailCredentialError]);

  useEffect(() => {
    if (updateEmailCredentialData && updateEmailCredentialData.body) {
      console.log("update email credential", updateEmailCredentialData);
      toast.success("Email credential updated", {
        id: "updateEmailCredential",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/email-credential`)
      }, 700);
    }

    if (updateEmailCredentialError) {
      console.log("updateEmailCredentialError", updateEmailCredentialError);
      toast.error("Something went wrong", {
        id: "updateEmailCredentialError",
      });
    }
  }, [updateEmailCredentialData, updateEmailCredentialError]);

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      setIsUpdate(true);
      setUserId(location.state.user_id);
      setPassword(location.state.password);
    }
  }, [location]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update" : "Add"} Email Credential
            <LinksComponent />
          </div>

          <div className="p-3">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}

                  <div className="flex justify-center space-x-3">
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"User Id"}
                        type={"text"}
                        value={userId}
                        setValue={setUserId}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-full">
                      <InputComponent
                        name={"Password"}
                        type={"text"}
                        value={password}
                        setValue={setPassword}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-2">
                    {isUpdate ? (
                      <button
                        type="update"
                        onClick={(e) => handleUpdate(e)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageEmailCredentials;
