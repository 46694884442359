import { rootApi } from "../../api/rootApi";

export const rewardSchemeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createRewardScheme: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/reward-scheme/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllRewardScheme: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/reward-scheme`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchRewardSchemeBySchemeId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/reward-scheme/${params.scheme_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    // updateSchemeById: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: "PUT",
    //       url: `/tenant/reward-scheme/${params.id}`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + params.token,
    //         slug: params.tenant_id,
    //       },
    //       body: params.body,
    //     };
    //   },
    // }),

    deleteRewardSchemeById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/reward-scheme/${params.scheme_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateRewardSchemeMutation,
  useFetchAllRewardSchemeMutation,
  useFetchRewardSchemeBySchemeIdMutation,
  useDeleteRewardSchemeByIdMutation,
} = rewardSchemeApi;
