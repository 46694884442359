import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import {
  useDeleteScratchableRewardByIdMutation,
  useFetchAllScratchableRewardBySchemeIdMutation,
} from "../../../features/tenant/scratchableReward/scratchableRewardAPI";

const ScratchableRewardList = () => {
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.original_price,
      sortable: true,
      width: "100px",
    },

    {
      name: "Value",
      selector: (row) => row.value ? row.value : "N/A",
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) =>
        row.image && (
          <img
            style={{ width: "50px", border: "1px solid #ddd", margin:"5px", padding:"5px", borderRadius:"5px", }}
            src={ row.image}
            alt="scratchable-reward-images"
          />
        ),
      // sortable: true,
    },
    {
      name: "Coupons",
      selector: (row) => row.coupon_count,
      sortable: true,
      width: "100px",
    },
    {
      name: "Used Coupons",
      selector: (row) => row.used_coupons,
      sortable: true,
      width: "120px",
    },
    {
      name: "Probability ",
      selector: (row) => row.probability + "%",
      sortable: true,
      width: "120px",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("add", {
                state: {scheme: {...location.state , used_coupons : totalCouponCount , total_probability : totalProbability} , data: row, isUpdate: true },
              })
            }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            Manage
          </button>
        </div>
      ),
      minWidth: "140px",
    },
    {
      name: "",
      selector: (row) => (
        <div>
          <button
            onClick={() => handleDelete(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        </div>
      ),
      minWidth: "120px",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [totalCouponCount, setTotalCouponCount] = useState(0);

  const [usedCouponCount, setUsedCouponCount] = useState(0);

  const [totalProbability, setTotalProbability] = useState(0);


  const [
    fetchAllRewardsBySchemeId,
    { data: allRewardsData, error: allRewardsError },
  ] = useFetchAllScratchableRewardBySchemeIdMutation();

  useEffect(() => {
    // const hasMembershipView = hasPermission("Membership");

    // if (!hasMembershipView) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }

    console.log(location.state);
    if (location.state) {
      fetchAllRewardsBySchemeId({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: location.state.id,
        limit: 20,
        offset: 0,
      });
    }
  }, []);

  console.log(allRewardsData);

  useEffect(() => {
    if(allRewardsData && allRewardsData.body){

      let count = 0;
      let used = 0;
      let totalProb = 0;
      allRewardsData.body.forEach( (each) => {
        count += each.coupon_count;
        used += each.used_coupons;
        totalProb += each.probability;
      } )
      setUsedCouponCount(used);
      setTotalCouponCount(count);
      setTotalProbability(totalProb);
    }
  }, [allRewardsData])
  

  console.log(totalCouponCount);

  const [
    deleteScratchableReward,
    { data: deleteScrachableRewardData, error: deleteScratchableRewardError },
  ] = useDeleteScratchableRewardByIdMutation();

  useEffect(() => {
    if (deleteScrachableRewardData) {
      console.log("deleteScrachableRewardData", deleteScrachableRewardData);
    }

    if (deleteScratchableRewardError) {
      console.log("deleteScratchableRewardError", deleteScratchableRewardError);
    }
  }, [deleteScrachableRewardData, deleteScratchableRewardError]);

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteScratchableReward({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: data.id,
                });
                fetchAllRewardsBySchemeId({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: location.state.id,
                  limit: 20,
                  offset: 0,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteScratchableReward",
      },
    );
    return;
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Scratchable Reward List
            <LinksComponent />
          </div>

          {/* {JSON.stringify(location.state)} */}

<div className="p-3">
<div className="p-3 w-full bg-white shadow rounded-lg mb-3">
{location.state && (

  
              <div className="">
                <div className="border-b border-gray-200">
                <div className="grid grid-cols-4 gap-2 pl-3 pr-3 pb-3 pt-3">
                  <div>
                  <span className="text-gray-400 text-sm  block">Name</span>
    <span className="flex text-black text-sm font-semibold  gap-1">{location.state.name}</span>
                    </div>

                    <div>
                  <span className="text-gray-400 text-sm  block">ID</span>
    <span className="flex text-black text-sm font-semibold gap-1">{location.state.id}</span>
                    </div>

                    <div>
                  <span className="text-gray-400 text-sm  block">Start Date</span>
    <span className="flex text-black text-sm font-semibold gap-1">{new Date(location.state.start_date).toLocaleDateString()}</span>
                    </div>

                    <div>
                  <span className="text-gray-400 text-sm  block">End Date</span>
    <span className="flex text-black text-sm font-semibold gap-1">{new Date(location.state.end_date).toLocaleDateString()}</span>
                    </div>
                </div>
                  </div>

                  <div className="border-b border-gray-200">
                <div className="grid grid-cols-4 gap-2 pl-3 pr-3 pb-3 pt-3">
                  <div>
                  <span className="text-gray-400 text-sm  block">Total Coupons</span>
    <span className="flex text-black text-sm font-semibold gap-1">{" "}
                    {location.state.total_coupons}{" "}</span>
                    </div>

                    <div>
                  <span className="text-gray-400 text-sm  block">Coupons Remaining</span>
    <span className="flex text-black text-sm font-semibold gap-1">{" "}
                    {totalCouponCount}{" "}</span>
                    </div>

                    <div>
                  <span className="text-gray-400 text-sm  block">Coupons Used</span>
    <span className="flex text-black text-sm font-semibold gap-1">{" "}
                    {usedCouponCount}{" "}</span>
                    </div>
                </div>
                  </div>
                
                
                
                
                
                
               
               
               

               

               

                {/* <h4 className="my-2">
                  Scheme Type :
                  <span className="text-gray-600 ">
                    {location.state.scheme_type}{" "}
                  </span>
                </h4> */}
                {location.state.scheme_type === "FIXED" && (
                  <h4 className="my-2">
                    Slab Value :
                    <span className="text-gray-600 ">
                      {" "}
                      {location.state.slab_value}{" "}
                    </span>
                  </h4>
                )}

<div className="border border-gray-200 mt-3 rounded-sm px-3 py-1 bg-gray-50">
                <div className="flex my-2 justify-between  flex-wrap ">
                  <h4 className="">
                    <span className="font-semibold">Information :</span>
                    <span className="text-gray-600 ">
                      {location.state.information}
                    </span>
                  </h4>
                </div>
                <div className="flex my-2 justify-between  flex-wrap ">
                  <h4 className="">
                    <span className="font-semibold">Terms and Conditions :</span>
                    <span className="text-gray-600 ">
                      {location.state.terms_conditions}
                    </span>
                  </h4>
                </div>
              </div></div>
            )}
</div>
          <div className="p-3 w-full bg-white shadow rounded-lg">
          
            <div className="capitalize">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={allRewardsData ? allRewardsData.body : []}
                // loading={loading}
                // totalRows={totalRows}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
              {
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() =>
                            navigate(`add`, {
                              state: { scheme: {...location.state , used_coupons : totalCouponCount , total_probability : totalProbability} },
                            })
                          }
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div></div>
        </div>
      </div>
    </>
  );
};

export default ScratchableRewardList;
