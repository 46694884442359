import React, { useEffect, useState } from "react";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { HiOutlinePlus } from "react-icons/hi2";
import { Menu } from "@headlessui/react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useAddTopicsMutation,
  useAddUsersToTopicsMutation,
  useDeleteTopicsMutation,
  useDeleteUsersTopicsMutation,
  useFetchAllTopicsMutation,
} from "../../../features/tenant/FirebaseTopics/firebaseTopicsApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import toast from "react-hot-toast";
import { downloadCSV } from "../../../utils/downloadCSV";

function parseCSV(string) {
  const csvHeader = string
    .slice(0, string.indexOf("\n"))
    .replace(/\s/g, "")
    .split(",");

  const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);

  console.log("csvRows: ", csvRows.length, csvRows);

  const array = csvRows
    .map((row) => {
      const values = [];
      let insideQuotes = false;
      let currentField = "";

      for (let char of row) {
        if (char === "," && !insideQuotes) {
          values.push(currentField.trim());
          currentField = "";
        } else if (char === '"') {
          insideQuotes = !insideQuotes;
        } else {
          currentField += char;
        }
      }

      values.push(currentField.trim());
      return values;
    })
    .filter((values) => values.length === csvHeader.length)
    .map((values) => {
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index].trim();
        return object;
      }, {});
      return obj;
    });

  return array;
}

const FirebaseTopicsList = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [topicList, setTopicList] = useState([]);
  const [totalTopics, setTotalTopics] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [topicName, setTopicName] = useState("");
  const [topicSubName, setTopicSubName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [mobileNumbers, setMobileNumbers] = useState("");
  const [mobileCsv, setMobileCsv] = useState(null);
  const [modalData, setmodalData] = useState(null);
  const [modalData3, setmodalData3] = useState(null);
  const [
    fetchAllTopics,
    {
      data: fetchAllTopicsData,
      error: fetchAllTopicsError,
      isLoading: fetchAllTopicsIsLoading,
      isError: fetchAllTopicsIsError,
    },
  ] = useFetchAllTopicsMutation();

  const [
    addTopics,
    {
      data: addTopicsData,
      error: addTopicsError,
      isLoading: addTopicsIsLoading,
      isError: addTopicsIsError,
    },
  ] = useAddTopicsMutation();

  const [
    deleteTopics,
    {
      data: deleteTopicsData,
      error: deleteTopicsError,
      isLoading: deleteTopicsIsLoading,
      isError: deleteTopicsIsError,
    },
  ] = useDeleteTopicsMutation();

  const [
    addUsersToTopics,
    {
      data: addUsersToTopicsData,
      error: addUsersToTopicsError,
      isLoading: addUsersToTopicsIsLoading,
      isError: addUsersToTopicsIsError,
    },
  ] = useAddUsersToTopicsMutation();

  const [
    deleteUsersTopics,
    {
      data: deleteUsersTopicsData,
      error: deleteUsersTopicsError,
      isLoading: deleteUsersTopicsIsLoading,
      isError: deleteUsersTopicsIsError,
    },
  ] = useDeleteUsersTopicsMutation();

  const handleFetchAllTopics = (limit, offset) => {
    fetchAllTopics({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    handleFetchAllTopics(limit, offset);
  }, [limit, offset]);

  useEffect(() => {
    if (fetchAllTopicsData) {
      setTopicList(fetchAllTopicsData.body.data);
      setTotalTopics(fetchAllTopicsData.body.total);
    }
  }, [fetchAllTopicsData]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleShowModal = (type, data) => {
    if (type === "add") {
      setTopicName("");
      setTopicSubName("");
      setShowModal(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (topicName.length == 0) {
      toast.error("Please enter the topic Name", {
        id: "version",
      });
      return;
    }
    const body = {
      topic: topicName,
    };
    if (topicSubName.length > 0) {
      body["sub_topic"] = topicSubName;
    }
    addTopics({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: body,
    });
  };

  useEffect(() => {
    if (addTopicsData) {
      toast.dismiss();
      toast.success(addTopicsData.message, {
        id: addTopicsData,
      });

      handleFetchAllTopics(limit, offset);
    }
    if (addTopicsError) {
      toast.dismiss();
      toast.error(addTopicsError.data.message, {
        id: addTopicsError,
      });
    }
  }, [addTopicsData, addTopicsError]);

  const handleDelete = (id) => {
    deleteTopics({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: id,
    });
  };

  useEffect(() => {
    if (deleteTopicsData) {
      toast.dismiss();
      toast.success(deleteTopicsData.message, {
        id: deleteTopicsData,
      });

      handleFetchAllTopics(limit, offset);
    }
    if (deleteTopicsError) {
      toast.dismiss();
      toast.error(deleteTopicsError.data.message, {
        id: deleteTopicsError,
      });
    }
  }, [deleteTopicsData, deleteTopicsError]);

  useEffect(() => {
    if (deleteUsersTopicsData) {
      toast.dismiss();
      toast.success(deleteUsersTopicsData.message, {
        id: "deleteUsersTopicsData",
      });

      handleFetchAllTopics(limit, offset);
    }
    if (deleteUsersTopicsError) {
      toast.dismiss();
      toast.error(deleteUsersTopicsError.data.message, {
        id: "deleteUsersTopicsError",
      });
    }
  }, [deleteUsersTopicsData, deleteUsersTopicsError]);

  const handleOpenModal = (data) => {
    setShowModal2(true);
    setmodalData(data);
  };
  const handleOpenModal2 = (data) => {
    setShowModal3(true);
    setmodalData3(data);
  };

  function filterValidPhoneNumbers(phoneNumbers) {
    // Regular expression to match 10-digit phone numbers
    const phoneRegExp = /^\d{10}$/;

    // Filter the array to include only valid phone numbers
    // const validPhoneNumbers = phoneNumbers.filter((number) =>
    //     phoneRegExp.test(number),
    // );

    const validPhoneNumbers = [];

    phoneNumbers.forEach((number) => {
      if (phoneRegExp.test(number)) {
        validPhoneNumbers.push({ mobile: number });
      }
    });

    return validPhoneNumbers;
  }

  const handleSendBulkMessages = (row) => {
    // console.log("row", row);
    const valid = filterValidPhoneNumbers(mobileNumbers.split(/[ ,]+/));
    console.log("valid mobiles ", valid);

    if (valid.length == 0) {
      toast.error("No Mobiles Number Added ");
    } else {
      toast(
        (t) => (
          <span>
            <h6 className="capitalize">{valid.length} valid mobile number</h6>
            <div className="mt-3 d-flex">
              <button
                className="btn btn-outline-danger btn-sm ml-auto mr-1"
                onClick={() => {
                  addUsersToTopics({
                    token: sessionStorage.getItem("access_token"),
                    tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                      .tenant_id,
                    id: row.id,
                    body: {
                      mobiles: valid,
                    },
                  });
                }}
              >
                Send
              </button>
              <button
                className="btn btn-outline-primary btn-sm mr-auto ml-1"
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                Cancel
              </button>
            </div>
          </span>
        ),
        {
          id: "sendToMobiles",
        }
      );
    }
  };
  const handleDeleteUsers = (row) => {
    // console.log("row", row);
    const valid = filterValidPhoneNumbers(mobileNumbers.split(/[ ,]+/));
    console.log("valid mobiles ", valid);

    if (valid.length == 0) {
      toast.error("No Mobiles Number Added ");
    } else {
      toast(
        (t) => (
          <span>
            <h6 className="capitalize">{valid.length} valid mobile number</h6>
            <div className="mt-3 d-flex">
              <button
                className="btn btn-outline-danger btn-sm ml-auto mr-1"
                onClick={() => {
                  deleteUsersTopics({
                    token: sessionStorage.getItem("access_token"),
                    tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                      .tenant_id,
                    id: row.id,
                    body: {
                      mobiles: valid,
                    },
                  });
                }}
              >
                Send
              </button>
              <button
                className="btn btn-outline-primary btn-sm mr-auto ml-1"
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                Cancel
              </button>
            </div>
          </span>
        ),
        {
          id: "sendToMobiles",
        }
      );
    }
  };

  useEffect(() => {
    if (addUsersToTopicsData) {
      toast.dismiss();
      toast.success(addUsersToTopicsData.message, {
        id: addUsersToTopicsData,
      });

      handleFetchAllTopics(limit, offset);
    }
    if (addUsersToTopicsError) {
      toast.dismiss();
      toast.error(addUsersToTopicsError.data.message, {
        id: addUsersToTopicsError,
      });
    }
  }, [addUsersToTopicsData, addUsersToTopicsError]);

  const handleUploadUserMobileCsv = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!e.target.files[0]) {
      toast.error("Please select a file");
      return;
    }
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      setMobileCsv(e.target.files[0]);
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);

        console.log("datas", datas);

        const mobiles = datas.map((each) => each.mobile);
        // setMobileNumbers(datas);
        setMobileNumbers(mobiles.join(","));
      };
      fileReader.readAsText(e.target.files[0]);
    }
  };
  const columns = [
    {
      name: "id",
      cell: (row) => row.id,
      width: "10%",
    },
    {
      name: "Topic Name",
      cell: (row) => (
        <div className="uppercase  ">
          {row.name.split("_").slice(1).join(" ")}
        </div>
      ),
      width: "20%",
    },
    {
      name: "No Of Users",
      cell: (row) => row.users.length,
      width: "20%",
    },
    {
      name: "",
      cell: (row) => (
        <>
          {row.status == "4" ? (
            <></>
          ) : (
            <button
              className="flex justify-center items-center rounded bg-red-600 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
              onClick={() => handleOpenModal2(row)}
            >
              Delete Users
            </button>
          )}
        </>
      ),
      width: "15%",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <button
            onClick={() => handleOpenModal(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Add Users
          </button>
        </>
      ),
      width: "15%",
    },
    {
      name: "",
      cell: (row) => (
        <>
          {row.status == "4" ? (
            <></>
          ) : (
            <button
              className="flex justify-center items-center rounded bg-red-600 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
              onClick={() => handleDelete(row.id)}
            >
              Delete
            </button>
           )}
        </>
      ),
      width: "15%",
    },

    {
      name: "",
      cell: (row) => row.created_at,
      width: "15%",
    },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Topics
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <div className="">
                <DataTableComponent
                  columns={columns}
                  data={topicList}
                  totalRows={totalTopics}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                >
                  {/* <div className="flex m-2 space-x-2 justify-end"></div> */}
                </DataTableComponent>
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => handleShowModal("add")}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"sm"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
              <div className="w-full flex justify-center ">
                <div className="flex items-center justify-center w-full mb-6">
                  <InputComponent
                    name={"Enter Topic Name*"}
                    placeholder={"Topic name "}
                    type={"text"}
                    value={topicName}
                    setValue={setTopicName}
                    required={true}
                  />
                </div>
                <div className="flex items-center justify-center w-full mb-6">
                  <InputComponent
                    name={"Enter Sub Topic Name"}
                    placeholder={"Sub Topic Name "}
                    type={"text"}
                    value={topicSubName}
                    setValue={setTopicSubName}
                    required={true}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleSubmit(e)}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {showModal2 && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal2}
            setShow={setShowModal2}
          >
            <div className="w-full">
              <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
                <div className="w-full">
                  <div className="flex justify-center mb-1">
                    <button
                      type="submit"
                      className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm px-3 py-1.5 text-center "
                      onClick={() =>
                        downloadCSV(sampleData, "mobileSample.csv")
                      }
                    >
                      Sample Csv Download
                    </button>
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <label
                      for="dropzone-file"
                      className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                    >
                      <div className="flex flex-col items-center justify-center ">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400 mt-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            strokeLinejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 text-center">
                          {mobileCsv ? (
                            mobileCsv.name
                          ) : (
                            <>
                              <span className="font-semibold">
                                Choose CSV file
                              </span>
                              <div>Click to upload or drag and drop</div>
                            </>
                          )}
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleUploadUserMobileCsv}
                      />
                    </label>
                  </div>

                  <div className="p-2 mt-3 ">
                    <div className="w-full min-h-max">
                      <InputComponent
                        name={"Enter Mobile Numbers"}
                        type={"textarea"}
                        value={mobileNumbers}
                        setValue={setMobileNumbers}
                        required={true}
                        placeholder={"Enter Mobile Numbers"}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  <button
                    type="submit"
                    onClick={(e) => handleSendBulkMessages(modalData)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
         {showModal3 && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal3}
            setShow={setShowModal3}
          >
            <div className="w-full">
              <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
                <div className="w-full">
                  <div className="flex justify-center mb-1">
                    <button
                      type="submit"
                      className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm px-3 py-1.5 text-center "
                      onClick={() =>
                        downloadCSV(sampleData, "mobileSample.csv")
                      }
                    >
                      Sample Csv Download
                    </button>
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <label
                      for="dropzone-file"
                      className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                    >
                      <div className="flex flex-col items-center justify-center ">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400 mt-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            strokeLinejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 text-center">
                          {mobileCsv ? (
                            mobileCsv.name
                          ) : (
                            <>
                              <span className="font-semibold">
                                Choose CSV file
                              </span>
                              <div>Click to upload or drag and drop</div>
                            </>
                          )}
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleUploadUserMobileCsv}
                      />
                    </label>
                  </div>

                  <div className="p-2 mt-3 ">
                    <div className="w-full min-h-max">
                      <InputComponent
                        name={"Enter Mobile Numbers"}
                        type={"textarea"}
                        value={mobileNumbers}
                        setValue={setMobileNumbers}
                        required={true}
                        placeholder={"Enter Mobile Numbers"}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  <button
                    type="submit"
                    onClick={(e) => handleDeleteUsers(modalData3)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default FirebaseTopicsList;
const sampleData = [
  { mobile: "9878987890" },
  { mobile: "8789987890" },
  { mobile: "9999997890" },
];
