import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import {
  useAddAppVideoMutation,
  useGetAppVideoTypesMutation,
} from "../../../../features/tenant/appVideo/appVideoApi";
import { getVendorName } from "../../../../utils/getVendorName";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../../utils/hasPermission";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";

const Video = () => {
  const [title, setTitle] = useState("");
  const [type, setType] = useState({
    name: "Please Select a Type",
    id: -1,
  });
  const [link, setLink] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleLinkChange = (event) => {
    const value = event.target.value;
    const isValidUrl =
      /^((https?|ftp):\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(\/.*)?$/.test(
        value
      );
    setLink(value);
    setIsValid(isValidUrl);
  };

  const [
    addVideo,
    {
      data: addAppVideoData,
      isLoading: addAppVideoIsLoading,
      isError: addAppVideoIsError,
      error: addAppVideoError,
    },
  ] = useAddAppVideoMutation();

  const [
    getAllVideoTypes,
    {
      data: getAllVideoTypesData,
      isLoading: getAllVideoTypesLoading,
      isError: getAllVideoTypesIsError,
      error: getAllVideoTypesError,
    },
  ] = useGetAppVideoTypesMutation();

  const getAllVideoTypesFun = () => {
    getAllVideoTypes({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    getAllVideoTypesFun();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const hasViewPermission = hasPermission("video");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    if (addAppVideoData) {
      toast.success("Video Added !");
      navigate(`/genefied/${getVendorName()}/video`);
    }
  }, [addAppVideoData, addAppVideoError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidUrl =
      /^((https?|ftp):\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(\/.*)?$/.test(
        link
      );
    if (!title) {
      toast.error("Please provide a Title", {
        id: "imageError",
      });
      return;
    }

    if (!type.id == -1) {
      toast.error("Please provide a type", {
        id: "imageError",
      });
      return;
    }
    if (!link) {
      toast.error("Please provide a link", {
        id: "imageError",
      });
      return;
    } else if (title && type && link && !isValidUrl) {
      toast.error("Please provide a valid link", {
        id: "imageError",
      });
      return;
    }

    addVideo({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        type: type.name,
        link,
        title,
      },
    });
  };
  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Video
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 w-full bg-white shadow rounded-lg">
              <div className="w-full  flex justify-center space-x-4">
                <div className="mb-3 w-1/2">
                  <div className="w-full">
                    <InputComponent
                      name={"Title"}
                      type={"text"}
                      value={title}
                      setValue={setTitle}
                      required={true}
                      placeholder={"Enter Title"}
                    />
                  </div>
                </div>

                <div className="mb-3 w-1/2 dropds">
                  <div className="w-full">
                    {getAllVideoTypesData &&
                      getAllVideoTypesData?.body &&
                      getAllVideoTypesData?.body.length && (
                        <SelectComponent
                          title={"Select type*"}
                          selected={type}
                          setSelected={setType}
                          dataArr={getAllVideoTypesData?.body}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <label
                  htmlFor={link}
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Link
                </label>
                <input
                  className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 "
                  name="Link"
                  type="text"
                  value={link}
                  onChange={handleLinkChange}
                  required={true}
                  placeholder="Paste Video link"
                />
                {!isValid && <p className="error-message">Invalid URL</p>}
              </div>

              <div className="flex justify-center mt-4">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
