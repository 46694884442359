

export function calculateParentCategoryScores(categoryScores, categoryData) {
    const parentCategoryScores = {};
    try {
        for (let [categoryName, score] of Object.entries(categoryScores)) {

            score = Number(score);

            let foundCategory = categoryData.find(category => category.name.toLowerCase() === categoryName.toLowerCase());

            if (!foundCategory) {
                throw new Error(`Category "${categoryName}" not found in category data.`);
            }

            const parentCategoryId = foundCategory.master_id;

            let parentCategory = categoryData.find(category => category.id === parentCategoryId);

            if (!parentCategory) {
                throw new Error(`Parent category with ID ${parentCategoryId} not found.`);
            }

            const parentCategoryName = parentCategory.name; 
            parentCategoryScores[parentCategoryName] = Number(parentCategoryScores[parentCategoryName] || 0) + Number(score);
        }

        return parentCategoryScores;
    } catch (error) {
        console.error("Error:", error.message);
        return null;
    }
}

