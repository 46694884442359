import { rootApi } from "../../../api/rootApi";

export const extraPointsScanHistory = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchExtraPointsScanHistory: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/extraPoint`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    countExtraPointsScanHistory: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/extraPoint/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    getExtraPointsData: builder.mutation({
      query(params) {
        console.log("data point sharing",params);
        return {
          method: 'GET',
          url:`/tenant/extra-points/${params.id}?limit=100&offset=0`,
         
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
   
  }),
});

export const {
  useFetchExtraPointsScanHistoryMutation,
  useCountExtraPointsScanHistoryMutation,
  useGetExtraPointsDataMutation
} = extraPointsScanHistory;
