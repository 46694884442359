import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useFetchAllBrandsMutation } from "../../../features/tenant/brands/brandsApi";
import { toast } from "react-hot-toast";
import { useImportQrsMutation } from "../../../features/tenant/OzoneAPI/ozoneApi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useNavigate } from "react-router-dom";
import { downloadSampleCSV } from "../../../utils/downloadSampleCsv";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useBulkUpdateScannedQrCodeMutation } from "../../../features/tenant/qrCode/qrCodeApi";

const brands = [
  { id: 1, name: "OOPL" },
  { id: 2, name: "OSPL" },
];

const QrBatchImport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [batchName, setBatchName] = useState("");

  const navigate = useNavigate();

  const [selectedBrand, setSelectedBrand] = useState({
    id: -1,
    name: "",
  });

  const [showModal2, setShowModal2] = useState(false);
  const [file, setFile] = useState();
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [
    bulkUpdateScannedQrCodeFunc,
    {
      data: bulkUpdateScannedQrCodeData,
      error: bulkUpdateScannedQrCodeError,
      isLoading: bulkUpdateScannedQrCodeIsLoading,
    },
  ] = useBulkUpdateScannedQrCodeMutation();

  function parseCSV(string) {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .replace(/\s/g, "")
      .split(",");
  
    const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);
  
    console.log("csvRows: ", csvRows.length, csvRows);
  
    const array = csvRows
      .map((row) => {
        const values = [];
        let insideQuotes = false;
        let currentField = '';
  
        for (let char of row) {
          if (char === ',' && !insideQuotes) {
            values.push(currentField.trim());
            currentField = '';
          } else if (char === '"') {
            insideQuotes = !insideQuotes;
          } else {
            currentField += char;
          }
        }
  
        values.push(currentField.trim());
        return values;
      })
      .filter((values) => values.length === csvHeader.length)
      .map((values) => {
        const rowObject = {};
        values.forEach((value, index) => {
          rowObject[csvHeader[index]] = value;
        });
        return rowObject;
      });
  
    console.log("array", array);
    return array;
  }
  

  

  
  const handleOnSubmitUserPoints = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!file) {
      toast.error("Please select a file");
      return;
    }
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);
        datas = datas.map((dataItem) => ({
          ...dataItem
        }));

        console.log(datas,"DATAS HERE");
            bulkUpdateScannedQrCodeFunc({
              token: sessionStorage.getItem("access_token"),
              body: { qrs: datas },
              tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
            });
      };

      fileReader.readAsText(file);
    }
  };

  useEffect(() => {
    if (bulkUpdateScannedQrCodeData) {
      console.log(bulkUpdateScannedQrCodeData, "Here is Register Data");
      toast.success("Qrs Updated Successfully!");
      return;
    }
    if (bulkUpdateScannedQrCodeError) {
      console.log(bulkUpdateScannedQrCodeError, "Error in Registor Data");
      toast.error("Error in Updating Qr !");
      return;
    }
  }, [bulkUpdateScannedQrCodeData, bulkUpdateScannedQrCodeError]);


  const [fetchBrandList, { data: brandsData, error: brandsError }] =
    useFetchAllBrandsMutation();

  const [
    importQrs,
    { data: importQrData, isLoading: importQrIsLoading, error: importQrError },
  ] = useImportQrsMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("importQrs");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchBrandList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (brandsData && brandsData.body && brandsData.body.brands) {
      console.log("brandsData", brandsData.body.brands);
    }

    if (brandsError) {
      console.log("brandsError", brandsError);
    }
  }, [brandsData, brandsError]);

  const convertDate = (inputDate) => {
    const parts = inputDate.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const date = new Date(year, month - 1, day);

    const formattedDate = `${
      date.getMonth() + 1
    }-${date.getDate()}-${date.getFullYear()}`;

    return formattedDate;
  };

  const submitHnadler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toast.loading("please wait...");

    let companyId = 0;
    if (selectedBrand.name == "OOPL") {
      companyId = 1;
    } else if (selectedBrand.name == "OSPL") {
      companyId = 2;
    }

    if (!startDate) {
      toast.error("no start date selected");
      return;
    }

    if (!endDate) {
      toast.error("no end date selected");
      return;
    }

    if(!batchName){
      toast.error("batch name not specified");
      return;
    }

    console.log(convertDate(startDate), endDate, companyId);

    importQrs({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        fromdate: convertDate(startDate),
        todate: convertDate(endDate),
        CompanyId: companyId,
        batch_name : batchName,
      },
    });
  };

  useEffect(() => {
    if (importQrData && importQrData.body) {
      console.log("importQrData", importQrData);
      toast.dismiss();
      toast.success(`Success , ${importQrData.body.total_qrs} imported`, { id: "importQrSuccess" });
    }

    if (importQrError) {
      console.log("importQrError", importQrError);
      toast.dismiss();

      if(importQrError.status == '404'){
        toast.error(importQrError.data.message, { id: "importQrError" });
      }else{
        toast.error("Something went wrong", { id: "importQrError" });
      }
    }
  }, [importQrData, importQrError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Qr Code Import
            <LinksComponent />
          </div>
          <div className="p-3">
          <div className="flex gap-2">
          
            <button
              className="btn btn-outline-primary clear-both float-right  mb-2"
              onClick={() => {
                setShowModal2(true);
              }}
            >
              Add Qrs Csv
            </button>
           </div>
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <form className="">
                <div className="w-full flex justify-center space-x-4">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                  <div className="mb-6 bottom-2 w-1/4">
                    <SelectComponentWithSearch
                      title={"Brand"}
                      selected={selectedBrand}
                      setSelected={setSelectedBrand}
                      dataArr={brands ? brands : []}
                      required={true}
                      placeholder={"Select Brand"}
                    />
                  </div>
                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Batch Name"}
                      type={"text"}
                      value={batchName}
                      setValue={setBatchName}
                      required={true}
                      placeholder={"Enter Batch Name"}
                    />
                  </div>
                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Start Date"}
                      type={"date"}
                      value={startDate}
                      setValue={setStartDate}
                      required={true}
                      placeholder={"Enter Start Date"}
                    />
                  </div>
                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"End Date"}
                      type={"date"}
                      value={endDate}
                      setValue={setEndDate}
                      required={true}
                      placeholder={"Enter End Date"}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    type="submit"
                    onClick={submitHnadler}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Fetch
                  </button>
                </div>
              </form>
            </div>{" "}
          </div>
        </div>
      </div>

      {showModal2 && (
          <ModalComponent show={showModal2} setShow={setShowModal2} size="md">
            <div className="w-full p-4">
             
                <div className="flex justify-center mt-3 ">
                  <button
                    type="submit"
                    className="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() => downloadSampleCSV()}
                  >
                    Sample Csv Download
                  </button>
                </div>
              <div className="w-full m-auto  justify-center ">
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnChange}
                    />
                  </label>
                </div>
              </div>
            
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={(e) => handleOnSubmitUserPoints()}
                  >
                    Add
                  </button>
                </div>
             
            </div>
          </ModalComponent>
        )}

    </>
  );
};

export default QrBatchImport;
