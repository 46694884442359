import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function GenChart({ gen }) {
  const darkColors = [
    "#004EDE",
    "#F66B4E",
    "#1CA2D5",
    "#00A54D",
    "#3B2947",
    "#19abde",
    "#1de4bd",
    "#6df0d1",
    "#29066c",
    "#7d39c0",
    "#da4cb2",
    "#ec548d",
    "#e97369",
    "#f0a58e",
    "#810400",
    "#bf2324",
    "#de542d",
    "#ef7e32",
    "#eabe3b",
  ];

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        data: gen,
        backgroundColor: darkColors.slice(0, gen.length),
        borderColor: darkColors.slice(0, gen.length),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: true,
    cutoutPercentage: 70,
  };

  return (
    <div className="relative flex flex-col  widthbox_chart">
      <Pie data={data} options={options} />
    </div>
  );
}

// import React from "react";
// import { Pie } from "react-chartjs-2";

// export function GenChart({ gen }) {
//   const darkColors = [
//     "#004EDE",
//     "#F66B4E",
//     "#1CA2D5",
//     "#00A54D",
//     "#3B2947",
//     "#19abde",
//     "#1de4bd",
//     "#6df0d1",
//     "#29066c",
//     "#7d39c0",
//     "#da4cb2",
//     "#ec548d",
//     "#e97369",
//     "#f0a58e",
//     "#810400",
//     "#bf2324",
//     "#de542d",
//     "#ef7e32",
//     "#eabe3b",
//   ];

//   const labels = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   const data = {
//     labels: labels,
//     datasets: [
//       {
//         data: gen,
//         backgroundColor: darkColors.slice(0, gen.length),
//         borderColor: darkColors.slice(0, gen.length),
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     plugins: {
//       legend: {
//         display: false,
//       },
//       labels: {
//         render: (args) => {
//           return labels[args.index];
//         },
//         fontSize: 12,
//         fontColor: "white",
//       },
//     },
//     aspectRatio: 1,
//     responsive: true,
//     maintainAspectRatio: true,
//     cutoutPercentage: 70,
//   };

//   return (
//     <div className="relative flex flex-col widthbox_chart">
//       <Pie data={data} options={options} />
//     </div>
//   );
// }
