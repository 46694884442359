import React, { useEffect, useMemo, useRef, useState } from "react";
import LoginImg from "../../../assests/tenant/images/login_img.svg";
import genefiedLogo from "../../../assests/tenant/images/logo_login.svg";
import { useTenantLoginMutation } from "../../../features/tenant/tenantAuth/tenantAuthApi";
import { Toaster, toast } from "react-hot-toast";
import { setTenantCredential } from "../../../features/tenant/tenantAuth/tenantAuthSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";

import tailwindColors from "../../../../node_modules/tailwindcss/colors";
import { useFetchCompanyProfileMutation } from "../../../features/tenant/companyProfile/companyProfileApi";
import { setCompanyProfile } from "../../../features/tenant/companyProfile/companyProfileSlice";

const Login = ({ showLoading, vendorThemeData, setTenantAuthorized }) => {
  console.log("Login renddered");
  const [primaryColorShades, setPrimaryColorShades] = useState({});

  const [logo, setLogo] = useState(null);

  useMemo(() => {
    console.log("In Login", vendorThemeData);

    if (vendorThemeData) {
      setLogo(
        vendorThemeData.body.logo
          ? 
              vendorThemeData.body.logo[0]
          : null,
      );
    }
    if (vendorThemeData && vendorThemeData?.body?.theme?.color_shades) {
      console.log(
        " Login useMemo vendor theme data 2",
        vendorThemeData.body.theme.color_shades,
      );

      setPrimaryColorShades(vendorThemeData.body.theme.color_shades);
      console.log(
        " new vendorThemeData",
        vendorThemeData.body.theme.color_shades,
      );
    }
  }, [vendorThemeData]);

  const dispatch = useDispatch();
  const [
    tenantLogin,
    {
      data: tenantData,
      isLoading: tenantIsLoading,
      isError: tenantIsError,
      error: tenantError,
    },
  ] = useTenantLoginMutation();

  const email = useRef("");
  const password = useRef("");

  const handleTenantLogin = (e) => {
    e.preventDefault();
    if (email.current.value.length > 0 && password.current.value.length > 0) {
      sessionStorage.setItem(
        "vendorData",
        JSON.stringify(vendorThemeData.body),
      );
      tenantLogin({
        data: {
          email: email.current.value,
          password: password.current.value,
        },
        slug: vendorThemeData.body.tenant_id,
      });
    } else {
      toast.error("Please enter your email and password", {
        id: "noEmailPassword",
      });
    }
  };

  if (tenantIsError) {
    console.log(tenantError);
    toast.error(tenantError.data.message, {
      id: "loginError",
    });
  }

  if (showLoading) {
    <Loader />;
  }

  const [
    fetchCompanyProfile,
    {
      data: companyData,
      isLoading: companyIsLoading,
      isError: companyIsError,
      error: companyError,
    },
  ] = useFetchCompanyProfileMutation();

  useEffect(() => {
    if (tenantData) {
      fetchCompanyProfile({
        token: tenantData.body.token,
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }

    if (tenantData && companyData) {
      sessionStorage.setItem("access_token", tenantData.body.token);
      sessionStorage.setItem("authorizedTenant", true);
      // sessionStorage.setItem("tenantId", tenantData.body.user_id);
      sessionStorage.setItem("userTenant", JSON.stringify(tenantData.body));
      sessionStorage.setItem(
        "companyProfile",
        JSON.stringify(companyData.body),
      );
      sessionStorage.setItem(
        "vendorTheme",
        JSON.stringify({
          tenant_id: vendorThemeData.body.tenant_id,
          tenant_name: vendorThemeData.body.tenant_name,
          theme: vendorThemeData.body.theme,
        }),
      );
      dispatch(setCompanyProfile(companyData.body));
      dispatch(setTenantCredential(tenantData.body));
      setTenantAuthorized(true);

      //   navigate("/genefied.tenant/");
    }
  }, [tenantData, companyData]);

  if (tenantIsLoading) {
    return <Loader />;
  }

  return (
    <>
      {vendorThemeData ? (
        <>
          <Toaster />

          <div className="w-full  sm:w-full lg:w-5/6  p-5  m-auto h-screen flex items-center justify-center">
            <div
              className="rounded-2xl overflow-hidden w-full lg:flex shadow-2xl"
              style={{ backgroundColor: `${primaryColorShades["700"]}` }}
            >
              <div className="rounded-2xl lg:w-1/2 shadow-2xl bg-white p-3 flex justify-center items-center lg:px-12">
                <div className="cursor-pointer max-w-xs flex justify-center items-center">
                  <img src={logo ? logo : genefiedLogo} alt="LoginImage" />
                </div>
              </div>
              <div className="lg:w-1/2 p-5 xspd-2 flex items-center justify-center">
                <div className="w-full">
                  <h2
                    style={{ color: `${primaryColorShades["800"]}` }}
                    className={`text-center   
                  text-4xl font-display font-bold lg:text-left xl:text-4xl xl:text-bold text-white`}
                  >
                    Log in
                  </h2>
                  <div className="mt-8">
                    <form>
                      <div className="relative">
                        {/* <div className="text-sm font-bold text-gray-700 tracking-wide">
                        Email Address
                      </div> */}
                        <input
                          className="w-full text-lg px-3 py-3 rounded-md border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                          type="email"
                          placeholder="mike@gmail.com"
                          ref={email}
                        />

                        <svg
                          class="h-5 w-5 text-gray-500 absolute right-4 top-5 "
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          {" "}
                          <path stroke="none" d="M0 0h24v24H0z" />{" "}
                          <rect x="3" y="5" width="18" height="14" rx="2" />{" "}
                          <polyline points="3 7 12 13 21 7" />
                        </svg>
                      </div>
                      <div className="mt-6 relative">
                        {/* <div className="flex justify-between items-center">
                        <div className="text-sm font-bold text-gray-700 tracking-wide">
                          Password
                        </div>
                      </div> */}
                        <input
                          className="w-full text-lg px-3 py-3 rounded-md border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                          type="password"
                          placeholder="Enter your password"
                          ref={password}
                        />
                        <svg
                          class="h-5 w-5 text-gray-500 absolute right-4 top-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          />
                        </svg>
                      </div>
                      {/* <span className="block text-white font-medium mt-2">Forgot Password?</span> */}
                      <div className="mt-4">
                        <button                        
                          className={`text-white p-3 text-lg w-full rounded-full tracking-wide
                    font-semibold font-display focus:outline-none focus:shadow-outline 
                    shadow-lg bg-black`}
                          onClick={handleTenantLogin}
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
          <span className="fixed bottom-2 left-0 right-00 block text-center w-full">2023 © Design & Develop by Genefied.</span>

          {/* <div
            style={{ backgroundColor: `${primaryColorShades["700"]}` }}
            className={`lg:flex  `}
          >
            <div
              style={{ backgroundColor: `${primaryColorShades["800"]}` }}
              className={`hidden lg:flex items-center justify-center flex-1 h-screen`}
            >
              <div className="max-w-md transform duration-200 hover:scale-110 ">
                <img
                  src={LoginImg}
                  className="w-full object-cover object-center"
                  alt="loginImage"
                />
              </div>
            </div>
            <div className="lg:w-1/2 xl:max-w-screen-sm bg-white">
              
             
            </div>
          </div> */}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Login;
