import { rootApi } from "../../api/rootApi";

export const schemeOrderApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createSchemeOrder: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scheme-order/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllSchemeOrder: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/scheme-order`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchSchemeOrderByOrderId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/scheme-order/${params.order_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchSchemeOrderBySchemeId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/scheme-order/${params.scheme_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    deleteRewardSchemeById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/scheme-order/${params.scheme_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
    useCreateSchemeOrderMutation , useFetchAllSchemeOrderMutation , useFetchSchemeOrderBySchemeIdMutation,useFetchSchemeOrderByOrderIdMutation , useDeleteRewardSchemeByIdMutation
} = schemeOrderApi;
