import React, { useRef, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useCreateSchemeOrderMutation } from "../../../features/tenant/schemeOrder/schemeOrderApi";
import { validateAndCleanObjects } from "../../../utils/rewardScheme";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import OrdersTable from "./OrdersTable";
import { getVendorName } from "../../../utils/getVendorName";

function flattenData(data) {
  let flattened = [];
  // orderId", "reward" , "assured_gift", "invoice_id", "retailer_code", "week","slab_range", "effective_value", "total_value"
  data.forEach((order) => {
    let flatItem = {
      orderId: order.orderId,
      lucky_draw: order.lucky_draw,
      assured_gift: order.assured_gift,
      week: order.week,
      slab_id: order.slab_id,
      scheme_name: order.slab.scheme_name,
      product_category_slab: order.slab.product_category,
      invoice_id: order.invoice_id,
      retailer_code: order.retailer_code,
      total_value: order.total_value,
      effective_value: order.effective_value,
      slab_range: order.slab_range,
    };

    flattened.push(flatItem);
  });

  return flattened;
}

function downloadCSV(arrayOfObjects, filename = "reward_scheme.csv") {
  function convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(
      arr.map((item) => Object.values(item)),
    );
    return array.map((it) => it.toString()).join("\n");
  }

  const csvString = convertToCSV(flattenData(arrayOfObjects));
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
function OrderDetails({ order }) {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th>Invoice ID</th>
          <th>Retailer Code</th>
          <th>Product Category</th>
          <th>SKU Code</th>
          <th>Sp to Retailer</th>
          <th>Qty</th>

          <th>Invoice Value</th>
        </tr>
      </thead>
      <tbody>
        {order.all_orders.map((item, index) => (
          <tr key={index} className="bg-white">
            <td>{item.invoice_id}</td>
            <td>{item.retailer_code}</td>
            <td>{item.product_category}</td>
            <td>{item.sku_code}</td>
            <td>{item.sp_to_retailer}</td>
            <td>{item.qty}</td>
            <td>{parseFloat(item.invoice_value).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function OrderCard({ order }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <tr
        onClick={() => setShowDetails(!showDetails)}
        className={
          order.lucky_draw
            ? " cursor-pointer hover:bg-gray-100  bg-green-200"
            : " cursor-pointer hover:bg-gray-100 "
        }
      >
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order.order_id}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order.slab.scheme_name}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order?.all_orders &&
            order.all_orders.length > 0 &&
            order.all_orders[0].week}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order.slab.product_category}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {parseFloat(order.slab.slab_start).toFixed(0)} -{" "}
          {parseFloat(order.slab.slab_end).toFixed(0)}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order.assured_gift}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order.lucky_draw ? order.lucky_draw : "N/A"}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order?.all_orders &&
            order.all_orders.length > 0 &&
            parseFloat(order.all_orders[0].total_value).toFixed(2)}
        </td>
        <td className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
          {order?.all_orders &&
            order.all_orders.length > 0 &&
            parseFloat(order.all_orders[0].effective_value).toFixed(2)}
        </td>
      </tr>
      {showDetails && (
        <tr className="min-w-full">
          <td colSpan="5">
            <OrderDetails order={order} />
          </td>
        </tr>
      )}
    </>
  );
}

const SchemeOrderUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const [canSubmit, setCanSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [sortedData, setsortedData] = useState([]);

  const [totalAssuredGift, setTotalAssuredGift] = useState(0);

  const [totalLuckyDraws, setTotalLuckyDraws] = useState(0);

  const fileReader = new FileReader();
  const [
    createSchemeOrder,
    {
      data: schemeOrderData,
      error: schemeOrderError,
      isLoading: schemeOrderisLoading,
    },
  ] = useCreateSchemeOrderMutation();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    const validationResult = validateAndCleanObjects(array);
    console.log(validationResult);

    setCanSubmit(validationResult.isValid);

    setModalData(validationResult.data);

    setArray(validationResult.data);
    toast.success("Data successfully imported...");
    // setShowModal(true);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    console.log(file);

    console.log("file upload");
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(file);
    }
  };

  console.log(array);
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const handleOnSubmit = (e) => {
    setsortedData(null);
    toast.loading("Processing your data...");

    e.preventDefault();

    createSchemeOrder({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        orders: array,
      },
    });
  };

  useEffect(() => {
    if (schemeOrderData) {
      console.log("Scheme order data", schemeOrderData);
      if (
        schemeOrderData.body &&
        schemeOrderData.body.rewardSchemeHistory &&
        schemeOrderData.body.rewardSchemeHistory.length > 0
      ) {
        const sortedOrders = [...schemeOrderData.body.rewardSchemeHistory].sort(
          (a, b) => {
            const weekA = parseInt(a.all_orders[0].week, 10);
            const weekB = parseInt(b.all_orders[0].week, 10);

            // Primary sort by week
            if (weekA !== weekB) return weekA - weekB;

            // Secondary sort by slab_start if weeks are the same
            return (
              parseFloat(a.slab.slab_start) - parseFloat(b.slab.slab_start)
            );
          },
        );

        const sumAssuredGift = schemeOrderData.body.rewardSchemeHistory.reduce(
          (total, order) => total + order.assured_gift,
          0,
        );
        setTotalAssuredGift(sumAssuredGift);
        const sumLuckyDraws = schemeOrderData.body.rewardSchemeHistory.reduce(
          (total, order) => total + order.lucky_draw,
          0,
        );
        setTotalLuckyDraws(sumLuckyDraws);
        setsortedData(sortedOrders);
        toast.dismiss();
        toast.success("Successfully added");
      }

      if (schemeOrderData.body && schemeOrderData.body.orderRewardsArr) {
        toast.dismiss();
        toast.success("Successfully added");
        const sumAssuredGift = schemeOrderData.body.orderRewardsArr.reduce(
          (total, order) => total + order.assured_gift,
          0,
        );
        setTotalAssuredGift(sumAssuredGift);
        const sumLuckyDraws = schemeOrderData.body.orderRewardsArr.reduce(
          (total, order) =>
            total + (order.lucky_draw == "N/A" ? 0 : Number(order.lucky_draw)),
          0,
        );
        setTotalLuckyDraws(sumLuckyDraws);

        setsortedData(schemeOrderData.body.orderRewardsArr);

        downloadCSV(schemeOrderData.body.orderRewardsArr, "schemeRewards.csv");

        // setTimeout(() => {
        //   navigate(`/genefied/${getVendorName()}/reward-scheme`);
        // }, 1000);
      }
    }

    if (schemeOrderError) {
      toast.dismiss();
      toast.error("Something went wrong");
      console.log("Scheme order error", schemeOrderError);
    }
  }, [schemeOrderData, schemeOrderError]);

  console.log(sortedData);
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Upload Scheme Orders
            <LinksComponent />
          </div>

          <div className="mx-9 mt-10">
            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-fit p-4 px-5 justify-center">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="text-center ">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                        Upload Excel/CSV File:
                      </label>
                    <div className="flex gap-3 border-b pb-4 border-gray-200">
                     
                      <input className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  
"
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={handleOnChange}
                      />
                      <button
                        class="whitespace-nowrap bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
                        onClick={(e) => handleFileUpload(e)}
                      >
                        IMPORT CSV
                      </button>
                    </div>

                    <div className="mt-4 flex justify-center space-x-2 ">
                      {/* View CSV File Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center"
                        >
                          View CSV File
                        </button>
                      )}

                      {/* Save Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => handleOnSubmit(e)}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Save
                        </button>
                      )}
                    </div>

                    {sortedData && sortedData.length > 0 && (
                      <div className="flex justify-evenly mt-2 space-x-4 shadow-md bg-white p-4 rounded-lg">
                        {/* Total Assured Gifts */}
                        <div className="flex flex-col items-center">
                          <span className="text-gray-600">
                            Total Assured Gifts
                          </span>
                          <span className="text-xl font-bold">
                            {totalAssuredGift} INR
                          </span>
                        </div>

                        {/* Total Lucky Draw */}
                        <div className="flex flex-col items-center">
                          <span className="text-gray-600">
                            Total Lucky Draw
                          </span>
                          <span className="text-xl font-bold">
                            {totalLuckyDraws} INR
                          </span>
                        </div>

                        <button
                          type=""
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/genefied/${getVendorName()}/reward-scheme`,
                            );
                          }}
                          className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Go To Reward Scheme
                        </button>
                        {/* Download Button */}
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            downloadCSV(sortedData, "schemeRewards.csv");
                          }}
                          className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Download
                        </button>
                      </div>
                    )}

                    {/* {schemeOrderData?.body?.rewardSchemeHistory && (
                      <table className="min-w-full divide-y divide-gray-200 mt-6">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Order ID
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Scheme Name
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Week
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Product Category
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Slab Start - End
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Assured Gift
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Lucky Draw
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Total Order Value
                            </th>
                            <th className="border-l-2 border-r-2 border-gray-800 px-2 py-2">
                              Effective Value
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedData.map((order, index) => (
                            <OrderCard key={index} order={order} />
                          ))}
                        </tbody>
                      </table>
                    )} */}

                    {sortedData && sortedData.length > 0 && (
                      <div className="container mx-auto p-6">
                        <OrdersTable orders={sortedData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          {showModal && modalData && (
            <ModalComponent
              size={"5xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg">
                <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr>
                        {headerKeys.map((key, idx) => (
                          <th
                            className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            key={idx}
                          >
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {array.slice(0, 30).map((item) => (
                        <tr
                          key={item.id}
                          className="border-t-2 border-b-2 border-gray-300"
                        >
                          {Object.values(item).map((val, index) => (
                            <td
                              key={index}
                              className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                    <h2 className="mt-2 ml-auto">
                      {" "}
                      {array.length - 30} rows more
                    </h2>
                  </table>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default SchemeOrderUpload;
