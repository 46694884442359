export const getStartDateEndDate = (value) => {
    if (value) {
      if (value.length === 10) {
        // console.log("type day");
        return { startDate: value, endDate: value };
      } else if (value.length === 8) {
        // console.log("type week");

        const startDate = new Date(value.split("-")[0], 0, 1);
        // console.log("Start Date", startDate);

        startDate.setDate(7 * value.split("-")[1].slice(1) - 1);
        // console.log("Week Start Date", startDate);
      } else if (value.length === 7) {
        const startDate = new Date(
          value.split("-")[0],
          Number(value.split("-")[1]) - 1,
          1 + 1,
        );
        const endDate = new Date(
          value.split("-")[0],
          Number(value.split("-")[1]),
          1,
        );
        return {
          startDate: startDate.toISOString().slice(0, 10),
          endDate: endDate.toISOString().slice(0, 10),
        };
      } else if (typeof value === "object" && value !== null) {
        // console.log("type year");
        const startDate = new Date(Number(value.name), 0, 1 + 1);
        const endDate = new Date(Number(value.name), 12, 1);
        return {
          startDate: startDate.toISOString().slice(0, 10),
          endDate: endDate.toISOString().slice(0, 10),
        };
      }
    }
  };