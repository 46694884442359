import { rootApi } from "../../api/rootApi";

export const schemeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSchemeWalletOfAppUserBySchemeId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/scheme-wallet?scheme_id=${params.scheme_id}&app_user_id=${params.app_user_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
    useFetchSchemeWalletOfAppUserBySchemeIdMutation
} = schemeApi;
