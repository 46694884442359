import { Menu } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { HiEye, HiOutlinePlus } from 'react-icons/hi2'
import DataTableComponent from '../../../components/Tenant/DataTableComponent/DataTableComponent'
import LinksComponent from '../../../components/Tenant/LinksComponent/LinksComponent'
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetAllBatchOfPaymentTargetMutation } from '../../../features/tenant/paymentTarget/paymentTargetApi'
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const PaymentTargetList = () => {
    const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

    const location = useLocation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
  
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [prevPage, setPrevPage] = useState(0);
    const [disableBtn, setDisableBtn] = useState(false);
    const [sheetName, setSheetName] = useState(null);
  
    const columns = [
      {
        name: "SNo",
        selector: (row, idx) => idx + 1,
        sortable: true,
        width: "80px",
      },
      {
        name: "File Name",
        selector: (row) => row?.file_name,
        sortable: true,
        width: "200px",
      },
      {
        name: "Batch",
        selector: (row) => row.batch_id,
        sortable: true,
        width: "110px",
      },
      {
        name: "Month",
        selector: (row) => months[row.month],
        sortable: true,
      },
  
      {
        name: "Year",
        selector: (row) => row.year,
        sortable: true,
      },
      {
        name: "Total ",
        selector: (row) => row.total_count,
        sortable: true,
      },
      {
        name: "Status",
        cell: (row) =>
        //   updateStatusPermission &&
          (row.status == "1" ? (
            <>
              <button
                onClick={() => {
                //   handleStatuChange(row, "2");
                }}
                className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Active
              </button>
            </>
          ) : (
            <button
              onClick={() => {
                // handleStatuChange(row, "1");
              }}
              className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              InActive
            </button>
          )),
      },
  
      {
        name: "",
        selector: (row) => (
          <div>
            <button
              onClick={() =>
                navigate("batch", {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <HiEye className="mr-1" />
              View
            </button>
          </div>
        ),
        minWidth: "100px",
        button: true,
      },
  
   
    //   {
    //     name: "",
    //     cell: (r) => (
    //       <button
    //         className="btn btn-outline-danger btn-sm"
    //         onClick={() => handleDeleteWrapper(r)}
    //       >
    //         Delete
    //       </button>
    //     ),
    //     minWidth: "100px",
  
    //     button: true,
    //   },
    ];

    useEffect(() => {
        getAllBatchOfPaymentTarget({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        });
      }, [location]);
    
      const [
        getAllBatchOfPaymentTarget,
        {
          data: getAllBatchOfPaymentTargetData,
          error: getAllBatchOfPaymentTargetError,
          isLoading: getAllBatchOfPaymentTargetisLoading,
        },
      ] = useGetAllBatchOfPaymentTargetMutation();

      const handlePageChange = (pageNumber) => {
        const newOffset = (pageNumber - 1) * limit;
        setOffset(newOffset);
        setPrevPage(pageNumber - 1);
      };
    
      const handlePerRowsChange = (newPerPage, page) => {
        setLimit(newPerPage);
      };
    
      useEffect(() => {
        if (getAllBatchOfPaymentTargetData && getAllBatchOfPaymentTargetData.body) {
          console.log("paymentTransferListData", getAllBatchOfPaymentTargetData);
        }
    
        if (getAllBatchOfPaymentTargetError) {
          console.log("paymentTransferListError", getAllBatchOfPaymentTargetError);
          toast.error("Something went wrong", { id: "paymentTransferListError" });
        }
      }, [getAllBatchOfPaymentTargetData, getAllBatchOfPaymentTargetError]);
    
    
  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          All Payment Target List
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            <DataTableComponent
              columns={columns}
              data={getAllBatchOfPaymentTargetData ? getAllBatchOfPaymentTargetData.body : []}
              loading={getAllBatchOfPaymentTargetisLoading}
              totalRows={
                getAllBatchOfPaymentTargetData
                  ? getAllBatchOfPaymentTargetData.body.length
                  : 0
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            ></DataTableComponent>
            {true && (
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                <div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentTargetList