import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { downloadCSV } from "../../../utils/downloadCSV";
import CSVReader from "../../../components/ImportCsv/CSVReader";
import { useTropoliteBulkUploadOrdersMutation } from "../../../features/tenant/TropoliteApi/tropoliteApi";

const sampleDataCustomer = [
  {
      "Voucher No": "13543",
      "Voucher Date": "01-Nov-2023",
      "Distributor Code": "DR0312",
      "Distributor Name": "Balaji Traders,Jabalpur",
      "Distributor Sales Person": "",
      "Dealer Code": "DLR00000000159412",
      "Dealer Name": "Cash",
      "Dealer City": "",
      "Dealer Phone No": "",
      "Dealer Email Id": "",
      "Dealer GSTIN": "",
      "Item Code": "FG0378",
      "Item Description": "Ecotrop-FG0378",
      "Item Group": "Bakery Toppings",
      "Item Unit": "Kilogram",
      "Sale Qty": "12.00",
      "FOC Qty": "0.00",
      "Rate": "80.01",
      "Billed Value": "960.12"
  },
  {
      "Voucher No": "13544",
      "Voucher Date": "01-Nov-2023",
      "Distributor Code": "DR0312",
      "Distributor Name": "Balaji Traders,Jabalpur",
      "Distributor Sales Person": "",
      "Dealer Code": "DLR00000000159412",
      "Dealer Name": "Cash",
      "Dealer City": "",
      "Dealer Phone No": "",
      "Dealer Email Id": "",
      "Dealer GSTIN": "",
      "Item Code": "FG0378",
      "Item Description": "Ecotrop-FG0378",
      "Item Group": "Bakery Toppings",
      "Item Unit": "Kilogram",
      "Sale Qty": "12.00",
      "FOC Qty": "0.00",
      "Rate": "80.01",
      "Billed Value": "960.12"
  },
  {
      "Voucher No": "13512",
      "Voucher Date": "01-Nov-2023",
      "Distributor Code": "DR0312",
      "Distributor Name": "Balaji Traders,Jabalpur",
      "Distributor Sales Person": "",
      "Dealer Code": "DLR00000000160635",
      "Dealer Name": "Vijay Bakers  (Adhartal) (Urd)",
      "Dealer City": "",
      "Dealer Phone No": "",
      "Dealer Email Id": "",
      "Dealer GSTIN": "",
      "Item Code": "FG0792",
      "Item Description": "Magic Jelly Neutral-FG0792",
      "Item Group": "Bakery Ingredients",
      "Item Unit": "Kilogram",
      "Sale Qty": "30.00",
      "FOC Qty": "0.00",
      "Rate": "80.51",
      "Billed Value": "2,415.30"
  },
  {
      "Voucher No": "13540",
      "Voucher Date": "01-Nov-2023",
      "Distributor Code": "DR0312",
      "Distributor Name": "Balaji Traders,Jabalpur",
      "Distributor Sales Person": "",
      "Dealer Code": "DLR00000000160635",
      "Dealer Name": "Kushagr Enterprises",
      "Dealer City": "",
      "Dealer Phone No": "",
      "Dealer Email Id": "",
      "Dealer GSTIN": "23AKEPP5308E1ZI",
      "Item Code": "FG0020",
      "Item Description": "Hitrop-FG0020",
      "Item Group": "Bakery Toppings",
      "Item Unit": "Kilogram",
      "Sale Qty": "216.00",
      "FOC Qty": "0.00",
      "Rate": "66.10",
      "Billed Value": "14,277.60"
  },
]

const sampleDataDistributor = [
  {
      "DocumentType": "Sale Invoice",
      "Date": "5/12/2023",
      "Customer Code": "DR3553",
      "Customer Name": "Amar and Sons Traders",
      "Docentry": "LKO2324111194",
      "docnum": "455211",
      "TFPL Code": "TFPL744",
      "SalePerson": "Ramesh Singh Baghari",
      "ItemCode": "FG0782",
      "ItemName": "M-Trop",
      "UQC": "KG",
      "Quantity": "300",
      "Price": "69.57",
      "Basic Value after Discount LineWise": "20,661.49",
      "Original Ref. Date": "1/1/2000"
  },
  {
      "DocumentType": "Sale Invoice",
      "Date": "5/12/2023",
      "Customer Code": "DR3553",
      "Customer Name": "Amar and Sons Traders",
      "Docentry": "LKO2324111194",
      "docnum": "455211",
      "TFPL Code": "TFPL744",
      "SalePerson": "Ramesh Singh Baghari",
      "ItemCode": "FG0600",
      "ItemName": "Supertrop",
      "UQC": "KG",
      "Quantity": "264",
      "Price": "89.34",
      "Basic Value after Discount LineWise": "23,349.28",
      "Original Ref. Date": "1/1/2000"
  },
  {
      "DocumentType": "Sale Invoice",
      "Date": "5/12/2023",
      "Customer Code": "DR0047",
      "Customer Name": "Pure Water Technology",
      "Docentry": "GWL2324111195",
      "docnum": "891091",
      "TFPL Code": "TFPL744",
      "SalePerson": "Ramesh Singh Baghari",
      "ItemCode": "FG0062",
      "ItemName": "Fondant Red",
      "UQC": "KG",
      "Quantity": "12",
      "Price": "336.85",
      "Basic Value after Discount LineWise": "4,042.20",
      "Original Ref. Date": "1/1/2000"
  },
  {
      "DocumentType": "Sale Invoice",
      "Date": "5/12/2023",
      "Customer Code": "DR1717",
      "Customer Name": "Hans Raj Provision Store",
      "Docentry": "GWL2324111196",
      "docnum": "891092",
      "TFPL Code": "TFPL744",
      "SalePerson": "Ramesh Singh Baghari",
      "ItemCode": "FG1432",
      "ItemName": "Idli Mix",
      "UQC": "KG",
      "Quantity": "40",
      "Price": "88.6",
      "Basic Value after Discount LineWise": "3,508.56",
      "Original Ref. Date": "1/1/2000"
  },
]

function formatDateCustomer(inputDateString) {
  const parsedDate = new Date(inputDateString);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    // If the parsed date is not valid, return today's date
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // Month is zero-indexed, so add 1
    const year = today.getFullYear();

    return `${month}/${day}/${year}`;
  } else {
    // If the parsed date is valid, format and return it
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1; // Month is zero-indexed, so add 1
    const year = parsedDate.getFullYear();

    return `${month}/${day}/${year}`;
  }
}

function formatDateDistributor(inputDateString, inputFormat, outputFormat) {
  const inputDate = new Date(inputDateString);

  const day = String(inputDate.getDate()).padStart(2, '0');
  const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  const year = inputDate.getFullYear();

  const outputDateString = outputFormat
    .replace('dd', day)
    .replace('mm', month)
    .replace('yyyy', year);

  return outputDateString;
}

function validateAndCleanObjectsForCustomer(objects) {
  const requiredKeysSet = new Set([
    "Voucher No",
    "Voucher Date",
    "Distributor Code",
    "Dealer Code",
    "Item Code",
    "Item Description",
    "Item Unit",
    "Sale Qty",
    "Invoice Id",
    "Rate",
    "Billed Value",
  ]);

  function isEmptyObject(obj) {
    return Array.from(requiredKeysSet).every((key) => !obj[key]);
  }

  const cleanedObjects = [];

  console.log("object", objects);

  for (const object of objects) {
    const newObject = {};

    if (isEmptyObject(object)) {
      continue;
    }

    let valid = true;
    for (let key in object) {
      key = key.trim();

      if (requiredKeysSet.has(key)) {
        if (!object[key]) {
          valid = false;
          break;
        } else {
          if (key == "Voucher Date") {
            // console.log("Voucher Date",object[key], formatDateCustomer(object[key].trim()) )

            newObject[key] = formatDateCustomer(object[key].trim());
          } else {
            newObject[key] = object[key];
          }
        }
      }
    }

    newObject.status = valid ? 1 : 2;
    newObject.unique_id =
      newObject["Voucher No"] +
      newObject["Voucher Date"] +
      newObject["Distributor Code"] +
      newObject["Dealer Code"] +
      newObject["Item Code"];
    cleanedObjects.push(newObject);
  }

  console.log("cleanedObjects", cleanedObjects);

  if (cleanedObjects.some((obj) => obj.status === 2)) {
    return {
      isValid: false,
      data: cleanedObjects,
    };
  }

  return {
    isValid: true,
    data: cleanedObjects,
  };
}

function validateAndCleanObjectsForDistributor(objects) {
  const requiredKeysSet = new Set([
    "docnum",
    "Date",
    "Customer Code",
    "ItemCode",
    "ItemName",
    "UQC",
    "Quantity",
    "Price",
    "Basic Value after Discount LineWise",
  ]);

  function isEmptyObject(obj) {
    return Array.from(requiredKeysSet).every((key) => !obj[key]);
  }

  const cleanedObjects = [];

  // console.log("object", objects);

  for (const object of objects) {
    const newObject = {};

    if (isEmptyObject(object)) {
      continue;
    }

    let valid = true;
    for (let key in object) {
      key = key.trim();


      if (requiredKeysSet.has(key)) {
        if (!object[key]) {
          valid = false;
          break;
        } else {
          if (key == "Date") {
            console.log("dateee", )
            newObject[key] = formatDateDistributor(object[key].trim() , "mm/dd/yyyy", "dd/mm/yyyy");
          } else {
            newObject[key] = object[key];
          }
        }
      }
    }

    newObject.status = valid ? 1 : 2;
    newObject.unique_id =
      newObject["docnum"] +
      newObject["Date"] +
      newObject["Customer Code"] +
      newObject["ItemCode"];

    cleanedObjects.push(newObject);
  }

  if (cleanedObjects.some((obj) => obj.status === 2)) {
    return {
      isValid: false,
      data: cleanedObjects,
    };
  }

  return {
    isValid: true,
    data: cleanedObjects,
  };
}

const orderTypeArr = [
  {
    id: "1",
    name: "Sale",
  },
  {
    id: "2",
    name: "Purchase",
  },
];

const UploadOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [canSubmit, setCanSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState(null);


  const [selectedOrderType, setSelectedOrderType] = useState(orderTypeArr[0]);

  const [orderType, setOrderType] = useState(orderTypeArr);

  const [
    bulkUploadOrderFunc,
    { data: bulkUploadOrderData, error: bulkUploadOrderError },
  ] = useTropoliteBulkUploadOrdersMutation();

  const [
    getAppUserTypes,
    {
      data: appUserTypesData,
      isLoading: appUserTypesIsLoading,
      error: appUserTypesError,
    },
  ] = useGetAppUserTypesMutation();

  const handleOnSubmit = (e) => {
    toast.loading("Processing your data...");

    e.preventDefault();
    console.log("userType ", userTypes);
    console.log("selectedOrderType ", selectedOrderType);

    console.log("Final ", finalArray);

    if (finalArray.length == 0) {
      toast.dismiss();
      toast.error(`User type and uploaded data mismatch`, {
        id: "mismatchCsv&UserType",
      });
      return;
    }

    bulkUploadOrderFunc({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        rows: finalArray,
        qty: finalArray.length,
        user_type_id: userTypes.user_type_id,
        user_type: userTypes.user_type,
        method_id: "1",
        method: "Upload Order",
        platform_id: "3",
        platform: "Panel",
        type: selectedOrderType?.name,
      },
    });
  };

  const [userTypes, setUserTypes] = useState();
  useEffect(() => {
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (appUserTypesData && appUserTypesData.body) {
      setUserTypes(appUserTypesData.body[0]);
    }
  }, [appUserTypesData]);

  useEffect(() => {
    if (bulkUploadOrderData) {
      toast.dismiss();
      toast.success("Saved Successfully", {
        id: "bulkUploadOrderData",
      });
      console.log("bulkUploadOrderData", bulkUploadOrderData);
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/orders`);
      }, 400);
    }

    if (bulkUploadOrderError) {
      toast.dismiss();
      if (bulkUploadOrderError.status == 409) {
        toast.error(
          `Duplicate Entry Found : ${bulkUploadOrderError.data.message}`,
        );
      } else {
        toast.error(
          `Something went wrong : ${bulkUploadOrderError.data.message}`,
        );
      }
      console.log("bulkUploadOrderError", bulkUploadOrderError);
    }
  }, [bulkUploadOrderData, bulkUploadOrderError]);

  const [CSVData, setCSVData] = useState(null);

  const [finalArray, setFinalArray] = useState([]);

  useEffect(() => {
    console.log(CSVData);
    if (CSVData && CSVData.parsed && userTypes.user_type == "customer") {
      const res = validateAndCleanObjectsForCustomer(CSVData?.parsed);

      const mappedArray = res.data.map((obj) => ({
        user_id: obj["Dealer Code"],
        unique_id: obj["unique_id"],
        invoice_id: obj["Voucher No"],
        date: obj["Voucher Date"],
        product_code: obj["Item Code"],
        product_name: obj["Item Description"],
        qty: parseFloat(obj["Sale Qty"]),
        price: parseFloat(obj["Rate"]),
        bill_value: parseFloat(obj["Billed Value"].replace(",", "")),
      }));

      // console.log(mappedArray);

      if (res.isValid) {
        setCanSubmit(true);
        setFinalArray(mappedArray);
      }
    } else if (
      CSVData &&
      CSVData.parsed &&
      userTypes.user_type == "distributor"
    ) {
      const res = validateAndCleanObjectsForDistributor(CSVData?.parsed);
      const mappedArray = res.data.map((obj) => ({
        user_id: obj["Customer Code"],
        unique_id: obj["unique_id"],
        invoice_id: obj["docnum"],
        date: obj["Date"],
        product_code: obj["ItemCode"],
        product_name: obj["ItemName"],
        qty: parseFloat(obj["Quantity"]),
        price: parseFloat(obj["Price"]),
        bill_value: parseFloat(
          obj["Basic Value after Discount LineWise"].replace(",", ""),
        ),
      }));

      // console.log(mappedArray);

      if (res.isValid) {
        setCanSubmit(true);
        setFinalArray(mappedArray);
      }
    }
  }, [CSVData]);

  // console.log("show modal", showModal, finalArray);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Upload Orders
            <LinksComponent />
          </div>

          <div className="mx-9 mt-6">

            <div className="flex space-x-3">

            <div className="flex mb-1">
              <button
                type="submit"
                className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center "
                onClick={() => downloadCSV(sampleDataCustomer, "sampleOrderUploadCustomer.csv")}
              >
                Sample Csv : Customer
              </button>
            </div>

            <div className="flex mb-1">
              <button
                type="submit"
                className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center "
                onClick={() => downloadCSV(sampleDataDistributor, "sampleOrderUploadDistributor.csv")}
              >
                Sample Csv : Distributor
              </button>
            </div>
            </div>

            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-full p-4 px-5 justify-center">
              <div className="w-full flex flex-col justify-center align-middle">
                <div className="w-full">
                  <div className="w-full ">
                    <div className="w-full flex justify-evenly  items-center space-x-4 ">
                      <div className="w-1/3 flex items-start ">
                        {appUserTypesData && appUserTypesData.body && (
                          <div className=" w-full">
                            <SelectComponent
                              title={"User Type"}
                              selected={userTypes}
                              setSelected={setUserTypes}
                              dataArr={appUserTypesData.body}
                              placeholder={"Select User Type"}
                            />
                          </div>
                        )}
                      </div>

                      <div className="w-1/3 flex items-start ">
                        <div className=" w-full">
                          <SelectComponent
                            title={"Order Type"}
                            selected={selectedOrderType}
                            setSelected={setSelectedOrderType}
                            dataArr={orderType}
                          />
                        </div>
                      </div>

                      <div className="w-2/3 flex items-center justify-evenly flex-col ">
                        <label className="block text-gray-700 text-sm mb-2">
                          Upload Excel/CSV File:
                        </label>

                        <CSVReader setCSVData={setCSVData} setFileName={setFileName} />
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center space-x-2 ">
                      {/* View CSV File Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center"
                        >
                          View CSV File
                        </button>
                      )}

                      {/* Save Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => handleOnSubmit(e)}
                          disabled={!canSubmit}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {showModal && finalArray && (
            <ModalComponent
              size={"5xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg">
                <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-300">
                      <thead>
                        <tr>
                          {(finalArray.length > 0
                            ? Object.keys(finalArray[0])
                            : []
                          ).map((column, index) => (
                            <th key={index} className="py-2 px-4 border-b">
                              {column}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {finalArray.slice(0, 30).map((item, rowIndex) => (
                          <tr key={rowIndex}>
                            {(finalArray.length > 0
                              ? Object.keys(finalArray[0])
                              : []
                            ).map((column, colIndex) => (
                              <td key={colIndex} className="py-2 px-4 border-b">
                                {item[column]}
                              </td>
                            ))}
                          </tr>
                        ))}

                        <h2 className="mt-2 ml-auto">
                          {" "}
                          {finalArray.length > 30
                            ? `${finalArray.length - 30} rows more`
                            : ` `}
                        </h2>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadOrders;
