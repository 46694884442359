import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  useDeleteSmsTemplateMutation,
  useFetchMessageTemplateQuery,
  useGetSmsTemplateWithLimitOffsetMutation,
  useUpdateSmsTemplateStatusMutation,
} from "../../../features/admin/messageTemplate/messageTemplateApi";
import { toast } from "react-hot-toast";
// import { tenant } from "../../utils/tenant";

export default function MessageTemplate() {
  const navigate = useNavigate();

  const location = useLocation();
  console.log("location",location.state);

  const [selectedRows, setSelectedRows] = useState([]);
  const [userTypes, setUsersTypes] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  // useEffect(() => {
  //   console.log("state", selectedRows);
  // }, [selectedRows]);
  const [
    getSmsTemplateWithLimitOffset,
    {
      data: getSmsTemplateWithLimitOffsetData,
      isError: getSmsTemplateWithLimitOffsetIsError,
      error: getSmsTemplateWithLimitOffsetError,
      isLoading: getSmsTemplateWithLimitOffsetIsLoading,
    },
  ] = useGetSmsTemplateWithLimitOffsetMutation();


  const [
    deleteSmsTemplate,
    {
    data: deleteSmsTemplateData,
    isError: deleteSmsTemplateIsError,
    error: deleteSmsTemplateError,
    isLoading: deleteSmsTemplateIsLoading,
  } ]= useDeleteSmsTemplateMutation();
  
  const [
    updateSmsTemplateStatus
    ,
    {
    data: updateSmsTemplateStatusData,
    isError: updateSmsTemplateStatusIsError,
    error: updateSmsTemplateStatusError,
    isLoading: updateSmsTemplateStatusIsLoading,
  } ]= useUpdateSmsTemplateStatusMutation();


  useEffect(() => {
    console.log("1");
    getSmsTemplateWithLimitOffset({
      token: sessionStorage.getItem("access_token"),
      account_id : location.state.id,
      limit: limit,
      offset: offset,
    });
  }, [limit, offset]);


 if(getSmsTemplateWithLimitOffsetIsError){
  toast.dismiss();
  toast.error("Some Error Occured", {
    id: "getSmsTemplateWithLimitOffsetIsError",
  });
 }

 if(getSmsTemplateWithLimitOffsetIsLoading){
  toast.dismiss();
  toast.loading("Loading...",{
    id : "getSmsTemplateWithLimitOffsetIsLoading"
  })
 }

 useEffect(()=>{
  if(getSmsTemplateWithLimitOffsetData){
    toast.dismiss();
    toast.success(`${getSmsTemplateWithLimitOffsetData.message}`,{
      id : `getSmsCredentialWithLimitOffsetData`
    })
  }
 },[getSmsTemplateWithLimitOffsetData])

 const handleDelete = (row)=>{
  deleteSmsTemplate({
    token: sessionStorage.getItem("access_token"),
    id : Number(row.id)
  });

 }



 useEffect(()=>{
  if(deleteSmsTemplateData){
    toast.dismiss();
    toast.success(`${deleteSmsTemplateData.message}`,{
      id : `getSmsCredentialWithLimitOffsetData`
    })

    getSmsTemplateWithLimitOffset({
      token: sessionStorage.getItem("access_token"),
      account_id : location.state.id,
      limit: limit,
      offset: offset,
    });

  }
 },[deleteSmsTemplateData])




  const handleButtonEditClick = (r) => {
    //handle the edit functionality
    navigate("/tenant/editMessage", { state: r });
  };

  const changeStatusBtn = (row)=>{
    console.log("chaange status");
    updateSmsTemplateStatus(
      {
        token: sessionStorage.getItem("access_token"),
        id : Number(row.id)
      }
    )
  }



 useEffect(()=>{
  if(updateSmsTemplateStatusData){
    console.log("updateSmsTemplateStatusData",updateSmsTemplateStatusData);
    toast.dismiss();
    toast.success(`${updateSmsTemplateStatusData.message}`,{
      id : `updateSmsTemplateStatusData`
    })

    getSmsTemplateWithLimitOffset({
      token: sessionStorage.getItem("access_token"),
      account_id : location.state.id,
      limit: limit,
      offset: offset,
    });

  }
 },[updateSmsTemplateStatusData])


  // const handleChange = useCallback((state) => {
  //   // setSelectedRows(state.selectedRows);
  // }, []);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: "Template Id",
      selector: (row) => row.template_id,
      allowOverflow: false,
      width: "",
    },
    {
      name: "Message",
      selector: (row) => row.template_text,
      // sortable: true,
      allowOverflow: false,
      width: "80px",


    },
    {
      name: "Sender Id",
      selector: (row) => row.sender_id,
      // sortable: true,
      allowOverflow: false,
      width: "60px",

    },
    {
      name: "type",
      selector: (row) => row.type,
      // sortable: true,
      allowOverflow: false,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      
    },
    {
      name: "",
      cell: (r) =>
      {
        // if( r.template_owned_by_id == null){
          if(r.status === "2" )
          {
            return <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => changeStatusBtn(r)}
          >
            In-Active
          </button>
          }else{
            return  <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => changeStatusBtn(r)}
          >
            Active
          </button>
          }
  
        // }else{
        //   return <>In-Active{r.template_owned_by_id}</>;
        // }
      }
       ,
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Action",
      cell: (r) =>
       
          (<button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleDelete(r)}
          >
            Delete
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };
  
  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  // console.log("object",getSmsTemplateWithLimitOffsetData?.body?.data);


  return (
    <div className="main-wrapper">
      <div className="page-content">
        <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              onClick={() => navigate("/addMessageTemplate",{
                state:location.state
              })}
            >
              Add New Message Template
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-xl-12">
            <DataTable
              title="Dynamic Messages"
              data={getSmsTemplateWithLimitOffsetData?.body?.data}
              columns={columns}
              //selectableRows
              // onSelectedRowsChange={handleChange}
              totalRows={getSmsTemplateWithLimitOffsetData?.body?.total}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              pagination
            />
          </div>
        </div>
      </div>
    </div>
  );
}
