import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useAccountTransferListMutation } from "../../../features/tenant/AccountTransfer/accountTransferApi";
import toast from "react-hot-toast";
import { Menu } from "@mui/material";
import { HiOutlinePlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const AccountTransferList = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [total, setTotal] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [myList, setMyList] = useState([]);
  const [totatRows, setTotalRows] = useState([]);

  const initialFilters = {
    old_mobile: "",
    new_mobile: "",
    startDate: "",
    endDate: currentDate,
    limit: 10,
    offset: 0,
  };

  const initialFiltersForCount = {
    startDate: "",
    endDate: currentDate,
    old_mobile: "",
    new_mobile: "",
  };
  const [request, setRequest] = useState(initialFilters);
  const [requestForCount, setRequestForCount] = useState(
    initialFiltersForCount
  );

  const [
    accountTransferList,
    {
      data: accountTransferListData,
      isLoading: accountTransferListIsLoading,
      isError: accountTransferListIsError,
      error: accountTransferListError,
    },
  ] = useAccountTransferListMutation();

  const handlefetchAccountTransfer = () => {
    accountTransferList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  useEffect(() => {
    if (accountTransferListData) {
      console.log("accountTransferListData", accountTransferListData);
      setMyList(accountTransferListData && accountTransferListData?.body?.data);
      setTotalRows(
        accountTransferListData && accountTransferListData?.body?.total
          ? accountTransferListData?.body?.total
          : 0
      );
    }
    if (accountTransferListError) {
      toast.error("something went wrong");
    }
  }, [accountTransferListData, accountTransferListError]);
  const handleSearchFilter = () => {
    handlefetchAccountTransfer();
  };

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    console.log("new offset: ", pageNumber, newOffset, limit);
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
    setRequest({ ...request, offset: newOffset });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    console.log("handlePerRowsChange: ", page);
    setRequest({ ...request, limit: newPerPage });
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Old Mobile",
      cell: (row) => row.old_mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "New Mobile",
      cell: (row) => row.new_mobile,
      sortable: true,
    },
    {
      name: "Changed By Name",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];
  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Account Transfer List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 justify-between">
                  {/* <div className="w-52 h-10">
                  <div className="relative searchin">
                    Old Mobile
                    <input
                      type="text"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile"
                      required
                      value={request.old_mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            old_mobile: newValue,
                          };
                        });
                        console.log("Mobile");
                      }}
                    />
                  </div>
                </div>
                <div className="w-52 h-10">
                  <div className="relative searchin">
                    New Mobile
                    <input
                      type="text"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile"
                      required
                      value={request.new_mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return {
                            ...prevState,
                            new_mobile: newValue,
                          };
                        });
                        console.log("Mobile");
                      }}
                    />
                  </div>
                </div> */}
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                      Start Date
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Start Date"
                        required
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      End Date
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="End Date"
                        required
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row gap-2 h-10">
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary py-2 clear-both float-right "
                        onClick={() => {
                          handleSearchFilter();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <DataTableComponent
                columns={columns}
                data={myList}
                // loading={loading}
                totalRows={totatRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
            </div>
            <div
                  className="fixed bottom-10 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <div className="relative inline-block text-left">
                      <div>
                        <div
                          onClick={() => navigate("add")}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="w-full h-full p-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4"></path></svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTransferList;
