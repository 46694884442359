import React, { useState } from "react";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGetAllRolesMutation } from "../../../features/tenant/appRoles/appRoles.Api";
import { useAddTenantUserMutation, useUpdateTenantUserByIdMutation } from "../../../features/tenant/tenantAuth/tenantAuthApi";
import { useGetAllZoneMutation } from "../../../features/tenant/zones/zonesApi";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";

const AddWebPanelUser = () => {
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState({});
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState(null);
  const [panelUserName, setPanelUserName] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [email, setEmail] = useState(null);
  const [roles, setRoles] = useState([]);
  const [userZone, setUserZone] = useState([]);
  const [allZones, setAllZones] = useState([]);
  const location = useLocation();

  const [
    getAllZone,
    {
      data: getAllZoneData,
      isLoading: getAllZoneIsLoading,
      isError: getAllZoneIsError,
      error: getAllZoneError,
    },
  ] = useGetAllZoneMutation();

  const [
    getAllRoles,
    {
      data: getAllRolesData,
      isLoading: getAllRolesIsLoading,
      isError: getAllRolesIsError,
      error: getAllRolesError,
    },
  ] = useGetAllRolesMutation();

  const [
    addTenantUser,
    {
      data: addTenantUserData,
      isLoading: addTenantUserIsLoading,
      isError: addTenantUserIsError,
      error: addTenantUserError,
    },
  ] = useAddTenantUserMutation();
  const [
    updateTenantUserById,
    {
      data: updateTenantUserByIdData,
      isLoading: updateTenantUserByIdIsLoading,
      isError: updateTenantUserByIdIsError,
      error: updateTenantUserByIdError,
    },
  ] = useUpdateTenantUserByIdMutation();

  useEffect(() => {
    console.log("location ======>",location.state);
    const data = location.state;
    if (data) {
      console.log("entered in if");
      setPanelUserName(data.name);
      setEmail(data.email);
      setPhoneNo(data.mobile);
      setEmployeeId(data.employee_id);
      setDesignation(data.designation);
      setSelectedRole({
        role_id: data.role_id,
        name: data.role_name,
        id: data.id,
      });
      const userZoneTemp = data.zone_id.map((x,index)=>{
        return {
          id: x,
          name: data.zone_name[index]
        }
      })
      console.log("userZoneTemp....",userZoneTemp);
      setUserZone([...userZoneTemp]);
    }
  }, [location.state]);

  useEffect(() => {
    getAllRoles({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
    getAllZone({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (getAllZoneData) {
      const allZone = getAllZoneData.body.map((x) => {
        return {
          id: x.id,
          name: x.zone_name,
        };
      });

      setAllZones(allZone);
    }
    if (getAllZoneError) {
      toast.dismiss();
      toast.error(getAllZoneError.data.message, {
        id: "errorAtGetallzonedata",
      });
    }
  }, [getAllZoneData, getAllZoneError]);

  useEffect(() => {
    if (addTenantUserData) {
      toast.dismiss();
      toast.success(addTenantUserData.message,{
        id : "addTenantUserData",
      });

      navigate(-1);
    }
    if (addTenantUserError) {
      toast.dismiss();
      toast.error(addTenantUserError.data.message,{
        id : "addTenantUserError",
      });
    }
  }, [addTenantUserData, addTenantUserError]);

  useEffect(() => {
    if (getAllRolesData?.body) {
      const responseArray = Object.values(getAllRolesData?.body);
      let objLen = Object.keys(selectedRole).length;
      for (let i = 0; i < responseArray.length; i++) {
        if (i === 0 && objLen == 0) {
          setSelectedRole({
            name: responseArray[i].name,
            id: i + 1,
            role_id: responseArray[i].id,
          });
        }
        setRoles((prevState) => [
          ...prevState,
          {
            name: responseArray[i].name,
            id: i + 1,
            role_id: responseArray[i].id,
          },
        ]);
      }
    }
  }, [getAllRolesData]);

  const handleAddProduct = (e) => {
    e.preventDefault();
 
    if (!panelUserName) {
      toast.error("Please enter the Name", {
        id: "imageError",
      });
      return;
    }
    if (!email) {
      toast.error("Please enter the Email", {
        id: "imageError",
      });
      return;
    }
    if (!phoneNo) {
      toast.error("Please enter the Mobile number", {
        id: "imageError",
      });
      return;
    }
    if (!selectedRole.name) {
      toast.error("Please enter the Role Name", {
        id: "imageError",
      });
      return;
    }
    if (!employeeId) {
      toast.error("Please enter the Dmployee Id", {
        id: "imageError",
      });
      return;
    }
    if (!designation) {
      toast.error("Please enter the Designation", {
        id: "imageError",
      });
      return;
    }
    console.log("userZone",userZone);
    addTenantUser({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        name: panelUserName,
        email: email,
        password: password ? password : location.state.password,
        mobile: phoneNo,
        role_id: selectedRole.role_id,
        employee_id: employeeId,
        designation: designation,
        role_name: selectedRole.name,
        user_zone: userZone
      },
    });
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
  
    if (!panelUserName) {
      toast.error("Please enter the Name", {
        id: "imageError",
      });
      return;
    }
    if (!email) {
      toast.error("Please enter the Email", {
        id: "imageError",
      });
      return;
    }
    if (!phoneNo) {
      toast.error("Please enter the Mobile number", {
        id: "imageError",
      });
      return;
    }
    if (!selectedRole.name) {
      toast.error("Please enter the Role Name", {
        id: "imageError",
      });
      return;
    }
    if (!employeeId) {
      toast.error("Please enter the Dmployee Id", {
        id: "imageError",
      });
      return;
    }
    if (!designation) {
      toast.error("Please enter the Designation", {
        id: "imageError",
      });
      return;
    }
    console.log("userZone",userZone);
    updateTenantUserById({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        name: panelUserName,
        email: email,
        password: password ? password : location.state.password,
        mobile: phoneNo,
        role_id: selectedRole.role_id,
        employee_id: employeeId,
        designation: designation,
        role_name: selectedRole.name,
        user_zone: userZone
      },
      id:location.state.id
    });
  };

  useEffect(() => {
    if (updateTenantUserByIdData) {
      toast.dismiss();
      toast.success(updateTenantUserByIdData.message,{
        id : "updateTenantUserByIdData",
      });

      navigate(-1);
    }
    if (updateTenantUserByIdError) {
      toast.dismiss();
      toast.error(updateTenantUserByIdError.data.message,{
        id : "updateTenantUserByIdError",
      });
    }
  }, [updateTenantUserByIdData, updateTenantUserByIdError]);

  console.log("allZones", allZones);

  return (
    <div className="flex w-full bg-slate-200 h-full justify-center">
      <div className="w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          {location && location.state ? "Update " : "Add "}User
          <LinksComponent />
        </div>
        <div className="mx-4 mt-4 p-3 bg-white shadow rounded-lg">
          <form className="">
            <div>
              <div className="w-full flex justify-center space-x-3 ">
                <div className="mb-6 w-1/4">
                  <InputComponent
                    name={"Name*"}
                    type={"text"}
                    value={panelUserName}
                    setValue={setPanelUserName}
                    required={true}
                  />
                </div>
                <div className="mb-6 w-1/4">
                  <InputComponent
                    name={"Email Id*"}
                    type={"text"}
                    value={email}
                    setValue={setEmail}
                    required={true}
                  />
                </div>
                <div className="mb-6 w-1/4">
                  <InputComponent
                    name={"Designation*"}
                    type={"text"}
                    value={designation}
                    setValue={setDesignation}
                    required={true}
                  />
                </div>

                <div className="mb-6 w-1/4">
                  <InputComponent
                    name={"Phone No*"}
                    type={"text"}
                    value={phoneNo}
                    setValue={setPhoneNo}
                    required={true}
                  />
                </div>
              </div>

              <div className="w-full flex  space-x-3  ">
                <div className="mb-6 w-1/4">
                  <InputComponent
                    name={"Password*"}
                    type={"text"}
                    value={password}
                    setValue={setPassword}
                    required={true}
                  />
                </div>
                <div className="mb-6 w-1/4 slctbox dropds">
                  <SelectComponent
                    title={"Select Role*"}
                    selected={selectedRole}
                    setSelected={setSelectedRole}
                    dataArr={roles}
                  />
                </div>

                <div className="mb-6 w-1/4">
                  <InputComponent
                    name={"Employee Id*"}
                    type={"text"}
                    value={employeeId}
                    setValue={setEmployeeId}
                    required={true}
                  />
                </div>
                <div className="mb-6 w-1/4 slctbox ">
                  <MultiSelectComponent
                    placeholder={"Select Zone*"}
                    selected={userZone}
                    setSelected={setUserZone}
                    dataArr={allZones}
                  />
                </div>

                {/* <div className="mb-6 w-1/4 slctbox">
                  <SelectComponent
                    title={"Select Zone* 2"}
                    selected={userZone}
                    setSelected={setUserZone}
                    dataArr={allZones}
                  />
                </div> */}
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                onClick={ location && location.state ?  handleUpdateUser : handleAddProduct}
              >
                {location && location.state ? "Update " : "Add "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddWebPanelUser;
