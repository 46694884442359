import { rootApi } from "../../api/rootApi";


export const WhatsappNotificationTemplateForTenantApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({

        getWhatsappNotificationTemplateWithLimitOffsetForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "GET",
                url: `/tenant/whatsapp-notification-template/?limit=${params.limit}&offset=${params.offset}${params.account_id ? `&account_id=${params.account_id}` : ""}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                },
              };
            },
          }),
        addWhatsappNotificationTemplateForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "POST",
                url: `/tenant/whatsapp-notification-template/add`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",

                },
                body: params.body,
              };
            },
          }),
          updateWhatsappNotificationTemplateStatusForTenant: builder.mutation({
            query: (params) => {
              console.log("params",params);
              return {
                method: "PATCH",
                url: `/tenant/whatsapp-notification-template/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",


                },
                body: params.body,
              };
            },
          }),
        updateWhatsappNotificationTemplateForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "PUT",
                url: `/tenant/whatsapp-notification-template/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",

                },
                body: params.body,
              };
            },
          }),
        deleteWhatsappNotificationTemplateForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "DELETE",
                url: `/tenant/whatsapp-notification-template/${params.id}`,
                headers: {
                  Authorization: "Bearer " + params.token,
                  slug: params.tenant_id,
                  "Content-Type": "application/json",

                }, };
            },
          }),
        
    })

});
export const { useAddWhatsappNotificationTemplateForTenantMutation,useDeleteWhatsappNotificationTemplateForTenantMutation,
useGetWhatsappNotificationTemplateWithLimitOffsetForTenantMutation,useUpdateWhatsappNotificationTemplateForTenantMutation,useUpdateWhatsappNotificationTemplateStatusForTenantMutation} = WhatsappNotificationTemplateForTenantApi;