import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AiTwotoneDelete } from "react-icons/ai";
import LuckyDrawSetup from "./LuckyDrawSetup";
import {
  useCreateRewardSchemeMutation,
  useFetchRewardSchemeBySchemeIdMutation,
} from "../../../features/tenant/rewardScheme/rewardSchemeApi";
import { getVendorName } from "../../../utils/getVendorName";

function validateData(dataArray) {
  for (let i = 0; i < dataArray.length; i++) {
    const obj = dataArray[i];

    // Check if slab_s is less than slab_e in the same object
    if (obj.slab_s >= obj.slab_e) {
      return {
        isValid: false,
        error: `Error in slab ${i + 1}: slab start is not less than slab end`,
      };
    }

    // Check if slab_s in the next object is greater than slab_e of the previous object
    if (i > 0 && obj.slab_s <= dataArray[i - 1].slab_e) {
      return {
        isValid: false,
        error: `Error between slabs ${i} and ${
          i + 1
        }: slab_s in current slab is not greater than slab end of the previous slab`,
      };
    }
  }

  return {
    isValid: true,
    error: null,
  };
}

function convertResponseToResult(responseArray) {
  // Initialize the result array
  const resultArray = [];

  for (let item of responseArray) {
    const transformedItem = {
      slab_s: parseFloat(item.slab_start),
      slab_e: parseFloat(item.slab_end),
      assured_g: item.assured_gift,
      lucky_draw: item.lucky_draws.map((ld) => ({
        [`ld${ld.ld1_v ? 1 : ld.ld2_v ? 2 : ld.ld3_v ? 3 : 4}_v`]:
          ld.ld1_v || ld.ld2_v || ld.ld3_v || ld.ld4_v,
        [`ld${ld.ld1_q ? 1 : ld.ld2_q ? 2 : ld.ld3_q ? 3 : 4}_q`]:
          ld.ld1_q || ld.ld2_q || ld.ld3_q || ld.ld4_q,
      })),
    };

    resultArray.push(transformedItem);
  }

  return resultArray;
}

const dataObj = {
  slab_s: 0,
  slab_e: 100,
  assured_g: 0,
  lucky_draw: [
    {
      ld1_v: 1000,
      ld1_q: 1,
      ld1_u : 0,
    },
    {
      ld2_v: 10,
      ld2_q: 1,
      ld2_u : 0,

    },
    {
      ld3_v: 10,
      ld3_q: 1,
      ld3_u : 0,

    },
    {
      ld4_v: 10,
      ld4_q: 1,
      ld4_u : 0,

    },
  ],
};

const AddRewardScheme = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [schemeName, setSchemeName] = useState(null);
  const [productCategory, setProductCategory] = useState(null);

  const [
    createRewardScheme,
    {
      data: rewardSchemeData,
      error: rewardSchemeError,
      isLoading: rewardSchemeisLoading,
    },
  ] = useCreateRewardSchemeMutation();
  const [
    fetchRewardSchemeBySchemeId,
    { data: rewardSchemeIdData, error: rewardSchemeIdError },
  ] = useFetchRewardSchemeBySchemeIdMutation();
  const [data, setData] = useState([{ ...dataObj }]);

  const [oldData, setOldData] = useState([]);

  const [isUpdate, setIsUpdate] = useState(false);

  const [isContinue, setIsContinue] = useState(false);

  useEffect(() => {
    console.log(location.state);

    if (location.state) {
      setIsUpdate(true);

      fetchRewardSchemeBySchemeId({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        scheme_id: location.state.scheme_id,
      });
    }
  }, [location]);

  useEffect(() => {
    if (
      rewardSchemeIdData &&
      rewardSchemeIdData.body &&
      rewardSchemeIdData.body.length > 0
    ) {
      console.log("rewardSchemeIdData ", rewardSchemeIdData);
      setSchemeName(rewardSchemeIdData.body[0].scheme_name);
      setProductCategory(rewardSchemeIdData.body[0].product_category);
      const tmp = convertResponseToResult(rewardSchemeIdData.body);
      setOldData(tmp);

      setData(tmp);
    }
    if (rewardSchemeIdError) {
      console.log("rewardSchemeIdError ", rewardSchemeIdError);
    }
  }, [rewardSchemeIdData, rewardSchemeIdError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationResult = validateData(data);
    if (!validationResult.isValid) {
      toast.error(validationResult.error);
    } else {
      setIsContinue(true);
      toast.success("Data is valid!");

      setTimeout(() => {
        var my_element = document.getElementById("luckydraw");

        my_element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 400);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const validationResult = validateData(data);
    if (!validationResult.isValid) {
      toast.error(validationResult.error);
    } else {
      setIsContinue(true);
      toast.success("Data is valid!");

      setTimeout(() => {
        var my_element = document.getElementById("luckydraw");

        my_element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 400);
    }
  };

  const addNewSlab = () => {
    setIsContinue(false);

    const tmp = [...data];
    const a = JSON.parse(JSON.stringify(dataObj));
    const newObj = {
      ...a,
      slab_s: Number(data[data.length - 1].slab_e) + 1,
      slab_e: Number(data[data.length - 1].slab_e) + 100,
    };
    tmp.push(newObj);
    setData(tmp);
  };

  const removeSlab = (idx) => {
    setIsContinue(false);

    const arr = [...data];
    arr.splice(idx, 1);
    setData(arr);
  };

  console.log("slabss", data);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handleChangeSlabValue = (idx, type, value) => {
    setIsContinue(false);
    console.log("chnage", idx, type, value);
    const tmp = [...data];
    tmp[idx][type] = Number(value);

    console.log("idx object ", tmp[idx]);

    setData(tmp);
  };

  const handleChangeLuckyDraw = (idx, ldIndex, type, value) => {
    setIsContinue(false);

    console.log("chnage", idx, ldIndex, type, value);
    const tmp = [...data];
    console.log("object befoore", tmp[idx]);

    tmp[idx].lucky_draw[ldIndex][type] = Number(value);

    console.log(" object after", tmp[idx]);

    setData(tmp);
  };

  const saveToDB = (luckydrawData) => {
    console.log("Slab Values ", data);
    console.log("Lucky Draw Data", luckydrawData);

    createRewardScheme({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        slabs: data,
        // week_data: luckydrawData,
        scheme_name: schemeName,
        product_category: productCategory,
        lucky_draws: luckydrawData,
      },
    });
  };

  useEffect(() => {
    if (rewardSchemeData) {
      console.log("rewardSchemeData", rewardSchemeData);
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/reward-scheme`);
      }, 1000);
    }

    if (rewardSchemeError) {
      console.log("rewardSchemeError", rewardSchemeError);
    }

    if (rewardSchemeisLoading) {
      console.log("rewardSchemeIsLoading", rewardSchemeisLoading);
    }
  }, [rewardSchemeData, rewardSchemeError, rewardSchemeisLoading]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
           Reward Scheme
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
            <div class="flex flex-col overflow-hidden mb-3 ">
                  <div class="capitalize w-full flex space-x-3 justify-evenly">
                    <div className=" w-1/2 flex space-x-2 justify-center items-end">
                      <InputComponent
                        name={"Scheme Name"}
                        placeholder={"Enter Scheme Name"}
                        type={"text"}
                        value={schemeName}
                        setValue={setSchemeName}
                        required={true}
                      />
                    </div>
                    <div className=" w-100 flex space-x-2 justify-center items-end">
                      <InputComponent
                        name={"Product Category"}
                        placeholder={"Enter Product Category"}
                        type={"text"}
                        value={productCategory}
                        setValue={setProductCategory}
                        required={true}
                      />
                    </div>
                    
                  </div>
                 
                </div>
              <div className=" flex w-full flex-col justify-center align-middle rounded-lg">
                <div className="w-full rounded-lg">
                  <div className="rounded-lg w-full flex flex-col justify-start items-start space-y-2">
                    {data.map((each, idx) => (
                      <form className="bg-gray-100 shadow-md w-full border-opacity-40 rounded-lg p-2">
                        <div className=" flex w-full flex-col justify-center align-middle rounded-lg ">
                          <div className="rounded-lg w-full flex flex-col justify-start items-start space-y-2">
                            <div class="w-full h-full rounded-lg ">
                              <div className="">
                                <div className="h-5  flex justify-between">
                                  <span class="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
                                    Slab {idx + 1}
                                  </span>

                                  <span
                                    className="inline-flex items-center rounded-md bg-pink-50 px-2 py-3 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10"
                                    onClick={() => removeSlab(idx)}
                                  >
                                    <AiTwotoneDelete className="w-4 h-4" />
                                  </span>
                                </div>
                                <div className="flex justify-start items-center space-x-2 ">
                                  <div className=" w-1/6 flex flex-col space-x-1 justify-end items-center  rounded-sm p-1">
                                  <h3>&nbsp;</h3>
                                    <InputComponent
                                      name={"Slab Start"}
                                      placeholder={"Slab start value"}
                                      type={"number"}
                                      value={each.slab_s}
                                      setValue={(e) =>
                                        handleChangeSlabValue(idx, "slab_s", e)
                                      }
                                      required={true}
                                    />
                                  </div>
                                  <div className="w-1/6 flex flex-col space-x-1 justify-end items-center  rounded-sm p-1">
                                  <h3>&nbsp;</h3>
                                    <InputComponent
                                      name={"Slab End"}
                                      placeholder={"Slab end value"}
                                      type={"number"}
                                      value={each.slab_e}
                                      setValue={(e) =>
                                        handleChangeSlabValue(idx, "slab_e", e)
                                      }
                                      required={true}
                                    />
                                  </div>
                                  <div className="w-1/6 flex flex-col space-x-1 justify-end items-center  rounded-sm p-1">
                                  <h3>&nbsp;</h3>
                                    <InputComponent
                                      name={"Assured Gift"}
                                      placeholder={"Gift value"}
                                      type={"number"}
                                      value={each.assured_g}
                                      setValue={(e) =>
                                        handleChangeSlabValue(
                                          idx,
                                          "assured_g",
                                          e,
                                        )
                                      }
                                      required={true}
                                    />
                                  </div>

                                  {each.lucky_draw.map((ld, i) => (
                                    <div className=" w-1/4 flex flex-col space-x-1 justify-end items-center  rounded-sm p-1">
                                      <h3 className="font-semibold">LD {i + 1}</h3>
                                      <div className="flex justify-center items-center space-x-1">
                                        {/* {  ld[`ld${i+1}_v`]} */}
                                        <InputComponent
                                          name={"Value"}
                                          placeholder={"Value"}
                                          type={"number"}
                                          value={ld[`ld${i + 1}_v`]}
                                          setValue={(e) =>
                                            handleChangeLuckyDraw(
                                              idx,
                                              i,
                                              `ld${i + 1}_v`,
                                              e,
                                            )
                                          }
                                          required={true}
                                        />
                                        <InputComponent
                                          name={"Qty"}
                                          placeholder={"Qty"}
                                          type={"number"}
                                          value={ld[`ld${i + 1}_q`]}
                                          setValue={(e) =>
                                            handleChangeLuckyDraw(
                                              idx,
                                              i,
                                              `ld${i + 1}_q`,
                                              e,
                                            )
                                          }
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>

                                {/* <div className="flex justify-start space-x-3"> */}

                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    ))}
                  </div>
                  <div className="flex-col justify-center items-center w-full py-2">
                    {true && (
                      // sbTriggersData.body.length + totaltriggerOns.length <
                      //   4 &&
                      <div className="w-full flex justify-end">
                        <button
                          onClick={(e) => addNewSlab()}
                          className="text-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                          style={{
                            backgroundColor: `${primaryColor["500"]}`,
                          }}
                        >
                          Add Slab
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-2 border-t border-gray-100 pt-3">
                {isUpdate ? (
                  <button
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                ) : (
                  isContinue === false && (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Continue
                    </button>
                  )
                )}
              </div>

              {isContinue && (
              <div id="luckydraw">
                <LuckyDrawSetup dataArr={data} submitFunction={saveToDB} />
              </div>
            )}
            </div>

          
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRewardScheme;
