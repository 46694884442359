import { configureStore } from "@reduxjs/toolkit";
import { rootApi } from "../features/api/rootApi";
import counterReducer from "../features/counter/counterSlice";
import { formTemplateApi } from "../features/admin/formTemplate/formTemplateApi";
import { messageTemplateApi } from "../features/admin/messageTemplate/messageTemplateApi";
import { adminAuthApi } from "../features/admin/adminAuth/adminAuthApi";
import adminAuthSlice from "../features/admin/adminAuth/adminAuthSlice";
import boardsSlice from "../features/admin/workflow/boardSlice";
import { tenantAuthApi } from "../features/tenant/tenantAuth/tenantAuthApi";
import { tenantAuthSlice } from "../features/tenant/tenantAuth/tenantAuthSlice";
import { vendorThemeApi } from "../features/admin/vendorTheme/vendorThemeApi";
import { productsApi } from "../features/tenant/products/productsApi";
import { companyProfileSlice } from "../features/tenant/companyProfile/companyProfileSlice";
import { productPointsApi } from "../features/tenant/rewardify/productPoints/productPoints";
import { eCatalogueApi } from "../features/tenant/eCatalogue/eCatalogueApi";
import { pgCredentials } from "../features/tenant/payoutGatewayCredentials/payoutGatewayCredentialsApi";
import { appUserTypeApi } from "../features/tenant/appUserType/appUserTypeApi";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    adminCredentials: adminAuthSlice,
    [formTemplateApi.reducerPath]: formTemplateApi.reducer,
    [messageTemplateApi.reducerPath]: messageTemplateApi.reducer,
    [adminAuthApi.reducerPath]: adminAuthApi.reducer,
    [vendorThemeApi.reducerPath]: vendorThemeApi.reducer,
    boards: boardsSlice.reducer,
    [tenantAuthApi.reducerPath]: tenantAuthApi.reducer,
    companyProfle: companyProfileSlice.reducer,
    tenantCredentials: tenantAuthSlice.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [productPointsApi.reducerPath] : productPointsApi.reducer,
    [eCatalogueApi.reducerPath] : eCatalogueApi.reducer,
    [pgCredentials.reducerPath] : pgCredentials.reducer,
    [appUserTypeApi.reducerPath] : appUserTypeApi.reducer

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([rootApi.middleware]),
});
