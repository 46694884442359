import { rootApi } from "../../api/rootApi";

export const appBannerApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addAppBanner: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/appBanner/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    getAppBanner: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/appBanner/`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    deleteAppBanner: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/appBanner/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const { useAddAppBannerMutation, useGetAppBannerMutation,useDeleteAppBannerMutation } =
  appBannerApi;
