import { rootApi } from "../../api/rootApi";

export const tenantWebDashboardApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenantWebDashboards: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/tenantWebDashboard`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addTenantWebDashboard: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/tenantWebDashboard/add`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),
    updateTenantWebDashboard: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/tenantWebDashboard/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getTenantWebDashboardById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/tenantWebDashboard/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getTenantWebDashboardByTenant: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/tenantWebDashboard/tenantId/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    removeTenantWebDashboard: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/tenantWebDashboard/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
  }),
});
export const {
  useGetAllTenantWebDashboardsMutation,
  useAddTenantWebDashboardMutation,
  useUpdateTenantWebDashboardMutation,
  useGetTenantWebDashboardByIdMutation,
  useRemoveTenantWebDashboardMutation,
  useGetTenantWebDashboardByTenantMutation
} = tenantWebDashboardApi;
