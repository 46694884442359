export function downloadCSV(dataArray, fileName) {
    // Convert array of objects to CSV format
    const csvContent = dataArray.reduce((acc, row) => {
      return (
        acc +
        Object.values(row)
          .map(value => `"${value}"`)
          .join(',') +
        '\n'
      );
    }, Object.keys(dataArray[0]).join(',') + '\n');
  
    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv' });
  
    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName || 'data.csv';
    link.click();
  }