import { rootApi } from "../../api/rootApi";

export const userMappingApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createUserMapping: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/user-mapping/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    createUserMappinginBulk: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/user-mapping/bulk`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchUserMappingByAppUserIdAndMappedUserType: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/user-mapping/${params.app_user_id}?mapped_user_type=${params.mapped_user_type}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchUserMappingByUserTypeAndMappedUserType: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/user-mapping/?user_type=${params.user_type}&&mapped_user_type=${params.mapped_user_type}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    deleteUserMapping: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/user-mapping/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    getMappingDetailsByAppUserId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/user-mapping/details/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    getAllMappingDetails: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/user-mapping`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    getAllMappingDetailsCount: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/user-mapping/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

  }),
});

export const {
  useCreateUserMappingMutation,
  useCreateUserMappinginBulkMutation,
  useFetchUserMappingByAppUserIdAndMappedUserTypeMutation,
  useFetchUserMappingByUserTypeAndMappedUserTypeMutation,
  useDeleteUserMappingMutation,
  useGetMappingDetailsByAppUserIdMutation,
  useGetAllMappingDetailsMutation,
  useGetAllMappingDetailsCountMutation
} = userMappingApi;
