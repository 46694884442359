import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useGetAllUserPermissionsMutation } from "../../../features/tenant/userPermissions/userPermissions";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllRolesMutation,
  useUpdateUserRoleByIdMutation,
} from "../../../features/tenant/appRoles/appRoles.Api";
import { toast } from "react-hot-toast";

const EditWebPanelRolePermission = () => {
  const [options, setOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const data = location.state;
    setSelectedRole({
      name: data.name,
      id: data.id,
      permission: data.permission,
    });
    if (data.permission) {
      setSelectedOptions([...selectedOptions, ...data.permission]);
    }
  }, [location.state]);

  const [
    getAllUserPermissions,
    {
      data: getAllUserPermissionsData,
      isLoading: getAllUserPermissionsIsLoading,
      isError: getAllUserPermissionsIsError,
      error: getAllUserPermissionsError,
    },
  ] = useGetAllUserPermissionsMutation();

  const [
    getAllRoles,
    {
      data: getAllRolesData,
      isLoading: getAllRolesIsLoading,
      isError: getAllRolesIsError,
      error: getAllRolesError,
    },
  ] = useGetAllRolesMutation();
  const [
    updateUserRoleById,
    {
      data: updateUserRoleByIdData,
      isLoading: updateUserRoleByIdIsLoading,
      isError: updateUserRoleByIdIsError,
      error: updateUserRoleByIdError,
    },
  ] = useUpdateUserRoleByIdMutation();

  const handleSubmit = (e) => {
    e.preventDefault()
    updateUserRoleById({
      id: selectedRole.id,
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        permission: selectedOptions,
      },
    });
  };

  useEffect(() => {
    if (updateUserRoleByIdData) {
      toast.success("Permissions Updated Successfully !!");
      navigate(-1);
    }
    if (updateUserRoleByIdError) {
      toast.error("Error in Creating User");
    }
  }, [updateUserRoleByIdData, updateUserRoleByIdError]);
  useEffect(() => {
    getAllRoles({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    getAllUserPermissions({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (getAllRolesData?.body) {
      setRoles(getAllRolesData?.body);
    }
  }, [getAllRolesData]);
  
  useEffect(() => {
    let myArr = [];
    if (getAllUserPermissionsData?.body.length) {
      for (let i = 0; i < getAllUserPermissionsData.body.length; i++) {
        if (!getAllUserPermissionsData.body[i].link&&getAllUserPermissionsData.body[i].type==='1') {
          myArr.push({
            category: getAllUserPermissionsData.body[i].name,
            id: getAllUserPermissionsData.body[i].id,
            children: [],
            label: getAllUserPermissionsData.body[i].action,
          });
        }
      }

      if (myArr.length) {
        for (let i = 0; i < myArr.length; i++) {
          for (let j = 0; j < getAllUserPermissionsData.body.length; j++) {
            if (
              myArr[i].label ===
              getAllUserPermissionsData.body[j].parent_module_id
            ) {
              myArr[i].children.push({
                category: getAllUserPermissionsData.body[j].name,
                id: getAllUserPermissionsData.body[j].id,
                label: getAllUserPermissionsData.body[j].action,
              });
            }
          }
        }
      }
    }
    setOptions(myArr);
  }, [getAllUserPermissionsData]);

  const handleOptionChange = (option) => {
    let updatedOptions;

    if (selectedOptions.includes(option.id)) {
      // Deselect the option and its children
      updatedOptions = selectedOptions.filter(
        (id) => !getOptionAndChildIds(option).includes(id)
      );
    } else {
      // Select the option and its children
      updatedOptions = [...selectedOptions, ...getOptionAndChildIds(option)];
    }

    setSelectedOptions(updatedOptions);
  };

  const getOptionAndChildIds = (option) => {
    let ids = [option.id];

    if (option.children) {
      option.children.forEach((child) => {
        ids = [...ids, ...getOptionAndChildIds(child)];
      });
    }

    return ids;
  };

  const isOptionSelected = (option) => {
    if (selectedOptions.includes(option.id)) {
      return true;
    }

    if (option.children) {
      return option.children.every((child) =>
        selectedOptions.includes(child.id)
      );
    }

    return false;
  };
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Web Panel Users
            <LinksComponent />
          </div>

          <div className="p-3">
          <div className=" ">
          <div className="m-auto p-auto w-full bg-gray-100 p-3">
            <SelectComponent
              title={"Select Role*"}
              selected={selectedRole}
              setSelected={setSelectedRole}
              dataArr={roles}
            />
          </div>
          <div className="w-full m-auto flex overflow-auto gap-2 p-3">
            {options.length &&
              options.map((option) => (
                <div key={option.id} className="bg-white shadow rounded-lg p-3">
                  <label className="flex bg-gray-100 p-2 rounded-md">
                    <input
                      type="checkbox"
                      checked={isOptionSelected(option)}
                      onChange={() => handleOptionChange(option)}
                      className="mr-2"
                    />
                    <h4 className="capitalize">{option.label}</h4>
                  </label>
                  {option.children && (
                    <div className=" flex ml-3">
                      <ul>
                        {option.children.map((child) => (
                          <div key={child.id} className="flex d-row pt-2">
                            <li className="flex">
                              <input
                                type="checkbox"
                                checked={selectedOptions.includes(child.id)}
                                onChange={() => handleOptionChange(child)}
                                className="mr-2"
                              />
                              <h4 className="capitalize">{child.label}</h4>
                            </li>
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="flex justify-center mt-3">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div> </div>
        </div>
      </div>
    </>
  );
};

export default EditWebPanelRolePermission;
