import React from 'react'

const Footer = () => {
  return (
    <footer className="border-t p-3 text-xs bg-gray-100">
    2023 © Designed &amp; Developed by Genefied.
  </footer>

  )
}

export default Footer