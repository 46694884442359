import { rootApi } from "../../api/rootApi";

export const giftApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllErrorLogs: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/error?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchErrorById: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/error/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    deleteErrorById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/error/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchAllErrorLogsMutation,
  useFetchErrorByIdQuery,
  useDeleteErrorByIdMutation,
} = giftApi;
