import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { Menu } from "@headlessui/react";
import {
  useDeleteSmsCredentialForTenantMutation,
  useGetSmsCredentialWithLimitOffsetForTenantMutation,
  useUpdateSmsCredentialStatusForTenantMutation,
} from "../../../features/tenant/smsCredential/smsCredentialApi";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";

const Status = ["active", "inactive", "deleted"];
const providerArray = [
  {
    id: 1,
    name: "orbital",
    form: {
      name: ["user_id", "password", "entity_id"],
      view_name: ["User Id", "Password", "Entity Id"],
    },
  },
  {
    id: 2,
    name: "smslane",
    form: {
      name: ["api_key", "entity_id"],
      view_name: ["Api Key", "Entity Id"],
    },
  },
  {
    id: 3,
    name: "juro",
    form: {
      name: ["token", "entity_id"],
      view_name: ["Token", "Entity Id"],
    },
  },
];

const SMSCredential = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const addSMSCredentialPermission = hasPermission("addSmsSetup");
  const editSMSCredentialPermission = hasPermission("editSmsSetup");
  const deleteSMSCredentialPermission = hasPermission("deleteSmsSetup");
  const updateSMSCredentialStatusPermission = hasPermission("updateSmsSetup");
  const viewSMSTemplatesPermission = hasPermission("viewSmsSetup");

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    getSmsCredentialWithLimitOffsetForTenant,
    {
      data: getSmsCredentialWithLimitOffsetForTenantData,
      isLoading: getSmsCredentialWithLimitOffsetForTenantIsLoading,
      isError: getSmsCredentialWithLimitOffsetForTenantIsError,
      error: getSmsCredentialWithLimitOffsetForTenantError,
    },
  ] = useGetSmsCredentialWithLimitOffsetForTenantMutation();

  const [
    deleteSmsCredentialForTenant,
    {
      data: deleteSmsCredentialForTenantData,
      isLoading: deleteSmsCredentialForTenantIsLoading,
      isError: deleteSmsCredentialForTenantIsError,
      error: deleteSmsCredentialForTenantError,
    },
  ] = useDeleteSmsCredentialForTenantMutation();

  const [
    updateSmsCredentialStatusForTenant,
    {
      data: updateSmsCredentialStatusForTenantData,
      isLoading: updateSmsCredentialStatusForTenantIsLoading,
      isError: updateSmsCredentialStatusForTenantIsError,
      error: updateSmsCredentialStatusForTenantError,
    },
  ] = useUpdateSmsCredentialStatusForTenantMutation();

  const fetchSMSCredential = (limit, offset) => {
    getSmsCredentialWithLimitOffsetForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    const viewSMSCredentialPermission = hasPermission("viewSmsSetup");

    if (!viewSMSCredentialPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchSMSCredential(limit, offset);
  }, [limit, offset]);

  useEffect(() => {
    if (getSmsCredentialWithLimitOffsetForTenantData) {
      toast.dismiss();
      toast.success(getSmsCredentialWithLimitOffsetForTenantData.message, {
        id: "getSmsCredentialWithLimitOffsetForTenantData",
      });
    }
    if (getSmsCredentialWithLimitOffsetForTenantError) {
      toast.dismiss();
      toast.error("Oops some error occurred", {
        id: "getSmsCredentialWithLimitOffsetForTenantError",
      });
    }
  }, [
    getSmsCredentialWithLimitOffsetForTenantData,
    getSmsCredentialWithLimitOffsetForTenantError,
  ]);

  if (getSmsCredentialWithLimitOffsetForTenantIsLoading) {
    toast.loading("Loading ..", {
      id: "getSmsCredentialWithLimitOffsetForTenantIsLoading",
    });
  }

  console.log(
    "fetchSMSCredential",
    getSmsCredentialWithLimitOffsetForTenantData
  );

  const handleEditSMSCredential = (row, form) => {
    console.log(row, "----------------", form);
    if (editSMSCredentialPermission) {
      navigate("add", {
        state: {
          data: row,
          form: form,
          edit: true,
        },
      });
    } else {
      toast.error("You don't have permission to Delete The status", {
        id: "You don't have permission to Delete The status",
      });
    }
  };
  const handleDeleteSMSCredential = (row) => {
    if (deleteSMSCredentialPermission) {
      deleteSmsCredentialForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: row.id,
      });
    } else {
      toast.error("You don't have permission to Delete The status", {
        id: "You don't have permission to Delete The status",
      });
    }
  };
  const handleStatusChange = (row, status) => {
    console.log("row data ", row);
    if (updateSMSCredentialStatusPermission) {
      updateSmsCredentialStatusForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: Number(row.id),
      });
    } else {
      toast.error("You don't have permission to change status", {
        id: "You can not update the status",
      });
    }
  };

  useEffect(() => {
    if (deleteSmsCredentialForTenantData) {
      toast.dismiss();
      toast.success("Deleted Successfully", {
        id: "deleteSmsCredentialForTenantData",
      });
    }
    if (deleteSmsCredentialForTenantError) {
      toast.dismiss();
      toast.error("Oops , something went wrong ", {
        id: "deleteSmsCredentialForTenantError",
      });
    }
    fetchSMSCredential(limit, offset);
  }, [deleteSmsCredentialForTenantData, deleteSmsCredentialForTenantError]);

  useEffect(() => {
    if (updateSmsCredentialStatusForTenantData) {
      toast.dismiss();
      toast.success("Status Updated Successfully", {
        id: "updateSmsCredentialStatusForTenantData",
      });
    }
    if (updateSmsCredentialStatusForTenantError) {
      toast.dismiss();
      toast.error("Oops , something went wrong ", {
        id: "updateSmsCredentialStatusForTenantError",
      });
    }
    fetchSMSCredential(limit, offset);
  }, [
    updateSmsCredentialStatusForTenantData,
    updateSmsCredentialStatusForTenantError,
  ]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Credentials",
      width: "300px",
      cell: (row) => {
        return providerArray.map((p) => {
          if (p.name === row.type) {
            return p.form?.name.map((x, index) => {
              if (index < Object.keys(row.api_credential).length) {
                return (
                  <>
                    <div className=""
                      key={index}
                      // className="form-group row"
                    >
                      <div className="w-32 mr-2 mb-2 m-2">
                        <label for="name" className="mb-1"> {p?.form.view_name[index]} </label>
                        <input
                          name={x}
                          className="form-control"
                          value={Object.values(row.api_credential)[index]}
                          type="text"
                        />
                      </div>
                    </div>
                  </>
                );
              } else {
                return <></>;
              }
            });
          } else {
            return <></>;
          }
        });
      },
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "Entity Id",
      cell: (row) => row.entity_id,
      sortable: true,
    },
    {
      name: "",
      cell: (row) =>
        //   updateStatusPermission &&
        row.status == "1" ? (
          <>
            {" "}
            <button
              onClick={() => {
                handleStatusChange(row, "2");
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              {/* <AiOutlineEdit className="mr-1" /> */}
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleStatusChange(row, "1");
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            InActive
          </button>
        ),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        // editProductPermission && (
        <div className="">
          <button
            onClick={() => {
              providerArray.map((p) => {
                if (p.name === row.type) {
                  handleEditSMSCredential(row, p);
                }
              });
            }}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            edit
          </button>
        </div>
      ),
      // ),
      
    },
    {
      name: "",
      cell: (row) => (
        <div className="">
          <button
            onClick={() => {
              if (viewSMSTemplatesPermission) {
                navigate("sms-templates", {
                  state: row,
                });
              } else {
                toast.error("You don't have permission to view Template", {
                  id: "You don't have permission to view Template",
                });
              }
            }}
            className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            Templates
          </button>
        </div>
      ),
     
    },
    {
      name: "",
      cell: (row) => (
        // deleteProductPermission &&
        <button
          onClick={() => handleDeleteSMSCredential(row)}
          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        >
          <AiOutlineDelete className="mr-1" />
          Delete
        </button>
      ),
      minWidth: "130px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            SMS Credentials
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"SMS Credential List"}
                data={
                  getSmsCredentialWithLimitOffsetForTenantData &&
                  getSmsCredentialWithLimitOffsetForTenantData.body.data
                }
                // loading={productsIsLoading}
                totalRows={
                  getSmsCredentialWithLimitOffsetForTenantData &&
                  getSmsCredentialWithLimitOffsetForTenantData.body.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                
              </DataTableComponent>
              {addSMSCredentialPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                      {/* <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -top-12 right-0 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-blue-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          onClick={() => navigate(`add`)}
                          className="p-2 font-semibold hover:text-gray-600 "
                        >
                          Add New Product
                        </div>
                      </Menu.Items>
                    </Transition> */}
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {showModal && (
          <ProductModal
            data={productData}
            show={showModal}
            setShow={setShowModal}
          />
        )} */}
      </div>
    </>
  );
};

export default SMSCredential;
