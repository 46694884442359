import React, { useEffect, useState } from "react";
import { useAddCustomNotificationsMutation } from "../../../features/tenant/CustomNotifications/CustomNotificationApi";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const templateTypeArray = [
  { id: 1, type: "welcome bonus" },
  { id: 2, type: "gift redeem" },
  { id: 3, type: "gift status" },
  { id: 4, type: "tier upgrade" },
  { id: 5, type: "nudge campaign" },
  { id: 6, type: "redemption campaign" },
];

const AddCustomNotifications = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const [isUpdate, setIsUpdate] = useState(
    location?.state?.edit ? true : false
  );
  const [selectTemplateType, setTemplateType] = useState(0);
  const [inputTemplateTitle, setTemplateTitle] = useState(null);
  const [inputTemplateBody, setTemplateBody] = useState(null);
  const [inputLanguage, setLanguage] = useState(null);

  const [
    AddCustomNotifications,
    {
      data: AddCustomNotificationsData,
      isLoading: AddCustomNotificationsIsLoading,
      isError: AddCustomNotificationsIsError,
      error: AddCustomNotificationsError,
    },
  ] = useAddCustomNotificationsMutation();

  const handleAddTemplate = (e) => {
    e.preventDefault();

    if (inputLanguage == null || inputLanguage == "") {
      toast.error("please enter language");
      return;
    }
    if (inputTemplateBody == null || inputTemplateBody == "") {
      toast.error("please enter Template Body");
      return;
    }
    if (inputTemplateTitle == null || inputTemplateTitle == "") {
      toast.error("please enter Title ");
      return;
    }

    // console.log("selectTemplateType", selectTemplateType);
    // console.log("inputTemplateText", inputTemplateText);
    // console.log("inputSenderId", inputSenderId);
    // console.log("inputTemplateId", inputTemplateId);

    const template = {
      title: inputTemplateTitle,
      body: inputTemplateBody,
    };

    AddCustomNotifications({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        template: template,
        language: inputLanguage,
        template_type: templateTypeArray[selectTemplateType].type,
      },
    });
  };

  useEffect(() => {
    if (AddCustomNotificationsData) {
      toast.dismiss();
      toast.success(`${AddCustomNotificationsData.message}`, {
        id: `AddCustomNotificationsData`,
      });
      navigate(-1);
    }
    if (AddCustomNotificationsError) {
      toast.dismiss();
      toast.error(`${AddCustomNotificationsError.data.message}`, {
        id: `AddCustomNotificationsError`,
      });
    }
  }, [AddCustomNotificationsData, AddCustomNotificationsError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}
            Add Custom Notifications
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className="w-full flex justify-center space-x-3 ">
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-0">
                      &nbsp;
                    </label>
                    <select
                      type="text"
                      className="h-auto shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      placeholder="Add new UserType"
                      value={selectTemplateType}
                      onChange={(e) => setTemplateType(Number(e.target.value))}
                    >
                      {templateTypeArray &&
                        templateTypeArray.length > 0 &&
                        templateTypeArray.map((x) => {
                          if (isUpdate) {
                            if (x.type == location.state.data.type) {
                              return (
                                <option value={Number(x.id) - 1}>
                                  {x.type}
                                </option>
                              );
                            }
                            return;
                          } else {
                            return (
                              <option value={Number(x.id) - 1}>{x.type}</option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div>
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                      for="name"
                    >
                      Title{" "}
                    </label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputTemplateTitle}
                      onChange={(e) => {
                        setTemplateTitle(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>{" "}
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                      for="name"
                    >
                      Body
                    </label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputTemplateBody}
                      onChange={(e) => {
                        setTemplateBody(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                      for="name"
                    >
                      Language
                    </label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputLanguage}
                      onChange={(e) => {
                        setLanguage(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-2">
                {isUpdate ? (
                  <button
                    type="update"
                    //   onClick={(e) => handleUpdate(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleAddTemplate}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Add
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomNotifications;
