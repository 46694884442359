import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
  useDeleteGiftMutation,
  useFetchAllGiftsMutation,
} from "../../../features/tenant/gift/giftApi";

import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import { toast } from "react-hot-toast";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";

const GiftList = () => {
  const addGiftPermission = hasPermission("addGift");
  const editGiftPermission = hasPermission("editGift");
  const deleteGiftPermission = hasPermission("deleteGift");
  const [showImageModal, setShowImageModal] = useState(false);
  const initialRequest = {
    name: "",
    limit: 10,
    offset: 0,
    category: "",
    brand: "",
  };
  const [request, setRequest] = useState(initialRequest);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.name === "" &&
      request.brand === "" &&
      request.category === ""
    ) {
      fetchGiftsFunction();
      // fetchGiftsCsvFunction();
    }
  }, [request, limit, offset]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Brand",
      cell: (row) => row.brand,
      sortable: true,
      width: "120px",
    },
    {
      name: "Category",
      cell: (row) => row.category,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Value",
      cell: (row) => row.value,
      sortable: true,
      width: "120px",
    },
    {
      name: "Image",
      cell: (row) =>
        row.images.length > 0 && (
          <div className="w-12 h-12 my-2 rounded-full">
            <img
              // style={{ width: "40px", padding: "10px" }}
              src={row.images[0]}
              alt="gift-images"
              className="rounded-full"
              onClick={() => setShowImageModal(row.images[0])}
            />
          </div>
        ),
      maxWidth: "120px",
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) =>
        editGiftPermission && (
          <div>
            <button
              onClick={() =>
                navigate("add", {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              Edit
            </button>
          </div>
        ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) =>
        deleteGiftPermission && (
          <div>
            <button
              onClick={() => handleDelete(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      minWidth: "120px",
    },
  ];

  const navigate = useNavigate();

  const [
    fetchGifts,
    {
      data: giftsData,
      isLoading: giftsIsLoading,
      isError: giftsIsError,
      error: giftsError,
    },
  ] = useFetchAllGiftsMutation();

  const [
    fetchGiftsCsv,
    {
      data: giftsCsvData,
      isLoading: giftsCsvIsLoading,
      isError: giftsCsvIsError,
      error: giftsCsvError,
    },
  ] = useFetchAllGiftsMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("giftList");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    // fetchGiftsFunction();
    // fetchGiftsCsvFunction();
  }, []);

  useEffect(() => {
   
    if (giftsData) {
      setMyList(giftsData && giftsData.body.gifts ? giftsData?.body?.gifts : []);
      setTotalRows(giftsData && giftsData.body.total_gifts ? giftsData?.body?.total_gifts : 0);
    }

    // fetchGiftsFunction();
    // fetchGiftsCsvFunction();
  }, [giftsData]);

  const fetchGiftsFunction = () => {
    fetchGifts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };
  const fetchGiftsCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchGiftsCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (giftsCsvData && giftsCsvData?.body?.gifts) {
      setCsvData(giftsCsvData.body.gifts);
    }

    if (giftsCsvError) {
      setCsvData(null);
    }
  }, [giftsCsvData, giftsCsvError]);

  const [
    deleteGiftById,
    {
      data: deleteGift,
      isLoading: deleteGiftIsLoading,
      isError: deleteGiftIsError,
      error: deleteGiftError,
    },
  ] = useDeleteGiftMutation();

  useEffect(() => {
    if (deleteGift) {
      toast.success("Deleted Successfully");
      fetchGifts({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: request,
      });
    }
    if (deleteGiftError) {
      toast.error("Something went wrong");
    }
  }, [deleteGift, deleteGiftError]);

  const handleDelete = (data) => {
    if (window.confirm("are you sure you want to delete this gift")) {
      deleteGiftById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: data.id,
      });
    }
    return;
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  useEffect(() => {
    if(giftsData && giftsData.body.gifts){
      setMyList(giftsData.body.gifts);
      setTotalRows(giftsData.body.total_gifts);
    }
  }, [giftsData])
  

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Gifts
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="w-full ">
              {/* {giftsCsvData && giftsCsvData?.body?.gifts && (
                <ExportCSV
                  data={giftsCsvData && giftsCsvData?.body?.gifts}
                  columnsToExport={[
                    "brand",
                    "category",
                    "name",
                    "points",
                    "value",
                  ]}
                />
              )} */}

              <div className="mb-2">
                <CustomExport
                  fetchData={fetchGiftsCsvFunction}
                  data={csvData}
                  defaultColumnExport={[
                    "brand",
                    "category",
                    "name",
                    "points",
                    "value",
                  ]}
                  filename="gift_list"
                />
              </div>
              <div className=" w-full bg-white shadow rounded-lg">
                <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                  <div className="flex  space-x-2 justify-end">
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Brand Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Brand Name..."
                        required
                        value={request.brand}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, brand: newValue };
                          });
                        }}
                      />
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Category Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Category Name..."
                        required
                        value={request.category}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, category: newValue };
                          });
                        }}
                      />
                    </div>
                    <div className="relative searchin">
                      <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Gift Name
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Gift Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                    <div className="flex flex-row  gap-2 h-10">
                      <div className="relative">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary pdbtn_all clear-both float-right "
                          onClick={() => {
                            fetchGiftsFunction();
                            // fetchGiftsCsvFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                          &nbsp;
                        </label>
                        <button
                          className="btn btn-outline-primary clear-both pdbtn_all float-right whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {giftsIsLoading && <CircleLoaderComponent />}
                <DataTableComponent
                  columns={columns}
                  data={myList}
                  // loading={loading}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
                {addGiftPermission && (
                  <div
                    className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                    id="box_btn"
                  >
                    <div className="text-right">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button
                            onClick={() => navigate(`add`)}
                            className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                            style={{
                              backgroundColor: `${primaryColor["700"]}`,
                            }}
                          >
                            <HiOutlinePlus className="w-full h-full p-3" />
                          </Menu.Button>
                        </div>
                        {/* <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -top-12 right-0 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-blue-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          onClick={() => navigate(`add`)}
                          className="p-2 font-semibold hover:text-gray-600 "
                        >
                          Add New Gift
                        </div>
                      </Menu.Items>
                    </Transition> */}
                      </Menu>
                    </div>
                  </div>
                )}

                {showImageModal && (
                  <ImageModal
                    show={showImageModal ? true : false}
                    setShow={setShowImageModal}
                    size="md"
                    data={showImageModal}
                  >
                    <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                      <img
                        src={showImageModal}
                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                      />
                    </div>
                  </ImageModal>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftList;
