import { rootApi } from "../../api/rootApi";

export const tenantAuthApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    tenantLogin: builder.mutation({
      query: (body) => {
        return {
          method: "POST",
          url: "/tenant/login",
          headers: {
            "Content-Type": "application/json",
            slug: body.slug,
          },
          body: JSON.stringify(body.data),
        };
      },
    }),
    addTenantUser: builder.mutation({
      query: (body) => {
        return {
          method: "POST",
          url: "/tenant/add",
          headers: {
            "Content-Type": "application/json",
            slug: body.tenant_id,
            Authorization: "Bearer " + body.token,
          },
          body: body.data,
        };
      },
    }),
    getAllTenantUser: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant`,
          headers: {
            "Content-Type": "application/json",
            slug: params.tenant_id,
            Authorization: "Bearer " + params.token,
          },
          body: params.body,
        };
      },
    }),
    updateTenantUserById: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    deleteTenantUser: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});
export const {
  useTenantLoginMutation,
  useAddTenantUserMutation,
  useGetAllTenantUserMutation,
  useDeleteTenantUserMutation,
  useUpdateTenantUserByIdMutation,
} = tenantAuthApi;
