import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { AiOutlineDelete } from "react-icons/ai";
import toast from "react-hot-toast";
import { useDeleteAllunEnteredProductsMutation, useGetAllunEnteredProductsMutation } from "../../../features/tenant/OzoneAPI/ozoneApi";

const UnEnteredProducts = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const initialRequest = {
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };



  const [
    getAllunEnteredProducts,
    {
      data: getAllunEnteredProductsData,
      isLoading: getAllunEnteredProductsLoading,
      isError: getAllunEnteredProductsIsError,
      error: getAllunEnteredProductsError,
    },
  ] = useGetAllunEnteredProductsMutation();

  const [
    deleteunEnteredProducts,
    {
      data: deleteunEnteredProductsData,
      isLoading: deleteunEnteredProductsLoading,
      isError: deleteunEnteredProductsIsError,
      error: deleteunEnteredProductsError,
    },
  ] = useDeleteAllunEnteredProductsMutation();

  const deleteunEnteredProductsFun = (row) => {
    deleteunEnteredProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: row.id,
    });
  };
  useEffect(() => {
    if(deleteunEnteredProductsData)
    {
      toast.dismiss();
      toast.success("unEnteredProducts deleted Successfully", {
        id: "deleteLegalData",
      });
      getAll();
    }
    if(deleteunEnteredProductsError)
    {
      toast.dismiss();
      toast.error("Error In Deleting unEnteredProducts", {
        id: "deleteLegalData",
      });
      getAll();
    }
 
  }, [deleteunEnteredProductsData,deleteunEnteredProductsError]);

  const getAll = () => {
    getAllunEnteredProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    // if (

    //   request.product_code === "" &&
    //   request.name === "" &&
    //   request.brand_name === "" &&
    //   request.category_name === ""
    // ) {
      getAll();
      // fetchProductsForCsvFunction();
    // }
  }, [request]);

  const unEnteredProductsColumns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },

    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "400px",
    },
    {
        name: "Created At",
        cell: (row) => new Date(row.created_at).toLocaleString(),
        sortable: true,
      },
    {
      name: "Action",
      cell: (row) => (
        <div>
          
          <button
            onClick={() => deleteunEnteredProductsFun(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
          
        </div>
      ),
      minWidth: "130px",
    },
  ];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
         Product Not Exist List
          <LinksComponent />
        </div>
        <div className="p-3">
          <div className="p-3 w-full bg-white shadow rounded-lg">
            <DataTableComponent
              columns={unEnteredProductsColumns}
              data={getAllunEnteredProductsData?.body}
              // loading={loading}
              totalRows={getAllunEnteredProductsData?.body?.length}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
            ></DataTableComponent>
            {/* <div
              className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
              id="box_btn"
            >
              <div className="text-right">
                <Menu className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={() => navigate("add")}
                      className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                      style={{ backgroundColor: `${primaryColor["700"]}` }}
                    >
                      <HiOutlinePlus className="w-full h-full p-3" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div> */}
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default UnEnteredProducts;
