import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const AddNewProgramOption = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [optionType, setOptionType] = useState("");

  const [newOption, setNewOption] = useState("");

  useEffect(() => {
    const option = location.search && location.search.slice(1);
    if (option) {
      console.log(option);
      setOptionType(option);
    }
  }, [location]);

  const addOption = () => {
    
    if(newOption && newOption.length > 0){    
    const data =  {
        option_name: newOption
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/admin/${optionType}/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          toast.success(response.data.message, {
            id: "addOptionSuccess",
          });

          setTimeout(() => {
            navigate("/program")
          }, 500);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          error.response?.data?.message
            ? error.response.data.message
            : error.message,
          {
            id: "addOptionError",
          },
        );
      });
    }
    else {
        toast.error("Please enter a valid option type" , {
            id : "notValidOption",
        })
    }
  };

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="borderbtm">
              <h4 className="card-title mb-0">Add New {optionType} Option</h4>
              </div>
              <div className="cmxform mt-4" id="signupForm">
                <div className="frmds  row">
                  <div className="col-md-6">
                    <input
                      id="name"
                      className="form-control"
                      value={newOption}
                      onChange={(e) => {
                        setNewOption(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>
                <div className="d-flex gap-3 mt-4">
                    <button
                      type="submit"
                      className="btn btn-outline-primary btnall"
                      onClick={addOption}
                    >
                      Add Option
                    </button>

                    <button
                      type="cancel"
                      className="btn btn-outline-danger btnall"
                      onClick={() => navigate("/program")}
                    >
                      Cancel
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewProgramOption;
