import { rootApi } from "../../api/rootApi";

export const VerificationRecordsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllVerificationRecords: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant//verification-records/`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
    getAllVerificationRecordsStats: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant//verification-records/stats`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
  }),
});

export const { useGetAllVerificationRecordsMutation ,useGetAllVerificationRecordsStatsMutation} = VerificationRecordsApi;
