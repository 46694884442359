import { rootApi } from "../../api/rootApi";

export const wheelApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addWheel: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/wheel/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getAllWheel: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/wheel`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getWheelById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/wheel/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getWheelByCampaignId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/wheel/campaign/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateWheelById: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/wheel/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    deleteWheel: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/wheel/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getAllWheelHistory: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/wheelHistory`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getWheelsDetails: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/appWheel/getFromIds`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getWheelsHistoryByUserId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/wheelHistory/userType?user_type_id=${params.user_type_id}&limit=${params.limit}&offset=${params.offset}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useAddWheelMutation,
  useGetAllWheelMutation,
  useGetWheelByIdMutation,
  useGetWheelByCampaignIdMutation,
  useUpdateWheelByIdMutation,
  useDeleteWheelMutation,
  useGetAllWheelHistoryMutation,
  useGetWheelsDetailsMutation,
  useGetWheelsHistoryByUserIdMutation,
} = wheelApi;
