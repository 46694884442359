import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { hasPermission } from "../../../utils/hasPermission";
import {
  useDeleteCouponBrandMutation,
  useGetAllCouponBrandsMutation,
  useGetLatestCouponBrandsMutation,
  useGetTokenMutation,
  useUpdateCouponBrandValueMutation,
} from "../../../features/tenant/OzoneAPI/ozoneApi";
import { getVendorName } from "../../../utils/getVendorName";

const OzoneDynamicCouponList = () => {
  const navigate = useNavigate();

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);


  const [viewModal, setViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [brandProductCode, setBrandProductCode] = useState(null);

  const [couponValue, setCouponValue] = useState(null);

  const editBrandPermission = hasPermission("editCouponValue");
  // const deleteBrandPermission = hasPermission("deleteBrand");

  const [selectedBrandId, setSelectedBrandId] = useState(-1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [prevPage, setPrevPage] = useState(0);

  const [fetchCouponBrandList, { data: brandsData, error: brandsError }] =
    useGetAllCouponBrandsMutation();

  const [
    fetchLatestCouponBrandList,
    { data: latestBrandsData, error: latestBrandsError },
  ] = useGetLatestCouponBrandsMutation();

  const [getAuthToken, { data: authTokenData, error: authTokenError }] =
    useGetTokenMutation();

  useEffect(() => {
    toast.dismiss();
    if (authTokenData) {
      console.log("authTokenData", authTokenData);
      toast.success(authTokenData.message, {
        id: "authTokenSuccess",
      });
      fetchBrandListFunction();
    }

    if (authTokenError) {
      console.log("authTokenError", authTokenError);
      toast.error("Something went wrong", { id: "authTokenError" });
    }
  }, [authTokenData, authTokenError]);

  useEffect(() => {
    const hasViewPermission = hasPermission("editCouponValue");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchBrandListFunction("");
  }, []);

  const fetchBrandListFunction = (e) => {
    fetchCouponBrandList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  const fetchLatestBrandListFunction = (e) => {
    fetchLatestCouponBrandList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    if (latestBrandsData && latestBrandsData.body) {
      // console.log("latestBrandsData", latestBrandsData);
      toast.dismiss();
      toast.success("Coupons have been updated", {
        id: "latestCouponsData",
      });
      fetchBrandListFunction();
    }

    if (latestBrandsError) {
      console.log("latestBrandsError", latestBrandsError);
      toast.error("Something went wrong", {
        id: "latestBrandError",
      });
    }
  }, [latestBrandsData, latestBrandsError]);

  const [updateBrand, { data: updateBrandData, error: updateBrandError }] =
    useUpdateCouponBrandValueMutation();

  useEffect(() => {
    if (brandsData) {
      toast.dismiss();
      toast.success("Brand List Fetched", {
        id: "brandAddSucess",
      });

      setMyList(brandsData?.body ? brandsData?.body : []);
      setTotalRows(brandsData?.body ? brandsData?.body.length : 0);
    }
    if (brandsError && brandsError.data) {
      toast.dismiss();
      console.log("brandsError", brandsError);
      toast.error(`${brandsError.data?.message}`, {
        id: "brandAddError",
      });
    }
  }, [brandsData, brandsError]);

  const [deleteBrand, { data: deleteBrandData, error: deleteBrandError }] =
    useDeleteCouponBrandMutation();

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete")) {
      deleteBrand({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        brand_product_code: brandProductCode,
      });
    }
    fetchBrandListFunction("");
    return;
  };

  useEffect(() => {
    if (deleteBrandData) {
      toast.success("Coupon Brand Deleted Successfully", {
        id: "brandDeleteSucess",
      });
      fetchCouponBrandList({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      return;
    }

    if (deleteBrandError) {
      toast.error("Something went wrong", {
        id: "deleteBrandError",
      });
      return;
    }
  }, [deleteBrandData, deleteBrandError]);

  useEffect(() => {
    if (updateBrandData) {
      toast.success("Coupon Brand Updated", {
        id: "brandDeleteSucess",
      });
      fetchCouponBrandList({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
      setBrandProductCode(null);
      setCouponValue(null);
      setShowModal(!showModal);
      return;
    }

    if (updateBrandError) {
      toast.error("Something went wrong", {
        id: "updateBrandError",
      });
      // fetchCouponBrandList({
      //   token: sessionStorage.getItem("access_token"),
      //   tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      // });
      setBrandProductCode(null);
      setCouponValue(null);
      setShowModal(!showModal);
      return;
    }
  }, [updateBrandData, updateBrandError]);

  const handleUpdateBrand = (e) => {
    e.preventDefault();

    // console.log("coupon value ", couponValue);
    // console.log("brandProductCode", brandProductCode);

    // return;
    if (!brandProductCode) {
      toast.error("Invalid Coupon Brand", {
        id: "brandNameError",
      });
      return;
    }

    // if (!couponValue) {
    //   toast.error("Invalid Coupon Value", {
    //     id: "couponValueError",
    //   });
    //   return;
    // }

    updateBrand({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      brand_product_code: brandProductCode,
      body: {
        value: couponValue,
      },
    });
  };

  const handleShowModal = (data) => {
    console.log("data", data);
    setSelectedBrandId(Number(data.id));
    setBrandProductCode(data.brand_product_code);
    setShowModal(true);
    setCouponValue(data.value);
  };

  const handleShowViewModal = (data) => {
    console.log("view data", data);
    setViewModalData(data);
    setViewModal(true);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
    },
    {
      name: "Coupon Brand Name",
      cell: (row) => row.brand_name,
      sortable: true,
    },
    {
      name: "Coupon Brand Code",
      cell: (row) => row.brand_product_code,
      sortable: true,
    },
    // {
    //   name: "Value",
    //   cell: (row) => JSON.stringify(row.value),
    //   sortable: true,
    // },

    {
      name: "Image",
      cell: (row) =>
        row.brand_image && (
          <div className="w-12 h-12 my-2 rounded-full">
            <img
              // style={{ width: "40px", padding: "10px" }}
              src={row.brand_image}
              alt="gift-images"
              className="rounded-full"
              // onClick={() =>
              //   setShowImageModal(
              //      row.images[0],
              //   )
              // }
            />
          </div>
        ),
      maxWidth: "120px",
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleShowViewModal(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiFillEye className="mr-1" />
            View
          </button>
        </div>
      ),
      minWidth: "100px",
    },
    {
      name: "",
      cell: (row) =>
        editBrandPermission && (
          <div>
            <button
              onClick={() => handleShowModal(row)}
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              Edit
            </button>
          </div>
        ),
      minWidth: "100px",
    },
    // {
    //   name: "",
    //   cell: (row) =>
    //     deleteBrandPermission && (
    //       <div>
    //         <button
    //           onClick={() => handleDelete(row.id)}
    //           className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
    //         >
    //           <AiOutlineDelete className="mr-1" />
    //           Delete
    //         </button>
    //       </div>
    //     ),
    //   minWidth: "100px",
    // },
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handleInputChange = (index, value) => {
    const updatedCouponValue = couponValue.map((item, idx) => {
      if (idx === index) {
        return { ...item, value: parseInt(value) };
      }
      return item;
    });
    setCouponValue(updatedCouponValue);
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Coupon Brand List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div class="flex justify-between">
              <div className="flex gap-2">
                <button
                  className="btn btn-outline-primary clear-both float-right  mb-2"
                  onClick={() => {
                    getAuthToken({
                      token: sessionStorage.getItem("access_token"),
                      tenant_id: JSON.parse(
                        sessionStorage.getItem("vendorData"),
                      ).tenant_id,
                    });
                  }}
                >
                  Refresh Auth Token
                </button>

                <button
                  className="btn btn-outline-primary clear-both float-right  mb-2"
                  onClick={() => {
                    fetchLatestBrandListFunction();
                  }}
                >
                  Fetch Latest Brands
                </button>
              </div>
            </div>

            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                data={myList}
                totalRows={totalRows}
              ></DataTableComponent>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"md"}
            data={couponValue}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-3">
              <h3 className="text-xl w-full text-center font-semibold">
                Denomination List
              </h3>
              <div className="w-full flex flex-col items-center">
                {couponValue.map((item, index) => (
                  <div
                    key={index}
                    className="py-1 px-2 flex justify-center items-center space-x-5 rounded-md bg-gray-200 my-1.5 "
                  >
                    <div className="flex justify-center items-center">
                      <label className="block my-0 font-medium">
                        Denomination:
                      </label>
                      <p className="ml-2 text-gray-700">{item.denomination}</p>
                    </div>

                    <div className="flex  justify-center items-center">
                      <label className="block my-0 font-medium">Value:</label>
                      <input
                        type="number"
                        className="border ml-2 border-gray-300 rounded-md p-1.5 w-32 focus:outline-none focus:border-blue-500"
                        value={item.value}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex mt-4 justify-center">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={handleUpdateBrand}
                >
                  Update
                </button>
              </div>
            </div>
          </ModalComponent>
        )}

        {viewModal && (
          <ModalComponent
            size={"3xl"}
            data={"nadnandand"}
            show={viewModal}
            setShow={setViewModal}
          >
            <div className="w-full p-3">
              <div className="mx-auto p-3">
                <div className="mb-6">
                  <h2 className="text-2xl font-bold">
                    {viewModalData.brand_name}
                  </h2>
                  <img
                    className="mt-2 rounded-lg w-40 object-cover"
                    src={viewModalData.brand_image}
                    alt={viewModalData.brand_name}
                  />
                  <p className="mt-2 text-gray-700">
                    {viewModalData.descriptions}
                  </p>
                </div>
                <div className="mb-6">
                  <h3 className="text-xl font-bold">Brand Details</h3>
                  <ul className="list-disc ml-6">
                    <li>
                      Brand Product Code: {viewModalData.brand_product_code}
                    </li>
                    <li>Brand Type: {viewModalData.brand_type}</li>
                    <li>
                      Denominations
                      {viewModalData?.value?.map((item, index) => (
                        <div
                          key={index}
                          className="py-1 px-2 flex justify-start items-center space-x-5 rounded-sm bg-green-200 my-1.5 max-w-sm "
                        >
                          <div className="flex justify-center items-center">
                            <label className="block my-0 font-medium">
                              Denomination:
                            </label>
                            <p className="ml-2 text-gray-700">
                              {item.denomination}
                            </p>
                          </div>

                          <div className="flex  justify-center items-center">
                            <label className="block my-0 font-medium">
                              Value:
                            </label>
                            <p className="ml-2 text-gray-700">
                              {item.value}
                            </p>
                          </div>
                        </div>
                      ))}
                    </li>
                    <li>
                      Status:{" "}
                      {viewModalData.status === "1" ? "Active" : "Inactive"}
                    </li>
                    <li>
                      Created At:{" "}
                      {new Date(viewModalData.created_at).toLocaleString()}
                    </li>
                    <li>Redemption Type: {viewModalData.redemption_type}</li>
                    <li>
                      Online Redemption URL:{" "}
                      {viewModalData.online_redemption_url}
                    </li>
                    <li>
                      Denomination List: {viewModalData.denomination_list}
                    </li>
                    <li>
                      Stock Available:{" "}
                      {viewModalData.stock_available ? "Yes" : "No"}
                    </li>
                    <li>Category: {viewModalData.category}</li>
                  </ul>
                </div>
                <div className="mb-6 overflow-y-auto max-h-56">
                  <h3 className="text-xl font-bold">Terms & Conditions</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: viewModalData.tnc }}
                  ></p>
                </div>
                <div className="mb-6 ">
                  <h3 className="text-xl font-bold">Important Instructions</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: viewModalData.important_instruction,
                    }}
                  ></p>
                </div>
                <div className="mb-6">
                  <h3 className="text-xl font-bold">Redeem Steps</h3>
                  <ul className=" overflow-y-auto max-h-72">
                    {viewModalData.redeem_steps &&
                      Object.values(JSON.parse(viewModalData.redeem_steps)).map(
                        (step, index) => (
                          <li key={index} className="mb-4">
                            <p>{step.text}</p>
                            {step.image && (
                              <img
                                className="mt-2 rounded-lg w-full h-48 object-cover"
                                src={step.image}
                                alt={`Redeem Step ${index + 1}`}
                              />
                            )}
                          </li>
                        ),
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default OzoneDynamicCouponList;