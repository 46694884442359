import React, { useEffect, useState } from "react";

import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useGetSmsTemplateWithLimitOffsetForTenantMutation } from "../../../features/tenant/smsTemplate/smsTemplateApi";
import toast from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { downloadCSV } from "../../../utils/downloadCSV";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useSendMessageForOpenRedemptionWindowMutation, useSendMessageForServerMaintainanceMutation, useSendMessageForStaticSmsMutation, useSendMessageForTdsMutation } from "../../../features/tenant/serverCaintanceApi/serverCaintanceApi";


function parseCSV(string) {
    const csvHeader = string
        .slice(0, string.indexOf("\n"))
        .replace(/\s/g, "")
        .split(",");

    const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);

    console.log("csvRows: ", csvRows.length, csvRows);

    const array = csvRows
        .map((row) => {
            const values = [];
            let insideQuotes = false;
            let currentField = '';

            for (let char of row) {
                if (char === ',' && !insideQuotes) {
                    values.push(currentField.trim());
                    currentField = '';
                } else if (char === '"') {
                    insideQuotes = !insideQuotes;
                } else {
                    currentField += char;
                }
            }

            values.push(currentField.trim());
            return values;
        })
        .filter((values) => values.length === csvHeader.length)
        .map((values) => {
            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index].trim();
                return object;
            }, {});
            return obj;
        });

    return array;
}

function filterValidPhoneNumbers(phoneNumbers) {
    // Regular expression to match 10-digit phone numbers
    const phoneRegExp = /^\d{10}$/;

    // Filter the array to include only valid phone numbers
    // const validPhoneNumbers = phoneNumbers.filter((number) =>
    //     phoneRegExp.test(number),
    // );

    const validPhoneNumbers = [];

    phoneNumbers.forEach((number) => {
        if (phoneRegExp.test(number)) {
            validPhoneNumbers.push({ mobile: number });
        }
    })

    return validPhoneNumbers;
}

const validTypesArr = ['tax deduction', 'server under maintance', 'server maintance done', 'open redemption window', 'static sms'];
// const validTypesArr = ['tax deduction'];

const SendBulkMessages = () => {

    const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
        .color_shades;
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const [modalData, setmodalData] = useState();

    const handleOpenModal = (data) => {
        setShowModal(true);
        setmodalData(data);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setmodalData(null);
        setMobileNumbers('');
    };

    const [limit, setLimit] = useState(40);
    const [offset, setOffset] = useState(0);
    const [prevPage, setPrevPage] = useState(0);

    const [mobileNumbers, setMobileNumbers] = useState("");

    const [smsTemplatesArr, setSmsTemplatesArr] = useState([])

    const [
        getSmsTemplateWithLimitOffsetForTenant,
        {
            data: getSmsTemplateWithLimitOffsetForTenantData,
            isLoading: getSmsTemplateWithLimitOffsetForTenantIsLoading,
            isError: getSmsTemplateWithLimitOffsetForTenantIsError,
            error: getSmsTemplateWithLimitOffsetForTenantError,
        },
    ] = useGetSmsTemplateWithLimitOffsetForTenantMutation();

    const fetchSMSTemplates = (limit, offset) => {
        getSmsTemplateWithLimitOffsetForTenant({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            limit: limit,
            offset: offset,
        });
    };

    useEffect(() => {
        fetchSMSTemplates(limit, offset);
    }, [limit, offset])


    const [
        sendBulkSmsForTds,
        {
            data: sendBulkSmsForTdsData,
            isLoading: sendBulkSmsForTdsLoading,
            error: sendBulkSmsForTdsError,
        },
    ] = useSendMessageForTdsMutation();


    const [
        sendBulkSmsForServerMaintenance,
        {
            data: sendBulkSmsForServerMaintenanceData,
            isLoading: sendBulkSmsForServerMaintenanceLoading,
            error: sendBulkSmsForServerMaintenanceError,
        },
    ] = useSendMessageForServerMaintainanceMutation();


    const [
        sendOpenRedemptionWindow,
        {
            data: sendOpenRedemptionWindowData,
            isLoading: sendOpenRedemptionWindowLoading,
            error: sendOpenRedemptionWindowError,
        },
    ] = useSendMessageForOpenRedemptionWindowMutation();
    const [
        sendMessageForStaticSms,
        {
            data: sendMessageForStaticSmsData,
            isLoading: sendMessageForStaticSmsLoading,
            error: sendMessageForStaticSmsError,
        },
    ] = useSendMessageForStaticSmsMutation();

    const sendBulkSms = (validNumbers, type, sendToAll) => {

        const data = {
            "tenant_id": JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            "type": type,
            "number": validNumbers,
            "send_to_all": sendToAll
        }
        if (type == "tax deduction") {

            sendBulkSmsForTds({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: data
            })
        } else if (type == 'server under maintance') {

            sendBulkSmsForServerMaintenance({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: data
            })
        }
        else if (type == 'server maintance done') {

            sendBulkSmsForServerMaintenance({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: data
            })
        } else if (type == 'open redemption window') {

            sendOpenRedemptionWindow({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: data
            })
        } else if (type == 'static sms') {

            sendMessageForStaticSms({
                token: sessionStorage.getItem("access_token"),
                tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
                body: data
            })
        } else {
            toast.dismiss();
            toast.error("Wrong message type");
        }

    };



    useEffect(() => {
        if (sendBulkSmsForTdsData && sendBulkSmsForTdsData.body) {
            toast.dismiss();
            // console.log("Successfully sent sendBulkSmsForTdsData", sendBulkSmsForTdsData);
            toast.success("SMS Sent", {
                id: "sendBulkSmsForTdsData",
            })
        }

        if (sendBulkSmsForServerMaintenanceData && sendBulkSmsForServerMaintenanceData.body) {
            toast.dismiss();
            // console.log("Successfully sent sendBulkSmsForServerMaintenanceData", sendBulkSmsForServerMaintenanceData);
            toast.success("SMS Sent", {
                id: "sendBulkSmsForServerMaintenanceData",
            })
        }


        if (sendOpenRedemptionWindowData && sendOpenRedemptionWindowData.body) {
            toast.dismiss();
            // console.log("Successfully sent sendOpenRedemptionWindowData", sendOpenRedemptionWindowData);
            toast.success("SMS Sent", {
                id: "sendOpenRedemptionWindowData",
            })
        }


        if (sendMessageForStaticSmsData && sendMessageForStaticSmsData.body) {
            toast.dismiss();
            // console.log("Successfully sent sendMessageForStaticSmsData", sendMessageForStaticSmsData);
            toast.success("SMS Sent", {
                id: "sendMessageForStaticSmsData",
            })
        }

        if (sendBulkSmsForTdsError) {
            toast.dismiss();
            // console.log("Something went wrong.");
            toast.error("Something went wrong", {
                id: "sendNotificationError"
            })
        }

        if (sendBulkSmsForServerMaintenanceError) {
            toast.dismiss();
            // console.log("Something went wrong.");
            toast.error("Something went wrong", {
                id: "sendNotificationError"
            })
        }

        if (sendOpenRedemptionWindowError) {
            toast.dismiss();
            // console.log("Something went wrong.");
            toast.error("Something went wrong", {
                id: "sendNotificationError"
            })
        }

        if (sendMessageForStaticSmsError) {
            toast.dismiss();
            // console.log("Something went wrong.");
            toast.error("Something went wrong", {
                id: "sendNotificationError"
            })
        }

        if (sendBulkSmsForTdsLoading) {
            toast.dismiss();
            toast.loading("Please wait...");
        }

        if (sendBulkSmsForServerMaintenanceLoading) {
            toast.dismiss();
            toast.loading("Please wait...");
        }


        if (sendOpenRedemptionWindowLoading) {
            toast.dismiss();
            toast.loading("Please wait...");
        }


        if (sendMessageForStaticSmsLoading) {
            toast.dismiss();
            toast.loading("Please wait...");
        }


    }, [sendBulkSmsForTdsData, sendBulkSmsForServerMaintenanceData, sendOpenRedemptionWindowData, sendMessageForStaticSmsData, sendBulkSmsForTdsError, sendBulkSmsForServerMaintenanceError, sendOpenRedemptionWindowError, sendMessageForStaticSmsError, sendBulkSmsForTdsLoading, sendBulkSmsForServerMaintenanceLoading, sendOpenRedemptionWindowLoading, sendMessageForStaticSmsLoading])


    const columns = [
        {
            name: "SNo",
            cell: (row, idx) => offset + Number(idx + 1),
            sortable: true,
            width: "80px",
        },

        {
            name: "Template Id ",
            cell: (row) => {
                return row.template_id;
            },
            sortable: true,
        },
        {
            name: "Message Template",
            cell: (row) => row.template_text,
            sortable: true,
        },
        {
            name: "type",
            cell: (row) => row.type,
            sortable: true,
        },
        {
            name: "sender Id",
            cell: (row) => row.sender_id,
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <div>
                    <button
                        onClick={() => handleOpenModal(row)}
                        className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
                    >
                        Send
                    </button>
                </div>
            ),
            sortable: true,
            width: "100px",
        },
    ];

    useEffect(() => {
        if (getSmsTemplateWithLimitOffsetForTenantData &&
            getSmsTemplateWithLimitOffsetForTenantData.body.data) {
            const arr = [];

            getSmsTemplateWithLimitOffsetForTenantData.body.data.forEach((each) => {
                if (validTypesArr.includes(each.type)) {
                    arr.push(each);
                }
            })

            setSmsTemplatesArr(arr);
        }
    }, [getSmsTemplateWithLimitOffsetForTenantData])


    const handlePageChange = (pageNumber) => {
        const newOffset = (pageNumber - 1) * limit;
        setOffset(newOffset);
        setPrevPage(pageNumber - 1);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setLimit(newPerPage);
    };

    const handleSendBulkMessages = (row) => {
       
        const valid = filterValidPhoneNumbers(mobileNumbers.split(/[ ,]+/));
        
        handleCloseModal()

        if (valid.length == 0) {
            toast(
                (t) => (
                    <span className="">
                        <h6>No mobile numbers , Are you sure you want to send notification to all devices ?</h6>
                        <div className="mt-3 d-flex">
                            <button
                                className="btn btn-outline-danger btn-sm ml-auto mr-1"
                                onClick={() => {
                                    // sendPushNotofications({
                                    //     notification_type: row.notification_type,
                                    //     mobile: valid,
                                    //     title: row.title,
                                    //     body: row.body,
                                    //     subtitle: row.subtitle,
                                    //     platform: "4",
                                    //     platform_id: "PANEL",
                                    //     sendToAll: true,
                                    // });
                                    sendBulkSms(valid, row.type, true);
                                }}
                            >
                                Send to all
                            </button>
                            <button
                                className="btn btn-outline-primary btn-sm mr-auto ml-1"
                                onClick={() => {
                                    toast.dismiss(t.id);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </span>
                ),
                {
                    id: "sendToAllMobiles",
                },
            );

        } else {
            toast(
                (t) => (
                    <span>
                        <h6 className="capitalize">{valid.length} valid mobile number</h6>
                        <div className="mt-3 d-flex">
                            <button
                                className="btn btn-outline-danger btn-sm ml-auto mr-1"
                                onClick={() => {
                                    // sendPushNotofications({
                                    //     notification_type: row.notification_type,
                                    //     mobile: valid,
                                    //     title: row.title,
                                    //     body: row.body,
                                    //     subtitle: row.subtitle,
                                    //     platform: "4",
                                    //     platform_id: "PANEL",
                                    //     sendToAll: false,
                                    // });
                                    sendBulkSms(valid, row.type, false);
                                }}
                            >
                                Send
                            </button>
                            <button
                                className="btn btn-outline-primary btn-sm mr-auto ml-1"
                                onClick={() => {
                                    toast.dismiss(t.id);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </span>
                ),
                {
                    id: "sendToMobiles",
                },
            );
        }

    };


    useEffect(() => {
        if (sendBulkSmsForTdsData) {
            toast.dismiss();
            if (sendBulkSmsForTdsData.body) {
                toast.success(sendBulkSmsForTdsData.message, {
                    id: "sendNotificationsSuccess",
                })
            } else {
                toast(sendBulkSmsForTdsData.message, {
                    id: "sendNotificationsSuccess",
                })
            }
        }

        if (sendBulkSmsForTdsError) {
            toast.dismiss();

            console.log("Something went wrong.");
            toast.error("Something went wrong", {
                id: "sendNotificationError"
            })
        }

        if (sendBulkSmsForTdsLoading) {
            toast.dismiss();
            toast.loading("Please wait...");
        }
    }, [sendBulkSmsForTdsData, sendBulkSmsForTdsError, sendBulkSmsForTdsLoading])


    useEffect(() => {
        if (sendBulkSmsForServerMaintenanceData) {
            toast.dismiss();
            // console.log("Successfully sent sms", sendBulkSmsForServerMaintenanceData);
            if (sendBulkSmsForServerMaintenanceData.body) {
                toast.success(sendBulkSmsForServerMaintenanceData.message, {
                    id: "sendNotificationsSuccess",
                })
            } else {

                toast(sendBulkSmsForServerMaintenanceData.message, {
                    id: "sendNotificationsSuccess",
                })
            }
        }

        if (sendBulkSmsForServerMaintenanceError) {
            toast.dismiss();

            console.log("Something went wrong.");
            toast.error("Something went wrong", {
                id: "sendNotificationError"
            })
        }

        if (sendBulkSmsForServerMaintenanceLoading) {
            toast.dismiss();
            toast.loading("Please wait...");
        }
    }, [sendBulkSmsForServerMaintenanceData, sendBulkSmsForServerMaintenanceError, sendBulkSmsForServerMaintenanceLoading])

    const [mobileCsv, setMobileCsv] = useState(null);

    const handleUploadUserMobileCsv = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!e.target.files[0]) {
            toast.error("Please select a file");
            return;
        }
        const fileReader = new FileReader();
        if (e.target.files[0]) {
            setMobileCsv(e.target.files[0]);
            fileReader.onload = function (event) {
                const text = event.target.result;
                let datas = parseCSV(text);

                console.log("datas", datas);

                const mobiles = datas.map((each) => each.mobile);
                // setMobileNumbers(datas);
                setMobileNumbers(mobiles.join(','));
            };
            fileReader.readAsText(e.target.files[0]);
        }
    };


    return (
        <div className="flex w-full bg-gray-200 h-full justify-center">
            <div className="w-full ">
                <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
                    Send Bulk Messages
                    <LinksComponent />
                </div>
                <div className="p-3">
                    <div className="p-3 w-full bg-white shadow rounded-lg">
                        <DataTableComponent
                            columns={columns}
                            data={smsTemplatesArr}
                            // loading={productsIsLoading}
                            totalRows={smsTemplatesArr.length}
                            handlePerRowsChange={handlePerRowsChange}
                            handlePageChange={handlePageChange}
                        ></DataTableComponent>
                    </div>
                </div>
            </div>
            {showModal && (
                <ModalComponent
                    size={"md"}
                    data={"nadnandand"}
                    show={showModal}
                    setShow={setShowModal}
                >
                    <div className="w-full">
                        <div className="mx-auto max-w-2xl px-2 py-2 lg:max-w-7xl lg:px-8">
                            <div className="w-full">
                                <div className="flex justify-center mb-1">
                                    <button
                                        type="submit"
                                        className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm px-3 py-1.5 text-center "
                                        onClick={() =>
                                            downloadCSV(sampleData, 'mobileSample.csv')
                                        }
                                    >
                                        Sample Csv Download
                                    </button>
                                </div>
                                <div className="flex items-center justify-center w-full">
                                    <label
                                        for="dropzone-file"
                                        className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                                    >
                                        <div className="flex flex-col items-center justify-center ">
                                            <svg
                                                aria-hidden="true"
                                                className="w-10 h-10 mb-3 text-gray-400 mt-2"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    strokeLinejoin="round"
                                                    stroke-width="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                ></path>
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500 text-center">
                                                {mobileCsv ? (
                                                    mobileCsv.name
                                                ) : (
                                                    <>
                                                        <span className="font-semibold">
                                                            Choose CSV file
                                                        </span>
                                                        <div>Click to upload or drag and drop</div>
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            className="hidden"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={handleUploadUserMobileCsv}
                                        />
                                    </label>
                                </div>

                                <div className="p-2 mt-3 ">
                                    <div className="w-full min-h-max">
                                        <InputComponent
                                            name={"Enter Mobile Numbers"}
                                            type={"textarea"}
                                            value={mobileNumbers}
                                            setValue={setMobileNumbers}
                                            required={true}
                                            placeholder={"Enter Mobile Numbers"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center mt-2">
                                <button
                                    type="submit"
                                    onClick={(e) => handleSendBulkMessages(modalData)}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                                >
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
            )}
        </div>
    );
};

export default SendBulkMessages;


const sampleData = [
    { mobile: "9878987890" },
    { mobile: "8789987890" },
    { mobile: "9999997890" },
]