import { rootApi } from "../../api/rootApi";

export const appUsersBankApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankDetailByAppUserId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/bankDetail/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getAllBankDetail: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/bankDetail/`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useGetBankDetailByAppUserIdMutation,
  useGetAllBankDetailMutation,
} = appUsersBankApi;
