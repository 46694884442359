import { rootApi } from "../../api/rootApi";

export const faqsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllfaqs: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/faqs`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchfaqsById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/faqs/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addfaqs: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/faqs/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteFaqs: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/faqs/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});
export const {
  useAddfaqsMutation,
  useDeleteFaqsMutation,
  useFetchAllfaqsMutation,
  useFetchfaqsByIdMutation,
} = faqsApi;
