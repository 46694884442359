import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import MultiSelectComponent from "../../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import { useFetchSubcategoriesMutation } from "../../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { toast } from "react-hot-toast";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import { getVendorName } from "../../../../utils/getVendorName";
import { hasPermission } from "../../../../utils/hasPermission";
import { useNavigate } from "react-router-dom";
import { useFetchAllCouponProviderMutation } from "../../../../features/tenant/couponProvider/couponProviderApi";

const AddDynamicCoupon = () => {
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [selectedBrandName, setSelectedBrandName] = useState({
    id: -1,
    name: "Select Provider",
  });

  const [userId, setUserId] = useState(null);
  const [apiLink, setApiLink] = useState(null);
  const [xAuthKey, setXAuthKey] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (type, data) => {
    setShowModal(true);
  };

  const [
    fetchCouponProviders,
    {
      data: couponProviderList,
      isLoading: couponProviderIsLoading,
      error: couponProviderError,
    },
  ] = useFetchAllCouponProviderMutation();

  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  const navigate = useNavigate();

  useEffect(() => {
    const hasViewPermission = hasPermission("addDynamicCoupon");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
    fetchCouponProviders({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      type: "dynamic",
    });
  }, []);

  useEffect(() => {
    if (subCategoriesData) {
      console.log("Sub Categories", subCategoriesData);
      // setSelectedProductCategory(subCategoriesData.body);
    }
  }, [subCategoriesData, subCategoriesError]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    
    const categoryIds = selectedCategory.map((category) => category.id);
    console.log(categoryIds, selectedBrandName);

    if (categoryIds.length <= 0) {
      toast.error("Please select category", { id: "selectCategoryError" });
      return;
    }
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Add Dynamic Coupon Provider
            <LinksComponent />
          </div>

          <div className="w-full ">
            <div className="">
              <div className="mx-9 mt-4">
                <div className="bg-gray-300 m-auto border-opacity-40 rounded-lg flex w-full p-4 px-5 justify-center">
                  <div className="flex flex-col justify-center align-middle w-full ">
                    <div className="mb-6 max-w-md">
                      <SelectComponent
                        title={"Select Provider"}
                        selected={selectedBrandName}
                        setSelected={setSelectedBrandName}
                        dataArr={
                          couponProviderList ? couponProviderList.body : []
                        }
                        placeholder="Select Provider"
                      />
                    </div>
                    <div className="mb-6 max-w-md">
                      <InputComponent
                        name={"Third Party User ID"}
                        type={"text"}
                        value={userId}
                        setValue={setUserId}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 max-w-md">
                      <InputComponent
                        name={"Api Link"}
                        type={"textarea"}
                        value={apiLink}
                        setValue={setApiLink}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 max-w-md">
                      <InputComponent
                        name={"X-Auth-Key"}
                        type={"textarea"}
                        value={xAuthKey}
                        setValue={setXAuthKey}
                        required={true}
                      />
                    </div>

                    <div className="flex mt-2">
                      <button
                        onClick={(e) => handleOnSubmit(e)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  // onClick={(e) => handleAddBrand(e)}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default AddDynamicCoupon;
