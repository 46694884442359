import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useNavigate } from "react-router-dom";
import { useFetchLeaderBoardMutation } from "../../../features/tenant/leaderBoard/leaderBoardApi";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { AiOutlineSearch } from "react-icons/ai";
import { getStartDateEndDate } from "../../../utils/getStartDateEndDate";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { toast } from "react-hot-toast";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";

const yearArr = [
  { id: 24, name: "2023" },
  { id: 25, name: "2024" },
  { id: 26, name: "2025" },
  { id: 27, name: "2026" },
  { id: 28, name: "2027" },
  { id: 29, name: "2028" },
  { id: 30, name: "2029" },
  { id: 31, name: "2030" },
  { id: 32, name: "2031" },
  { id: 33, name: "2032" },
  { id: 34, name: "2033" },
  { id: 35, name: "2034" },
  { id: 36, name: "2035" },
  { id: 37, name: "2036" },
  { id: 38, name: "2037" },
  { id: 39, name: "2038" },
  { id: 40, name: "2039" },
  { id: 41, name: "2040" },
  { id: 42, name: "2041" },
  { id: 43, name: "2042" },
  { id: 44, name: "2043" },
  { id: 45, name: "2044" },
  { id: 46, name: "2045" },
  { id: 47, name: "2046" },
  { id: 48, name: "2047" },
  { id: 49, name: "2048" },
  { id: 50, name: "2049" },
  { id: 51, name: "2050" },
  { id: 52, name: "2051" },
  { id: 53, name: "2052" },
  { id: 54, name: "2053" },
  { id: 55, name: "2054" },
  { id: 56, name: "2055" },
  { id: 57, name: "2056" },
  { id: 58, name: "2057" },
  { id: 59, name: "2058" },
  { id: 60, name: "2059" },
  { id: 61, name: "2060" },
  { id: 62, name: "2061" },
  { id: 63, name: "2062" },
  { id: 64, name: "2063" },
  { id: 65, name: "2064" },
  { id: 66, name: "2065" },
  { id: 67, name: "2066" },
  { id: 68, name: "2067" },
  { id: 69, name: "2068" },
  { id: 70, name: "2069" },
  { id: 71, name: "2070" },
  { id: 72, name: "2071" },
  { id: 73, name: "2072" },
  { id: 74, name: "2073" },
  { id: 75, name: "2074" },
  { id: 76, name: "2075" },
  { id: 77, name: "2076" },
  { id: 78, name: "2077" },
  { id: 79, name: "2078" },
  { id: 80, name: "2079" },
  { id: 81, name: "2080" },
  { id: 82, name: "2081" },
  { id: 83, name: "2082" },
  { id: 84, name: "2083" },
  { id: 85, name: "2084" },
  { id: 86, name: "2085" },
  { id: 87, name: "2086" },
  { id: 88, name: "2087" },
  { id: 89, name: "2088" },
  { id: 90, name: "2089" },
  { id: 91, name: "2090" },
  { id: 92, name: "2091" },
  { id: 93, name: "2092" },
  { id: 94, name: "2093" },
  { id: 95, name: "2094" },
  { id: 96, name: "2095" },
  { id: 97, name: "2096" },
  { id: 98, name: "2097" },
  { id: 99, name: "2098" },
  { id: 100, name: "2099" },
];
const offerTypeObj = [
  { id: 1, name: "scan", checked: true },
  { id: 2, name: "point", checked: false },
];
const LeaderBoard = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [offerType, setOfferType] = useState(offerTypeObj);

  const [type, setType] = useState("scan");
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [allUserTypes, setAllUserTypes] = useState([]);
  const [selectedAppUser, setSelectedAppUser] = useState();

  const [date, setDate] = useState();

  const [leaderBoardList, setleaderBoardList] = useState([]);
  const [fetchLeaderBoard, { data: leaderBoardData, error: leaderBoardError , isLoading : leaderBoardIsLoading }] =
    useFetchLeaderBoardMutation();


    const [fetchLeaderBoardCsv, { data: leaderBoardCsvData, error: leaderBoardCsvError }] =
    useFetchLeaderBoardMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("leaderboard");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    const appUsers = sessionStorage.getItem("appUserType")
      ? JSON.parse(sessionStorage.getItem("appUserType"))
      : [{ id: -1, name: "No User" }];
    setAllUserTypes(appUsers);

    handleSearchClick();
  }, [type, limit, offset]);

  const handleSearchClick = () => {
    if (!date) {
      toast.error("Select day ", { id: "dateError" });
      return;
    }

    const val = getStartDateEndDate(date);

    if (!val) {
      return;
    }

    if (!selectedAppUser || selectedAppUser.id == -1) {
      toast.error("Select user type ", { id: "selectedAppUser" });
      return;
    }

    fetchLeaderBoard({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
      type: type,
      startDate: val.startDate,
      endDate: val.endDate,
      userType: selectedAppUser?.user_type,
    });
  };

  //Handle chnage offer type (Single /Multiple)
  const handleChangeOfferType = (idx, name) => {
    const newData = [...offerType];

    for (let i = 0; i < newData.length; i++) {
      const element = newData[i];
      element.checked = false;
    }
    newData[idx].checked = true;
    // console.log(newData[idx]);
    setType(newData[idx].name);
    setOfferType(newData);
  };
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  useEffect(() => {
    toast.dismiss();
    if (leaderBoardData && leaderBoardData.body) {
      console.log(leaderBoardData);
      if (leaderBoardData.body.data.length == 0) {
        toast("No records to display.", {
          id: "noRecordsToDisplay",
        });
      } else {
        toast.success("Data fetched successfully", {
          id: "leaderBoardDataSucess",
        });
      }
      setleaderBoardList(
        leaderBoardData.body.data ? leaderBoardData.body.data : []
      );
    }
  }, [leaderBoardData, leaderBoardError]);

  const handleSearchClickCsv = () => {
    if (!date) {
      toast.error("Select day ", { id: "dateError" });
      return;
    }

    const val = getStartDateEndDate(date);

    if (!val) {
      return;
    }

    if (!selectedAppUser || selectedAppUser.id == -1) {
      toast.error("Select user type ", { id: "selectedAppUser" });
      return;
    }

    fetchLeaderBoardCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: null,
      offset: null,
      type: type,
      startDate: val.startDate,
      endDate: val.endDate,
      userType: selectedAppUser?.user_type,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (leaderBoardCsvData && leaderBoardCsvData?.body?.data) {
      setCsvData(leaderBoardCsvData.body.data);
    }

    if (leaderBoardCsvError) {
      setCsvData(null);
    }
  }, [leaderBoardCsvData, leaderBoardCsvError])

  const scanTypeColumns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => <h2>{row.name}</h2>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },

    {
      name: "Scans",
      selector: (row) => row.total_scan,
      sortable: true,
    },
  ];

  const pointTypeColumns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => <h2>{row.name}</h2>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: " Point Earned",
      selector: (row) => row.points,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Leaderboard
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {leaderBoardData && leaderBoardData?.body?.data?.length > 0 && (
              <ExportCSV
                data={leaderBoardData && leaderBoardData?.body?.data}
                columnsToExport={[
                  "name",
                  "mobile",
                  "email",
                  "user_type",
                  "total_scan",
                  "points",
                ]}
              />
            )} */}

<div className="mb-2">
              <CustomExport fetchData={handleSearchClickCsv} data={csvData} defaultColumnExport={[
                 "name",
                 "mobile",
                 "email",
                 "user_type",
                 "total_scan",
                 "points",
              ]}
                filename="leaderboard"
              />
            </div>
            <div className="flex max-w-sm space-x-3 ">
              {offerType.map((each, idx) => (
                <div
                  key={each.id}
                  className={`p-2 rounded-t-lg w-full capitalize  text-base font-medium leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2  text-center " +
                                      ${
                                        each.checked
                                          ? "  text-white "
                                          : " text-black hover:bg-white/[0.12] hover:text-gray-800 "
                                      }`}
                  style={{
                    backgroundColor: `  ${
                      each.checked ? primaryColor["600"] : primaryColor["100"]
                    }`,
                  }}
                  onClick={() => handleChangeOfferType(idx, each.name)}
                >
                  {each.name}
                </div>
              ))}
            </div>

            <div className="w-full bg-white ">
              <div className="flex w-full space-x-3  bg-gray-100  px-3 py-3 ">
                <div className="text-left w-20">
                  <SelectComponentWithSearch
                    title={"User Type"}
                    selected={selectedAppUser}
                    setSelected={setSelectedAppUser}
                    dataArr={allUserTypes}
                    required={true}
                  />
                </div>
                <div className="text-left w-20">
                  <InputComponent
                    name={"Day"}
                    type={"date"}
                    value={date}
                    setValue={setDate}
                    required={true}
                  />
                </div>
                {/* <div className="text-center">
                  <InputComponent
                    name={"Week"}
                    type={"week"}
                    value={date}
                    setValue={setDate}
                    required={true}
                  />
                </div> */}
                <div className="text-left w-20">
                  <InputComponent
                    name={"Month"}
                    type={"month"}
                    value={date}
                    setValue={setDate}
                    required={true}
                  />
                </div>
                <div className="text-left w-20">
                  <SelectComponentWithSearch
                    title={"Year"}
                    selected={date}
                    setSelected={setDate}
                    dataArr={yearArr}
                  />
                </div>

                <div className="text-center mt-4 w-20">
                  <button
                    onClick={() => handleSearchClick()}
                    className="flex justify-center items-center rounded bg-primary p-2.5 w-full text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    <AiOutlineSearch className="mr-1" />
                    Search
                  </button>
                </div>
              </div>

              <div className=" w-full bg-white shadow  rounded-2xl">
                <DataTableComponent
                  columns={
                    offerType[0].checked ? scanTypeColumns : pointTypeColumns
                  }
                  // title={""}
                  data={leaderBoardList}
                  loading={loading}
                  totalRows={
                    leaderBoardList &&
                    leaderBoardList.body &&
                    leaderBoardList.body.total
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderBoard;
