import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useCreateReferAndEarnRewardsMutation, useEditReferAndEarnRewardsMutation, useGetAllReferAndEarnRewardsMutation } from "../../../features/tenant/ReferAndEarnRewards/referAndEarnRewards";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const RewardType = {
  body: [
    {
      id: 1,
      name: "points",
    },
    {
      id: 2,
      name: "cash",
    },
  ],
};


const AddReferralAndEarnRewards = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location ",location);
  const isUpdate = location.state ? true :false;

  const [selectedRewardType, setSelectedRewardType] = useState( isUpdate ? {
    id : location.state.reward_type,
    name : location.state.reward_type == 1 ? "points" : "cash"
  } :{
    id: -1,
    name: "Select Reward Type",
  });
  const [rewardValues, setRewardValues] = useState({
    [isUpdate &&location.state.reward_type == 1 ? "points" : "cash"] : isUpdate&&location.state.reward[location.state.reward_type == 1 ? "points" : "cash"]
  });

  console.log("rewardValues",rewardValues);

  const [
    createReferAndEarnRewards,
    {
      data: createReferAndEarnRewardsData,
      isLoading: createReferAndEarnRewardsIsLoading,
      isError: createReferAndEarnRewardsIsError,
      error: createReferAndEarnRewardsError,
    },
  ] = useCreateReferAndEarnRewardsMutation();

  const [
    editReferAndEarnRewards,
    {
      data: editReferAndEarnRewardsData,
      isLoading: editReferAndEarnRewardsIsLoading,
      isError: editReferAndEarnRewardsIsError,
      error: editReferAndEarnRewardsError,
    },
  ] = useEditReferAndEarnRewardsMutation();
  const [
    getAllReferAndEarnRewards,
    {
      data: getAllReferAndEarnRewardsData,
      isLoading: getAllReferAndEarnRewardsIsLoading,
      isError: getAllReferAndEarnRewardsIsError,
      error: getAllReferAndEarnRewardsError,
    },
  ] = useGetAllReferAndEarnRewardsMutation();


  const handleSubmit = (e)=>{
    e.preventDefault();
    console.log("rewardValues",rewardValues);
    createReferAndEarnRewards({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body : {
            reward_type : selectedRewardType.id.toString(),
            reward : rewardValues
        }
    })
  }

  
  useEffect(()=>{
    if(createReferAndEarnRewardsData && !createReferAndEarnRewardsIsError){
        toast.dismiss();
        toast.success(createReferAndEarnRewardsData.message,{
            id : createReferAndEarnRewardsData.message
        });
        getAllReferAndEarnRewards({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        })
        navigate(-1);
    }

    if(createReferAndEarnRewardsError && createReferAndEarnRewardsIsError){
        toast.dismiss();
        console.log("createReferAndEarnRewardsError",createReferAndEarnRewardsError);
        toast.error("Not Able Update The Status",{
            id : "notAbleUpdateTheStatus"
        });
    }

  },[createReferAndEarnRewardsData , createReferAndEarnRewardsError])
  
  
  const handleUpdate = (e)=>{
    e.preventDefault();
    console.log("rewardValues",rewardValues);
    console.log("select",selectedRewardType);
    editReferAndEarnRewards({
      token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body : {
            reward : rewardValues
        },
        id : location.state.id.toString(),
    })
  }
  
  useEffect(()=>{
    if(editReferAndEarnRewardsData && !editReferAndEarnRewardsIsError){
        toast.dismiss();
        toast.success(editReferAndEarnRewardsData.message,{
            id : editReferAndEarnRewardsData.message
        });
        getAllReferAndEarnRewards({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        })
        navigate(-1);
    }

    if(editReferAndEarnRewardsError && editReferAndEarnRewardsIsError){
        toast.dismiss();
        console.log("editReferAndEarnRewardsError",editReferAndEarnRewardsError);
        toast.error("Not Able Update THe Reward",{
            id : "notAbleeditThereward"
        });
    }

  },[editReferAndEarnRewardsData , editReferAndEarnRewardsError])


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update" : "Add"} Reward
            <LinksComponent />
          </div>
          <div className=" mx-9 mt-10">
            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-fit p-4 px-5 justify-center">
              <div className="w-96 flex flex-col justify-center align-middle  ">
                {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                <div className="mb-6">
                  <SelectComponent
                    title={"Select Rewards Type"}
                    selected={selectedRewardType}
                    setSelected={setSelectedRewardType}
                    dataArr={RewardType ? RewardType?.body : []}
                  />
                </div>

                <div className="mb-6 ">
                  <div className="w-full">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      {selectedRewardType.id == 1 ? "Enter Points" : selectedRewardType.id == -1 ? "Select Reward Type" : "Enter Cash"}
                    </label>
                    <input
                      type="number"
                      name={selectedRewardType.id == 1 ? "points" : "cash"}
                      value = {isUpdate ? rewardValues[ `${location.state.reward_type == 1 ? "points" : "cash"}` ] : null}
                      className="w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  "
                      onChange={(e) => {
                       setRewardValues({
                        [e.target.name] : e.target.value
                       })
                      }}
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  {isUpdate ? (
                    <button
                      type="update"
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddReferralAndEarnRewards;
