import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useGetAppUserTypeQuery,
  useUpdateAppUserTypeMutation,
} from "../../../features/tenant/appUserType/appUserTypeApi";
import { AiOutlineEdit } from "react-icons/ai";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  // Get day, month, and year components
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  // Create the formatted date string
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

const AppUserType = () => {
  const editAppUserTypePermission = hasPermission("editCashPerPoint");
  const {
    data: GetAppUserTypesData,
    isLoading: GetAppUserTypesIsLoading,
    isError: GetAppUserTypesIsError,
    error: GetAppUserTypesError,
    refetch,
  } = useGetAppUserTypeQuery({
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  const [
    UpdateAppUserType,
    {
      data: updateAppUserTypeData,
      isLoading: updateAppUserTypeIsLoading,
      isError: updateAppUserTypeIsError,
      error: updateAppUserTypeError,
    },
  ] = useUpdateAppUserTypeMutation();

  

  const [popUpBtnToggle, setPopUpBtnToggle] = useState(false);
  const [inputCashPerPoint, setInputCashPerPoint] = useState(null);
  const [inputMinCashRedeem, setInputMinCashRedeem] = useState(null);
  const [inputMinPointRedeem, setInputMinPointRedeem] = useState(null);
  const [currentAppUserType, setCurrentAppUserType] = useState(null);
  const [redeemStartDate, setRedeemStartDate] = useState(null);
  const [redeemEndDate, setRedeemEndDate] = useState(null);
  const [registrationBonus, setRegistrationBonus] = useState(null);
  const [maxTransactionPerDay, setMaxTransactionPerDay] = useState(null);
  const [maxAmountPerDay, setMaxAmountPerDay] = useState(null);
  const [maxAmountPerTransaction, setMaxAmountPerTransaction] = useState(null);
  const [cashback, setCashback] = useState(null);

  const handleUpdateAppUserType = (e) => {
    e.preventDefault();
    if (inputCashPerPoint == null || inputCashPerPoint == 0) {
      toast.dismiss();
      toast.error("fill the field ", {
        id: "fill field",
      });
      return;
    }
    if (inputMinCashRedeem == null || inputMinCashRedeem == 0) {
      toast.dismiss();
      toast.error("fill the field ", {
        id: "fill field",
      });
      return;
    }
    if (inputMinPointRedeem == null || inputMinPointRedeem == 0) {
      toast.dismiss();
      toast.error("fill the field ", {
        id: "fill field",
      });
      return;
    }
    if (redeemStartDate == null || redeemStartDate == 0) {
      toast.dismiss();
      toast.error("fill the field ", {
        id: "fill field",
      });
      return;
    }
    if (redeemEndDate == null || redeemEndDate == 0) {
      toast.dismiss();
      toast.error("fill the field ", {
        id: "fill field",
      });
      return;
    }

    if (
      redeemStartDate != null &&
      redeemEndDate != null &&
      new Date(redeemStartDate) > new Date(redeemEndDate)
    ) {
      toast.dismiss();
      toast.error("Start Date should smalller the End Date ", {
        id: "fill field",
      });
      return;
    }
    // if (registrationBonus != null && registrationBonus == 0) {
    //   toast.dismiss();
    //   toast.error("Please enter registraction Bonus ", {
    //     id: "fill field",
    //   });
    //   return;
    // }
    console.log("redeemStartDate",redeemStartDate);
    console.log("redeemEndDate",redeemEndDate);

    // return ;

    UpdateAppUserType({
      id: Number(currentAppUserType.id),
      token: sessionStorage.getItem("access_token"),
      body: {
        cash_per_point: Number(inputCashPerPoint),
        min_cash_redeem: Number(inputMinCashRedeem),
        min_point_redeem: Number(inputMinPointRedeem),
        redeem_end_date: redeemEndDate,
        redeem_start_date: redeemStartDate,
        registration_bonus: registrationBonus,
        max_amount_per_day: maxAmountPerDay,
        max_amount_per_transaction: maxAmountPerTransaction,
        max_transaction_per_day: maxTransactionPerDay,
        cashback: cashback,
      },
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    setPopUpBtnToggle(false);
  };

  useEffect(() => {
    if (updateAppUserTypeData && !updateAppUserTypeIsError) {
      toast.dismiss();
      toast.success(`${updateAppUserTypeData.message}`, {
        id: "updateAppUserTypeData",
      });

      refetch();
    }
  }, [updateAppUserTypeData]);

  if (updateAppUserTypeIsError) {
    toast.dismiss();
    toast.error(`${updateAppUserTypeError.message}`, {
      id: "updateAppUserTypeError",
    });
  }

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Cash Per Point",
      selector: (row) => (row.cash_per_point == null ? "0" : row.cash_per_point),
      sortable: true,
    },
    {
      name: "Cashback",
      selector: (row) => (row.cashback == null ? "0" : row.cashback),
      sortable: true,
    },
    {
      name: "Min Cash Redeem",
      selector: (row) => (row.min_cash_redeem == null ? "0" : row.min_cash_redeem),
      sortable: true,
    },
    {
      name: "Min Point Redeem",
      selector: (row) => (row.min_point_redeem == null ? "" : row.min_point_redeem),
      sortable: true,
    },
    {
      name: "Redeem Start Date",
      selector: (row) =>
        row.redeem_start_date == null
          ? ""
          : row.redeem_start_date.split('T')[0],
      sortable: true,
    },
    {
      name: "Redeem End Date",
      selector: (row) =>
        row.redeem_end_date == null ? "" : row.redeem_end_date.split('T')[0],
      sortable: true,
    },
    {
      name: "Registration Bonus",
      selector: (row) =>
        row.registration_bonus == null ? 0 : row.registration_bonus,
      sortable: true,
      width : "170px"
    },
    {
      name: "Max Amount/Day",
      selector: (row) =>
        row.max_amount_per_day == null ? 0 : row.max_amount_per_day,
      sortable: true,
      width : "170px"
    },
    {
      name: "Max Amount/Transaction",
      selector: (row) =>
        row.max_amount_per_transaction == null ? 0 : row.max_amount_per_transaction,
      sortable: true,
      width : "200px"
    },
    {
      name: "Max Transaction/Day",
      selector: (row) =>
        row.max_transaction_per_day == null ? 0 : row.max_transaction_per_day,
      sortable: true,
      width : "200px"
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        editAppUserTypePermission && (
          <div className="">
            <button
              onClick={() => {
                toggleModal(row)
              }}
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <AiOutlineEdit className="mr-1" />
              edit
            </button>
          </div>
        ),
      //   ),
      minWidth: "100px",
    },
  ];

  const toggleModal = (row) => {
    console.log("roww", row)
    setInputMinCashRedeem(
      row.min_cash_redeem == null ? 0 : Number(row.min_cash_redeem)
    );
    setInputMinPointRedeem(
      row.min_point_redeem == null
        ? 0
        : Number(row.min_point_redeem)
    );
    setInputCashPerPoint(
      row.cash_per_point == null ? 0 : Number(row.cash_per_point)
    );
    setRedeemEndDate(row.redeem_end_date ? row.redeem_end_date.split('T')[0] : null);
    setRedeemStartDate(row.redeem_start_date ? row.redeem_start_date.split('T')[0] : null);
    setRegistrationBonus(row.registration_bonus ? row.registration_bonus : 0);
    setMaxAmountPerDay(row.max_amount_per_day ? row.max_amount_per_day : 0);
    setMaxAmountPerTransaction(row.max_amount_per_transaction ? row.max_amount_per_transaction : 0);
    setMaxTransactionPerDay(row.max_transaction_per_day ? row.max_transaction_per_day : 0);
    setCashback(row.cashback ? row.cashback : 0);
    setRedeemStartDate( row.redeem_start_date ? row.redeem_start_date.split('T')[0] : null );
    setRedeemEndDate( row.redeem_end_date ? row.redeem_end_date.split('T')[0] : null );
    
    setPopUpBtnToggle(true);
    setCurrentAppUserType(row);
  }
  console.log("redeemStartDate2", redeemStartDate);
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Cash Per Point
            <LinksComponent />
          </div>
          <div className="p-3">
            {GetAppUserTypesData && GetAppUserTypesData?.body && (
              <ExportCSV
                data={GetAppUserTypesData && GetAppUserTypesData?.body}
                columnsToExport={[
                  "name",
                  "cash_per_point",
                  "min_cash_redeem",
                  "min_point_redeem",
                  "created_at",
                ]}
              />
            )}
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"App User Type List"}
                data={GetAppUserTypesData && GetAppUserTypesData?.body}
                //   loading={productsIsLoading}
                //   totalRows={productsData && productsData.body.total_products}
                //   handlePerRowsChange={handlePerRowsChange}
                //   handlePageChange={handlePageChange}
              ></DataTableComponent>
              <div
                className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                id="box_btn"
              >
                {/* <div className="text-right">
                  <Menu className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        // onClick={() => navigate(`add`)}
                        className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                      >
                        <HiOutlinePlus className="w-full h-full p-3" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div> */}
              </div>
              {/* )} */}
              {popUpBtnToggle && (
                <ModalComponent
                  size={"2xl"}
                  show={popUpBtnToggle}
                  setShow={setPopUpBtnToggle}
                >
                  <div className="w-full p-4">
                    <div className="grid grid-cols-3 gap-2">
                      <div className="w-full flex justify-center mb-3">
                        <InputComponent
                          name={"Cash Per Point"}
                          type={"number"}
                          value={inputCashPerPoint}
                          setValue={setInputCashPerPoint}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center mb-3">
                        <InputComponent
                          name={"Minimum Cash Redeem"}
                          type={"number"}
                          value={inputMinCashRedeem}
                          setValue={setInputMinCashRedeem}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Minimum Points Redeem"}
                          type={"number"}
                          value={inputMinPointRedeem}
                          setValue={setInputMinPointRedeem}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-3 gap-2">
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Redeem Start Date"}
                          type={"date"}
                          value={redeemStartDate}
                          setValue={setRedeemStartDate}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Redeem End Date"}
                          type={"date"}
                          value={redeemEndDate}
                          setValue={setRedeemEndDate}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Registration Bonus"}
                          type={"number"}
                          value={registrationBonus}
                          setValue={setRegistrationBonus}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Max Amount Per Day"}
                          type={"number"}
                          value={maxAmountPerDay}
                          setValue={setMaxAmountPerDay}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Max Amount Per Transaction"}
                          type={"number"}
                          value={maxAmountPerTransaction}
                          setValue={setMaxAmountPerTransaction}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Max Transaction Per Day"}
                          type={"number"}
                          value={maxTransactionPerDay}
                          setValue={setMaxTransactionPerDay}
                          required={true}
                        />
                      </div>
                      <div className="w-full flex justify-center ">
                        <InputComponent
                          name={"Cashback"}
                          type={"number"}
                          value={cashback}
                          setValue={setCashback}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="w-full flex justify-center mt-4 ">
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={handleUpdateAppUserType}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppUserType;
