import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllUserRolesSupplyBeamMutation,
  useUpdateUserRolesSupplyBeamMutation,
} from "../../../../features/supplyBeam/userRoleSB/userRoleSB";
import toast from "react-hot-toast";
import { useGetAllSupplyBeamUserActionsMutation } from "../../../../features/supplyBeam/usersActions/usersActions";

const EditUserRolesPermissionSupplyBeam = () => {
  const [options, setOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const data = location.state;
    console.log("data", data);
    setSelectedRole({
      name: data.name,
      id: data.id,
      permission: data.permission,
    });
    if (data.permission) {
      setSelectedOptions([ ...data.permission]);
    }
  }, [location.state]);

  useEffect(() => {
    console.log("selectedRole useEffect",selectedRole);
    if (selectedRole) {
        if(selectedRole.permission){

            setSelectedOptions([ ...selectedRole.permission]);
        }else{
            setSelectedOptions([ ]);

        }
    }
  }, [selectedRole]);

  const [
    getAllSupplyBeamUserActions,
    {
      data: getAllSupplyBeamUserActionsData,
      isLoading: getAllSupplyBeamUserActionsIsLoading,
      isError: getAllSupplyBeamUserActionsIsError,
      error: getAllSupplyBeamUserActionsError,
    },
  ] = useGetAllSupplyBeamUserActionsMutation();

  const [
    getAllRoles,
    {
      data: getAllRolesData,
      isLoading: getAllRolesIsLoading,
      isError: getAllRolesIsError,
      error: getAllRolesError,
    },
  ] = useGetAllUserRolesSupplyBeamMutation();
  const [
    updateUserRoleById,
    {
      data: updateUserRoleByIdData,
      isLoading: updateUserRoleByIdIsLoading,
      isError: updateUserRoleByIdIsError,
      error: updateUserRoleByIdError,
    },
  ] = useUpdateUserRolesSupplyBeamMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUserRoleById({
      role_id: selectedRole.id,
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: {
        name: selectedRole.name,
        permission: selectedOptions,
      },
    });
  };

  const handleOptionChange = (option , curValue) => {
    console.log("curvalue",curValue);
    let oldOption = [...selectedOptions]
    if( !curValue){
        // false , remove item
        oldOption = oldOption.filter(item => item !== option.action);
    }else{
        // true , add item
        oldOption.push(option.action);
    }
    setSelectedOptions([...oldOption ]);
  };
  
  useEffect(() => {
    if (updateUserRoleByIdData) {
      toast.success("Permissions Updated Successfully !!");
      navigate(-1);
    }
    if (updateUserRoleByIdError) {
      toast.error("Error in Creating User");
    }
  }, [updateUserRoleByIdData, updateUserRoleByIdError]);

  useEffect(() => {
    getAllRoles({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    getAllSupplyBeamUserActions();
  }, []);

  useEffect(() => {
    if (getAllRolesData?.body) {
      setRoles(getAllRolesData?.body);
    }
  }, [getAllRolesData]);

  useEffect(() => {
    if (getAllSupplyBeamUserActionsData) {
      console.log(
        "getAllSupplyBeamUserActionsData",
        getAllSupplyBeamUserActionsData
      );
      setOptions([...getAllSupplyBeamUserActionsData.body]);
    }
    if (getAllSupplyBeamUserActionsError) {
      console.log("object");
    }
  }, [getAllSupplyBeamUserActionsData, getAllSupplyBeamUserActionsError]);

  console.log("-->", options);
  console.log(selectedRole);
console.log("selectedOptions",selectedOptions);
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {selectedRole?.name}
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className=" ">
              <div className="m-auto p-auto w-full bg-gray-100 p-3">
                <SelectComponent
                  title={"Select Role*"}
                  selected={selectedRole}
                  setSelected={setSelectedRole}
                  dataArr={roles}
                />
              </div>
              <div className="w-full m-auto flex overflow-auto gap-2 p-3">
                {options.length &&
                  options.map((option) => (
                    <div
                      key={option.id}
                      className="bg-white shadow rounded-lg p-3 flex-col "
                    >
                      <label className="flex bg-gray-100 p-2 rounded-md">
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option.action)}
                          onChange={(e) => handleOptionChange(option , e.target.checked)}
                          className="mr-2"
                        />
                        <h4 className="capitalize">{option.label}</h4>
                      </label>
                      <div className=" text-black ">{option.action}</div>
                    </div>
                  ))}
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUserRolesPermissionSupplyBeam;
