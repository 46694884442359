import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { toast } from "react-hot-toast";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useCountAllOtpsMutation, useFetchAllOtpsMutation } from "../../../features/tenant/appOtps/appOtpsApi";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";

const AppOtps = () => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    name: "",
    mobile: "",
    limit: 10,
    offset: 0,
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
   
  };


  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (request.name === "" && request.mobile === "") {
      fetchOtpsFunction();
       countOtpsFunction();
    }
  }, [request]);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const [
    fetchOtps,
    { data: otpsData, error: otpsError, isLoading: otpsIsLoading },
  ] = useFetchAllOtpsMutation();

  const [
    countOtps,
    { data: countotpsData, error: countotpsError, isLoading: countotpsIsLoading },
  ] = useCountAllOtpsMutation();


  // useCountAllOtpsMutation

  useEffect(() => {
    if(otpsData){
      setMyList( otpsData?.body?.otp  ? otpsData.body.otp : []);
    }
  }, [otpsData])

  useEffect(() => {
    if (countotpsData) {
      setTotalRows( countotpsData && countotpsData.body && countotpsData.body.total ? countotpsData.body.total : 0  );
    }
  }, [countotpsData, countotpsError]);


  

  const [
    fetchOtpsCsv,
    { data: otpsCsvData, error: otpsCsvError, isLoading: otpsCsvIsLoading },
  ] = useFetchAllOtpsMutation();

  useEffect(() => {
    // const hasViewPermission = hasPermission("brandList");
    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    fetchOtpsFunction();
    countOtpsFunction()
    // fetchOtpsCsvFunction();
  }, [limit, offset]);

  const fetchOtpsFunction = () => {
    fetchOtps({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const countOtpsFunction = () => {
    countOtps({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchOtpsCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchOtpsCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if(otpsCsvData && otpsCsvData?.body?.otp){
      setCsvData(otpsCsvData?.body?.otp);
    }

    if (otpsCsvError) {
      setCsvData(null);
    }
  }, [otpsCsvData , otpsCsvError])

  const columns = [
    {
      name: "S.No",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Otp Type",
      cell: (row) => row.otp_type,
      sortable: true,
    },
    {
      name: "OTP",
      cell: (row) => row.otp,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.otp_created_at).toLocaleString(),
      sortable: true,
    },
  ];

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            All Users OTP
            <LinksComponent />
          </div>

          <div className="p-3">
          {/* {otpsCsvData && otpsCsvData?.body?.otp && (
              <ExportCSV
                data={otpsCsvData && otpsCsvData?.body?.otp}
                columnsToExport={[
                  "name",
                  "mobile",
                  "otp",
                  "otp_type",
                  "otp_created_at",
                ]}
              />
            )} */}

<div className="mb-2">
                  <CustomExport fetchData={fetchOtpsCsvFunction} data={csvData} defaultColumnExport={[
                      "name",
                      "mobile",
                      "otp",
                      "otp_type",
                      "otp_created_at",
                  ]} 
                    filename="otp"
                    />
                </div>

            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 justify-end">
                <div className="w-52 h-10">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Name..."
                      required
                      value={request.name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52 h-10">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile No..."
                      required
                      value={request.mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, mobile: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                <div className="flex flex-row  gap-2 h-10">
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        fetchOtpsFunction();
                        countOtpsFunction();
                        // fetchOtpsCsvFunction();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div className="relative searchin">
                  <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                data={myList}
                loading={otpsIsLoading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                striped
                highlightOnHover
                pointerOnHover
                pagination
              ></DataTableComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppOtps;
