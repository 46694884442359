import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    company : JSON.parse(sessionStorage.getItem("companyProfile"))
    // company : "sakjfghajksfgaksjfgkasgfkasgfkasgfkjagsf"
}

export const companyProfileSlice = createSlice({
    name: 'companyProfile',
    initialState,
    reducers: {
        setCompanyProfile: (state, action) => {
            state.company = action.payload;
        },
        clearData : (state, action) => {
            state.company = null;
        },
    },
})

export const { setCompanyProfile , clearData} = companyProfileSlice.actions

export const selectCompany = (state) => state.companyProfle?.company;
export const selectUserTypes = (state) => state.companyProfle?.company?.user_types;


export default companyProfileSlice.reducer