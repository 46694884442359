import { rootApi } from "../../api/rootApi";

export const ozoneApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    importQrs: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/tenant/oopl/add",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),

    getToken: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/tenant/oopl/token",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
        };
      },
    }),

    getTokenAndCoupons: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/tenant/oopl/token-coupons",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
        };
      },
    }),

    getAllCouponBrands: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/tenant/oopl/dynamic-coupons",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
        };
      },
    }),

    getLatestCouponBrands: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/tenant/oopl/coupon-brands",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
        };
      },
    }),

    updateCouponBrandValue: builder.mutation({
      query: (params) => {
        console.log("params: " ,params)
        return {
          method: "PUT",
          url: `/tenant/oopl/dynamic-coupons/${params.brand_product_code}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),

    deleteCouponBrand: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/oopl/dynamic-coupons/${params.brand_product_code}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
        };
      },
    }),

    getAllCouponsRequests: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/oopl/all-requests`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body : params.body
        };
      },
    }),

    acceptCouponsRequests: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/oopl/accept/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),

    rejectCouponsRequests: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/oopl/reject/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),


    checkCouponStatus: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/oopl/check-status/${params.order_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),


    updateCouponStatus: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/oopl/update-status/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),


    getAllunEnteredProducts: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/oopl/deletedProduct`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),

    deleteAllunEnteredProducts: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/oopl/deletedProduct/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useImportQrsMutation,
  useGetTokenMutation,
  useGetTokenAndCouponsMutation,
  useGetAllCouponBrandsMutation,
  useUpdateCouponBrandValueMutation,
  useDeleteCouponBrandMutation,
  useGetAllCouponsRequestsMutation,
  useAcceptCouponsRequestsMutation,
  useRejectCouponsRequestsMutation,
  useCheckCouponStatusMutation,
  useUpdateCouponStatusMutation,
  useGetAllunEnteredProductsMutation,
  useDeleteAllunEnteredProductsMutation,
  useGetLatestCouponBrandsMutation,
} = ozoneApi;
