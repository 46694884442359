import { rootApi } from "../../api/rootApi";

export const redemtionHistory = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchRedemptionHistory: builder.mutation({
      query: (params) => {
        console.log("zoneId-->", params.zoneId);
        return {
          method: "POST",
          url: `/app/userPointRedemptions/?limit=${params.limit}&offset=${params.offset}&zoneId=${params.zoneId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const { useFetchRedemptionHistoryMutation } = redemtionHistory;
