import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import {
  useFetchCompanyProfileMutation,
  useUpdateCompanyProfileMutation,
} from "../../../features/tenant/companyProfile/companyProfileApi";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setCompanyProfile } from "../../../features/tenant/companyProfile/companyProfileSlice";
import { useNavigate } from "react-router-dom";
const EditProfile = () => {
  const [email, setEmail] = useState(null);
  const [website, setWebsite] = useState(null);
  const [contactPersonName, setContactPersonName] = useState(null);
  const [contactPersonNumber, setcontactPersonNumber] = useState(null);

  const [facebook, setFacebook] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [instagram, setInstagram] = useState(null);
  const [youtube, setYoutube] = useState(null);
  const [customerSupportEmail, setCustomerSupportEmail] = useState(null);
  const [customerSupportMobile, setCustomerSupportMobile] = useState(null);
  const navigate = useNavigate();
  const [
    fetchCompanyProfile,
    {
      data: companyData,
      isLoading: companyIsLoading,
      isError: companyIsError,
      error: companyError,
    },
  ] = useFetchCompanyProfileMutation();

  useEffect(() => {
    fetchCompanyProfile({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const [
    updateCompanyPofile,
    {
      data: updatedCompanyData,
      isLoading: updatedCompanyIsLoading,
      isError: updatedCompanyIsError,
      error: updatedCompanyError,
    },
  ] = useUpdateCompanyProfileMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (companyData) {
      console.log("companyyy", companyData);
      sessionStorage.setItem(
        "companyProfile",
        JSON.stringify(companyData.body)
      );
      dispatch(setCompanyProfile(companyData.body));
      setEmail(companyData.body.email);
      setWebsite(companyData.body.website);
      setcontactPersonNumber(companyData.body?.contact_person_number);
      setContactPersonName(companyData.body?.contact_person_name);
      setFacebook(companyData.body.socials?.facebook);
      setTwitter(companyData.body.socials?.twitter);
      setInstagram(companyData.body.socials?.instagram);
      setYoutube(companyData.body.socials?.youtube);
      setCustomerSupportMobile(companyData.body.customer_support_mobile);
      setCustomerSupportEmail(companyData.body.customer_support_email);
    }

    if (companyError) {
      toast.dismiss();
      // console.log("company error", companyError);
      toast.error("Error Occured while fetching profile data", {
        id: "fetchCompanyError",
      });
    }

    if (updatedCompanyIsLoading) {
      toast.dismiss();
      toast.loading("Updating profile", { id: "updateCompanyloading" });
    }

    if (updatedCompanyData) {
      toast.dismiss();
      // console.log("updated company data", companyData);
      if (updatedCompanyData?.body) {
        sessionStorage.setItem(
          "companyProfile",
          JSON.stringify(updatedCompanyData.body)
        );
      }

      setEmail(updatedCompanyData.body?.email);
      setWebsite(updatedCompanyData.body?.website);
      setcontactPersonNumber(updatedCompanyData.body?.contact_person_number);
      setContactPersonName(updatedCompanyData.body?.contact_person_name);

      setFacebook(updatedCompanyData.body.socials?.facebook);
      setTwitter(updatedCompanyData.body.socials?.twitter);
      setInstagram(updatedCompanyData.body.socials?.instagram);
      setYoutube(updatedCompanyData.body.socials?.youtube);
      setCustomerSupportMobile(updatedCompanyData.body.customer_support_mobile);
      setCustomerSupportEmail(updatedCompanyData.body.customer_support_email);
      toast.success("Updated Company Profile", { id: "updatedCompanyData" });
      navigate(-1);
    }

    if (updatedCompanyError) {
      toast.dismiss();
      // console.log("updated company error", companyError);
      toast.error("Error Occured", { id: "updatedCompanyError" });
    }
  }, [
    companyData,
    companyError,
    updatedCompanyError,
    updatedCompanyData,
    updatedCompanyIsLoading,
  ]);

  const updateCompany = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      website: website,
      contact_person_name: contactPersonName,
      contact_person_number: contactPersonNumber,
      customer_support_mobile: customerSupportMobile,
      customer_support_email: customerSupportEmail,
      socials: {
        youtube: youtube,
        twitter: twitter,
        instagram: instagram,
        facebook: facebook,
      },
    };

    updateCompanyPofile({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: data,
    });
  };

  console.log(customerSupportEmail, customerSupportMobile);
  return (
    <div className="flex w-full bg-slate-200 h-full justify-center">
      <div className=" w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Company Profile
          <LinksComponent />
        </div>
        <div className="p-3">
          <form className="p-3 bg-white shadow rounded-lg">
            <div className="w-full  flex justify-center space-x-3">
              <div className="mb-6 w-1/4">
                <InputComponent
                  name={"Email"}
                  type={"text"}
                  value={email}
                  setValue={setEmail}
                  required={true}
                  placeholder={"genuinemark@org"}
                />
              </div>
              <div className="mb-6 w-1/4">
                <InputComponent
                  name={"Website"}
                  type={"text"}
                  value={website}
                  setValue={setWebsite}
                  required={true}
                  placeholder={"www.genuinemark.in"}
                />
              </div>
              <div className="mb-6 w-1/4">
                <InputComponent
                  name={"Contact Person Name"}
                  type={"text"}
                  value={contactPersonName}
                  setValue={setContactPersonName}
                  required={true}
                  placeholder={""}
                />
              </div>
              <div className="mb-6 w-1/4">
                <InputComponent
                  name={"Contact Person Number"}
                  type={"text"}
                  value={contactPersonNumber}
                  setValue={setcontactPersonNumber}
                  required={true}
                  maxLength={10}
                  placeholder={""}
                />
              </div>
            </div>

            <div className="w-full flex justify-center space-x-4">
              <div className="mb-6 w-1/3">
                <InputComponent
                  name={"Facebook"}
                  type={"text"}
                  value={facebook}
                  setValue={setFacebook}
                  required={true}
                  placeholder={""}
                />
              </div>
              <div className="mb-6 w-1/3">
                <InputComponent
                  name={"Twitter"}
                  type={"text"}
                  value={twitter}
                  setValue={setTwitter}
                  required={true}
                  placeholder={""}
                />
              </div>
              <div className="mb-6 w-1/3">
                <InputComponent
                  name={"Youtube"}
                  type={"text"}
                  value={youtube}
                  setValue={setYoutube}
                  required={true}
                  placeholder={""}
                />
              </div>
              <div className="mb-6 w-1/3">
                <InputComponent
                  name={"Instagram"}
                  type={"text"}
                  value={instagram}
                  setValue={setInstagram}
                  required={true}
                  placeholder={""}
                />
              </div>
            </div>

            <div className="w-full flex justify-center space-x-4">
              <div className="mb-6 w-1/3">
                <InputComponent
                  name={"Customer Support Email"}
                  type={"text"}
                  value={customerSupportEmail}
                  setValue={setCustomerSupportEmail}
                  required={true}
                  placeholder={""}
                />
              </div>
              <div className="mb-6 w-1/3">
                <InputComponent
                  name={"Customer Support Phone Number"}
                  type={"text"}
                  value={customerSupportMobile}
                  setValue={setCustomerSupportMobile}
                  required={true}
                  placeholder={""}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                onClick={(e) => {
                  updateCompany(e);
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </form>
        </div>{" "}
      </div>
    </div>
  );
};

export default EditProfile;
