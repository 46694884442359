import { rootApi } from "../../api/rootApi";

export const ActiveUsersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    accountTransfer: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "POST",
          url: "/tenant/account-transfer",
          headers: {
            "Content-Type": "application/json",
            slug: `${params.tenant_id}`,
            Authorization: "Bearer " + params.token,
          },
          body : JSON.stringify(params.body)
        };
      },
    }),
    accountTransferList: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "GET",
          url: `/tenant/account-transfer/?limit=${params.body.limit}&offset=${params.body.offset}&startDate=${params.body.startDate}&endDate=${params.body.endDate}`,
          headers: {
            "Content-Type": "application/json",
            slug: `${params.tenant_id}`,
            Authorization: "Bearer " + params.token,
          }
        };
      },
    }),
  }),
});

export const {
    useAccountTransferMutation,
    useAccountTransferListMutation
} = ActiveUsersApi;
