import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useAddHeirarchyMutation,
  useBulkAddHeirarchyMutation,
  useFetchHeirarchyMutation,
} from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import HeirarchyComponent from "../../../components/Tenant/HeirarchyComponent/HeirarchyComponent";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import toast from "react-hot-toast";
import { downloadSampleCSV } from "../../../utils/downloadSampleCsv";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";

const ProductHeirarchy = () => {
  const [heirarchy, setHeirarchy] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState();
  const [transformedData, setTransformedData] = useState([]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!file) {
      toast.error("Please select a file");
      return;
    }

    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let datas = parseCSV(text);

        if (datas.length > 0) {
          bulkAddHeirarchy({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
              .tenant_id,
            body: { productHeirachy: datas },
          });
        }
      };
      fileReader.readAsText(file);
    }
  };
  const [
    fetchHeirarchy,
    {
      data: heirarchyData,
      isLoading: heirarchyIsLoading,
      isError: heirarchyIsError,
      error: heirarchyError,
    },
  ] = useFetchHeirarchyMutation();

  const [
    bulkAddHeirarchy,
    {
      data: bulkAddHeirarchyData,
      isLoading: bulkAddHeirarchyIsLoading,
      isError: bulkAddHeirarchyIsError,
      error: bulkAddHeirarchyError,
    },
  ] = useBulkAddHeirarchyMutation();
  useEffect(() => {
    if (bulkAddHeirarchyData) {
      toast.success("Product Heirarchy added successfully");
      setShowModal(false);
      return;
    }
    if (bulkAddHeirarchyError) {
      toast.error("Error in Adding Product Heirarchy");
      return;
    }
  }, [bulkAddHeirarchyData, bulkAddHeirarchyError]);

  const [
    addHeirarchy,
    {
      data: addHeirarchyData,
      isLoading: addHeirarchyIsLoading,
      isError: addHeirarchyIsError,
      error: addHeirarchyError,
    },
  ] = useAddHeirarchyMutation();
  function parseCSV(string) {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .replace(/\s/g, "")
      .split(",");
  
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
  
    const array = csvRows
      .map((row) => row.split(","))
      .filter((values) => values.length === csvHeader.length)
      .map((values) => {
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index].trim();
          return object;
        }, {});
        return obj;
      });
  
    return array;
  }

  const addRoot = () => {
    const newNodeId = 1;
    const newNode = {
      id: newNodeId,
      name: "root",
      master_id: 0,
      is_sub: 1,
      is_master: 1,
    };
    console.log("add node ", newNode);
    const newArr = [];
    newArr.push(newNode);
    const body = {
      name: newNode.name,
      is_sub: `${newNode.is_sub}`,
      master_id: `${newNode.master_id}`,
      is_master: `${newNode.is_master}`,
    };

    addHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: body,
    });
    // setData(newArr);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const hasViewPermission = hasPermission("productHierarchy");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    // console.log("heirarchy -----> ", heirarchyData);
    if (heirarchyData) {
      // console.log("lengthhhhhhhhhhhhhhh" ,heirarchyData.body.length );
      if (heirarchyData.body.length === 0) {
        addRoot();
      }
      setTransformedData(
        heirarchyData.body.map((item) => {
          console.log("item", item);
          if (item.name === "root") {
            return {
              category: item.name,
            };
          } else {
            return {
              sub_category: item.name,
              category:
                heirarchyData.body.find((cat) => cat.id === item.master_id)
                  ?.name || "N/A",
                  category_id : item.id
            };
          }
        })
      );
      setHeirarchy(heirarchyData.body);
    }
  }, [heirarchyData]);

  console.log("transformed", transformedData)

  useEffect(() => {
    fetchHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, [addHeirarchyData]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Product Heirarchy
            <LinksComponent />
          </div>

          <div className="p-3">
            <button
              className="btn btn-outline-primary clear-both   mb-2"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add ProductHeirarchy Csv
            </button>
            <div className="p-3 w-full bg-white shadow rounded-lg">
              {transformedData && transformedData.length > 0 && (
                <ExportCSV
                  data={transformedData}
                  columnsToExport={["category", "sub_category"]}
                />
              )}
              { heirarchyIsLoading && <CircleLoaderComponent/> }
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="flex items-center ">
                    <div className=" w-full">
                      <HeirarchyComponent
                        heirarchyData={heirarchy}
                        fetchHeirarchy={fetchHeirarchy}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <ModalComponent show={showModal} setShow={setShowModal} size="md">
            <div className="w-full p-4">
              {
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="text-white mb-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() =>
                      downloadSampleCSV([
                        { name: "category" },
                        {
                          name: "sub_category",
                        },
                      ])
                    }
                  >
                    Sample Csv Download
                  </button>
                </div>
              }
              <div className="w-full  justify-center ">
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnChange}
                    />
                  </label>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleOnSubmit()}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default ProductHeirarchy;
