import { rootApi } from "../../api/rootApi";

export const otpApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    loginViaUserIdAndPassword: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "POST",
          url: "/app/appUserLogin/login",
          headers: {
            "Content-Type": "application/json",
            slug: `${params.tenant_id}`,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),
    registerUser: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "POST",
          url: "/app/appUserLogin/",
          headers: {
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    bulkRegisterUser: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/app/appUserLogin/bulk",
          headers: {
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useLoginViaUserIdAndPasswordMutation,
  useRegisterUserMutation,
  useBulkRegisterUserMutation,
} = otpApi;
