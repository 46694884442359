import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { BsCheckLg } from "react-icons/bs";
import { HiChevronUpDown } from "react-icons/hi2";

// const dataArr = [
//   { id: 1, name: 'Wade Cooper' },
//   { id: 2, name: 'Arlene Mccoy' },
//   { id: 3, name: 'Devon Webb' },
//   { id: 4, name: 'Tom Cook' },
//   { id: 5, name: 'Tanya Fox' },
//   { id: 6, name: 'Hellen Schmidt' },
// ]

export default function SelectComponentWithSearch({
  title,
  selected,
  setSelected,
  placeholder,
  dataArr,
  required = false
}) {
  // const [selected, setSelected] = useState(people[0])
  const [query, setQuery] = useState("");

  const filteredData =
    query === ""
      ? dataArr
      : dataArr.filter((data) =>
          data.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  // const [show, setShow] = useState(false);


  return (
    <div className="w-full">
      <Combobox value={selected} onChange={setSelected}>
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 mb-0">
          {title} {required && " * "}
        </Combobox.Label>
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="shadow-sm capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
              displayValue={(person) => person.name}
              // onClick={ (e) => setShow(!show)}
              placeholder={placeholder}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronUpDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            // show={show}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2.5 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((each , idx) => (
                  <Combobox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2.5 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={each}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block capitalize truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {each.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <BsCheckLg className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
