import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateSmsCredentialMutation } from '../../../features/admin/smsCredential/smsCredentialApi';
import { toast } from 'react-hot-toast';

const UpdateSMSCredential = () => {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("object",location.state);

    const [selectCredType, setSelectCredType] = useState( location.state.type === "smslane" ? 1:2   );
  const [inputApiKey, setApiKey] = useState(location.state.type === "smslane" ? location.state.api_credential.api_key : null );
  const [inputUserId, setuserId] = useState(location.state.type === "smslane" ? null : location.state.api_credential.user_id );
  const [inputPassword, setPassword] = useState(location.state.type === "smslane" ? null : location.state.api_credential.password );
  const [inputEntityId, setEntityId] = useState(location.state.entity_id);



  const [
    updateSmsCredential,
    {
    data: updateSmsCredentialData,
    isError: updateSmsCredentialIsError,
    error: updateSmsCredentialError,
    isLoading: updateSmsCredentialIsLoading,
  } ]= useUpdateSmsCredentialMutation();



    const handleUpdateSmsCredential =(e)=>{
        e.preventDefault();

        console.log("selectCredType",selectCredType);
        console.log("inputApiKey",inputApiKey);
        console.log("selectCredType",inputUserId);

        if( selectCredType === 1 && ( inputApiKey == null || inputApiKey === "" )){
            toast.error("please enter api key")
            return ;
        }
        if( selectCredType === 2 && ( inputUserId == null || inputUserId === ""  || inputPassword == null || inputPassword === "" )){
            toast.error("please enter User id or Password")
            return ;
        }
    
        if( inputEntityId === null || inputEntityId === "" ){
            toast.error("please enter Entity Id")
            return ;
        }

        const apiCredential = selectCredType === 1 ? {
            api_key : inputApiKey,
        } : {
            user_id : inputUserId,
            password : inputPassword
        };
        
        updateSmsCredential({
            token: sessionStorage.getItem("access_token"),
            body:{
                api_credential: apiCredential,
                entity_id: inputEntityId,
            },
            id : location.state.id
        }
        )
    }

    if(updateSmsCredentialIsError){
        toast.dismiss();
        toast.error("Some Error Occured", {
          id: "updateSmsCredentialIsError",
        });
       }
      
       if(updateSmsCredentialIsLoading){
        toast.dismiss();
        toast.loading("Loading...",{
          id : "updateSmsCredentialIsLoading"
        })
       }
    
       useEffect(()=>{
        if(updateSmsCredentialData){
          toast.dismiss();
          toast.success(`${updateSmsCredentialData.message}`,{
            id : `getSmsCredentialWithLimitOffsetData`
          })
          navigate(-1);
        }
       },[updateSmsCredentialData])

  return (
    <>
      <div className="page-content">
      <div className="card">
              <div className="card-body">
              <div className="borderbtm">
                <h4 className="card-title">Update SMS Credentials</h4>
                </div>

                <div className="cmxform" id="signupForm">
                  <div className="frmds row">
                    <div className="col-md-6 mt-3">
                      <select
                        type="text"
                        className="border "
                        placeholder="Add new UserType"
                        value={selectCredType}
                        // onChange={(e) => setSelectCredType(Number(e.target.value))}
                      >
                        {/* <option value={0}>select</option> */}
                        {location.state.type === "smslane" ? (<option value={1}>Api Key</option>) : ( <option value={2}>User_id/Password</option>) }

                        
                      </select>
                    </div>
                  </div>
                  {location.state.type === "smslane" ? (
                    <>
                      <div className="frmds row">
                        <div className="col-md-6 mt-3">
                          <label for="name">API KEY </label>
                          <input
                            id="name"
                            className="form-control"
                            value={inputApiKey}
                            onChange={(e) => {
                              setApiKey(e.target.value);
                            }}
                            type="text"
                          />
                        </div>
                      </div>{" "}
                    </>
                  ) : ( 
                    <>
                      <div className="frmds row">
                        <div className="col-md-6 mt-3">
                          <label for="name">User Id </label>
                          <input
                            id="name"
                            className="form-control"
                            value={inputUserId}
                            onChange={(e) => {
                              setuserId(e.target.value);
                            }}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="frmds row">
                        <div className="col-md-6 mt-3">
                          <label for="name">Password </label>
                          <input
                            id="name"
                            className="form-control"
                            value={inputPassword}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            type="text"
                          />
                        </div>
                      </div>
                    </>
                )}

                  <div className="frmds row">
                    <div className="col-md-6 mt-3">
                      <label for="name">Entity Id </label>
                      <input
                        id="name"
                        className="form-control"
                        value={inputEntityId}
                        onChange={(e) => {
                          setEntityId(e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-4">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btnall"
                          onClick={handleUpdateSmsCredential}
                      >
                        Update
                      </button>
                    </div>
                    {/* <div className="col-md-2 mt-3">
                      <button
                        type="cancel"
                        className="btn btn-outline-danger mr-2 w-100"
                        //   onClick={cancelAddIndustry}
                      >
                        Cancel
                      </button>
                    </div> */}
                  </div>
                  {/* {error && (
                  <div className="alert alert-danger mt-4" role="alert">
                    {error}
                  </div>
                )} */}
                </div>
              </div>
            </div>
      </div>
    </>
  )
}

export default UpdateSMSCredential