import React, { useState } from "react";
import { useFetchAllAppUserTypeQuery } from "../../../features/admin/AppUserType/appUserTypeApi";

const AddAppUserType = () => {

    const [selectAppUserType, setSelectedAppUserType] = useState({
        id : 0,
        value : "select app usertype",
    });

  const {
    data: allAppUserTypeData,
    isError: allAppUserTypeIsError,
    error: allAppUserTypeError,
    isLoading: allAppUserTypeIsLoading,
  } = useFetchAllAppUserTypeQuery({
    token: sessionStorage.getItem("access_token"),
  });

  console.log("object",selectAppUserType);

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add App Users Type</h4>
              <div className="cmxform">
              
                <div className="form-group row">
                  <div className="col-md-3 my-3">
                    <label>select Type User </label>
                    <select
                      className="js-example-basic-single w-100"
                      value={selectAppUserType.value}
                    >
                        <option value={selectAppUserType.value}>{selectAppUserType.value}</option>
                        {
                            allAppUserTypeData&&allAppUserTypeData?.body.map(x=>{
                                return <>
                                <option value={x.user_type}>{x.user_type}</option>
                                </>
                            })
                        }
                     
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <button
                      className="btn btn-outline-primary mr-2 w-100"
                      //   onClick={AddVendorTheme}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAppUserType;
