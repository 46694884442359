import { rootApi } from "../../api/rootApi";

export const productInstallationVideoApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({

    addProductInstallationVideos: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/product-installation-videos/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
    fetchProductInstallationVideos: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/product-installation-videos?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    deleteProductInstallationVideos: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/product-installation-videos/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateProductInstallationVideos: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/product-installation-videos/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
        },
        body:params.body
        };
      },
    }),
  }),
});

export const {
  useFetchProductInstallationVideosMutation,
  useDeleteProductInstallationVideosMutation,
  useUpdateProductInstallationVideosMutation,
  useAddProductInstallationVideosMutation
} = productInstallationVideoApi;
