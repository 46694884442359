import { rootApi } from "../../api/rootApi";

export const scratchableRewardApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
      createScratchableReward: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/scratchableReward/add`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body: params.body,
          };
        },
      }),
  
      fetchAllScratchableRewardBySchemeId: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/scratchableReward/scheme/${params.id}?limit=${params.limit}&offset=${params.offset}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
  
      fetchScratchableRewardById: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/scratchableReward/${params.id}?limit=${params.limit}&offset=${params.offset}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
  
      updateScratchableRewardById: builder.mutation({
        query: (params) => {
          return {
            method: "PUT",
            url: `/tenant/scratchableReward/${params.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body: params.body,
          };
        },
      }),
      
      deleteScratchableRewardById: builder.mutation({
        query: (params) => {
          return {
            method: "DELETE",
            url: `/tenant/scratchableReward/${params.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
    }),
  });
  
  export const {
    useCreateScratchableRewardMutation,
    useFetchScratchableRewardByIdMutation,
    useFetchAllScratchableRewardBySchemeIdMutation,
    useUpdateScratchableRewardByIdMutation,
    useDeleteScratchableRewardByIdMutation,
  } = scratchableRewardApi;
  