import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import silver from "../../../assests/tenant/images/Silver.svg";
import Gold from "../../../assests/tenant/images/Gold.svg";
import platinum from "../../../assests/tenant/images/Platinum.svg";
import { toast } from "react-hot-toast";
import {
  useDeleteMembershipByIdMutation,
  useFetchAllMembershipMutation,
  useFetchMembershipByIdMutation,
} from "../../../features/tenant/membership/membershipApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { HiEye } from "react-icons/hi2";

function convertToTierFormat(dataArray, type = 1) {
  const result = {};

  dataArray.sort((a, b) => b.tier.rank - a.tier.rank);
  console.log("dataArray", dataArray);

  if (type == 0) {
    dataArray.forEach((entry) => {
      result[entry.tier.name] = {
        id: entry.id,
        rangeType: entry.range_type,
        rangeStart: entry.range_start,
        rangeEnd: entry.range_end,
        perMonth: entry.per_month || 0,
        pointsMultiplier: parseFloat(entry.points),
        giftDiscount: parseFloat(entry.gift_discount),
        cashback: entry.cashback,
        cash_benefit: entry.cash_benefit,
      };
    });
  } else {
    dataArray.forEach((entry) => {
      result[entry.tier.name] = {
        rangeType: entry.range_type == 1 ? "Purchase" : entry.range_type == 2 ? "Scan" : entry.range_type == 3 ? "QrMrp" : "Points",
        rangeStart: entry.range_start,
        rangeEnd: entry.range_end,
        perMonth: entry.per_month || 0,
        pointsMultiplier: parseFloat(entry.points),
        giftDiscount: parseFloat(entry.gift_discount),
        cashback: entry.cashback,
        cash_benefit: entry.cash_benefit,
      };
    });
  }

  return result;
}

const gradients = [
  "bg-gradient-to-r from-orange-700 to-orange-400",
  "bg-gradient-to-r from-rose-700 to-rose-400",
  "bg-gradient-to-r from-gray-600 to-gray-400",
  "bg-gradient-to-r from-indigo-600 to-indigo-400",
  "bg-gradient-to-r from-blue-600 to-blue-400",
  "bg-gradient-to-r from-blue-600 to-blue-400",
];

const stars = [Gold, platinum, silver, silver, silver, silver];

const MembershipList = () => {
  const addMembershipPermission = hasPermission("addMembership");
  const manageMembershipPermission = hasPermission("manageMembership");
  const deleteMembershipPermission = hasPermission("deleteMembership");

  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Range Type",
      selector: (row) =>
        row.range_type == 1
          ? " Purchase"
          : (row.range_type == 2
          ? "Scan"
          :  row.range_type == 3 ? "QrMrp" : "Points"),
      sortable: true,
      // width: "120px",
    },
    {
      name: "Start date",
      selector: (row) => new Date(row.start_date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "End date",
      selector: (row) => new Date(row.end_date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <button
          className="flex justify-center items-center rounded bg-green-500 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#28a745] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(40,167,69,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)]"
          onClick={() =>
            fetchMembershipById({
              token: sessionStorage.getItem("access_token"),
              tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                .tenant_id,
              id: row.id,
            })
          }
        >
          <HiEye className="mr-0.5" />
          View
        </button>
      ),
      sortable: true,
      width: "110px",
    },

    {
      name: "",
      selector: (row) => (
        <button
          className="flex justify-center items-center rounded bg-green-500 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#28a745] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(40,167,69,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)]"
          onClick={() =>
            navigate("history", {
              state: row,
            })
          }
        >
          History
        </button>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "",
      selector: (row) => (
        // editMembershipPermission &&
        <div>
          <button
            onClick={() =>
              navigate("add", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-gray-500 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-0.5" />
            Edit
          </button>
        </div>
      ),
      width: "110px",
    },

    {
      name: "",
      selector: (row) => (
        // manageMembershipPermission &&
        <div>
          <button
            onClick={() =>
              navigate("manage", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-0.5" />
            Manage
          </button>
        </div>
      ),
      width: "140px",
    },
    {
      name: "",
      selector: (row) => (
        // deleteMembershipPermission &&
        <div>
          <button
            onClick={() => handleDelete(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        </div>
      ),
      width: "140px",
    },
  ];

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [tiers, setTiers] = useState([]);
  const [rangeType, setRangeType] = useState(null);

  const [
    fetchAllMembership,
    {
      data: membershipData,
      isLoading: MembershipIsLoading,
      error: MembershipError,
    },
  ] = useFetchAllMembershipMutation();

  const [
    fetchMembershipById,
    { data: fetchMembershipData, error: fetchMembershipError },
  ] = useFetchMembershipByIdMutation();

  useEffect(() => {
    if (fetchMembershipData && fetchMembershipData.body) {
      console.log("fetch membership data", fetchMembershipData);
      setRangeType(
        fetchMembershipData.body[0].range_type == 1
          ? "Purchase"
          : fetchMembershipData.body[0].range_type == 2
          ? "Scan" : fetchMembershipData.body[0].range_type == 3 ? "QrMrp" : "Points",
      );
      const arr = [...fetchMembershipData.body[0].tiers].reverse();
      setTiers(arr);
      setModalData(convertToTierFormat([...fetchMembershipData.body]));
      setShowModal(true);
    }

    if (fetchMembershipError) {
      console.log("fetch membership error", fetchMembershipError);
      toast("Something went wrong , Please refresh the page and try again", {
        id: "errroRefresh",
      });
    }
  }, [fetchMembershipData, fetchMembershipError]);

  useEffect(() => {
    // const hasMembershipView = hasPermission("Membership");

    // if (!hasMembershipView) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    fetchAllMembership({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: 10,
      offset: 0,
    });
  }, []);

  console.log(modalData);

  const [
    deleteMembership,
    {
      data: deleteMembershipData,
      isLoading: deleteMembershipIsLoading,
      error: deleteMembershipError,
    },
  ] = useDeleteMembershipByIdMutation();

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteMembership({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: data.id,
                });
                fetchAllMembership({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  limit: 10,
                  offset: 0,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteMembership",
      },
    );
    return;
  };

  useEffect(() => {
    if (deleteMembershipData) {
      console.log("DeleteMembership ", deleteMembershipData);
      toast.success("Deleted Successfully.");
    }

    if (deleteMembershipError) {
      toast.error("Something went wrong", {
        id: "deleteMembershipError",
      });
    }
  }, [deleteMembershipError, deleteMembershipData]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Membership
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={membershipData ? membershipData.body.data : []}
                // loading={loading}
                // totalRows={totalRows}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
              {
                //   addMembershipPermission &&
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"6xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full rounded-sm p-4">
              <div className="rounded-lg w-full overflow-x-scroll flex flex-col justify-start items-start space-y-2">
                <div class="w-full h-full rounded-lg ">
                  <h1 className="text-3xl pb-4 mt-2 text-center font-bold">
                    Membership Details
                  </h1>
                  <div class={`grid grid-cols-${tiers.length} gap-4`}>
                    {/* <div className="border border-gray-200 rounded-2xl pb-3">
                      <div className="bg-gradient-to-r from-gray-600 to-gray-400 px-3 pb-20 pt-4 rounded-t-2xl">
                        <h2 className="block text-center text-2xl font-semibold text-white">
                          Silver
                        </h2>
                      </div>

                      <div className="text-center m-auto">
                        <div className="rounded_c rounded-full flex justify-center items-center bg-white  ">
                          <span className="rounded-full p-2">
                            <img
                              src={silver}
                              className="max-h-full max-w-full "
                            />
                          </span>
                        </div>
                      </div>

                      <ul className="px-4">
                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Scan Value
                          </span>
                          <span className="font-medium text-md">21-50</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Scan/Month
                          </span>
                          <span className="font-medium text-md">21</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Points Multiplier %
                          </span>
                          <span className="font-medium text-md">2</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Gift Discount %
                          </span>
                          <span className="font-medium text-md">10</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Cashback Option
                          </span>
                          <span className="font-medium text-md text-green-700">
                            Yes
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="border border-gray-200 rounded-2xl pb-3">
                      <div className="bg-gradient-to-r from-amber-700 to-amber-400 px-3 pb-20 pt-4 rounded-t-2xl">
                        <h2 className="block text-center text-2xl font-semibold text-white">
                          Gold
                        </h2>
                      </div>

                      <div className="text-center m-auto">
                        <div className="rounded_c rounded-full flex justify-center items-center bg-white  ">
                          <span className="rounded-full p-2">
                            <img
                              src={Gold}
                              className="max-h-full max-w-full "
                            />
                          </span>
                        </div>
                      </div>

                      <ul className="px-4">
                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Scan Value
                          </span>
                          <span className="font-medium text-md">11-20</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Scan/Month
                          </span>
                          <span className="font-medium text-md">11</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Points Multiplier %
                          </span>
                          <span className="font-medium text-md">1.5</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Gift Discount %
                          </span>
                          <span className="font-medium text-md">5</span>
                        </li>

                        <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                          <span className="font-medium text-md">
                            Cashback Option
                          </span>
                          <span className="font-medium text-md text-green-700">
                            Yes
                          </span>
                        </li>
                      </ul>
                    </div> */}

                    {tiers.map((tier, idx) => (
                      <div
                        key={idx}
                        className="border border-gray-200 rounded-2xl pb-3"
                      >
                        <div
                          className={
                            " px-3 pb-20 pt-4 rounded-t-2xl  " +
                            gradients[tier.id - 1]
                          }
                        >
                          <h2 className="block text-center text-2xl font-semibold text-white">
                            {tier.name}
                          </h2>
                        </div>
                        <div className="text-center m-auto">
                          <div className="rounded_c rounded-full flex justify-center items-center bg-white  ">
                            <span className="rounded-full p-2">
                              <img
                                src={stars[tier.id - 1]}
                                className="max-h-full max-w-full "
                              />
                            </span>
                          </div>
                        </div>

                        <ul className="px-4">
                          <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                            <span className="font-medium text-md">
                              {rangeType} Slab
                            </span>
                            <span className="font-medium text-md">
                              {" "}
                              {Number(
                                modalData[tier.name].rangeStart,
                              ).toLocaleString()}{" "}
                              -{" "}
                              {Number(
                                modalData[tier.name].rangeEnd,
                              ).toLocaleString()}
                            </span>
                          </li>

                          <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                            <span className="font-medium text-md">
                              {rangeType}/Month
                            </span>
                            <span className="font-medium text-md">
                              {Number(
                                modalData[tier.name].perMonth,
                              ).toLocaleString()}
                            </span>
                          </li>

                          <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                            <span className="font-medium text-md">
                              Points Multiplier %
                            </span>
                            <span className="font-medium text-md">
                              {modalData[tier.name].pointsMultiplier}
                            </span>
                          </li>

                          <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                            <span className="font-medium text-md">
                              Gift Discount %
                            </span>
                            <span className="font-medium text-md">
                              {modalData[tier.name].giftDiscount}
                            </span>
                          </li>

                          <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                            <span className="font-medium text-md">
                              Cashback Option
                            </span>
                            {modalData[tier.name].cashback ? (
                              <span className="font-medium text-md text-green-700">
                                Yes
                              </span>
                            ) : (
                              <span className="font-medium text-md text-red-700">
                                No
                              </span>
                            )}
                          </li>

                          <li className="flex border-b border-gray-100 justify-between pb-2.5 mb-2.5">
                            <span className="font-medium text-md">
                              Cash Benefit
                            </span>
                            <span className="font-medium text-md">
                              {modalData[tier.name]?.cash_benefit}
                            </span>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>

                  {/* <table class="w-full h-full rounded-lg">
                    <thead className=" bg-blue-600 rounded-lg text-white w-full border-b border-gray-300">
                      <tr class=" w-full h-14">
                        <th class=" cursor-pointer text-lg font-bold bg-gray-100 text-black  w-3/12"></th>

                        <td className="w-full h-full flex justify-evenly items-center ">
                          {tiers.map((tier) => (
                            <th class=" w-full h-full border-r border-gray-300 cursor-pointer text-lg font-bold">
                              <div class="flex h-full items-center justify-center ">
                                {tier.name}
                              </div>
                            </th>
                          ))}
                        </td>
                      </tr>
                    </thead>
                    <tbody className="w-full min-h-max">
                      <tr class="text-center w-full h-24 border-b border-gray-300">
                        <td class="font-bold text-base capitalize bg-gray-100 w-3/12">
                          {rangeType} Value : Start - End
                        </td>

                        <td className="w-full h-full bg-white flex justify-evenly items-center ">
                          {tiers.map((tier) => (
                            <td class="flex px-2 justify-center border-r border-gray-300 h-full items-center space-x-2 w-full ">
                              <input
                                type="number"
                                readOnly
                                class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 w-24"
                                value={modalData[tier.name].rangeStart}
                              />
                              <input
                                type="number"
                                readOnly
                                class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 w-24"
                                value={modalData[tier.name].rangeEnd}
                              />
                            </td>
                          ))}
                        </td>
                      </tr>

                      <tr class=" w-full h-24 text-center border-b border-gray-300">
                        <td class="font-bold text-base capitalize bg-gray-100 w-3/12">
                          {rangeType}/month
                        </td>

                        <td className="w-full h-full bg-white flex  justify-evenly items-center ">
                          {tiers.map((tier) => (
                            <td class=" flex justify-center items-center w-full border-r border-gray-300 h-full ">
                              <input
                                type="number"
                                readOnly
                                class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 w-24"
                                value={modalData[tier.name].perMonth}
                              />
                            </td>
                          ))}
                        </td>
                      </tr>

                      <tr class=" w-full h-24 text-center border-b border-gray-300">
                        <td class="  font-bold text-base capitalize bg-gray-100 w-3/12">
                          Points multiplier %
                        </td>

                        <td className="w-full h-full bg-white flex  justify-evenly items-center ">
                          {tiers.map((tier) => (
                            <td class="    flex justify-center items-center  border-r border-gray-300 h-full w-full ">
                              <input
                                type="number"
                                readOnly
                                class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 w-24"
                                value={modalData[tier.name].pointsMultiplier}
                              />
                            </td>
                          ))}
                        </td>
                      </tr>

                      <tr class=" w-full h-24 text-center border-b border-gray-300">
                        <td class="  font-bold text-base capitalize bg-gray-100 w-3/12">
                          Gift discount %
                        </td>
                        <td className="w-full h-full bg-white  flex justify-evenly items-center ">
                          {tiers.map((tier) => (
                            <td class="  flex justify-center  border-r border-gray-300 h-full items-center w-full ">
                              <input
                                type="number"
                                readOnly
                                class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 w-24"
                                value={modalData[tier.name].giftDiscount}
                              />
                            </td>
                          ))}
                        </td>
                      </tr>

                      <tr class=" w-full h-24 text-center border-b border-gray-300">
                        <td class="  font-bold text-base capitalize bg-gray-100 w-3/12">
                          Cashback Option
                        </td>
                        <td className="w-full h-full bg-white  flex justify-evenly items-center ">
                          {tiers.map((tier) => (
                            <td class="  flex justify-center items-center w-full  border-r border-gray-300 h-full ">
                              <div className="flex justify-center items-center ">
                                <div className="flex w-full justify-center items-center">
                                  {modalData[tier.name].cashback ? (
                                    <div className=" flex text-lg justify-center items-center">
                                      <BsCheckCircleFill className="w-3.5 mr-2" />
                                      Yes
                                    </div>
                                  ) : (
                                    <div className="flex text-lg justify-center items-center">
                                      <BsCircle className="w-3.5 mr-2" />
                                      No
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default MembershipList;
