import { rootApi } from "../../api/rootApi";

export const paymentTransferApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllPaymentTransfer: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/payment-transfer`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchAllNotInitiatedPayment: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/payment-transfer/not-intiate-payment`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),

    fetchPaymentTransferByBatchID: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/payment-transfer/batch/${params.batch_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchPaymentTransferByBatchIDCSV: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/payment-transfer/batch-csv/${params.batch_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),


    initiatePaymentTransferByBatchID: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/payment-transfer/intiate-payment/${params.batch_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    
    bulInsertPaymentTransfer: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/payment-transfer/bulk-insert`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
   
    deletePaymentTransfer: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/payment-transfer/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    deletePaymentTransferByBatchID: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/payment-transfer/batch/${params.batch_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
 useBulInsertPaymentTransferMutation,
 useFetchAllPaymentTransferMutation,
 useFetchPaymentTransferByBatchIDMutation,
 useFetchPaymentTransferByBatchIDCSVMutation,
 useInitiatePaymentTransferByBatchIDMutation ,
 useDeletePaymentTransferMutation,
 useDeletePaymentTransferByBatchIDMutation,
 useFetchAllNotInitiatedPaymentMutation
} = paymentTransferApi;
