import React, { useEffect, useState } from "react";
import { useAddSmsCredentialMutation } from "../../../features/admin/smsCredential/smsCredentialApi";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const providerArray = [
  {
    id: 1,
    name: "orbital",
    form: {
      name: ["user_id", "password", "entity_id"],
      view_name: ["User Id", "Password", "Entity Id"],
    },
  },
  {
    id: 2,
    name: "smslane",
    form: {
      name: ["api_key", "entity_id"],
      view_name: ["Api Key", "Entity Id"],
    },
  },
  {
    id: 3,
    name: "juro",
    form: {
      name: ["token","entity_id"],
      view_name: ["Token", "Entity Id"],
    },
  },
];

const AddSMSCredential = () => {
  const navigate = useNavigate();
  const [selectCredType, setSelectCredType] = useState(0);
  const [formData , setFormData] = useState({});
//   const [inputData , setInputData] = useState({});

//   const [inputApiKey, setApiKey] = useState(null);
//   const [inputUserId, setuserId] = useState(null);
//   const [inputPassword, setPassword] = useState(null);
//   const [inputEntityId, setEntityId] = useState(null);

  console.log("selectCredType", selectCredType);

  const [
    addSmsCredential,
    {
      data: addSmsCredentialData,
      isError: addSmsCredentialIsError,
      error: addSmsCredentialError,
      isLoading: addSmsCredentialIsLoading,
    },
  ] = useAddSmsCredentialMutation();

  console.log("object", providerArray[selectCredType]);

//   const updateData = function (e) {
    // data[e.name] = e.value;
//     console.log(data);
//   };

  const handleAddSmsCredential = (e) => {
    e.preventDefault();
    let allFieldAdded = false;

    for( const [key , value] of Object.entries(formData)){
        console.log(key , value);
        if(value === null || value === ""){
            allFieldAdded = true;
        }
    }
    if(allFieldAdded || Object.keys(formData).length <  providerArray[selectCredType].form.name.length ){
        toast.error("Please Enter field ",{
            id : "field error "
        });
        return;
    }

    let apiCredential={};

    for( const [key , value] of Object.entries(formData)){
        if(key != "entity_id")
        apiCredential[key] = value;
    }
    // console.log("{{{{{{{{{{{{{{{{{{{{{{{{",apiCredential);
    console.log(formData['entity_id']);
    
    addSmsCredential({
      token: sessionStorage.getItem("access_token"),
      body: {
        api_credential: apiCredential,
        entity_id: formData['entity_id'],
        type: providerArray[selectCredType].name
      },
    });
  };

  if (addSmsCredentialIsError) {
    toast.dismiss();
    toast.error("Some Error Occured", {
      id: "addSmsCredentialIsError",
    });
  }

  if (addSmsCredentialIsLoading) {
    toast.dismiss();
    toast.loading("Loading...", {
      id: "addSmsCredentialIsLoading",
    });
  }

  useEffect(() => {
    if (addSmsCredentialData) {
      toast.dismiss();
      toast.success(`${addSmsCredentialData.message}`, {
        id: `getSmsCredentialWithLimitOffsetData`,
      });
      navigate(-1);
    }
  }, [addSmsCredentialData]);

  console.log("formData----------->",formData);

  return (
    <>
      <div className="page-content">
      <div className="card">
              <div className="card-body">
                <div className="borderbtm">
                <h4 className="card-title mb-0">Add New SMS Credentials</h4>
                </div>
                <div className="grid grid-cols-4 gap-4 cmxform" id="signupForm">
                  <div className=" frmds">
                 
                    <div className=" mt-3">
                    <label for="">Select </label>
                      <select
                        type="text"
                        className="border capitalize"
                        placeholder="Add new UserType"
                        value={selectCredType}
                        onChange={(e) =>
                          setSelectCredType(Number(e.target.value))
                        }
                      >
                        {/* <option value={0}>select</option> */}
                        {/* <option value={1}>Api Key</option>
                        <option value={2}>User_id/Password</option> */}
                        {providerArray &&
                          providerArray.map((x, index) => {
                            return (
                              <option key={x.id} value={Number(index)}>
                                {x.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  {providerArray && providerArray.length >0 &&
                    providerArray[Number(selectCredType)]?.form?.name.map((x, index) => {
                      return (
                        <>
                          <div key={index} className=" frmds">
                            <div className="mt-3">
                              <label for="name"> {  providerArray[selectCredType]?.form.view_name[index] } </label>
                              <input
                                name={x}
                                className="form-control"
                                // value={inputApiKey}
                                onChange={(e) => {
                                  let data = {};
                                  data[e.target.name] = e.target.value
                                  setFormData({ ...formData,...data});
                                }}
                                type="text"
                              />
                            </div>
                          </div>{" "}
                        </>
                       
                      );
                    })}

               
                  {/* {error && (
                  <div className="alert alert-danger mt-4" role="alert">
                    {error}
                  </div>
                )} */}
                </div>
                <div className="block">
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btnall"
                        onClick={handleAddSmsCredential}
                      >
                        Add
                      </button>
                    </div>
                    {/* <div className="col-md-2 mt-3">
                      <button
                        type="cancel"
                        className="btn btn-outline-danger mr-2 w-100"
                        //   onClick={cancelAddIndustry}
                      >
                        Cancel
                      </button>
                    </div> */}
                  </div>
              </div>
            </div>
      </div>
    </>
  );
};

export default AddSMSCredential;
