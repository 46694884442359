import { rootApi } from "../../api/rootApi";

export const imageUploadApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/images/upload",
          // Authorization: "Bearer " + params.token,
          body: params.body,
        };
      },
    }),


    uploadSingleFile: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/file/single",
          Authorization: "Bearer " + params.token,
          body: params.body,
        };
      },
    }),

    uploadMultipleFiles: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/file/multiple",
          Authorization: "Bearer " + params.token,
          body: params.body,
        };
      },
    }),
  }),
});
export const { useUploadImageMutation , useUploadMultipleFilesMutation , useUploadSingleFileMutation } = imageUploadApi;
