import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddSmsTemplateMutation } from "../../../features/admin/messageTemplate/messageTemplateApi";
import { toast } from "react-hot-toast";

const templateTypeArray = [
  {
    id: 1,
    type: "otp",
  },
  { id: 2, type: "registraion" },
  { id: 3, type: "block account" },
  { id: 4, type: "activate account" },
  { id: 5, type: "reset" },
  { id: 6, type: "profile completion" },
  { id: 7, type: "set mpin" },
  { id: 8, type: "redeem request" },
  { id: 9, type: "bonus points" },
  { id: 10, type: "birthday wishes" },
  { id: 11, type: "redemption reminders" },
  { id: 12, type: "scan_coupon points" },
  { id: 13, type: "complaint message" },
  { id: 14, type: "gift delivery" },
  { id: 15, type: "extra points" },
  { id: 16, type: "account status" },
  { id: 17, type: "point earned" },
  { id: 18, type: "gift redeem request" },
];

export default function AddMessageTemplate() {
  const navigate = useNavigate();

  const [selectTemplateType, setTemplateType] = useState(null);
  const [inputTemplateId, setTemplateId] = useState(null);
  const [inputTemplateText, setTemplateText] = useState(null);
  const [inputSenderId, setSenderId] = useState(null);

  const [
    addSmsTemplate,
    {
      data: addSmsTemplateData,
      isError: addSmsTemplateIsError,
      error: addSmsTemplateError,
      isLoading: addSmsTemplateIsLoading,
    },
  ] = useAddSmsTemplateMutation();

  const location = useLocation();
  console.log("location", location.state);

  const handleAddSmsCredential = (e) => {
    e.preventDefault();
    if (selectTemplateType == null) {
      toast.error("please select template type");
      return;
    }
    if (inputTemplateId == null || inputTemplateId == "") {
      toast.error("please enter Template Id");
      return;
    }
    if (inputTemplateText == null || inputTemplateText == "") {
      toast.error("please enter Template Text");
      return;
    }
    if (inputSenderId == null || inputSenderId == "") {
      toast.error("please enter Sender Id ");
      return;
    }

    addSmsTemplate({
      token: sessionStorage.getItem("access_token"),
      body:{
        "template_id": inputTemplateId,
        "template_text" : inputTemplateText,
        "account_id" : Number(location.state.id ),
        "sender_id" : inputSenderId,
        "type"  :  selectTemplateType,
        "template_owned_by_id":location.state.tenant_id
      }

    }
    )

  };

  if(addSmsTemplateIsError){
    toast.dismiss();
    toast.error("Some Error Occured", {
      id: "addSmsTemplateIsError",
    });
   }
  
   if(addSmsTemplateIsLoading){
    toast.dismiss();
    toast.loading("Loading...",{
      id : "addSmsTemplateIsLoading"
    })
   }

   useEffect(()=>{
    if(addSmsTemplateData){
      toast.dismiss();
      toast.success(`${addSmsTemplateData.message}`,{
        id : `addSmsTemplateData`
      })
      navigate(-1);
    }
   },[addSmsTemplateData])
  

  return (
    <>
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add New SMS Template</h4>

                <div className="cmxform" id="signupForm">
                  <div className="form-group row">
                    <div className="col-md-6 mt-3">
                      <label for="name">Template Type</label>

                      <select
                        type="text"
                        className="border p-1"
                        placeholder="Add new UserType"
                        value={selectTemplateType}
                        onChange={(e) => setTemplateType(e.target.value)}
                      >
                        <option value={null}>select</option>

                        {templateTypeArray &&
                          templateTypeArray.length > 0 &&
                          templateTypeArray.map((x) => {
                            return <option value={x.type}>{x.type}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 mt-3">
                      <label for="name">Template Id </label>
                      <input
                        id="name"
                        className="form-control"
                        value={inputTemplateId}
                        onChange={(e) => {
                          setTemplateId(e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>{" "}
                  <div className="form-group row">
                    <div className="col-md-6 mt-3">
                      <label for="name">Template Text</label>
                      <input
                        id="name"
                        className="form-control"
                        value={inputTemplateText}
                        onChange={(e) => {
                          setTemplateText(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 mt-3">
                      <label for="name">Sender Id </label>
                      <input
                        id="name"
                        className="form-control"
                        value={inputSenderId}
                        onChange={(e) => {
                          setSenderId(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 mt-3">
                      <button
                        type="submit"
                        className="btn btn-outline-primary mr-2 w-100"
                        onClick={handleAddSmsCredential}
                      >
                        Add
                      </button>
                    </div>
                    {/* <div className="col-md-2 mt-3">
                    <button
                      type="cancel"
                      className="btn btn-outline-danger mr-2 w-100"
                      //   onClick={cancelAddIndustry}
                    >
                      Cancel
                    </button>
                  </div> */}
                  </div>
                  {/* {error && (
                <div className="alert alert-danger mt-4" role="alert">
                  {error}
                </div>
              )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
