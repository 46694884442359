import React from "react";
import { Wheel } from "react-custom-roulette";


const Wheels = ({data,backgroundColor,textColors,outerBorderColor,fontSize}) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <Wheel  
          textColors={textColors}
          data={data}
          backgroundColors={backgroundColor}
          outerBorderColor={outerBorderColor}
          radiusLineColor={outerBorderColor}
          fontSize={fontSize}
        />
      </div>
    </>
  );
};

export default Wheels;