import { rootApi } from "../../api/rootApi";

export const schemeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createScheme: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scheme/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllScheme: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scheme?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchSchemeById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/scheme/${params.id}&limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateSchemeById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/scheme/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    deleteSchemeById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/scheme/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateSchemeMutation,
  useFetchAllSchemeMutation,
  useFetchSchemeByIdMutation,
  useUpdateSchemeByIdMutation,
  useDeleteSchemeByIdMutation,
} = schemeApi;
