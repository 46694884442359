import { rootApi } from "../../api/rootApi";

export const userPermissionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUserPermissions: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/userPermissions`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const { useGetAllUserPermissionsMutation } = userPermissionsApi;
