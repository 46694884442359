// const data = [
//   {
//     isActive: true,
//     columns: [
//       {
//         name: "User",
//         tasks: [
//           {
//             title: "Build UI for onboarding flow",
//           },
//         ],
//       },
//       {
//         name: "Program 1",
//         tasks: [
//           {
//             title: "Design settings and search pages",
//           },
//           {
//             title: "Add account management endpoints",
//           },
//           {
//             title: "Design onboarding flow",
//           },
//           {
//             title:
//               "Research pricing points of various competitors and trial different business models",
//           },
//         ],
//       },
//       {
//         name: "Program 2",
//         tasks: [
//           {
//             title: "Conduct 5 wireframe tests",
//           },
//           {
//             title: "Create wireframe prototype",
//           },
//           {
//             title: "Review results of usability tests and iterate",
//           },
//           {
//             title:
//               "Create paper prototypes and conduct 10 usability tests with potential customers",
//           },
//           {
//             title: "Market discovery",
//           },
//           {
//             title: "Competitor analysis",
//           },
//           {
//             title: "Research the market",
//           },
//         ],
//       },
//     ],
//   },
// ];

export const createBoardObject = (selectedProgram, allProgramOptions) => {
  // console.log("create Object ", selectedProgram, allProgramOptions);

  const boardObject = [
    {
      isActive: true,
      columns: [],
    },
  ];

  boardObject[0].columns.push( createColumsObject("User" , []));

  for (const i in allProgramOptions) {
    const program = allProgramOptions[i];
    if (selectedProgram.includes(program.name)) {
      boardObject[0].columns.push(program);
    }

    // boardObject[0].columns.push({
    //   name: program,
    //   tasks: [
    //     {
    //       title: "ABCD",
    //     },
    //   ],
    // });
  }

  // console.log("Board Object: " , boardObject)
  return boardObject;
};

export const createColumsObject = (name, options) => {
  const obj = {};
  obj.name = name;
  obj.tasks = [];
  for (const op in options) {
    obj.tasks.push({
      title: options[op].option_name,
    });
  }

  return obj;
};
