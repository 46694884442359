import { rootApi } from "../../api/rootApi";

export const redeemedGiftsStatusApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRedeemedGiftsStatus: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/redeemedGiftsStatus/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body,
        };
      },
    }),
    getRedeemedGiftsStatus: builder.query({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/redeemedGiftsStatus/`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),


      getRedeemedGiftsStatusForScheme: builder.query({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/redeemedGiftsStatus/scheme`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),

      getExtraRedeemedGiftsStatus: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/redeemedGiftsStatus/${params.user_type}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),


      updateSchemeRedeemedGiftsStatus: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/redeemedGiftsStatus/scheme`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body:params.body,
          };
        },
      }),
  }),
});

export const {
 useUpdateRedeemedGiftsStatusMutation,
 useGetRedeemedGiftsStatusForSchemeQuery,
 useUpdateSchemeRedeemedGiftsStatusMutation,
 useGetRedeemedGiftsStatusQuery,
 useGetExtraRedeemedGiftsStatusMutation
} = redeemedGiftsStatusApi;
