import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { toast } from "react-hot-toast";
import { useAddSmsTemplateForTenantMutation, useUpdateSmsTemplateForTenantMutation } from "../../../features/tenant/smsTemplate/smsTemplateApi";

const templateTypeArray = [
  {
    id: 1,
    type: "otp",
  },
  { id: 2, type: "registration request" },
  { id: 3, type: "profile completion" },
  { id: 4, type: "set mpin" },
  { id: 5, type: "redeem request" },
  { id: 6, type: "bonus points" },
  { id: 7, type: "birthday wishes" },
  { id: 8, type: "redemption reminders" },
  { id: 9, type: "scan_coupon points" },
  { id: 10, type: "complaint message" },
  { id: 11, type: "extra points" },
  { id: 12, type: "account status" },
  { id: 13, type: "point earned" },
  { id: 14, type: "gift redeem request" },
  { id: 15, type: "gift delivery status" },
  { id: 16, type: "tier upgrade" },
  { id: 17, type: "server under maintance" },
  { id: 18, type: "server maintance done" },
  { id: 19, type: "redemption-otp" },
  { id: 20, type: "forgot password" },
  { id: 21, type: "tax deduction" },
  { id: 22, type: "send credentials" },
  { id: 23, type: "open redemption window" },
  { id: 24, type: "static sms" },
  { id: 25, type: "delete account" },
];

const AddSMSTemplates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location",location);
  const [isUpdate, setIsUpdate] = useState(
    location?.state?.edit ? true : false
  );
  const [selectTemplateType, setTemplateType] = useState(0);
  const [inputTemplateId, setTemplateId] = useState(null);
  const [inputTemplateText, setTemplateText] = useState(null);
  const [inputSenderId, setSenderId] = useState(null);

  useEffect(()=>{
    if(isUpdate){
        let typeId =0;
        templateTypeArray.map((x)=>{
            if(x.type == location.state.data.type){
                typeId = Number(x.id)-1;
            }
        })
        setTemplateType(typeId);
        setTemplateId(location.state.data.template_id);
        setTemplateText(location.state.data.template_text);
        setSenderId(location.state.data.sender_id);
    }
  },[location])

  const [
    addSmsTemplateForTenant,
    {
      data: addSmsTemplateForTenantData,
      isError: addSmsTemplateForTenantIsError,
      error: addSmsTemplateForTenantError,
      isLoading: addSmsTemplateForTenantIsLoading,
    },
  ] = useAddSmsTemplateForTenantMutation();

  const [
    updateSmsTemplateForTenant,
    {
      data: updateSmsTemplateForTenantData,
      isError: updateSmsTemplateForTenantIsError,
      error: updateSmsTemplateForTenantError,
      isLoading: updateSmsTemplateForTenantIsLoading,
    },
  ] = useUpdateSmsTemplateForTenantMutation();

  const handleAddSmsTemplate = (e) => {
    e.preventDefault();

    if (inputTemplateId == null || inputTemplateId == "") {
      toast.error("please enter Template Id");
      return;
    }
    if (inputTemplateText == null || inputTemplateText == "") {
      toast.error("please enter Template Text");
      return;
    }
    if (inputSenderId == null || inputSenderId == "") {
      toast.error("please enter Sender Id ");
      return;
    }

    console.log("selectTemplateType", selectTemplateType);
    console.log("inputTemplateText", inputTemplateText);
    console.log("inputSenderId", inputSenderId);
    console.log("inputTemplateId", inputTemplateId);

    addSmsTemplateForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        template_id: inputTemplateId,
        template_text: inputTemplateText,
        account_id: Number(location.state.account_id),
        sender_id: inputSenderId,
        type: templateTypeArray[selectTemplateType].type,
      },
    });
  };

  useEffect(() => {
    if (addSmsTemplateForTenantData) {
      toast.dismiss();
      toast.success(`${addSmsTemplateForTenantData.message}`, {
        id: `addSmsTemplateForTenantData`,
      });
      navigate(-1);
    }
    if (addSmsTemplateForTenantError) {
      toast.dismiss();
      toast.error(`${addSmsTemplateForTenantError.data.message}`, {
        id: `addSmsTemplateForTenantError`,
      });
    }
  }, [addSmsTemplateForTenantData, addSmsTemplateForTenantError]);

  const handleUpdate = (e) => {
    e.preventDefault();
    e.preventDefault();

    if (inputTemplateId == null || inputTemplateId == "") {
      toast.error("please enter Template Id");
      return;
    }
    if (inputTemplateText == null || inputTemplateText == "") {
      toast.error("please enter Template Text");
      return;
    }
    if (inputSenderId == null || inputSenderId == "") {
      toast.error("please enter Sender Id ");
      return;
    }
    updateSmsTemplateForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id : location.state.data.id,
        body: {
          template_id: inputTemplateId,
          template_text: inputTemplateText,
        //   account_id: Number(location.state.account_id),
          sender_id: inputSenderId,
        //   type: templateTypeArray[selectTemplateType].type,
        },
    })
  };

  useEffect(() => {
    if (updateSmsTemplateForTenantData) {
      toast.dismiss();
      toast.success(`${updateSmsTemplateForTenantData.message}`, {
        id: `updateSmsTemplateForTenantData`,
      });
      navigate(-1);
    }
    if (updateSmsTemplateForTenantError) {
      toast.dismiss();
      toast.error(`${updateSmsTemplateForTenantError.data.message}`, {
        id: `updateSmsTemplateForTenantError`,
      });
    }
  }, [updateSmsTemplateForTenantData, updateSmsTemplateForTenantError]);


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}
            SMS Credential
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className="w-full flex justify-center space-x-3 ">
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-0">&nbsp;</label>
                  <select
                    type="text"
                    className="h-auto shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Add new UserType"
                    value={selectTemplateType}
                    onChange={(e) => setTemplateType(Number(e.target.value))}
                  >
                    {templateTypeArray &&
                      templateTypeArray.length > 0 &&
                      templateTypeArray.map((x) => {
                        if(isUpdate){
                            if(x.type == location.state.data.type){
                                return  (
                                    <option value={Number(x.id) - 1}>{x.type}</option>
                                  )
                            }
                            return ;
                        }else{
                            return (
                              <option value={Number(x.id) - 1}>{x.type}</option>
                            );

                        }
                      })}
                  </select></div>
                </div>
                <div className="mb-6 w-1/4">
                <div className="w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-0" for="name">Template Id </label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputTemplateId}
                      onChange={(e) => {
                        setTemplateId(e.target.value);
                      }}
                      type="number"
                    />
                  </div>
                </div>{" "}
                <div className="mb-6 w-1/4">
                <div className="w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-0" for="name">Template Text</label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputTemplateText}
                      onChange={(e) => {
                        setTemplateText(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>
                <div className="mb-6 w-1/4">
                <div className="w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-0" for="name">Sender Id </label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputSenderId}
                      onChange={(e) => {
                        setSenderId(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>
                
              </div>
              <div className="flex justify-center mt-2">
                  {isUpdate ? (
                    <button
                      type="update"
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={handleAddSmsTemplate}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Add
                    </button>
                  )}
                </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSMSTemplates;
