import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow, LoadScript } from "@react-google-maps/api";
import { useFetchallRegistrationsHistoryStateWiseMutation } from "../../../features/tenant/appUsers/appUsersApi";

const IndiaMapRegistrations = ({userTypeName}) => {
    // console.log(userTypeName,"YE RHA NA");
  const [
    fetchUserQrScanStateWiseHistory,
    { data: qrScanHistoryStateWiseData, error: qrScanHistoryStateWiseError },
  ] = useFetchallRegistrationsHistoryStateWiseMutation();

  const [map, setMap] = useState(null);

  const fetchallQrCodeStateWiseDataFunction = () => {
    fetchUserQrScanStateWiseHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    fetchallQrCodeStateWiseDataFunction();
  }, []);

  const stateCoordinates = {
    "Andhra Pradesh": { lat: 15.9129, lng: 79.7400 },
    "Arunachal Pradesh": { lat: 28.2180, lng: 94.7278 },
    "Assam": { lat: 26.2006, lng: 92.9376 },
    "Bihar": { lat: 25.0961, lng: 85.3131 },
    "Chhattisgarh": { lat: 21.2787, lng: 81.8661 },
    "Delhi": { lat: 28.6139, lng: 77.2090 },
    "Goa": { lat: 15.2993, lng: 74.1240 },
    "Gujarat": { lat: 22.2587, lng: 71.1924 },
    "Haryana": { lat: 29.0588, lng: 76.0856 },
    "Himachal Pradesh": { lat: 31.1048, lng: 77.1734 },
    "Jharkhand": { lat: 23.6102, lng: 85.2799 },
    "Karnataka": { lat: 15.3173, lng: 75.7139 },
    "Kerala": { lat: 10.8505, lng: 76.2711 },
    "Madhya Pradesh": { lat: 22.9734, lng: 78.6569 },
    "Maharashtra": { lat: 19.7515, lng: 75.7139 },
    "Manipur": { lat: 24.6637, lng: 93.9063 },
    "Meghalaya": { lat: 25.4670, lng: 91.3662 },
    "Mizoram": { lat: 23.1645, lng: 92.9376 },
    "Nagaland": { lat: 26.1584, lng: 94.5624 },
    "Odisha": { lat: 20.9517, lng: 85.0985 },
    "Punjab": { lat: 31.1471, lng: 75.3412 },
    "Rajasthan": { lat: 27.0238, lng: 74.2179 },
    "Sikkim": { lat: 27.5330, lng: 88.5122 },
    "Tamil Nadu": { lat: 11.1271, lng: 78.6569 },
    "Telangana": { lat: 18.1124, lng: 79.0193 },
    "Tripura": { lat: 23.9408, lng: 91.9882 },
    "Uttar Pradesh": { lat: 26.8467, lng: 80.9462 },
    "Uttarakhand": { lat: 30.0668, lng: 79.0193 },
    "West Bengal": { lat: 22.9868, lng: 87.8550 },
    "Jammu and Kashmir": { lat: 33.7782, lng: 76.5762 },
  };
  const updatedStateCoordinates = {};
for (const state in stateCoordinates) {
    updatedStateCoordinates[state.toUpperCase()] = stateCoordinates[state];
}
  
// console.log(updatedStateCoordinates)

const mapData = qrScanHistoryStateWiseData && updatedStateCoordinates
    ? qrScanHistoryStateWiseData.body[userTypeName].map(e => ({
        state: e.state,
        count: parseInt(e.count, 10),
        lat: updatedStateCoordinates[e.state]?.lat || 0,
        lng: updatedStateCoordinates[e.state]?.lng || 0,
      }))
    : [];

    // console.log(qrScanHistoryStateWiseData?.body[userTypeName])

    // console.log(mapData)

  const mapOptions = {
    center: { lat: 20.5937, lng: 78.9629 }, 
    zoom: 5,
  };

  const onMapLoad = (map) => {
    setMap(map);
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCfuLK5LlNWwr9Pvz1au8k-WSCuUYZL-6E"
      libraries={['places']}
    >
      <div style={{ height: "500px", position: "relative" }}>
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          zoom={mapOptions.zoom}
          center={mapOptions.center}
          onLoad={onMapLoad}
        >
          {mapData.map((data, index) => (
            <Marker
              key={index}
              position={{ lat: data.lat, lng: data.lng }}
              onClick={() => {
                // Handle marker click event if needed
              }}
            >
              <InfoWindow position={{ lat: data.lat, lng: data.lng }}>
                <div>
                  <strong>{data.state}</strong>
                  <br />
                  Total : {data.count}
                </div>
              </InfoWindow>
            </Marker>
          ))}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default IndiaMapRegistrations;