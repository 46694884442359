import React, { useEffect } from "react";
import { useGetCouponsMutation, useGetTokenMutation } from "../features/tenant/OzoneAPI/ozoneApi";

const NotFound = () => {
  // const [fetchAppUserByUserType, { data: appUsersData, error: appUsersError }] =
  // useGetCouponsMutation();


  //   const fetchFetchAllbanUnbanFunction = (row) => {
  //     fetchAppUserByUserType({
  //       token: sessionStorage.getItem("access_token"),
  //       tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //     });
  //   };
  

  //   useEffect(()=>{
  //     fetchFetchAllbanUnbanFunction()
  //   },[])
  

  return (
    <section className="flex items-center h-screen p-16 dark:bg-gray-900 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Sorry, we couldn't find this page.
          </p>
          <p className="mt-4 mb-8 dark:text-gray-400">
            But dont worry , check your url or contact our support.
          </p>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
