import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  useDeleteEmailCredentialMutation,
  useGetAllEmailCredentialMutation,
  useUpdateEmailCredentialMutation,
} from "../../../features/admin/emailMaster/emailCredentialsApiAdmin";
// import { tenant } from "../../utils/tenant";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

export default function EmailTemplate() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(-1);

  const [tableDataItems, setTableDataItems] = useState([]);

  const [
    fetchAllEmailCredentials,
    {
      data: emailCredentialData,
      isLoading: emailCredentialIsLoading,
      error: emailCredentialError,
    },
  ] = useGetAllEmailCredentialMutation();

  const [
    deleteEmailCredentials,
    {
      data: deleteEmailCredentialData,
      isLoading: deleteEmailCredentialIsLoading,
      error: deleteEmailCredentialError,
    },
  ] = useDeleteEmailCredentialMutation();

  const [
    updateEmailCredential,
    {
      data: updateEmailCredentialData,
      error: updateEmailCredentialError,
      isLoading: updateEmailCredentialisLoading,
    },
  ] = useUpdateEmailCredentialMutation();

  useMemo(() => {
    if (emailCredentialData && emailCredentialData.body && emailCredentialData.body.data) {
      (() => {
        console.log(emailCredentialData);
        const arr = [...emailCredentialData.body?.data];
        arr.sort((a, b) => a.id - b.id);
        setTableDataItems(arr);
      })();
    }else {
      setTableDataItems([])
    }

    if (emailCredentialError) {
      (() => {
        toast.error("Something went wrong", {
          id: "emailCredentialsError",
        });
      })();
    }
  }, [emailCredentialData, emailCredentialError]);

  useEffect(() => {
    fetchAllEmailCredentials({
      token: sessionStorage.getItem("access_token"),
    });
  }, []);

  useEffect(() => {
    if (updateEmailCredentialData) {
      toast.success("Status Updated", {
        id: "updateEmailCredentialSuccess",
      });
      fetchAllEmailCredentials({
        token: sessionStorage.getItem("access_token"),
      });
    }

    if (updateEmailCredentialError) {
      toast.error("Something went wrong", {
        id: "updateEmailCredentialError",
      });
    }
  }, [updateEmailCredentialData, updateEmailCredentialError]);

  useEffect(() => {
    if (deleteEmailCredentialData) {
      toast.success("Deleted Successfully", {
        id: "deleteEmailCredentialSuccess",
      });
      fetchAllEmailCredentials({
        token: sessionStorage.getItem("access_token"),
      });
    }

    if (deleteEmailCredentialError) {
      toast.error("Something went wrong", {
        id: "deleteEmailCredentialError",
      });
    }
  }, [deleteEmailCredentialData, deleteEmailCredentialError]);

  const handleDelete = (id) => {
    deleteEmailCredentials({
      token: sessionStorage.getItem("access_token"),
      id: id,
    });
  };

  const handleUpdateStatus = (r) => {
    console.log(r);

    // if (r.tenant_id != "0") {
    //   toast.error("Cannot Updated Status of Tenant Credentials");
    //   return;
    // }

    let status = "1";

    if (r.status === "1") {
      status = "2";
    } else {
      status = "1";
    }

    updateEmailCredential({
      token: sessionStorage.getItem("access_token"),
      id: r.id,
      data: {
        status: status,
      },
    });
  };

  console.log("object", showPassword);
  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "User Id ",
      selector: (row) => row.user_id,
      sortable: true,
    },
    {
      name: "Password",
      selector: (row) =>
        showPassword === row.id ? (
          <input type="text" value={row.password} readOnly />
        ) : (
          <input type="password" value={row.password} readOnly />
        ),
      width: "150px",
    },
    {
      name: "Belongs To ",
      selector: (row) =>
        row.tenant_id == "0" ? "Admin" : `Tenant ${row.tenant_id}`,
      width: "120px",
    },

    {
      name: "Status ",
      selector: (row) =>
        row.status == "1" ? (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            {" "}
            Active
          </button>
        ) : (
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            InActive{" "}
          </button>
        ),
      width: "120px",
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      selector: (row) =>
        showPassword === row.id ? (
          <button div onClick={() => setShowPassword(-1)}>
            <AiFillEye />
          </button>
        ) : (
          <div div onClick={() => setShowPassword(row.id)}>
            <AiFillEyeInvisible />
          </div>
        ),
      width: "70px",
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => navigate("/viewEmailTemplate", { state: r })}
        >
          View Templates
        </button>
      ),
      width: "130px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => navigate("/addEmailCredential", { state: r })}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDelete(r.id)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  if (emailCredentialIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="">
      <div className="page-content">
        <div className="card p-4">
          <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 className="mb-0 card-title">Welcome to Email Master</h4>
            </div>
            <div className="d-flex align-items-center flex-wrap text-nowrap">
              <button
                type="button"
                className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                onClick={() => navigate("/addEmailCredential")}
              >
                Add Email Credentials
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <DataTable
                title="Email Credentials"
                data={tableDataItems}
                columns={columns}
                //selectableRows
              />
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
