import React, { useEffect, useMemo, useState } from "react";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import { useCountAllbookAppointmentMutation, useFetchAllbookAppointmentMutation } from "../../../features/tenant/bookAppointment/bookAppointmentApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";

const BookAppointment = () => {
  const { zone_id: zoneId, zone_name: zoneName } = getLoginUserData();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [prevPage, setPrevPage] = useState(0);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState( {
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const [showImageModal, setShowImageModal] = useState(false);
  const initialRequest = {
    user_type_id: "",
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);

  const [
    fetchAllbookAppointment,
    {
      data: allbookAppointmentData,
      isLoading: allbookAppointmentIsLoading,
      isError: allbookAppointmentIsError,
      error: allbookAppointmentError,
    },
  ] = useFetchAllbookAppointmentMutation();

  const [
    countAllbookAppointment,
    {
      data: countallbookAppointmentData,
      isLoading: countallbookAppointmentIsLoading,
      isError: countallbookAppointmentIsError,
      error: countallbookAppointmentError,
    },
  ] = useCountAllbookAppointmentMutation();

  const [
    fetchAllbookAppointmentCsv,
    {
      data: allbookAppointmentCsvData,
      isLoading: allbookAppointmentCsvIsLoading,
      isError: allbookAppointmentCsvIsError,
      error: allbookAppointmentCsvError,
    },
  ] = useFetchAllbookAppointmentMutation();

  const fetchAllBankDetailsFun = () => {
    fetchAllbookAppointment({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const countAllBankDetailsFun = () => {
    countAllbookAppointment({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchAllBankDetailsCsvFun = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAllbookAppointmentCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if(allbookAppointmentCsvData && allbookAppointmentCsvData?.body?.BookAppointmentList){
      setCsvData(allbookAppointmentCsvData?.body?.BookAppointmentList);
    }

    if (allbookAppointmentCsvError) {
      setCsvData(null);
    }
  }, [allbookAppointmentCsvData , allbookAppointmentCsvError])

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
    },

    {
      name: "Title",
      cell: (row) => row?.title,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Desc",
      cell: (row) => row?.description,
      sortable: true,
      minWidth: "120px",
    },
    {
      name: "Remarks",
      cell: (row) => row?.remarks,
      sortable: true,
      minWidth: "120px",
    },
    // {
    //   name: "Image",
    //   cell: (row) => (
    //     <div className="w-12 h-12 my-2 rounded-full">
    //       {row.images && row.images[0] ? ( // Check if row.images exists and has at least one element
    //         <img
    //           src={ row.images[0]}
    //           alt={row.images[0]}
    //           className="rounded-full"
    //           onClick={() =>
    //             setShowImageModal(
    //                row.images[0]
    //             )
    //           }
    //         />
    //       ) : (
    //         <span>No Image</span> // Display a message or placeholder for no image
    //       )}
    //     </div>
    //   ),
    // },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      minWidth: "200px",
    },
  ];

  useEffect(() => {
    fetchAllBankDetailsFun();
    countAllBankDetailsFun();
    // fetchAllBankDetailsCsvFun();
  }, [limit, offset, userTypesArray]);
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };
  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Enquiry
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="flex justify-between items-start">
            {/* {allbookAppointmentCsvData &&
              allbookAppointmentCsvData?.body?.BookAppointmentList && (
                <ExportCSV
                  data={
                    allbookAppointmentCsvData &&
                    allbookAppointmentCsvData?.body?.BookAppointmentList
                  }
                  columnsToExport={[
                    "user_type",
                    "name",
                    "mobile",
                    "title",
                    "description",
                    "remarks",
                  ]}
                />
              )} */}

<div className="mb-2">
                  <CustomExport fetchData={fetchAllBankDetailsCsvFun} data={csvData} defaultColumnExport={[
                       "user_type",
                       "name",
                       "mobile",
                       "title",
                       "description",
                       "remarks",
                  ]} 
                    filename="enquiry"
                    />
                </div>
          </div>
          {/* <div className="">
            <button
              type="submit"
              className="btn btn-outline-primary clear-both px-3 py-2.5  mb-2"
            >
              Export
            </button>
          </div> */}
          <div className=" bg-white shadow rounded-lg ">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 ">
                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                    placeholder=""
                    value={request.startDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          startDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.endDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          endDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">User Types</label>
                    <div className="relative searchin">  {appUsers && appUsers.length > 0 && (
                      <SelectComponent
                        className="capitalize"
                        //title={"User Types"}
                        selected={userTypesArray}
                        setSelected={setUserTypesArray}
                        dataArr={appUsers}
                      />
                    )} </div>
                  </div>
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    Mobile No
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                    placeholder="Enter Mobile No"
                    value={request.mobile}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          mobile: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    &nbsp;
                  </label>
                  <button
                    type="submit"
                    className="btn btn-outline-primary clear-both  pdbtn_all w-full"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className=" p-3">
              <DataTableComponent
                columns={columns}
                data={
                  allbookAppointmentData &&
                  allbookAppointmentData.body.BookAppointmentList
                }
                totalRows={
                  countallbookAppointmentData &&
                  countallbookAppointmentData.body.total_book_appointment
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
      </div>
    </div>
  );
};

export default BookAppointment;
