import React from 'react'

const TDS = () => {
  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
    <div className="w-full ">
      <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
     TDS
        {/* <LinksComponent /> */}
      </div>

      <div className='p-3'>
        <div className=''>
          <button type='submit' className='btn btn-outline-primary clear-both px-3 py-2.5  mb-2'>Export</button>
        </div>
        <div className=' bg-white shadow rounded-lg '>

  <div className='border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3'>
  <div className='flex  space-x-2 '>
<div className='w-52 '>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>From Date</label>
<input type='date' className='w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 h-auto' placeholder=''/>
</div>

<div className='w-52 '>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>End Date</label>
<input type='date' className='w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 h-auto' placeholder=''/>
</div>

<div className='w-52 '>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>User Type</label>
<select className='w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 h-auto'>
  <option>Electrician</option>
  <option>Retailer</option>
</select>
</div>

<div className='w-52 '>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>Mobile No</label>
<input type='text' className='w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 h-auto' placeholder='Enter Mobile No'/>
</div>

<div className='w-52 '>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>&nbsp;</label>
<button type='submit' className='btn btn-outline-primary clear-both  p-2.5 w-full'>Submit</button>
</div>


  </div>
  </div>

<div className=' p-3'>

<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                User Type
                </th>
                <th scope="col" class="px-6 py-3">
                Name
                </th>
                <th scope="col" class="px-6 py-3">
                Mobile No
                </th>
                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                Transaction Amount,
                </th>

                <th scope="col" class="px-6 py-3">
                Transaction Date
                </th>
                <th scope="col" class="px-6 py-3">
                TSD Amount
                </th>
              

            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   Retailer
                </th>
                <td class="px-6 py-2">
                    Amit
                </td>
                <td class="px-6 py-2">
                    9999999999
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                   INR 20000
                </td>
                <td class="px-6 py-2">
               25-Oct-2023
                </td>

                <td class="px-6 py-2">
                INR 2000
                </td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   Retailer
                </th>
                <td class="px-6 py-2">
                    Amit
                </td>
                <td class="px-6 py-2">
                    9999999999
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                   INR 20000
                </td>
                <td class="px-6 py-2">
               25-Oct-2023
                </td>

                <td class="px-6 py-2">
                INR 2000
                </td>
            </tr>

        </tbody>
    </table>
</div>

  

</div>





        </div>
      </div>
      </div></div>
  )
}

export default TDS