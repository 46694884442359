import { rootApi } from "../../api/rootApi";

export const schemePointEntriesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({


    fetchAllSchemePointEntries: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scheme-point-entries`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllSchemePointEntriesCount: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scheme-point-entries/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),


    fetchAllSchemePointSummary: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scheme-point-entries/summary`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

  }),
});

export const {
    useFetchAllSchemePointEntriesMutation , 
    useFetchAllSchemePointEntriesCountMutation ,
    useFetchAllSchemePointSummaryMutation,
} = schemePointEntriesApi;
