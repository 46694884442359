import { rootApi } from "../../api/rootApi";


export const formTemplateApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        fetchFormTemplate: builder.mutation({
            query: (token) => {
                return {
                method: 'GET',
                url: '/admin/formtemplate',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
            }}
        }),

        deleteFormTemplate: builder.mutation({
            query: (params) => {
                return {
                method: 'DELETE',
                url: `/admin/formtemplate/${params.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                  },
            }}
        }),
        
    })

});
export const { useFetchFormTemplateMutation , useDeleteFormTemplateMutation } = formTemplateApi;