import { rootApi } from "../../api/rootApi";

export const salesBoosterApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createSalesBooster: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/salesBooster/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllSalesBooster: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/salesBooster?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchSalesBoosterById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/salesBooster/${params.id}&limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateSalesBoosterById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/salesBooster/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteSalesBoosterById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/salesBooster/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateSalesBoosterMutation,
  useFetchAllSalesBoosterMutation,
  useFetchSalesBoosterByIdMutation,
  useUpdateSalesBoosterByIdMutation,
  useDeleteSalesBoosterByIdMutation,
} = salesBoosterApi;
