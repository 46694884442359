import { rootApi } from "../../api/rootApi";

export const pushNotificationTypeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createPushNotificationType: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/push-notification/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllPushNotificationType: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/push-notification`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchAllPushNotificationDumpList: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/push-notification/list`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body : params.body
        };
      },
    }),


    fetchAllPushNotificationDumpListByAppUserId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/push-notification/app-user/${params.app_user_id}?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchPushNotificationTypeById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/push-notification/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    // updateSchemeById: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: "PUT",
    //       url: `/tenant/push-notification/${params.id}`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + params.token,
    //         slug: params.tenant_id,
    //       },
    //       body: params.body,
    //     };
    //   },
    // }),

    deletePushNotificationTypeById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/push-notification/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updatePushNotificationTypeById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/push-notification/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body : params.body
        };
      },
    }),

    sendNotifications: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/push-notification/send`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body: params.body,
          };
        },
      }),


      sendFirebaseNotifications: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/push-notification/v2-send`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body: params.body,
          };
        },
      }),
  }),
});

export const {
  useCreatePushNotificationTypeMutation , useFetchAllPushNotificationTypeMutation , useFetchPushNotificationTypeByIdMutation , useDeletePushNotificationTypeByIdMutation , useSendNotificationsMutation , useFetchAllPushNotificationDumpListMutation,useUpdatePushNotificationTypeByIdMutation , useSendFirebaseNotificationsMutation
} = pushNotificationTypeApi;
