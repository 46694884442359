import { rootApi } from "../../api/rootApi";

export const qrCodeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllQrCode: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchAllQrCodeWithoutGeneration: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr/qrList`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchQrByBatchRunningCode: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr/qrBatchRunningCode`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    
    fetchAllQrCodeWithoutGenerationCount: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr/qrListCount`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchAllQrCodeCountMonthWise: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr/month`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchQrCodeByGenerationID: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qr/${params.generationId}?limit=${params.limit}&offset=${params.offset}`,
          // url: `/tenant/qr/${params.generationId}?limit=5&offset=1`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchQrCodeByGenerationIDMut: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qr/${params.generationId}?limit=${params.limit}&offset=${params.offset}`,
          // url: `/tenant/qr/${params.generationId}?limit=5&offset=1`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchAllQrCodeswithScanned: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qr/total`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addQrCode: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr/generate`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    bulkUploadQrCode: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr/bulk-upload`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    // updateBatch: builder.mutation({
    //     query: (params) => {
    //       return {
    //         method: "PUT",
    //         url: `/tenant/batch/${params.batchId}`,
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: "Bearer " + params.token,
    //           slug: params.tenant_id,
    //         },
    //         body: params.body,
    //       };
    //     },
    // }),
    deleteQrCodeByBatchId: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/qr/${params.batchId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    groupedQrCodeByProductCodeAndGenerationId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qr/grouped/${params.generationId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),


    updateQrCodeByProductCodeAndGenerationId: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/qr/grouped/${params.generationId}?product_code=${params.productCode}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    bulkUpdateScannedQrCode: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/qr/bulk`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    getGenerationVsScanningStats: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/qr/stats/generation-scanning`,
          headers: {
            slug: params.tenant_id,
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
          body:params.body
        };
      },
    }),

    addQrCodeForCg: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/cg/generate`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    bulkUploadQrCodeForCg: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/cg/bulk-upload`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useFetchAllQrCodeMutation,
  useFetchAllQrCodeWithoutGenerationMutation,
  useFetchAllQrCodeWithoutGenerationCountMutation,
  useFetchAllQrCodeCountMonthWiseMutation,
  useFetchQrCodeByGenerationIDQuery,
  useFetchQrCodeByGenerationIDMutMutation,
  //  useFetchQrCodeByProductCodeQuery,
  useFetchAllQrCodeswithScannedMutation,
  useAddQrCodeMutation,
  useDeleteQrCodeByBatchIdMutation,
  useBulkUploadQrCodeMutation,
  useGroupedQrCodeByProductCodeAndGenerationIdMutation,
  useUpdateQrCodeByProductCodeAndGenerationIdMutation,
  useBulkUpdateScannedQrCodeMutation,
  useGetGenerationVsScanningStatsMutation,
  useFetchQrByBatchRunningCodeMutation,
  useAddQrCodeForCgMutation,
  useBulkUploadQrCodeForCgMutation
} = qrCodeApi;
