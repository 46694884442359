import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetchVendorsQuery } from "../../../features/admin/vendor/vendorApi";
import { useNavigate } from "react-router-dom";
import {
  useAddFirebaseSetupMutation,
} from "../../../features/admin/firebaseSetup/firebaseSetupApi";
const AddTenantFirebaseSetup = () => {
  const [firebaseKey, setFirebaseKey] = useState(null);

  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const navigate = useNavigate();

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    vendorsList.forEach(function (data) {
      if (data.company_id == event.target.value) {
        setSelectedVendorData(data);
      }
    });
  };

  const {
    data: vendorsData,
    error: vendorsError,
  } = useFetchVendorsQuery(sessionStorage.getItem("access_token")) || {};

  const [
    AddTenantFirebaseSetup,
    { data: AddFirebaseSetupData, error: AddFirebaseSetupError },
  ] = useAddFirebaseSetupMutation();

  const addTenantFirebaseSetupFun = () => {
    if (!selectedVendorData) {
      toast.error("Please select a vendor");
      return;
    }
    var body = {
      tenant_id: selectedVendorData.tenant_id,
      tenant_name: selectedVendorData.name,
      key: firebaseKey,
    };
    AddTenantFirebaseSetup({
      token: sessionStorage.getItem("access_token"),
      body: body,
    });
  };

  useMemo(() => {
    if (vendorsData) {
      (() => {
        setVendorsList(vendorsData.body);
      })();
    }
    if (vendorsError) {
      toast.error(vendorsError.error, {
        id: "vendorsError",
      });
    }
  }, [vendorsData, vendorsError]);

  useEffect(() => {
    if (AddFirebaseSetupData) {
      toast.success("Firebase Key Added Successfully", {
        id: "dashboardSuccess",
      });
      setTimeout(() => {
          navigate(-1);
      }, 300);
    }
    if (AddFirebaseSetupError) {
      if (AddFirebaseSetupError.status == 409) {
        toast.error("Firebase Key already Exists", {
          id: "dashboardError",
        });
      } else {
        toast.error("Error in Adding Firebase Key", {
          id: "dashboardError",
        });
      }
    }
  }, [AddFirebaseSetupData, AddFirebaseSetupError]);

  return (
    <div className="page-content">
      <div className="card p-4">
        <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="card-title">Add Firebase Key</h4>
          </div>
        </div>
        <div className="cmxform " id="signupForm">
          <div className="">
            <div className="w-50 my-2 ">
              <label className="text-capitalize font-weight-bold">
                Choose Client
              </label>
              <select
                className="js-example-basic-single w-100 rounded-lg shadow-sm shadow-black p-2.5 h-auto"
                value={selectedVendor}
                onChange={handleVendorChange}
              >
                <option
                  className="w-100 rounded-lg shadow-sm shadow-black p-2.5 h-auto"
                  value={0}
                >
                  Select Client
                </option>
                {vendorsList &&
                  vendorsList.map((vendor) => (
                    <option
                      className="w-100 rounded-lg shadow-sm shadow-black p-2.5 h-auto"
                      key={vendor.id}
                      value={vendor.company_id}
                    >
                      {vendor.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="w-50 my-2">
              <label className="my-1 font-weight-bold">Firebase Key </label>

              <textarea
                className="form-control"
                placeholder="Firebase Key"
                value={firebaseKey}
                onChange={(e) => setFirebaseKey(e.target.value)}
                rows={4}
              />
            </div>
          </div>
        </div>

        <button
          className="btn btn-outline-primary btn-icon-text mt-3 mb-2 mb-md-0 w-24 py-2.5"
          onClick={() => addTenantFirebaseSetupFun()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddTenantFirebaseSetup;
