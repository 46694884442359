import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ManageMembership.css";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import {
  useCreateMembershipDetailsMutation,
  useUpdateMembershipDetailsMutation,
} from "../../../features/tenant/membershipDetails/membershipDetailsApi";
import { getVendorName } from "../../../utils/getVendorName";
import { useFetchMembershipByIdMutation } from "../../../features/tenant/membership/membershipApi";

const dataObj = {
  rangeStart: 0,
  rangeEnd: 100,
  perMonth: 0,
  pointsMultiplier: 0,
  giftDiscount: 0,
  cashback: false,
  cash_benefit: 0,
};

function convertToTierFormat(dataArray, type = 1) {
  const result = {};

  dataArray.sort((a, b) => b.tier.rank - a.tier.rank);
  console.log("dataArray", dataArray);

  if (type == 0) {
    dataArray.forEach((entry) => {
      result[entry.tier.name] = {
        id: entry.id,
        rangeType: entry.range_type,
        rangeStart: entry.range_start,
        rangeEnd: entry.range_end,
        perMonth: entry.per_month || 0,
        pointsMultiplier: parseFloat(entry.points),
        giftDiscount: parseFloat(entry.gift_discount),
        cashback: entry.cashback,
        cash_benefit: parseFloat(entry.cash_benefit),
      };
    });
  } else {
    dataArray.forEach((entry) => {
      result[entry.tier.name] = {
        rangeType: entry.range_type,
        rangeStart: entry.range_start,
        rangeEnd: entry.range_end,
        perMonth: entry.perMonth || 0,
        pointsMultiplier: parseFloat(entry.points),
        giftDiscount: parseFloat(entry.gift_discount),
        cashback: entry.cashback,
        cash_benefit: parseFloat(entry.cash_benefit),
      };
    });
  }

  console.log("result: ", result);

  return result;
}

function convertToDesiredFormat(obj, type = 1) {
  const result = [];

  if (type == 0) {
    let i = Object.keys(obj).length;

    console.log(obj);

    for (let key in obj) {
      const tier = obj[key];
      result.push({
        id: tier.id,
        range_start: tier.rangeStart,
        range_end: tier.rangeEnd,
        per_month: tier.perMonth,
        points: tier.pointsMultiplier / 1,
        gift_discount: tier.giftDiscount / 1,
        cashback: tier.cashback,
        cash_benefit: tier.cash_benefit,
      });
    }
  } else {
    let i = Object.keys(obj).length;
    for (let key in obj) {
      const tier = obj[key];
      result.push({
        tier: { rank: i--, name: key },
        range_start: tier.rangeStart,
        range_end: tier.rangeEnd,
        per_month: tier.perMonth,
        points: tier.pointsMultiplier / 1,
        gift_discount: tier.giftDiscount / 1,
        cashback: tier.cashback,
        cash_benefit: tier.cash_benefit,
      });
    }
  }

  return result;
}

const ManageMembership = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [rangeType, setRangeType] = useState(null);
  const [tiers, setTiers] = useState([]);
  const [data, setData] = useState(null);

  const [isUpdate, setIsUpdate] = useState(false);

  const [
    addMembershipDetails,
    {
      data: addMembershipDetailsData,
      error: addMembershipDetailsError,
      isLoading: addMembershipDetailsisLoading,
    },
  ] = useCreateMembershipDetailsMutation();

  const [
    updateMembershipDetails,
    { data: updateMembershipDetailsData, error: updateMembershipDetailsError },
  ] = useUpdateMembershipDetailsMutation();

  const [
    fetchMembershipById,
    { data: fetchMembershipData, error: fetchMembershipError },
  ] = useFetchMembershipByIdMutation();

  useEffect(() => {
    let rangeType = "Purchase";
    console.log(location.state);

    if (location.state) {
      fetchMembershipById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: location.state.id,
      });

      if (location.state?.range_type == 1) {
        rangeType = "Purchase";
      } 
      else if (location.state?.range_type == 2) {
        rangeType = "Scan";
      } 
      else if (location.state?.range_type == 3) {
        rangeType = "QrMrp";
      }
      else if (location.state?.range_type == 4) {
        rangeType = "Points";
      }
      setRangeType(rangeType);
      setTiers(location.state?.tiers);

      let finalObj = {};
      const arr = location.state.tiers.reverse();
      arr.map((tier) => {
        finalObj[tier.name] = {
          rangeType: rangeType,
          ...dataObj,
        };
      });

      console.log(finalObj);
      setData(finalObj);
    }
  }, [location.state]);

  useEffect(() => {
    if (fetchMembershipData) {
      console.log("fetch membership data", fetchMembershipData);
      if (fetchMembershipData.body.length > 1) {
        setIsUpdate(true);
        setData(convertToTierFormat([...fetchMembershipData.body], 0));
      }
    }

    if (fetchMembershipError) {
      console.log("fetch membership error", fetchMembershipError);
      toast("Something went wrong , Please refresh the page and try again", {
        id: "errroRefresh",
      });
    }
  }, [fetchMembershipData, fetchMembershipError]);

  function isValidRanges(obj) {
    let previousRangeEnd = -Infinity;

    for (let key in obj) {
      let category = obj[key];

      if (category.rangeStart < 0 || category.rangeEnd < 0) {
        console.error(`In ${key}, Start/End values cannot be negative.`);
        toast.error(`In ${key}, Start/End values cannot be negative.`, {
          id: "noNegetiveValues",
        });
        return false;
      }

      if (Number(category.rangeStart) >= Number(category.rangeEnd)) {
        console.error(`In ${key}, Start Value is not smaller than End Value.`);
        toast.error(`In ${key}, Start Value is not smaller than End Value.`, {
          id: "inValidRangeStart",
        });
        return false;
      }

      console.log(
        "catregoryy",
        category,
        Number(category.rangeStart),
        Number(category.rangeEnd),
        previousRangeEnd,
      );

      if (Number(category.rangeStart) <= Number(previousRangeEnd)) {
        console.error(
          `In ${key}, Start Value is not greater than the End Value of the previous category.`,
        );
        toast.error(
          `In ${key}, Start Value is not greater than the End Value of the previous category.`,
          {
            id: "inValidRangeEnd",
          },
        );
        return false;
      }

      if (
        category.pointsMultiplier / 1 < 0 ||
        category.pointsMultiplier / 1 > 100
      ) {
        console.error(
          `In ${key}, pointsMultiplier is not in the range [0, 100].`,
        );
        toast.error(
          `In ${key}, Points Multiplier is not in the range [0, 100].`,
          {
            id: "pointsMultiplierError",
          },
        );
        return false;
      }

      if (category.cash_benefit / 1 < 0 || category.cash_benefit / 1 > 100) {
        console.error(`In ${key}, Cash Benefit is not in the range [0, 100].`);
        toast.error(`In ${key}, Cash Benefit is not in the range [0, 100].`, {
          id: "cashBenefitError",
        });
        return false;
      }

      if (category.giftDiscount / 1 < 0 || category.giftDiscount / 1 > 100) {
        console.error(`In ${key}, giftDiscount is not in the range [0, 100].`);
        toast.error(`In ${key}, Gift Discount is not in the range [0, 100].`, {
          id: "giftDiscountError",
        });
        return false;
      }

      previousRangeEnd = category.rangeEnd;
      console.log("Updated previous range end", previousRangeEnd);
    }
    return true;
  }

  const handleOnValueChange = (value, tier, key) => {
    console.log(value, tier, key);
    const newObj = { ...data };
    if (key == "cashback") {
      data[tier][key] = value;
    } else {
      console.log("value: ", value, data[tier][key]);
      data[tier][key] = value;
    }
    setData(newObj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(data);

    if (isValidRanges(data)) {
      const res = convertToDesiredFormat(data);

      addMembershipDetails({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          membership_id: location.state.id,
          data: res,
          qty: res.length,
        },
      });
    } else {
      return;
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (isValidRanges(data)) {
      const res = convertToDesiredFormat(data, 0);
      let reqData = [];
      res.map((each) => {
        delete each.created_at;
        delete each.created_by_id;
        delete each.created_by_name;
        delete each.updated_at;
        delete each.updated_by_id;
        delete each.updated_by_name;
        delete each.status;
        delete each.tier;

        console.log("each", each);
        reqData.push(each);
      });

      console.log("reqData", reqData);

      updateMembershipDetails({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          membership_id: location.state.id,
          batch: reqData,
        },
      });
    } else {
      return;
    }
  };

  useEffect(() => {
    if (addMembershipDetailsData) {
      toast.success("Membership created successfully", {
        id: "membershipDetailsCreated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/membership`);
      }, 400);
    }

    if (addMembershipDetailsError) {
      if (addMembershipDetailsError.status == 409) {
        toast.error("Membership already exists for this user type", {
          id: "addMembershipErrorAlreadyExists",
        });
      } else {
        toast.error("Something went wrong", {
          id: "addMembershipDetailsError",
        });
      }
      return;
    }
  }, [addMembershipDetailsData, addMembershipDetailsError]);

  useEffect(() => {
    if (updateMembershipDetailsData) {
      toast.success("Membership Updated successfully", {
        id: "membershipDetailsUpdated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/membership`);
      }, 400);
    }

    if (updateMembershipDetailsError) {
      toast.error("Something went wrong", {
        id: "updateMembershipDetailsError",
      });

      return;
    }
  }, [updateMembershipDetailsData, updateMembershipDetailsError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Manage Membership
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className=" w-full bg-white shadow rounded-lg">
              <div class="flex justify-between  bg-gray-100 shadow-md rounded-t-lg overflow-hidden p-3  mb-3">
                <div class="capitalize">
                  <h1 class="text-gray-900 font-medium text-lg">
                    User type : {location.state?.user_type}
                  </h1>
                  <h1 class="text-gray-900 font-medium text-lg">
                    Range Type :{" "}
                    {rangeType}
                    {/* {location.state?.range_type == 1 ? "Purchase" : "Scan"} */}
                  </h1>
                </div>

                <div className="">
                  <span className="flex space-x-3">
                    {location.state.tiers.map((each) => (
                      <p class="mt-2 text-white w-24 text-center rounded-lg p-2.5 bg-blue-700 text-sm font-md">
                        {" "}
                        {each.name}{" "}
                      </p>
                    ))}
                  </span>
                </div>
              </div>
              <div className=" flex w-full flex-col justify-center align-middle rounded-lg mb-4">
                <div className="w-full rounded-lg">
                  <div className="rounded-lg w-full overflow-x-scroll flex flex-col justify-start items-start space-y-2">
                    <div class="w-full h-full rounded-lg ">
                      <table class="w-full h-full rounded-lg">
                        <thead className=" bg-blue-600 rounded-lg text-white w-full border-b border-gray-300">
                          <tr class=" w-full h-14">
                            <th class=" cursor-pointer text-lg font-bold bg-gray-100 text-black  w-3/12">
                              {/* <div class="flex w-full h-full items-center justify-evenly text-center">
                                <div className="w-1/2">Features ⬇️ </div>
                                <div className="w-1/2">Tiers ➡️ </div>
                              </div> */}
                            </th>

                            <td className="w-full h-full flex justify-evenly items-center ">
                              {tiers.map((tier) => (
                                <th class=" w-full h-full border-r border-gray-300 cursor-pointer text-lg font-bold">
                                  <div class="flex h-full items-center justify-center ">
                                    {tier.name}
                                  </div>
                                </th>
                              ))}
                            </td>
                          </tr>
                        </thead>
                        <tbody className="w-full min-h-max">
                          <tr class="text-center w-full h-24 border-b border-gray-300">
                            <td class="font-bold text-base capitalize bg-gray-100 w-3/12">
                              {rangeType} Slab : Start - End
                            </td>

                            <td className="w-full h-full bg-white flex justify-evenly items-center ">
                              {tiers.map((tier) => (
                                <td class="flex px-2 justify-center border-r border-gray-300 h-full items-center space-x-2 w-full ">
                                  <input
                                    type="number"
                                    class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block py-3 text-center w-24 "
                                    value={data[tier.name].rangeStart}
                                    onChange={(e) =>
                                      handleOnValueChange(
                                        e.target.value,
                                        tier.name,
                                        "rangeStart",
                                      )
                                    }
                                  />
                                  <input
                                    type="number"
                                    class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block py-3 text-center  w-24"
                                    value={data[tier.name].rangeEnd}
                                    onChange={(e) =>
                                      handleOnValueChange(
                                        e.target.value,
                                        tier.name,
                                        "rangeEnd",
                                      )
                                    }
                                  />
                                </td>
                              ))}
                            </td>
                          </tr>

                          <tr class=" w-full h-24 text-center border-b border-gray-300">
                            <td class="font-bold text-base capitalize bg-gray-100 w-3/12">
                              {rangeType}/month
                            </td>

                            <td className="w-full h-full bg-white flex  justify-evenly items-center ">
                              {tiers.map((tier) => (
                                <td class=" flex justify-center items-center w-full border-r border-gray-300 h-full ">
                                  <input
                                    type="number"
                                    class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-3 px-4 text-center max-w-lg "
                                    value={data[tier.name].perMonth}
                                    onChange={(e) =>
                                      handleOnValueChange(
                                        e.target.value,
                                        tier.name,
                                        "perMonth",
                                      )
                                    }
                                  />
                                </td>
                              ))}
                            </td>
                          </tr>

                          <tr class=" w-full h-24 text-center border-b border-gray-300">
                            <td class="  font-bold text-base capitalize bg-gray-100 w-3/12">
                              Points multiplier %
                            </td>

                            <td className="w-full h-full bg-white flex  justify-evenly items-center ">
                              {tiers.map((tier) => (
                                <td class="    flex justify-center items-center  border-r border-gray-300 h-full w-full ">
                                  <input
                                    type="text"
                                    class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-3 px-4 text-center max-w-lg"
                                    value={data[tier.name].pointsMultiplier}
                                    onChange={(e) => {
                                      const amount = e.target.value;
                                      if (
                                        !amount ||
                                        amount.match(/^\d{1,}(\.\d{0,4})?$/)
                                      ) {
                                        // console.log(amount);
                                        handleOnValueChange(
                                          e.target.value,
                                          tier.name,
                                          "pointsMultiplier",
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              ))}
                            </td>
                          </tr>

                          <tr class=" w-full h-24 text-center border-b border-gray-300">
                            <td class="  font-bold text-base capitalize bg-gray-100 w-3/12">
                              Gift discount %
                            </td>
                            <td className="w-full h-full bg-white  flex justify-evenly items-center ">
                              {tiers.map((tier) => (
                                <td class="  flex justify-center  border-r border-gray-300 h-full items-center w-full ">
                                  <input
                                    type="text"
                                    class="shadow-sm  bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-3 px-4 text-center max-w-lg"
                                    value={data[tier.name].giftDiscount}
                                    onChange={(e) => {
                                      const amount = e.target.value;
                                      if (
                                        !amount ||
                                        amount.match(/^\d{1,}(\.\d{0,4})?$/)
                                      ) {
                                        // console.log(amount);
                                        handleOnValueChange(
                                          e.target.value,
                                          tier.name,
                                          "giftDiscount",
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              ))}
                            </td>
                          </tr>

                          <tr class=" w-full h-24 text-center border-b border-gray-300">
                            <td class="  font-bold text-base capitalize bg-gray-100 w-3/12">
                              Cashback Option
                            </td>
                            <td className="w-full h-full bg-white  flex justify-evenly items-center ">
                              {tiers.map((tier) => (
                                <td class="  flex justify-center items-center w-full  border-r border-gray-300 h-full ">
                                  {/* <input
                                    type="checkbox"
                                    class=" bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24"
                                    value={data[tier.name].cashback}
                                    checked={data[tier.name].cashback}
                                    onChange={(e) =>
                                      handleOnValueChange(
                                        e.target.value,
                                        tier.name,
                                        "cashback",
                                      )
                                    }
                                  /> */}

                                  <div className="flex justify-center items-center ">
                                    <div
                                      className="flex w-full justify-center items-center"
                                      onClick={(e) =>
                                        handleOnValueChange(
                                          !data[tier.name].cashback,
                                          tier.name,
                                          "cashback",
                                        )
                                      }
                                    >
                                      {data[tier.name].cashback ? (
                                        <div className=" flex text-lg justify-center items-center">
                                          <BsCheckCircleFill className="w-3.5 mr-2" />
                                          Yes
                                        </div>
                                      ) : (
                                        <div className="flex text-lg justify-center items-center">
                                          <BsCircle className="w-3.5 mr-2" />
                                          No
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              ))}
                            </td>
                          </tr>

                          <tr class=" w-full h-24 text-center border-b border-gray-300">
                            <td class="  font-bold text-base capitalize bg-gray-100 w-3/12">
                              Cash Benefit
                            </td>

                            <td className="w-full h-full bg-white flex  justify-evenly items-center ">
                              {tiers.map((tier) => (
                                <td class="    flex justify-center items-center  border-r border-gray-300 h-full w-full ">
                                  <input
                                    type="text"
                                    class="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-3 px-4 text-center max-w-lg"
                                    value={data[tier.name].cash_benefit}
                                    onChange={(e) => {
                                      const amount = e.target.value;
                                      if (
                                        !amount ||
                                        amount.match(/^\d{1,}(\.\d{0,4})?$/)
                                      ) {
                                        // console.log(amount);
                                        handleOnValueChange(
                                          e.target.value,
                                          tier.name,
                                          "cash_benefit",
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center ">
                {isUpdate ? (
                  <button
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                    className="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageMembership;
