import { rootApi } from "../../api/rootApi";

export const referAndEarnRewardsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllReferAndEarnRewards: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/referralAndEarnRewards`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    createReferAndEarnRewards: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/referralAndEarnRewards/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
    updateStatusOfReferAndEarnRewards: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/referralAndEarnRewards/statusUpdate/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    deleteReferAndEarnRewards: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/referralAndEarnRewards/delete/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
     editReferAndEarnRewards: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/referralAndEarnRewards/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
  }),
});

export const { useGetAllReferAndEarnRewardsMutation , useCreateReferAndEarnRewardsMutation , useUpdateStatusOfReferAndEarnRewardsMutation,useDeleteReferAndEarnRewardsMutation,useEditReferAndEarnRewardsMutation} = referAndEarnRewardsApi;
