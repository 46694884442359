import React, { useMemo, useState } from "react";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import {
  useAddProductMutation,
  useFetchProductsMutation,
} from "../../../features/tenant/products/productsApi";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useFetchSubcategoriesMutation } from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { useEffect } from "react";
import { useFetchAllBrandsMutation } from "../../../features/tenant/brands/brandsApi";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useFetchUomKeysMutation } from "../../../features/admin/uomKeys/uomKeysApi";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";

const productQRType = {
  normal: 1,
  complex: 2,
};

const productStatus = [
  {
    id: "1",
    name: "active",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: "2",
    name: "inactive",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: "3",
    name: "deleted",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

const productUnits = [
  {
    id: "1",
    name: "box",
  },
  {
    id: "2",
    name: "pieces",
  },
];

const productBrandName = [
  {
    id: "1",
    name: "Apple",
  },
  {
    id: "2",
    name: "Samsung",
  },
];

const tempProductImage =
  "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

const AddProduct = () => {
  const navigate = useNavigate();
  const [selectedProductCategory, setSelectedProductCategory] = useState({
    id: -1,
    name: "",
  });
  const [selectedProductStatus, setSelectedProductStatus] = useState(
    productStatus[0],
  );

  const [selectedProductUnit, setSelectedProductUnit] = useState(
    productUnits[0],
  );
  const [selectedBrandName, setSelectedBrandName] = useState({
    id: -1,
    name: "",
  });

  const [
    fetchUomKeys,
    { data: uomKeysData, isLoading: uomKeysisLoading, error: uomKeysError },
  ] = useFetchUomKeysMutation();

  const [selectedUomKey, setSelectedUomKey] = useState({
    id: -1,
    name: "",
  });

  const [uomValue, setUomValue] = useState(null);
  const [video, setVideo] = useState(null);

  const [
    addProduct,
    {
      data: addProductData,
      isLoading: addProductIsLoading,
      isError: addProductIsError,
      error: addProductError,
    },
  ] = useAddProductMutation() || {};

  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  const [fetchProducts, { data: productData }] = useFetchProductsMutation();

  const [productCode, setProductCode] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productDes, setProductDes] = useState(null);
  const [mrp, setMrp] = useState(null);
  const [qty, setQty] = useState(0);
  const [images, setImages] = useState();
  // const [formImg, setFormImg] = useState("");

  useEffect(() => {
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const [fetchBrandList, { data: brandsData, error: brandsError }] =
    useFetchAllBrandsMutation();

  useEffect(() => {
    fetchBrandList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    fetchUomKeys({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, [])
  

  useEffect(() => {
    if (uomKeysData) {
      console.log("uom keys data", uomKeysData);
    }
    if (uomKeysError) {
      console.log("uom keys errror", uomKeysError);
    }
  }, [uomKeysData, uomKeysError]);

  const handleAddProduct = (e) => {
    e.preventDefault();
    console.log("object", selectedProductStatus);

    const formData = new FormData();
    formData.append("image", images);
    formData.append("brand_id", Number(selectedBrandName.id));
    formData.append("brand_name", selectedBrandName.name);
    formData.append("product_code", productCode);
    formData.append("name", productName);
    formData.append("description", productDes);
    formData.append("unit_id", Number(selectedProductUnit.id));
    formData.append("unit_name", selectedProductUnit.name);
    formData.append("uom_key", selectedUomKey.name);
    formData.append("uom_value", uomValue);
    formData.append("video", video);
    formData.append("qty", qty);
    formData.append("mrp", mrp);
    formData.append(
      "qr_type",
      productQRType[
        Number(selectedProductUnit.id) === 1 ? "normal" : "complex"
      ],
    );
    formData.append("status", selectedProductStatus.id);
    formData.append("category_id", Number(selectedProductCategory.id));
    formData.append("category_name", selectedProductCategory.name);

    const objectData = {};
    formData.forEach(function (value, key) {
      objectData[key] = value;
    });
    addProduct({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: objectData,
      // {
      //   brand_id: Number(selectedBrandName.id),
      //   brand_name: selectedBrandName.name,
      //   product_code: productCode,
      //   name: productName,
      //   description: productDes,
      //   unit_id: Number(selectedProductUnit.id),
      //   unit_name: selectedProductUnit.name,
      //   qty: qty,
      //   qr_type:
      //     productQRType[
      //       Number(selectedProductUnit.id) === 1 ? "normal" : "complex"
      //     ],
      //   images: images,
      //   status: selectedProductStatus.id,
      //   category_id: Number(selectedProductCategory.id),
      //   category_name: selectedProductCategory.name,
      // },
    });

    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: 10,
      offset: 0,
    });
  };

  if (addProductIsError) {
    console.log("error fksdfalsfashfhfhnkklkhksdfhafk");
    toast.dismiss();
    toast.error(`${addProductError.data.message}`, {
      id: "add-product-error",
    });
  }
  // console.log("addLoading", addProductIsLoading);
  if (addProductIsLoading) {
    console.log("inside loading");
    toast.dismiss();
    toast.loading("adding Product...", {
      id: "add-product-loading",
    });
    if (productData) {
      console.log("inside");
    }
  }
  useMemo(() => {
    if (productData && addProductIsError === false) {
      console.log("use Memo");
      toast.dismiss();
      navigate("/genefied/baltra/product");
    }
  }, [productData, addProductIsError]);

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const previewImage = (event) => {
    // setFormImg(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    uploadImage({
      body: formData,
    });

    // const imageFiles = event.target.files;
    // const imageFilesLength = imageFiles.length;
    // if (imageFilesLength > 0) {
    //   const imageSrc = URL.createObjectURL(imageFiles[0]);
    //   setImages(imageSrc);
    // }
  };

  useEffect(() => {
    toast.dismiss();
    if (uploadImageData) {
      console.log("success image upload", uploadImageData.body);
      setImages(uploadImageData.body.fileLink);
    }
    if (uploadImageError) {
      toast.error("Image upload failed");
    }

    if (uploadImageIsLoading) {
      toast.loading("Uploading your image...", { id: "uploadImageLoading" });
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);

  return (
    <div className="flex w-full bg-slate-200 h-full justify-center">
      <div className="w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Add Product
          <LinksComponent />
        </div>
        <div className="p-3">
          <div className=" p-3 w-full bg-white shadow rounded-lg">
            <form className="">
              <div>
                <div className="w-full flex justify-center space-x-3">
                  <div className="mb-6 bottom-2 w-1/2">
                    <SelectComponentWithSearch
                      title={"Brand Name"}
                      selected={selectedBrandName}
                      setSelected={setSelectedBrandName}
                      dataArr={brandsData?.body?.brands || []}
                      placeholder="Select Brand"
                    />
                  </div>

                  <div className="mb-6 w-1/2">
                    <SelectComponentWithSearch
                      title={"Category"}
                      selected={selectedProductCategory}
                      setSelected={setSelectedProductCategory}
                      dataArr={subCategoriesData ? subCategoriesData?.body : []}
                      placeholder="Select Category"
                    />
                  </div>

                  <div className="mb-6 w-1/3">
                    <InputComponent
                      name={"Product Name"}
                      type={"text"}
                      value={productName}
                      setValue={setProductName}
                      required={true}
                    />
                  </div>
                </div>

                {/* <div className="w-full flex justify-center ">
              
              </div> */}

                <div className="w-full flex justify-center space-x-3">
                  <div className="mb-6 w-1/3">
                    <InputComponent
                      name={"Product Code"}
                      type={"text"}
                      value={productCode}
                      setValue={setProductCode}
                      required={true}
                    />
                  </div>
                  <div className="mb-6 bottom-2 border-red-600 w-1/3 dropds">
                    <SelectComponent
                      title={"Packing"}
                      selected={selectedProductUnit}
                      setSelected={setSelectedProductUnit}
                      dataArr={productUnits}
                    />
                  </div>
                  {selectedProductUnit.name === "box" && (
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Qty"}
                        type={"number"}
                        value={qty}
                        setValue={setQty}
                        required={true}
                      />
                    </div>
                  )}
                  <div className="mb-6 bottom-2 border-red-600 w-1/3 dropds">
                    <SelectComponent
                      title={"Status"}
                      selected={selectedProductStatus}
                      setSelected={setSelectedProductStatus}
                      dataArr={productStatus}
                    />
                  </div>
                </div>

                <div className="w-full flex justify-start space-x-3">
                  <div className="mb-6 bottom-2 border-red-600 w-1/3">
                    <SelectComponentWithSearch
                      title={"Unit of Measurement"}
                      selected={selectedUomKey}
                      setSelected={setSelectedUomKey}
                      dataArr={uomKeysData ? uomKeysData?.body : []}
                    />
                  </div>
                  <div className="mb-6 w-1/3">
                    <InputComponent
                      name={"Value"}
                      type={"text"}
                      value={uomValue}
                      setValue={setUomValue}
                      required={true}
                    />
                  </div>
                  <div className="mb-6 w-1/3">
                    <InputComponent
                      name={"Video"}
                      type={"text"}
                      value={video}
                      setValue={setVideo}
                    />
                  </div>
                </div>

                <div className="w-full flex justify-center">
                <div className="mb-6 w-full">
                    <InputComponent
                      name={"Product mrp"}
                      type={"text"}
                      value={mrp}
                      setValue={setMrp}
                      required={true}
                      maxLength={1000}
                    />
                  </div>
                </div>

                <div className="w-full flex justify-center ">
                  <div className="mb-6 w-full">
                    <InputComponent
                      name={"Product Description"}
                      type={"textarea"}
                      value={productDes}
                      setValue={setProductDes}
                      required={true}
                      maxLength={1000}
                    />
                  </div>

                
                </div>

                <div className="w-full flex justify-center ">
                  <div className="flex items-center justify-center w-full">
                    <label
                      for="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                    >
                      <div className="flex flex-col items-center justify-center">
                        <svg
                          aria-hidden="true"
                          className="w-20 h-20 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            strokeLinejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500">
                          PNG or JPG (800x400px) . Max Upto 3 Images
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => previewImage(e)}
                      />
                    </label>
                  </div>
                </div>
                {images && (
                  <span className="w-10 h-10 d-block m-auto border rounded-md p-3">
                    <img
                      src={images}
                      alt=""
                      srcset=""
                      className="img-fluid flex items-center justify-center w-48 m-auto "
                    />
                  </span>
                )}
              </div>
              <div className="flex justify-center mt-4">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={handleAddProduct}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
