import React from "react";
import { Route, Routes } from "react-router-dom";
import MasterHome from "../MasterHome/MasterHome";
import VendorRegistration from "../Vendor/VendorRegistration";
import IndustryMaster from "../IndustryMaster/IndustryMaster";
import AddIndustry from "../IndustryMaster/AddIndustry";
import ChangePassword from "../ChangeAdminPassword/ChangePassword";
import BasicSetup from "../BasicSetup/BasicSetup";
import VendorUpdate from "../Vendor/VendorUpdate";
import UpdateIndustry from "../IndustryMaster/UpdateIndustry";
import FormTemplate from "../FormTemplate/FormTemplate";
import AddForm from "../FormTemplate/AddForm";
import MessageTemplate from "../MessageTemplate/MessageTemplate";
import AddMessageTemplate from "../MessageTemplate/AddMessageTemplate";
import EmailTemplate from "../EmailMaster/EmailCredentials";
import AddEmailTemplate from "../EmailMaster/AddEmailTemplate";
import ProgramSetup from "../ProgramSetup/ProgramSetup";
import AddNewProgramOption from "../ProgramSetup/AddNewProgramOption";
import EditProgramOption from "../ProgramSetup/EditProgramOption";
import Dwan from "../../Dwan";
import "../../../assests/style.css";
import FormSetup from "../FormSetup/FromSetup";
import VendorTheme from "../VendorTheme/VendorTheme";
import AddVendorTheme from "../VendorTheme/AddVendorTheme";
import AllWorkflow from "../WorkflowSetup/AllWorkflow";
import AddWorkflow from "../WorkflowSetup/AddWorkflow";
import Navbar from "../../../components/Admin/Navbar";
import Sidebar from "../../../components/Admin/Sidebar";
import AppUserType from "../AppUserType/AppUserType";
import AddAppUserType from "../AppUserType/AddAppUserType";
import SmsCredential from "../SmsCredential/SmsCredential";
import AddSMSCredential from "../SmsCredential/AddSMSCredential";
import UpdateSMSCredential from "../SmsCredential/UpdateSMSCredential";
import AppMenu from "../AppSetup/AppMenu";
import ManageAppMenu from "../AppSetup/ManageAppMenu";
import AppDashboard from "../AppSetup/AppDashboard";
import ManageAppDashboard from "../AppSetup/ManageAppDashboard";
import NotFound from "../../../components/NotFound";
import UomKeys from "../UomMaster/UomKeys";
import UomValues from "../UomMaster/UomValues";
import AddTenantWebDashboard from "../TenantWebDashboardSetup/AddTenantWebDashboard";
import AllTenantWebDashboards from "../TenantWebDashboardSetup/AllTenantWebDashboards";
import AddEmailCredentials from "../EmailMaster/AddEmailCredentials";
import ViewEmailTemplates from "../EmailMaster/ViewEmailTemplates";
import AddTenantSidebar from "../TenantSidebar/AddTenantSidebar";
import ErrorBoundry from "../../../components/ErrorBoundry/ErrorBoundry";
import AllTenantSidebar from "../TenantSidebar/AllTenantSidebar";
import AllFirebaseSetup from "../FirebaseSetup/AllFirebaseSetup";
import AddTenantFirebaseSetup from "../FirebaseSetup/AddFirebaseSetup";
// import VendorProfile from "../Vendor/VendorProfile";

const Home = () => {
  return (
    <>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <Navbar />
          <Sidebar />
          <ErrorBoundry>

          <Routes>
            <Route path="" element={<MasterHome />} />
            <Route path="addVendor" element={<VendorRegistration />} />
            <Route path="editVendor" element={<VendorUpdate />} />
            {/* <Route path="vendorProfile" element={<VendorProfile />} /> */}
            <Route path="vendorTheme" element={<VendorTheme />} />
            <Route path="addVendorTheme" element={<AddVendorTheme />} />
            <Route path="editVendorTheme" element={<AddVendorTheme />} />

            <Route path="industryMaster" element={<IndustryMaster />} />
            <Route path="addIndustry" element={<AddIndustry />} />
            <Route path="updateIndustry" element={<UpdateIndustry />} />

            <Route path="changeAdminPassword" element={<ChangePassword />} />

            <Route path="basicSetup" element={<BasicSetup />} />
            <Route path="workflowSetup" element={<AllWorkflow />} />
            <Route path="addWorkflow" element={<AddWorkflow />} />

            <Route path="formsetup" element={<FormSetup />} />

            <Route path="formTemplate" element={<FormTemplate />} />
            <Route path="addForm" element={<AddForm />} />
            <Route path="messageTemplate" element={<MessageTemplate />} />
            <Route path="addMessageTemplate" element={<AddMessageTemplate />} />

            <Route path="emailCredential" element={<EmailTemplate />} />
            <Route
              path="addEmailCredential"
              element={<AddEmailCredentials />}
            />
            <Route path="addEmailTemplate" element={<AddEmailTemplate />} />
            <Route path="viewEmailTemplate" element={<ViewEmailTemplates />} />

            <Route path="dwan" element={<Dwan />} />

            <Route path="program" element={<ProgramSetup />} />
            <Route path="addNewOption" element={<AddNewProgramOption />} />
            <Route path="editOption" element={<EditProgramOption />} />

            <Route path="appUsers" element={<AppUserType />} />
            <Route path="addAppUserType" element={<AddAppUserType />} />

            <Route path="appMenu" element={<AppMenu />} />
            <Route path="appMenu/add" element={<ManageAppMenu />} />

            <Route path="appDashboard" element={<AppDashboard />} />
            <Route path="appDashboard/add" element={<ManageAppDashboard />} />

            <Route path="smsCredential" element={<SmsCredential />} />
            <Route path="addSMSCredential" element={<AddSMSCredential />} />
            <Route
              path="updateSMSCredential"
              element={<UpdateSMSCredential />}
            />

            <Route path="uomKeys" element={<UomKeys />} />
            <Route path="uomValues" element={<UomValues />} />
            <Route
              path="tenantWebDashboard"
              element={<AllTenantWebDashboards />}
            />
            <Route
              path="tenantWebDashboard/add"
              element={<AddTenantWebDashboard />}
            />
             <Route path="tenantSidebar" element={<AllTenantSidebar />} />
            <Route path="tenantSidebar/manage" element={<AddTenantSidebar />} />
            {/* <Route path="uomKeys/add" element={<ManageAppMenu />} /> */}

            <Route
              path="firebaseSetup"
              element={<AllFirebaseSetup />}
            />

<Route
              path="firebaseSetup/add"
              element={<AddTenantFirebaseSetup />}
            />

            <Route path="/*" element={<NotFound />} />
          </Routes>
          </ErrorBoundry>

        </div>
      </div>
    </>
  );
};

export default Home;
