import { rootApi } from "../../api/rootApi";

export const ActiveUsersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveUsers: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "POST",
          url: "/tenant/getActiveUsers",
          headers: {
            "Content-Type": "application/json",
            slug: `${params.tenant_id}`,
            Authorization: "Bearer " + params.token,
          },
          body : JSON.stringify(params.body)
        };
      },
    }),
  }),
});

export const {
    useGetActiveUsersMutation
} = ActiveUsersApi;
