import { rootApi } from "../../api/rootApi";

export const appUserTypeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllVersion: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/app-versions`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),
      updateHWSandSWS: builder.mutation({
        query: (params) => {
          return {
            method: "PUT",
            url: `/tenant/app-versions/${params.id}`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),

      addVersion: builder.mutation({
        query: (params) => {
          return {
            method: "POST",
            url: `/tenant/app-versions/add`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),


      deleteVersion: builder.mutation({
        query: (params) => {
          return {
            method: "DELETE",
            url: `/tenant/app-versions/${params.id}`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
          };
        },
      }),
  }),
});

export const { useAddVersionMutation,useDeleteVersionMutation,useUpdateHWSandSWSMutation,useGetAllVersionMutation} = appUserTypeApi;
