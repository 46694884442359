import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  useDeleteEmailTemplateMutation,
  useGetAllEmailTemplateByTenantIdMutation,
} from "../../../features/admin/emailMaster/emailTemplateApi";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function ViewEmailTemplates() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableDataItems, setTableDataItems] = useState([]);

  const [viewTemplates, setViewTemplates] = useState(false);
  const handleViewTemplateOpen = () => setViewTemplates(true);
  const handleViewTemplateClose = () => setViewTemplates(false);

  const [viewTemplateData, setViewTemplateData] = useState(null);

  const [
    fetchAllEmailTemplates,
    {
      data: emailTemplateData,
      isLoading: emailTemplateIsLoading,
      error: emailTemplateError,
    },
  ] = useGetAllEmailTemplateByTenantIdMutation();

  const [
    deleteEmailTemplate,
    { data: deleteEmailTemplateData, error: deleteEmailTemplateError },
  ] = useDeleteEmailTemplateMutation();

  useMemo(() => {
    if (emailTemplateData) {
      (() => {
        console.log(emailTemplateData);
        const arr = [...emailTemplateData.body];
        arr.sort((a, b) => a.id - b.id);
        setTableDataItems(arr);
      })();
    }

    if (emailTemplateError) {
      (() => {
        toast.error("Something went wrong", {
          id: "emailCredentialsError",
        });
      })();
    }
  }, [emailTemplateData, emailTemplateError]);

  useEffect(() => {
    // if(location.state && location.state.tenant_id){

    fetchAllEmailTemplates({
      tenant_id: location.state.tenant_id,
      token: sessionStorage.getItem("access_token"),
    });
    // }else {
    // navigate("/emailCredential")
    // }
  }, []);

  useEffect(() => {
    if (deleteEmailTemplateData) {
      fetchAllEmailTemplates({
        tenant_id: location.state.tenant_id,
        token: sessionStorage.getItem("access_token"),
      });
      toast.success("Deleted Successfully", {
        id: "emailTemplateDeleteSuccess",
      });
    }

    if (deleteEmailTemplateError) {
      console.log("delete email template error", deleteEmailTemplateError);
      toast.error("Something went wrong", { id: "emailTemplateDeleteError" });
    }
  }, [deleteEmailTemplateData, deleteEmailTemplateError]);

  const handleDelete = (id) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                toast.dismiss();
                deleteEmailTemplate({
                  token: sessionStorage.getItem("access_token"),
                  id: id,
                });
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      },
    );
    return;
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Template Name ",
      selector: (row) => row.template_name,
    },
    {
      name: "Template Type",
      selector: (row) => row.template_type,
      // sortable: true,
    },
    {
      name: "Owned By ",
      selector: (row) =>
        row.template_owned_by_id == "0"
          ? "Admin"
          : `Tenant ${row.template_owned_by_id}`,
    },

    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    // {
    //     name: "Status ",
    //     selector: (row) =>
    //       row.status == "1" ? (
    //         <button className="btn btn-outline-primary btn-sm"> Active</button>
    //       ) : (
    //         <button className="btn btn-outline-secondary btn-sm">
    //           InActive
    //         </button>
    //       ),
    //   },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            handleViewTemplateOpen();
            setViewTemplateData(r.html_template);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => navigate("/addEmailTemplate", { state: r })}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDelete(r.id)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  if (emailTemplateIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="">
      <div className="page-content">
        <div>
          {viewTemplates && (
            <Modal
              keepMounted
              open={viewTemplates}
              onClose={handleViewTemplateClose}
            >
              <Box style={{ overflowY: "scroll" }} sx={style}>
                <div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: viewTemplateData }}
                  />
                </div>
              </Box>
            </Modal>
          )}
        </div>
        <div className="card p-4">
          <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 className="mb-0 card-title">All Email Templates of Tenant</h4>
            </div>
            <div className="d-flex align-items-center flex-wrap text-nowrap">
              {location.state.tenant_id == 0 && (
                <button
                  type="button"
                  className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                  onClick={() => navigate("/addEmailTemplate")}
                >
                  Add New Email Template
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-12">
              <DataTable
                title="Email Templates"
                data={tableDataItems}
                columns={columns}
                //selectableRows
              />
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
