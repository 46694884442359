export const egyptStates = [
    { id: "ALX", name: "Alexandria" },
    { id: "ASN", name: "Aswan" },
    { id: "AST", name: "Assiut" },
    { id: "BH", name: "Beheira" },
    { id: "BNS", name: "Beni Suef" },
    { id: "C", name: "Cairo" },
    { id: "DK", name: "Dakahlia" },
    { id: "DT", name: "Damietta" },
    { id: "FYM", name: "Fayoum" },
    { id: "GH", name: "Gharbia" },
    { id: "GZ", name: "Giza" },
    { id: "IS", name: "Ismailia" },
    { id: "KFS", name: "Kafr El Sheikh" },
    { id: "MT", name: "Matrouh" },
    { id: "MN", name: "Minya" },
    { id: "MNF", name: "Menofia" },
    { id: "WAD", name: "New Valley" },
    { id: "NS", name: "North Sinai" },
    { id: "PS", name: "Port Said" },
    { id: "KB", name: "Qalyubia" },
    { id: "KN", name: "Qena" },
    { id: "RS", name: "Red Sea" },
    { id: "SHR", name: "Sharqia" },
    { id: "SHG", name: "Sohag" },
    { id: "SNS", name: "South Sinai" },
    { id: "SUZ", name: "Suez" },
    { id: "LX", name: "Luxor" }
]