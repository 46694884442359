import React from "react";
import DataTable from "react-data-table-component";
import noDataImg from "../../../assests/tenant/images/nodata.png"
const DataTableComponent = ({
  columns,
  title,
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  children,
  selectableRows = false,
  onSelectedRowsChange
}) => {

  // columns = [
  //   {
  //     name: 'ID',
  //     selector: row => row.id,
  //   },
  //   {
  //     name: 'Title',
  //     selector: row => row.title,
  //   },
  //   {
  //     name: 'Year',
  //     selector: row => row.year,
  //   },
  // ];
  
  // data = [
  //     { id: 1, title: 'Beetlejuice', year: '1988'},
  //     { id: 2, title: 'Beetlejuice', year: '1988'},
  //     { id: 3, title: 'Beetlejuice', year: '1988'},
  //     { id: 4, title: 'Beetlejuice', year: '1988'},
  //     { id: 5, title: 'Beetlejuice', year: '1988'},
  //     { id: 6, title: 'Beetlejuice', year: '1988'},
  //     { id: 7, title: 'Beetlejuice', year: '1988'},
  //     { id: 8, title: 'Beetlejuice', year: '1988'},
  //     { id: 9, title: 'Beetlejuice', year: '1988'},
  //     { id: 10, title: 'Beetlejuice', year: '1988'},
  //     { id: 11, title: 'Beetlejuice', year: '1988'},
  //     { id: 12, title: 'Beetlejuice', year: '1988'},
  //     { id: 13, title: 'Beetlejuice', year: '1988'},
  //     { id: 14, title: 'Beetlejuice', year: '1988'},
  //     { id: 15, title: 'Beetlejuice', year: '1988'},
  //     { id: 16, title: 'Beetlejuice', year: '1988'},
  //     { id: 17, title: 'Beetlejuice', year: '1988'},
  //     { id: 18, title: 'Beetlejuice', year: '1988'},
  //     { id: 19, title: 'Beetlejuice', year: '1988'},
  //     { id: 20, title: 'Beetlejuice', year: '1988'},
  //     { id: 21, title: 'Beetlejuice', year: '1988'},
  //     { id: 22, title: 'Beetlejuice', year: '1988'},
  // ]

  // totalRows = 22;
 
  // console.log("Total rows: " + totalRows)
  return (
      <div className="flex_bt mb-2">
        {children}
        <div>
          {data?.length > 0
          ?
          <DataTable
          columns={columns}
          title={title}
          data={data}
          // persistTableHead
          // fixedHeader
          responsive
          striped
          highlightOnHover
          fixedHeaderScrollHeight={"500px"}
          loading={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows= {selectableRows}
          onSelectedRowsChange ={onSelectedRowsChange}
          />
          : <div className="flex flex-col justify-center items-center py-2">
          <div className="aspect-h-1 aspect-w-1 w-96 overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={noDataImg}
                alt="no-data"
                className="h-full w-full object-cover object-center "
              />
            </div>
            <h2 className="text-lg text-center w-full font-semibold">No records to display.</h2>


          </div>
        }
        </div>
      </div>
  );
};

export default DataTableComponent;
