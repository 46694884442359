import { rootApi } from "../../api/rootApi";

export const membershipApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createMembership: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/membership/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchAllMembership: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/membership?limit=${params.limit}&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchMembershipById: builder.mutation({
      query: (params) => {
        return { method: "GET",
          url: `/tenant/membership/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateMembershipById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/membership/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteMembershipById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/membership/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    checkActiveMembershipForUser: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/membership/check?user_type=${params.user_type}&app_user_id=${params.app_user_id}&user_type_id=${params.user_type_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateMembershipMutation,
  useDeleteMembershipByIdMutation,
  useFetchAllMembershipMutation,
  useFetchMembershipByIdMutation,
  useUpdateMembershipByIdMutation,
  useCheckActiveMembershipForUserMutation,
} = membershipApi;
