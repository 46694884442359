import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useFetchUomValuesMutation } from "../../../features/admin/uomValues/uomValuesApi";
import { Box, Modal } from "@mui/material";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

const UomValues = () => {

  const [tableDataItems, setTableDataItems] = useState([]);
  const [modal, setModal] = useState(false);

  const handleModalOpen = () => setModal(true);
  const handleModalClose = () => setModal(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [refetch, setRefetch] = useState(0);
  const [value, setValue] = useState(null)

  const [
    fetchUomValues,
    {
      data: uomValuesData,
      isLoading: uomValuesIsLoading,
      error: uomValuesError,
    },
  ] = useFetchUomValuesMutation();

  useMemo(() => {
    if (uomValuesData) {
      (() => {
        setTableDataItems(uomValuesData.body);
      })();
    }

    if (uomValuesError) {
      toast.error("Something went wrong", {
        id: "uomValuesFetchError",
      });
    }
  }, [uomValuesData, uomValuesError]);

  useEffect(() => {
    fetchUomValues(sessionStorage.getItem("access_token"));
  }, []);

  const handleButtonEditClick = (r) => {
    //handle the edit functionality
  };

  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleButtonDeleteClick(r);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteIndustry",
      },
    );
    return;
  };

  const handleButtonDeleteClick = (r) => {
    //handle the delete functionality
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleAddUomKey = (e) => {
    e.preventDefault();

    if (!value || value.trim().length === 0) {
      toast.error("Please enter valid key name", { id: "valueNotValid" });
      return;
    }

    // addUomKey({
    //   token: sessionStorage.getItem("access_token"),
    //   body: {
    //     name: value,
    //   },
    // });
  };


  return (
    <>
      <div className="page-content">
      <div>
          {modal && (
            <Modal keepMounted open={modal} onClose={handleModalClose}>
              <Box sx={style}>
                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                  <div className="frmds w-100">
                  <div className="col">
                      <label for="name">Select Key </label>
                      <input
                        id="name"
                        className="form-control"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="text"
                      />
                    </div>
                    <div className="col">
                      <label for="name">Value </label>
                      <input
                        id="name"
                        className="form-control"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="pl-4 d-flex gap-2 mt-3 mr-auto justify-content-around w-50 ">
                    {/* {isUpdate ? (
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-icon-text"
                        onClick={(e) => handleUpdateUomKey(e)}
                      >
                        Update
                      </button>
                    ) : (
                         */}
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-icon-text"
                        onClick={(e) => handleAddUomKey(e)}
                      >
                        Add
                      </button>
                    {/* )} */}
                    <button
                      type="cancel"
                      className="btn btn-outline-danger  btn-icon-text"
                      onClick={() => {
                        setValue(null);
                        handleModalClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          )}
        </div>
        <div className="card p-4">
          <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 className="mb-3 mb-md-0 card-title">
                Welcome to Uom Values Master
              </h4>
            </div>
            <div className="d-flex align-items-center flex-wrap text-nowrap">
              <button
                type="button"
                className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                onClick={() => handleModalOpen()}
              >
                Add New Value
              </button>
            </div>
          </div>

          <div className="row">
            <div className="table-responsive">
              <DataTable
                data={tableDataItems}
                columns={columns}
                //selectableRows
                // customStyles={customStyles}
                striped
                highlightOnHover
                pointerOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UomValues;
