import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { useLocation, useNavigate } from "react-router-dom";

import { hasPermission } from "../../../../utils/hasPermission";
import { getVendorName } from "../../../../utils/getVendorName";
import { useFetchCouponsByBrandNameMutation } from "../../../../features/tenant/staticCoupons/staticCouponsApi";
import { useFetchSubcategoriesMutation } from "../../../../features/tenant/productHeirarchy/productHeirarchyApi";

const couponStatus = ["Deleted/Expired", "Active", "Inactive", "Redeemed"];

const CouponByBrand = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [brand, setBrand] = useState(null);

  const [
    fetchAllCouponByBrand,
    {
      data: couponList,
      isLoading: couponListIsLoading,
      error: couponListError,
    },
  ] = useFetchCouponsByBrandNameMutation();

  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("staticCouponListByBrand");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    console.log(location.state);
    if (location.state) setBrand(location.state?.brand);
  }, [location.pathname]);

  useEffect(() => {
    console.log("coupon brand useffects");
    fetchAllCouponByBrand({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      brandName: brand,
    });
  }, [brand]);

  useEffect(() => {
    if (couponList) {
      console.log("coupon List By Brand", couponList);
    }
    if (couponListError) {
      console.log("coupon list error", couponListError);
    }
  }, [couponList, couponListError]);

  console.log("categories coupons", subCategoriesData);

  const getCategoryName = (catId) => {
    if (subCategoriesData && subCategoriesData.body) {
      for (let index = 0; index < subCategoriesData.body.length; index++) {
        const element = subCategoriesData.body[index];
        if (element.id === Number(catId)) {
          return element.name;
        }
      }
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
      textAlign: "left",
    },
    {
      name: "Coupon Code",
      selector: (row) => row.coupon_code,
      sortable: true,
      width: "140px",
    },
    {
      name: "Category",
      selector: (row) =>
        row.category.map((cat) => (
          <span className="mr-1 text-gray-600 ">{getCategoryName(cat)}</span>
        )),
      sortable: true,
      width: "250px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "1" ? (
          <span className="text-blue-700 font-bold">
            {couponStatus[Number(row.status)]}
          </span>
        ) : (
          <span className="text-green-700 font-bold">
            {couponStatus[Number(row.status)]}
          </span>
        ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Add Date",
      selector: (row) => row.coupon_add_date.slice(0, 10),
      sortable: true,
      // width: "13%",
      width: "150px",
    },
    {
      name: "Expires On",
      selector: (row) => row.expires_on.slice(0, 10),
      sortable: true,
      // width: "13%",
      width: "150px",

    },
    {
      name: "Desc",
      selector: (row) => row.desc,
    },
    {
      name: "Terms & Conditions",
      selector: (row) => row.terms_conditions,
    },

    // {
    //   name: "",
    //   selector: (row) => (
    //     <button
    //       className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
    //       // onClick={() => handleShowQr(row)}
    //     >
    //       View
    //     </button>
    //   ),
    //   sortable: true,
    //   width: "15%",
    // },
    // {
    //   name: "",
    //   selector: (row) => (
    //     <button
    //       // onClick={() => handleDeleteProduct(row)}
    //       className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
    //     >
    //       <AiOutlineDelete className="mr-1" />
    //       Delete
    //     </button>
    //   ),
    //   width: "20%",
    // },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Static coupons
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b bg-gray-100 rounded-t-lg  border-gray-300 pl-6 py-2 shadow-sm text-lg mt-2 font-medium">
                <span className="flex my-2 gap-3">
                  <h4 className="bg-blue-700 border border-gray-100 p-2.5 rounded-sm flex">
                    <span className="text-white   ">Brand Name&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                    <span className="flex text-white   gap-1 capitalize">
                      {location.state?.brand}
                    </span>
                  </h4>
                  <h4 className="bg-blue-700 border border-gray-100 p-2.5 rounded-sm flex">
                   <span className="text-white   ">Total coupons&nbsp;&nbsp;:&nbsp;{" "}</span>
                    <span className="flex text-white   gap-1 capitalize">
                      {" "}
                      {location.state?.count}{" "}
                    </span>{" "}
                  </h4>
                </span>
              </div>
              <DataTableComponent
                columns={columns}
                data={couponList && couponList.body.coupons}
                loading={couponListIsLoading}
                totalRows={couponList && couponList.body.total}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              >
              
              </DataTableComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponByBrand;
