import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import {
  useAddAppVideoMutation,
  useGetAppVideoTypesMutation,
} from "../../../../features/tenant/appVideo/appVideoApi";
import { getVendorName } from "../../../../utils/getVendorName";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../../../utils/hasPermission";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import {
  pushNotificationTypeApi,
  useCreatePushNotificationTypeMutation,
  useUpdatePushNotificationTypeByIdMutation,
} from "../../../../features/tenant/PushNotificationType/pushNotificationTypeAPi";

const AddPushNotificationsType = () => {
  const location = useLocation();
  console.log("object", location);
  const isUpdate = location.state.isUpdate ? true : false;
  const data = location.state.row;
  console.log("Data", data);

  const [title, setTitle] = useState(data?.title ? data.title : "");

  const [notificationType, setNotificationType] = useState(
    data?.notification_type ? data.notification_type : ""
  );
  const [body, setBody] = useState(data?.body ? data.body : "");
  const [subtitle, setSubtitle] = useState(data?.subtitle ? data.subtitle : "");

  const [
    addPushNotificationTypeFunc,
    {
      data: addPushNotificationTypeData,
      isLoading: addPushNotificationTypeIsLoading,
      error: addPushNotificationTypeError,
    },
  ] = useCreatePushNotificationTypeMutation();


  const [
    updatePushNotificationTypeById,
    {
      data: updatePushNotificationTypeByIdData,
      isLoading: updatePushNotificationTypeByIdIsLoading,
      error: updatePushNotificationTypeByIdError,
    },
  ] = useUpdatePushNotificationTypeByIdMutation();

  const navigate = useNavigate();

  useEffect(() => {
    // const hasViewPermission = hasPermission("video");
    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }

    if (addPushNotificationTypeData) {
      toast.success("Push Notifications Type Added !");
      navigate(`/genefied/${getVendorName()}/push-notification`);
    }
  }, [addPushNotificationTypeData, addPushNotificationTypeError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!notificationType) {
      toast.error("Please provide notification type", {
        id: "imageError",
      });
      return;
    }

    if (!title) {
      toast.error("Please provide title", {
        id: "imageError",
      });
      return;
    }

    if (!body) {
      toast.error("Please provide notification body", {
        id: "imageError",
      });
      return;
    }

    if (!subtitle) {
      toast.error("Please provide subtitle", {
        id: "imageError",
      });
      return;
    }

    addPushNotificationTypeFunc({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        notification_type: notificationType,
        title: title,
        body: body,
        subtitle: subtitle,
      },
    });
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    console.log(notificationType);
    console.log(title);
    console.log(body);
    console.log(subtitle);

    if (!notificationType) {
      toast.error("Please provide notification type", {
        id: "imageError",
      });
      return;
    }

    if (!title) {
      toast.error("Please provide title", {
        id: "imageError",
      });
      return;
    }

    if (!body) {
      toast.error("Please provide notification body", {
        id: "imageError",
      });
      return;
    }

    if (!subtitle) {
      toast.error("Please provide subtitle", {
        id: "imageError",
      });
      return;
    }

    updatePushNotificationTypeById(
      {
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          title: title,
          body: body,
          subtitle: subtitle,
        },
        id: data.id
      }
    )

    return;
  };

  useEffect(() => {
    if (updatePushNotificationTypeByIdData) {
      toast.success("Push Notifications Type Added !");
      navigate(`/genefied/${getVendorName()}/push-notification`);
    }
    if (updatePushNotificationTypeByIdError) {
      toast.error(updatePushNotificationTypeByIdError.data.message, {
        id: "updatePushNotificationTypeByIdError"
      });
    }
  }, [updatePushNotificationTypeByIdData, updatePushNotificationTypeByIdError])
  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Add Push Notifications Type
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 w-full bg-white shadow rounded-lg">
              <div className="w-full  flex justify-center space-x-4">
                <div className="mb-3 w-1/2">
                  <div className="w-full">
                    <InputComponent
                      name={"Notification Type"}
                      type={"textarea"}
                      value={notificationType}
                      setValue={setNotificationType}
                      required={true}
                      placeholder={"Enter Notification Type"}
                      disabled={isUpdate}
                    />
                  </div>
                </div>

                <div className="mb-3 w-1/2">
                  <div className="w-full">
                    <InputComponent
                      name={"Title"}
                      type={"textarea"}
                      value={title}
                      setValue={setTitle}
                      required={true}
                      placeholder={"Enter Title"}
                    />
                  </div>
                </div>

                <div className="mb-3 w-1/2">
                  <div className="w-full">
                    <InputComponent
                      name={"Subtitle"}
                      type={"textarea"}
                      value={subtitle}
                      setValue={setSubtitle}
                      required={true}
                      placeholder={"Enter Subtitle"}
                    />
                  </div>
                </div>

                <div className="mb-3 w-1/2">
                  <div className="w-full">
                    <InputComponent
                      name={"body"}
                      type={"textarea"}
                      value={body}
                      setValue={setBody}
                      required={true}
                      placeholder={"Enter Body"}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-4">
                {isUpdate ? (
                  <button
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Add
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPushNotificationsType;
