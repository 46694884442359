import React, { useEffect, useState } from "react";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import { toast } from "react-hot-toast";
import { useUploadECatalogueMutation } from "../../../../features/tenant/eCatalogue/eCatalogueApi";
import { useNavigate } from "react-router-dom";
import {useUploadSingleFileMutation } from "../../../../features/admin/imageUpload/imageUploadApi";

const AddECatalogue = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(null);
  const [type, setType] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);

  const [displayImage, setDisplayImage] = useState(null);

  const [
    uploadECatalogue,
    {
      data: uploadECatalogueData,
      isLoading: uploadECatalogueIsLoading,
      isError: uploadECatalogueIsError,
      error: uploadECatalogueError,
    },
  ] = useUploadECatalogueMutation();

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const uploadImageFunc = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    uploadImage({
      body: formData,
    });
  };

  useEffect(() => {
    if (uploadImageData) {
      console.log("success image upload", uploadImageData.body);
      setDisplayImage(uploadImageData.body.fileLink);
    }
    if (uploadImageError) {
      toast.error("image upload failed");
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("pdfFile", pdfFile);

    if (pdfFile === null) {
      toast.error("Please provide E-Catalogue PDF", {
        id: "imageError",
      });
      return;
    }
    if (!title) {
      toast.error("Please provide a Title", {
        id: "imageError",
      });
      return;
    }

    if (!type) {
      toast.error("Please provide a type", {
        id: "imageError",
      });
      return;
    }

    const formData = new FormData();

    formData.append("e_catalogue", pdfFile);
    formData.append("name", title);
    formData.append("type", type);
    formData.append("image", displayImage);

    uploadECatalogue({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      data: formData,
    });
  };

  if (uploadECatalogueIsLoading) {
    toast.loading("loading...", {
      id: "uplaodECatalogueIsLoading",
    });
  }

  if (uploadECatalogueIsError) {
    toast.dismiss();
    toast.error("Ops something went wrong !!!", {
      id: "uploadECatalogueIsError",
    });
  }

  if (uploadECatalogueData) {
    toast.dismiss();
    navigate(-1);
  }

  const previewImage = (event) => {
    setDisplayImage(event.target.files[0]);
    const imageFiles = event.target.files;
    const imageFilesLength = imageFiles.length;
    // if (imageFilesLength > 0) {
    //   const imageSrc = URL.createObjectURL(imageFiles[0]);
    //   setImages(imageSrc);
    // }
  };

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Add E-Catalogue
          <LinksComponent />
        </div>
        <div className="p-3">
          <form className="p-3 w-full bg-white shadow rounded-lg">
            <div className="w-full flex flex-col justify-center align-middle  ">
              <div className="flex w-full justify-start space-x-10 align-middle">
                <div className="mb-3 w-1/2">
                  <InputComponent
                    name={"Title"}
                    type={"text"}
                    value={title}
                    setValue={setTitle}
                    required={true}
                  />
                </div>

                <div className="mb-3 w-1/2">
                  <InputComponent
                    name={"Type"}
                    type={"text"}
                    value={type}
                    setValue={setType}
                    required={true}
                  />
                </div>
              </div>

              <div className="flex w-full justify-start space-x-5 items-start">
                <div className="flex flex-col items-center justify-center w-1/2">
                  <h1 className="p-2 w-full block text-sm font-medium leading-6 text-gray-900">Upload Image</h1>
                  <div className="w-full flex justify-center ">
                    <div className="flex items-center justify-center w-full">
                      <label
                        for="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-40 h-40 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              strokeLinejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500">
                            {displayImage !== null ? (
                              <span>{displayImage}</span>
                            ) : (
                              <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                                "or drag and drop"
                                <p className="text-xs text-center text-gray-500">
                                  Image (PNG / JPG)
                                </p>
                              </p>
                            )}
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => uploadImageFunc(e.target.files[0])}
                        />
                      </label>
                    </div>
                  </div>

                  {displayImage && (
                    <img
                      src={
                        
                        displayImage
                      }
                      alt=""
                      srcset=""
                      className="flex items-center justify-center w-20 m-auto rounded-md"
                    />
                  )}
                </div>

                <div className="flex flex-col items-center justify-center w-1/2">
                <h1 className="p-2 w-full block text-sm font-medium leading-6 text-gray-900">Upload Pdf</h1>

                  <label className="flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100  ">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-40 h-40 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500">
                        {pdfFile !== null ? (
                          <span>{pdfFile.name}</span>
                        ) : (
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            "or drag and drop"
                            <p className="text-xs text-center text-gray-500">
                              PDF.
                            </p>
                          </p>
                        )}
                      </p>
                    </div>
                    <input
                      type="file"
                      accept=".pdf"
                      className="hidden"
                      onChange={(e) => setPdfFile(e.target.files[0])}
                    />
                  </label>
                </div>
              </div>

              <div className="flex justify-center mt-2">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Add
                </button>
              </div>
            </div>
          </form>

          <div className="flex  m-auto">
            <div className="flex justify-center  flex-wrap  w-full">
              {/* {previewImage.length > 0 &&
            previewImage.map((img, index) => (
              <img
                src={img}
                alt=""
                srcSet=""
                key={index}
                className=" max-w-xs pb-3 pt-3 m-auto"
              />
            ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddECatalogue;
