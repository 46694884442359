import { rootApi } from "../../api/rootApi";


export const smsCredentialForTenantApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        getSmsCredentialWithLimitOffsetForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "GET",
                url: `/tenant/smsCredential/?limit=${params.limit}&&offset=${params.offset}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
              };
            },
          }),
        addSmsCredentialForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "POST",
                url: `/tenant/smsCredential/add`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
          updateSmsCredentialForTenant: builder.mutation({
            query: (params) => {
              console.log("params",params);
              return {
                method: "PUT",
                url: `/tenant/smsCredential/${params.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
          updateSmsCredentialStatusForTenant: builder.mutation({
            query: (params) => {
              console.log("params",params);
              return {
                method: "PATCH",
                url: `/tenant/smsCredential/${params.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
        deleteSmsCredentialForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "DELETE",
                url: `/tenant/smsCredential/delete/${params.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },              };
            },
          }),
        
    })

});
export const { useAddSmsCredentialForTenantMutation , useDeleteSmsCredentialForTenantMutation , useGetSmsCredentialWithLimitOffsetForTenantMutation , useUpdateSmsCredentialForTenantMutation ,useUpdateSmsCredentialStatusForTenantMutation} = smsCredentialForTenantApi;