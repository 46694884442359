import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useLocation } from "react-router-dom";
import {
  useDeleteStatesAndDistrictsOfZoneMutation,
  useGetAllDistrictLocationsByZoneMutation,
  useGetAllLocationsByZoneMutation,
} from "../../../features/tenant/zones/zonesApi";
import toast from "react-hot-toast";
import { useGetAllDistrictMutation } from "../../../features/tenant/locations/locations.api";

const ViewLocations = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const locations = useLocation();
  const zoneId = locations && locations.state ? locations.state.zone_id : null;
  const zoneName =
    locations && locations.state ? locations.state.zone_name : null;

  const [states, setStates] = useState(
    locations.state ? locations.state.states : {}
  );

  console.log("locations.state",locations);

  const [districts, setDistricts] = useState({});

  const [locationDistricts, setLocationDistricts] = useState([]);
  const [curState, setCurState] = useState(null);

  const [toggleViewDistrictPopUp, setToggleViewDistrictPopUp] = useState(false);
  const [
    getAllLocationsByZone,
    {
      data: getAllLocationsByZoneData,
      isLoading: getAllLocationsByZoneIsLoading,
      isError: getAllLocationsByZoneIsError,
      error: getAllLocationsByZoneError,
    },
  ] = useGetAllLocationsByZoneMutation();

  const [
    getAllDistrict,
    {
      data: getAllDistrictData,
      isLoading: getAllDistrictIsLoading,
      isError: getAllDistrictIsError,
      error: getAllDistrictError,
    },
  ] = useGetAllDistrictMutation();

  const [
    getAllDistrictLocationsByZone,
    {
      data: getAllDistrictLocationsByZoneData,
      isLoading: getAllDistrictLocationsByZoneIsLoading,
      isError: getAllDistrictLocationsByZoneIsError,
      error: getAllDistrictLocationsByZoneError,
    },
  ] = useGetAllDistrictLocationsByZoneMutation();
  const [
    deleteStatesAndDistrictsOfZone,
    {
      data: deleteStatesAndDistrictsOfZoneData,
      isLoading: deleteStatesAndDistrictsOfZoneIsLoading,
      isError: deleteStatesAndDistrictsOfZoneIsError,
      error: deleteStatesAndDistrictsOfZoneError,
    },
  ] = useDeleteStatesAndDistrictsOfZoneMutation();


  console.log("dist all",getAllDistrictData);
  console.log("dist all",getAllDistrictLocationsByZoneData);
  console.log("dist all",getAllLocationsByZoneData);

  const handleGetAllDistrictByStateId = (stateId) => {
    getAllDistrict({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      state_id: stateId,
    });
  };
  useEffect(() => {
    if (curState) {
      handleGetAllDistrictByStateId(curState);
    }
  }, [curState]);

  useEffect(() => {
    if (getAllDistrictData) {
      console.log("------>", getAllDistrictData);
      let allDistricts = {};
      getAllDistrictData.body.map((x) => {
        allDistricts[x.id] = x.district;
      });
      setDistricts(allDistricts);
      getAllDistrictLocationsByZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: zoneId,
        state_id: curState.toString(),
      });
    }
  }, [getAllDistrictData]);

  useEffect(() => {
    if (getAllDistrictLocationsByZoneData) {
      console.log("taost", getAllDistrictLocationsByZoneData);

      let allDistricts = [];

      getAllDistrictLocationsByZoneData.body.map((x) => {
      
          allDistricts.push({
            id: x.district_id,
            district: districts[x.district_id],
          });

      });

      setLocationDistricts(allDistricts);
    }
  }, [getAllDistrictLocationsByZoneData]);

  console.log("locaitons districts", locationDistricts);
  useEffect(() => {
    if (locations.state) {
      getAllLocationsByZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: locations.state.zone_id,
      });
    }
  }, []);

  useEffect(() => {
    if (getAllLocationsByZoneData) {
      toast.dismiss();
      toast.success(getAllLocationsByZoneData.message, {
        id: "getAllLocationsByZoneData",
      });

      getAllLocationsByZoneData.body.map((x) => {});
    }
    if (getAllLocationsByZoneError) {
      toast.dismiss();
      toast.error(getAllLocationsByZoneError.data.message, {
        id: "getAllLocationsByZoneError",
      });
    }
  }, [getAllLocationsByZoneData, getAllLocationsByZoneError]);
  console.log("state", curState);

  const handleDeleteDistrictsAndStates = (e, districtId) => {
    console.log("districtId ", districtId);
    deleteStatesAndDistrictsOfZone({
      token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: locations.state.zone_id.toString(),
        state_id : curState,
        district_id : districtId
    })
  };
  useEffect(()=>{
    if(deleteStatesAndDistrictsOfZoneData){
      toast.dismiss();
      toast.success(deleteStatesAndDistrictsOfZoneData.message, {
        id: "deleteStatesAndDistrictsOfZoneData",
      });
      getAllDistrictLocationsByZone({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        zone_id: zoneId,
        state_id: curState.toString(),
      });
    }
    if(deleteStatesAndDistrictsOfZoneError){
      toast.dismiss();
      toast.error(deleteStatesAndDistrictsOfZoneError.message, {
        id: "deleteStatesAndDistrictsOfZoneError",
      });

    }
  },[deleteStatesAndDistrictsOfZoneData,deleteStatesAndDistrictsOfZoneError])
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center  ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            ZONE : {zoneName}
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <div className="grid grid-cols-4 gap-4">
                {getAllLocationsByZoneData &&
                getAllLocationsByZoneData?.body.length > 0 ? (
                  getAllLocationsByZoneData.body.map((x) => {
                    return (
                      <div
                        className={`px-2 py-3 flex  justify-center items-center capitalize font-semibold rounded-sm text-center  text-white  `}
                        style={{ backgroundColor: `${primaryColor["700"]}` }}
                        onClick={() => {
                          setCurState(x.state_id);
                          setToggleViewDistrictPopUp(true);
                        }}
                      >
                        {
                          states.map(state=>{
                            if(state.id == x.state_id){
                              return (
                                <div>{state.name}</div>
                              )

                            }else{
                              return <></>
                            }
                          })
                        }
                      </div>
                    );
                  })
                ) : (
                  <div className="flex text-center "> No State Added Yet </div>
                )}
              </div>
            </div>
          </div>
          {toggleViewDistrictPopUp && (
            <ModalComponent
              size={"lg"}
              show={toggleViewDistrictPopUp}
              setShow={setToggleViewDistrictPopUp}
            >
              <div className="w-full ">
                <div className="flex justify-start p-3">
                  <ol class="grid grid-cols-3 gap-4 capitalize w-full">
                    {locationDistricts.map((x) => {
                      return (
                        <li className="border-blue-700 border-2 p-2 text-center rounded-sm font-semibold">
                          {x.district}{" "}
                          <button
                            className="mt-1 w-full flex justify-center  items-center rounded   text-xs font-medium capitalize  text-red-700"
                            onClick={(e) =>
                              handleDeleteDistrictsAndStates(e, x.id)
                            }
                          >
                            delete
                          </button>{" "}
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewLocations;
