import { rootApi } from "../../api/rootApi";

export const productHeirarchyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchHeirarchy: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/heirarchy`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchSubcategories: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/heirarchy/subcategories`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addHeirarchy: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/heirarchy/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    bulkAddHeirarchy: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/heirarchy/bulk-insert`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateHeirarchyById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/heirarchy/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deletedHeirarchyById: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/heirarchy/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchHeirarchyMutation,
  useFetchSubcategoriesMutation,
  useAddHeirarchyMutation,
  useBulkAddHeirarchyMutation,
  useUpdateHeirarchyByIdMutation,
  useDeletedHeirarchyByIdMutation,
} = productHeirarchyApi;
