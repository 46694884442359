import { rootApi } from "../../api/rootApi";

export const tropoliteApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    tropoliteBulkUploadOrders: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/tropolite/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    tropoliteGetAllOrders: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/tropolite`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body : params.body
        };
      },
    }),


    revertOrderPoints: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/tropolite/revert/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

   
  }),
});

export const {
 useTropoliteBulkUploadOrdersMutation , useTropoliteGetAllOrdersMutation , useRevertOrderPointsMutation
} = tropoliteApi;
