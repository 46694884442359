import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
export default function ModalComponent({
  size,
  show,
  setShow,
  data,
  children,
}) {
  const [isOpen, setIsOpen] = useState(show);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
  .color_shades;

  function closeModal() {
    setIsOpen(false);
    setShow(false);
  }

  let width = "max-w-3xl";
  if (size === "sm") {
    width = "max-w-sm";
  } else if (size === "md") {
    width = "max-w-md";
  } else if (size === "lg") {
    width = "max-w-lg";
  } else if (size === "xl") {
    width = "max-w-xl";
  } else if (size === "2xl") {
    width = "max-w-2xl";
  } else if (size === "3xl") {
    width = "max-w-3xl";
  } else if (size === "4xl") {
    width = "max-w-4xl";
  }else if(size === "5xl"){
    width = "max-w-5xl";

  }else if(size === "6xl"){
    width = "max-w-6xl";

  }else if(size === "7xl"){
    width = "max-w-7xl";

  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 max-w" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full transform  rounded-xl bg-white  text-left align-middle shadow-xl transition-all ${width}`}
                  style={{ border: `2px solid ${primaryColor["700"]}` }}
                >
                  <Dialog.Title
                    as="div"
                    className="text-lg text-center font-medium leading-6 text-gray-900"
                  >
                    <div className="w-full flex justify-between">
                        <div></div>
                      <div className="cursor-pointer w-7 h-7 absolute -right-3 -top-3"  onClick={closeModal}>
                      <svg class="h-7 w-7 bg-red-700 text-white p-1 rounded-sm"  fill="none" viewBox="0 0 24 24" stroke="currentColor" >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
</svg>
                        {/* <IoIosCloseCircle className="w-full h-full" /> */}
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className="mx-auto py-0">
                    <div className=" ">
                      {children}
                    </div>
                  </div>

                  {/* <div className="mt-4 text-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-black-900 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
