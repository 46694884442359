import { rootApi } from "../../api/rootApi";

export const supportQueriesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllsupportQueries: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/supportQueries`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    countAllsupportQueries: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/supportQueries/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchsupportQueriesById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/supportQueries/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateSupportQueriesById: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/supportQueries/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useFetchAllsupportQueriesMutation,
  useCountAllsupportQueriesMutation,
  useFetchsupportQueriesByIdMutation,
  useUpdateSupportQueriesByIdMutation
} = supportQueriesApi;
