import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useFetchAllAppUserTypeQuery } from "../../../features/admin/AppUserType/appUserTypeApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const AppUserType = () => {

    const navigate = useNavigate();

  const {
    data: allAppUserTypeData,
    isError: allAppUserTypeIsError,
    error: allAppUserTypeError,
    isLoading: allAppUserTypeIsLoading,
  } = useFetchAllAppUserTypeQuery({
    token: sessionStorage.getItem("access_token"),
  });

  useEffect(()=>{

    if(allAppUserTypeData){
        toast.dismiss();
        toast.success(`${allAppUserTypeData.message}`,{
            id : `${allAppUserTypeData}`
        })
    }

  },[allAppUserTypeData])

  if(allAppUserTypeIsError){
    toast.dismiss();
    toast.error("Ops something went wrong",{
        id : "allAppUserTypeError",
    })
  }
  if(allAppUserTypeIsLoading){
    toast.dismiss();
    toast.loading("Loading...",{
        id : "allAppUserTypeLoading",
    })
  }
 
  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Visible Name",
      selector: (row) => row.name,
      sortable: false,
      allowOverflow: false,
    },
    {
      name: "created at",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: false,
      allowOverflow: false,
    },
    // {
    //   name: "Action",
    //   cell: (r) => (
    //     <button
    //       className="btn btn-outline-success btn-sm"
    //       onClick={() => handleButtonEditClick(r)}
    //     >
    //       Edit
    //     </button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];
  return (
    <div className="main-wrapper">
      <div className="page-content">
        <div className="card p-4">
        <div className="borderbtm">
          <div>
            <h4 className="mb-3 mb-md-0 card-title">Welcome to App Users Master</h4>
          </div>
          {/* <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              onClick={() => navigate("/addAppUserType")}
            >
              Add App User Type
            </button>
          </div> */}
        </div>

        <div className="row">
          <div className="col-12 col-xl-12">
            <DataTable
              title="App Users"
              data={allAppUserTypeData && allAppUserTypeData.body}
              columns={columns}
              //selectableRows
              //   onSelectedRowsChange={handleChange}
            />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default AppUserType;
