import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFetchVendorsQuery } from "../../../features/admin/vendor/vendorApi";
import { useFetchDwamQuery } from "../../../features/admin/dwam/dwamApi";
import { useFetchRewardifyQuery } from "../../../features/admin/rewardify/rewardifyApi";
import { useFetchGenuinemarkQuery } from "../../../features/admin/genuinemark/genuinemarkApi";
import { useAddWorkflowMutation } from "../../../features/admin/workflow/workflowApi";
import {
  createBoardObject,
  createColumsObject,
} from "../../../utils/createBoardObject";
import { getUserPrograms } from "../../../utils/getUserPrograms";
import boardsSlice from "../../../features/admin/workflow/boardSlice";
import WorkflowBoard from "../../../components/Admin/WorkflowBoard";
import ReorderList from "../../../components/ReorderList/ReorderList";
import { Box, Fade, Backdrop, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const AddWorkflow = () => {
  const [vendorsList, setVendorsList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(0);
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [boardObject, setBoardObject] = useState(null);
  const [allProgramOptions, setAllProgramOptions] = useState([]);

  const [orderList, setOrderList] = useState([]);
  const [finalProgramList, setFinalProgramList] = useState(null);

  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const {
    data: vendorsData,
    isError: vendorsIsError,
    isLoading: vendorsIsLoading,
    error: vendorsError,
  } = useFetchVendorsQuery(sessionStorage.getItem("access_token")) || {};

  const {
    data: dwamData,
    isError: dwamIsError,
    isLoading: dwamIsLoading,
    error: dwamError,
  } = useFetchDwamQuery(sessionStorage.getItem("access_token")) || {};

  const {
    data: rewardifyData,
    isError: rewardifyIsError,
    isLoading: rewardifyIsLoading,
    error: rewardifyError,
  } = useFetchRewardifyQuery(sessionStorage.getItem("access_token")) || {};

  const {
    data: genuinemarkData,
    isError: genuinemarkIsError,
    isLoading: genuinemarkIsLoading,
    error: genuinemarkError,
  } = useFetchGenuinemarkQuery(sessionStorage.getItem("access_token")) || {};

  const [
    addWorkflow,
    {
      data: workflowData,
      isLoading: workflowIsLoading,
      isError: workflowIsError,
      error: workflowError,
    },
  ] = useAddWorkflowMutation();

  useMemo(() => {
    if (vendorsData) {
      (() => {
        setVendorsList(vendorsData.body);
      })();
    }
    if (vendorsError) {
      toast.error(vendorsError.error, {
        id: "vendorsError",
      });
    }
  }, [vendorsData, vendorsError]);

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    vendorsList.forEach(function (data) {
      if (data.company_id == event.target.value) {
        setSelectedVendorData(data);
        setUsersList(data.user_types);
      }
    });
  };

  useEffect(() => {
    if (dwamData?.body && rewardifyData?.body && genuinemarkData?.body) {
      const rdata = createColumsObject("Rewardify", rewardifyData.body);
      const dData = createColumsObject("DWAN", dwamData.body);
      const gData = createColumsObject("Genuinity", genuinemarkData.body);
      const data = [];
      data.push(rdata);
      data.push(dData);
      data.push(gData);
      setAllProgramOptions(data);
    }
  }, [dwamData, rewardifyData, genuinemarkData]);

  useEffect(() => {
    toast.dismiss();
    // console.log(selectedUser , selectedVendor.product_availing);
    if (selectedUser && selectedVendorData.product_availing) {
      const selectedPrograms = getUserPrograms(
        selectedUser,
        selectedVendorData.product_availing,
      );
      if (allProgramOptions.length > 0 && selectedPrograms.length > 0) {
        // console.log("hereeeeee", selectedUser ,  selectedPrograms);
        const data = createBoardObject(selectedPrograms, allProgramOptions);
        setBoardObject(data);
        dispatch(boardsSlice.actions.initializeBoard(data));
      } else {
        if (selectedUser !== 0) {
          dispatch(
            boardsSlice.actions.initializeBoard([
              {
                columns: [],
                isActive: true,
              },
            ]),
          );
        }
      }
    } else {
      dispatch(
        boardsSlice.actions.initializeBoard([
          {
            columns: [],
            isActive: true,
          },
        ]),
      );
    }
  }, [selectedUser, selectedVendor]);

  const resetWorkflowBoard = () => {
    if (boards && selectedVendorData && selectedUser) {
      dispatch(boardsSlice.actions.initializeBoard(boardObject));
    } else {
      return;
    }
  };

  const handleSubmit = () => {
    console.log("Submit", finalProgramList);
    const programs = [];
    finalProgramList.forEach((each) => {
      programs.push(each.id);
    });

    var body = {
      tenant_id: selectedVendorData.tenant_id,
      tenant_name: selectedVendorData.name,
      industry_id: selectedVendorData.industry_id,
      industry_name: selectedVendorData.industry_name,
      user_type: selectedUser,
      program: programs,
    };

    addWorkflow({
      token: sessionStorage.getItem("access_token"),
      body: body,
    });
  };

  const previewButton = () => {

    console.log(selectedUser , selectedVendorData);

    if (boards && selectedVendorData && selectedUser) {
      const board = boards.find((board) => board.isActive === true);
      
      const programs = [];
      const itemList = []; 

      if(board.columns && board.columns[0] && board.columns[0].tasks ){
      board.columns[0].tasks.forEach((task) => {
        programs.push(task.title);
      });

      for (let i = 0; i < programs.length; i++) {
        const element = programs[i];
        itemList.push({
          id: element,
          order: i + 1,
        });
      }

      if (itemList.length > 0) {
        setOrderList(itemList);
        setOpen(true);
        setFinalProgramList(itemList);
        return;
      }
    }else {
      toast.error("No Programs Selected for this user" , {
        id : "noProgramsError"
      })
    }

    } else {
      return;
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
   

    if (workflowData) {
      toast.success(workflowData.message);
      setTimeout(() => {
        navigate("/workflowSetup");
      }, 500);
    }

    if (workflowError) {
      toast.error(workflowError.data.message + " for this user", {
        id: "workflowError",
      });
      setOpen(false);
      resetWorkflowBoard();
    }
  }, [workflowData, workflowError]);

  return (
    <>
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add New Workflow</h4>
                <div className="row">
                  <div className="col-12 col-xl-6 col-xs-12">
                    <label className="text-capitalize font-weight-bold">
                      Choose Client
                    </label>
                    <select
                      className="js-example-basic-single w-100"
                      value={selectedVendor}
                      onChange={handleVendorChange}
                    >
                      <option value={0}>Select Client</option>
                      {vendorsList &&
                        vendorsList.map((vendor) => (
                          <option key={vendor.id} value={vendor.company_id}>
                            {vendor.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-12 col-xl-6 col-xs-12">
                    <label className="text-capitalize font-weight-bold">
                      Choose User
                    </label>
                    <select
                      className="js-example-basic-single w-100"
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                    >
                      <option value={0}>Select User</option>
                      {usersList &&
                        usersList.map((user, idx) => (
                          <option key={idx} value={user}>
                            {user}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-5" style={{ minHeight: "300px" }}>
                  {boardObject && <WorkflowBoard />}
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <button
                      className="btn btn-outline-primary mr-2 w-100"
                      onClick={previewButton}
                    >
                      Preview
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="btn btn-outline-secondary mr-2 w-100"
                      onClick={resetWorkflowBoard}
                    >
                      Reset Board
                    </button>
                  </div>
                </div>

                <div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <Box sx={style}>
                        <Typography
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          className="text-center"
                        >
                          {selectedUser &&
                          selectedUser.charAt(0).toUpperCase() +
                            selectedUser.slice(1)} Workflow
                        </Typography>
                        {orderList.length > 0 && (
                          <ReorderList
                            list={orderList}
                            setList={setFinalProgramList}
                          />
                        )}
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <button
                            className="btn btn-primary mt-3"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWorkflow;
