export const csvFileToArray = (string) => {
    const array = string.toString().split("\r\n");
    const data = [];
    for (const r of array) {
      const row = r.toString().split(",");
      // console.log("row" , row);
      data.push(row);
    }
    const heading = data[0];
    //  to get the column headers which will act as key
    console.log(heading);
    const ans_array = [];
    for (let i = 1; i < data.length; i++) {
      let row = data[i];
      let obj = {};
      for (let j = 0; j < heading.length; j++) {
        if (!row[j]) {
          row[j] = "NA";
        }
        obj[heading[j].replaceAll(" ", "_")] = row[j]
          .toString()
          .replaceAll(" ", "_");
      }
      ans_array.push(obj);
    }

    console.log(ans_array);
    return ans_array;
  }; 