import { rootApi } from "../../api/rootApi";

export const pincodeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationByPincode: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/app/pincode/"+params.pincode,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: `${params.tenant_id}`,
          },
        };
      },
    }),
  }),
});

export const { useGetLocationByPincodeMutation } = pincodeApi;
