import { rootApi } from "../../api/rootApi";

export const tenantDashboardApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenantDashboards: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/tenantDashboard`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});
export const { useGetAllTenantDashboardsMutation } = tenantDashboardApi;
