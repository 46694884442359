import { rootApi } from "../../api/rootApi";

export const userRoleApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addUserRole: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/userRole/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getAllRoles: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/userRole`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getUserRoleById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/userRole/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateUserRoleById: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/userRole/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    deleteUserRole: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/userRole/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useAddUserRoleMutation,
  useGetAllRolesMutation,
  useGetUserRoleByIdMutation,
  useUpdateUserRoleByIdMutation,
  useDeleteUserRoleMutation,
} = userRoleApi;
