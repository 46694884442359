import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";

import { HiEye } from "react-icons/hi2";
import { useFetchAllOrderBatchesMutation } from "../../../features/tenant/Orders/ordersApi";
import {
  useDeletePrinterByIdMutation,
  useFetchAllPrintersMutation,
} from "../../../features/tenant/qrPrinter/qrPrinterApi";

const PrintersList = () => {
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "90px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "250px",
    },
    {
        name: "Local IP Address",
        selector: (row) => row.local_ip,
        sortable: true,
        width: "300px",
      },
    {
      name: "Command",
      selector: (row) => row.command,
      sortable: true,
      width: "300px",
    },

    {
      name: "Data",
      selector: (row) => JSON.stringify(row.data),
      sortable: true,
      width: "300px",
    },
    // {
    //   name: "",
    //   selector: (row) => (
    //     <button
    //       className="flex justify-center items-center rounded bg-green-500 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#28a745] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(40,167,69,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)]"
    //       onClick={() =>
    //         navigate("batch", {
    //           state: row,
    //         })
    //       }
    //     >
    //       <HiEye className="mr-0.5" />
    //       View
    //     </button>
    //   ),
    //   sortable: true,
    //   width: "110px",
    // },
    {
      name: "",
      selector: (row) =>
        editPrinterPermission && (
          <button
            className="flex justify-center items-center rounded bg-blue-500 px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#28a745] transition duration-150 ease-in-out hover:bg-blue-600 hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:bg-blue-600 focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.3),0_4px_18px_0_rgba(40,167,69,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(40,167,69,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(40,167,69,0.2),0_4px_18px_0_rgba(40,167,69,0.1)]"
            onClick={() =>
              navigate("add", {
                state: row,
              })
            }
          >
            <AiOutlineEdit className="mr-0.5" />
            Edit
          </button>
        ),
      sortable: true,
      width: "110px",
    },
    {
      name: "",
      selector: (row) =>
        deletePrinterPermission && (
          <div>
            <button
              onClick={() => handleDelete(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      width: "140px",
    },
  ];

  const navigate = useNavigate();

  const [fetchAllPrinters, { data: printerListData, error: printerListError }] =
    useFetchAllPrintersMutation();

  const addPrinterPermission = hasPermission("addPrinter");
  const editPrinterPermission = hasPermission("editPrinter");
  const deletePrinterPermission = hasPermission("deletePrinter");

  useEffect(() => {
    const hasViewPermission = hasPermission("printerList");

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    fetchAllPrinters({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (printerListError) {
      console.log("printerListError", printerListError);
      toast.error("Something went wrong", { id: "printerListError" });
    }
  }, [printerListError]);

  const [
    deletePrinter,
    { data: deletePrinterData, error: deletePrinterError },
  ] = useDeletePrinterByIdMutation();

  const handleDelete = (data) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deletePrinter({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                  id: data.id,
                });
                fetchAllPrinters({
                  token: sessionStorage.getItem("access_token"),
                  tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))
                    .tenant_id,
                });
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deletePrinter",
      },
    );
    return;
  };

  useEffect(() => {
    if (deletePrinterData) {
      console.log("DeleteMembership ", deletePrinterData);
      toast.success("Deleted Successfully.");
    }

    if (deletePrinterError) {
      toast.error("Something went wrong", {
        id: "deletePrinterError",
      });
    }
  }, [deletePrinterError, deletePrinterData]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Printers List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={printerListData ? printerListData.body.data : []}
                // loading={loading}
                totalRows={printerListData ? printerListData.body.count : 0}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
              {addPrinterPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintersList;
