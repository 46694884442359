import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import validator from "validator";
import { useAdminLoginMutation } from "../../../features/admin/adminAuth/adminAuthApi";
import { setAdminCredential } from "../../../features/admin/adminAuth/adminAuthSlice";
import genefiedlogo from "../../../assests/tenant/images/genefied-logo.png";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import Loader from "../../../components/Loader/Loader";

const LoginAdmin = ({ setAuthorized, url }) => {
  console.log("env", process.env.REACT_APP_BASE_URL);
  const dispatch = useDispatch();

  const [
    loginAdmin,
    {
      data: adminData,
      isLoading: adminIsLoading,
      isError: adminIsError,
      error: adminError,
    },
  ] = useAdminLoginMutation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passError, setPassError] = useState(null);
  const [rePassError, setRePassError] = useState(null);

  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(null);

  const [register, setRegister] = useState(true);

  // const handleLogin = () => {
  //   alert("user tried to login");
  //   if (emailError == null && passError == null) {
  //     var data = JSON.stringify({
  //       email: email,
  //       password: password,
  //     });

  //     var config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_BASE_URL}/admin/login`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         headers: {
  //           Authorization: "Bearer " + sessionStorage.getItem("access_token"),
  //         },
  //         withCredentials: true,
  //       },
  //       data: data,
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         if (response.data.success) {
  //           console.log(response.data);
  //           sessionStorage.setItem("access_token", response.data.body.token);
  //           sessionStorage.setItem("authorized", true);
  //           sessionStorage.setItem("adminId", response.data.body.admin_id);
  //           setAuthorized(true);
  //         } else {
  //           alert(response.data.message);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         alert("Try Again After sometime");
  //       })
  //       .finally(() => resetValues());
  //   }
  // };

  const resetValues = () => {
    setEmail("");
    setPassword("");
    setName("");
    setRePassword("");
    setPhone("");
  };

  const handleLogin = () => {
    toast.loading("Trying to login.....", { id: "tryLogin" });
    if (emailError == null && passError == null) {
      var data = {
        email: email,
        password: password,
      };

      loginAdmin(data);
    } else {
      resetValues();
    }
  };

  // useEffect is used to set dispatch the action
  useEffect(() => {
    if (adminData) {
      toast.dismiss();
      toast.success("Login Successfull", { id: "loginSucess" });
      sessionStorage.setItem("access_token", adminData.body.token);
      sessionStorage.setItem("authorized", true);
      sessionStorage.setItem("adminId", adminData.body.admin_id);
      sessionStorage.setItem("user", JSON.stringify(adminData.body));
      dispatch(setAdminCredential(adminData.body));
      setAuthorized(true);
      console.log("object");
    }

    if (adminError) {
      toast.dismiss();
      toast.error("Invalid Credentials");
      console.log("admin error", adminError);
    }
  }, [adminData, adminError]);

  useMemo(() => {
    if (adminIsError) {
      resetValues();
    }
  }, [adminIsError]);

  const handleSignup = () => {
    alert("user tried to sign up ");

    if (
      passError == null &&
      rePassError == null &&
      emailError == null &&
      phoneErr == null
    ) {
      var data = JSON.stringify({
        email: email,
        password: password,
        phone: phone,
        name: name,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/admin/add`,
        headers: {
          slug: "tenant1",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          alert(JSON.stringify(response.data));
          //  //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          resetValues();
        });
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setRegister(!register);
  };

  const handlePhoneChange = (event) => {
    if (
      !validator.isMobilePhone(event.target.value, ["en-IN"]) &&
      event.target.value > 0
    ) {
      setPhoneErr("Phone no Invalid");
    } else {
      setPhoneErr(null);
    }

    setPhone(event.target.value);
  };

  const handleEmailChange = (event) => {
    console.log(validator.isEmail(event.target.value));
    if (
      !validator.isEmail(event.target.value) &&
      event.target.value.length > 0
    ) {
      setEmailError("Email is invalid");
    } else {
      setEmailError(null);
    }

    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    if (
      event.target.value.length > 0 &&
      !validator.isStrongPassword(event.target.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPassError(
        "Password is not strong , must include a number , symbol , uppercase and lowercase ",
      );
    } else {
      setPassError(null);
    }

    setPassword(event.target.value);
  };

  const handleRePasswordChange = (event) => {
    if (event.target.value !== password) {
      setRePassError("Pasword Not Matching");
    } else {
      setRePassError(null);
    }

    setRePassword(event.target.value);
  };

  if (adminIsLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page bgblue">

<div className="page-content d-flex align-items-center justify-content-center">
      <div className="d-md-flex justify-between w-8/12 md100 md:w-full lg:w-8/12 items-center">
  <div className="w-2/5 md100  text-center">
    <span className="text-center d-block"> <img src={genefiedlogo} className="max-h-full max-w-full h-48 m-auto"/></span>
  </div>

  <div className="w-3/5 md100 px-5 py-0 md-pd0 border-l border-blue-600 bl0">
                {register ? (
                  //Login
                  <div className="w-full">                    
                      <div className="mb-4 text-white">
                        <span>Welcome back!</span> 
                        <h1 className="font-bold text-2xl">Log in to your account.</h1>
                      </div>
                      <div className="forms-sample">
                        <div className="form-group mb-4">
                          <label className="text-white">Email address</label>
                          <input
                            type="email"
                            className="form-control h-auto rounded-full px-4 py-3"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email"
                          />
                          <p className="text-danger  mt-1">{emailError}</p>
                        </div>
                        <div className="form-group mb-4">
                          <label className="text-white">Password</label>
                          <input
                            type="password"
                            className="form-control h-auto rounded-full px-4 py-3"
                            id="exampleInputPassword1"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Password"
                          />
                          <p className="text-danger  mt-1">{passError}</p>
                        </div>
                      </div>
                      <div className="form-check form-check-flat form-check-primary ml-4">
                        <input type="checkbox" className="form-check-input h-5 w-4" />
                        <label className="form-check-label text-white ml-1">
                          Remember me
                        </label>
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn bg-black px-5  py-3 text-white rounded-full"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      </div>
                      {/* <button
                        className="d-block mt-3 text-muted"
                        onClick={handleChange}
                      >
                        Not a user? Sign up
                      </button> */}
                    </div>
                ) : (
                  //Signup
                  <></>
                  //   <div className="col-md-12 pl-md-0">
                  //     <div className="auth-form-wrapper px-4 py-5">
                  //       <a href=" " className="noble-ui-logo d-block mb-2">
                  //         Genefied<span></span>
                  //       </a>
                  //       <h5 className="text-muted font-weight-normal mb-4">
                  //         Create a free account.
                  //       </h5>
                  //       <form className="forms-sample">
                  //         <div className="form-group">
                  //           <label>Full Name</label>
                  //           <input
                  //             type="text"
                  //             className="form-control"
                  //             id="exampleInputUsername1"
                  //             placeholder="Full Name"
                  //             value={name}
                  //             onChange={(e) => setName(e.target.value)}
                  //           />
                  //         </div>
                  //         <div className="form-group">
                  //           <label>Email address</label>
                  //           <input
                  //             type="email"
                  //             className="form-control"
                  //             id="exampleInputEmail1"
                  //             onChange={handleEmailChange}
                  //             placeholder="Email"
                  //           />
                  //           <p className="text-danger p-1 m-1">{emailError}</p>
                  //         </div>
                  //         <div className="form-group">
                  //           <label>Phone No</label>
                  //           <input
                  //             type="text"
                  //             className="form-control"
                  //             id="exampleInputUsername1"
                  //             value={phone}
                  //             placeholder="9988776655"
                  //             onChange={handlePhoneChange}
                  //           />
                  //           <p className="text-danger p-0 m-0">{phoneErr}</p>
                  //         </div>
                  //         <div className="form-group">
                  //           <label>Password</label>
                  //           <input
                  //             type="password"
                  //             className="form-control"
                  //             value={password}
                  //             onChange={handlePasswordChange}
                  //             placeholder="Password"
                  //           />
                  //           <p className="text-danger p-1 m-1">{passError}</p>
                  //         </div>
                  //         <div className="form-group">
                  //           <label>Re-Password</label>
                  //           <input
                  //             type="password"
                  //             className="form-control"
                  //             value={rePassword}
                  //             onChange={handleRePasswordChange}
                  //             placeholder="Re-Password"
                  //           />
                  //           <p className="text-danger p-2 m-2">{rePassError}</p>
                  //         </div>
                  //         <div className="form-check form-check-flat form-check-primary ml-4">
                  //           <input type="checkbox" className="form-check-input" />
                  //           <label className="form-check-label ml-2">
                  //             Remember me
                  //           </label>
                  //         </div>
                  //         <div className="mt-3">
                  //           <button
                  //             className="btn btn-primary text-white mr-2 mb-2 mb-md-0"
                  //             onClick={handleSignup}
                  //           >
                  //             Sign up
                  //           </button>
                  //         </div>
                  //         <button
                  //           className="d-block mt-3 text-muted"
                  //           onClick={handleChange}
                  //         >
                  //           Already a user? Sign in
                  //         </button>
                  //       </form>
                  //     </div>
                  //   </div>
                )}
              </div>
  </div>
</div>



        
      </div>
    </div>
  );
};

export default LoginAdmin;
