import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddPrinterMutation,
  useUpdatePrinterByIDMutation,
} from "../../../features/tenant/qrPrinter/qrPrinterApi";

const printerTypes = [
  {
    id: 1,
    name: "TSC",
  },
  {
    id: 2,
    name: "Zebra",
  },
];

const AddPrinter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isUpdate, setIsUpdate] = useState(location.state ? true : false);

  const [selectedPrinterType, setselectedPrinterType] = useState({
    id: -1,
    name: "Select Type",
  });

  const [printerName, setprinterName] = useState(null);
  const [printerCommand, setPrinterCommand] = useState(null);

  const [localIp, setLocalIp] = useState(null);

  const [printerJsonData, setPrinterJsonData] = useState(null);

  useEffect(() => {
    if (isUpdate) {
      const state = location.state;
      setprinterName(state.name);
      setPrinterCommand(state.command);
      setLocalIp(state.local_ip);
      if(state.type === "TSC"){
        setselectedPrinterType(printerTypes[0]);
      }else {
        setselectedPrinterType(printerTypes[1]);
      }
      setPrinterJsonData(state.data);
    }
  }, [location.state]);

  const [
    updatePrinterFunc,
    { data: updatePrinterData, error: updatePrinterError },
  ] = useUpdatePrinterByIDMutation();

  const handleUpdate = (e) => {
    e.preventDefault();
    if (isUpdate) {
      const data = {
        brand: selectedPrinterType.name,
        name: printerName,
        command: printerCommand,
        local_ip: localIp,
        data: JSON.stringify(printerJsonData),
      };
      updatePrinterFunc({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: data,
        id: Number(location.state.id),
      });
    }
  };

  useEffect(() => {
    if (updatePrinterData) {
      toast.success("Printer Details Updated", {
        id: "updateBatchSuccess",
      });
      setTimeout(() => {
        navigate(-1);
      }, 300);
    }

    if (updatePrinterError) {
      toast.error(updatePrinterError.data.message, {
        id: "updatePrinterError",
      });
    }
  }, [updatePrinterData, updatePrinterError]);

  const [addPrinterFunc, { data: addPrinterData, error: addPrinterError }] =
    useAddPrinterMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedPrinterType.id === -1) {
      return;
    }
    const data = {
      type: selectedPrinterType.name,
      name: printerName,
      command: printerCommand,
      local_ip: localIp,
      data: JSON.stringify(printerJsonData),
    };
    addPrinterFunc({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: data,
    });
  };

  useEffect(() => {
    if (addPrinterData) {
      console.log("add printer data", addPrinterData);
      toast.success("Printer Added Successfully", {
        id: "printerSuccess",
      });
      setTimeout(() => {
        navigate(-1);
      }, 300);
    }

    if (addPrinterError) {
      console.log("printer error", addPrinterError);
      toast.error(addPrinterError.data.message, {
        id: "addPrinterError",
      });
    }
  }, [addPrinterData, addPrinterError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update" : "Add"} Printer
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className=" p-3 w-full bg-white shadow rounded-lg">
              <form className="">
                <div className="w-full flex justify-center space-x-4">
                  {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                  <div className="mb-6 bottom-2 w-1/4 dropds">
                    <SelectComponent
                      title={"Type"}
                      selected={selectedPrinterType}
                      setSelected={setselectedPrinterType}
                      dataArr={printerTypes}
                    />
                  </div>
                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Printer Name"}
                      type={"text"}
                      value={printerName}
                      setValue={setprinterName}
                      required={true}
                    />
                  </div>

                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Local Ip"}
                      type={"text"}
                      value={localIp}
                      setValue={setLocalIp}
                      required={true}
                    />
                  </div>

                  <div className="mb-6 bottom-2 w-1/4">
                    <InputComponent
                      name={"Command"}
                      type={"text"}
                      value={printerCommand}
                      setValue={setPrinterCommand}
                      required={true}
                    />
                  </div>
                </div>
                {selectedPrinterType?.id == 1 && (
                  <div className="mb-6 w-full">
                    <InputComponent
                      name={"Data"}
                      type={"textarea"}
                      value={printerJsonData}
                      setValue={setPrinterJsonData}
                      required={true}
                      rows={20}
                    />
                  </div>
                )}

                <div className="flex justify-end mt-2">
                  {isUpdate ? (
                    <button
                      type="update"
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Add
                    </button>
                  )}
                </div>
              </form>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPrinter;
