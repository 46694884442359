import { rootApi } from "../../api/rootApi";


export const whatsappNotificationCredentialForTenantApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        getWhatsappNotificationCredentialWithLimitOffsetForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "GET",
                url: `/tenant/whatsapp-notification-credentials/?limit=${params.limit}&&offset=${params.offset}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
              };
            },
          }),
        addwhatsappNotificationCredentialForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "POST",
                url: `/tenant/whatsapp-notification-credentials/add`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
          updatewhatsappNotificationCredentialForTenant: builder.mutation({
            query: (params) => {
              console.log("params",params);
              return {
                method: "PUT",
                url: `/tenant/whatsapp-notification-credentials/${params.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
          updatewhatsappNotificationCredentialStatusForTenant: builder.mutation({
            query: (params) => {
              console.log("params",params);
              return {
                method: "PATCH",
                url: `/tenant/whatsapp-notification-credentials/statusUpdate/${params.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },
                body: params.body,
              };
            },
          }),
        deletewhatsappNotificationCredentialForTenant: builder.mutation({
            query: (params) => {
              return {
                method: "DELETE",
                url: `/tenant/whatsapp-notification-credentials/delete/${params.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    slug: params.tenant_id,
                },              };
            },
          }),
        
    })

});
export const { useAddwhatsappNotificationCredentialForTenantMutation ,
useDeletewhatsappNotificationCredentialForTenantMutation,useGetWhatsappNotificationCredentialWithLimitOffsetForTenantMutation,
useUpdatewhatsappNotificationCredentialForTenantMutation,useUpdatewhatsappNotificationCredentialStatusForTenantMutation } = whatsappNotificationCredentialForTenantApi;