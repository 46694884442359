import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useAddAppBannerMutation,
  useDeleteAppBannerMutation,
  useGetAppBannerMutation,
} from "../../../../features/tenant/appBanner/appBanner.Api";
import { Menu } from "@headlessui/react";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import { toast } from "react-hot-toast";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../../utils/hasPermission";
import { getVendorName } from "../../../../utils/getVendorName";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";

const bannerType = [
  { id: 1, name: "product" },
  { id: 2, name: "promotion" },
  { id: 3, name: "information" },
  { id: 4, name: "support" },
  { id: 5, name: "feedback" },
];

const BannerList = () => {
  const [bannerImg, setBannerImg] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [images, setImages] = useState([]);
  const [formImg, setFormImg] = useState("");
  const [type, setType] = useState(bannerType[0]);
  const [name, setName] = useState();

  const [
    getAllBanner,
    {
      data: allBannerData,
      isLoading: allBannerLoading,
      isError: allBannerIsError,
      error: allBannerError,
    },
  ] = useGetAppBannerMutation();

  const getAll = () => {
    getAllBanner({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const hasViewPermission = hasPermission("bannerList");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    getAll();
  }, []);

  useEffect(() => {
    if (allBannerData && allBannerData.body) {
      const responseArray = Object.values(allBannerData.body);
      console.log("responseArray", responseArray);
      const imagesArr = [];
      for (let i = 0; i < responseArray.length; i++) {
        imagesArr.push(responseArray[i]);
        // setBannerImg((prevState) => [...prevState, responseArray[i].image[0]]);
        // setBannerData((prevState) => [...prevState, responseArray[i]]);
      }
      setBannerImg(imagesArr);
    }
  }, [allBannerData]);

  const [
    addBanner,
    {
      data: addAppBannerData,
      isLoading: addAppBannerIsLoading,
      isError: addAppBannerIsError,
      error: addAppBannerError,
    },
  ] = useAddAppBannerMutation();

  const [
    deleteAppBanner,
    {
      data: deleteAppBannerData,
      isLoading: deleteAppBannerIsLoading,
      isError: deleteAppBannerIsError,
      error: deleteAppBannerError,
    },
  ] = useDeleteAppBannerMutation();

  useEffect(() => {
    if (deleteAppBannerData) {
      getAll();
      toast.success("Banner deleted successfully!");
      return;
    }
    if (deleteAppBannerError) {
      toast.success("Oops Something went Wrong!");
    }
  }, [deleteAppBannerData, deleteAppBannerError]);

  // useDeleteAppBannerMutation
  useEffect(() => {
    if (addAppBannerData) {
      getAll();
      toast.success("Banner Added");
      setImages("");
      setFormImg(null);
      setShowModal(!showModal);
    }
  }, [addAppBannerData, addAppBannerError]);

  const deleteBanner = (id) => {
    deleteAppBanner({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: id,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please provide The Name", {
        id: "imageError",
      });
      return;
    }

    if (!type?.name) {
      toast.error("Please provide The Type", {
        id: "imageError",
      });
      return;
    }

    if (images.length === 0) {
      toast.error("Please provide at least one image", {
        id: "imageError",
      });
      return;
    }

    const formData = new FormData();
    formData.append("image", formImg);
    formData.append("name", name);
    formData.append("type", type.name);

    addBanner({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: formData,
    });
  };
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (type, data) => {
    if (type === "add") {
      setShowModal(true);
      // setSelectedBrandId(-1);
      // setBrandName(null);
    }

    if (type === "edit") {
      // setSelectedBrandId(Number(data.id));
      // setBrandName(data.name);
      setShowModal(true);
    }
  };

  const previewImage = (event) => {
    setFormImg(event.target.files[0]);
    const imageFiles = event.target.files;
    const imageFilesLength = imageFiles.length;
    let img = [];
    if (imageFilesLength && imageFiles.length) {
      for (let i = 0; i < imageFilesLength; i++) {
        const imageSrc = URL.createObjectURL(imageFiles[i]);
        img.push(imageSrc);
      }
      setImages(img);
    }
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const deleteBannerToast = (id) => {
    toast(
      (t) => (
        <span>
          <h6>
            Are you sure you want to delete this<b> Banner ?</b>
          </h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteBanner(id);
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deletebanner",
      }
    );
    return;
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Banner List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <div className="">
                <h2 className="sr-only">Banners</h2>

                <div className="grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-3">
                  {bannerImg.length
                    ? bannerImg.map((res) => {
                        return (
                          <span href="#" className="group">
                            {res.image && res.image.length > 0 && (
                              <>
                                {/* <div
                                  className="relative ml-auto flex justify-center items-center rounded-full top-2 font-extrabold  "
                                  onClick={() =>
                                    {
                                      console.log("clicked item");
                                      // deleteBanner(res.id)
                                    }
                                    }
                                >
                                  
                                </div> */}

                                <div className="relative text-center aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg  xl:aspect-h-8 xl:aspect-w-7">
                                 
<div className="h-32">                              <img
                                    src={
                                      
                                      res?.image[0]
                                    }
                                    alt={res?.image[0]}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                  /></div>    
                                  <div className="absolute top-2 w-full h-full flex justify-center items-center">
                                    <button
                                      type="submit"
                                      onClick={() => {
                                        toast.dismiss();
                                        deleteBannerToast(res.id);
                                      }}
                                      className="absolute right-2 top-1 text-white bg-red-500 opacity-0 group-hover:opacity-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 py-1 text-center "
                                    >
                                      <svg
                                        class="h-5 w-5 text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                  <h3 className="text-lg"> {res.name} </h3>
                                </div>
                              </>
                            )}
                          </span>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            <div
              className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
              id="box_btn"
            >
              <div className="text-right">
                <Menu className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={() => handleShowModal("add")}
                      className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                      style={{ backgroundColor: `${primaryColor["700"]}` }}
                    >
                      <HiOutlinePlus className="w-full h-full p-3" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"lg"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
              <div className="px-3 flex flex-col justify-center align-middle m-auto ">
                <div className="mb-3 ">
                  <InputComponent
                    name={"Name"}
                    type={"text"}
                    value={name}
                    setValue={setName}
                    required={true}
                    placeholder={"Enter Name"}
                  />
                </div>
                <div className="mb-3 dropds">
                  {/* <InputComponent
                    name={"Type"}
                    type={"text"}
                    value={type}
                    setValue={setType}
                    required={true}
                    placeholder={"Enter Type"}
                  /> */}
                  <SelectComponent
                    title={"Banner Type"}
                    selected={type}
                    setSelected={setType}
                    dataArr={bannerType}
                    required={true}
                  />
                </div>
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center p-3">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-red-600">
                        PNG or JPG <strong>(420x200px)</strong> .
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => previewImage(e)}
                    />
                  </label>
                </div>
              </div>
              <div className="flex justify-center mt-2">
                {images.length > 0 &&
                  images.map((img, index) => (
                    <div className="aspect-h-1 aspect-w-1 imgrs overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 ">
                      <img
                        src={img}
                        alt=""
                        srcset=""
                        key={index}
                        className="max-w-full max-h-full"
                      />
                    </div>
                  ))}
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleSubmit(e)}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default BannerList;
