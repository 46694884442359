import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetActiveUsersMutation } from "../../../features/tenant/ActiveUsers/activeUsersApi";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { AiOutlineSearch } from "react-icons/ai";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import toast from "react-hot-toast";

const intervalOptions = ["days", "months", "years"];

const ActiveUsers = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [intervalValue, setIntervalValue] = useState(1);
  const [intervalOption, setIntervalOption] = useState(intervalOptions[0]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [outletId, setOutletId] = useState("");
  const [outletName, setOutletName] = useState("");
  const [btn, setBtn] = useState(false);

  const [
    getActiveUsers,
    { data: getActiveUsersData, error: getActiveUsersError },
  ] = useGetActiveUsersMutation();

  const [
    getActiveUsersCSV,
    { data: getActiveUsersDataCSV, error: getActiveUsersErrorCSV },
  ] = useGetActiveUsersMutation();

  const fetchScanCount = (isAllData) => {
    let request = {
      interval: `${intervalValue} ${intervalOption}`,
    };

    // if (!isAllData) {
    request["limit"] = limit;
    request["offset"] = offset;
    // }

    if (name) {
      request["name"] = name;
    }
    if (mobile) {
      request["mobile"] = mobile;
    }
    if (outletId) {
      request["firm_id"] = outletId;
    }
    if (outletName) {
      request["firm_name"] = outletName;
    }

    console.log("request", request);

    getActiveUsers({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });

    let allData = { ...request };
    delete allData.limit;
    delete allData.offset;

    console.log("allData", allData);


    getActiveUsersCSV({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: allData,
    });
  };


  useEffect(() => {
    if (getActiveUsersData) {
      fetchScanCount(false);
    }
  }, [limit, offset]);

  useEffect(() => {
    if (getActiveUsersData) {
      toast.dismiss();
      toast.success(getActiveUsersData.message, {
        id: "getActiveUsersData",
      });
      //   fetchScanCount(true);

    }
    if (getActiveUsersError) {
      toast.dismiss();
      toast.error(getActiveUsersError.data.message, {
        id: "getActiveUsersError",
      });
    }
  }, [getActiveUsersData, getActiveUsersError]);

  useEffect(() => {
    if (getActiveUsersDataCSV) {
      toast.dismiss();
      toast.success(getActiveUsersDataCSV.message, {
        id: "getActiveUsersDataCSV",
      });
    }
    if (getActiveUsersErrorCSV) {
      toast.dismiss();
      toast.error(getActiveUsersErrorCSV.data.message, {
        id: "getActiveUsersErrorCSV",
      });
    }
    setBtn(false);
  }, [getActiveUsersDataCSV, getActiveUsersErrorCSV]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleSearchClick = () => {
    if (intervalValue == null || intervalValue == "") {
      toast.error("Please add interval value");
      return;
    }
    if (intervalValue < 1) {
      toast.error("Please add valid interval value");
      return;
    }

    fetchScanCount(false);
  };

  const scanTypeColumns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Outlet ID",
      selector: (row) => <h2>{row.firm_id}</h2>,
      sortable: true,
    },
    {
      name: "Outlet Name",
      selector: (row) => row.firm_name,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },

    {
      name: "Scans",
      selector: (row) => row.scan_count,
      sortable: true,
    },
  ];

  console.log("getActiveUsersDataCSV", getActiveUsersDataCSV);
  console.log("getActiveUsersData", getActiveUsersData);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Active Users
            <LinksComponent />
          </div>

          <div className="p-3">
            {getActiveUsersData &&
              getActiveUsersData?.body?.data?.length > 0 && (
                <ExportCSV
                  data={
                    getActiveUsersDataCSV && getActiveUsersDataCSV?.body?.data
                  }
                  columnsToExport={[
                    "name",
                    "mobile",
                    "firm_id",
                    "firm_name",
                    "scan_count",
                  ]}
                />
              )}

            <div className="w-full bg-white ">
              <div className="flex w-full space-x-3  bg-gray-100  px-3 py-3 ">
                <div className="text-left w-20">
                  <InputComponent
                    name={"Interval value"}
                    type={"number"}
                    value={intervalValue}
                    setValue={setIntervalValue}
                    required={true}
                  />
                </div>
                <div className="text-left w-20">
                  <select
                    className="js-example-basic-single rounded-lg shadow-sm shadow-black"
                    value={intervalOption}
                    onChange={(e) => setIntervalOption(e.target.value)}
                  >
                    {intervalOptions.map((x) => (
                      <option value={x}>{x}</option>
                    ))}
                  </select>
                </div>
                <div className="text-center">
                  <InputComponent
                    name={"Outlet Id"}
                    type={"text"}
                    value={outletId}
                    setValue={setOutletId}
                    required={false}
                  />
                </div>
                <div className="text-center">
                  <InputComponent
                    name={"Outlet Name"}
                    type={"text"}
                    value={outletName}
                    setValue={setOutletName}
                    required={false}
                  />
                </div>

                <div className="text-center mt-4 w-20">
                  <button
                    onClick={() => handleSearchClick()}
                    className="flex justify-center items-center rounded bg-primary p-2.5 w-full text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    <AiOutlineSearch className="mr-1" />
                    Search
                  </button>
                </div>
              </div>

              <div className=" w-full bg-gray-200 rounded-2xl">
                <DataTableComponent
                  columns={scanTypeColumns}
                  // title={""}
                  data={getActiveUsersData ? getActiveUsersData.body.data : []}
                  totalRows={
                    getActiveUsersData &&
                    getActiveUsersData.body &&
                    getActiveUsersData.body.total
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveUsers;
