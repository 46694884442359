import { rootApi } from "../../api/rootApi";

export const appUserTypeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppUserType: builder.query({
        query: (params) => {
          return {
            method: "GET",
            url: `/app/appUserType${params.query ? `${params.query}` : ""}`,
            headers: {
              slug: params.tenant_id,
            },
          };
        },
      }),
      updateAppUserType: builder.mutation({
        query: (params) => {
          return {
            method: "PUT",
            url: `/tenant/appUserType/update/${params.id}`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),

      getRegistrationStats: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/appUserType/stats/registrations?zone_id=${params.zone_id}&state=${params.state}`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),

      getPointRedemptionStats: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/appUserType/stats/point-redemptions?zone_id=${params.zone_id}&state=${params.state}`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),


      getUserScanningStats: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/appUserType/stats/user-scanning?zone_id=${params.zone_id}&state=${params.state}`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),

      getScanningVsRedemptionStats: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/tenant/appUserType/stats/scanning-redemption?zone_id=${params.zone_id}&state=${params.state}`,
            headers: {
              slug: params.tenant_id,
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
            body:params.body
          };
        },
      }),
  }),
});

export const { useGetAppUserTypeQuery, useUpdateAppUserTypeMutation , useGetRegistrationStatsMutation , useGetPointRedemptionStatsMutation , useGetUserScanningStatsMutation , useGetScanningVsRedemptionStatsMutation} = appUserTypeApi;
