import { rootApi } from "../../api/rootApi";

export const vendorApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchVendors: builder.query({
      query: (token) => {
        return {
          method: "GET",
          url: "/admin/company",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
      },
    }),
    addVendor: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/admin/company/add",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),
    updateVendor: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/company/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),

    getAllVendorThemeNames: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/vendorTheme/name`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
  }),
});
export const { useFetchVendorsQuery, useAddVendorMutation , useUpdateVendorMutation , useGetAllVendorThemeNamesMutation  } = vendorApi;
