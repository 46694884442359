import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const PrivacyPolicy = () => {
    const [htmlContent, setHtmlContent] = useState('');
    const [fileName, setFileName] = useState(null);

    const location = useLocation();

    useEffect(() => {

        const path = location.pathname.split("/");
        console.log("privacy-policy-path", path)
        if (path.length >= 2 && path[1] === "privacy-policy" && path[2]) {
            setFileName(path[2])
        }
    }, []);

    useEffect(() => {

        if (fileName && fileName.length > 0) {
            console.log("file name: ", fileName)
            const importHTML = async () => {
                try {
                    const htmlModule = await import(`../../../assests/privacyPolicyPages/${fileName}.json`);
                    // console.log("htmlModule", htmlModule);
                    setHtmlContent(htmlModule.htmlContent);
                } catch (error) {
                    setHtmlContent(null);
                    console.error('Error fetching HTML:', error);
                }
            };

            importHTML();
        }
    }, [fileName]);

    // console.log("htmlContent", htmlContent);

    return (

        <div className="bglog">
            {
                htmlContent ? htmlContent.length > 0 &&
                <section className="data-deletion ">
                    <div
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                </section>

                : 

                <h2 className='text-lg font-semibold'> Invalid Url </h2>
            }
        </div>
    );
};

export default PrivacyPolicy;