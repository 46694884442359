import { rootApi } from "../../api/rootApi";

export const appUserDashboardApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllDashboardItems: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/admin/appDashboard",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),

    fetchAllAppUserDashboard: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/admin/appUserDashboard",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
    fetchAllTenant: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/appUserDashboard/${params.tenant_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
    addAppUserDashboard: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/admin/appUserDashboard/add",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: params.data,
        };
      },
    }),
    updateAppUserDashboard: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/appUserDashboard/${params.tenant_id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: params.data,
        };
      },
    }),
    deleteAppUserDashboard: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/appUserDashboard/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
        };
      },
    }),
  }),
});
export const {
  useAddAppUserDashboardMutation,
  useDeleteAppUserDashboardMutation,
  useFetchAllDashboardItemsMutation,
  useFetchAllAppUserDashboardMutation,
  useFetchAllTenantMutation,
} = appUserDashboardApi;
