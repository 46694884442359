import React, { useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import {
  useCreateScratchCouponMutation,
  useUpdateScratchCouponByIdMutation,
} from "../../../features/tenant/scratchableCouponScheme/scratchableCouponSchemeApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";

function convertDateFormat(dateStr) {
  const [day, month, year] = dateStr.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

const schemeTypes = [
  {
    id: 1,
    name: "Fixed",
  },
  // {
  //   id: 2,
  //   name: "Continuous",
  // },
];

const AddScaratchableScheme = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isUpdate, setIsUpdate] = useState(false);

  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalCoupons, setTotalCoupons] = useState(null);
  const [info, setInfo] = useState(null);
  const [termsCondit, setTermsCondit] = useState(null);

  const [schemeType, setSchemeType] = useState({
    id: -1,
    name: "Select Scheme Type",
  });

  const [slabValue, setSlabValue] = useState(null);

  useEffect(() => {
    console.log(location.state);
    if (location.state) {
      setIsUpdate(true);
      setName(location.state.name);
      location.state.start_date &&
        setStartDate(
          convertDateFormat(
            new Date(location.state.start_date).toLocaleDateString(),
          ),
        );
      location.state.end_date &&
        setEndDate(
          convertDateFormat(
            new Date(location.state.end_date).toLocaleDateString(),
          ),
        );
      setTotalCoupons(location.state.total_coupons);
      setInfo(location.state.information);
      setTermsCondit(location.state.terms_conditions);

      if(location.state.scheme_type == "FIXED"){
        setSchemeType(schemeTypes[0])
        setSlabValue(location.state.slab_value);
      }else {
        setSchemeType(schemeTypes[1]);
      }

    }
  }, []);

  const [
    createScratchCouponMutation,
    { data: scratchCouponSchemeData, error: scratchCouponSchemeError },
  ] = useCreateScratchCouponMutation();

  const [
    updateScratchCouponMutation,
    {
      data: updateScratchCouponSchemeData,
      error: updateScratchCouponSchemeError,
    },
  ] = useUpdateScratchCouponByIdMutation();

  useEffect(() => {
    if (scratchCouponSchemeData) {
      toast.success("Scratchable Coupon Scheme created successfully", {
        id: "ScratchableSchemeCreated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/scratchable-coupon`);
      }, 400);
    }

    if (scratchCouponSchemeError) {
      if (scratchCouponSchemeError.status == 409) {
        toast.error("Scheme already exists with this name", {
          id: "addSchemeErrorAlreadyExists",
        });
      } else {
        toast.error("Something went wrong", { id: "addMembershipError" });
      }
      return;
    }
  }, [scratchCouponSchemeData, scratchCouponSchemeError]);

  useEffect(() => {
    if (updateScratchCouponSchemeData) {
      toast.success("Scratchable Coupon Scheme Updated", {
        id: "ScratchableSchemeUpdated",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/scratchable-coupon`);
      }, 400);
    }

    if (updateScratchCouponSchemeError) {
      toast.error("Something went wrong", { id: "updateScratchCouponError" });
      return;
    }
  }, [updateScratchCouponSchemeData, updateScratchCouponSchemeError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please provide name", { id: "noName" });
      return;
    }

    if (!startDate) {
      toast.error("Please provide start date", { id: "noStartDate" });
      return;
    }

    if (!endDate) {
      toast.error("Please provide end date", { id: "noEndDate" });
      return;
    }

    if (!totalCoupons) {
      toast.error("Please provide total coupons", { id: "noCoupons" });
      return;
    }

    // if(schemeType.id == -1 ){
    //   toast.error("No scheme type selected", { id: "noSchemeSelected" });
    //   return;
    // }



    createScratchCouponMutation({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        name: name,
        start_date: startDate,
        end_date: endDate,
        total_coupons: totalCoupons,
        information: info,
        terms_conditions: termsCondit,
        slab_value: slabValue , 
        // scheme_type : schemeType.name,
        // slab_value: schemeType.id === 1 ? slabValue : null, 
      },
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if(schemeType.id == -1 ){
      toast.error("No scheme type selected", { id: "noSchemeSelected" });
      return;
    }

    updateScratchCouponMutation({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: location.state.id,
      body: {
        name: name,
        start_date: startDate,
        end_date: endDate,
        total_coupons: totalCoupons,
        information: info,
        terms_conditions: termsCondit,
        slab_value: slabValue , 
        // scheme_type : schemeType.name,
        // slab_value: schemeType.id === 1 ? slabValue : null, 
      },
    });
  };

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}Scratchable Coupon Scheme
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 w-full bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="flex justify-start space-x-3">
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Name"}
                        type={"text"}
                        value={name ? name : ""}
                        setValue={setName}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Start Date"}
                        type={"date"}
                        value={startDate ? startDate : ""}
                        setValue={setStartDate}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"End Date"}
                        type={"date"}
                        value={endDate ? endDate : ""}
                        setValue={setEndDate}
                        required={true}
                      />
                    </div>

                   
                  </div>

                  {/* <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Coupon Cancel Date"}
                        type={"text"}
                        value={tierName ? tierName : ""}
                        setValue={setTierName}
                        required={true}
                      />
                    </div> */}

                  <div className="flex justify-start space-x-3">
                    {/* <div className="mb-6 bottom-2 w-1/4">
                      <SelectComponent
                        title={"Scheme Type"}
                        selected={schemeType}
                        setSelected={setSchemeType}
                        dataArr={schemeTypes}
                      />
                    </div> */}
{/* 
                    {schemeType.id == 1 && (
                      <div className="mb-6 w-1/4">
                        <InputComponent
                          name={"Slab Value"}
                          type={"number"}
                          value={slabValue ? slabValue : ""}
                          setValue={setSlabValue}
                          required={true}
                        />
                      </div>
                    )} */}
                     <div className="mb-6 w-1/3">
                      <InputComponent
                        name={"Total Coupons"}
                        type={"number"}
                        value={totalCoupons ? totalCoupons : ""}
                        setValue={setTotalCoupons}
                        required={true}
                      />
                    </div>
                    <div className="mb-6 w-1/3">
                        <InputComponent
                          name={"Slab Value"}
                          type={"number"}
                          value={slabValue ? slabValue : ""}
                          setValue={setSlabValue}
                          required={true}
                        />
                      </div>
                  </div>
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="mb-6 w-1/2">
                      <InputComponent
                        name={"Information"}
                        type={"textarea"}
                        value={info ? info : ""}
                        setValue={setInfo}
                        required={true}
                        rows={10}
                      />
                    </div>

                    <div className="mb-6 w-1/2">
                      <InputComponent
                        name={"Terms & Conditions"}
                        type={"textarea"}
                        value={termsCondit ? termsCondit : ""}
                        setValue={setTermsCondit}
                        required={true}
                        rows={10}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  {isUpdate ? (
                    <button
                      type=""
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type=""
                      onClick={(e) => handleSubmit(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddScaratchableScheme;
