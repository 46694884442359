import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import { useFetchPurchaseOrdersBySchemeIDMutation } from "../../../features/tenant/purchaseOrder/purchaseOrderApi";
import { useGetScratchedRewardsOfOrderMutation } from "../../../features/tenant/scratchRewardHistory/scratchRewardHistoryApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";

const SchemePurchaseOrders = () => {
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "OrderID",
      selector: (row) => row.order_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      //   width: "120px",
    },
    {
      name: "Order Value",
      selector: (row) => row.order_value,
      sortable: true,
      width: "120px",
    },

    {
      name: "Order Date",
      selector: (row) =>
        new Date(row.order_date).toLocaleDateString() +
        " , " +
        new Date(row.order_date).toLocaleTimeString(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Bill Value ",
      selector: (row) => row.bill_value,
      sortable: true,
      width: "120px",
    },
    {
      name: "Bill Date ",
      selector: (row) =>
        row.bill_date &&
        new Date(row.bill_date).toLocaleDateString() +
          " , " +
          new Date(row.bill_date).toLocaleTimeString(),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Carry Taken ",
    //   selector: (row) => row.carry_taken_value,
    //   sortable: true,
    //   width: "140px",
    // },
    // {
    //   name: "Carry Forward ",
    //   selector: (row) => row.carry_forward_value,
    //   sortable: true,
    //   width: "140px",
    // },

    {
      name: "",
      selector: (row) => (
        <button
          onClick={() => handleShowModal(row)}
          className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 break-normal pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] "
        >
          More Info
        </button>
      ),
      minWidth: "150px",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);

  const [
    fetchPurchaseOrdersBySchemeId,
    { data: purchaseOrderBySchemeIdData, error: purchaseOrderBySchemeIdError },
  ] = useFetchPurchaseOrdersBySchemeIDMutation();

  const [
    getRewardHistoryByOrderId,
    { data: rewardHistoryData, error: rewardHistoryError },
  ] = useGetScratchedRewardsOfOrderMutation();

  useEffect(() => {
    // const hasScratchCouponSchemeView = hasPermission("Membership");

    // if (!hasScratchCouponSchemeView) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }

    if (!location.state) {
      navigate(-1);
    }

    fetchPurchaseOrdersBySchemeId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      scheme_id: location.state.id,
      limit: 100,
      offset: 0,
    });
  }, []);

  console.log(purchaseOrderBySchemeIdData);

  const handleShowModal = (row) => {
    setSelectedPurchaseOrder(row);
    getRewardHistoryByOrderId({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      order_id: row.order_id,
      limit: 100,
      offset: 0,
    });
  };

  useEffect(() => {
    if (rewardHistoryData) {
      console.log("rewardHistoryData", rewardHistoryData);
      setShowModal(true);
      setModalData(rewardHistoryData.body);
    }

    if (rewardHistoryError) {
      console.log("rewardHistoryError", rewardHistoryError);
    }
  }, [rewardHistoryData, rewardHistoryError]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Scheme Purchase Orders List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={
                  purchaseOrderBySchemeIdData
                    ? purchaseOrderBySchemeIdData.body
                    : []
                }
                // loading={loading}
                totalRows={
                  purchaseOrderBySchemeIdData
                    ? purchaseOrderBySchemeIdData.body.length
                    : 0
                }
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
              ></DataTableComponent>
              {
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div>

          {showModal && modalData && (
            <ModalComponent
              size={"3xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg">

                <div className="my-2 flex justify-evenly">
                <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                        Total Assigned Coupons  :
                        <span class="text-gray-500">{selectedPurchaseOrder?.assigned_coupons}</span>
                      </p>

                      <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                       Scratchable Coupons :{" "}
                        <span class="text-gray-500">{selectedPurchaseOrder?.scratchable_coupons} </span>
                      </p>
                      <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                        Scratched Coupons :
                        <span class="text-gray-500">
                        {selectedPurchaseOrder?.scratched_coupons} 
                        </span>
                      </p>
                </div>
                <div class="my-2 flex justify-evenly items-center flex-wrap">

                

                  {modalData.map((each) => (
                    <div class="border-b text-center pb-2 px-5 border-gray-200">
                      <h1 class="text-xl pb-2 font-semibold lg:leading-6 leading-7 text-black mt-2">
                        <div class=" w-full h-24 flex justify-center">
                          <div className="w-28 flex justify-center items-center">
                            {each.reward_image && (
                              <img
                                class="w-full"
                                alt={each.reward_image}
                                src={
                                  
                                  each.reward_image
                                }
                              />
                            )}
                          </div>
                        </div>
                      </h1>
                      <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                        Reward Name :
                        <span class="text-gray-500">{each?.reward_name}</span>
                      </p>

                      <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                        Reward Value :{" "}
                        <span class="text-gray-500">{each?.reward_value}  INR</span>
                      </p>
                      <p class="text-sm text-black  font-semibold lg:leading-6 leading-7 mt-2">
                        Scratch Date :
                        <span class="text-gray-500">
                          {new Date(each?.created_at).toLocaleDateString()}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>

                {/* <div className="flex justify-evenly mt-2">
                <button
                  type=""
                  onClick={() =>
                    navigate("orders", {
                      state: modalData,
                    })
                  }
                  className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center "
                >
                  View Purchase Orders
                </button>

                <button
                  type=""
                  onClick={() =>
                    navigate("rewards", {
                      state: modalData,
                    })
                  }
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center "
                >
                  View Reward List
                </button>
              </div> */}
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default SchemePurchaseOrders;
