import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteAppGalleryMutation, useGetAppGalleryMutation } from "../../../../features/tenant/appGallery/appGallery.Api";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import { HiOutlinePlus } from "react-icons/hi2";
import { Menu } from "@headlessui/react";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import { toast } from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";

const GalleryList = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [currentGalleryData, setCurrentGalleryData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const handleOpenModal = (image) => {
    setCurrentGalleryData(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelectCarousel = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const [
    getAllGallery,
    {
      data: getAllGalleryData,
      isLoading: getAllGalleryLoading,
      isError: getAllGalleryIsError,
      error: getAllGalleryError,
    },
  ] = useGetAppGalleryMutation();

  const getAll = () => {
    getAllGallery({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (getAllGalleryData && getAllGalleryData.body) {
      const responseArray = Object.values(getAllGalleryData.body);
      setGalleryData(responseArray.map((item) => item.images));
    }
  }, [getAllGalleryData]);

  useEffect(() => {
    if (galleryData.length > 0) {
      setCurrentGalleryData(galleryData[0]);
    }
  }, [galleryData]);

  const [
    deleteGallery,
    {
      data: deleteGallerydata,
      isLoading: deleteBatchIsLoading,
      error: deleteGalleryError,
    },
  ] = useDeleteAppGalleryMutation();

  useEffect(() => {

    if (deleteGallerydata) {
      getAll();
      toast.success("Images Deleted", {
        id: "deleteBatchSuccess",
      });
    }

    if (deleteGalleryError) {
      toast.error(deleteGalleryError.data.message, {
        id: "deleteGalleryError",
      });
    }
  }, [deleteGallerydata, deleteGalleryError]);

  const handleDelete = (id) => {
    // console.log("deleteGallery", id)
    if (window.confirm("Are you sure you want to delete this ?")) {
      deleteGallery({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: id,
      });
    }
    return;
  };  


  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
        Gallery
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className="p-3 bg-white shadow rounded-lg">
          
              {/* <h2 className="sr-only">Gallery</h2> */}
              <div className="grid grid-cols-4 gap-4">
                {getAllGalleryData?.body && Object.values(getAllGalleryData.body) 
                  ? Object.values(getAllGalleryData.body).map((row, index) => {
                      return (
                        <span key={index} href="#" className="group h-52 overflow-hidden bg-gray-200 border-2 border-gray-200 rounded-sm">
                          <button className="w-10 h-10 float-right" onClick={() => handleDelete(row.id)}>  
                            <AiFillDelete className="w-full h-full text-red-600 "/>
                          </button>
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                            <img
                              src={ row.images[0]}
                              alt={row.images[0]}
                              className="h-full w-full object-cover object-center group-hover:opacity-75 max-w-full max-h-full"
                              onClick={()=>handleOpenModal(row.images)}
                            />
                          </div>
                        </span>
                      );
                    })
                  : null}
              </div>
          
          
          </div>

          <div
            className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
            id="box_btn"
          >
            <div className="text-right">
              <Menu className="relative inline-block text-left">
                <div>
                  <Menu.Button
                    onClick={() => navigate("add")}
                    className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                    style={{ backgroundColor: `${primaryColor["700"]}` }}
                  >
                    <HiOutlinePlus className="w-full h-full p-3" />
                  </Menu.Button>
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
          <ModalComponent
            size={"4xl"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
            <div className="mx-auto ">
              <h2 className="sr-only"> All Gallery</h2>

              <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {currentGalleryData.length
                  ? currentGalleryData.map((img, index) => {
                      return (
                        <span href="#" className="group">
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                            <img
                              src={ img}
                              alt={img}
                              className="h-full w-full object-cover object-center group-hover:opacity-75"
                            />
                          </div>
                        </span>
                      );
                    })
                  : null}
              </div>
            </div>
            </div>
          </ModalComponent>
        )}
    </div>
  );
};

export default GalleryList;
