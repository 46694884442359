import React from 'react'

const Notification = () => {
  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
    <div className="w-full ">
      <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
      Notification
        {/* <LinksComponent /> */}
      </div>

      <div className='p-3'>
        
        <div className=' bg-white shadow rounded-lg p-3'>
<div className='w-full  flex  space-x-3'>
  <div className='mb-6 w-1/2'>
<div className='w-full'>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>User Type</label>
<select className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-auto'>
  <option>Electrician</option>
  <option>Retailer</option>
</select></div>
  </div>

  <div className='mb-6 w-1/2'>
<div className='w-full'>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>Events</label>
<select className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-auto'>
  <option>Scanning</option>
  <option>Birthday</option>
  <option>Annaversery</option>
  <option>Bonus</option>
  <option>Memebership</option>
  <option>Mitivitional</option>
  <option>Scheme</option>
  <option>Deram Gift</option>
  <option>Scheme</option>
  <option>Day notification</option>
  <option>Profile</option>
  <option>Points</option>
  <option>Special message</option>
  <option>Product Annoucement</option>
  
</select></div>
  </div>
</div>

<div className='w-full  flex  space-x-3'>
  <div className='mb-6 w-1/2'>
<div className='w-full'>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>Image (optional) </label>

<div class="flex items-center justify-center w-full">
    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div class="flex flex-col items-center justify-center ">
            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
        </div>
        <input id="dropzone-file" type="file" class="hidden" />
    </label>
</div> 

</div>
  </div>

  <div className='mb-6 w-1/2'>
<div className='w-full'>
<label className='block text-sm font-medium capitalize leading-6 text-gray-900 mb-1'>Message </label>
<textarea  className='h-44 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  '></textarea>


</div>
  </div>
</div>

<div className='flex justify-center'>
<button type='submit' className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Submit</button>

</div>
          </div>
      </div>
      </div></div>
  )
}

export default Notification