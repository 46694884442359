import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useBulInsertPaymentTransferMutation } from "../../../features/tenant/paymentTransfer/paymentTransferApi";
import { downloadSampleCSV } from "../../../utils/downloadSampleCsv";
import CSVReader from "../../../components/ImportCsv/CSVReader";

function validateAndCleanObjects(objects, requiredKeysSet) {
  function isEmptyObject(obj) {
    return Array.from(requiredKeysSet).every((key) => !obj[key.trim()]);
  }

  const cleanedObjects = [];

  console.log("object", objects);

  for (const object of objects) {
    const newObject = {};

    if (isEmptyObject(object)) {
      continue;
    }

    let valid = true;
    for (let key in object) {
      key = key.trim();
      console.log(
        "object key",
        key,
        key.length,
        requiredKeysSet.has(key),
        Object.keys(object)
      );
      if (requiredKeysSet.has(key)) {
        if (!object[key]) {
          valid = false;
          break;
        } else {
          newObject[key] = object[key];
        }
      }
    }

    newObject.status = valid ? 1 : 2;
    cleanedObjects.push(newObject);
  }

  if (cleanedObjects.some((obj) => obj.status === 2)) {
    return {
      isValid: false,
      data: cleanedObjects,
    };
  }

  return {
    isValid: true,
    data: cleanedObjects,
  };
}

const UploadPaymentTransfer = () => {
  let requiredKeysSet;
  console.log(
    "JSON.parse(sessionStorage.getItem())",
    JSON.parse(sessionStorage.getItem("vendorData"))
  );
  if (JSON.parse(sessionStorage.getItem("vendorData")).tenant_id == 13) {
    requiredKeysSet = new Set([
      "mobile",
      "name",
      "upi_mobile",
      "amount",
      "month",
      "year",
    ]);
  } else {
    requiredKeysSet = new Set([
      "emp_id",
      "name",
      "amount",
      "l1_manager",
      "month",
      "year",
    ]);
  }
  const navigate = useNavigate();
  const location = useLocation();

  const [date, setDate] = useState(null);
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [csvData, setCSVData] = useState([]);

  const [canSubmit, setCanSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [sortedData, setsortedData] = useState([]);

  const [fileName, setFileName] = useState(null);

  const [totalAssuredGift, setTotalAssuredGift] = useState(0);

  const [totalLuckyDraws, setTotalLuckyDraws] = useState(0);

  const fileReader = new FileReader();
  const [
    bulkInsertPaymentTransfer,
    {
      data: paymentTransferData,
      error: paymentTransferError,
      isLoading: paymentTransferIsLoading,
    },
  ] = useBulInsertPaymentTransferMutation();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\r\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\r\n");

    console.log("--------->", csvHeader[csvHeader.length - 1]);

    console.log("csv header: ", csvHeader);

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    const validationResult = validateAndCleanObjects(array, requiredKeysSet);
    console.log("validationResult", validationResult);

    setCanSubmit(validationResult.isValid);

    setModalData(validationResult.data);

    setArray(validationResult.data);
    setsortedData(validationResult.data);
    toast.success("Data successfully imported...");
  };
  console.log("csvData2",csvData , csvData?.file?.name);

  const handleFileUpload = (e) => {
    e.preventDefault();
    console.log(file);

    // if (file) {
    //   console.log("file upload");
    //   fileReader.onload = function (event) {
    //     const text = event.target.result;
    //     csvFileToArray(text);
    //   };
    //   fileReader.readAsText(file);
    // }

    console.log("csvData3",csvData);

    if (csvData.parsed.length > 0) {
      const validationResult = validateAndCleanObjects(csvData.parsed, requiredKeysSet);
      console.log("validationResult", validationResult);

      setCanSubmit(validationResult.isValid);

      setModalData(validationResult.data);

      setArray(validationResult.data);
      setsortedData(validationResult.data);
      toast.success("Data successfully imported...");
    }
  };

  console.log(array);
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const handleOnSubmit = (e) => {
    setsortedData(null);
    toast.loading("Processing your data...");

    e.preventDefault();

    bulkInsertPaymentTransfer({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        rows: array,
        month: new Date(date).getMonth().toString(),
        year: new Date(date).getFullYear().toString(),
        file_name : fileName
      },
    });
  };

  useEffect(() => {
    if (paymentTransferData && paymentTransferData.body) {
      toast.dismiss();
      toast.success("Saved Successfully", {
        id: "bulkInsertPaymentTransferData",
      });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/payment-transfer`);
      }, 400);
      console.log("Payment Transfer data", paymentTransferData);
    }

    if (paymentTransferError) {
      toast.dismiss();


      if (paymentTransferError.status === 409) {
        toast.error(paymentTransferError.data.message, {
          id: "bulkInsertPaymentTransferError",
        });
      } else {
        toast.error("Something went wrong");
      }

      console.log("Payment Transfer error", paymentTransferError);
    }
  }, [paymentTransferData, paymentTransferError]);

  // console.log(sortedData);

  console.log("filename", fileName);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Upload Payment Transfer Data
            <LinksComponent />
          </div>

          <div className="mx-9 mt-10">
            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-full p-4 px-5 justify-center">
              <div className=" flex flex-col justify-center align-middle w-full">
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() =>
                      downloadSampleCSV(
                        JSON.parse(sessionStorage.getItem("vendorData"))
                          .tenant_id == 13
                          ? [
                              { name: "mobile" },
                              { name: "name" },
                              {
                                name: "amount",
                              },
                              {
                                name: "upi_mobile",
                              },
                            ]
                          : [
                              {
                                name: "emp_id",
                              },
                              { name: "name" },
                              {
                                name: "l1_manager",
                              },
                              {
                                name: "amount",
                              },
                            ]
                      )
                    }
                  >
                    Sample Csv Download
                  </button>
                </div>
                <div className="">
                  <div className="text-center ">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Upload Excel/CSV File:
                    </label>
                    <div className="flex gap-3 border-b pb-4 border-gray-200">
                      {/* Month and Year Input */}
                      <div className="flex gap-2">
                        <label htmlFor="monthYearInput" className="sr-only">
                          Select Month and Year
                        </label>
                        <input
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2"
                          type="month"
                          id="monthYearInput"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>

                      {/* CSV File Input */}

                      {/*<input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={handleOnChange}
                      /> */}

                      <CSVReader setCSVData={setCSVData}  setFileName={setFileName}/>

                      {/* Import CSV Button */}
                      <button
                        className="whitespace-nowrap bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
                        onClick={(e) => handleFileUpload(e)}
                      >
                        IMPORT CSV
                      </button>
                    </div>

                    <div className="mt-4 flex justify-center space-x-2 ">
                      {/* View CSV File Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center"
                        >
                          View CSV File
                        </button>
                      )}

                      {/* Save Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => handleOnSubmit(e)}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {showModal && modalData && (
            <ModalComponent
              size={"5xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg">
                <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr>
                        {headerKeys.map((key, idx) => (
                          <th
                            className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            key={idx}
                          >
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {array.slice(0, 30).map((item) => (
                        <tr
                          key={item.id}
                          className="border-t-2 border-b-2 border-gray-300"
                        >
                          {Object.values(item).map((val, index) => (
                            <td
                              key={index}
                              className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                    <h2 className="mt-2 ml-auto">
                      {" "}
                      {array.length - 30} rows more
                    </h2>
                  </table>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadPaymentTransfer;
