import React, { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

const VendorRegistration = () => {
  const navigate = useNavigate();

  const companyAddressObj = {
    homeAddress: "",
    state: "",
    country: "",
    state: "",
    district: "",
    landMark: "",
    city: "",
    pincode: "",
  };
  const companySocialMediaObj = {
    facebook: "",
    youtube: "",
    instagram: "",
    twitter: "",
  };

  const [comapnyName, setComapnyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [companyRePassword, setCompanyRePassword] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactPersonNumber, setContactPersonNumber] = useState("");
  const [companyAddress, setcompanyAddress] = useState(companyAddressObj);
  const [companySocialMedia, setCompanySocialMedia] = useState(
    companySocialMediaObj,
  );
  const [picture, setPicture] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  const [navbarLogo, setNavbarLogo] = useState(null);
  const [navbarFile, setNavbarFile] = useState(null);

  const [companyIndustry, setCompanyIndustry] = useState(0);
  const [companyGstin, setCompanyGstin] = useState("");
  const [panelStatus, setPanelStatus] = useState(0);
  const [companyStatus, setCompanyStatus] = useState(0);
  const [companyUrl, setCompanyUrl] = useState("");
  const [qrType, setQrType] = useState(0);
  const [vendorId, setVendorId] = useState(0);
  const [industryData, setIndustryData] = useState([]);

  // errors
  const [companyEmailError, setCompanyEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordReError, setPasswordReError] = useState(null);
  const [masterError, setMasterError] = useState(null);
  const [companyMobileError, setCompanyMobileError] = useState(null);
  const [logoErr, setLogoErr] = useState(null);

  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/admin/industry`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.body);
        setIndustryData(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (
      !companyEmailError &&
      !passwordError &&
      !passwordReError &&
      !companyMobileError
    ) {
      setMasterError(null);
    }
  }, [companyEmailError, passwordError, passwordReError, companyMobileError]);

  const handleComapnyAddressChange = (e, target) => {
    let copiedValue = { ...companyAddress };
    copiedValue[target] = e.target.value;
    setcompanyAddress(copiedValue);
  };

  const handlePhoneChange = (event) => {
    if (
      !validator.isMobilePhone(event.target.value, ["en-IN"]) &&
      event.target.value > 0
    ) {
      setCompanyMobileError("Phone no Invalid");
    } else {
      setCompanyMobileError(null);
    }

    setCompanyMobile(event.target.value);
  };

  const handleCompanySocialChange = (e, target) => {
    let copiedValue = { ...companySocialMedia };
    copiedValue[target] = e.target.value;
    setCompanySocialMedia(copiedValue);
  };

  const handleEmailChange = (event) => {
    console.log(event.target.value);
    console.log(validator.isEmail(event.target.value));
    if (
      !validator.isEmail(event.target.value) &&
      event.target.value.length > 0
    ) {
      setCompanyEmailError("Email is invalid");
    } else {
      setCompanyEmailError(null);
    }
    setCompanyEmail(event.target.value);
  };

  const handlePasswordChange = (event, type) => {
    if (
      event.target.value.length > 0 &&
      !validator.isStrongPassword(event.target.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      if (event.target.value !== companyPassword && type == 1) {
        setPasswordReError("Password Not Same");
      } else if (type == 1)
        setPasswordReError(
          "Password is not strong , must include a number , symbol , uppercase and lowercase ",
        );
      else
        setPasswordError(
          "Password is not strong , must include a number , symbol , uppercase and lowercase ",
        );
    } else {
      if (type == 1) setPasswordReError(null);
      else setPasswordError(null);
    }

    if (type == 1) setCompanyRePassword(event.target.value);
    else setCompanyPassword(event.target.value);
  };

  const handleCompanyIndustryChange = (e) => {
    setCompanyIndustry(e.target.value);
  };

  const addNewVendor = (e) => {
    e.preventDefault();
    setLogoErr(null);
    setMasterError(null);

    console.log("logooooooo", picture , thumbnail , navbarLogo , navbarFile);
    
    let industryName = "";
    let user_types = [];
    industryData.forEach(function (data) {
      if (data.industry_id == companyIndustry) {
        industryName = data.name;
        user_types = data.users;
      }
    });

    if (
      companyEmailError == null &&
      companyMobileError == null &&
      passwordError == null &&
      passwordReError == null &&
      companyEmail.length > 0 &&
      comapnyName.length > 0 &&
      companyGstin.length > 0
    ) {

      const formData = new FormData();
      formData.append("logo", thumbnail[0]);
      formData.append("logo", navbarFile[0]);


      formData.append("name" , comapnyName);
      formData.append("email" , companyEmail);
      formData.append("gstin" , companyGstin);
      formData.append("mobile" , companyMobile);
      formData.append("address" , JSON.stringify(companyAddress));
      formData.append("qr_type" , String(qrType));
      formData.append("password" , companyPassword);
      formData.append("socials" , JSON.stringify(companySocialMedia));
      formData.append("panel_status" , panelStatus);
      formData.append("status" , companyStatus);
      formData.append("website" , companyUrl);
      formData.append("contact_person_name" , contactPerson);

      formData.append("contact_person_number" , companyStatus);
      formData.append("user_types" , JSON.stringify(user_types));
      formData.append("industry_id" , companyIndustry);
      formData.append("industry_name" , industryName);


      
      // const data = {
      //   name: comapnyName,
      //   email: companyEmail,
      //   gstin: companyGstin,
      //   mobile: companyMobile,
      //   address: companyAddress,
      //   qr_type: String(qrType),
      //   password: companyPassword,
      //   socials: companySocialMedia,
      //   panel_status: panelStatus,
      //   status: companyStatus,
      //   website: companyUrl,
      //   contact_person_name: contactPerson,
      //   contact_person_number: contactPersonNumber,
      //   logo: "thumbnail",
      //   user_types :  user_types ,
      //   industry_id: companyIndustry,
      //   industry_name: industryName,
      // };

      console.log(JSON.stringify(formData));

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/admin/company/add`,
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
        data: formData
      };
      axios(config)
        .then(function (response) {
          console.log(response.data);
          if(response.data.success){
            setSuccessMsg(response.data.message)
            toast.success(response.data.message , {
              id : "companyCreatedSuccess"
            });
            setTimeout(() => {
              navigate("/");
            }, 2000);
          }
        //   const { id } = response.data.data;
        //   setVendorId(id);
          resetValues();
        })
        .catch(function (error) {
          console.log(error);

        })
        .finally(() => {});
    } else {
      setLogoErr("Upload Image First");
      setMasterError(
        "Please fill all the required fields and check the errors",
      );
      toast.error("Please fill all the required fields and check the errors" , {
        id : "masterErrorCompanyCreate"
      })
    }
  };

  const resetValues = () => {
    setComapnyName("");
    setCompanyEmail("");
    setCompanyPassword("");
    setCompanyRePassword("");
    setCompanyGstin("");
    setcompanyAddress(companyAddressObj);
    setCompanySocialMedia(companySocialMediaObj);
    setContactPerson("");
    setContactPersonNumber("");
    setCompanyIndustry(0);
    setCompanyMobile("");
    setCompanyUrl("");
    setPanelStatus(0);
    setPicture(null);
    setThumbnail(null);
    setNavbarFile(null);
    setNavbarLogo(null);
    setCompanyStatus(0);
  };

  const handleCompanyStatus = (event) => {
    setCompanyStatus(event.target.value);
  };

  const handleCompanyPanelStatus = (event) => {
    // console.log(panelStatus);
    console.log(event.target.value);
    setPanelStatus(event.target.value);
  };

  const handleQrChange = (event) => {
    setQrType(parseInt(event.target.value));
    console.log({ qrType });
  };
  
  return (
    <>
    <Toaster/>
      <div className="page-content">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {/* {successMsg && ( <div className="alert alert-success mt-4" role="alert"> 
                    {successMsg}
                </div> )} */}
                <h4 className="card-title">Add New Client</h4>
                <div className="cmxform">
                  <div className="form-group row">
                    <div className="col-md-6 my-3">
                      <label>Name *</label>
                      <input
                        id="m_name"
                        className="form-control"
                        name="m_name"
                        type="text"
                        value={comapnyName}
                        onChange={(e) => setComapnyName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 my-3">
                      <label>Email *</label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="m_no"
                        type="text"
                        value={companyEmail}
                        onChange={(e) => handleEmailChange(e)}
                      />
                      {companyEmailError && (
                        <p className="text-danger mx-2 my-2">
                          {companyEmailError}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 my-2">
                      <label>Password *</label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="password"
                        type="text"
                        value={companyPassword}
                        onChange={(e) => handlePasswordChange(e, 0)}
                      />
                      {passwordError && (
                        <p className="text-danger mx-2 my-2">{passwordError}</p>
                      )}
                    </div>
                    <div className="col-md-6 my-2">
                      <label>Re-Password *</label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="re-password"
                        type="password"
                        value={companyRePassword}
                        onChange={(e) => handlePasswordChange(e, 1)}
                      />
                      {passwordReError && (
                        <p className="text-danger mx-2 my-2">
                          {passwordReError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 my-3">
                      <label>Mobile No *</label>
                      <input
                        id="m_name"
                        className="form-control"
                        name="m_name"
                        type="text"
                        value={companyMobile}
                        onChange={(e) => handlePhoneChange(e)}
                        maxLength={10}
                      />
                      {companyMobileError && (
                        <p className="text-danger mx-2 my-2">
                          {companyMobileError}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 my-3">
                      <label className="my-1">Industry *</label>
                      <select
                        className="js-example-basic-single w-100"
                        value={companyIndustry}
                        onChange={handleCompanyIndustryChange}
                      >
                        <option value={0}>Select Industry</option>
                        {industryData &&
                          industryData.map((industry) => (
                            <option
                              key={industry.industry_id}
                              value={industry.industry_id}
                            >
                              {industry.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-6 my-3">
                      <label>Contact Person Name </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="m_no"
                        type="text"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 my-3">
                      <label>Contact Person Number</label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="m_no"
                        type="text"
                        value={contactPersonNumber}
                        onChange={(e) => setContactPersonNumber(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mt-3">
                      <label>Head Office Address </label>
                      <textarea
                        id="maxlength-textarea"
                        className="form-control"
                        maxLength="100"
                        rows="4"
                        value={companyAddress.homeAddress}
                        placeholder="This textarea has a limit of 100 chars."
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "homeAddress")
                        }
                      />
                    </div>
                    <div className="col-md-6 mt-3">
                      <label>Landmark </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.landMark}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "landMark")
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 my-2 ">
                      <label>State </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.state}
                        onChange={(e) => handleComapnyAddressChange(e, "state")}
                      />
                    </div>
                    <div className="col-md-6 my-2 ">
                      <label>City </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="city"
                        type="text"
                        value={companyAddress.city}
                        onChange={(e) => handleComapnyAddressChange(e, "city")}
                      />
                    </div>
                    <div className="col-md-6 my-2 ">
                      <label>Country </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.country}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "country")
                        }
                      />
                    </div>
                    <div className="col-md-6 my-2 ">
                      <label>District </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="landmark"
                        type="text"
                        value={companyAddress.district}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "district")
                        }
                      />
                    </div>
                    <div className="col-md-6 my-2 ">
                      <label>Pincode/Zipcode </label>
                      <input
                        id="m_no"
                        className="form-control"
                        name="pincode"
                        type="text"
                        value={companyAddress.pincode}
                        onChange={(e) =>
                          handleComapnyAddressChange(e, "pincode")
                        }
                      />
                    </div>
                  </div>
                  <label className="text-xl text-uppercase">
                    Social Media{" "}
                  </label>

                  <div className="form-group row">
                    <div className="col-md-11 my-2">
                      <label>Facebook </label>

                      <input
                        id="m_no"
                        className="form-control"
                        name="fb"
                        type="text"
                        value={companySocialMedia.facebook}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "facebook")
                        }
                      />
                    </div>
                    <div className="col-md-11 my-2">
                      <label>Instagram </label>

                      <input
                        id="m_no"
                        className="form-control"
                        name="ig"
                        type="text"
                        value={companySocialMedia.instagram}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "instagram")
                        }
                      />
                    </div>
                    <div className="col-md-11 my-2">
                      <label>Twitter </label>

                      <input
                        id="m_no"
                        className="form-control"
                        name="tw"
                        type="text"
                        value={companySocialMedia.twitter}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "twitter")
                        }
                      />
                    </div>
                    <div className="col-md-11 my-2">
                      <label>Youtube </label>

                      <input
                        id="m_no"
                        className="form-control"
                        name="yt"
                        type="text"
                        value={companySocialMedia.youtube}
                        onChange={(e) =>
                          handleCompanySocialChange(e, "youtube")
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group row my-2">
                    <div className="col-md-6 my-1">
                      <label>Company URL</label>
                      <input
                        id="name"
                        className="form-control"
                        name="p_box"
                        value={companyUrl}
                        onChange={(e) => {
                          setCompanyUrl(e.target.value);
                        }}
                        type="text"
                      />
                    </div>

                    <div className="col-md-6 my-1">
                      <label>Company GSTIN * </label>
                      <input
                        id="gstin"
                        className="form-control"
                        name="p_box"
                        value={companyGstin}
                        maxLength={16}
                        onChange={(e) => {
                          setCompanyGstin(e.target.value);
                        }}
                        type="text"
                      />
                    </div>

                    <div className="col-md-6 my-2">
                      <label>QR Type *</label>
                      <select
                        className="js-example-basic-single w-100"
                        value={qrType}
                        onChange={handleQrChange}
                      >
                        <option value={'0'}>Standard Qr Codes </option>
                        <option value={'1'}>Enterprise QR Codes</option>
                      </select>
                    </div>
                    <div className="col-md-6 my-2">
                      <label>Status</label>
                      <select
                        className="js-example-basic-single w-100"
                        value={companyStatus}
                        onChange={handleCompanyStatus}
                      >
                        <option value={0}>Active</option>
                        <option value={1}>Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 my-1">
                      <label className="my-1">Pannel Status</label>
                      <select
                        className="js-example-basic-single w-100"
                        value={panelStatus}
                        onChange={handleCompanyPanelStatus}
                      >
                        <option value={0}>Demo</option>
                        <option value={1}>Testing / Staging</option>
                        <option value={2}>Pre-Production</option>
                        <option value={3}>Production (Live)</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 my-3">
                    <img
                      className="userUpdateImg"
                      src={picture ? picture : ""}
                      alt=""
                    />
                    <label >Upload Image *</label>
                    <div>
                      <input
                        data-testid="fileInput"
                        type="file"
                        id="file"
                        accept="image/*" 
                        onChange={(e) => {
                          setPicture(URL.createObjectURL(e.target.files[0]));
                          setThumbnail(e.target.files);
                        }}
                      />
                    </div>
                    {logoErr && (
                      <p className="text-danger mx-2 my-2">{logoErr}</p>
                    )}
                  </div>

                  <div className="col-md-6 my-3">
                    <img
                      className="userUpdateImg"
                      src={navbarLogo ? navbarLogo : ""}
                      alt=""
                    />
                    <label >Upload Logo(Navbar) *</label>
                    <div>
                      <input
                        data-testid="fileInput"
                        type="file"
                        id="file"
                        accept="image/*" 
                        onChange={(e) => {
                          setNavbarLogo(URL.createObjectURL(e.target.files[0]));
                          setNavbarFile(e.target.files);
                        }}
                      />
                    </div>
                    {logoErr && (
                      <p className="text-danger mx-2 my-2">{logoErr}</p>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <button
                        className="btn btn-outline-primary mr-2 w-100"
                        onClick={(e) => addNewVendor(e)}
                      >
                        Register
                      </button>
                    </div>
                    <div className="col-md-6 mt-3">
                    <button
                        className="btn btn-outline-secondary mr-2 w-100"
                        onClick={(e) => resetValues(e)}
                      >
                        Reset Values
                      </button>
                    </div>
                  </div>
                </div>
                {/* {masterError && (
                  <div className="alert alert-danger mt-4" role="alert">
                    {masterError}
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorRegistration;
