import { rootApi } from "../../api/rootApi";

export const schemeGiftRedemptionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({


    fetchAllSchemeRedemptions: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/scheme-redemptions?zoneId=${params.zoneId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    updateSchemeRedemptionsStatus: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/scheme-redemptions`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

  }),
});

export const {
   useFetchAllSchemeRedemptionsMutation,
   useUpdateSchemeRedemptionsStatusMutation
} = schemeGiftRedemptionsApi;
