import { rootApi } from "../../api/rootApi";

export const EmailCredentialApiAdmin = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEmailCredential: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/admin/emailCredential",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),

    createEmailCredential: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/emailCredential/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
          body: params.data,
        };
      },
    }),

    updateEmailCredential: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/emailCredential/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
          body: params.data,
        };
      },
    }),

    deleteEmailCredential: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/emailCredential/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
  }),
});
export const {
  useGetAllEmailCredentialMutation,
  useCreateEmailCredentialMutation,
  useUpdateEmailCredentialMutation,
  useDeleteEmailCredentialMutation,
} = EmailCredentialApiAdmin;
