import { rootApi } from "../../api/rootApi";

export const purchaseOrderApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllPurchaseOrders: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/purchaseOrder`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchPurchaseOrderByID: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/purchaseOrder/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchPurchaseOrdersBySchemeID: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/purchaseOrder/scheme/${params.scheme_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addPurchaseOrder: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/purchaseOrder/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updatePurchaseOrder: builder.mutation({
      query: (params) => {
        console.log("parmas", params);
        return {
          method: "PUT",
          url: `/tenant/purchaseOrder/${params.order_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateBillOfPurchaseOrder: builder.mutation({
        query: (params) => {
          return {
            method: "PUT",
            url: `/tenant/purchaseOrder/bill/${params.order_id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
            body: params.body,
          };
        },
      }),
    deletePurchaseOrder: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/purchaseOrder/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchAllPurchaseOrdersMutation,
  useFetchPurchaseOrderByIDMutation,
  useFetchPurchaseOrdersBySchemeIDMutation,
  useAddPurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
  useUpdateBillOfPurchaseOrderMutation,
  useDeletePurchaseOrderMutation,
} = purchaseOrderApi;
