import { rootApi } from "../../../api/rootApi";

export const productPointsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchProductWithPoints: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/productPoints`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    addBatchOfProductsWithPoints: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/productPoints/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: { batch: params.body },
        };
      },
    }),
    updateBatchProductsWithPoints: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/productPoints/bulk`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: { batch: params.body },
        };
      },
    }),
    updateProductWithPointsByProductId: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/products/productPoints/${params.product_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    // deletedProductById: builder.mutation({
    //   query: (params) => {
    //     return {
    //       method: "GET",
    //       url: `/tenant/products/delete/${params.product_id}`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + params.token,
    //         slug: params.tenant_id,
    //       },
    //     };
    //   },
    // }),
  }),
});

export const {
  useAddBatchOfProductsWithPointsMutation,
  useUpdateBatchProductsWithPointsMutation,
  useFetchProductWithPointsMutation,
} = productPointsApi;
