import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { toast } from "react-hot-toast";

import { getVendorName } from "../../../utils/getVendorName";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utils/hasPermission";
import { useAddfaqsMutation } from "../../../features/tenant/faqs/faqs.api";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

const AddFaq = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [type, setType] = useState();

  

  const [
    addFaq,
    {
      data: addFaqData,
      isLoading: addFaqIsLoading,
      isError: addFaqIsError,
      error: addFaqError,
    },
  ] = useAddfaqsMutation();

  const navigate = useNavigate();

  useEffect(() => {
    const hasViewPermission = hasPermission("video");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    if (addFaqData) {
      toast.success("Added Successfully!");
      navigate(`/genefied/${getVendorName()}/faqs`);
    }
  }, [addFaqData, addFaqError]);

  const handleSubmit = (e) => {
    e.preventDefault();
   
    if (!question) {
      toast.error("Please provide a Question", {
        id: "imageError",
      });
      return;
    }

    if (!answer) {
        toast.error("Please provide an Answer", {
          id: "imageError",
        });
        return;
      }

    addFaq({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        question,
        answer
      },
    });
  };
  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Add Faq
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <div className="w-full">
                <div className="mb-3 w-full justify-center ">
                  <div className="w-full">
                    <InputComponent
                      name={"Question"}
                      type={"textarea"}
                      value={question}
                      setValue={setQuestion}
                      required={true}
                      placeholder={"Enter Title"}
                    />
                  </div>
                </div>

                <div className="mb-3 w-full justify-center ">
                  <div className="w-full">
                    <InputComponent
                      name={"Answer"}
                      type={"textarea"}
                      value={answer}
                      setValue={setAnswer}
                      required={true}
                      placeholder={"Enter Title"}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFaq;
