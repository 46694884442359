import React, { useEffect, useState } from 'react'
import SelectComponent from '../../../../components/Tenant/SelectComponent/SelectComponent';
import InputComponent from '../../../../components/Tenant/InputComponent/InputComponent';
import toast from 'react-hot-toast';
import LinksComponent from '../../../../components/Tenant/LinksComponent/LinksComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddSupplyBeamSiteLocationsMutation } from '../../../../features/supplyBeam/siteLocationsSB/siteLocationSBApi';

const roles =[
    {
        id : 1,
        name : "India"
    }
]
const AddSiteLocations = () => {
    const navigate = useNavigate();
    const [locationName, setLocationName] = useState(null);
    const [address, setAddress] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedState, setSelectedState] = useState({});
    const [selectedDistrict, setSelectedDistrict] = useState({});
    const [selectedCity, setSelectedCity] = useState({});
    const [countries, setCounties] = useState([
        {
            id : 1 ,
            name : "india"
        }
    ]);
    const [states, setStates] = useState([
        {
            id : 1 ,
            name : "haryana"
        },
        {
            id : 2 ,
            name : "Delhi"
        },
        {
            id : 3 ,
            name : "Maharastra"
        }
    ]);
    const [districts, setDistricts] = useState([
        {
            id : 1 ,
            name : "yamunanagar"
        },
        {
            id : 2 ,
            name : "mumbai"
        },
        {
            id : 3 ,
            name : "delhi"
        }
    ]);
    const [cites, setCities] = useState([
        {
            id : 1 ,
            name : "yamunanagar"
        },
        {
            id : 2 ,
            name : "mumbai"
        },
        {
            id : 3 ,
            name : "delhi"
        }
    ]);

    
    
  
    const location = useLocation();
  
   
  
    const [
        addSupplyBeamSiteLocations,
      {
        data: addSupplyBeamSiteLocationsData,
        isLoading: addSupplyBeamSiteLocationsIsLoading,
        isError: addSupplyBeamSiteLocationsIsError,
        error: addSupplyBeamSiteLocationsError,
      },
    ] = useAddSupplyBeamSiteLocationsMutation();
    const [
      updateTenantUserById,
      {
        data: updateTenantUserByIdData,
        isLoading: updateTenantUserByIdIsLoading,
        isError: updateTenantUserByIdIsError,
        error: updateTenantUserByIdError,
      },
    ] = useAddSupplyBeamSiteLocationsMutation();
  
    useEffect(() => {
      console.log("location ======>",location.state);
      const data = location.state;
      if (data) {
        console.log("entered in if");
        setAddress(data.address);
        setLocationName(data.location);
        // setSelectedRole({
        //   role_id: data.role_id,
        //   name: data.role_name,
        //   id: data.id,
        // });
  
  
      }
    }, [location.state]);
  
  
  
    useEffect(() => {
      if (addSupplyBeamSiteLocationsData) {
        toast.dismiss();
        toast.success(addSupplyBeamSiteLocationsData.message,{
          id : "addSupplyBeamSiteLocationsData",
        });
  
        navigate(-1);
      }
      if (addSupplyBeamSiteLocationsError) {
        toast.dismiss();
        toast.error(addSupplyBeamSiteLocationsError.data.message,{
          id : "addSupplyBeamSiteLocationsError",
        });
      }
    }, [addSupplyBeamSiteLocationsData, addSupplyBeamSiteLocationsError]);
  

  
    const handleAddSiteLocations = (e) => {
      e.preventDefault();
   
      if (!address) {
        toast.error("Please enter the Address", {
          id: "Address",
        });
        return;
      }
      if (!locationName) {
        toast.error("Please enter the Location Name ", {
          id: "Location Name",
        });
        return;
      }
      if (!selectedCountry.name) {
        toast.error("Please Selected Country", {
          id: "imageCountryError",
        });
        return;
      }
      if (!selectedState.name) {
        toast.error("Please Selected State", {
          id: "state",
        });
        return;
      }
      if (!selectedDistrict.name) {
        toast.error("Please Selected District", {
          id: "District",
        });
        return;
      }
      if (!selectedCity.name) {
        toast.error("Please Selected City", {
          id: "city",
        });
        return;
      }
     
  
      addSupplyBeamSiteLocations({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        data: {
          location: locationName,
          address: address,
          country: selectedCountry.name,
          state: selectedState.name,
          district: selectedDistrict.name,
          city: selectedCity.name,
         
        },
      });
    };
  
    const handleUpdateUser = (e) => {
      e.preventDefault();
    
      if (!address) {
        toast.error("Please enter the Address", {
          id: "Address",
        });
        return;
      }
      if (!locationName) {
        toast.error("Please enter the Location Name ", {
          id: "Location Name",
        });
        return;
      }
      if (!selectedCountry.name) {
        toast.error("Please Selected Country", {
          id: "imageCountryError",
        });
        return;
      }
      if (!selectedState.name) {
        toast.error("Please Selected State", {
          id: "state",
        });
        return;
      }
      if (!selectedDistrict.name) {
        toast.error("Please Selected District", {
          id: "District",
        });
        return;
      }
      if (!selectedCity.name) {
        toast.error("Please Selected City", {
          id: "city",
        });
        return;
      }
  
      updateTenantUserById({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        data: {
            location: locationName,
            address: address,
            country: selectedCountry.name,
            state: selectedState.name,
            district: selectedDistrict.name,
            city: selectedCity.name,
        },
        id:location.state.id
      });
    };
  
    useEffect(() => {
      if (updateTenantUserByIdData) {
        toast.dismiss();
        toast.success(updateTenantUserByIdData.message,{
          id : "updateTenantUserByIdData",
        });
  
        navigate(-1);
      }
      if (updateTenantUserByIdError) {
        toast.dismiss();
        toast.error(updateTenantUserByIdError.data.message,{
          id : "updateTenantUserByIdError",
        });
      }
    }, [updateTenantUserByIdData, updateTenantUserByIdError]);
  
    
    return (
      <div className="flex w-full bg-slate-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {location && location.state ? "Update " : "Add "} Site Location
            <LinksComponent />
          </div>
          <div className="mx-4 mt-4 p-3 bg-white shadow rounded-lg">
            <form className="">
              <div>
                <div className="w-full flex justify-center space-x-3 ">
                  <div className="mb-6 w-1/4">
                    <InputComponent
                      name={"Location Name*"}
                      type={"text"}
                      value={locationName}
                      setValue={setLocationName}
                      required={true}
                    />
                  </div>
                  <div className="mb-6 w-1/4">
                    <InputComponent
                      name={"Address"}
                      type={"text"}
                      value={address}
                      setValue={setAddress}
                      required={true}
                    />
                  </div>
                 
                  <div className="mb-6 w-1/4 slctbox dropds">
                    <SelectComponent
                      title={"Select country*"}
                      selected={selectedCountry}
                      setSelected={setSelectedCountry}
                      dataArr={countries}
                    />
                  </div>
                 
                  <div className="mb-6 w-1/4 slctbox dropds">
                    <SelectComponent
                      title={"Select State*"}
                      selected={selectedState}
                      setSelected={setSelectedState}
                      dataArr={states}
                    />
                  </div>
                 
                  <div className="mb-6 w-1/4 slctbox dropds">
                    <SelectComponent
                      title={"Select district*"}
                      selected={selectedDistrict}
                      setSelected={setSelectedDistrict}
                      dataArr={districts}
                    />
                  </div>
                 
                  <div className="mb-6 w-1/4 slctbox dropds">
                    <SelectComponent
                      title={"Select city*"}
                      selected={selectedCity}
                      setSelected={setSelectedCity}
                      dataArr={cites}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={ location && location.state ?  handleUpdateUser : handleAddSiteLocations}
                >
                  {location && location.state ? "Update " : "Add "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
}

export default AddSiteLocations