import { rootApi } from "../../api/rootApi";

export const legalApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteLegal: builder.mutation({
        query: (params) => {
          return {
              method: "DELETE",
              url: `/tenant/legal/delete/${params.legalId}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + params.token,
                slug: params.tenant_id,
              },
            };
        },
      }),
    fetchLegals: builder.mutation({
      query: (params) => {
        return {
            method: "GET",
            url: `/tenant/legal/?limit=${params.limit}&&offset=${params.offset}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
      },
    }),
    fetchLegalByLegalId: builder.mutation({
      query: (params) => {
        return {
            method: "GET",
            url: `/tenant/legal/?id=${params.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
      },
    }),
    uploadlegal: builder.mutation({
      query: (params) => {
        return {
          method: "post",
          url: `/tenant/legal/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug : params.tenant_id,
          },
          body : params.data
        };
      },
    }),
  }),
});

export const { useDeleteLegalMutation , useFetchLegalByLegalIdMutation ,useFetchLegalsMutation,useUploadlegalMutation } = legalApi;
