import { rootApi } from "../../api/rootApi";

export const EmailTemplateApiTenant = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenantEmailTemplates: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/tenant/emailTemplate",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    createTenantEmailTemplate: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/emailTemplate/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),

    updateTenantEmailTemplate: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/emailTemplate/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),

    deleteTenantEmailTemplate: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/emailTemplate/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateTenantEmailTemplateMutation , useGetAllTenantEmailTemplatesMutation , useUpdateTenantEmailTemplateMutation , useDeleteTenantEmailTemplateMutation
} = EmailTemplateApiTenant;
