import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import MultiSelectComponent from "../../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import { useFetchSubcategoriesMutation } from "../../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { csvFileToArray } from "../../../../utils/csvFileToArray";
import { toast } from "react-hot-toast";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import { getVendorName } from "../../../../utils/getVendorName";
import { hasPermission } from "../../../../utils/hasPermission";
import { useNavigate } from "react-router-dom";
import { useBulkInsertCouponsMutation } from "../../../../features/tenant/staticCoupons/staticCouponsApi";
import { useFetchAllCouponProviderMutation } from "../../../../features/tenant/couponProvider/couponProviderApi";

const AddStaticCoupon = () => {
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [selectedBrandName, setSelectedBrandName] = useState({
    id: -1,
    name: "Select Provider",
  });

  const [desc, setDesc] = useState("");
  const [termCondititons, setTermCondititons] = useState("")

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (type, data) => {
    setShowModal(true);
  };

  const [
    fetchCouponProviders,
    {
      data: couponProviderList,
      isLoading: couponProviderIsLoading,
      error: couponProviderError,
    },
  ] = useFetchAllCouponProviderMutation();


  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  const navigate = useNavigate();

  useEffect(() => {
     
    const hasViewPermission = hasPermission("addStaticCoupon");

    if(!hasViewPermission){
      navigate(`/genefied/${getVendorName()}/`)
    }
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
    fetchCouponProviders({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      type: "static",
    });
  }, []);

  useEffect(() => {
    if (subCategoriesData) {
      console.log("SubCAtegories", subCategoriesData);
      // setSelectedProductCategory(subCategoriesData.body);
    }
  }, [subCategoriesData, subCategoriesError]);

  const [file, setFile] = useState();
  const handleOnChange = (e) => {
    // console.log(e.target);
    setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const [
    bulkInsertcoupon,
    {
      data: addcouponData,
      isLoading: addcouponIsLoading,
      error: addcouponError,
    },
  ] = useBulkInsertCouponsMutation();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const fileReader = new FileReader();

    const categoryIds = selectedCategory.map((category) => category.id);

    console.log(categoryIds, selectedBrandName);

    if (categoryIds.length <= 0) {
      toast.error("Please select category", { id: "selectCategoryError" });
      return;
    }

    if (selectedBrandName.id === -1) {
      toast.error("Please select a brand", { id: "selectBrandError" });
      return;
    }

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        //  console.log(event);
        //  console.log(event.target.result);
        const data = csvFileToArray(text);
        bulkInsertcoupon({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          body: {
            qty: data.length,
            static_coupons: data,
            brand: selectedBrandName.name,
            category: categoryIds,
            desc : desc ,
            terms_conditions : termCondititons
          },
        });
      };
      fileReader.readAsText(file);
    }
  };

  useEffect(() => {
    if (addcouponData) {
      console.log("coupon data added", addcouponData);
      toast.success("Coupons Saved", {id : "couponListSuccess"})
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/static-coupon`)
      }, 400);
    }
    if (addcouponError) {
      console.log("coupon data error", addcouponError);
      toast.error("Some error occured" , {
        id : "couponListError",
    }); 
    }

    if (addcouponIsLoading) {
      console.log("add coupon loading", addcouponIsLoading);
    }
  }, [addcouponData, addcouponError, addcouponIsLoading]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Add Static coupon
            <LinksComponent />
          </div>

          <div className="w-full ">
            <div className="p-3">
              <div className="p-3 w-full bg-white shadow rounded-lg">

<div className="w-full flex justify-center space-x-4">
  <div className="mb-6 bottom-2 w-1/2">
  <div className="w-full">
                      <MultiSelectComponent
                        title={"Category"}
                        selected={selectedCategory}
                        setSelected={setSelectedCategory}
                        dataArr={
                          subCategoriesData ? subCategoriesData?.body : []
                        }
                      />
                    </div>
  </div>

  <div className="mb-6 bottom-2 w-1/2">
  <div className="w-full">
                      <SelectComponent
                        title={"Select Provider"}
                        selected={selectedBrandName}
                        setSelected={setSelectedBrandName}
                        dataArr={couponProviderList ? couponProviderList.body : []}
                        placeholder="Select Provider"
                      />
                    </div>
    </div>
</div>

<div className="w-full flex justify-center space-x-4">
  <div className="mb-6 bottom-2 w-1/2">
  <div className="w-full">
                      <InputComponent
                        name={"Coupon Description"}
                        type={"textarea"}
                        value={desc}
                        setValue={setDesc}
                        required={true}
                        maxLength={200}
                      />
                    </div>
  </div>

  <div className="mb-6 bottom-2 w-1/2">
  <div className="w-full">
                      <InputComponent
                        name={"Terms & Conditions"}
                        type={"textarea"}
                        value={termCondititons}
                        setValue={setTermCondititons}
                        required={true}
                        maxLength={200}
                      />
                    </div>
  </div>

  </div>



              <div className="flex flex-col justify-center align-middle w-full ">
                    {/* <h2 className="font-bold text-xl mb-3">Add Batch</h2> */}
                  
                  
                   
                  
                    <div className="w-full flex justify-center ">
                      <div className="flex items-center justify-center w-full">
                        <label
                          for="dropzone-file"
                          className="flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                        >
                          <div className="flex flex-col items-center justify-center ">
                            <svg
                              aria-hidden="true"
                              className="w-20 h-20 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                strokeLinejoin="round"
                                stroke-width="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 text-center">
                              <span className="font-semibold">
                                Choose CSV file
                              </span>
                              <div>Click to upload or drag and drop</div>
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={handleOnChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-center mt-2">
                      <button
                        onClick={(e) => handleOnSubmit(e)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Add
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <ModalComponent
            size={"md"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  // onClick={(e) => handleAddBrand(e)}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default AddStaticCoupon;
