export const getUserPrograms = (usertype, programsObject) => {
  // DWAN , Genuinity , Rewardify , SupplyBeam , HybridOcean
  const programs = [];

  if (programsObject["Rewardify"].taken) {
    if (programsObject["Rewardify"]["users"].includes(usertype)) {
        programs.push("Rewardify")
    }
  }

  if (programsObject["Genuinity"].taken) {
    if (programsObject["Genuinity"]["users"].includes(usertype)) {
        programs.push("Genuinity")
    }
  }
  if (programsObject["DWAN"].taken) {
    if (programsObject["DWAN"]["users"].includes(usertype)) {
        programs.push("DWAN")
    }
  }
  if (programsObject["SupplyBeam"].taken) {
    if (programsObject["SupplyBeam"]["users"].includes(usertype)) {
        programs.push("SupplyBeam")
    }
  }
  return programs;
};
