import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateEmailCredentialMutation,
  useUpdateEmailCredentialMutation,
} from "../../../features/admin/emailMaster/emailCredentialsApiAdmin";

import { toast } from "react-hot-toast";
import validator from "validator";

const AddEmailCredentials = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [isUpdate, setIsUpdate] = useState(false);

  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);

  const [
    createEmailCredential,
    {
      data: createEmailCredentialData,
      error: createEmailCredentialError,
      isLoading: createEmailCredentialisLoading,
    },
  ] = useCreateEmailCredentialMutation();

  const [
    updateEmailCredential,
    {
      data: updateEmailCredentialData,
      error: updateEmailCredentialError,
      isLoading: updateEmailCredentialisLoading,
    },
  ] = useUpdateEmailCredentialMutation();

  useEffect(() => {
    if (createEmailCredentialData) {
      toast.success("Credentials Added", {
        id: "createEmailCredentialSuccess",
      });
      setTimeout(() => {
        navigate("/emailCredential");
      }, 700);
    }

    if (createEmailCredentialError) {
      toast.error("Something went wrong", {
        id: "createEmailCredentialError",
      });
    }
  }, [
    createEmailCredentialData,
    createEmailCredentialError,
    createEmailCredentialisLoading,
  ]);

  const handleSubmit = () => {
    if (!userId) {
      toast.error("please enter user id", { id: "noUserId" });
      return;
    }

    if (!validator.isEmail(userId) && userId.length > 0) {
      toast.error("Please enter a valid email address", {
        id: "emailNotValid",
      });
      return;
    }

    if (!password) {
      toast.error("please enter password", { id: "noPassword" });
      return;
    }

    createEmailCredential({
      token: sessionStorage.getItem("access_token"),
      data: {
        user_id: userId,
        password: password,
      },
    });
  };

  const handleUpdate = () => {
    if (!userId) {
      toast.error("please enter user id", { id: "noUserId" });
      return;
    }

    if (!validator.isEmail(userId) && userId.length > 0) {
      toast.error("Please enter a valid email address", {
        id: "emailNotValid",
      });
      return;
    }

    if (!password) {
      toast.error("please enter password", { id: "noPassword" });
      return;
    }

    updateEmailCredential({
      token: sessionStorage.getItem("access_token"),
      id: location.state.id,
      data: {
        user_id: userId,
        password: password,
      },
    });
  };

  useEffect(() => {
    if (updateEmailCredentialData) {
      toast.success("Credentials Updated", {
        id: "updateEmailCredentialSuccess",
      });
      setTimeout(() => {
        navigate("/emailCredential");
      }, 700);
    }

    if (updateEmailCredentialError) {
      toast.error("Something went wrong", {
        id: "updateEmailCredentialError",
      });
    }
  }, [
    updateEmailCredentialData,
    updateEmailCredentialError,
    updateEmailCredentialisLoading,
  ]);

  useEffect(() => {
    // console.log(location.state);
    if (location.state) {
      setIsUpdate(true);
      setUserId(location.state.user_id);
      setPassword(location.state.password);
    }
  }, [location]);

  return (
    <>
      <div className="page-content">
        <div className="card">
          <div className="card-body">
            <div className="borderbtm d-flex align-items-start justify-content-between">
              <h4 className="card-title mb-0">
                {" "}
                {isUpdate ? " Update " : "Add New "}Email Credentials
              </h4>
              {isUpdate && (
                <div className="d-flex align-items-center flex-wrap text-nowrap">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                    onClick={() => navigate("/addEmailTemplate")}
                  >
                    Add Email Templates
                  </button>
                </div>
              )}
            </div>
            <div className="cmxform">
              <div className="">
                <div className="grid grid-cols-2 gap-4">
                  <div className=" mt-3">
                    <label for="name"> User Id </label>
                    <input
                      name={""}
                      className="border rounded-lg shadow-sm shadow-black  w-full p-2"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                      type="email"
                    />
                  </div>

                  <div className=" mt-3">
                    <label for="name"> Password </label>
                    <input
                      name={""}
                      className="border rounded-lg shadow-sm shadow-black  w-full p-2"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                    />
                  </div>
                </div>

                <div className="cmxform mt-3">
                  {isUpdate ? (
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0 px-4 py-2.5"
                        onClick={() => handleUpdate()}
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0 px-4 py-2.5"
                        onClick={() => handleSubmit()}
                      >
                        Add
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmailCredentials;
