import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useNavigate } from "react-router-dom";
import {
  useDeleteCatalogueMutation,
  useFetchAllCataloguesMutation,
  useUpdateStatusOfCatalogueByIdMutation,
} from "../../../features/tenant/giftCatalogue/giftCatalogueApi";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { toast } from "react-hot-toast";

const TypeArr = [{
  id: "1",
  name: "Dream Gift",
  },
{
  id: "2",
  name: "Other",
  },
  {
    id: "3",
    name: "Scheme",
    }]


const GiftCatalogueList = () => {
  const navigate = useNavigate();

  const addGiftCataloguePermission = hasPermission("addGiftCatalogue");
  const editGiftCataloguePermission = hasPermission("editGiftCatalogue");
  const deleteGiftCataloguePermission = hasPermission("deleteGiftCatalogue");
  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.catalogue_name,
      sortable: true,
    },
    {
      name: "Total Gifts",
      cell: (row) => row.gift_count,
      sortable: true,
      width: "110px",
    },
    {
      name: "Catalogue Type",
      cell: (row) => row.catalogue_type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Type",
      cell: (row) => (
        TypeArr.map(x=>{
          if(x.id == row.type){
            return x.name
          }else{
            return ""
          }
        })
      ),
      sortable: true,
      // width: "120px",
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Status",
      cell: (r) =>
        r.status === "2" ? (
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => handleUpdate(r)}
          >
            In-Active
          </button>
        ) : (
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleUpdate(r)}
          >
            Active
          </button>
        ),
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <button
            onClick={() =>
              navigate("catalogue", {
                state: row,
              })
            }
            className="flex justify-center items-center rounded bg-success  px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]"
          >
            View
          </button>
        </div>
      ),
      minWidth: "100px",
    },
    // {
    //   name: "",
    //   cell: (row) =>
    //     editGiftCataloguePermission && (
    //       <div>
    //         <button
    //           // onClick={() =>
    //           //   navigate("add", {
    //           //     state: row,
    //           //   })
    //           // }
    //           className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    //         >
    //           <AiOutlineEdit className="mr-1" />
    //           Edit
    //         </button>
    //       </div>
    //     ),
    //   minWidth: "100px",
    // },
    {
      name: "",
      cell: (row) =>
        deleteGiftCataloguePermission && (
          <div>
            <button
              onClick={() => handleDelete(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          </div>
        ),
      minWidth: "120px",
    },
  ];

  const [cataloguesList, setCataloguesList] = useState([]);

  const [
    fetchCatalogues,
    {
      data: cataloguesData,
      isLoading: cataloguesIsLoading,
      isError: cataloguesIsError,
      error: cataloguesError,
    },
  ] = useFetchAllCataloguesMutation();

  useEffect(() => {
    const hasViewPermission = hasPermission("giftCatalogueList");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    fetchCatalogues({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const [
    deleteCatalogue,
    {
      data: delCatalogueData,
      error: delCatalogueError,
    },
  ] = useDeleteCatalogueMutation();

  const [
    updateCatalogueStatus,
    { data: updateCatalogueStatusData, error: updateCatalogueStatusError },
  ] = useUpdateStatusOfCatalogueByIdMutation();

  const handleDelete = (data) => {
    if (window.confirm(`Are you sure you want to delete`)) {
      if (!data.catalogue_id) {
        return;
      }

      deleteCatalogue({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: data.catalogue_id,
      });

      fetchCatalogues({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  };

  const handleUpdate = (data) => {
    
    let status = "1";
    if (data.status === "1") {
      status = "2";
    } else status = "1";

    console.log(data , status);

    updateCatalogueStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: data.catalogue_id,
      status: status,
    });
  };

  useEffect(() => {
    if(cataloguesData && cataloguesData.body){
      console.log("cataloguesData" , cataloguesData);
      const arr = [...cataloguesData.body];
      arr.sort((a, b) => a.catalogue_id - b.catalogue_id)
      console.log(arr);
      setCataloguesList(arr)
    }
    
  }, [cataloguesData])
  

  useEffect(() => {
    toast.dismiss();
    if (updateCatalogueStatusData) {
      toast.success("Catalogue Updated Successfully !!");
      fetchCatalogues({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      });
    }
  }, [updateCatalogueStatusData, updateCatalogueStatusError]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Gift Catalogues
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={cataloguesList}
                // loading={loading}
                totalRows={cataloguesData && cataloguesData?.body.length}
                // handlePerRowsChange={handlePerRowsChange}
                // handlePageChange={handlePageChange}
                defaultSortField="catalogue_id"
                defaultSortAsc={true}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {addGiftCataloguePermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                      
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCatalogueList;
