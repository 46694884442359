import { rootApi } from "../../api/rootApi";

export const paymentTargetApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadPaymentTarget: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "POST",
          url: "/tenant/paymentTarget/add",
          headers: {
            "Content-Type": "application/json",
            slug: `${params.tenant_id}`,
            Authorization: "Bearer " + params.token,
          },
          body : JSON.stringify(params.body)
        };
      },
    }),
    getAllBatchOfPaymentTarget: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "GET",
          url: "/tenant/paymentTarget/",
          headers: {
            "Content-Type": "application/json",
            slug: `${params.tenant_id}`,
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
    getByBatchIdOfPaymentTarget: builder.mutation({
      query: (params) => {
        console.log("body ......................", params);
        return {
          method: "POST",
          url: "/tenant/paymentTarget/"+params.batch_id,
          headers: {
            "Content-Type": "application/json",
            slug: `${params.tenant_id}`,
            Authorization: "Bearer " + params.token,
          },
          body : JSON.stringify(params.body)
        };
      },
    }),
  }),
});

export const {
    useUploadPaymentTargetMutation,
    useGetAllBatchOfPaymentTargetMutation,
    useGetByBatchIdOfPaymentTargetMutation
} = paymentTargetApi;
