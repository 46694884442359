import { rootApi } from "../../api/rootApi";

export const userCashbackApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserCashbackByAppUserId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/userCashback?app_user_id=${params.app_user_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchCashbackRedemptionsOfUser: builder.mutation({
      query: (params) => {
        // console.log("params--->",params);
        return {
          method: "GET",
          url: `app/userPointRedemptions/${params.userId}/${params.type}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useFetchUserCashbackByAppUserIdMutation,
  useFetchCashbackRedemptionsOfUserMutation,
} = userCashbackApi;
