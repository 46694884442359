import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import toast from "react-hot-toast";
import { useAddwhatsappNotificationCredentialForTenantMutation, useUpdatewhatsappNotificationCredentialForTenantMutation } from "../../../features/tenant/whatappNotificationCredential/whatsappNotificaitonCredentialApi";

const AddWhatsappNotificationCredential = () => {
      const navigate = useNavigate();
    const location = useLocation();


    console.log("location ",location);
  const isUpdate = location && location.state ?  location.state.edit : false;

  const [userId, setUserId] = useState(isUpdate ? location.state.data.api_credential.user_id : null);
  const [password, setPassword] = useState(isUpdate ? location.state.data.api_credential.password : null);

  const [
    addwhatsappNotificationCredentialForTenant,
    {
      data: addwhatsappNotificationCredentialForTenantData,
      isError: addwhatsappNotificationCredentialForTenantIsError,
      error: addwhatsappNotificationCredentialForTenantError,
      isLoading: addwhatsappNotificationCredentialForTenantIsLoading,
    },
  ] = useAddwhatsappNotificationCredentialForTenantMutation();

  const [
    updatewhatsappNotificationCredentialForTenant,
    {
      data: updatewhatsappNotificationCredentialForTenantData,
      isError: updatewhatsappNotificationCredentialForTenantIsError,
      error: updatewhatsappNotificationCredentialForTenantError,
      isLoading: updatewhatsappNotificationCredentialForTenantIsLoading,
    },
  ] = useUpdatewhatsappNotificationCredentialForTenantMutation();

  const handleAddWhatsappNotificationCredential = (e) => {
    e.preventDefault();
    
    if (
     userId == null || userId.length ==0
    ) {
        toast.dismiss();
      toast.error("Please Enter user Id ", {
        id: "field error 1 ",
      });
      return;
    }
    if (
     password == null || password.length ==0
    ) {
        toast.dismiss();
      toast.error("Please Enter password ", {
        id: "field error 2 ",
      });
      return;
    }

    let apiCredential = {
        user_id : userId,
        password : password
    };

    addwhatsappNotificationCredentialForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        api_credential: apiCredential,
        type: "normal",
      },
    });
  };


  useEffect(() => {
    if (addwhatsappNotificationCredentialForTenantData) {
      toast.dismiss();
      toast.success(`${addwhatsappNotificationCredentialForTenantData.message}`, {
        id: `addwhatsappNotificationCredentialForTenantData`,
      });
      navigate(-1);
    }
    if (addwhatsappNotificationCredentialForTenantError) {
      toast.dismiss();
      toast.success(`${addwhatsappNotificationCredentialForTenantError.data.message}`, {
        id: `addwhatsappNotificationCredentialForTenantError`,
      });
    }
  }, [addwhatsappNotificationCredentialForTenantData, addwhatsappNotificationCredentialForTenantError]);

  const handleUpdate = (e)=>{
    if (
        userId.length ==0
       ) {
        toast.dismiss();
         toast.error("Please Enter user Id ", {
           id: "field error 1 ",
         });
         return;
       }
       if (
        password.length ==0
       ) {
        toast.dismiss();
         toast.error("Please Enter password ", {
           id: "field error 2 ",
         });
         return;
       }

       updatewhatsappNotificationCredentialForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id : location.state.data.id,
        body: {
          api_credential: {
            user_id : userId,
            password : password
          },
          type: "normal",
        },
       })
     
  }


  useEffect(() => {
    if (updatewhatsappNotificationCredentialForTenantData) {
      toast.dismiss();
      toast.success(`${updatewhatsappNotificationCredentialForTenantData.message}`, {
        id: `updatewhatsappNotificationCredentialForTenantData`,
      });
      navigate(-1);
    }
    if (updatewhatsappNotificationCredentialForTenantError) {
      toast.dismiss();
      toast.success(`${updatewhatsappNotificationCredentialForTenantError.data.message}`, {
        id: `updatewhatsappNotificationCredentialForTenantError`,
      });
    }
  }, [updatewhatsappNotificationCredentialForTenantData, updatewhatsappNotificationCredentialForTenantError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}
            WhatsApp Notification Credential
            <LinksComponent />
          </div>
          <div className="p-3 ">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className="w-full flex justify-center space-x-3 ">
                
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                      for="name"
                    >
                      Enter User Id
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={userId}
                      onChange={(e) => {
                        setUserId(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>{" "}

                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                      for="name"
                    >
                      Enter password
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      type="text"
                    />
                  </div>
                </div>{" "}
            
              </div>
              <div className="flex justify-center mt-2">
                {isUpdate ? (
                  <button
                    type="update"
                      onClick={(e) => handleUpdate(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                      onClick={handleAddWhatsappNotificationCredential}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Add
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWhatsappNotificationCredential;
