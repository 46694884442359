import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import {
  useGetAllWinnersMutation,
  useGetWheelRewardsByWheelIdMutation,
} from "../../../features/tenant/wheelRewards/wheelRewardsApi";
import { useGetWheelByCampaignIdMutation } from "../../../features/tenant/wheel/wheelApi";

const WheelSummary = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const [wc, setWc] = useState();
  const [wheelId, setWheelId] = useState(null);
  const color = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .primary_color;

  const [getAllWinners, { data: allWinnersData, error: allWinnersError }] =
    useGetAllWinnersMutation();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const initialRequest = {
    name: "",
    mobile: "",
    limit: 10,
    offset: 0,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (request.name === "" && request.mobile === "") {
      getAllWinnersFun();
    }
  }, [request]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const [
    getWheelRewards,
    { data: wheelRewardsData, error: addWheelRewardsError },
  ] = useGetWheelRewardsByWheelIdMutation();

  const [getWheelByCampaignId, { data: wheelData, error: wheelError }] =
    useGetWheelByCampaignIdMutation();

  const getAllWinnersFun = () => {
    getAllWinners({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };
  useEffect(() => {
    getAllWinnersFun();
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (wc?.id) {
      getWheelByCampaignId({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: wc.id,
        limit: 10,
        offset: 0,
      });
    }
  }, [wc]);

  useEffect(() => {
    console.log(wheelData, "Here");
    if (wheelData?.body?.length) {
      setWheelId(wheelData.body[0].id);
    }
  }, [wheelData]);

  useEffect(() => {
    console.log(location.state, "STAte");
    setWc(location.state);
  }, [location.state]);

  const rewardColumns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Probability",
      cell: (row) => row.chance,
      sortable: true,
      width: "120px",
    },
    {
      name: "Quantity",
      cell: (row) => row.quantity - row.used_quantity,
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      cell: (r) =>
        r.status === "2" ? <div>In-Active</div> : <div>Active</div>,
      ignoreRowClick: true,
      button: true,
    },
    {
      name: "Price",
      cell: (row) => row.price,
      sortable: true,
      width: "120px",
    },
    {
      name: "Image",
      cell: (row) => (
        <img src={ row.image} />
      ),
      sortable: true,
      width: "120px",
    },

    {
      name: "Start Date",
      cell: (row) => new Date(row.start_date).toLocaleString(),
      sortable: true,
      width: "130px",
    },
    {
      name: "End Date",
      cell: (row) => new Date(row.end_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
      width: "130px",
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      width: "130px",
    },
  ];

  const winnerColumns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },

    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Price",
      cell: (row) => row.price,
      sortable: true,
      width: "120px",
    },
    {
      name: "Image",
      cell: (row) => (
        <img src={ row.image} />
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Winner Name",
      cell: (row) => row.created_by_name,
      sortable: true,
      width: "180px",
    },
    {
      name: "Winner Mobile No.",
      cell: (row) => row.mobile,
      sortable: true,
      width: "190px",
    },
    {
      name: "Won At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];
  useEffect(() => {
    if (location.state) {
      if (openTab === 2 && wheelId) {
        getWheelRewards({
          token: sessionStorage.getItem("access_token"),
          tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
          id: wheelId,
        });
      } else if (openTab === 3) {
        // getWinnerList
      }
    }
  }, [openTab]);

  function getFormattedStates(states) {
    if (!states || !Array.isArray(states)) {
      return "No excluded states";
    }

    const stateNames = states.map((stateJson) => {
      try {
        const stateObject = JSON.parse(stateJson);
        return stateObject.name;
      } catch (error) {
        console.error("Error parsing 'states' data: ", error);
        return "Invalid state data";
      }
    });

    return stateNames.join(", ");
  }
  return (
    <div className="w-full container flex flex-col items-center justify-center">
      <div className="w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Wheel Summary
          <LinksComponent />
        </div>
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
          role="tablist"
        >
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <span
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 1
                  ? "text-blue-600 bg-" + color + "-600"
                  : "text-" + color + "-600 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              Summary
            </span>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <span
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 2
                  ? "text-blue-600 bg-" + color + "-600"
                  : "text-" + color + "-600 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              Reward List
            </span>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <span
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 3
                  ? "text-blue-600 bg-" + color + "-600"
                  : "text-" + color + "-600 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              Winners
            </span>
          </li>
        </ul>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                {wc && (
                  <div className="bg-gray-300 w-full border-opacity-40 rounded-lg p-2">
                    <div className="w-full flex justify-center gap-3 ">
                      <div className="mb-6 w-1/3"> Title: {wc.title}</div>
                      <div className="mb-6 w-1/3">
                        {" "}
                        Description:{wc.description}
                      </div>
                      <div className="mb-6 w-1/3"> Budget:{wc.budget}</div>
                    </div>
                    <div className="w-full flex justify-center gap-3 ">
                      <div className="mb-6 w-1/3">
                        Start Date:{wc.start_date}
                      </div>
                      <div className="mb-6 w-1/3">End Date:{wc.end_date}</div>
                      <div className="mb-6 w-1/3">
                        Excluded States: {getFormattedStates(wc?.states)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <div className="bg-gray-300 w-full border-opacity-40 rounded-lg p-2">
                  <div>
                    <DataTableComponent
                      columns={rewardColumns}
                      title={"Reward List"}
                      data={wheelRewardsData?.body?.gifts}
                    >
                      <div className="flex m-2 space-x-2 justify-end"></div>
                    </DataTableComponent>
                  </div>
                </div>
              </div>

              <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                {" "}
                <div className="bg-gray-300 w-full border-opacity-40 rounded-lg p-2">
                  <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                    <div className="flex  space-x-2 justify-end">
                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Name..."
                            required
                            value={request.name}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest((prevState) => {
                                return { ...prevState, name: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="w-52 h-10">
                        <div className="relative searchin">
                          <input
                            type="search"
                            id="default-search"
                            className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Mobile No..."
                            required
                            value={request.mobile}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRequest((prevState) => {
                                return { ...prevState, mobile: newValue };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row  gap-2 h-10">
                        <div className="relative searchin">
                          <button
                            className="py-2 btn btn-outline-primary clear-both float-right  p-2"
                            onClick={() => {
                              getAllWinnersFun();
                              // handleFetchRedemptionHistory();
                              // handleFetchRedemptionHistoryCsv();
                            }}
                          >
                            Search
                          </button>
                        </div>
                        <div className="relative searchin">
                          <button
                            className="btn btn-outline-primary clear-both float-right p-2 whitespace-nowrap"
                            onClick={() => {
                              clearFilters();
                            }}
                          >
                            Clear Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <DataTableComponent
                      columns={winnerColumns}
                      title={"Winners"}
                      data={allWinnersData && allWinnersData?.body?.data}
                      // loading={loading}
                      totalRows={allWinnersData?.body?.total}
                      handlePerRowsChange={handlePerRowsChange}
                      handlePageChange={handlePageChange}
                    >
                      <div className="flex m-2 space-x-2 justify-end"></div>
                    </DataTableComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WheelSummary;
