import React, { useState } from "react";
import ModalComponent from "../Tenant/ModalComponent/ModalComponent";

function convertArrayOfObjectsToCSV(array, columnsToExport) {
  let result = "";
  const columnDelimiter = ",";
  const lineDelimiter = "\n";

  if (!columnsToExport || columnsToExport.length === 0) {
    alert("No columns specified for export.");
    return null;
  }

  const filteredData = array.map((item) =>
    columnsToExport.reduce((acc, key) => {
      acc[key] = item[key] || "";
      return acc;
    }, {})
  );

  const keys = columnsToExport;

  result += keys.map(key => `"${key}"`).join(columnDelimiter); // Quote column names to handle special characters
  result += lineDelimiter;

  filteredData.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      let cellValue = item[key];

      // Handle object type
      if (typeof cellValue === 'object') {
        cellValue = JSON.stringify(cellValue);
        cellValue = cellValue.replace(/"/g, '""'); // Escape double quotes
        cellValue = `"${cellValue}"`; // Quote cell value
      } else {
        cellValue = String(cellValue || "").replace(/\n/g, " ");
        cellValue = cellValue.replace(/"/g, '""'); // Escape double quotes
        cellValue = `"${cellValue}"`; // Quote cell value

        if(key === 'status'){
          // console.log("cellValue: " , cellValue);
          cellValue = cellValue == '"1"' ? "Active" : cellValue == '"2"' ? "Inactive" : "Deleted";
        }
      }

      result += cellValue;
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}


function downloadCSV(data, columnsToExport) {
  if (!data || data.length === 0) {
    alert("No data to export.");
    return;
  }

  // console.log("arrray" , data);


  const link = document.createElement("a");
  const csv = convertArrayOfObjectsToCSV(data, columnsToExport);

  if (csv == null) {
    return;
  }

  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  link.setAttribute("href", url);
  link.setAttribute("download", `export${timestamp}.csv`);
  link.click();
}












const ExportCSV = ({ data, columnsToExport }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialSelectedColumns =
    columnsToExport || (data[0] ? Object.keys(data[0]) : []);
  const [selectedColumns, setSelectedColumns] = useState(
    initialSelectedColumns
  );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleColumnSelection = (column) => {
    const updatedColumns = [...selectedColumns];
    if (updatedColumns.includes(column)) {
      updatedColumns.splice(updatedColumns.indexOf(column), 1);
    } else {
      updatedColumns.push(column);
    }
    setSelectedColumns(updatedColumns);
  };

  const exportCSV = () => {
    toggleModal();
    downloadCSV(data, selectedColumns);
  };
  const handleExportWithDefaults = () => {
    downloadCSV(data, columnsToExport);
  };

  return (
    <div className="flex gap-2 ">
      <button
        className="btn btn-outline-primary clear-both float-right mb-2"
        onClick={handleExportWithDefaults}
      >
        Export CSV with Defaults
      </button>

      <button
        className="btn btn-outline-primary clear-both float-right mb-2"
        onClick={toggleModal}
      >
        Export CSV with Custom Columns
      </button>

      {isModalOpen && (
        <ModalComponent show={isModalOpen} setShow={setIsModalOpen}>
          <div className="px-3 py-3 flex w-full justify-between align-center border-b pb-2 border-gray-100">
          <h2 className="modal-title font-weight-bold text-lg">Select Columns to Export</h2>
           <div className="">
           <button
            className="btn btn-outline-primary clear-both mr-2"
            onClick={exportCSV}
          >
            Export
          </button>
          <button
            className="btn btn-outline-danger clear-both"
            onClick={toggleModal}
          >
            Cancel
          </button>
           </div>
          </div>
          <div className="grid grid-cols-3 gap-2 mt-2 px-3 py-2">
            
            {data && data.length > 0 && Object.keys(data[0]).map((column) => (
              <div key={column} className="modal-checkbox p-2 mb-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 ">
                <label className="flex items-end gap-1 mb-0">
                  <input className=""
                    type="checkbox"
                    checked={selectedColumns.includes(column)}
                    onChange={() => handleColumnSelection(column)}
                  />
                  <span className="modal-label-text capitalize">{column}</span>
                </label>
              </div>
            ))}
          </div>
         
        </ModalComponent>
      )}
    </div>
  );
};

export default ExportCSV;
