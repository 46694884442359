import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  RiListSettingsLine,
  RiLockPasswordLine,
  RiProfileFill,
} from "react-icons/ri";
import { VscActivateBreakpoints, VscDiffAdded } from "react-icons/vsc";
import { BiCube } from "react-icons/bi";
import { FaIndustry } from "react-icons/fa";
import { AiOutlineForm, AiOutlineMail } from "react-icons/ai";
import { MdSms, MdAssignmentAdd } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { logOut } from "../../features/admin/adminAuth/adminAuthSlice";

export default function Navbar() {
  const dispatch = useDispatch();
  const logout = () => {
    toast(
      (t) => (
        <span>
          <h6>
            Are you sure you want to <b>Logout ?</b>
          </h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                sessionStorage.clear();
                dispatch(logOut());
                window.location.href = "/";
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "logout",
      }
    );
    return;
  };

  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname.split("/")[1]);

  useEffect(() => {
    const pathname = location.pathname.split("/")[1];
    setPathname(pathname);
  }, [location]);

  const sidebarOptions = {
    type: "Admin",
    item: [
      {
        title: "Main",
        heading: true,
      },
      {
        title: "Clients",
        path: "",
        icon: <BiCube />,
        heading: false,
      },
      {
        title: "Change Admin Password",
        path: "changeAdminPassword",
        icon: <RiLockPasswordLine />,
        heading: false,
      },
      {
        title: "Industry Master",
        path: "industryMaster",
        icon: <FaIndustry />,
        heading: false,
      },
      {
        title: "App Users",
        path: "appUsers",
        icon: <RiProfileFill />,
        heading: false,
      },
      {
        title: "Client Theme",
        path: "vendorTheme",
        icon: <RiProfileFill />,
        heading: false,
      },

      {
        title: "Client Setup",
        path: "basicSetup",
        icon: <RiListSettingsLine />,
        heading: false,
      },
      {
        title: "Workflow Setup",
        path: "workflowSetup",
        icon: <VscDiffAdded />,
        heading: false,
      },

      {
        title: "App Dashboard",
        path: "appDashboard",
        icon: <RiListSettingsLine />,
        heading: false,
      },
      {
        title: "App Menu",
        path: "appMenu",
        icon: <RiListSettingsLine />,
        heading: false,
      },

      {
        title: "Program Setup",
        path: "program",
        icon: <VscActivateBreakpoints />,
        heading: false,
      },
      {
        title: "Form Setup",
        path: "formsetup",
        icon: <MdAssignmentAdd />,
        heading: false,
      },
      // {
      //   title: "UOM Master",
      //   heading: true,
      // },
      {
        title: "Uom Keys",
        path: "uomKeys",
        icon: <RiListSettingsLine />,
        heading: false,
      },

      // {
      //   title: "Uom Values",
      //   path: "uomValues",
      //   icon: <VscActivateBreakpoints />,
      //   heading: false,
      // },
      {
        title: "Other Options",
        heading: true,
      },
      {
        title: "Form Template ",
        path: "formTemplate",
        icon: <AiOutlineForm />,
        heading: false,
      },
      {
        title: "Email Master ",
        path: "emailCredential",
        icon: <AiOutlineMail />,
        heading: false,
      },
      {
        title: "SMS Master ",
        path: "smsCredential",
        icon: <AiOutlineMail />,
        heading: false,
      },
      {
        title: "Firebase Setup",
        path: "firebaseSetup",
        icon: <VscActivateBreakpoints />,
        heading: false,
      },
      {
        title: "Tenant Dashboard Setup ",
        path: "tenantWebDashboard",
        icon: <VscDiffAdded />,
        heading: false,
      },
      {
        title: "Tenant Sidebar Setup ",
        path: "tenantSidebar",
        icon: <VscDiffAdded />,
        heading: false,
      },
    ],
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-header">
        <a href=" " className="sidebar-brand">
          Gene<span></span>fied
        </a>
        <div className="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="sidebar-body">
        <ul className="nav">
          {sidebarOptions.item.map((item, idx) => {
            if (item.heading) {
              return (
                <li className="nav-item nav-category" key={idx}>
                  {item.title}
                </li>
              );
            } else
              return (
                <li
                  className={
                    item.path == pathname ? " nav-item active " : "nav-item"
                  }
                  key={idx}
                >
                  <Link to={item.path} className="nav-link">
                    {item.icon}
                    <span className="link-title"> {item.title}</span>
                  </Link>
                </li>
              );
          })}
          <li className="nav-item">
            <div to="printQr" className="nav-link">
              <BiLogOut />
              <span className="link-title" onClick={logout}>
                Logout
              </span>
            </div>
          </li>

          {/* <li className="nav-item">
            <Link to="dwan" className="nav-link">
              <MdSms />
              <span className="link-title">dwan</span>
            </Link>
          </li> */}

          {/*      
     <li className="nav-item nav-category">Product</li>

          <li className="nav-item">
            <Link to="productCategory" className="nav-link">
              <span className="link-title">Product Category</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="productSubCategory" className="nav-link">
              <span className="link-title">Product Sub Category</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="addProd" className="nav-link">
              <span className="link-title">Add Product</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="allProducts" className="nav-link">
              <span className="link-title">All Products</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="batchCreation" className="nav-link">
              <span className="link-title">Batch Creation</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="generateQr" className="nav-link">
              <span className="link-title">QR Code Generation</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="printQr" className="nav-link">
              <span className="link-title">QR Code Print</span>
            </Link>
          </li>

  */}
        </ul>
      </div>
    </nav>
  );
}
