import React, { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [companyRePassword, setCompanyRePassword] = useState("");

  // errors
  const [passwordError, setPasswordError] = useState(null);
  const [passwordReError, setPasswordReError] = useState(null);
  const [masterError, setMasterError] = useState(null);

  useEffect(() => {
    toast.remove();
    if (!passwordError && !passwordReError) {
      // setMasterError(null);
    }
  }, [passwordError, passwordReError]);

  const handlePasswordChange = (event, type) => {
    console.log(companyPassword, companyRePassword);
    if (type === 1) setCompanyRePassword(event.target.value);
    else setCompanyPassword(event.target.value);

    if (
      event.target.value.length > 0 &&
      !validator.isStrongPassword(event.target.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      if (type == 1)
        setPasswordReError(
          "Password is not strong , must include a number , symbol , uppercase and lowercase",
        );
      else
        setPasswordError(
          "Password is not strong , must include a number , symbol , uppercase and lowercase ",
        );
    } else {
      if (type == 1) setPasswordReError(null);
      else setPasswordError(null);
    }
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    const adminId = sessionStorage.getItem("adminId");

    if (!adminId) {
      alert("Admin not found");
    }

    if (companyPassword !== companyRePassword) {
      setMasterError("passwords do not match");
      return;
    }

    if (passwordError == null && passwordReError == null) {
      var data = {
        oldPassword: oldPassword,
        newPassword: companyPassword,
      };

      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/admin/update-password/${adminId}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
          "Content-Type": "application/json",
          withCredentials: true,
        },

        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.success) {
            toast.success("Password Updated", {
              id: "passwordUpdateSuccess",
            });
          }
          setTimeout(() => {
            navigate("/");
          }, 200);
        })
        .catch(function (error) {
          console.log(error);
          toast.error(
            error.response?.data?.message
              ? error.response.data.message
              : error.message,
            {
              id: "passwordUpdateError",
            },
          );
        })
        .finally(resetValues);
    } else {
      toast.error("Please fill all fields and check errors");
    }
  };

  const resetValues = () => {
    setOldPassword("");
    setCompanyPassword("");
    setCompanyRePassword("");
  };

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="borderbtm">
              <h4 className="card-title mb-0">Update Admin Password</h4>
              </div>
              <div className="cmxform">
                <div className="row frmds">
                  <div className="col-md-4 my-3">
                    <label>Old Password </label>
                    <input
                      id="m_name"
                      className="form-control"
                      name="m_name"
                      type="password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4 my-3">
                    <label>Password </label>
                    <input
                      id="m_no"
                      className="form-control"
                      name="password"
                      type="password"
                      value={companyPassword}
                      onChange={(e) => handlePasswordChange(e, 0)}
                    />
                    {passwordError && (
                      <p className="text-danger mx-2 my-2">{passwordError}</p>
                    )}
                  </div>
                  <div className="col-md-4 my-3">
                    <label>Re-Password </label>
                    <input
                      id="m_no"
                      className="form-control"
                      name="re-password"
                      type="password"
                      value={companyRePassword}
                      onChange={(e) => handlePasswordChange(e, 1)}
                    />
                    {passwordReError && (
                      <p className="text-danger mx-2 my-2">{passwordReError}</p>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 text-center">
                    {masterError && (
                      <p className="text-danger mx-2 my-2">{masterError}</p>
                    )}
                    <button
                      className="btn btn-outline-primary mt-3 btnall"
                      onClick={(e) => handleChangePassword(e)}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
