import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { HiOutlinePlus } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllAppCampaignMutation,
  useDeleteAppCampaignMutation,
} from "../../../features/tenant/appCampaign/appCampaignApi";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit } from "react-icons/ai";
import { toast } from "react-hot-toast";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";

const Campaign = () => {
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const [prevPage, setPrevPage] = useState(0);
  const columns = [
    {
      name: "SNo",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      width: "120px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => new Date(row.start_date).toLocaleString(),
      sortable: true,
      width: "120px",
    },
    {
      name: "End Date",
      selector: (row) => new Date(row.end_date).toLocaleString(),
      sortable: true,
    },
    {
      name: "Video Title",
      selector: (row) => row.video_title,
      sortable: true,
    },
    {
      name: "Video Url",
      selector: (row) => row.video_link,
      sortable: true,
    },
    {
      name: "Redirection Url",
      selector: (row) => row.web_link,
      sortable: true,
    },

    {
      name: "Applicable For",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => <div  className="w-12 h-12 my-2 rounded-full">
      {/* <ZoomImage image ={ row.images[0]} /> */}
      <img
        src={ row.image}
        alt={row.image}
        className="rounded-full"
        onClick={() =>
          setShowImageModal(
             row.image,
          )
        }
      />
    </div>,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="flex justify-center space-x-2 m-auto">
          <>
            <button
              onClick={() =>
                navigate(`/genefied/${getVendorName()}/campaign/view`, {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              View
            </button>
          </>
          <>
            <button
              onClick={() =>
                navigate(`/genefied/${getVendorName()}/campaign/add`, {
                  state: row,
                })
              }
              className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Edit
            </button>
          </>
          <>
            <button
              onClick={() => deleteBannerToast(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              Delete
            </button>
          </>
        </div>
      ),

      width: "20%",
    },
  ];

  const deleteBannerToast = (row) => {
    toast(
      (t) => (
        <span>
          <h6>
            Are you sure you want to delete this<b> Banner ?</b>
          </h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                deleteAppCampaignFun(row);
                toast.dismiss(t.id);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deletebanner",
      }
    );
    return;
  };
  const [
    deleteAppCampaign,
    {
      data: deleteAppCampaignData,
      isLoading: deleteAppCampaignIsLoading,
      isError: deleteAppCampaignIsError,
      error: deleteAppCampaignError,
    },
  ] = useDeleteAppCampaignMutation();

  const deleteAppCampaignFun = (data) => {
    deleteAppCampaign({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      id: data.id,
    });
  };
  useEffect(() => {
    if (deleteAppCampaignData) {
      toast.success("Campaign Deleted Successfully !!");
      getAllAppCampaign();
    }
    if (deleteAppCampaignError) {
      toast.error("Error in Deleting Campaign");
    }
  }, [deleteAppCampaignData, deleteAppCampaignError]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  const [
    getAppCampaign,
    {
      data: getAppCampaignData,
      isLoading: getAppCampaignIsLoading,
      isError: getAppCampaignIsError,
      error: getAppCampaignError,
    },
  ] = useGetAllAppCampaignMutation();
  const getAllAppCampaign = () => {
    getAppCampaign({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    getAllAppCampaign();
  }, []);
  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Campaign
          <LinksComponent />
        </div>

        <div className="p-3">
          <div className=" p-3 w-full bg-white shadow rounded-lg">
            <DataTableComponent
              // columns={columns}
              data={
                getAppCampaignData && getAppCampaignData?.body?.appCampaigns
              }
              columns={columns}
              loading={getAppCampaignData}
              totalRows={
                getAppCampaignData && getAppCampaignData.body.totalAppCampaigns
              }
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
            >
              <div className="flex m-2 space-x-2 justify-end"></div>
            </DataTableComponent>

            <div
              className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
              id="box_btn"
            >
              <div className="text-right">
                <Menu className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      onClick={() => navigate(`add`)}
                      className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                      style={{ backgroundColor: `${primaryColor["700"]}` }}
                    >
                      <HiOutlinePlus className="w-full h-full p-3" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
      </div>
    </div>
  );
};

export default Campaign;
