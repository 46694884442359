import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import { HiOutlinePlus } from "react-icons/hi2";
import { Menu } from "@headlessui/react";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import {
  useAddMediaMutation,
  useGetAllMediaMutation,
  useDeleteMediaMutation,
} from "../../../../features/tenant/AppMedia/media.Api";
import SelectComponentWithSearch from "../../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";
import toast from "react-hot-toast";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Avatar from "@mui/material/Avatar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, CssBaseline, Fab } from "@mui/material";
import { useUploadSingleFileMutation } from "../../../../features/admin/imageUpload/imageUploadApi";
import { AiOutlineDelete } from "react-icons/ai";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

const Mediaist = () => {
  const [mediaData, setMediaData] = useState([]);
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [images, setImages] = useState(null);
  const [formImg, setFormImg] = useState(null);
  const [value, setValue] = React.useState(0);
  const [selectedMediaType, setSelectedMediaType] = useState("All");
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [singleData, setSingleData] = useState();
  const truncateText = (text, numWords) => {
    const words = text.split(" ");
    if (words.length > numWords) {
      return words.slice(0, numWords).join(" ") + "...";
    } else {
      return text;
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (selectedMediaType === "All") {
      setFilteredMedia(mediaData);
    } else {
      const filtered = mediaData.filter(
        (media) => media.type === selectedMediaType,
      );
      setFilteredMedia(filtered);
    }
  }, [selectedMediaType, mediaData]);
  const navigate = useNavigate();

  function isToday(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  function isYesterday(dateString) {
    const date = new Date(dateString);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  function groupMediaByDate(mediaList) {
    const groupedMedia = [];
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    mediaList.forEach((media) => {
      const mediaDate = new Date(media.created_at);
      if (isToday(mediaDate)) {
        addToGroup("Today", media);
      } else if (isYesterday(mediaDate)) {
        addToGroup("Yesterday", media);
      } else {
        addToGroup("Past", media);
      }
    });

    return groupedMedia;

    function addToGroup(label, media) {
      const groupIndex = groupedMedia.findIndex(
        (group) => group.label === label,
      );
      if (groupIndex === -1) {
        groupedMedia.push({ label, media: [media] });
      } else {
        groupedMedia[groupIndex].media.push(media);
      }
    }
  }

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const previewImage = (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    uploadImage({
      body: formData,
    });
    // setFormImg(event.target.files[0]);
    // const imageFiles = event.target.files;
    // const imageFilesLength = imageFiles.length;
    // if (imageFilesLength > 0) {
    //   const imageSrc = URL.createObjectURL(imageFiles[0]);
    //   setImages(imageSrc);
    // }
  };

  useEffect(() => {
    toast.dismiss();
    if (uploadImageData) {
      console.log("success image upload", uploadImageData.body);
      setImages(uploadImageData.body.fileLink);
    }
    if (uploadImageError) {
      toast.error("Image upload failed");
    }

    if (uploadImageIsLoading) {
      toast.loading("Uploading your image...", { id: "uploadImageLoading" });
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);

  const [
    getAllMedia,
    {
      data: getAllMediaData,
      isLoading: getAllMediaLoading,
      isError: getAllMediaIsError,
      error: getAllMediaError,
    },
  ] = useGetAllMediaMutation();

  const [
    addMedia,
    {
      data: addMediaData,
      isLoading: addMediaLoading,
      isError: addMediaIsError,
      error: addMediaError,
    },
  ] = useAddMediaMutation();

  const [deleteMedia, { data: deleteMediaData, error: deleteMediaError }] =
    useDeleteMediaMutation();

  const handleDeleteMedia = (data) => {
    console.log("Data", data);
    if (window.confirm("are you sure you want to delete this?")) {
      deleteMedia({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: data.id,
      });
    }
    return;
  };

  useEffect(() => {
    if (deleteMediaData) {
      console.log("deleteMediaData", deleteMediaData);
      setShowModal2(false);
      setSingleData();
      toast.dismiss();
      toast.success(" Deleted Successfully", {
        id: " Deleted Successfully",
      });
    }

    if (deleteMediaError) {
      console.log("deleteMediaError", deleteMediaError);
      toast.dismiss();
      toast.error("Something went wrong", {
        id: "delete-product-error",
      });
    }
  }, [deleteMediaError]);

  const getAll = () => {
    getAllMedia({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };

  const addMediaFun = () => {
    if (!title) {
      toast.error("Please provide a Title", {
        id: "imageError",
      });
      return;
    }

    if (!description) {
      toast.error("Please provide a description", {
        id: "imageError",
      });
      return;
    }

    if (!selectedTypeName) {
      toast.error("Please provide a type", {
        id: "imageError",
      });
      return;
    }

    const formData = {
      images: images,
      title: title,
      description: description,
      type: selectedTypeName.name,
    };

    // const objectData = {};
    // formData.forEach(function (value, key) {
    //   objectData[key] = value;
    // });
    addMedia({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: formData,
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (getAllMediaData && getAllMediaData.body) {
      setMediaData(getAllMediaData.body);
    }
  }, [getAllMediaData]);

  useEffect(() => {
    if (addMediaData) {
      toast.success("Media Added Successfully!");
      setShowModal1(false);
      getAll();
    }
    if (addMediaError) {
      toast.error("Error in Adding Media");
    }
  }, [addMediaData, addMediaError]);

  const groupedMedia = groupMediaByDate(filteredMedia);

  return (
    <div className="flex w-full bg-gray-200 h-full justify-center">
      <div className="w-full">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Media
        </div>

        <div className="p-3">
          <div className="p-3 bg-white shadow rounded-lg">
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="All" onClick={() => setSelectedMediaType("All")} />
              <Tab label="News" onClick={() => setSelectedMediaType("News")} />
              <Tab
                label="Event"
                onClick={() => setSelectedMediaType("Event")}
              />
              <Tab
                label="Conference"
                onClick={() => setSelectedMediaType("Conference")}
              />
            </Tabs>

            {groupedMedia && groupedMedia.length > 0 && (
              <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                {groupedMedia.map((group, groupIndex) => (
                  <React.Fragment key={groupIndex}>
                    <ListSubheader sx={{ bgcolor: "background.paper" }}>
                      {group.label}
                    </ListSubheader>

                    {group.media.map((media, index) => (
                      <div
                        onClick={() => {
                          setShowModal2(true);
                          setSingleData(media);
                        }}
                      >
                        <ListItem button key={media.id}>
                          <ListItemAvatar>
                            <Avatar
                              alt="Image Picture"
                              src={
                                media?.images && media?.images.length > 0
                                  ? media?.images[0]
                                  : ""
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={media.title}
                            secondary={truncateText(media.description, 20)}
                          />
                        </ListItem>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </Box>
            )}
          </div>

          <div
            className="fixed bottom-0 right-10 w-16 h-16 mr-10 mb-6 cursor-pointer"
            id="box_btn"
          >
            <div className="text-right">
              <Menu className="relative inline-block text-left">
                <div>
                  <Menu.Button
                    onClick={() => setShowModal1(true)}
                    className="inline-flex justify-center w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    style={{ backgroundColor: `${primaryColor["700"]}` }}
                  >
                    <HiOutlinePlus className="w-full h-full p-3" />
                  </Menu.Button>
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      {showModal1 && (
        <ModalComponent
          size={"xl"}
          data={"nadnandand"}
          show={showModal1}
          setShow={setShowModal1}
        >
          <div className="w-full p-4">
            <div className="w-full m-auto  justify-center ">
              <div className="flex gap-1">
                <div className="pb-1  bottom-2 w-full mb-2">
                  <SelectComponentWithSearch
                    title={"Type"}
                    selected={selectedTypeName}
                    setSelected={setSelectedTypeName}
                    dataArr={[
                      {
                        id: 1,
                        name: "News",
                      },
                      {
                        id: 2,
                        name: "Event",
                      },
                      {
                        id: 3,
                        name: "Conference",
                      },
                    ]}
                    placeholder="Select Type"
                  />
                </div>
              </div>
              <div className="flex gap-1 ">
                <div className="pb-1  bottom-2 w-full mb-2">
                  <InputComponent
                    name={"Title"}
                    type={"text"}
                    value={title}
                    setValue={setTitle}
                    required={true}
                  />
                </div>
              </div>
              <div className="flex gap-1 ">
                <div className="pb-1  bottom-2 w-full mb-2">
                  <InputComponent
                    name={"Description"}
                    type={"text"}
                    value={description}
                    setValue={setDescription}
                    required={true}
                  />
                </div>
              </div>
              <div className="w-full flex justify-center ">
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hoverbg-gray-100"
                  >
                    <div className="flex flex-col items-center justify-center">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500">
                        PNG or JPG (800x400px) . Max Upto 3 Images
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => previewImage(e)}
                    />
                  </label>
                </div>
              </div>
              {images && (
                <span className="w-10 h-10 d-block m-auto border rounded-md p-3">
                  <img
                    src={images}
                    alt=""
                    srcset=""
                    className="img-fluid flex items-center justify-center w-48 m-auto"
                  />
                </span>
              )}
            </div>
            <div className="flex justify-center mt-3">
              <button
                type="submit"
                className="text-white bg-blue-700 hover-bg-blue-800 focus-ring-4 focus-outline-none focus-ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={(e) => addMediaFun()}
              >
                Add
              </button>
            </div>
          </div>
        </ModalComponent>
      )}

      {showModal2 && (
        <ModalComponent
          size={"xl"}
          data={"nadnandand"}
          show={showModal2}
          setShow={setShowModal2}
        >
          {singleData && (
            <div className="w-full p-4 flex justify-evenly">
              <div className="w-full m-auto  justify-center ">
                <Avatar
                  alt="Image Picture"
                  src={
                    singleData?.images && singleData?.images.length > 0
                      ? singleData?.images[0]
                      : ""
                  }
                />
                <br />
                Title
                {singleData.title}
                <br />
                Type
                {singleData.type}
                Description
                <br />
                {singleData.description}
                <br />
              </div>

              <div>
                <button
                  onClick={() => handleDeleteMedia(singleData)}
                  className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                >
                  <AiOutlineDelete className="mr-1" />
                  Delete
                </button>
              </div>
            </div>
          )}
        </ModalComponent>
      )}
    </div>
  );
};

export default Mediaist;
