import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { GrDocumentPdf } from "react-icons/gr";
import { HiOutlinePlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import {
  useDeleteLegalMutation,
  useFetchLegalByLegalIdMutation,
  useFetchLegalsMutation,
} from "../../../features/tenant/legal/legalApi";
import { getVendorName } from "../../../utils/getVendorName";
import toast from "react-hot-toast";

const LegalList = () => {
  const navigate = useNavigate();

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  let hasViewPermission = true;
  let hasDeletePermission = true;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const addECatalogue = true;

  const [
    fetchAllLegals,
    {
      data: fetchAllLegalsData,
      isLoading: fetchAllLegalsIsLoading,
      isError: fetchAllLegalsIsError,
      error: fetchAllLegalsError,
    },
  ] = useFetchLegalsMutation();

  const [
    deleteLegal,
    {
      data: deleteLegalData,
      isLoading: deleteLegalIsLoading,
      isError: deleteLegalIsError,
      error: deleteLegalError,
    },
  ] = useDeleteLegalMutation();

  useEffect(() => {
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    fetchAllLegals({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  }, [limit, offset]);

  useEffect(() => {
    if (fetchAllLegalsData) {
      toast.dismiss();
      toast.success(`${fetchAllLegalsData.message}`, {
        id: "fetchAllLegalsData",
      });
    }
    if (fetchAllLegalsError) {
      toast.dismiss();
      toast.error(`${fetchAllLegalsError.data.message}`, {
        id: "fetchAllLegalsError",
      });
    }
  }, [fetchAllLegalsData, fetchAllLegalsError]);

  const handleDelete = (row) => {
    deleteLegal({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      legalId: row.id,
    });
  };

  useEffect(() => {
    if (deleteLegalData) {
      toast.dismiss();
      toast.success(deleteLegalData.message, {
        id: "deleteLegalData",
      });

      fetchAllLegals({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        limit: limit,
        offset: offset,
      });
    }
    if (deleteLegalError) {
      toast.dismiss();
      toast.error(deleteLegalError.data.message, {
        id: "deleteLegalError",
      });
    }
  }, [deleteLegalData, deleteLegalError]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Legal Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Legal Type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => row.status,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          style={{ width: "60px" }}
          src={ row.image}
          alt="gift-images"
        />
      ),
      maxWidth: "120px",
    },
    {
      name: "Created By",
      cell: (row) => row.created_by_name,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <a
            href={ row?.files[0]}
            target="__blank"
            // onClick={() => {setShowPdf(!showPdf)
            // setCurrentPdf(row.files[0])
            // }}
            className="flex text-center justify-center items-center rounded bg-primary px-3
             text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <div className="w-40 h-40"> */}
            <GrDocumentPdf className="w-40 h-30" /> &nbsp; &nbsp;{" "}
            <span className="w-full">View</span>
            {/* </div> */}
          </a>
        </div>
      ),
      minWidth: "150px",
    },
    {
      name: "",
      cell: (row) => (
        <div>
          {hasDeletePermission && (
            <button
              onClick={() => handleDelete(row)}
              className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
            >
              <AiOutlineDelete className="mr-1" />
              Delete
            </button>
          )}
        </div>
      ),
      minWidth: "130px",
    },
  ];

  return (
    <>
      {" "}
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Legal
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                data={fetchAllLegalsData && fetchAllLegalsData?.body?.data}
                // loading={loading}
                totalRows={
                  fetchAllLegalsData && fetchAllLegalsData?.body?.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex m-2 space-x-2 justify-end"></div>
              </DataTableComponent>
              {addECatalogue && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalList;
