import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  useFetchUomKeysMutation,
  useAddUomKeysMutation,
  useUpdateUomKeyByIdMutation,
  useDeleteUomKeyByIdMutation,
} from "../../../features/admin/uomKeys/uomKeysApi";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  height: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const UomKeys = () => {
  const navigate = useNavigate();

  const [tableDataItems, setTableDataItems] = useState([]);
  const [modal, setModal] = useState(false);

  const handleModalOpen = () => setModal(true);
  const handleModalClose = () => setModal(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [refetch, setRefetch] = useState(0);

  const [keyName, setKeyName] = useState(null);

  const [
    fetchUomKeys,
    { data: uomKeysData, isLoading: uomKeysisLoading, error: uomKeysError },
  ] = useFetchUomKeysMutation();

  const [
    addUomKey,
    { data: addUomKeyData, isLoading: addUomKeyLoading, error: addUomKeyError },
  ] = useAddUomKeysMutation();

  const [
    updateUomKey,
    {
      data: updateUomKeyData,
      isLoading: updateUomKeyLoading,
      error: updateUomKeyError,
    },
  ] = useUpdateUomKeyByIdMutation();

  const [
    deleteUomKey,
    {
      data: deleteUomKeyData,
      isLoading: deleteUomKeyLoading,
      error: deleteUomKeyError,
    },
  ] = useDeleteUomKeyByIdMutation();

  useMemo(() => {
    if (uomKeysData) {
      (() => {
        setTableDataItems(uomKeysData.body);
      })();
    }
    if (uomKeysError) {
      toast.error("Something went wrong", {
        id: "uomKeysFetchError",
      });
    }
  }, [uomKeysData, uomKeysError]);

  useEffect(() => {
    fetchUomKeys({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData"))?.tenant_id,
    });

  }, [refetch]);

  const handleButtonEditClick = (r) => {
    setUpdateData(r);
    setKeyName(r.name);
    setIsUpdate(true);
    handleModalOpen();
  };

  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleButtonDeleteClick(r);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteUomKeys",
      },
    );
    return;
  };

  const handleButtonDeleteClick = (r) => {
    deleteUomKey({
      token: sessionStorage.getItem("access_token"),
      id: r.id,
    });
  };

  useEffect(() => {
    if (deleteUomKeyData) {
      toast.dismiss();
      setKeyName(null);
      setRefetch((previous) => previous + 1);
      toast.success("Key Deleted", { id: "keyDeleteSuccess" });
    }

    if (deleteUomKeyError) {
      toast.error("Something went wrong", { id: "keyDeleteError" });
    }
  }, [deleteUomKeyData, deleteUomKeyError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          // onClick={() => handleButtonEditClick(r)}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => handleButtonEditClick(r)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleAddUomKey = (e) => {
    e.preventDefault();

    if (!keyName || keyName.trim().length === 0) {
      toast.error("Please enter valid key name", { id: "keyNameNotValid" });
      return;
    }

    addUomKey({
      token: sessionStorage.getItem("access_token"),
      body: {
        name: keyName,
      },
    });
  };

  useEffect(() => {
    if (addUomKeyData) {
      setRefetch((previous) => previous + 1);
      toast.success("Key added", {
        id: "uomKeyAddSuccess",
      });

      handleModalClose();
    }

    if (addUomKeyError) {
      toast.error("Something went wrong", {
        id: "uomKeyAddError",
      });

      //   handleModalClose();
    }
  }, [addUomKeyData, addUomKeyError]);

  const handleUpdateUomKey = (e) => {
    e.preventDefault();

    if (!keyName || keyName.trim().length === 0) {
      toast.error("Please enter valid key name", { id: "keyNameNotValid" });
      return;
    }

    updateUomKey({
      token: sessionStorage.getItem("access_token"),
      id: updateData.id,
      body: {
        name: keyName,
      },
    });
  };

  useEffect(() => {
    if (updateUomKeyData) {
      setKeyName(null);
      setRefetch((previous) => previous + 1);
      toast.success("Key Updated", {
        id: "uomKeyUpdateSuccess",
      });
      setIsUpdate(false);
      setUpdateData(null);
      handleModalClose();
    }

    if (updateUomKeyError) {
      toast.error("Something went wrong", {
        id: "uomKeyUpdateError",
      });
      //   handleModalClose();
    }
  }, [updateUomKeyData, addUomKeyError]);

  return (
    <>
      <div className="page-content">
        <div>
          {modal && (
            <Modal keepMounted open={modal} onClose={handleModalClose}>
              <Box sx={style}>
                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                  <div className="frmds w-100">
                    <div className="col">
                      <label for="name">Key Name </label>
                      <input
                        id="name"
                        className="form-control"
                        value={keyName}
                        onChange={(e) => setKeyName(e.target.value)}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="pl-4 d-flex gap-2 my-3 mr-auto justify-content-around w-50 ">
                    {isUpdate ? (
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-icon-text"
                        onClick={(e) => handleUpdateUomKey(e)}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-icon-text"
                        onClick={(e) => handleAddUomKey(e)}
                      >
                        Add
                      </button>
                    )}
                    <button
                      type="cancel"
                      className="btn btn-outline-danger  btn-icon-text"
                      onClick={() => {
                        setKeyName(null);
                        handleModalClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          )}
        </div>
        <div className="card p-4">
          <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 className="mb-3 mb-md-0 card-title">
                Welcome to Uom Keys Master
              </h4>
            </div>
            <div className="d-flex align-items-center flex-wrap text-nowrap">
              <button
                type="button"
                className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
                onClick={() => handleModalOpen()}
              >
                Add New Uom Key
              </button>
            </div>
          </div>

          <div className="row">
            <div className="table-responsive">
              <DataTable
                data={tableDataItems}
                columns={columns}
                //selectableRows
                //customStyles={customStyles}
                striped
                highlightOnHover
                pointerOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UomKeys;
