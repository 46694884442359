import React, { useEffect, useState } from "react";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useFetchAllTheCashTransferRequestsMutation,
  useHandleAcceptRequestMutation,
  useHandleRejectRequestMutation,
} from "../../../../features/tenant/cashTransactions/cashTransactionsApi";
import { getVendorName } from "../../../../utils/getVendorName";
import toast from "react-hot-toast";
import { TiTick, TiTimes } from "react-icons/ti";
import { hasPermission } from "../../../../utils/hasPermission";

const CouponRequests = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [tableDataItems, setTableDataItems] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [
    fetchAllTheCashTransferRequests,
    {
      data: fetchAllTheCashTransferRequestsData,
      error: fetchAllTheCashTransferRequestsError,
    },
  ] = useFetchAllTheCashTransferRequestsMutation();

  const [
    handleAcceptRequest,
    { data: handleAcceptRequestData, error: handleAcceptRequestError },
  ] = useHandleAcceptRequestMutation();

  const [
    handleRejectRequest,
    { data: handleRejectRequestData, error: handleRejectRequestError },
  ] = useHandleRejectRequestMutation();

  const hasViewPermission = hasPermission("viewTransactionRequestList");
  const hasAcceptPermission = hasPermission("acceptTransactionRequestList");
  const hasRejectPermission = hasPermission("rejectTransactionRequestList");

  const handleCashTransferList = (limit, offset) => {
    fetchAllTheCashTransferRequests({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }

    handleCashTransferList(limit, offset);
  }, [limit, offset]);

  useEffect(() => {
    if (
      fetchAllTheCashTransferRequestsData &&
      fetchAllTheCashTransferRequestsData.body
    ) {
      toast.dismiss();
      toast.success(`${fetchAllTheCashTransferRequestsData.message}`, {
        id: "fetchAllTheCashTransferRequestsData",
      });
      console.log(
        "transactionsData  data",
        fetchAllTheCashTransferRequestsData
      );
      const arr = [...fetchAllTheCashTransferRequestsData.body.data];
      arr.sort((a, b) => a.id - b.id);
      setTableDataItems(arr);
    }

    if (fetchAllTheCashTransferRequestsError) {
      console.log(
        "transactionsData error",
        fetchAllTheCashTransferRequestsError
      );
      toast.dismiss();
      toast.error(`${fetchAllTheCashTransferRequestsError.data.message}`, {
        id: "fetchAllTheCashTransferRequestsError",
      });
    }
  }, [
    fetchAllTheCashTransferRequestsData,
    fetchAllTheCashTransferRequestsError,
  ]);

  const handleApprove = (e, row) => {
    console.log("object");
    const remark = window.prompt("please add remarks");
    if (remark && remark.length == 0) {
      toast.error("Please add a remark");
      return;
    }
    console.log("remark", remark);
    handleAcceptRequest({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        remark: remark,
      },
      account_id: row.account_id.toString(),
      cash_transfer_to_bank_id: row.id.toString(),
    });
  };

  useEffect(() => {
    if (handleAcceptRequestData) {
      toast.dismiss();
      toast.success(`${handleAcceptRequestData.message}`, {
        id: "handleAcceptRequestData",
      });
      handleCashTransferList(limit, offset);
    }

    if (handleAcceptRequestError) {
      toast.dismiss();
      toast.error(`${handleAcceptRequestError.data.message}`, {
        id: "handleAcceptRequestError",
      });
    }
  }, [handleAcceptRequestData, handleAcceptRequestError]);

  const handleReject = (e, row) => {
    const remark = window.prompt("please add remarks");
    if (remark && remark.length == 0) {
      toast.error("Please add a remark");
      return;
    }

    handleRejectRequest({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        remark: remark,
      },
      account_id: row.account_id.toString(),
      cash_transfer_to_bank_id: row.id.toString(),
    });
  };

  useEffect(() => {
    if (handleRejectRequestData) {
      toast.dismiss();
      toast.success(`${handleRejectRequestData.message}`, {
        id: "handleRejectRequestData",
      });
      handleCashTransferList(limit, offset);
    }

    if (handleRejectRequestError) {
      toast.dismiss();
      toast.error(`${handleRejectRequestError.data.message}`, {
        id: "handleAcceptRequestError",
      });
    }
  }, [handleRejectRequestData, handleRejectRequestError]);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Mobile  ",
      selector: (row) => row.mobile,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
    },
    {
      name: "Last Balance ",
      selector: (row) => row.balance,
    },
    {
      name: "Redemption Amount",
      selector: (row) => row.cash,
    },
    {
      name: "Remarks  ",
      selector: (row) => row.remarks,
    },
    {
      name: "Transfer ID  ",
      selector: (row) => row.transfer_id,
    },
    {
      name: "State ",
      selector: (row) => row.state,
    },
    {
      name: "District ",
      selector: (row) => row.district,
    },
    {
      name: "city",
      selector: (row) => row.city,
    },
    {
      name: "Request Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Request Updated At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setModalData(r);
            setShowModal(true);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "",
      cell: (row) => (
        <div>
          {row.approval_status == "3" && (
            <div className="flex space-x-1 ">
              {hasAcceptPermission && (
                <button
                  onClick={(e) => handleApprove(e, row)}
                  className="flex justify-center items-center rounded bg-success  px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]"
                >
                  <TiTick className="mr-1" />
                  Approve
                </button>
              )}
              {hasRejectPermission && (
                <button
                  onClick={(e) => handleReject(e, row)}
                  className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                >
                  <TiTimes className="mr-1" />
                  Reject
                </button>
              )}
            </div>
          )}
          {row.approval_status == "2" && (
            <div className=" text-red-950 ">Rejected</div>
          )}
          {row.approval_status == "1" && (
            <div className=" text-green-600 ">Approved</div>
          )}
        </div>
      ),
      width: "250px",
    },
  ];
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Coupon Requests List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                data={tableDataItems}
                totalRows={
                  fetchAllTheCashTransferRequestsData &&
                  fetchAllTheCashTransferRequestsData?.body?.count
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="m-4  bg-white rounded-md  shadow-lg">
                <div className="p-2.5 mt-3 shadow-md mb-4">
                  <h2 className="text-2xl font-semibold mb-4">User Details</h2>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <p className="text-gray-600 mb-2">
                        User ID:{" "}
                        <span className="font-semibold">
                          {modalData.user_id}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Name:{" "}
                        <span className="font-semibold">{modalData.name}</span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Email:{" "}
                        <span className="font-semibold">{modalData.email}</span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Mobile:{" "}
                        <span className="font-semibold">
                          {modalData.mobile}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Gender:{" "}
                        <span className="font-semibold">
                          {modalData.gender}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Date of Birth:{" "}
                        <span className="font-semibold">
                          {modalData.dob || "N/A"}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-600 mb-2">
                        Address:{" "}
                        <span className="font-semibold">{`${modalData.address1}, ${modalData.city}, ${modalData.state} ${modalData.pincode}`}</span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        PAN:{" "}
                        <span className="font-semibold">{modalData.pan}</span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Aadhar:{" "}
                        <span className="font-semibold">
                          {modalData.aadhar}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        GSTIN:{" "}
                        <span className="font-semibold">
                          {modalData.gstin || "N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-2.5 mt-3 shadow-md">
                  <h2 className="text-2xl font-semibold mb-4">Bank Details</h2>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <p className="text-gray-600 mb-2">
                        Beneficiary ID:{" "}
                        <span className="font-semibold">
                          {modalData.bene_id}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Name:{" "}
                        <span className="font-semibold">
                          {modalData.bene_name}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Mobile:{" "}
                        <span className="font-semibold">
                          {modalData.bene_mobile}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Branch:{" "}
                        <span className="font-semibold">
                          {modalData.bene_branch}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2">
                        Transfer Mode:{" "}
                        <span className="font-semibold">
                          {modalData.transfer_mode}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-600 mb-2">
                        Beneficiary Details:{" "}
                        <span className="font-semibold">
                          {/* {modalData.bene_details} */}
                          {modalData.transfer_mode == "upi" &&
                            modalData.bene_details.upi_id}
                          {modalData.transfer_mode == "banktransfer" &&
                            ("Bank Account :",
                            modalData.bene_details.bank_account)}
                          {modalData.transfer_mode == "banktransfer" &&
                            ("IFSC :", modalData.bene_details.ifsc)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default CouponRequests;
