import React from "react";
import { Bar } from "react-chartjs-2";
import { months } from "../../../screens/Tenant/PaymentTransfer/PaymentTransferList";

const BarcChartStacked = ({ data }) => {

    
  const chartData = {
    labels: data.map((item) => `${months[item.month]}/${item.year}`),
    datasets: [
      //   {
      //     label: 'Total Count',
      //     backgroundColor: 'rgba(75,192,192,0.2)',
      //     borderColor: 'rgba(75,192,192,1)',
      //     borderWidth: 1,
      //     hoverBackgroundColor: 'rgba(75,192,192,0.4)',
      //     hoverBorderColor: 'rgba(75,192,192,1)',
      //     data: data.map(item => parseInt(item.total_count, 10)),
      //   },
      {
        label: "Failure",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: data.map((item) => parseInt(item.failure_cnt, 10)),
      },
      {
        label: "Success",
        backgroundColor: "rgba(0,255,0,0.2)",
        borderColor: "rgba(0,255,0,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(0,255,0,0.4)",
        hoverBorderColor: "rgba(0,255,0,1)",
        data: data.map((item) => parseInt(item.success_cnt, 10)),
      },
      {
        label: "Pending",
        backgroundColor: "rgba(255,255,0,0.2)",
        borderColor: "rgba(255,255,0,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,255,0,0.4)",
        hoverBorderColor: "rgba(255,255,0,1)",
        data: data.map((item) => parseInt(item.pending_cnt, 10)),
      },
      {
        label: "Not Initiated",
        backgroundColor: "rgba(128,128,128,0.2)",
        borderColor: "rgba(128,128,128,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(128,128,128,0.4)",
        hoverBorderColor: "rgba(128,128,128,1)",
        data: data.map((item) => parseInt(item.not_initiated_cnt, 10)),
      },
    ],
  };

  return (
    <div>
      <Bar
        data={chartData}
        options={{
          //   plugins: {
          //     title: {
          //       display: true,
          //       text: "Payment Transfer Stats",
          //     },
          //   },
          responsive: true,
          scales: {
            x: { stacked: true },
            y: {
              stacked: true,
              min: 0,
              type: "logarithmic", // Set y-axis scale to logarithmic
              ticks: {
                callback: function (value, index, values) {
                  return Number(value.toString());
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default BarcChartStacked;
