import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utils/hasPermission";
import { HiOutlinePlus } from "react-icons/hi";
import { Menu } from "@headlessui/react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import toast from "react-hot-toast";
import {
    useDeletewhatsappNotificationCredentialForTenantMutation,
  useGetWhatsappNotificationCredentialWithLimitOffsetForTenantMutation,
  useUpdatewhatsappNotificationCredentialStatusForTenantMutation,
} from "../../../features/tenant/whatappNotificationCredential/whatsappNotificaitonCredentialApi";
import { getVendorName } from "../../../utils/getVendorName";

const WhatsappNotificationCredentials = () => {
  const navigate = useNavigate();
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;
  const addWhatsappNotificationCredentialPermission =
    hasPermission("addWhatsappNotificationCredentialSetup");
  const editWhatsappNotificationCredentialPermission =
    hasPermission("editWhatsappNotificationCredentialSetup");
  const deleteWhatsappNotificationCredentialPermission =
    hasPermission("deleteWhatsappNotificationCredentialSetup");
  const updateWhatsappNotificationCredentialStatusPermission =
    hasPermission("updateWhatsappNotificationCredentialSetup");
  const viewWhatsappNotificationTemplatesPermission =
    hasPermission("viewWhatsappNotificationCredentialSetup");

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const [
    getWhatsappNotificationCredentialWithLimitOffsetForTenant,
    {
      data: getWhatsappNotificationCredentialWithLimitOffsetForTenantData,
      isLoading:
        getWhatsappNotificationCredentialWithLimitOffsetForTenantIsLoading,
      isError: getWhatsappNotificationCredentialWithLimitOffsetForTenantIsError,
      error: getWhatsappNotificationCredentialWithLimitOffsetForTenantError,
    },
  ] = useGetWhatsappNotificationCredentialWithLimitOffsetForTenantMutation();

  const [
    updatewhatsappNotificationCredentialStatusForTenant,
    {
      data: updatewhatsappNotificationCredentialStatusForTenantData,
      isLoading: updatewhatsappNotificationCredentialStatusForTenantIsLoading,
      isError: updatewhatsappNotificationCredentialStatusForTenantIsError,
      error: updatewhatsappNotificationCredentialStatusForTenantError,
    },
  ] = useUpdatewhatsappNotificationCredentialStatusForTenantMutation();

  const [
    deletewhatsappNotificationCredentialForTenant,
    {
      data: deletewhatsappNotificationCredentialForTenantData,
      isLoading: deletewhatsappNotificationCredentialForTenantIsLoading,
      isError: deletewhatsappNotificationCredentialForTenantIsError,
      error: deletewhatsappNotificationCredentialForTenantError,
    },
  ] = useDeletewhatsappNotificationCredentialForTenantMutation();


  const fetchWhatsappNotificationCredential = (limit, offset) => {
    getWhatsappNotificationCredentialWithLimitOffsetForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      limit: limit,
      offset: offset,
    });
  };

  useEffect(() => {
    if (!viewWhatsappNotificationTemplatesPermission) {
      navigate(`/genefied/${getVendorName()}`);
    }
    fetchWhatsappNotificationCredential(limit, offset);
  }, [limit, offset]);

  useEffect(() => {
    if (getWhatsappNotificationCredentialWithLimitOffsetForTenantData) {
      toast.dismiss();
      console.log("getWhatsappNotificationCredentialWithLimitOffsetForTenantData : ",getWhatsappNotificationCredentialWithLimitOffsetForTenantData);
      toast.success(
        getWhatsappNotificationCredentialWithLimitOffsetForTenantData.message,
        {
          id: "getWhatsappNotificationCredentialWithLimitOffsetForTenantData",
        }
      );
    }
    if (getWhatsappNotificationCredentialWithLimitOffsetForTenantError) {
      toast.dismiss();
      toast.error(
        getWhatsappNotificationCredentialWithLimitOffsetForTenantError.data
          .message,
        {
          id: "getWhatsappNotificationCredentialWithLimitOffsetForTenantError",
        }
      );
    }
  }, [
    getWhatsappNotificationCredentialWithLimitOffsetForTenantData,
    getWhatsappNotificationCredentialWithLimitOffsetForTenantError,
  ]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleEditWhatsappNotificationCredential = (row) => {
    console.log("row", row);
    if (editWhatsappNotificationCredentialPermission) {
      navigate("add", {
        state: {
          data: row,
          edit: true,
        },
      });
    } else {
      toast.error("You don't have permission to Delete The status", {
        id: "You don't have permission to Delete The status",
      });
    }
  };

  const handleStatusChange = (row, status) => {
    if (updateWhatsappNotificationCredentialStatusPermission) {
      updatewhatsappNotificationCredentialStatusForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: Number(row.id),
      });
    } else {
      toast.error("You don't have permission to change status", {
        id: "You can not update the status",
      });
    }
  };

  useEffect(() => {
    if (updatewhatsappNotificationCredentialStatusForTenantData) {
      toast.dismiss();
      toast.success(
        updatewhatsappNotificationCredentialStatusForTenantData.message,
        {
          id: "updatewhatsappNotificationCredentialStatusForTenantData",
        }
      );

      fetchWhatsappNotificationCredential(limit, offset);
      
    }
    if (updatewhatsappNotificationCredentialStatusForTenantError) {
      toast.dismiss();
      toast.error(
        updatewhatsappNotificationCredentialStatusForTenantError.data
          .message,
        {
          id: "updatewhatsappNotificationCredentialStatusForTenantError",
        }
      );
    }
  }, [
    updatewhatsappNotificationCredentialStatusForTenantData,
    updatewhatsappNotificationCredentialStatusForTenantError,
  ]);


  const handleDeleteWhatsappNotificationCredential = (row) => {
    if (deleteWhatsappNotificationCredentialPermission) {
      deletewhatsappNotificationCredentialForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: row.id,
      });
    } else {
      toast.error("You don't have permission to Delete The status", {
        id: "You don't have permission to Delete The status",
      });
    }
  };

  useEffect(() => {
    if (deletewhatsappNotificationCredentialForTenantData) {
      toast.dismiss();
      toast.success(
        deletewhatsappNotificationCredentialForTenantData.message,
        {
          id: "deletewhatsappNotificationCredentialForTenantData",
        }
      );

      fetchWhatsappNotificationCredential(limit, offset);
      
    }
    if (deletewhatsappNotificationCredentialForTenantError) {
      toast.dismiss();
      toast.error(
        deletewhatsappNotificationCredentialForTenantError.data
          .message,
        {
          id: "deletewhatsappNotificationCredentialForTenantError",
        }
      );
    }
  }, [
    deletewhatsappNotificationCredentialForTenantData,
    deletewhatsappNotificationCredentialForTenantError,
  ]);



  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Credentials",
      width: "300px",
      cell: (row) => {
        return (
          <div className="flex flex-col ">
            {Object.keys(row.api_credential).length > 0 &&
              Object.keys(row.api_credential).map((x) => {
                return (
                  <>
                    <div className="flex flex-col ">
                      <div>
                        <label>{x}</label>
                      </div>
                      <input type="text" value={row.api_credential[x]} />
                    </div>
                  </>
                );
              })}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => row.type,
      sortable: true,
    },
    {
      name: "",
      cell: (row) =>
        row.status == "1" ? (
          <>
            {" "}
            <button
              onClick={() => {
                handleStatusChange(row, "2");
              }}
              className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Active
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              handleStatusChange(row, "1");
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            InActive
          </button>
        ),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        <div className="">
          <button
            onClick={() => handleEditWhatsappNotificationCredential(row)}
            className="flex justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            edit
          </button>
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <div className="">
          <button
            onClick={() => {
              if (viewWhatsappNotificationTemplatesPermission) {
                navigate("templates", {
                  state: row,
                });
              } else {
                toast.error("You don't have permission to view Template", {
                  id: "You don't have permission to view Template",
                });
              }
            }}
            className="flex justify-center items-center rounded bg-primary px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            <AiOutlineEdit className="mr-1" />
            Templates
          </button>
        </div>
      ),
    },
    {
      name: "",
      cell: (row) =>
        deleteWhatsappNotificationCredentialPermission && (
          <button
              onClick={() => handleDeleteWhatsappNotificationCredential(row)}
            className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
          >
            <AiOutlineDelete className="mr-1" />
            Delete
          </button>
        ),
      minWidth: "130px",
    },
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Whatsapp Notification Credentials
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                //title={"Whatsapp Notification Credential List"}
                data={
                  getWhatsappNotificationCredentialWithLimitOffsetForTenantData &&
                  getWhatsappNotificationCredentialWithLimitOffsetForTenantData
                    .body.data
                }
                // loading={productsIsLoading}
                totalRows={
                  getWhatsappNotificationCredentialWithLimitOffsetForTenantData &&
                  getWhatsappNotificationCredentialWithLimitOffsetForTenantData
                    .body.total
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              {addWhatsappNotificationCredentialPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {showModal && (
          <ProductModal
            data={productData}
            show={showModal}
            setShow={setShowModal}
          />
        )} */}
      </div>
    </>
  );
};

export default WhatsappNotificationCredentials;
