import React, { useEffect, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../../utils/hasPermission";
import { useGetAppUserTypeQuery } from "../../../../features/tenant/appUserType/appUserTypeApi";
import ExportCSV from "../../../../components/ExportCsv/ExportCsv";
import { useFetchProductsMutation } from "../../../../features/tenant/products/productsApi";
import CustomExport from "../../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../../components/Loader/CircleLoaderComponent";
import { downloadSampleCSV } from "../../../../utils/downloadSampleCsv";
import ModalComponent from "../../../../components/Tenant/ModalComponent/ModalComponent";
import { useUpdateBatchProductsWithPointsMutation } from "../../../../features/tenant/rewardify/productPoints/productPoints";

const ProductPoints = () => {
  const navigate = useNavigate();

  const updateProductPointsPermission = hasPermission("updateProductPoints");

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const [
    updateBatchOfProductsWithPoints,
    {
      data: updateBatchOfProductsWithPointsData,
      isLoading: updateBatchOfProductsWithPointsIsLoading,
      isError: updateBatchOfProductsWithPointsIsError,
      error: updateBatchOfProductsWithPointsError,
    },
  ] = useUpdateBatchProductsWithPointsMutation() || {};


  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [showModal2, setShowModal2] = useState(false);
  const initialRequest = {
    product_code: "",
    name: "",
    brand_name: "",
    category_name: "",
    limit: 10,
    offset: 0,
  };
  const [file, setFile] = useState();
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  function parseCSV(string) {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .replace(/\s/g, "")
      .split(",");

    const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);

    // console.log("csvRows: ", csvRows.length, csvRows);

    const array = csvRows
      .map((row) => {
        const values = [];
        let insideQuotes = false;
        let currentField = "";

        for (let char of row) {
          if (char === "," && !insideQuotes) {
            values.push(currentField.trim());
            currentField = "";
          } else if (char === '"') {
            insideQuotes = !insideQuotes;
          } else {
            currentField += char;
          }
        }

        values.push(currentField.trim());
        return values;
      })
      .filter((values) => values.length === csvHeader.length)
      .map((values) => {
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index].trim();
          return object;
        }, {});
        return obj;
      });

    // console.log("array", array);
    return array;
  }
  const handleOnSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!file) {
      toast.error("Please select a file");
      return;
    }
      const fileReader = new FileReader();
      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          let datas = parseCSV(text);

          updateBatchOfProductsWithPoints({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            body: datas,
          });
       console.log(datas,"DATAASS");


        };
        fileReader.readAsText(file);
      
    } 
  };
  useEffect(() => {
    if (
      request.product_code === "" &&
      request.name === "" &&
      request.brand_name === "" &&
      request.category_name === ""
    ) {
      fetchProductWithPointsFunction();
      // fetchProductWithPointsCsvFunction();
    }
  }, [request]);

  const {
    data: getAppUserTypeData,
    isLoading: getAppUserTypeIsLoading,
    isError: getAppUserTypeIsError,
    error: getAppUserTypeError,
  } = useGetAppUserTypeQuery({
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  const [
    fetchProductWithPoints,
    {
      data: productPointsData,
      isLoading: productPointsIsLoading,
      isError: productPointsIsError,
      error: productPointsError,
    },
  ] = useFetchProductsMutation();

  const [
    fetchProductWithPointsCsv,
    {
      data: productPointsCsvData,
      isLoading: productPointsCsvIsLoading,
      isError: productPointsCsvIsError,
      error: productPointsCsvError,
    },
  ] = useFetchProductsMutation();


  const fetchProductWithPointsFunction = () => {
    fetchProductWithPoints({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchProductWithPointsCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchProductWithPointsCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (productPointsCsvData && productPointsCsvData?.body?.products) {
      setCsvData(productPointsCsvData.body.products);
    }

    if (productPointsCsvError) {
      setCsvData(null);
    }
  }, [productPointsCsvData, productPointsCsvError])

  

  useEffect(() => {
    if (productPointsData && productPointsData.body) {
      // console.log("producDat");
      toast.dismiss();
      setMyList(productPointsData && productPointsData.body.products ? productPointsData.body.products : [])
      setTotalRows(productPointsData && productPointsData.body.total_products ? productPointsData.body.total_products : [])
    }
  }, [productPointsData])
  

  const startColumn = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "90px",
      textAlign: "left",
    },
    {
      name: "Product Name",
      cell: (row) => row.name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Product Code",
      cell: (row) => row.product_code,
      sortable: true,
      width: "150px",
    },
  ];

  const midColumns = getAppUserTypeData
    ? getAppUserTypeData.body.map((user) => {
        return {
          name: user.name.toUpperCase(),
          cell: (row) => row[`${user.name}_points`.toLocaleLowerCase()],
          sortable: true,
          width: "150px",
        };
      })
    : [];

  const endColumns = [
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      width: "150px",
    },
  ];

  const userTypes = getAppUserTypeData
    ? getAppUserTypeData.body.map((user) => {
        return user.name + "_points";
      })
    : [];

  const columns = [...startColumn, ...midColumns, ...endColumns];

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Product With Points List
            <LinksComponent />
          </div>

<div className="p-3">


          {/* {productPointsCsvData && productPointsCsvData?.body?.products && (
            <ExportCSV
              data={
                productPointsCsvData && productPointsCsvData?.body?.products
              }
              columnsToExport={[
                "name",
                "product_code",
                ...userTypes,
                "row.created_at",
              ]}
            />
          )} */}
<div className="flex">
<div className="mb-2">
              <CustomExport fetchData={fetchProductWithPointsCsvFunction} data={csvData} defaultColumnExport={[
                 "name",
                 "product_code",
                 ...userTypes,
                 "row.created_at",
              ]}
                filename="product_with_points"
              />
            </div>

            <button
                className="btn btn-outline-primary clear-both mb-2 ml-3"
                onClick={() => {
                  setShowModal2(true);
                }}
              >
                Add Product Csv
              </button>
              </div>
          <div className=" w-full bg-white shadow rounded-lg">
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
            <div className="flex space-x-2 justify-end">
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Product Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code ..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all"
                        onClick={() => {
                          fetchProductWithPointsFunction();
                          // fetchProductWithPointsCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right  pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
            </div>
            <div className="">
              {productPointsIsLoading && <CircleLoaderComponent/>}
              <DataTableComponent
                columns={columns}
                // title={"Product With Points List"}
                data={myList}
                // loading={productPointsIsLoading}
                totalRows={
                  totalRows
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
             
              </DataTableComponent>
              {updateProductPointsPermission && (
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`add`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
          </div>
        </div>
      </div>
      {showModal2 && (
          <ModalComponent show={showModal2} setShow={setShowModal2} size="md">
            <div className="w-full p-4">
              {
                <div className="flex justify-center mt-3">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() =>
                      downloadSampleCSV([
                        {
                          name: "product_code",
                        },
                        {
                          name: "usertype_points",
                        },
                       
                      ])
                    }
                  >
                    Sample Csv Download
                  </button>
                </div>
              }
              <div className="w-full m-auto  justify-center ">
               
                <div className="flex items-center justify-center w-full">
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <svg
                        aria-hidden="true"
                        className="w-20 h-20 mb-3 text-gray-400 mt-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-center">
                        <span className="font-semibold">Choose CSV file</span>
                        <div>Click to upload or drag and drop</div>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleOnChange}
                    />
                  </label>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => handleOnSubmit()}
                >
                  Add
                </button>
              </div>
            </div>
          </ModalComponent>
        )}
    </>
  );
};

export default ProductPoints;
