import { rootApi } from "../../api/rootApi";

export const smsLoggingApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSMSLogging: builder.mutation({
      query: (params) => {
        // console.log("params11111--->", params.success);
        // console.log("startData--->", params.startDate);
        // console.log("endDate--->", params.endDate);
        return {
          method: "POST",
          url: `/tenant/smsLogging`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body
        };
      },
    }),
    getSMSLoggingTotalCount: builder.mutation({
      query: (params) => {
        // console.log("params11111--->", params.success);
        // console.log("startData--->", params.startDate);
        // console.log("endDate--->", params.endDate);
        return {
          method: "POST",
          url: `/tenant/smsLogging/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body
        };
      },
    }),
    smsBalanceCheck: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/sms-balance/check`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body
        };
      },
    }),
  }),
});
export const { useGetSMSLoggingMutation ,useGetSMSLoggingTotalCountMutation,useSmsBalanceCheckMutation } = smsLoggingApi;
