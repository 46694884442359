// export function mergeAndRemove(a, b, fieldToMatch) {
//   console.log(a,b,fieldToMatch);
//   let count = 0;

//   const mapA = new Map();
//   a.forEach((objA) => {
//     const key = objA[fieldToMatch];
//     mapA.set(key, objA);
//   });
//   const resultB = [];
//   for (let i = 0; i < b.length; i++) {
//     const objB = b[i];
//     const fieldValue = objB[fieldToMatch];

//     if (mapA.has(fieldValue)) {
//       const objA = mapA.get(fieldValue);
//       Object.assign(objA, objB);
//       count++;
//     } else {
//       resultB.push(objB);
//     }
//   }

//   return { count, data:resultB };
// }

export function mergeAndRemove(a, b, fieldToMatch) {
  console.log(a, b, fieldToMatch);
  let count = 0;

  const mapA = new Map();
  a.forEach((objA) => {
    const key = objA[fieldToMatch];
    mapA.set(key, objA);
  });
  const resultB = [];
  const seenProductCodes = new Set(); // To keep track of product codes seen in a
  for (let i = 0; i < b.length; i++) {
    const objB = b[i];
    const fieldValue = objB[fieldToMatch];

    if (mapA.has(fieldValue)) {
      const objA = mapA.get(fieldValue);
      // Create a new object instead of modifying objA
      const mergedObject = { ...objA, ...objB };
      mapA.set(fieldValue, mergedObject); // Update the map with the merged object
      count++;
    } else {
      // Check if the product code exists in a and not seen before in b
      if (!seenProductCodes.has(fieldValue)) {
        resultB.push(objB);
        seenProductCodes.add(fieldValue); // Add the product code to seen set
      }
      else{
        count++;
      }
    }
  }

  return { count, data: resultB };
}


