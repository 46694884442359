import { rootApi } from "../../api/rootApi";

export const warrantyApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllWarrantyClaims: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/warrantyclaim/?&limit=${params.limit}&offset=${
            params.offset
          }}${params.mobile ? `&mobile=${params.mobile}` : ""}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchWarrantyClaimByWarrantyId: builder.mutation({
      query: (params) => {
        console.log("claim by warranty id ", params);
        return {
          method: "GET",
          url: `/tenant/warrantyclaim/${params.warranty_id}/?&limit=${params.limit}&offset=${
            params.offset
          }}${params.mobile ? `&mobile=${params.mobile}` : ""}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const { useFetchAllWarrantyClaimsMutation  , useFetchWarrantyClaimByWarrantyIdMutation} = warrantyApi;
