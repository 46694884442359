import { rootApi } from "../../api/rootApi";

export const appUserMenuApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllMenuItems: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: "/admin/appMenu",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
          };
        },
      }),

    fetchAll: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/admin/appUserMenu",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
    fetchAllTenant: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/admin/appUserMenu/${params.tenant_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
    addAppUserMenu: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/admin/appUserMenu/add",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: params.data,
        };
      },
    }),
    updateAppUserMenu: builder.mutation({
        query: (params) => {
          return {
            method: "PUT",
            url: `/admin/appUserMenu/${params.tenant_id}`,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + params.token,
            },
            body: params.data,
          };
        },
      }),
      deleteAppUserMenu: builder.mutation({
        query: (params) => {
          return {
            method: "DELETE",
            url: `/admin/appUserMenu/${params.id}`,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + params.token,
            },
          };
        },
      }),
  }),
});
export const { useFetchAllMenuItemsMutation,  useFetchAllMutation , useAddAppUserMenuMutation , useDeleteAppUserMenuMutation , useFetchAllTenantMutation , useUpdateAppUserMenuMutation } = appUserMenuApi;
