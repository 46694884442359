import React from 'react';

function OrdersTable({ orders }) {
  // List of keys we want to display in our table
  const displayKeys = ["orderId", "lucky_draw" , "assured_gift", "invoice_id", "retailer_code", "week","slab_range", "effective_value", "total_value", ];

  console.log("orders: " ,orders);

  return (
    <div className="overflow-x-auto shadow-lg rounded-lg">
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            {displayKeys.map((key, index) => (
              <th
                key={index}
                className="px-6 py-3 border-b-2 border-gray-200 bg-indigo-500 text-left text-xs font-semibold text-white uppercase tracking-wider"
              >
                {key.replace(/_/g, ' ')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orders.slice(0,50).map((order, rowIndex) => (
            <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? 'bg-indigo-50' : 'bg-white'} hover:bg-indigo-100`}>
              {displayKeys.map((key, idx) => (
                <td
                  key={idx}
                  className="px-6 py-3 border-b border-gray-200 text-sm"
                >
                    {key === "total_value" || key === "effective_value" ? order[key].toFixed(2) : order[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <h2 className="mt-2 mb-2 ml-auto"> {orders.length - 50} rows more</h2>
      </table>
    </div>
  );
}

export default OrdersTable;
