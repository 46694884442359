import { rootApi } from "../../api/rootApi";


export const adminAuthApi = rootApi.injectEndpoints({

    endpoints: (builder) => ({
        fetchAllAppUserType: builder.query({
            query: (params) => {
                return {
                method: 'GET',
                url: '/admin/appUserType',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                },
            }}
        }),
        addAppUsersType : builder.mutation({
            query: (params) => {
                return {
                method: 'POST',
                url: '/admin/appUserType/add',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + params.token,
                    body : params.body
                },
            }}
        }),
    })

});
export const { useFetchAllAppUserTypeQuery } = adminAuthApi;