import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { LoaderIcon } from "react-hot-toast";
import LoaderComponent from "../../Loader/LoaderComponent";

const months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const getCurrentYear = () => {
  return new Date().getFullYear().toString();
};

const getCurrentMonth = () => {
  return (new Date().getMonth() + 1).toString(); // Month is zero-indexed
};

const YearMonthWeekBarChart = ({ data, type, field, isLoading = false }) => {
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedWeek, setSelectedWeek] = useState("all");
  const [filteredData, setFilteredData] = useState([]);

  // Filter data based on selected year, month, and week
  useEffect(() => {
    if (selectedYear && selectedMonth) {
      let filtered;
      if (selectedWeek === "all") {
        filtered = data.filter(
          (item) =>
            item.year == parseInt(selectedYear) &&
            item.month == parseInt(selectedMonth),
        );
        // Sum the total for each category within the selected month
        const summedData = {};
        filtered.forEach((item) => {
          const category = item[field];
          summedData[category] =
            (summedData[category] || 0) + parseFloat(item.total);
        });
        setFilteredData(
          Object.keys(summedData).map((category) => ({
            [field]: category,
            total: summedData[category],
          })),
        );
      } else {
        filtered = data.filter(
          (item) =>
            item.year == parseInt(selectedYear) &&
            item.month == parseInt(selectedMonth) &&
            item.week_in_month == parseInt(selectedWeek),
        );
        setFilteredData(filtered);
      }
    }
  }, [selectedYear, selectedMonth, selectedWeek, data, field]);

  // Get unique years from data
  const years = Array.from(new Set(data.map((item) => item.year)));

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleWeekChange = (e) => {
    // console.log("selected week ", e.target.value);
    if (e.target.value == "all") {
      setSelectedWeek("all");
    } else {
      setSelectedWeek(e.target.value);
    }
  };

  // Get unique weeks from data based on selected year and month
  const weeks = Array.from(
    new Set(
      data
        .filter(
          (item) =>
            item.year == parseInt(selectedYear) &&
            item.month == parseInt(selectedMonth),
        )
        .map((item) => item.week_in_month),
    ),
  );

  // Add an option for selecting all weeks
  const allWeeksOption = { id: "all", name: "All Weeks" };
  weeks.unshift(allWeeksOption);

  const getBarColor = (type) => {
    switch (type) {
      case "Scan Count":
        return ["#003f5c", "#7a5195"]; // Dark and bright colors for Scan Count
      case "Total MRP":
        return ["#2f4b7c", "#ff6f61"]; // Dark and bright colors for Total MRP
      case "Total Registrations":
        return ["#665191", "#ff964f"]; // Dark and bright colors for Total Registrations
      case "Product Total Mrp":
        return ["#810400", "#ffb745"]; // Dark and bright colors for Product Total Mrp
      case "Total Scan":
        return ["#810400", "#19abde"]; // Dark and bright colors for Total Scan
      case "Total Scanning":
        return ["#19abde", "#fc5c65"]; // Dark and bright colors for Total Scanning
      case "Total Redemptions":
        return ["#1a4ab2", "#48dbfb"]; // Dark and bright colors for Total Redemptions
      default:
        return ["#000000", "#ffffff"]; // Default dark and bright colors
    }
  };

  const chartData = {
    labels: filteredData.map((item) => item[field]),
    datasets: [
      {
        label: type,
        data: filteredData.map((item) => parseFloat(item.total)),
        backgroundColor: getBarColor(type)[0], // Dark color
        borderColor: getBarColor(type)[1], // Bright color
        borderWidth: 1,
      },
    ],
  };

  if (isLoading) {
    return <div className="flex justify-center items-center mt-4 mb-4">
      <LoaderComponent/>
    </div>;
  } else {
    return (
      <div>
        <div className="flex justify-center items-center px-5 mt-2 space-x-5 ">
          <div className="w-1/3 rounded-md">
            <select id="year" value={selectedYear} onChange={handleYearChange}>
              <option selected>Select Year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div className="w-1/3 rounded-md">
            <select
              id="month"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              <option selected>Select Month</option>
              {years.length > 0 &&
                months.map((month) => (
                  <option key={month.id} value={month.id}>
                    {month.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-1/3 rounded-md">
            <select id="week" value={selectedWeek} onChange={handleWeekChange}>
              <option value="">Select Week</option>
              {years.length > 0 &&
                weeks.map((week, index) =>
                  Object.keys(week).includes("name") ? (
                    <option key={index} value={week.id}>
                      All Weeks
                    </option>
                  ) : (
                    <option key={index} value={week}>{`Week ${week}`}</option>
                  ),
                )}
            </select>
          </div>
        </div>
        {filteredData.length > 0 ? (
          <div className="max-h-80 mx-auto flex justify-center items-center">
            <Bar data={chartData} />
          </div>
        ) : (
          <div className="flex justify-center items-center text-lg font-bold pt-5 mb-2 ">
            {" "}
            No data available for the selected filters.
          </div>
        )}
      </div>
    );
  }
};

export default YearMonthWeekBarChart;
