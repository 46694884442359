import { rootApi } from "../../api/rootApi";

export const cashTransactionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllCashTransactions: builder.mutation({
      query: (params) => {
        console.log("params",params.query);

        let queryString =`?`;
        // let queryString =`?limit=${params.query.limit}&offset=${params.query.offset}`;
        
        for (const x in params.query){
          if(params.query[x] && typeof params.query[x] == 'string' &&params.query[x].length > 0 ){
            queryString+=`&${x}=${params.query[x]}`

          }else if( typeof params.query[x] == 'number'){
            queryString+=`&${x}=${params.query[x]}`
          }
        }
        console.log("object",queryString);
        return {
          method: "GET",
          url: `/tenant/cash-transactions/${queryString}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchPayoutStats: builder.mutation({
      query: (params) => {
        console.log("fetchPayoutStats",params);

        return {
          method: "GET",
          url: `/tenant/cash-transactions/payout-stats?start_date=${params.start_date}&end_date=${params.end_date}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchAllCashTransactionsByAppUserID: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/cash-transactions/app-user/?limit=${params.limit}&offset=${params.offset}&app_user_id=${params.app_user_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchAllCashTransactionsStats: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/cash-transactions/stats`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchAllTheCashTransferRequests: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/cash-transactions/transfer-request`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
    handleAcceptRequest: builder.mutation({
      query: (params) => {
        console.log("accept",params);
        return {
          method: "PATCH",
          url: `/tenant/cash-transactions/accept/${params.account_id}/${params.cash_transfer_to_bank_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
    handleRejectRequest: builder.mutation({
      query: (params) => {
        console.log("reject",params);
        return {
          method: "PATCH",
          url: `/tenant/cash-transactions/reject/${params.account_id}/${params.cash_transfer_to_bank_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body:params.body
        };
      },
    }),
    
  }),
});

export const {
  useFetchAllCashTransactionsMutation, 
  useFetchAllCashTransactionsByAppUserIDMutation,
  useFetchAllCashTransactionsStatsMutation,
  useFetchAllTheCashTransferRequestsMutation,
  useHandleAcceptRequestMutation,
  useHandleRejectRequestMutation,
  useFetchPayoutStatsMutation
} = cashTransactionsApi;
