import React, { Fragment, useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";

import {
  useCountFeedbackMutation,
  useFetchFeedbackMutation,
} from "../../../features/tenant/feedback/feedBackApi";
import CustomExport from "../../../components/CustomExport/CustomExport";

const AppFeedback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [
    FetchFeedback,
    {
      data: FetchFeedbackData,
      error: FetchFeedbackError,
      isLoading: FetchFeedbackIsLoading,
      isError: FetchFeedbackIsError,
    },
  ] = useFetchFeedbackMutation();

  const [
    CountFeedback,
    {
      data: CountFeedbackData,
      error: CountFeedbackError,
      isLoading: CountFeedbackIsLoading,
      isError: CountFeedbackIsError,
    },
  ] = useCountFeedbackMutation();

  const [
    FetchCsvFeedback,
    {
      data: FetchCsvFeedbackData,
      error: FetchCsvFeedbackError,
      isLoading: FetchCsvFeedbackIsLoading,
      isError: FetchCsvFeedbackIsError,
    },
  ] = useFetchFeedbackMutation();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [prevPage, setPrevPage] = useState(0);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [feedback, setFeedback] = useState([]);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);

  useEffect(() => {
    if (FetchFeedbackData?.body?.feedbackList) {
      setFeedback(FetchFeedbackData.body.feedbackList);
    }
  }, [FetchFeedbackData]);

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  useEffect(() => {
    fetchFetchFeedbackFunction();
    fetchCountFeedbackFunction();
    // fetchFetchCsvFeedbackFunction();
  }, [location.pathname, limit, offset, userTypesArray]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
    setRequest({ ...request, offset: newOffset });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setRequest({ ...request, limit: newPerPage });
  };

  useEffect(() => {}, [request]);
  const fetchFetchFeedbackFunction = (row) => {
    FetchFeedback({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchCountFeedbackFunction = (row) => {
    CountFeedback({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchFetchCsvFeedbackFunction = (row) => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    FetchCsvFeedback({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };
  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (FetchCsvFeedbackData && FetchCsvFeedbackData.body.feedbackList) {
      setCsvData(FetchCsvFeedbackData.body.feedbackList);
    }

    if (FetchCsvFeedbackError) {
      setCsvData(null);
    }
  }, [FetchCsvFeedbackData, FetchCsvFeedbackError]);

  // FetchCsvFeedback

  const clearFilters = () => {
    setRequest(initialRequest);
  };
  useEffect(() => {
    if (
      request.mobile === "" &&
      request.user_type_id === userTypeId &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      fetchFetchFeedbackFunction();
      // fetchFetchCsvFeedbackFunction();
    }
  }, [request, userTypeId]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => <div>{row.name}</div>,
      sortable: true,
    },

    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "UserType",
      cell: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "Rating",
      cell: (row) => row.rating,
      sortable: true,
    },

    {
      name: "Feedback",
      cell: (row) => row.feedback,
      sortable: true,
      width: "300px",
    },
    // {
    //   name: "Status",
    //   cell: (row) => (row.status == "1" ? <>Blocked</> : <>unBlocked</>),
    // }
  ];
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            App FeedBack
            <LinksComponent />
          </div>

          <div className="p-3 ">
            <div className="flex justify-between items-start">
              {/* {FetchCsvFeedbackData && FetchCsvFeedbackData?.body?.feedbackList && (
                <ExportCSV
                  data={FetchCsvFeedbackData && FetchCsvFeedbackData?.body?.feedbackList}
                  columnsToExport={[
                    "name",
                    "mobile",
                    "user_type",
                    "rating",
                    "feedback"
                  ]}
                />
              )} */}

              <div className="mb-2">
                <CustomExport
                  fetchData={fetchFetchCsvFeedbackFunction}
                  data={csvData}
                  defaultColumnExport={[
                    "name",
                    "mobile",
                    "user_type",
                    "rating",
                    "feedback",
                  ]}
                  filename="app_feedback"
                />
              </div>
            </div>

            <div className=" w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex  space-x-2 ">
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile No
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="flex-col slc_dvd topslt">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        User Types
                      </label>
                      <div className="relative searchin">
                        {appUsers && appUsers.length > 0 && (
                          <SelectComponent
                            className="capitalize"
                            // title={"User Types"}
                            selected={userTypesArray}
                            setSelected={setUserTypesArray}
                            dataArr={appUsers}
                          />
                        )}{" "}
                      </div>
                    </div>
                  </div>

                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>

                    <div className="flex flex-row  gap-2 ">
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                          onClick={() => {
                            fetchFetchFeedbackFunction();

                            // fetchFetchCsvFeedbackFunction();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-3">
                <DataTableComponent
                  columns={columns}
                  data={feedback.length && feedback}
                  totalRows={
                    CountFeedbackData && CountFeedbackData.body.total_feedback
                  }
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppFeedback;
