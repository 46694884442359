import { rootApi } from "../../api/rootApi";

export const giftApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllGifts: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/gift`,
          // url: `/tenant/gift/?limit=${params.limit}&&offset=${params.offset}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchGiftById: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/gift/${params.id}`,
          // url: `/tenant/gift/${params.id}?limit=5&offset=1`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addGift: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/gift/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateGift: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/gift/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deleteGift: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/gift/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchGiftsRedemptionsOfUser: builder.mutation({
      query: (params) => {
        // console.log("params--->",params);
        return {
          method: "GET",
          url: `app/userPointRedemptions/${params.userId}/${params.type}?${
            params.limit ? `limit=${params.limit}&` : ""
          }${params.offset ? `offset=${params.offset}` : ""}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const {
  useAddGiftMutation,
  useUpdateGiftMutation,
  useFetchAllGiftsMutation,
  useFetchGiftByIdQuery,
  useDeleteGiftMutation,
  useFetchGiftsRedemptionsOfUserMutation,
} = giftApi;
