import React, { useEffect, useMemo, useState } from "react";
import LinksComponent from "../../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../../components/Tenant/InputComponent/InputComponent";

import { toast } from "react-hot-toast";
import { AiOutlinePlus } from "react-icons/ai";
import {
  useAddBatchOfProductsWithPointsMutation,
  useFetchProductWithPointsMutation,
} from "../../../../features/tenant/rewardify/productPoints/productPoints";
import { useFetchProductsByCategoryIdMutation } from "../../../../features/tenant/products/productsApi";
import { useNavigate } from "react-router-dom";
import { useFetchSubcategoriesMutation } from "../../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { useGetAppUserTypeQuery } from "../../../../features/tenant/appUserType/appUserTypeApi";

const temp = [
  {
    id: "1",
    name: "tushar",
  },
];

// const AppUsers = [
//   {
//     id: 1,
//     name: "retailer",
//   },
//   {
//     id: 2,
//     name: "distributor",
//   },
//   {
//     id: 3,
//     name: "consumer",
//   },
// ];

const productCategories = [
  {
    id: "1",
    name: "categoryElectronic",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: "2",
    name: "wood",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];
// const productSubCategories = [
//   {
//     id: "1",
//     name: "electronic",
//     avatar:
//       "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//   },
//   {
//     id: "2",
//     name: "wood",
//     avatar:
//       "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//   },
//   {
//     id: "3",
//     name: "steel",
//     avatar:
//       "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//   },
// ];

const productBrandName = [
  {
    id: "1",
    name: "Apple",
  },
  {
    id: "2",
    name: "Samsung",
  },
];

const AddProductPoints = () => {
  const handleInputChange = (index, name, value) => {
    const updatedList = [...checkProductsList];
    updatedList[index][`${name}_points`.toLocaleLowerCase()] = value === "" ? "" : Number(value);
    setCheckProductsList(updatedList);
  };
  const navigate = useNavigate();
  const [selectedProductCategory, setSelectedProductCategory] = useState({
    id: "-1",
    name: "select category",
  });
  const [selectedProductSubCategory, setSelectedProductSubCategory] = useState({
    id: "-1",
    name: "select sub-category",
  });
  const [selectedBrandName, setSelectedBrandName] = useState(
    productBrandName[0]
  );
  const {
    data: getAppUserTypeData,
    isLoading: getAppUserTypeIsLoading,
    isError: getAppUserTypeIsError,
    error: getAppUserTypeError,
  } = useGetAppUserTypeQuery({
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  console.log("getAppUserTypeData", getAppUserTypeData);

  const [
    fetchProductsByCategoryId,
    {
      data: productsByCategoryData,
      isLoading: productsByCategoryIsLoading,
      isError: productsByCategoryIsError,
      error: productsByCategoryError,
    },
  ] = useFetchProductsByCategoryIdMutation() || {};

  const [
    addBatchOfProductsWithPoints,
    {
      data: addBatchOfProductsWithPointsData,
      isLoading: addBatchOfProductsWithPointsIsLoading,
      isError: addBatchOfProductsWithPointsIsError,
      error: addBatchOfProductsWithPointsError,
    },
  ] = useAddBatchOfProductsWithPointsMutation() || {};

  const [
    fetchSubCategories,
    { data: subCategoriesData, error: subCategoriesError },
  ] = useFetchSubcategoriesMutation();

  // state for check mark change for all the products
  const [checkAllProducts, setCheckAllProducts] = useState(false);
  // state to track the changes for individal products
  const [checkProductsList, setCheckProductsList] = useState([]);
  // state for point change for all the products according to user
  const [globalPointsChange, setGlobalPointsChange] = useState([]);

  useMemo(() => {
    console.log("memo");

    if (Number(selectedProductSubCategory.id) > 0) {
      // console.log("memo if",selectedProductSubCategory);
      fetchProductsByCategoryId({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        category_id: Number(selectedProductSubCategory.id),
      });
    }
  }, [selectedProductSubCategory]);

  //   console.log("object",productsByCategoryData);

  if (productsByCategoryIsLoading) {
    toast.dismiss();
    toast.loading("loading...", {
      id: "product-by-category-loading",
    });
  }

  useMemo(() => {
    if (productsByCategoryData) {
      toast.dismiss();
    }
    if (productsByCategoryData && productsByCategoryData.body.length === 0) {
      toast.dismiss();
      console.log("sakjhfaksfhkasfhkaf------------", productsByCategoryData);
      toast.error(productsByCategoryData.message, {
        id: "product-by-category-no-data-fetch",
      });
    }
    if (
      productsByCategoryData &&
      productsByCategoryData.body &&
      productsByCategoryData.body.products
    ) {
      // state to manage points for individual product for a app user

      let isCheckedRequired = productsByCategoryData.body.products.map(
        (prod, index) => {
          return getAppUserTypeData.body.reduce((acc, x) => {
            let bool = Number(prod[`${x.name}_points`.toLocaleLowerCase()]) === 0 ? true : false;
            return acc && bool;
          }, true);
          return { ...prod, checked: false };
        }
      );

      console.log("isCheck required", isCheckedRequired);

      setCheckProductsList(
        productsByCategoryData.body.products.map((prod, index) => {
          return isCheckedRequired[index]
            ? { ...prod, checked: false }
            : { ...prod, checked: true };
        })
      );

      setGlobalPointsChange(
        getAppUserTypeData.body.map((user) => {
          return {
            user_type_id: Number(user.id),
            user_type: user.name,
            all_points: 0,
          };
        })
      );
    }
  }, [productsByCategoryData]);

  if (productsByCategoryIsError) {
    toast.dismiss();
    toast.error(productsByCategoryError.message, {
      id: "fetch-product-by-category-error",
    });
  }

  const handleAddPointsToProducts = (e) => {
    e.preventDefault();
    console.log(checkProductsList);
    let reqData = [];
    checkProductsList.map((product, index) => {
      if (product.checked) {
        delete product.brand_id;
        delete product.brand_name;
        delete product.category_id;
        delete product.category_name;
        delete product.checked;
        delete product.created_at;
        delete product.created_by_id;
        delete product.created_by_name;
        delete product.description;
        delete product.updated_at;
        delete product.updated_by_id;
        delete product.updated_by_name;
        delete product.name;
        delete product.product_code;
        delete product.qr_type;
        delete product.qty;
        delete product.status;
        delete product.unit_id;
        delete product.unit_name;
        delete product.images;
        console.log("product", product);

        reqData.push(product);
      }
      return;
    });

    console.log("reqdata", reqData);
    if (reqData.length === 0) {
      toast.error("please select the product", {
        id: "check-mark-product-error",
      });
      return;
    }

    addBatchOfProductsWithPoints({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: reqData,
    });
  };

  console.log(
    "object...................",
    checkProductsList.length > 0 && checkProductsList[0]
  );

  if (addBatchOfProductsWithPointsData) {
    console.log("object", addBatchOfProductsWithPointsData);
    toast.dismiss();
    navigate("/genefied/baltra/product-points");
  }

  useEffect(() => {
    fetchSubCategories({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (subCategoriesData) {
      console.log("SubCAtegories", subCategoriesData);
      // setSelectedProductCategory(subCategoriesData.body);
    }
  }, [subCategoriesData, subCategoriesError]);

  return (
    <div className="flex w-full bg-slate-200 h-full ">
      <div className="w-full justify-center ">
        <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
          Manage Products Points
          <LinksComponent />
        </div>
        <div className="p-3">
          <div className=" bg-white shadow rounded-lg ">
          <div className="w-full bg-gray-100 mb-3 p-3 rounded-t-lg">
                <SelectComponent
                  title={"Sub Category"}
                  selected={selectedProductSubCategory}
                  setSelected={setSelectedProductSubCategory}
                  dataArr={subCategoriesData ? subCategoriesData?.body : []}
                />
              </div>

              <div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
            {Number(selectedProductSubCategory.id) <= 0 ? (
              <></>
            ) : productsByCategoryData &&
              productsByCategoryData.body.length < 1 ? (
              <></>
            ) : (
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <button
                    className="fixed text-sm m-6 px-3 py-2.5 bottom-5 min-w-20 right-2  bg-blue-700 text-blue-100 rounded-full "
                    onClick={handleAddPointsToProducts}
                  >
                    {/* <AiOutlinePlus className="w-4 h-4 " /> */}
                    Save
                  </button>
                  <tr>
                    <th scope="col" className="px-6 py-2">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
                          checked={checkAllProducts}
                          onChange={() => {
                            setCheckAllProducts(!checkAllProducts);
                            setCheckProductsList(
                              checkProductsList.map((x) => {
                                x.checked = !checkAllProducts;
                                return x;
                              })
                            );
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Product name
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Product Code
                    </th>
                    {getAppUserTypeData &&
                      getAppUserTypeData?.body.length > 0 &&
                      getAppUserTypeData?.body.map((user, index) => {
                        return (
                          <th
                            scope="col"
                            className=" text-center px-6 py-2"
                            key={user.id}
                          >
                            <span>{user.name}</span>
                            <div className="w-full">
                              <input
                                type="number"
                                key={user.id}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                placeholder={"Enter Point"}
                                value={
                                  globalPointsChange.length > 0 &&
                                  globalPointsChange[index].all_points
                                }
                                onChange={(e) => {
                                  globalPointsChange[index].all_points = Number(
                                    e.target.value
                                  );

                                  setGlobalPointsChange([
                                    ...globalPointsChange,
                                  ]);

                                  setCheckProductsList(
                                    checkProductsList.map((x) => {
                                      console.log("user.............", user);
                                      x[`${user.name}_points`.toLocaleLowerCase()] =
                                        globalPointsChange[index].all_points;
                                      return x;
                                    })
                                  );
                                }}
                              />
                            </div>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {productsByCategoryData &&
                    productsByCategoryData.body.products &&
                    productsByCategoryData.body.products.map(
                      (product, index) => {
                        return (
                          <>
                            <tr
                              className="bg-white border-b  hover:bg-gray-50
                            "
                              key={product.product_id}
                            >
                              <td className="w-4 px-6 py-3" key={index}>
                                <div className="flex items-center" key={index}>
                                  <input
                                    id="checkbox-table-search-1"
                                    type="checkbox"
                                    key={index}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
                                    checked={
                                      checkProductsList &&
                                      checkProductsList.length > 0 &&
                                      checkProductsList[index]?.checked
                                    }
                                    onChange={() => {
                                      checkProductsList[index].checked =
                                        checkProductsList.length > 0 &&
                                        !checkProductsList[index].checked;
                                      setCheckProductsList([
                                        ...checkProductsList,
                                      ]);
                                    }}
                                  />
                                </div>
                              </td>
                              <th
                                scope="row"
                                className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {product.name}
                              </th>
                              <th
                                scope="row"
                                className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {product.product_code}
                              </th>
                              {getAppUserTypeData &&
                                getAppUserTypeData.body.length > 0 &&
                                getAppUserTypeData.body.map(
                                  (user, indexUser) => {
                                    return (
                                      <td className="px-6 py-3" key={indexUser}>
                                        <div className="w-full">
                                          <input
                                            type="number"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            value={
                                              (checkProductsList?.length > 0 &&
                                                `${user.name}_points`.toLocaleLowerCase() in
                                                  checkProductsList[index] &&
                                                checkProductsList[index][
                                                  `${user.name}_points`.toLocaleLowerCase()
                                                ]) ||
                                              ""
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                index,
                                                user.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                    );
                                  }
                                )}
                            </tr>
                          </>
                        );
                      }
                    )}
                </tbody>
              </table>
            )}
          </div>

          </div>
         
        </div>
      </div>
    </div>
  );
};

export default AddProductPoints;
