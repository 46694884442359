
export function validateAndCleanObjects(objects) {
    const requiredKeysSet = new Set(['invoice_id', 'week', 'retailer_code', 'sku_code', 'product_category', 'sp_to_retailer', 'qty', 'invoice_value']);

    function isEmptyObject(obj) {
        return Array.from(requiredKeysSet).every(key => !obj[key]);
    }

    const cleanedObjects = [];

    for (const object of objects) {
        const newObject = {};

        if (isEmptyObject(object)) {
            continue;
        }

        let valid = true;
        for (const key in object) {
            if (requiredKeysSet.has(key)) {
                if (!object[key]) {
                    valid = false;
                    break;
                } else {
                    newObject[key] = object[key];
                }
            }
        }

        newObject.status = valid ? 1 : 2;
        cleanedObjects.push(newObject);
    }

    if (cleanedObjects.some(obj => obj.status === 2)) {
        return {
            isValid: false,
            data: cleanedObjects
        };
    }

    return {
        isValid: true,
        data: cleanedObjects
    };
}
