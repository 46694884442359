import { rootApi } from "../../../api/rootApi";

export const pointsScanHistory = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPointsScanHistory: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/userPointsEnteries?zone_id=${params.zone_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchPointsScanHistoryCount: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/userPointsEnteries/count?zone_id=${params.zone_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    fetchUserPoints: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/userPoints?user_id=${params.userId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    fetchUserPointsHistory: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/userPointsEnteries?${
            params.userId ? `id=${params.userId}&` : ""
          }${
            params.limit ? `limit=${params.limit}&` : ""
          }${`offset=${params.offset}`}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    allUserPointsEntry: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/app/userPoints?user_id=${params.userId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    revertUserPointEntery: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/userPointEnteries/revert/${params.app_user_id}/${params.qr_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    bulkUserPointsUpdate: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/app/userPoints`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useFetchPointsScanHistoryMutation,
  useFetchUserPointsHistoryMutation,
  useFetchUserPointsMutation,
  useAllUserPointsEntryMutation,
  useRevertUserPointEnteryMutation,
  useBulkUserPointsUpdateMutation,
  useFetchPointsScanHistoryCountMutation
} = pointsScanHistory;
