import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import logoimg from "../../../assests/tenant/images/OZO-STAR.gif";
import { getLoginUserData } from "../../../utils/getLoginUserData";


const Home = () => {
 
 const tenantUserdata = getLoginUserData();
 const vendorData = JSON.parse(sessionStorage.getItem("vendorData"));
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Home
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="homecenter">
            <div className="txthome text-center w-100">
            <span>Last Login&nbsp;&nbsp;{tenantUserdata.last_login.split("T")[0]}&nbsp;&nbsp;{tenantUserdata.last_login.split("T")[1].split(".")[0]}
              
              </span>
            <img className="m-auto" src={vendorData.logo[0]} alt="" />
            <h1>Welcome to {tenantUserdata.name}</h1>
           
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
