import { rootApi } from "../../api/rootApi";

export const manageUserSBApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addSupplyBeamUsers: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/supply-beam/user-enrollment/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getSupplyBeamUsers: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/supply-beam/user-enrollment`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateSupplyBeamUsers: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/supply-beam/user-enrollment/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    deleteSupplyBeamUsers: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/supply-beam/user-enrollment/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
  }),
});

export const { useAddSupplyBeamUsersMutation , useDeleteSupplyBeamUsersMutation ,useGetSupplyBeamUsersMutation,useUpdateSupplyBeamUsersMutation } = manageUserSBApi;
