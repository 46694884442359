import { rootApi } from "../../api/rootApi";

export const tenantPermissionsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenantPermissions: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/userPermissions/all`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateAllTenantPermissions: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/userPermissions/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
  }),
});

export const { useGetAllTenantPermissionsMutation ,useUpdateAllTenantPermissionsMutation} = tenantPermissionsApi;
