import { rootApi } from "../../api/rootApi";

export const EmailTemplateApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEmailTemplate: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: "/admin/emailTemplate",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),

    getAllEmailTemplateByTenantId: builder.mutation({
        query: (params) => {
          return {
            method: "GET",
            url: `/admin/emailTemplate/tenant/${params.tenant_id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
            },
          };
        },
      }),

    createEmailTemplate: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/admin/emailTemplate/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
          body: params.data,
        };
      },
    }),

    updateEmailTemplate: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/admin/emailTemplate/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
          body: params.data,
        };
      },
    }),

    deleteEmailTemplate: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/emailTemplate/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
          },
        };
      },
    }),
  }),
});
export const {
  useGetAllEmailTemplateMutation,
  useGetAllEmailTemplateByTenantIdMutation,
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
} = EmailTemplateApi;
