import { rootApi } from "../../api/rootApi";

export const wheelRewardsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addWheelRewards: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/wheelRewards/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    getAllWheelRewards: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/wheelRewards`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    getWheelRewardsById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/wheelRewards/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateWheelRewardsById: builder.mutation({
      query: (params) => {
        return {
          method: "PATCH",
          url: `/tenant/wheelRewards/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.data,
        };
      },
    }),
    deleteWheelRewards: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/wheelRewards/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    getWheelRewardsByWheelId: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/wheelRewards/wheelId/${params.id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    getAllWinners: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/wheelRewardsWon`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const {
  useAddWheelRewardsMutation,
  useGetAllWheelRewardsMutation,
  useGetWheelRewardsByIdMutation,
  useUpdateWheelRewardsByIdMutation,
  useDeleteWheelRewardsMutation,
  useGetWheelRewardsByWheelIdMutation,
  useGetAllWinnersMutation,
} = wheelRewardsApi;
