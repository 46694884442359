import { rootApi } from "../../api/rootApi";

export const formSetupApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchFormSetup: builder.mutation({
      query: (token) => {
        return {
          method: "GET",
          url: "/admin/formUsage",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
      },
    }),
    addFormSetup: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: "/admin/formUsage/add",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
          body: JSON.stringify(params.body),
        };
      },
    }),

    deleteFormSetup: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/admin/formUsage/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + params.token,
          },
        };
      },
    }),
  }),
});
export const {
  useFetchFormSetupMutation,
  useAddFormSetupMutation,
  useDeleteFormSetupMutation,
} = formSetupApi;
