import { rootApi } from "../../api/rootApi";

export const membershipApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    manualRewarding: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/manual-rewarding`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    bulkManualRewarding: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/manual-rewarding/bulk`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    getUserProfile: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/manual-rewarding/user-profile/${params.mobile}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

  }),
});

export const {
 useGetUserProfileMutation,
 useManualRewardingMutation,
 useBulkManualRewardingMutation
} = membershipApi;
