import { rootApi } from "../../api/rootApi";

export const companyProfileApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanyProfile: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/company/${params.tenant_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateCompanyProfile: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/company/${params.tenant_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const { useFetchCompanyProfileMutation , useUpdateCompanyProfileMutation } = companyProfileApi;
