import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { HiDownload } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";
import { downloadImage } from "../../../utils/downloadImage";



export default function ImageModal({ size, show, setShow, data, children }) {
  const [isOpen, setIsOpen] = useState(show);

  function closeModal() {
    setIsOpen(false);
    setShow(false);
  }

  let width = "max-w-3xl";
  if (size === "sm") {
    width = "max-w-sm";
  } else if (size === "md") {
    width = "max-w-md";
  } else if (size === "lg") {
    width = "max-w-lg";
  } else if (size === "xl") {
    width = "max-w-xl";
  } else if (size === "2xl") {
    width = "max-w-2xl";
  } else if (size === "3xl") {
    width = "max-w-3xl";
  } else if (size === "4xl") {
    width = "max-w-4xl";
  } else if (size === "5xl") {
    width = "max-w-5xl";
  } else if (size === "6xl") {
    width = "max-w-6xl";
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 max-w" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-1 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full transform rounded-xl bg-white px-1 py-1 text-left align-middle shadow-xl transition-all ${width}`}
                >
                  <Dialog.Title
                    as="div"
                    className="text-lg text-center font-medium leading-6 text-gray-900"
                  >
                    <div className="w-full flex justify-between">
                      <div></div>

                      <div className=" w-7 h-7" onClick={closeModal}>
                        <IoIosCloseCircle className="w-full h-full" />
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className="mx-auto my-2 py-0">
                    <div className=" ">{children}</div>
                  </div>
                  <button
                    className="flex m-auto justify-center items-center rounded bg-primary px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    onClick={() => downloadImage(data)}
                  >
                    Download
                    <HiDownload className="ml-1" />
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
