import { rootApi } from "../../api/rootApi";

export const issueReportApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchIssueReports: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/issue`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateIssueReports: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/issue/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
  }),
});

export const { useFetchIssueReportsMutation,useUpdateIssueReportsMutation } = issueReportApi;
