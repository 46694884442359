import { rootApi } from "../../api/rootApi";

export const customNotificationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addCustomNotifications: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/custom-notifications/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    getAllCustomNotifications: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/custom-notifications/`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    updateStatusOfCustomNotifications: builder.mutation({
        query: (params) => {
          return {
            method: "PUT",
            url: `/tenant/custom-notifications/${params.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
    deleteCustomNotifications: builder.mutation({
        query: (params) => {
          return {
            method: "DELETE",
            url: `/tenant/custom-notifications/${params.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + params.token,
              slug: params.tenant_id,
            },
          };
        },
      }),
  }),
});

export const {
useAddCustomNotificationsMutation,
useGetAllCustomNotificationsMutation,
useUpdateStatusOfCustomNotificationsMutation,
useDeleteCustomNotificationsMutation
} = customNotificationApi;
